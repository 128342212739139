
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const TEMPLATE = `${API_URL}analytics/template`;



export const insertTemplate = async (
  name: string,token: string
) => {
  return axios.post(
    TEMPLATE,
    {
      name,
      
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateTemplate = async (
    name: string,id: string, token: string
  ) => {
    return axios.put(
      TEMPLATE+"?templateId="+id,
      {
        name,
      },
      {
        headers: {
          'X-Auth-token': token,
        },
      }
    );
  };

export const deleteTemplate = async (id: string, token: string) => {
    return axios.delete(
        TEMPLATE+"/"+id, 
        {
      headers: {
        'X-Auth-token': token,
      },
    });
  };

