import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const GET_LABEL = `${API_URL}labels`
const GET_LABEL_PRODUCT = `${API_URL}labels?usableBy[]=product`
const LABEL = `${API_URL}label/`

export const getLabel = (token: string) => {
    return axios.get(GET_LABEL,
        {
            headers: {
                'X-Auth-token': token
            }
        })
}
export const getLabelProduct = (token: string) => {
    return axios.get(GET_LABEL_PRODUCT,
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const insertLabel = (name: string,isUsableByPipeline:boolean,isUsableByMeeting:boolean,isUsableByMerchant:boolean,isUsableByProduct:boolean,color:string, token: string) => {
    return axios.post(LABEL,
        {
            name,isUsableByPipeline,isUsableByMeeting,isUsableByMerchant,isUsableByProduct,color
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}

export const updateLabel = (id: string, name: string,isUsableByPipeline:boolean,isUsableByMeeting:boolean,isUsableByMerchant:boolean,isUsableByProduct:boolean,color:string, token: string) => {
    return axios.patch(LABEL + id,
        {
            name,isUsableByPipeline,isUsableByMeeting,isUsableByMerchant,isUsableByProduct,color
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })

}

export const deleteLabel = (id: string, token: string) => {
    return axios.delete(LABEL + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}