import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../_metronic/helpers'
import {IComponent, ISection} from '../../../interfaces/Analytics/Analytics'
import clsx from 'clsx'
import {handleCaps, randomColor} from '../../../functions/general'
import {insertSection} from '../../../api/Analitik/SectionCRUD'
import useAccessToken from '../../../hooks/AccessToken'
import Select from 'react-select'
import {DateRangePicker} from 'rsuite'
import {DateRange, DateRangePickerProps} from 'rsuite/esm/DateRangePicker'
import {useReactTable, ColumnDef, getCoreRowModel, createColumnHelper} from '@tanstack/react-table'
import {IconButton, Menu, MenuItem, Popover, Switch} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useModalEffect from '../../../hooks/useModalEffect'
import AddCore from './AddCore'
import EditCore from './EditCore'
import {inserComponent, updateComponent} from '../../../api/Analitik/ComponentCRUD'
import DateRangePickerInterval from './DateRangePickerInterval'
import {useAnalyticsStore} from '../../../stores/Analytics/AnalyticStore'
import TableLite from '../../DataTable/TableLite'

interface IProps {
  section: ISection
  component: IComponent
}
interface IRule {
  typeFilter: string // Atau bisa menggunakan tipe khusus jika "progress" adalah satu-satunya nilai
  valueFilter: any[] | string // Atau gunakan tipe elemen array yang spesifik jika diketahui
}
interface RowData {
  index: number
  name: string
  tableName: string // or Date if you're dealing with date objects
  coreType: string // optional field
  colorCode: string
  relationLead: boolean // or other possible statuses
  rules: IRule[] // or other possible statuses
}




export default function UpdateComponent({section, component}: IProps) {
  const columnHelper = createColumnHelper<RowData>()
  const intl = useIntl()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const [modalBtnLoading, setModalBtnLoading] = useState(false)
  const token = useAccessToken()
  const {components, setComponents} = useAnalyticsStore()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openMenuId, setOpenMenuId] = useState<number | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
    setOpenMenuId(null)
  }

  const columns : ColumnDef<RowData, any>[] = [
    {
      id: 'actions', // Memberikan id untuk kolom aksi
      header: intl.formatMessage({id:"MASTERS.DATATABLE.COLUMNS.ACTION"}),
      size: 25,
      cell: (info: any) => {
        const rowIndex = info.row.index // Ambil index dari urutan data

        return (
          <>
            <IconButton
              aria-label={`more-${rowIndex}`} // Menggunakan `rowIndex` untuk ID yang unik
              onClick={(event) => {
                setAnchorEl(event.currentTarget)
                setOpenMenuId(rowIndex) // Gunakan `rowIndex` untuk membuka menu
              }}
            >
              <MoreVertIcon />
            </IconButton>

            <Menu
              id={`menu-${rowIndex}`} // Menggunakan `rowIndex` untuk ID menu
              open={openMenuId === rowIndex} // Membuka menu berdasarkan index
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose()
                  setShowEditCoreModal(true)
                  setIndexCoreModal(rowIndex)
                }}
              >
                <i className='fas fa-edit fs-4 me-4'></i>
                <span>{intl.formatMessage({id:"FORM.LABEL.UPDATE"})}</span>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  // Hapus data berdasarkan `rowIndex`
                  formik.setFieldValue(
                    'dataCore',
                    formik.values.dataCore.filter((_, index) => index !== rowIndex)
                  )
                }}
              >
                <i className='fas fa-trash fs-3 me-5'></i>
                <span>{intl.formatMessage({id:"FORM.LABEL.DELETE"})}</span>
              </MenuItem>
            </Menu>
          </>
        )
      },
    },
    columnHelper.accessor('name', {
      header: intl.formatMessage({id: 'MASTERS.DATATABLE.COLUMNS.NAME'}),
      size: 30,
      cell: (info) => (
        <span className='d-flex justify-content-between align-items-center gap-3'>
          <span>{info.getValue()}</span>
        </span>
      ),
    }),
    columnHelper.accessor('coreType', {
      header: intl.formatMessage({id: 'FORM.LABEL.DATA_STATUS'}),
      size: 30,
      cell: (info) => {
        let dataStatus = ""
        if(info.getValue()==="created"){
          dataStatus=intl.formatMessage({id:"FORM.OPTION.LABEL.CREATED"})
        }
        if( info.getValue()==="finished" ){
          dataStatus= intl.formatMessage({id:"FORM.OPTION.LABEL.FINISHED"})
        }
       
        return (
        <span className='d-flex justify-content-between align-items-center gap-3'>
          <span>{dataStatus}</span>
        </span>
      )},
    }),
    columnHelper.accessor('colorCode', {
      header: intl.formatMessage({id: 'MASTERS.DATATABLE.COLUMNS.COLOR'}),
      size: 30,
      cell: (info) => (
        <span className='d-flex justify-content-between align-items-center gap-3'>
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '6px',
              backgroundColor: info.getValue() ?? '#D33115',
            }}
          />
        </span>
      ),
    }),
    columnHelper.accessor('relationLead', {
      header: intl.formatMessage({id: 'MASTERS.DATATABLE.COLUMNS.RELATION_LEAD'}),
      size: 30,
      cell: (info) => (
        <span className='d-flex justify-content-between align-items-center gap-3'>
          <span>{info.getValue() ? intl.formatMessage({id:"YES"}) : intl.formatMessage({id:"NOT"})}</span>
        </span>
      ),
    }),
    // columnHelper.accessor('rules', {
    //   header: intl.formatMessage({id: 'MASTERS.DATATABLE.COLUMNS.RULE'}),
    //   size: 30,
    //   cell: (info) => {
    //     const row = info.row.original
    //     const rules = info.row.original.rules
    //     let lengthRules = 0
    //     const getRulehaveValue = rules?.map((rule: IRule) => {
    //       if (rule.valueFilter.length > 0 || rule.valueFilter !== '') {
    //         return rule
    //       }
    //     })
    //     return (
    //       <span className='d-flex justify-content-between align-items-center gap-3'>
    //         <span>{getRulehaveValue?.length}</span>
    //       </span>
    //     )
    //   },
    // }),
  ]

  const timeFrameOptions = [
    {value: 'DAILY', label: intl.formatMessage({id: 'FORM.LABEL.DAILY'})},
    {value: 'WEEKLY', label: intl.formatMessage({id: 'FORM.LABEL.WEEKLY'})},
    {value: 'MONTHLY', label: intl.formatMessage({id: 'FORM.LABEL.MONTHLY'})},
    // {value: 'YEARLY', label: intl.formatMessage({id: 'FORM.LABEL.YEARLY'})},
  ]

  const typeChartOptions = [
    {label: 'Bar', value: 'BAR'},
    {label: 'Pie', value: 'PIE'},
    {label: 'Table', value: 'LIST'},
  ]
  const dataTypeOption = [
    {value: 'CALCULATION', label: intl.formatMessage({id:"FORM.OPTION.TYPE.CALCULATION"})},
    {value: 'NOMINAL', label: intl.formatMessage({id:"FORM.OPTION.TYPE.NOMINAL"})},
    {value: 'AGE', label: intl.formatMessage({id:"FORM.OPTION.TYPE.AGE"})},
  ]



  const formik = useFormik({
    initialValues: {
      componentId: component.componentId,
      sectionId: component.sectionId,
      name: component.nameComponent,
      dataType: component.dataType,
      timeFrame: component.timeFrame,
      chartType: component.chartType,
      position: component.position,
      interval: [
        new Date(component.startDate) as unknown as Date,
        new Date(component.endDate) as unknown as Date,
      ] as [Date, Date],
      dataCore: component.dataCore,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage(
          {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
          {title: intl.formatMessage({id: 'FORM.LABEL.NAME'})}
        )
      ),
      dataType: Yup.string().required(
        intl.formatMessage({id: 'FORM.VALIDATION.FIELD_REQUIRED'}, {title: 'Tipe Data'})
      ),
      chartType: Yup.string().required(
        intl.formatMessage(
          {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
          {title: intl.formatMessage({id: 'FORM.LABEL.TYPE_CHART'})}
        )
      ),
      timeFrame:  Yup.string().required(
              intl.formatMessage(
                {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
                {title: intl.formatMessage({id: 'FORM.LABEL.TIME_FRAME'})}
              )
            ),
      interval: Yup.array()
        .nullable()
        .required(
          intl.formatMessage(
            {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
            {title: intl.formatMessage({id: 'FORM.LABEL.INTERVAL'})}
          )
        ),
      dataCore: Yup.array()
        .min(
          1,
          intl.formatMessage(
            {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
            {title: intl.formatMessage({id: 'FORM.LABEL.DATA_CORE'})}
          )
        ) // Validasi agar dataCore memiliki lebih dari 0 elemen
        .required(
          intl.formatMessage(
            {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
            {title: intl.formatMessage({id: 'FORM.LABEL.DATA_CORE'})}
          )
        ),
    }),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setModalBtnLoading(true)
      try {
        const [startDate, endDate] = values.interval ?? [
          new Date().toISOString().split('T')[0],
          new Date().toISOString().split('T')[0],
        ] // Safely handle null
        const response = await updateComponent({
          componentId: values.componentId,
          chartType: values.chartType,
          name: values.name,
          dataType: values.dataType,
          timeFrame: values.timeFrame,
          dataCore: values.dataCore,
          position: values.position,
          endDate,
          sectionId: values.sectionId,
          token: token,
          startDate,
        })
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: intl.formatMessage({id: 'FORM.VALIDATION.ADD_SUCCESS'}),
            timer: 2000,
            timerProgressBar: true,
          })
          closeModalRef.current?.click()
          setComponents(
            components.map((c) => {
              if (component.componentId === c.componentId) {
                return response.data.data[0]
              }
              return c
            })
          )
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
      } finally {
        setSubmitting(false)
        setModalBtnLoading(false)
      }
    },
  })

  const table = useReactTable({
    data: formik.values.dataCore as never[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })
  const [stepForm, setStepForm] = useState('1')

  const {handleCloseModal} = useHandleCloseModal(modalRef, closeModalRef, formik)

  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
        formik.resetForm()
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  const handleChangeInterval: DateRangePickerProps['onChange'] = (value) => {
    formik.setFieldValue('interval', value)
  }

  const [showAddCoreModal, setShowAddCoreModal] = useState(false)
  const [showEditCoreModal, setShowEditCoreModal] = useState(false)
  const [indexEditCore, setIndexCoreModal] = useState()
  useModalEffect({
    showModal: showAddCoreModal,
    setShowModal: setShowAddCoreModal,
    modalId: `add-core-modal`,
  })
  useModalEffect({
    showModal: showEditCoreModal,
    setShowModal: setShowEditCoreModal,
    modalId: `edit-core-modal-${indexEditCore}`,
  })

  return (
    <>
      <div
        className={`modal fade`}
        tabIndex={-1}
        id={`update-component-modal-${component.componentId}`}
        ref={modalRef}
      >
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})} {component.nameComponent}
              </h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleCloseModal}
                ref={closeModalRef}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            {/* form */}

            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='modal-body'>
                {/* name */}
                <>
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'FORM.LABEL.NAME'})}
                  </label>
                  <div className='input-group mb-3'>
                    <input
                      placeholder={intl.formatMessage({id: 'MASTERS.DATATABLE.COLUMNS.NAME'})}
                      {...formik.getFieldProps('name')}
                      className={clsx('form-control form-control-lg', {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      })}
                      type='text'
                      name='name'
                      autoComplete='off'
                      onInput={(e) => handleCaps(e)}
                    />
                  </div>
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.name}
                      </span>
                    </div>
                  )}
                </>
                {/* jenis chart */}
                <>
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'FORM.LABEL.CHART_TYPE'})}
                  </label>
                  <div className='input-group mb-3'>
                    <Select
                      closeMenuOnSelect={true}
                      openMenuOnClick
                      options={typeChartOptions}
                      placeholder={`${intl.formatMessage({
                        id: 'FORM.ACTION.CHOOSE',
                      })} ${intl.formatMessage({id: 'FORM.LABEL.CHART_TYPE'})}`}
                      value={typeChartOptions.filter(
                        (option: any) => formik.values.chartType === option.value
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                        formik.setFieldValue('chartType', selectedValues?.value)
                      }}
                      className='w-100 '
                      onBlur={() =>
                        formik.values.chartType === '' && formik.setFieldTouched('chartType', true)
                      }
                      styles={{
                        menu: (provided) => ({...provided, zIndex: 9999}),
                      }}
                    />
                  </div>
                  {formik.touched.chartType && formik.errors.chartType && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.chartType}
                      </span>
                    </div>
                  )}
                </>
                {/* jenis data */}
                <>
                  <label className='form-label fs-6 fw-bolder text-dark required'>Tipe Data</label>
                  <div className='input-group mb-3'>
                    <Select
                      closeMenuOnSelect={true}
                      openMenuOnClick
                      options={dataTypeOption}
                      placeholder={`${intl.formatMessage({
                        id: 'FORM.ACTION.CHOOSE',
                      })} Tipe Data `}
                      value={dataTypeOption.filter(
                        (option: any) => formik.values.dataType === option.value
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                        formik.setFieldValue('dataType', selectedValues?.value)
                      }}
                      className='w-100 '
                      onBlur={() =>
                        formik.values.dataType === '' && formik.setFieldTouched('dataType', true)
                      }
                      styles={{
                        menu: (provided) => ({...provided, zIndex: 9999}),
                      }}
                    />
                  </div>
                  {formik.touched.dataType && formik.errors.dataType && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.dataType}
                      </span>
                    </div>
                  )}
                </>
                {/* timeframe */}

                <>
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'FORM.LABEL.TIME_FRAME'})}
                  </label>
                  <div className='input-group mb-3'>
                    <Select
                      closeMenuOnSelect={true}
                      openMenuOnClick
                      options={timeFrameOptions}
                      placeholder={`${intl.formatMessage({
                        id: 'FORM.ACTION.CHOOSE',
                      })} ${intl.formatMessage({id: 'FORM.LABEL.TIME_FRAME'})}`}
                      value={timeFrameOptions.filter(
                        (option: any) => formik.values.timeFrame === option.value
                      )}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions
                        formik.setFieldValue('timeFrame', selectedValues?.value)
                        formik.setFieldValue('interval', null)
                      }}
                      className='w-100'
                      onBlur={() =>
                        formik.values.timeFrame === '' && formik.setFieldTouched('timeFrame', true)
                      }
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({...provided, zIndex: 9999}),
                      }}
                    />
                  </div>
                  {formik.touched.timeFrame && formik.errors.timeFrame && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.timeFrame}
                      </span>
                    </div>
                  )}
                </>

                {/* interval */}
                <>
                  <label className='form-label fs-6 fw-bolder text-dark required'>
                    {intl.formatMessage({id: 'FORM.LABEL.INTERVAL'})}
                  </label>
                  <div className='input-group mb-3'>
                    <DateRangePickerInterval
                      timeSeries={formik.values.timeFrame}
                      value={formik.values.interval}
                      onChange={handleChangeInterval}
                    />
                  </div>
                  {formik.touched.interval && formik.errors.interval && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.interval}
                      </span>
                    </div>
                  )}
                </>
                {/* button create rule/core */}
                <>
                  <div className=' d-flex justify-content-end my-5'>
                    <button
                      type='button'
                      id='add-product-button'
                      className='btn btn-primary mt-3'
                      onClick={() => setShowAddCoreModal(true)}
                    >
                      <span className=''>
                        <i className='fas fa-plus'></i>{' '}
                        {intl.formatMessage({id: 'FORM.ACTION.ADD'})}
                        {' Core'}
                      </span>
                    </button>
                  </div>
                </>
                {/* table */}
                <>
                  {formik.touched.dataCore && formik.errors.dataCore && (
                    <div className='fv-plugins-message-container text-danger'>
                      <span role='alert' className='text-danger'>
                        {formik.errors.dataCore}
                      </span>
                    </div>
                  )}
                  <TableLite columns={columns} data={ formik.values.dataCore!}  usePagination={false}/>
                </>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light d-none'
                  data-bs-dismiss='modal'
                  ref={closeModalRef}
                >
                  {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})}
                </button>
                <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                  {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})}
                </button>
                <button
                  type='submit'
                  className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`}
                  data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})}
                  </span>
                  <span className='indicator-progress'>
                    Loading...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </form>
            {/* form */}
          </div>
        </div>
      </div>
      {showAddCoreModal && (
        <AddCore
          formik={formik}
          stepForm={stepForm}
          setStepForm={setStepForm}
          setShowAddCoreModal={setShowAddCoreModal}
        />
      )}
      {showEditCoreModal && (
        <EditCore
          formik={formik}
          stepForm={stepForm}
          setStepForm={setStepForm}
          setShowEditCoreModal={setShowEditCoreModal}
          index={indexEditCore!}
          setIndex={setIndexCoreModal}
        />
      )}
    </>
  )
}
