import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useIntl } from 'react-intl';

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];
export interface RangeType {
    label: React.ReactNode;
    value: DateRange | ((value?: ValueType) => DateRange);
    closeOverlay?: boolean;
    placement?: 'bottom' | 'left';
}

const usePredefinedRanges = (compact: boolean = false) => {

    const intl = useIntl();
    var now = new Date();
    var startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    var endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999)

    const predefinedRanges: RangeType[] = [
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.TODAY" }),
            value: [startDate, endDate],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.YESTERDAY" }),
            value: [addDays(startDate, -1), addDays(endDate, -1)],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_7_DAYS" }),
            value: [subDays(startDate, 7), endDate],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_30_DAYS" }),
            value: [subDays(startDate, 30), endDate],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.THIS_WEEK" }),
            value: [
                startOfWeek(new Date(), { weekStartsOn: 1 }),  // Start of the week: Monday
                endOfWeek(new Date(), { weekStartsOn: 1 })
                // new Date() > endOfWeek(new Date(), { weekStartsOn: 1 })
                //     ? endOfWeek(new Date(), { weekStartsOn: 1 })
                //     : new Date()  // If today is after the end of the week, use end of week. Otherwise, use today.
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.THIS_MONTH" }),
            value: [
                startOfMonth(new Date()),  // Start of the month
                endOfMonth(new Date())
                // new Date() > endOfMonth(new Date())
                //     ? endOfMonth(new Date())
                //     : new Date()  // If today is after the end of the month, use end of month. Otherwise, use today.
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_WEEK" }),
            value: [
                startOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 }),  // Monday is 1
                endOfWeek(subDays(new Date(), 7), { weekStartsOn: 1 })  // Monday is 1
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.LAST_MONTH" }),
            value: [
                startOfMonth(subDays(new Date(), 30)),
                endOfMonth(subDays(new Date(), 30))
            ],
            placement: 'left'
        },
        {
            label: intl.formatMessage({ id: "PREDEFINED_RANGES.THIS_YEAR" }),
            value: [
                new Date(new Date().getFullYear(), 0, 1),
                new Date(new Date().getFullYear(), 11, 31)
            ],
            placement: 'left'
        }

    ];

    return predefinedRanges;
}

export default usePredefinedRanges;