import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

export const exportPDF = (nameFile: string, nameHeader: { [key: string]: string[] }, data: any[]): File => {
    let exportData: any[] = [];
    const title = nameFile.substring(nameFile.indexOf("[") + 1, nameFile.indexOf("]"));

    const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'cm',
        format: 'a4'
    });

    // Set Header PDF
    doc.setFont('helvetica', 'bold');
    doc.text('Laporan Log Pipeline', 15, 2, { align: 'center' });
    doc.text(title, 15, 2.8, { align: 'center' })

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(14);
    var lastYHeader = 4.8;

    const formatAttachments = (attachments: any[]): string => {
        if (!attachments || attachments.length === 0) return "-";
        return attachments.map(att => att.url).join('\n'); // dijoinkan \n agar ke enter di templatenya
    };

    Array.from(data).map((row, index) => {
        var temp_pdf = []
        temp_pdf.push(
            row.creator_name ?? "-",
            moment(row.date_created).format('DD MMMM YYYY HH:mm'),
            row.duration,
            row.lead_sub_progress_name ? row.lead_progress_name + " (" + row.lead_sub_progress_name + ")" : row.lead_progress_name,
            formatDescription(row.description), // TODO : jadi sebelum masih formatDescription harus ada url Gambar 1 dan Gambar 2 dan itu cukup cuman url nya saja
            formatAttachments(row.attachments),
        )
        exportData.push(temp_pdf);
    });

    addData(doc, exportData, lastYHeader, nameHeader);

    return exportDoc(doc, nameFile);
};

const formatDescription = (description: string): string[] => {
    const maxLength = 100; // Maximum characters per line
    const words = description.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach(word => {
        if ((currentLine + word).length > maxLength) {
            lines.push(currentLine.trim());
            currentLine = '';
        }
        currentLine += word + ' ';
    });
    if (currentLine) lines.push(currentLine.trim());

    return lines;
};

const addData = (doc: jsPDF, data: any[], lastYHeader: number, nameHeader: { [key: string]: string[] }) => {
    autoTable(doc, {
        startY: lastYHeader,
        columnStyles: {
            0: { cellWidth: 3 },  // Created By
            1: { cellWidth: 4 },  // Date Created
            2: { cellWidth: 2 },  // Duration
            3: { cellWidth: 4 },  // Progress
            4: { cellWidth: 'auto' },  // Description (auto-sized)
            5: { cellWidth: 4 }   // File
        },
        head: [nameHeader.reportLog],
        body: data,
        didParseCell: function (data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
                data.cell.styles.fontSize = 10; // mengatur font size di description
            }
        },
        willDrawCell: function (data) {
            if (data.column.index === 4 && data.cell.section === 'body') {
                // Adjust row height for description column
                const cellHeight = data.row.height;
                const textHeight = data.cell.height;
                if (textHeight > cellHeight) {
                    data.row.height = textHeight;
                }
            }
        }
    });
};

const exportDoc = (doc: jsPDF, nameFile: string): File => {
    // Save PDF as Blob
    const blob = doc.output('blob');
    // Create a File object from the Blob
    const pdfFile = new File([blob], `${nameFile}.pdf`, { type: blob.type, lastModified: Date.now() });

    return pdfFile;
};