interface ModalWrapperProps {
    children: React.ReactNode;
    isOpen: boolean;
    onClose: () => void;
    modalSize?: 'sm' | 'md' | 'lg';
}

const ModalWrapper = ({ children, isOpen, onClose, modalSize = 'md' }: ModalWrapperProps) => {
    if (!isOpen) return null;

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1050,
            }}
            onClick={onClose}
        >
            <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    width: '90%',
                    maxWidth: modalSize === 'sm' ? '400px' : modalSize === 'md' ? '550px' : '900px',
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
};

export default ModalWrapper;
