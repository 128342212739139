import React, { useRef } from 'react'
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../_metronic/helpers';
import { getLinkChecker } from '../../../api/EmailCRUD';
import useSWR from 'swr';
import { UrlType } from '../../../interfaces/Email';
import moment from 'moment-timezone';

interface ModalViewListUrlProps {
    ids: string[];
    setShowModal: (show: boolean) => void;
}

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

const ModalViewListUrl = ({ ids, setShowModal }: ModalViewListUrlProps) => {
    const refModal = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const { data: dataUrl, isLoading } = useSWR(!ids ? null : `${API_URL_EMAIL}linkchecker/get-link`, (url) => getLinkChecker(ids))

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
    }

    const charElipsis = (text: string) => {
        if (text.length > 60) {
            return text.substring(0, 60) + '...';
        }
        return text;
    }

    return (
        <div className='modal fade' id={`modal-view-list-url`} tabIndex={-1} ref={refModal}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {intl.formatMessage({ id: 'FORM.ACTION.VIEW_URL' })}
                        </h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={() => {
                                setShowModal(false);
                                refModal.current?.click();
                            }}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        {isLoading ? (
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border spinner-grow"></div>
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table align-middle table-row-bordered table-row-gray-100 gy-3">
                                    <thead>
                                        <tr className='fw-bold fs-6 text-gray-800'>
                                            <th>URL</th>
                                            <th>Waktu Akses</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataUrl?.data.map((item: UrlType) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <div className='d-flex align-items-center'>
                                                        {charElipsis(item.link)}
                                                        <span
                                                            className='ms-2 cursor-pointer'
                                                            onClick={() => copyToClipboard(item.link)}
                                                        >
                                                            <i className='fas fa-copy'></i>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{item.clickStatus ? moment(item.createdAt).format('DD-MMM-YYYY HH:mm:ss') : '-'}</td>
                                                <td>
                                                    {item.clickStatus ?
                                                        <span className='badge badge-light-success'>Clicked</span>
                                                        :
                                                        <span className='badge badge-light-danger'>Not Clicked</span>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalViewListUrl