import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Merchant } from "../../../interfaces/Merchant";
import { AddMerchant } from "../../../components/Merchants/AddMerchant";
import { UpdateMerchant } from "../../../components/Merchants/UpdateMerchant";
import { DeleteMerchant } from "../../../components/Merchants/DeleteMerchant";
import { Modal } from "bootstrap";
import { UserModel } from "../../modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../../setup";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField } from "../../../interfaces/Settings";
import moment from "moment";
import { MerchantAttachments } from "../../../components/Merchants/MerchantAttachments";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import { FilterMerchant } from "../../../components/Merchants/FilterMerchant";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { checkWhatsapp, updateWhatsapp } from "../../../api/Whatsapp";
import useAccessToken from "../../../hooks/AccessToken";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router";
import { useChatStore } from "../../../stores/ChatStore";
import { UpdatePhoneMerchant } from "../../../components/Merchants/UpdatePhoneMerchant";
import { useIntl } from "react-intl";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";
import useSWR, { mutate } from "swr";
import { Link } from "react-router-dom";
import { DetailMerchant } from "../../../components/Merchants/DetailMerchant";
import { AddReimbursement } from "../../../components/Reimbursement/AddReimbursement";
import { MergeMerchant } from "../../../components/Merchants/MergeMerchant";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useModalEffect from "../../../hooks/useModalEffect";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useMerchantStore } from "../../../stores/MerchantStore";
import axios from "axios";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { sortColumnDatatable } from "../../../functions/general";
import { sortListChat } from "../../../functions/chat";
import { getChatMessagesData } from "../../../api/ChatCRUD";
import { Message } from "../../../interfaces/Chat/Message";

const MasterMerchant: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const { templateMerchantId } = useParams()
    const { companyId, roleId } = useCustomeStore()
    const { multiPipelines } = usePipelineStore()
    const [showAddReimbursementModal, setShowAddReimbursementModal] = useState<boolean>(false);
    const [showUpdatePhoneModal, setShowUpdatePhoneModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [showAttachmentCustomField, setShowAttachmentCustomFieldModal] = useState<boolean>(false);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [key, setKey] = useState<string>('');
    const [url, setUrl] = useState<string | string[]>('');
    const [merchant, setMerchant] = useState<Merchant>();
    const { settings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [manageColumns, setManageColumns] = useState<TableColumn<Merchant>[]>([]);
    const token = useAccessToken()
    const { multiMerchants, setSelectMultiMerchant, selectMultiMerchant, setSimpleMerchants, setSimpleMerchantLoading, setMultiMerchants, setMerchants, setMerchantLoading, merchants } = useMerchantStore()

    const navigate = useNavigate()
    const {
        setPhoneNumber, persistedMessages, phoneNumber, incomingMessageNotifications, setIncomingMessageNotifications,
        setRecentChatHistories, setFilteredRecentChatHistories, setMessagesIsSyncing, setMessages, setPersistedMessages
    } = useChatStore()
    const [openDrawer, setOpenDrawer] = useState(false);
    const { pathname } = useLocation()
    const [codeUrl, setCodeUrl] = useState((pathname).split('/').pop());

    const { dateStart, dateEnd } = useDataTableStore()
    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const onRowClicked = (row: Merchant) => {
        setMerchant(row)
        setShowDetailModal(true);
    };

    const updateWhatsappValid = async (id: string, phone: string, res: boolean) => {
        try {
            const response = await updateWhatsapp(id, res, token)
            if (response.data.success) {
                if (res) {
                    if (user.data.is_superadmin) {
                        window.open(`https://api.whatsapp.com/send?phone=${phone.replace("+", "")}`)
                    } 
                    // else {
                    //     navigate('/chat')
                    //     setPhoneNumber(phone.replace("+", ""))
                    // }
                }
            }
        } catch (error) {

        }
    }

    const redirectWhatsappValid = async (id: string, phone: string) => {
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            if (!response.data.result.exists) {
                updateWhatsappValid(id, phone, response.data.result.exists)
            } else {
                if (user.data.is_superadmin) {
                    window.open(`https://api.whatsapp.com/send?phone=${phone.replace("+", "")}`)
                } 
                // else {
                //     navigate('/chat')
                //     setPhoneNumber(phone.replace("+", ""))
                //     getChatMessages(phone.replace("+", ""), '')
                // }
            }
        } catch (error) {

        }
    }

    const redirectWhatsappNotValid = async (id: string, phone: string) => {
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            if (response.data.result.exists) {
                updateWhatsappValid(id, phone, response.data.result.exists)
            } else {
                Swal.fire({
                    icon: "error",
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.INVALID_NUMBER' }),
                    heightAuto: false,
                })
            }
        } catch (error) {

        }
    }

    const tableColumns: TableColumn<Merchant>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {

                return (
                    <>
                        <IconButton
                            aria-label={`more-${row.id}`}
                            id={`menu-anchor-${row.id}`}
                            onClick={() => handleClick(row.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id={`menu-${row.id}`}
                            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                            open={openMenuId === row.id}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            {
                                user.data.id === row.ownerId &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowAddReimbursementModal(true);
                                    setMerchant(row);
                                }}>
                                    <i className="fas fa-plus fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.REIMBURSEMENTS' })}</span>
                                </MenuItem>
                            }

                            <MenuItem>
                                <Link to={`${row.id}/pipelines/${multiPipelines[0]?.id}`} target="_blank" className="text-black w-100">
                                    <i className="fas fa-eye fs-4 me-4"></i>
                                    <span>Lihat {settings.pipeline_title}</span>
                                </Link>
                            </MenuItem>

                            <MenuItem>
                                <Link to={`meetings/${row.id}`} target="_blank" className="text-black w-100">
                                    <i className="fas fa-eye fs-4 me-4"></i>
                                    <span>Lihat {settings.meeting_title}</span>
                                </Link>
                            </MenuItem>

                            {
                                (user.data.is_superadmin || user.data.permission_merchant_update !== 'NONE') &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowUpdateModal(true);
                                    setMerchant(row);
                                }}>
                                    <i className="fas fa-edit fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                </MenuItem>
                            }

                            {
                                settings.merchant_phone_can_be_changed &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowUpdatePhoneModal(true);
                                    setMerchant(row);
                                }}>
                                    <i className="fas fa-edit fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_PHONE' })}</span>
                                </MenuItem>
                            }

                            {
                                (user.data.is_superadmin || user.data.permission_merchant_delete !== 'NONE') &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowDeleteModal(true);
                                    setMerchant(row);
                                }}>
                                    <i className="fas fa-trash fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                </MenuItem>
                            }

                            {
                                (user.data.is_superadmin) &&
                                <MenuItem onClick={() => {
                                    handleClose();
                                    setShowMergeModal(true);
                                    setMerchant(row);
                                }}>
                                    <i className="fas fa-object-group fs-4 me-4"></i>
                                    <span>{intl.formatMessage({ id: 'FORM.ACTION.MERGE_DATA' })}</span>
                                </MenuItem>
                            }
                        </Menu>
                    </>
                )
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }))?.omit
        },
        {
            id: 'name',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.name?.toLowerCase(),
            sortable: true,
            cell: row => {

                return (
                    <div className="d-flex flex-row gap-2">
                        <div>
                            {row.name}
                        </div>
                        <div>
                            <OverlayTrigger
                                // key={`labels-${recentChatHistory.chat_id}`}
                                placement='right'
                                overlay={<Tooltip id='tooltip-labels'>
                                    {row?.labels?.map((label, index) => (
                                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                            <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color || "black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                            </svg>
                                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {label.name}
                                            </span>
                                        </div>
                                    ))
                                    }
                                </Tooltip>}
                            >
                                <span
                                    id={`labels-${row.id}`}
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    {(row.labels?.length && row.labels.length > 0) ? (
                                        row.labels.length === 1 ? (
                                            <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color || "black" }} >
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                            </svg>
                                        ) : (
                                            <div className="ps-1" style={{ color: row.labels[1].color || "black" }}>
                                                <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                    <path fill={`${row.labels[0].color || "black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                    <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                </svg>
                                            </div>

                                        )
                                    ) : <></>}
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                )

            },
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }))?.omit
        },
        {
            id: 'pic',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.ownerName?.toLowerCase(),
            sortable: true,
            cell: row => row.ownerName,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }))?.omit
        },
        {
            id: 'city',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.cityName?.toLowerCase(),
            sortable: true,
            cell: row => row.cityName,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }))?.omit
        },
        {
            id: 'phone',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.phone,
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }))?.omit
        },
        {
            id: 'whatsapp',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.isWhatsappPhone,
            sortable: true,
            center: true,
            reorder: true,
            cell: row => {
                if (row.isWhatsappPhone) return <img alt='Banner' style={{ padding: "15px" }} onClick={() => redirectWhatsappValid(row.id, row.phone)} className='h-50px cursor-pointer' src={toAbsoluteUrl('/media/assets/checked.png')} />
                else return <img alt='Banner' style={{ padding: "15px" }} onClick={() => redirectWhatsappNotValid(row.id, row.phone)} className='h-50px cursor-pointer' src={toAbsoluteUrl('/media/assets/remove.png')} />
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }))?.omit
        },
        {
            id: 'email',
            name: 'Email',
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.email as string,
            sortable: true,
            reorder: true,
            cell: row => {
                if (!row.email) {
                    return <p>-</p>
                } else {
                    return <p className="mt-2">{row.email}</p>
                }
            },
            omit: manageColumns.find(item => item.name === 'Email')?.omit
        },
        {
            id: 'lastMeeting',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_MEETING' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.lastMeetEnd,
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_MEETING' }))?.omit
        },
        {
            id: 'lastPipeline',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_ADD_MERCHANT' }, { title: settings.pipeline_title }),
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.lastLeadCreatedTime,
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_ADD_MERCHANT' }, { title: settings.pipeline_title }))?.omit
        },
        {
            id: 'countPipeline',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: settings.pipeline_title }),
            style: { cursor: "pointer" },
            sortable: true,
            reorder: true,
            right: true,
            selector: row => row.leadsCount,
            cell: row => {
                return <Link to={`${row.id}/pipelines/${multiPipelines[0]?.id}`} target="_blank" className="text-black">
                    <span className="cursor-pointer text-primary">{row.leadsCount}</span>
                </Link>
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: settings.pipeline_title }))?.omit
        },
        {
            id: 'countMeeting',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: intl.formatMessage({ id: 'MENU.MEETINGS' }) }),
            style: { cursor: "pointer" },
            width: '200px',
            sortable: true,
            reorder: true,
            right: true,
            selector: row => row.meetingsCount,
            cell: row => {
                return <Link to={`meetings/${row.id}`} target="_blank" className="text-black">
                    <span className="cursor-pointer text-primary">{row.meetingsCount}</span>
                </Link>
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: intl.formatMessage({ id: 'MENU.MEETINGS' }) }))?.omit
        },
        {
            id: 'creatorName',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.creatorName?.toLowerCase(),
            sortable: true,
            cell: row => row.creatorName,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }))?.omit
        },
        {
            id: 'dateCreated',
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.dateCreated,
            sortable: true,
            cell: (row) => {
                return moment(row.dateCreated).format("DD MMM YYYY HH:mm")
            },
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }))?.omit
        },
    ];

    const tableColumnsWithCustomFields: TableColumn<Merchant>[] = [
        ...tableColumns,
        ...Object.keys(customFields).map((key, index) => {
            if (customFields[key].type === 'date') {
                return {
                    id: key,
                    width: "150px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    selector: (row: Merchant) => row.customFields && row.customFields[key] ? moment(row.customFields[key]).format('DD-MM-YYYY') : '-',
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit,
                    cell: (row: Merchant) => {
                        if (row.customFields && row.customFields[key]) {
                            return (
                                moment(row.customFields[key]).format("DD MMM YYYY")
                            )
                        } else {
                            return '-'
                        }
                    }
                };
            }
            if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                return {
                    id: key,
                    width: "70px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    center: true,
                    cell: (row: Merchant) => {
                        if (row.customFields && row.customFields[key]) {
                            return (
                                <span role="button" onClick={
                                    () => {
                                        setShowAttachmentCustomFieldModal(true);
                                        setMerchant(row);
                                        setKey(key);
                                        setUrl(row.customFields[key] as any);
                                    }
                                }>
                                    <i className="fas fs-2 fa-eye"></i>
                                </span>
                            )
                        } else {
                            return '-'
                        }
                    },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                }
            }
            if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                return {
                    id: key,
                    width: "70px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    center: true,
                    cell(row: Merchant) {
                        const fileUrl = row.customFields && row.customFields[key];
                        if (typeof fileUrl === "string") {
                            return fileUrl ? (
                                <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                >
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </a>
                            ) : '-';
                        } else {
                            return fileUrl ? (
                                <span role="button" onClick={() => {
                                    setShowAttachmentCustomFieldModal(true);
                                    setMerchant(row);
                                    setKey(key);
                                    setUrl(fileUrl as string[]);
                                }}>
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </span>
                            ) : '-';
                        }
                    },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                }
            }
            if (customFields[key].type === 'multiple') {
                return {
                    id: key,
                    width: "150px",
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    cell: (row: Merchant) => {
                        const values = row.customFields && row.customFields[key] && row.customFields[key].toString()
                        return values ? values.split(',').join(', ') : '-'
                    },
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                };
            }
            if (customFields[key].type === 'link') {
                return {
                    id: key,
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    grow: 2,
                    cell: (row: Merchant) => {
                        if (row.customFields && row.customFields[key]) {
                            return (
                                <a href={row.customFields[key] as string} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
                            )
                        } else return "-"
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                };
            }
            if (customFields[key].type === 'serial') {
                return {
                    id: key,
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    grow: 2,
                    cell: (row: Merchant) => {
                        const values = row.customFields && row.customFields[key];
                        return values ? (values as string[]).join('') : '-';
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                };
            }
            if (customFields[key].type === 'merchant') {
                return {
                    id: key,
                    name: customFields[key].name,
                    style: { cursor: "pointer" },
                    grow: 2,
                    cell: (row: Merchant) => {
                        return (row as any)[`custom_fields.${key}`] ? (row as any)[`custom_fields.${key}`] : "-"
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                };
            }
            if (customFields[key].type === 'link') {
                return {
                    name: customFields[key].name,
                    cell: (row: Merchant) => {
                        if (row.customFields && row.customFields[key]) {
                            return (
                                <a href={row.customFields[key] as string} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">{intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LINK' })}<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
                            )
                        } else return "-"
                    },
                    sortable: true,
                    reorder: true,
                    width: "150px",
                    omit: manageColumns.find(item => item.name === customFields[key].name)?.omit
                };
            }
            return {
                id: key,
                width: "150px",
                name: customFields[key].name,
                style: { cursor: "pointer" },
                selector: (row: Merchant) => {
                    // Check if row.customFields is defined and not null
                    const fieldValue = row.customFields && row.customFields[key];

                    // Check if fieldValue is an object and not null
                    const displayValue = fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
                        ? '-'
                        : fieldValue;

                    return (displayValue as string)?.toLowerCase() || '-';
                },
                allowOverflow: true,
                sortable: true,
                omit: manageColumns.find(item => item.name === customFields[key].name)?.omit,
                cell: (row: Merchant) => {
                    // Check if row.customFields is defined and not null
                    const fieldValue = row.customFields && row.customFields[key];

                    // Check if fieldValue is an object and not null
                    const displayValue = fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
                        ? '-'
                        : fieldValue;

                    return displayValue || '-';
                },
                reorder: true
            };
        }),
    ];

    if (user.data.company_id === companyId.esb) {
        const companyCodeIndex = tableColumnsWithCustomFields.findIndex(col => (col.name as string)?.toLowerCase() === "company code");
        const nameIndex = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }));
        companyCodeIndex !== -1 && sortColumnDatatable(tableColumnsWithCustomFields, companyCodeIndex, nameIndex);

        const companyNameIndex = tableColumnsWithCustomFields.findIndex(col => (col.name as string)?.toLowerCase() === "company name");
        const nameIndex2 = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }));
        companyNameIndex !== -1 && sortColumnDatatable(tableColumnsWithCustomFields, companyNameIndex, nameIndex2);
    }

    let actions: ReactNode[] = []
    if (user.data.is_allowed_export_excel) {
        actions.push(<DataTableExport source={"Merchant"} columns={manageColumns} dateStart={dateStart} dateEnd={dateEnd} user={user} />)
    }

    if (user.data.permission_merchant_create !== 'NONE') {
        actions.push(
            <DataTableImport source={"template-merchant"} columns={manageColumns} />,
            <AddMerchant />
        );
    }

    actions.push(<FilterMerchant manageColumns={manageColumns} setManageColumns={setManageColumns} />);

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token,
            },
        });
        return response.data.data;
    };

    const { data: merchantsData = [], error: merchantsError, isLoading: merchantsLoading } = useSWR(`${API_URL}simple-merchants`, fetcher)

    useEffect(() => {
        setSimpleMerchantLoading(merchantsLoading)
        setMerchantLoading(merchantsLoading)
        if (!merchantsLoading) {
            setMerchants(merchantsData)
            setSimpleMerchants(merchantsData)
        }
    }, [merchantsLoading]);

    useEffect(() => {
        setManageColumns(tableColumnsWithCustomFields);
    }, [customFields])

    useModalEffect({
        showModal: showAddReimbursementModal,
        setShowModal: setShowAddReimbursementModal,
        modalId: `add-reimbursement-modal`
    })

    useModalEffect({
        showModal: showUpdatePhoneModal,
        setShowModal: setShowUpdatePhoneModal,
        modalId: `update-phone-merchant-modal-${merchant?.id}`
    })

    useModalEffect({
        showModal: showUpdateModal,
        setShowModal: setShowUpdateModal,
        modalId: `update-merchant-modal-${merchant?.id}`
    })

    useModalEffect({
        showModal: showDeleteModal,
        setShowModal: setShowDeleteModal,
        modalId: `delete-merchant-modal-${merchant?.id}`
    })

    useModalEffect({
        showModal: showMergeModal,
        setShowModal: setShowMergeModal,
        modalId: `merge-merchant-modal-${merchant?.id}`
    })

    useModalEffect({
        showModal: showAttachmentCustomField,
        setShowModal: setShowAttachmentCustomFieldModal,
        modalId: `attachments-merchant-modal-${merchant?.id}`
    })

    useModalEffect({
        showModal: showDetailModal,
        setShowModal: setShowDetailModal,
        modalId: `detail-merchant-modal-${merchant?.id}`
    })

    useEffect(() => {
        if (codeUrl && multiMerchants.length > 0) {
            const data = multiMerchants.find(item => item.id === codeUrl)
            setSelectMultiMerchant(data!)
            setCustomFields(data?.customFields!);
        }
    }, [multiMerchants, codeUrl])

    useEffect(() => {
        if (codeUrl !== (pathname).split('/').pop()) {
            setCodeUrl((pathname).split('/').pop()!)
        }
    }, [pathname])

    return (
        <>
            <div className="d-flex justify-content-between mb-5">

                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark text-muted">{settings.merchant_title}</li>
                    <li className="breadcrumb-item text-dark">{selectMultiMerchant.name}</li>
                </ol>
            </div>
            <MasterDataTable
                tableKey={`merchants`}
                tableColumns={tableColumnsWithCustomFields}
                actions={actions}
                apiURL={`merchants${codeUrl ? `?merchantTemplateId=${codeUrl}` : ""}`}
                orderBy={'dateCreated'}
                order={'desc'}
                onRowClicked={onRowClicked}
            />

            {
                showAddReimbursementModal && <AddReimbursement merchant={merchant!} />
            }
            {
                showDetailModal && <DetailMerchant data={merchant!} redirectWhatsappValid={redirectWhatsappValid} redirectWhatsappNotValid={redirectWhatsappNotValid} />
            }
            {
                showUpdateModal && <UpdateMerchant merchant={merchant!} />
            }
            {
                showUpdatePhoneModal && <UpdatePhoneMerchant merchant={merchant!} />
            }
            {
                showDeleteModal && <DeleteMerchant merchant={merchant!} />
            }
            {
                showMergeModal && <MergeMerchant merchant={merchant!} column={tableColumnsWithCustomFields} />
            }
            {
                showAttachmentCustomField && <MerchantAttachments merchant={merchant!} title={customFields[key].name!} url={url} />
            }
        </>
    )
}

export { MasterMerchant }