import { FC, useEffect, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import { SelectOption } from "../../interfaces/SelectOption";
import axios from "axios";
import useSWR, { mutate } from "swr";
import useAccessToken from "../../hooks/AccessToken";
import useUser from "../../hooks/User";
import { User } from "../../interfaces/User";
import { DataTableSelect } from "../DataTable/DataTableSelect";
import { ExpenseCategory } from "../../interfaces/Reimbursement";
import { useIntl } from "react-intl";

const FilterReimbursement: FC = () => {
    const intl = useIntl()
    const statusData: SelectOption[] = [
        {
            value: "PENDING",
            label: intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PENDING' })
        },
        {
            value: "REJECTED",
            label: intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.REJECTED' })
        },
        {
            value: "APPROVED",
            label: intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.APPROVED' })
        },
        {
            value: "PAID_OFF",
            label: intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PAID_OFF' })
        }
    ]

    const { selectedFilters, setSelectedFilter, setSelectColumn } = useDataTableStore();
    const token = useAccessToken()
    const user = useUser()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [categoriesData, setCategoriesData] = useState<SelectOption[]>([]);

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: categories = [], error: categoriesError, isLoading: categoriesLoading } = useSWR(`${API_URL}reimbursement-categories`, fetcher)

    let selectConfigs = [
        {
            placeholder: intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_STATUS' }),
            tableKey: !user.data.is_superadmin ? "my-reimbursements" : "reimbursements",
            filterKey: 'approvalStatus',
            options: statusData,
            onChange: (values: string[]) => setSelectedFilter(!user.data.is_superadmin ? "my-reimbursements" : "reimbursements", 'approvalStatus', values)
        }
    ];

    if (user.data.is_superadmin) selectConfigs.push({
            placeholder: intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_USER' }),
            tableKey: "reimbursements",
            filterKey: 'userOwner',
            options: usersData,
            onChange: (values: string[]) => setSelectedFilter("reimbursements", 'userOwner', values)
        })

    selectConfigs.push({
        placeholder: intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_CATEGORY' }),
        tableKey: !user.data.is_superadmin ? "my-reimbursements" : "reimbursements",
        filterKey: 'reimbursementCategory',
        options: categoriesData,
        onChange: (values: string[]) => setSelectedFilter(!user.data.is_superadmin ? "my-reimbursements" : "reimbursements", 'reimbursementCategory', values)
    })

    const handleResetFilter = () => {
        // Reset Data 
        setSelectedFilter(!user.data.is_superadmin ? "my-reimbursements" : "reimbursements", 'approvalStatus', [])
        setSelectedFilter("reimbursements", 'userOwner', [])
        setSelectedFilter(!user.data.is_superadmin ? "my-reimbursements" : "reimbursements", 'reimbursementCategory', [])
    }

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));

            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [users]);

    useEffect(() => {
        if (!categoriesLoading) {
            const categoriesData = categories.map((category: ExpenseCategory) => ({
                value: category.id,
                label: category.name
            }));

            // Prepend the two objects to the salesData array
            setCategoriesData(categoriesData);
        }
    }, [categories]);

    return (
        <div>
            {
                (selectedFilters['my-reimbursements']?.approvalStatus?.length > 0 || selectedFilters.reimbursements?.approvalStatus?.length > 0 || selectedFilters.reimbursements?.userOwner?.length > 0 || selectedFilters['my-reimbursements']?.reimbursementCategory?.length > 0 || selectedFilters.reimbursements?.reimbursementCategory?.length > 0) ?
                    <>
                        <button className="btn mt-3 me-3" style={{ backgroundColor: "#f1faff", border: "2px solid #009EF7", padding: "calc(0.75rem) calc(1.5rem)" }} id="drawer_filter_reimbursement">
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 svg-icon-primary m-0' />
                        </button>
                        <button type="button"
                            className="btn btn-white mt-3 text-primary"
                            onClick={() => { handleResetFilter() }}
                        >
                            Reset Filter
                        </button>
                    </>
                    :
                    <button type="button"
                        className="btn btn-light mt-3"
                        id="drawer_filter_reimbursement"
                    >
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
                    </button>
            }

            <div
                id="kt_drawer_filter_reimbursement"
                className="bg-white"
                data-kt-drawer="true"
                data-kt-drawer-activate="true"
                data-kt-drawer-overlay="true"
                data-kt-drawer-toggle="#drawer_filter_reimbursement"
                data-kt-drawer-close="#kt_drawer_example_basic_close"
                data-kt-drawer-width="{default:'300px', 'md': '400px'}"
            >
                <div className="card w-100 rounded-0">
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">Filter Reimbursement</span>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_example_basic_close">
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body hover-scroll-overlay-y">
                        <div style={{ fontWeight: "600" }}>Filter Data</div>
                        <div className="separator separator-dashed my-3"></div>
                        {
                            selectConfigs.map((config, index) => (
                                <DataTableSelect
                                    placeholder={config.placeholder}
                                    tableKey={config.tableKey}
                                    filterKey={config.filterKey}
                                    key={index}
                                    options={config.options}
                                    onChange={config.onChange}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export { FilterReimbursement }