import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl';
import { Dropdown } from "rsuite";
import { KTSVG } from '../../../_metronic/helpers';
import { FileUploader } from 'react-drag-drop-files';
import { useFormik } from 'formik';
import DataExportTemplateEmail from './DataExportTemplateEmail';
import { createDetailGroupContactEmail, updateDetailGroupContactEmail } from '../../../api/EmailCRUD';
import { useEmailStore } from '../../../stores/EmailStore';
import * as ExcelJS from 'exceljs';
import { off } from 'process';
import Swal from 'sweetalert2';
import { useDataTableStore } from '../../../stores/DataTableStore';
import { useCustomFieldStore } from '../../../stores/FieldEmailStore';
import { IDetailGroupEmail, IUpdateDetailGroupEmail } from '../../../interfaces/Email/InputEmailTypes';

interface DetailImportGroupEmailProps {
    source: string
}

const DetailImportGroupEmail = ({ source }: DetailImportGroupEmailProps) => {
    const intl = useIntl();
    const { tablesData, setTablesData } = useDataTableStore();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [typeAction, setTypeAction] = useState<"insert" | "update">("insert")
    const [listErrorInsert, setListErrorInsert] = useState<any>([])
    const [listErrorUpdate, setListErrorUpdate] = useState<any>([])
    const [listData, setListData] = useState<any>([])
    const [indexUpload, setIndexUpload] = useState(0);
    const { idDetailGroupEmail } = useEmailStore()
    const { customFieldStore } = useCustomFieldStore()

    let dataSuccess: any[] = []
    let dataError: any[] = []

    const renderButton = (props: any, ref: any) => {
        return (
            <button {...props} ref={ref} type="button" className={`btn btn-light-success mt-3`}>
                <i className="fas fa-file-download fs-2 me-2"></i> {intl.formatMessage({ id: 'FORM.LABEL.IMPORT_EXCEL' })}
            </button>
        );
    };

    const formik = useFormik({
        initialValues: {
            data: ''
        },
        onSubmit: async (values, { setSubmitting }) => {
            dataError = [];
            setSubmitting(true);
            for (let i = 0; i < listData.length; i++) {
                try {
                    setIndexUpload(i + 1);
                    await handleCheckExcel(listData[i]);
                } catch (error: any) {
                    if (error.response.data) { // cek di bagian sini apakah useless kode apakah tidak
                        let mergeError = {
                            ...listData[i],
                            error: error.response.data.message,
                        };
                        dataError.push(mergeError);
                    } else {
                        let mergeError = { ...listData[i], error: error.message };
                        dataError.push(mergeError);
                    }
                }
            }

            if (typeAction === 'insert') setListErrorInsert(dataError)
            else if (typeAction === 'update') setListErrorUpdate(dataError)
            setListData([])
            setSubmitting(false);

            if (source === 'template-detail-group-email') {
                if (typeAction === 'insert') {
                    setTablesData('detailGroupEmail', ([...dataSuccess, ...tablesData.detailGroupEmail]))
                }

                else if (typeAction === 'update') {
                    setTablesData('detailGroupEmail', tablesData.detailGroupEmail.map(item => {
                        let dataSame = dataSuccess.find((data) => data.id === item.id)
                        const filterDataCustomField = dataSame.dataCustomField.map((data: any) => {
                            return {
                                ...data,
                            }
                        })
                        if (dataSame) {
                            return {
                                ...item,
                                name: dataSame.name,
                                email: dataSame.email,
                                dataCustomField: filterDataCustomField

                            }
                        } else {
                            return item
                        }
                    }))
                }
            }

            if (dataSuccess.length > 0) {
                Swal.fire({
                    icon: 'success',
                    title: intl.formatMessage({ id: 'ALERT.TITLE.SUCCESS' }),
                    html: `<b>${dataSuccess.length} Data</b> ${typeAction === 'insert'
                        ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_SUCCESS' })
                        : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_SUCCESS' })
                        }.
                    <br>
                    ${dataError.length > 0
                            ? `<b style="color: red;">${dataError.length} Data </b>${typeAction === 'insert'
                                ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_FAILED' })
                                : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_FAILED' })
                            }`
                            : ''
                        }
                    `,
                    heightAuto: false,
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
                    html: `<b>${dataError.length} Data</b> ${typeAction === 'insert' ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_FAILED' }) : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_FAILED' })}.`,
                    heightAuto: false,
                });
            }

            if (dataError.length === 0) {
                closeModalRef.current?.click();
            }
        }
    })

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();
            setListData([])
            dataSuccess = []
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const handleCheckExcel = async (data: any) => {
        const rowErrors: string[] = [];
        if (source === 'template-detail-group-email') {
            if (typeAction === 'insert') {

                customFieldStore && Object.keys(customFieldStore).length > 0 && Object.keys(customFieldStore).forEach((key) => {
                    const type = customFieldStore[key].required
                    const value = data[key];
                    if (type && !value) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldStore[key].name }));
                })
                if (!data.name) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }));
                if (!data.email) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Email' }));
            } else if (typeAction === 'update') {
                customFieldStore && Object.keys(customFieldStore).length > 0 && Object.keys(customFieldStore).forEach((key) => {
                    const type = customFieldStore[key].required
                    const value = data[key];
                    if (type && !value) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldStore[key].name }));
                })
                if (!data.name) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }));
                if (!data.email) rowErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Email' }));
            }
        }

        if (rowErrors.length === 0) {
            if (source === 'template-detail-group-email' && typeAction === 'insert') await handleInsertDetailEmail(data);
            else if (source === 'template-detail-group-email' && typeAction === 'update') await handleUpdateDetailEmail(data);
        } else {
            dataError.push({
                ...data,
                error: rowErrors.join(', ')
            })
        }
    }

    const handleChangeDetailEmail = (e: any) => {
        setListData([]);
        var check_excel = true;
        var empty_excel = false;
        var success = false;
        const file = e;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer: any = reader.result;
            wb.xlsx.load(buffer).then((workbook: any) => {
                const worksheets = workbook.worksheets;
                const mainSheet = worksheets.find((sheet: any) =>
                    sheet.name.toLowerCase().includes('sheet1')
                );
                const infoSheet = worksheets.find((sheet: any) =>
                    sheet.name.toLowerCase().includes('info')
                );

                if (!mainSheet || !infoSheet) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
                        text: 'Required sheets not found',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }
                const newData: any = [];
                var nameWorksheet = mainSheet
                var nameWorksheetInfo = infoSheet
                if (nameWorksheetInfo !== undefined) {
                    nameWorksheetInfo.eachRow(async (columnInfo: any, rowIndexInfo: number) => {
                        if (rowIndexInfo > 3) {
                            if (columnInfo.values[2] === `Template Insert Email` || columnInfo.values[2] === `Template Error Insert Email`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            const customFielsStartAt = 3
                                            newData.push({
                                                name: rowData[1],
                                                email: typeof rowData[2] === 'object' ? rowData[2].text : rowData[2],
                                                ...(customFieldStore && Object.keys(customFieldStore).length > 0 ? Object.fromEntries(Object.keys(customFieldStore).map((key, index) => [
                                                    key,
                                                    typeof rowData[index + customFielsStartAt] === 'object' ? rowData[index + customFielsStartAt].text : rowData[index + customFielsStartAt]
                                                ])) : {})
                                            })

                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                            } else if (columnInfo.values[2] === `Template Update Email` || columnInfo.values[2] === `Template Error Update Email`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            const customFielsStartAt = 4
                                            let id = atob(rowData[1])
                                            newData.push({
                                                id: id.slice(0, 3) + id.slice(6),
                                                name: rowData[2],
                                                email: typeof rowData[3] === 'object' ? rowData[3].text : rowData[3],
                                                // filter
                                                ...(customFieldStore && Object.keys(customFieldStore).length > 0 ? Object.fromEntries(Object.keys(customFieldStore).map((key, index) => [
                                                    key,
                                                    typeof rowData[index + customFielsStartAt] === 'object' ? rowData[index + customFielsStartAt].text : rowData[index + customFielsStartAt]
                                                ])) : {})
                                            })
                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                            } else {
                                check_excel = false
                                return false
                            }
                        }
                    })
                } else {
                    check_excel = false
                }

                if (empty_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
                        confirmButtonText: 'Ok'
                    });
                }

                if (!check_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
                        confirmButtonText: 'Ok'
                    });
                }
                if (success) setListData(newData);
            });
        };
    }

    const handleInsertDetailEmail = async (data: any) => {
        try {
            const dataCustomField = Object.keys(data)
                .filter(key => key !== 'name' && key !== 'email')
                .map(key => ({
                    [key]: data[key] || null
                }));

            const newData: IDetailGroupEmail = {
                name: data.name,
                email: data.email,
                dataCustomField: dataCustomField,
                idGroupContact: idDetailGroupEmail
            };

            const response = await createDetailGroupContactEmail(newData);
            if (response.success) {
                dataSuccess.push(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleUpdateDetailEmail = async (data: any) => {
        try {
            const dataCustomField = Object.keys(data)
                .filter(key => key !== 'name' && key !== 'email' && key !== 'id') // memfilterkan key yang bukan name dan email
                .map(key => ({
                    [key]: data[key] || null
                }));

            const newData: IUpdateDetailGroupEmail = {
                id: data.id,
                name: data.name,
                email: data.email,
                dataCustomField: dataCustomField,
            };

            const response = await updateDetailGroupContactEmail(newData);
            if (response.success) {
                dataSuccess.push(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };



    return (
        <>
            {
                source === 'template-detail-group-email' &&
                <Dropdown renderToggle={(props, ref) => renderButton(props, ref)} noCaret placement="bottomEnd">
                    <Dropdown.Item data-bs-toggle="modal" data-bs-target={`#import-excel-${source}-modal`} onClick={() => setTypeAction("insert")}>{intl.formatMessage({ id: 'FORM.ACTION.ENTER_DATA' })}</Dropdown.Item>
                    <Dropdown.Item data-bs-toggle="modal" data-bs-target={`#import-excel-${source}-modal`} onClick={() => setTypeAction("update")}>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_DATA' })}</Dropdown.Item>
                </Dropdown>
            }


            <div className="modal fade" tabIndex={-1} id={`import-excel-${source}-modal`} ref={modalRef} >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.LABEL.IMPORT_EXCEL' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        {
                            (source === 'template-detail-group-email') ?
                                <form onSubmit={formik.handleSubmit} noValidate>
                                    <div className="modal-body" style={{ height: "300px" }}>
                                        <DataExportTemplateEmail source={source} typeAction={typeAction} />

                                        {
                                            listErrorInsert.length > 0 && typeAction === 'insert' &&
                                            <DataExportTemplateEmail source={source} typeAction={typeAction} type='error' param={{ listErrorInsert }} />
                                        }

                                        {
                                            listErrorUpdate.length > 0 && typeAction === 'update' &&
                                            <DataExportTemplateEmail source={source} typeAction={typeAction} type='error' param={{ listErrorUpdate }} />
                                        }

                                        <FileUploader
                                            fileOrFiles={listData.length === 0 ? null : listData}
                                            classes="h-50"
                                            handleChange={handleChangeDetailEmail}
                                            name="file"
                                            types={["XLSX", "XLS", "CSV"]}
                                        />

                                        {
                                            listData.length > 0 &&
                                            <div style={{ padding: "10px" }}>{listData.length} {intl.formatMessage({ id: 'FORM.LABEL.DATA_UPLOAD' })}</div>
                                        }
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-light"
                                            data-bs-dismiss="modal"
                                            ref={closeModalRef}
                                        >
                                            {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                                        </button>
                                        <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? "disabled" : ""}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                                            <span className="indicator-label">
                                                {intl.formatMessage({ id: 'FORM.ACTION.UPLOAD' })}
                                            </span>
                                            {
                                                formik.isSubmitting && <span className="indicator-progress">
                                                    Loading... &nbsp;
                                                    {indexUpload + " / " + listData.length}
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            }
                                        </button>
                                    </div>
                                </form>
                                :
                                <div className="modal-body" style={{ height: "200px" }}>
                                    <div className="loading-overlay">
                                        <div className="loader"></div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailImportGroupEmail