import { FC, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { UpdateAttendance } from "../../../components/Attendance/UpdateAttendance";
import { MyAttendance } from "../../../interfaces/Attendance";
import moment from "moment";
import { AttendanceNote } from "../../../components/Attendance/AttendanceNote";
import { DateRangePicker } from "rsuite";
import { mutate } from "swr";
import { AttendanceAttachments } from "../../../components/Attendance/AttendanceAttachments";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import 'moment-duration-format';
import { ApproveAttendance } from "../../../components/Attendance/ApproveAttendance";
import { calculateHaversineDistance } from "../../../functions/general";
import { MapDistanceAttendance } from "../../../components/Attendance/MapDistanceAttendance";
import startOfMonth from 'date-fns/startOfMonth';
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import useUser from "../../../hooks/User";
import { AttendanceInfo } from "../../../components/Attendance/AttendanceInfo";
import { useIntl } from "react-intl";
import usePredefinedRanges from "../../../hooks/PredefinedRanges";
import useCalendarLocale from "../../../hooks/CalendarLocale";

const ReportAttendanceRecap: FC = () => {

    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [attendance, setAttendance] = useState<MyAttendance>();

    const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [updateType, setUpdateType] = useState<string>("");
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [fileAttachment, setFileAttachment] = useState<string>('');

    const [showMapModal, setShowMapModal] = useState<boolean>(false);
    const [latitudeMap, setLatitudeMap] = useState<number>();
    const [longitudeMap, setLongitudeMap] = useState<number>();
    const [difference, setDifference] = useState<number>();

    const API_URL = process.env.REACT_APP_API_URL
    const today = new Date()

    const [startDate, setStartDate] = useState<Date>(startOfMonth(today));
    const [endDate, setEndDate] = useState<Date>(today);
    const [loading, setLoading] = useState<boolean>(true);

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const user = useUser()

    const intl = useIntl();

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();

    const tableColumns: TableColumn<MyAttendance>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }),
            width: '150px',
            selector: row => row.userName?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.userName
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.START_HOUR" }),
            width: '200px',
            selector: row => row.dateClockIn ?? "-",
            sortable: true,
            cell: row => row.dateClockIn ?
                <span className="">{moment(row.dateClockIn).format('DD MMM YYYY HH:mm')}</span>
            :
            '-'
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.END_HOUR" }),
            width: '200px',
            selector: row => row.dateClockOut ?? "-",
            sortable: true,
            cell: row => row.dateClockOut?
                <span className="">{moment(row.dateClockOut).format('DD MMM YYYY HH:mm')}</span>
            :
            '-'
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN_NOTES" }),
            center: true,
            width: '200px',
            // selector: row => row ?? "-",
            cell: row => (
                <>
                    { row.dateClockInDifference && row.dateClockInDifference < 0 ? 
                        <Tooltip
                            title={`${intl.formatMessage({ id: "TOOLTIP.LATE" })} ` + (moment.duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'minutes'), 'minutes').as('minutes') !== 0 ? moment.duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'minutes'), 'minutes').format(`h [${intl.formatMessage({id: "HOURS"})}] m [${intl.formatMessage({id: "MINUTES"})}]`) : moment.duration(moment(row.dateClockIn).diff(moment(row.dateTemplateClockIn), 'seconds'), 'seconds').format(`h [${intl.formatMessage({id: "HOURS"})}] m [${intl.formatMessage({id: "MINUTES"})}] s [detik]`))}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                            style={{cursor: "default"}}
                            >
                            <div className={"btn btn-icon btn-flush btn-icon-danger w-auto"} style={{cursor: "default"}}><i className="fas fa-clock fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush " + (row.dateClockIn ? "btn-icon-primary" : "btn-icon-muted")} style={{cursor: "default"}}><i className="fas fa-clock fs-2"></i></div>
                    }
                    
                    { row.latitudeClockIn && row.longitudeClockIn ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.LOCATION" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush " + (row.positionClockInDifference && row.positionClockInDifference > (row.templateRadiusClockIn?? 0) || (!row.positionClockInDifference && (row.templateLatitudeClockIn || row.templateLatitudeClockOut) && (row.templateLongitudeClockIn || row.templateLongitudeClockOut) && (calculateHaversineDistance(row.latitudeClockIn, row.longitudeClockIn, row.templateLatitudeClockIn ?? row.templateLatitudeClockOut, row.templateLongitudeClockIn ?? row.templateLongitudeClockOut) > (row.templateRadiusClockIn?? 0))) ? "btn-icon-danger" : "btn-icon-primary")}
                                onClick={() => {
                                    setShowMapModal(true);
                                    setLatitudeMap(row.latitudeClockIn);
                                    setLongitudeMap(row.longitudeClockIn);
                                    setDifference(!row.positionClockInDifference && (row.templateLatitudeClockIn || row.templateLatitudeClockOut) && (row.templateLongitudeClockIn || row.templateLongitudeClockOut) ? calculateHaversineDistance(row.latitudeClockIn, row.longitudeClockIn, row.templateLatitudeClockIn ?? row.templateLatitudeClockOut, row.templateLongitudeClockIn ?? row.templateLongitudeClockOut) : row.positionClockInDifference);
                                    setAttendance(row);
                                }} style={{cursor: "pointer"}}><i className="fas fa-map-marker-alt fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-map-marker-alt fs-2"></i></div>
                    }

                    { row.imageClockIn ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PHOTO" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush w-auto " + ( row.imageClockIn && row.isUnrecognizedFaceClockIn ? 'btn-icon-danger' : 'btn-icon-primary')} style={{cursor: "pointer"}}
                                onClick={() => {
                                    if (row.imageClockIn){
                                        setShowAttachmentModal(true);
                                        setFileAttachment(row.imageClockIn);
                                        setAttendance(row);
                                        setUpdateType("clockIn")
                                    }
                                }}><i className="fas fa-eye fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-eye fs-2"></i></div>
                    }

                    { row.descriptionClockIn ? 
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-primary w-auto"} style={{cursor: "pointer"}} onClick={() => {
                                if (row.descriptionClockIn){
                                    setAttendance(row);
                                    setNote(row.descriptionClockIn);
                                    setShowNoteModal(true);
                                    setUpdateType("clockIn")
                                }
                            }}><i className="fas fa-sticky-note fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-sticky-note fs-2"></i></div>
                    }
                    {/* <div className={"btn btn-icon btn-flush " + ( row.imageClockIn && row.isUnrecognizedFaceClockIn ? 'btn-icon-danger' : row.imageClockIn ? 'btn-icon-primary' : 'btn-icon-muted')} style={row.imageClockIn? {} : {cursor: "default"}}
                        onClick={() => {
                            if (row.imageClockIn){
                                setShowAttachmentModal(true);
                                setFileAttachment(row.imageClockIn);
                                setAttendance(row);
                                setUpdateType("clockIn")
                            }
                        }}><i className="fas fa-eye fs-2"></i></div>
                    <div className={"btn btn-icon btn-flush " + ( row.descriptionClockIn ? 'btn-icon-primary' : 'btn-icon-muted')} style={row.descriptionClockIn? {} : {cursor: "default"}}
                        onClick={() => {
                            if (row.descriptionClockIn){
                                setAttendance(row);
                                setNote(row.descriptionClockIn);
                                setShowNoteModal(true);
                                setUpdateType("clockIn")
                            }
                        }}><i className="fas fa-sticky-note fs-2"></i></div> */}
                </>
            )
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT_NOTES" }),
            center: true,
            width: '200px',
            // selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => (
                <>
                    { row.dateClockOutDifference && row.dateClockOutDifference < 0 ? 
                        <Tooltip
                            title={"Keluar lebih awal " + (moment.duration(moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'minutes'), 'minutes').as('minutes') !== 0 ? moment.duration(moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'minutes'), 'minutes').format("h [jam] m [menit]") : moment.duration(moment(row.dateTemplateClockOut).diff(moment(row.dateClockOut), 'seconds'), 'seconds').format("h [jam] m [menit] s [detik]"))}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                            style={{cursor: "default"}}
                            >
                            <div className={"btn btn-icon btn-flush btn-icon-danger w-auto"} style={{cursor: "default"}}><i className="fas fa-clock fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush " + (row.dateClockOut ? "btn-icon-primary" : "btn-icon-muted")} style={{cursor: "default"}}><i className="fas fa-clock fs-2"></i></div>
                    }
                    
                    { row.latitudeClockOut && row.longitudeClockOut ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.LOCATION" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush " + (row.positionClockOutDifference && row.positionClockOutDifference > (row.templateRadiusClockOut?? 0) || (!row.positionClockOutDifference && (row.templateLatitudeClockIn || row.templateLatitudeClockOut) && (row.templateLongitudeClockIn || row.templateLongitudeClockOut) && (calculateHaversineDistance(row.latitudeClockOut, row.longitudeClockOut, row.templateLatitudeClockOut ?? row.templateLatitudeClockIn, row.templateLongitudeClockOut ?? row.templateLongitudeClockIn) > (row.templateRadiusClockOut?? 0))) ? "btn-icon-danger" : "btn-icon-primary")}
                                onClick={() => {
                                    setShowMapModal(true);
                                    setLatitudeMap(row.latitudeClockOut);
                                    setLongitudeMap(row.longitudeClockOut);
                                    setDifference(!row.positionClockOutDifference && (row.templateLatitudeClockIn || row.templateLatitudeClockOut) && (row.templateLongitudeClockIn || row.templateLongitudeClockOut) ? calculateHaversineDistance(row.latitudeClockOut, row.longitudeClockOut, row.templateLatitudeClockOut ?? row.templateLatitudeClockIn, row.templateLongitudeClockOut ?? row.templateLongitudeClockIn) : row.positionClockOutDifference);
                                    setAttendance(row);
                                }} style={{cursor: "pointer"}}><i className="fas fa-map-marker-alt fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-map-marker-alt fs-2"></i></div>
                    }

                    { row.imageClockOut ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PHOTO" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush w-auto " + ( row.imageClockOut && row.isUnrecognizedFaceClockOut ? 'btn-icon-danger' : 'btn-icon-primary')} style={{cursor: "pointer"}}
                                onClick={() => {
                                    if (row.imageClockOut){
                                        setShowAttachmentModal(true);
                                        setFileAttachment(row.imageClockOut);
                                        setAttendance(row);
                                        setUpdateType("clockOut")
                                    }
                                }}><i className="fas fa-eye fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-eye fs-2"></i></div>
                    }

                    { row.descriptionClockOut ? 
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-primary w-auto"} style={{cursor: "pointer"}} onClick={() => {
                                if (row.descriptionClockOut){
                                    setAttendance(row);
                                    setNote(row.descriptionClockOut);
                                    setShowNoteModal(true);
                                    setUpdateType("clockOut")
                                }
                            }}><i className="fas fa-sticky-note fs-2"></i></div>
                        </Tooltip>
                    : 
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{cursor: "default"}}><i className="fas fa-sticky-note fs-2"></i></div>
                    }
                    {/* <div className={"btn btn-icon btn-flush " + ( row.imageClockOut && row.isUnrecognizedFaceClockOut ? 'btn-icon-danger' : row.imageClockOut ? 'btn-icon-primary' : 'btn-icon-muted')} style={row.imageClockOut? {} : {cursor: "default"}}
                        onClick={() => {
                            if (row.imageClockOut){
                                setShowAttachmentModal(true);
                                setFileAttachment(row.imageClockOut);
                                setAttendance(row);
                                setUpdateType("clockOut")
                            }
                        }}><i className="fas fa-eye fs-2"></i></div>
                    <div className={"btn btn-icon btn-flush " + ( row.descriptionClockOut ? 'btn-icon-primary' : 'btn-icon-muted')} style={row.descriptionClockOut? {} : {cursor: "default"}}
                        onClick={() => {
                            if (row.descriptionClockOut){
                                setAttendance(row);
                                setNote(row.descriptionClockOut);
                                setShowNoteModal(true);
                                setUpdateType("clockOut")
                            }
                        }}><i className="fas fa-sticky-note fs-2"></i></div> */}
                </>
            )
        },
    ];

    useEffect(() => {
        if (showNoteModal && attendance && note && updateType) {
            const modalElement = document.getElementById(`note-attendance-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowNoteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showNoteModal, attendance, note, updateType, setShowNoteModal]);

    useEffect(() => {
        if (showUpdateModal && attendance && updateType) {
            const modalElement = document.getElementById(`update-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, attendance, updateType, setShowUpdateModal]);

    
    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}all-user-attendances`);
        }
    }, [startDate, endDate]);

    useEffect(() => {
      const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (showAttachmentModal && fileAttachment && attendance && updateType) {
            const modalElement = document.getElementById(`attachments-attendance-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, fileAttachment, attendance, updateType, setShowAttachmentModal]);

    useEffect(() => {
        if (showApproveModal && attendance && updateType) {
            const modalElement = document.getElementById(`approve-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowApproveModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showApproveModal, attendance, updateType, setShowApproveModal]);

    useEffect(() => {
        if (showMapModal && attendance) {
            const modalElement = document.getElementById(`map-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowMapModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showMapModal, attendance, setShowMapModal]);

    let actions = [
        <AttendanceInfo />
    ];
    if (user.data.is_allowed_export_excel) {
        actions.push(<DataTableExport source={"Attendance"} user={user!} dateStart={moment(startDate).format('DD MMM yyyy')} dateEnd={moment(endDate).format('DD MMM yyyy')} columns={[]}/>)
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <div className="text-gray-900 fs-2x fw-bold">
                    <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                        <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.ATTENDANCE_RECAP' })}</li>
                    </ol>
                </div>
                <DateRangePicker
                    cleanable={false}
                    character=" - "
                    format="dd MMM yyyy"
                    locale={calendarLocale}
                    value={[startDate, endDate]}
                    ranges={predefinedRanges}
                    placement="bottomEnd"
                    shouldDisableDate={(date) => {
                        return date > today;
                    }}
                    onChange={(value) => {
                        if (value && value.length === 2) {
                            setStartDate(value[0]);
                            setEndDate(value[1]);
                        }
                    }}
                    showOneCalendar={isMobile}
                    disabled={loading}
                    isoWeek={true}
                /> 
            </div>
            <MasterDataTable
                tableKey="all-user-attendances"
                tableColumns={tableColumns}
                apiURL="all-user-attendances"
                dateStart={moment(startDate).format('YYYY-MM-DD')}
                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                setLoading={setLoading}
                orderBy={1}
                order="asc"
                actions={actions}
            />
        
            {
                showNoteModal && <AttendanceNote attendance={attendance!} note={note!} updateType={updateType!}/>
            }
            {
                showUpdateModal && <UpdateAttendance attendance={attendance!} updateType={updateType!}/>
            }
            {
                showApproveModal && <ApproveAttendance attendance={attendance!} updateType={updateType!}/>
            }
            {
                showAttachmentModal && <AttendanceAttachments image={fileAttachment!} attendance={attendance!} updateType={updateType!}/>
            }
            {
                showMapModal && <MapDistanceAttendance  latitude={latitudeMap!} longitude={longitudeMap!} attendance={attendance!} difference={difference!}/>
            }

        </>
    )
}

export { ReportAttendanceRecap }