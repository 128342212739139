import { FC, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { Target } from "../../../interfaces/Target";
import moment from "moment";
import { customNumberFormat } from "../../../functions/general";
import { AddTarget } from "../../../components/Targets/AddTarget";
import { UpdateTarget } from "../../../components/Targets/UpdateTarget";
import { startOfMonth, endOfYear, addYears } from "date-fns";
import { DateRangePicker } from 'rsuite';
import { useSettingsStore } from "../../../stores/SettingsStore";
import { FilterTarget } from "../../../components/Targets/FilterTarget";
import useCalendarLocale from "../../../hooks/CalendarLocale";
import useCompactPredefinedRanges from "../../../hooks/CompactPredefinedRanges";
import { mutate } from "swr";
import useUser from "../../../hooks/User";
import { UserScope } from "../../../interfaces/UserScope";
import { UserScopeModal, defaultScope } from "../../../components/DataTable/UserScopeModal";
import { useIntl } from "react-intl";

export declare type ValueType = [Date?, Date?];
export declare type DateRange = [Date, Date];

export interface TargetType {
    label: React.ReactNode;
    value: string;
}

const defaultTargetScope = (permission: string): UserScope => {
    switch (permission) {
        case 'SELF':
            return UserScope.Self
        case 'WHOLE TEAM EXCEPT SELF':
            return UserScope.WholeTeam
        case 'WHOLE TEAM':
            return UserScope.WholeTeam
        case 'TEAM EXCEPT SELF':
            return UserScope.Team
        case 'TEAM':
            return UserScope.Team
        case 'ALL':
            return UserScope.All
        default:
            return UserScope.Self
    }
}

const today = new Date()

const MasterTargets: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const { settings } = useSettingsStore()

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [target, setTarget] = useState<Target>();
    const [startDate, setStartDate] = useState<Date>(startOfMonth(today));
    const [endDate, setEndDate] = useState<Date>(endOfYear(addYears(today, 2)));
    const [loading, setLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [targetType, setTargetType] = useState<string>("PIPELINE SUCCESS NOMINAL")
    const [openDrawer, setOpenDrawer] = useState(false);

    const predefinedRanges = useCompactPredefinedRanges();
    const calendarLocale = useCalendarLocale();
    const user = useUser()
    const [user_id, setUser_id] = useState<string>(user.data.id);
    const [user_name, setUser_name] = useState<string>(user.data.name);
    const [scope, setScope] = useState<UserScope>(defaultTargetScope(user.data.permission_user_target_set));

    const intl = useIntl()

    const allMonthYear = (start: Date, end: Date) => {
        let startDate = moment(start);
        let endDate = moment(end);

        let dates = [];
        startDate.subtract(1, "month"); //Substract one month to exclude endDate itself
        endDate.subtract(1, "month"); //Substract one month to exclude endDate itself

        var month = moment(startDate); //clone the startDate
        while (month < endDate) {
            month.add(1, "month");
            dates.push(month.format('YYYY-MM-DD'));
        }

        return dates
    }
    const [customFields, setCustomFields] = useState<Array<string>>(allMonthYear(startOfMonth(today), endOfYear(addYears(today, 2))))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    const tableColumns: TableColumn<Target>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            selector: row => row.name ?? row.userName,
            sortable: true,
            width: '150px',
            id: 'fixedLeft',
            cell(row) {
                return (
                    <div title={row.name ?? row.userName}>{row.name ?? row.userName}</div>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' }),
            selector: row => row.role_name ?? row.roleName,
            sortable: true,
            width: '100px',
            id: 'fixedLeft2',
            cell(row) {
                return (
                    <div title={ row.role_name ?? row.roleName}>{ row.role_name ?? row.roleName}</div>
                )
            }
        },
    ];

    const tableColumnsWithCustomFields: TableColumn<Target>[] = [
        ...tableColumns,
        ...Object.keys(customFields).map((field, index) => {
            let style = {}
            if (moment(customFields[index]).isAfter(moment(), 'month') || moment(customFields[index]).isSame(moment(), 'month')) {
                style = {
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#E4E6EF !important',
                    }
                }
            }

            return {
                name: moment(customFields[index]).format('MMM YYYY'),
                selector: (row: Target) => {
                    // Check if row is defined and not null
                    const fieldValue = row.targets && row.targets[index] && ((typeof row.targets[index].value === 'number' && row.targets[index].value >= 0) || (typeof row.targets[index] === 'number' && row.targets[index]));
                    return fieldValue ? (row.targets[index].value ?? row.targets[index]) : 0;
                },
                allowOverflow: false,
                width: 'auto',
                sortable: true,
                right: true,
                cell: (row: Target) => {
                    // Check if row is defined and not null
                    const fieldValue = row.targets && row.targets[index] && ((typeof row.targets[index].value === 'number' && row.targets[index].value >= 0) || (typeof row.targets[index] === 'number' && row.targets[index]));
                    return fieldValue ? customNumberFormat(row.targets[index].value ?? row.targets[index]) : '-';
                },
                style: style,
                conditionalCellStyles: [
                    {
                        when: (row: Target) => row.targets[index]?.isSet === true,
                        style: {
                            backgroundColor: '#E8FFF3'
                        },
                    },
                    {
                        when: (row: Target) => row.targets[index]?.isSet === false,
                        style: {
                            backgroundColor: '#fee0e0'
                        },
                    }
                ],
            }
        }),
    ];

    let actions = [
        <AddTarget />
    ];

    actions.push(<FilterTarget targetType={targetType} setTargetType={setTargetType} />);

    useEffect(() => {
        if (showUpdateModal && target) {
            const modalElement = document.getElementById(`update-target-modal-${target.user_id ?? target.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, target, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && target) {
            const modalElement = document.getElementById(`delete-target-modal-${target.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, target, setShowDeleteModal]);

    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}user-targets`);
        }
    }, [startDate, endDate]);

    const onRowClicked = (row: any, event: any) => {
        if (event.target.dataset.columnId && !isNaN(Number(event.target.dataset.columnId.toString())) && (moment(customFields[event.target.dataset.columnId - 3]).isAfter(moment(), 'month') || moment(customFields[event.target.dataset.columnId - 3]).isSame(moment(), 'month'))) {
            setShowUpdateModal(true);
            setTarget({ ...row, value: event.target.textContent !== '-' ? event.target.textContent : 0, date_start: customFields[event.target.dataset.columnId - 3], target_type: targetType })
        }
    };

    const typeTargetData = [{
        label: intl.formatMessage({ id: "TARGET.TYPE.PAID_PIPELINE_NOMINAL" }, { title: settings.pipeline_title }),
        value: "PIPELINE SUCCESS NOMINAL"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.PRODUCTS_SOLD_QUANTITY" }),
        value: "PRODUCT SOLD QTY"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.MEETINGS_COUNT" }),
        value: "MEETING COUNT"
    }]

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                    <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.TARGETS' })} {typeTargetData.find(option => option.value === targetType)?.label}</li>
                </ol>
                <DateRangePicker
                    cleanable={false}
                    character=" - "
                    format="dd MMM yyyy"
                    locale={calendarLocale}
                    value={[startDate, endDate]}
                    ranges={predefinedRanges}
                    placement="bottomEnd"
                    onChange={(value) => {
                        if (value && value.length === 2) {
                            setCustomFields(allMonthYear(value[0], value[1]));

                            setStartDate(value[0]);
                            setEndDate(value[1]);
                        }
                    }}
                    showOneCalendar={isMobile}
                    disabled={loading}
                    isoWeek={true}
                />
            </div>

            {
                user.data.permission_user_target_set !== 'NONE' &&
                <UserScopeModal
                    user_id={user_id}
                    setUser_id={setUser_id}
                    user_name={user_name}
                    setUser_name={setUser_name}
                    scope={scope} setScope={setScope}
                    permission={user.data.permission_user_target_set}
                    apiURL="user-targets"
                />
            }
            <MasterDataTable
                tableKey="user_targets"
                tableColumns={tableColumnsWithCustomFields}
                actions={actions}
                apiURL="user-targets"
                dateStart={moment(startDate).format('YYYY-MM-DD')}
                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                targetType={targetType}
                setLoading={setLoading}
                onRowClicked={onRowClicked}
                user_id={user_id}
                scope={scope}
            />
            {
                showUpdateModal && <UpdateTarget target={target!} />
            }
        </>
    )
}

export { MasterTargets }