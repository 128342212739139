import { FC, useEffect, useState } from "react";
import useUser from "../../../hooks/User";
import { useIntl } from "react-intl";

interface Props {
    title: string
}

const PageNoPermisson: FC<Props> = ({ title }) => {
    const user = useUser()
    const intl = useIntl()

    const sendWa = () => {
        let text = `Halo Wooblazz CRM , saya mau upgrade ke PRO User untuk ${user.data.company_name} akun ${user.data.name}`
        let phone = "6282330779799"
        window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${text}`, '_blank');
    }

    return (
        <>
            {
                title &&
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">{title}</li>
                </ol>
            }

            <div className="card mt-8">
                <div className="card-body flex-column p-5">
                    <div className="text-center p-5 p-lg-15">
                        <div className="d-flex flex-column flex-equal me-5">
                            <h1 className="fw-bolder fs-1 text-gray-800">{intl.formatMessage({ id: "FORM.LABEL.BASIC_USER" })}</h1>
                            <h3 className="fw-bolder text-muted mb-5 mb-15">{intl.formatMessage({ id: "FORM.LABEL.UPGRADE_TO_PRO" })}</h3>
                            <div className="position-relative w-100">
                                <button className="btn btn-primary" style={{ width: "150px" }} onClick={sendWa}>
                                    UPGRADE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { PageNoPermisson }