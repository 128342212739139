import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { useIntl } from 'react-intl';

const useOptionReminder = (compact: boolean = false) => {

    const intl = useIntl();

    const option = [
        {
            value: 0,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.WITHOUT_REMINDER" }),
        },
        {
            value: 5,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.MINUTE_EARLIER" }, { title: "5" }),
        },
        {
            value: 10,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.MINUTE_EARLIER" }, { title: "10" }),
        },
        {
            value: 15,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.MINUTE_EARLIER" }, { title: "15" }),
        },
        {
            value: 30,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.MINUTE_EARLIER" }, { title: "30" }),
        },
        {
            value: 60,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.HOUR_EARLIER" }, { title: "1" }),
        },
        {
            value: 1440,
            label: intl.formatMessage({ id: "CALENDAR.REMINDER.HOUR_EARLIER" }, { title: "24" }),
        },
    ]

    return option;
}

export default useOptionReminder;