import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG } from "../../../../_metronic/helpers";
import useHandleCloseModal from "../../../../hooks/HandleCloseModal";
import { UpdateEmailType, updateEmail } from "../../../../api/MasterEmailCRUD";
import { Email } from "../../../../interfaces/Email";
import { insertEmailAttachment } from "../../../../api/ResourceAPI";
import { handleCaps } from "../../../../functions/general";
import { updateGroupContactEmail } from "../../../../api/EmailCRUD";
import { GroupContactEmail, GroupEmailTypes } from "../../../../interfaces/Email/BroadCastEmailType";
import { mutate } from "swr";
import { useUserStore } from "../../../../hooks/UserEmail";

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL
interface UpdateEmailProps {
    groupContact: GroupEmailTypes
}

const UpdateGroupEmail: FC<UpdateEmailProps> = ({ groupContact }) => {
    const intl = useIntl();
    const { tablesData, setTablesData } = useDataTableStore();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { userEmail } = useUserStore()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nameGroup: groupContact.name,
        },
        validationSchema: Yup.object().shape({
            nameGroup: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Name Broadcast' })),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                const updateEmailData = {
                    id: groupContact.id,
                    name: values.nameGroup
                };
                const response = await updateGroupContactEmail(updateEmailData);
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_EMAIL_SUCCESS" })}`,
                        timer: 1500,
                        timerProgressBar: true
                    });
                    mutate(`${API_URL_EMAIL}/groupcontact/list-group?userId=${userEmail?.id}`)
                    setTablesData('groupEmail', tablesData.groupEmail.map((item: any) =>
                        item.id === groupContact.id ? response.data : item
                    ));
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.message || 'An error occurred',
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={`update-group-contact-modal-${groupContact.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} Group Contact</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}

                            {/* Name */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Broadcast Name</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Broadcast Name'
                                    {...formik.getFieldProps('nameGroup')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.nameGroup && formik.errors.nameGroup },
                                    )}
                                    type='text'
                                    name='nameGroup'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.nameGroup && formik.errors.nameGroup && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.nameGroup}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? 'disabled' : ''}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateGroupEmail }