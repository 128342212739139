import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Select, { components } from "react-select";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField, SettingMultiMerchants, } from "../../../interfaces/Settings";
import { SelectOption, SelectOptionMerchantLink } from "../../../interfaces/SelectOption";
import { saveSetting, saveSettingMerchantLink, updateTemplateSettingMeeting, updateTemplateSettingMerchant } from "../../../api/SettingCRUD";
import { useIntl } from "react-intl";
import clsx from "clsx";
import * as Yup from 'yup';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from "../RowCustomField";
import { useMerchantStore } from "../../../stores/MerchantStore";
import { useMeetingStore } from "../../../stores/MeetingsStore";
import { SettingMultiMeetings } from "../../../interfaces/Meeting";

interface Props {
    data: SettingMultiMeetings // SettingMultiMerchants
    optionMeeting: any[]
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataPipeline: SelectOption[]
    dataMerchant: SelectOption[]
}

const CustomFieldMultiMeeting: FC<Props> = ({ data, optionMeeting, optionType, optionsResetSerial, dataPipeline, dataMerchant }) => {
    const { settings, setSettings } = useSettingsStore()
    const token = useAccessToken()
    const {
        meetings,
        setMeetings,
        meetingLoading,
        setMeetingLoading,
        multiMeetings,
        setMultiMeetings,
        selectMultiMeeting,
        setSelectMultiMeeting,
    } = useMeetingStore();
    const [selectTemplate, setSelectTemplate] = useState(multiMeetings ? multiMeetings[0] : {} as any)
    
    const [keysMeeting, setKeysMeeting] = useState<string[]>([])
    const [fieldMeeting, setFieldMeeting] = useState<CustomField>({})
    const [loadingMeetingPreferences, setLoadingMeetingPreferences] = useState(false)
    const [loadingMeeting, setLoadingMeeting] = useState(false)
    const [countAutoCancel, setCountAutoCancel] = useState({
        day: 0,
        hour: 0,
        minute: 0
    })

    const intl = useIntl()
    const allOptionMerchant = [{ label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, {title: settings.merchant_title}), value: 'all' }]

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );
    
    const handleDragEnd = (event: any) => {
            const { active, over } = event;
    
            if (active.id !== over.id) {
                const updateColumns = (field: CustomField) => {
                    // Ambil urutan kunci lama
                    const keys = Object.keys(field);
                    // Temukan indeks lama dan baru
                    const oldIndex = keys.indexOf(active.id);
                    const newIndex = keys.indexOf(over.id);
    
                    // Urutkan kunci dengan arrayMove
                    const newKeys = arrayMove(keys, oldIndex, newIndex);
    
                    // Buat objek baru dengan urutan kunci baru
                    const newField: CustomField = {};
                    newKeys.forEach(key => {
                        newField[key] = field[key];
                    });
    
                    return newField;
                };
    
                // Perbarui urutan fieldMeeting
                const newColumns = updateColumns(fieldMeeting);
                setFieldMeeting(newColumns);
            }
    };

    const formikPreferencesTemplate = useFormik({
        initialValues: {
            value: data.name,
            // merchantTemplateIds: data.merchantTemplates 
            //     ? data.merchantTemplates.map(template => ({ label: template.name, value: template.id }))
            //     : [{ label: 'All Merchants', value: 'all' }],
            merchantTemplateIds: data.merchantTemplates.length>0 ? data.merchantTemplates.map(template => ({ label: template.name, value: template.id })) : allOptionMerchant,
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            merchantTemplateIds: Yup.array().min(0, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'merchant template' })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) })).nullable(),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let merchantTemplateIds = values.merchantTemplateIds.map(item => item.value);

            if (values.merchantTemplateIds.find(progress => progress.value === 'all')) merchantTemplateIds = dataPipeline.filter(item => item.value !== 'all').map(item => item.value);
            else merchantTemplateIds = values.merchantTemplateIds.map(item => item.value);
            try {
                const req = await updateTemplateSettingMeeting(data.id, values.value, data.customFields, values.merchantTemplateIds.map(item => {return item.value==='all'?'':item.value}), token)
                if (req.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setMultiMeetings(multiMeetings.map(item => item.id === data.id ? { ...req.data.data, label: req.data.data.name, value: req.data.data.id } : item));
                }
            } catch (error: any) {
                console.error('error', error)
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMeetingPreferences(false)
            }
        }
    })

    // useEffect(() => {
    //     console.log('formikPreferencesTemplate.values', formikPreferencesTemplate.values)
    // }, [formikPreferencesTemplate.values]);

    const addMeetingColumn = () => {
        var key = crypto.randomUUID()
        const addColumnMeeting = { ...fieldMeeting, [key]: { name: `${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })} ` + (keysMeeting.length + 1), type: 'text', required: false, value: [], score: "", new: true } };
        setFieldMeeting(addColumnMeeting);
        setKeysMeeting([...keysMeeting, key])
    }

    const formikCustomColumn = useFormik({
        initialValues: {
            value: data.name,
            // merchantTemplateIds: data.merchantTemplates ? data.merchantTemplates : [],
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            // merchantTemplateIds: Yup.array().min(0, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'merchant template' })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) })).nullable(),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isDuplicate = false
            let isNameColumnEmpty = false
            let isOptionEmpty = false

            Object.keys(fieldMeeting).map((key1) => {
                if (fieldMeeting[key1].type === "option" || fieldMeeting[key1].type === "multiple") {
                    if ((fieldMeeting[key1].value as string[]).includes("")) {
                        isOptionEmpty = true
                    }
                }

                if (fieldMeeting[key1].name === "") {
                    isNameColumnEmpty = true
                }

                Object.keys(fieldMeeting).map((key2) => {
                    if ((fieldMeeting[key1].name)?.trim() === (fieldMeeting[key2].name)?.trim() && key1 !== key2) {
                        isDuplicate = true
                    }
                })
            })

            const modifiedObject = Object.fromEntries(
                Object.entries(fieldMeeting).map(([key, value]) => {
                    const { new: _, ...rest } = value;
                    return [key, rest];
                })
            );

            if (isOptionEmpty) return false
            if (isNameColumnEmpty) return false
            if (isDuplicate) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                return false
            }

            setLoadingMeeting(true)

            try {
                // const res = await saveSetting("meeting_custom_fields", JSON.stringify(modifiedObject), token)
                // if (res.data.success) {
                //     Swal.fire({
                //         icon: 'success',
                //         heightAuto: false,
                //         title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                //         timer: 2000,
                //         timerProgressBar: true
                //     })
                //     Object.values(fieldMeeting).forEach(field => {
                //         field.new = false;
                //     });
                //     setSettings({ ...settings, meeting_custom_fields: JSON.stringify(fieldMeeting) })
                // }


                const req = await updateTemplateSettingMeeting(data.id, data.name, fieldMeeting, data.merchantTemplates.map(item => item.id), token)
                if (req.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setMultiMeetings(multiMeetings.map(item => item.id === data.id ? { ...req.data.data, label: req.data.data.name, value: req.data.data.id } : item));
                }
            } catch (error: any) {
                console.error('error meetings', error);
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            }  finally {
                setLoadingMeeting(false);
            }
        }
    });

    useEffect(() => {
        formikCustomColumn.setFieldValue('value', data.name ? data.name : 'Template Merchant')
        if (data.customFields) {
            const modifiedData = Object.fromEntries(
                Object.entries(data.customFields).map(([key, field]) => {
                    const { new: _, ...rest } = field;
                    
                    if (data.customFields[key].type === 'merchant') {
                        rest.value = rest.value.length === 0 ? ['all'] : rest.value.length === dataMerchant.filter((item: any) => item.value !== 'all').length ? ['all'] : rest.value;
                    }

                    return [key, rest];
                })
            );

            setFieldMeeting(modifiedData)
            setKeysMeeting(Object.keys(`${modifiedData}`))
        }
    }, [data])

    // useEffect(() => {
    //     console.log("fieldMeeting", data.name, fieldMeeting)
    // }, [fieldMeeting]);

    return (
        <>
            <div className="card mb-3">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: "Template " + data.name })}</h3>
                    </div>
                </div>
                <div>
                    <form onSubmit={formikPreferencesTemplate.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                    <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME_TEMPLATE" })}</span>
                                    </span>
                                    <div className="col-md-5 col-lg-3 col-xl-3">
                                        <input
                                            {...formikPreferencesTemplate.getFieldProps('value')}
                                            className={clsx(
                                                'form-control',
                                                { 'is-invalid': formikPreferencesTemplate.touched.value && formikPreferencesTemplate.errors.value },
                                            )}
                                            type='text'
                                            name='value'
                                        />
                                        {formikPreferencesTemplate.touched.value && formikPreferencesTemplate.errors.value && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formikPreferencesTemplate.errors.value}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 align-items-start">
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.SETTING_TEMPLATE" }, { title: settings.merchant_title })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_MEETING" }, { title: settings.merchant_title, title2: settings.meeting_title })}</span>
                                    </span>
                                </label>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <Select
                                        key={data.id}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: "1px solid #E4E6EF",
                                                backgroundColor: "none",
                                            }),
                                            option: (baseStyles, { isSelected, isFocused }) => ({
                                                ...baseStyles,
                                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                cursor: 'pointer'
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: 'none',
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'none',
                                            },
                                        })}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        isClearable
                                        onChange={(selectedOptions) => {
                                            if(selectedOptions.length === 0) {
                                                formikPreferencesTemplate.setFieldValue('merchantTemplateIds', allOptionMerchant)
                                            } else {
                                                var endIndex = selectedOptions.length - 1
                                                if(selectedOptions[endIndex].value === 'all') {
                                                    formikPreferencesTemplate.setFieldValue('merchantTemplateIds', allOptionMerchant)
                                                } else {
                                                    formikPreferencesTemplate.setFieldValue('merchantTemplateIds', selectedOptions.filter(item => item.value !== 'all'))
                                                }
                                            }
                                        }}
                                        value={formikPreferencesTemplate.values.merchantTemplateIds as any}
                                        className='react-select-styled'
                                        classNamePrefix='react-select'
                                        options={optionMeeting}
                                        placeholder={intl.formatMessage({ id: "FORM.PLACEHOLDER.CHOOSE_COLUMN" })}
                                        // components={{
                                        //     Option: CustomOptionMerchantLink,
                                        //     MultiValue: CustomMultiValueMerchantLink
                                        // }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingMeetingPreferences ? "disabled" : ""}`} data-kt-indicator={loadingMeetingPreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.CUSTOM_COLUMN" })}</h3>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formikCustomColumn.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            {
                                <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
                                    <SortableContext items={Object.keys(fieldMeeting) as any} strategy={verticalListSortingStrategy}>
                                        {
                                            Object.keys(fieldMeeting).map((data, index) => {
                                                return (
                                                    <>
                                                    {/* <p>{index}</p> */}
                                                    <RowCustomField
                                                        key={data} 
                                                        id={data}
                                                        type="meeting"
                                                        customField={fieldMeeting}
                                                        index={index}
                                                        optionType={optionType}
                                                        optionsResetSerial={optionsResetSerial}
                                                        setCustomField={setFieldMeeting}
                                                        />
                                                    </>
                                                )
                                            })
                                        }
                                    </SortableContext>
                                </DndContext>
                            }
                        </div>
                        <div className="card-footer py-6 px-9">
                            <div className="row">
                                <label className="col-xl-3"></label>
                                <div className="col-lg-9 col-xl-9" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <button type="button" className="btn btn-secondary" onClick={() => addMeetingColumn()}>
                                        <i className="fas fa-plus"></i>
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}
                                    </button>
                                    <button type="submit" className={`btn btn-primary ${loadingMeeting ? "disabled" : ""}`} data-kt-indicator={loadingMeeting ? 'on' : 'off'}>
                                        <span className="indicator-label">
                                            {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                        </span>
                                        <span className="indicator-progress">
                                            Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { CustomFieldMultiMeeting }