import { Dropdown } from "rsuite";
import { SketchPicker } from "react-color";
import useNameWidget from '../../hooks/NameWidget';
import { ArrayWidget, Widget } from "../../interfaces/Widget";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";

type Props = {
    index: number
    type: string
    data: ArrayWidget
    dataDisplayWidget: Widget
    setDataDisplayWidget: (widget: Widget) => void
    handleWidget: (data: ArrayWidget, type: string, action: string) => void
}

const renderIconButton = (props: any, ref: any, color: string) => {
    return (
        <button className="btn btn-sm btn-icon" type="button" {...props} ref={ref} style={{ background: "none", width: "auto" }}>
            <i className="fas fa-palette fs-5" style={{ color: color }}></i>
        </button>
    );
};

const CustomColumnWidgetOrder: React.FC<Props> = ({ index, type, data, dataDisplayWidget, setDataDisplayWidget, handleWidget }) => {
    const intl = useIntl()
    const nameWidget = (data.name.includes("Monthly") && type === 'pieChart') ? `${data.alias} (${intl.formatMessage({ id: "FORM.LABEL.MONTHLY" })})` : data.alias;
    const dataWidget = type === 'static' ? dataDisplayWidget.static : type === 'monthly' ? dataDisplayWidget.monthly : dataDisplayWidget.pieChart

    return (
        <div className="col-12 col-lg-3 col-xl-3 mb-3" key={index}>
            <div className={`card-dashboard`} style={{ position: "relative", backgroundColor: data.color, color: "white" }}>
                <div className="form-check form-check-custom justify-content-between">
                    <div className='my-3 mx-3 d-flex' style={{ alignItems: "center" }}>
                        <label className="form-check-label ms-3 fw-bold fs-7">{nameWidget}</label>
                    </div>
                    <div className="d-flex">
                        <Dropdown renderToggle={(props, ref) => renderIconButton(props, ref, "white")} placement="bottomEnd" style={{ color: "black" }}>
                            <SketchPicker
                                color={data.color}
                                onChange={color => {
                                    setDataDisplayWidget({ ...dataDisplayWidget, [type]: dataWidget.map(item => item.id === data.id ? { ...item, color: color.hex } : item) })
                                }}
                            />
                        </Dropdown>
                        <button className="btn btn-sm btn-icon" type="button" onClick={() => handleWidget(data, type, 'hide')}>
                            <KTSVG path={'/media/icons/duotune/general/gen034.svg'} className='svg-icon-2 svg-icon-light' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { CustomColumnWidgetOrder }