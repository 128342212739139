import { useIntl } from 'react-intl';
import { useSettingsStore } from '../stores/SettingsStore';
import useUser from './User';
import { CustomField } from '../interfaces/Settings';
import { useCustomeStore } from '../stores/CustomeStore';
import { usePipelineStore } from '../stores/PipelineStore';
import { useParams } from 'react-router-dom';
import { useMerchantStore } from '../stores/MerchantStore';

const formatSubHeader = (field: any, customField: CustomField, intl: any) => {
  const fieldData = customField[field];
  const isRequired = fieldData.required;
  const type = fieldData.type;

  if (type === 'phone') {
    return isRequired ? `Ex: 628xxx` : `Ex: 628xxx (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`;
  }

  if (type === 'date') {
    return isRequired ? `Ex: 2024-12-30` : `Ex: 2024-12-30 (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`;
  }

  if (type === 'file' || type === 'image') {
    const title = 'URL';
    return isRequired
      ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title })}`
      : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${title} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`;
  }

  const title = fieldData.name;
  return isRequired
    ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title })}`
    : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${title} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`;
};

const useNameColumnExcel = (compact: boolean = false) => {
  const { multiPipelines } = usePipelineStore()
  const { multiMerchants } = useMerchantStore()
  const { settings } = useSettingsStore();
  const { companyId } = useCustomeStore();
  const intl = useIntl();
  const user = useUser();
  const { templatePipelineId, templateMerchantId } = useParams()

  var arrayCustomFieldPipeline: CustomField = templatePipelineId && multiPipelines.length > 0 ? multiPipelines.find(item => item.id === templatePipelineId)?.customFields! : {};
  var arrayCustomFieldUsers: CustomField = settings.users_custom_fields ? JSON.parse(settings.users_custom_fields) : {};
  var arrayCustomFieldMerchants: CustomField = templateMerchantId && multiMerchants.length > 0 ? multiMerchants.find(item => item.id === templateMerchantId)?.customFields! : {};

  let filteredCustomFieldUsers = Object.fromEntries(
    Object.entries(arrayCustomFieldUsers).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial';
    })
  );

  let filteredCustomFieldMerchants = Object.fromEntries(
    Object.entries(arrayCustomFieldMerchants).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant';
    })
  );

  let filteredCustomFieldPipeline = Object.fromEntries(
    Object.entries(arrayCustomFieldPipeline).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant' && value.type !== 'user' && value.type !== 'pipeline';
    })
  );

  var nameHeader = {
    export: {
      pipeline: [
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED' }),
        intl.formatMessage(
          { id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' },
          { title: settings.merchant_title }
        ),
        user.data.company_id === companyId.elgisa
          ? 'Opportunity'
          : intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
        ...(user?.data.company_name === "Maspion Square" ? [intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL_BEFORE_VAT' })] : []),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT_LABEL' }),
      ],
      meeting: [
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHOTO' }),
        settings.merchant_title,
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PARTICIPANT' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LOCATION' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.REMINDER' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DURATION' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TYPE' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.STATUS' }),
      ],
      merchant: [
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }),
        'Email',
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_MEETING' }),
        intl.formatMessage(
          { id: 'MASTERS.DATATABLE.COLUMNS.LAST_ADD_MERCHANT' },
          { title: settings.pipeline_title }
        ),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: settings.pipeline_title }),
        intl.formatMessage(
          { id: 'MASTERS.DATATABLE.COLUMNS.COUNT' },
          { title: intl.formatMessage({ id: 'MENU.MEETINGS' }) }
        ),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
      ],
      reportLog: [
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DURATION' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DESCRIPTION' }),
        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.FILE' }),
      ],
    },
    import: {
      insert: {
        pipeline: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
            settings.merchant_title,
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
            ...Object.keys(filteredCustomFieldPipeline).map((field: any) => filteredCustomFieldPipeline[field].name),
          ],
          subHeader: [
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: settings.merchant_title }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }) }),
            'Ex: 2024-12-30',
            `Ex: 2024-12-30 (${intl.formatMessage({ id: 'FORM.VALIDATION.SUB_HEADER_PROGRESS' })})`,
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }) }),
            ...Object.keys(filteredCustomFieldPipeline).map((field: any) => formatSubHeader(field, filteredCustomFieldPipeline, intl)),
          ],
        },
        merchant: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            'Email',
            ...Object.keys(filteredCustomFieldMerchants).map((field: any) => filteredCustomFieldMerchants[field].name),
          ],
          subHeader: [
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }) }),
            'Ex: 628xxx',
            `Email (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`,
            ...Object.keys(filteredCustomFieldMerchants).map((field: any) => formatSubHeader(field, filteredCustomFieldMerchants, intl)),
          ],
        },
        user: {
          header: [
            'Username',
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ID_NUMBER' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PASSWORD' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.MANAGER' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ATTENDANCE_TEMPLATE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_JOINED' })
          ],
          subHeader: [
            `Ex: ${user?.data.company_prefix}-username`,
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ID_NUMBER' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            'Ex: 628xxx',
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PASSWORD' })}` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.MANAGER' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' })}` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' })}` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ATTENDANCE_TEMPLATE' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            'Ex: 2024-12-30',
            ...Object.keys(filteredCustomFieldUsers).map((field: any) =>
              filteredCustomFieldUsers[field].type === 'phone' && filteredCustomFieldUsers[field].required ? `Ex: 628xxx` : filteredCustomFieldUsers[field].type === 'phone' ? `Ex: 628xxx(${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`
                : filteredCustomFieldUsers[field].type === 'date' ? filteredCustomFieldUsers[field].required ? `Ex: 2024-12-30` : `Ex: 2024-12-30 (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`
                  : filteredCustomFieldUsers[field].type === 'file' || filteredCustomFieldUsers[field].type === 'image' ? filteredCustomFieldUsers[field].required ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'URL' })}` : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `URL (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`
                    : filteredCustomFieldUsers[field].required ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: filteredCustomFieldUsers[field].name })}` : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${filteredCustomFieldUsers[field].name} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`
            ),
          ],
        },
        unit: {
          header: [intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }), 'Alias'],
          subHeader: [
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }
            ),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'Alias' }),
          ],
        },
        product: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            intl.formatMessage({ id: 'FORM.LABEL.PRICE' }),
            intl.formatMessage({ id: 'FORM.LABEL.VAT' }),
            'SKU',
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
          ],
          subHeader: [
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }
            ),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PRICE' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'FORM.LABEL.VAT' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'SKU' }),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              {
                title: `${intl.formatMessage({
                  id: 'MASTERS.DATATABLE.COLUMNS.NOTES',
                })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`,
              }
            ),
          ],
        },
        productUnit: {
          header: [
            'SKU',
            intl.formatMessage({ id: 'FORM.LABEL.UNITS' }),
            intl.formatMessage({ id: 'FORM.LABEL.UNIT_CONVERSION' }),
          ],
          subHeader: [
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.CHOOSE' },
              {
                title: intl.formatMessage(
                  { id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT_TITLE' },
                  { title: 'SKU' }
                ),
              }
            ),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.CHOOSE' },
              { title: `${intl.formatMessage({ id: 'FORM.LABEL.UNITS' })}` }
            ) +
            '. Ex: gram (' +
            intl.formatMessage({ id: 'EXCEL.VALIDATION.BLANK_UNIT' }) +
            ')',
            'Ex: 10. 1 PCS = 10 gram (' + intl.formatMessage({ id: 'EXCEL.VALIDATION.FILL_UNIT' }) + ')',
          ],
        },
        productLabel: {
          header: ['SKU', intl.formatMessage({ id: 'FORM.LABEL.LABELS' })],
          subHeader: [
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.CHOOSE' },
              {
                title: intl.formatMessage(
                  { id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT_TITLE' },
                  { title: 'SKU' }
                ),
              }
            ),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.CHOOSE' },
              { title: `${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}` }
            ),
          ],
        },
      },
      update: {
        user: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CODE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ID_NUMBER' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.MANAGER' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ATTENDANCE_TEMPLATE' }),
          ],
          subHeader: [
            intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ID_NUMBER' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.MANAGER' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' })}` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' })}` }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.CHOOSE' }, { title: `${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ATTENDANCE_TEMPLATE' })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` }),
            ...Object.keys(filteredCustomFieldUsers).map((field: any) =>
              filteredCustomFieldUsers[field].type === 'phone' && filteredCustomFieldUsers[field].required ? `Ex: 628xxx` : filteredCustomFieldUsers[field].type === 'phone' ? `Ex: 628xxx(${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`
                : filteredCustomFieldUsers[field].type === 'date' ? filteredCustomFieldUsers[field].required ? `Ex: 2024-12-30` : `Ex: 2024-12-30 (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`
                  : filteredCustomFieldUsers[field].type === 'file' || filteredCustomFieldUsers[field].type === 'image' ? filteredCustomFieldUsers[field].required ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'URL' })}` : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `URL (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`
                    : filteredCustomFieldUsers[field].required ? `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: filteredCustomFieldUsers[field].name })}` : `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `${filteredCustomFieldUsers[field].name} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})` })}`
            ),
          ]
        },
        unit: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CODE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            'Alias',
          ],
          subHeader: [
            intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' }),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }
            ),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'Alias' }),
          ],
        },
        product: {
          header: [
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CODE' }),
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            intl.formatMessage({ id: 'FORM.LABEL.PRICE' }),
            intl.formatMessage({ id: 'FORM.LABEL.VAT' }),
            'SKU',
            intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
          ],
          subHeader: [
            intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' }),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }
            ),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PRICE' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'FORM.LABEL.VAT' }) }),
            intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: 'SKU' }),
            intl.formatMessage(
              { id: 'EXCEL.VALIDATION.ENTER' },
              {
                title: `${intl.formatMessage({
                  id: 'MASTERS.DATATABLE.COLUMNS.NOTES',
                })} (${intl.formatMessage({ id: 'FORM.LABEL.OPTIONAL' })})`,
              }
            ),
          ],
        },
      },
      info: {
        fileInformation: intl.formatMessage({ id: 'EXCEL.LABEL.FILE_INFORMATION' }),
        downloader: intl.formatMessage({ id: 'EXCEL.LABEL.DOWNLOADER' }),
        downloadTime: intl.formatMessage({ id: 'EXCEL.LABEL.DOWNLOAD_TIME' }),
        type: intl.formatMessage({ id: 'EXCEL.LABEL.TYPE' }),
      },
      error: intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' }),
    },
  };

  return nameHeader;
};

export default useNameColumnExcel;
