import { FC, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { Unit } from "../../interfaces/Unit";
import { deleteUnit, getLeadProductList } from "../../api/UnitsCRUD";
import { useSettingsStore } from "../../stores/SettingsStore";

interface Props {
    unit: Unit
}

const DeleteUnit: FC<Props> = ({ unit }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const { settings } = useSettingsStore()

    const intl = useIntl()

    const handleDeleteLabel = async () => {
        setModalBtnLoading(true)
        try {
            // cek unit sudah digunakan di produk pipeline atau belum
            const checkUnitUsed = await getLeadProductList(unit.id, token)

            if (checkUnitUsed.data.data.length > 0) { // kalau unit terpakai kasih alert
                Swal.fire({
                    title: intl.formatMessage({ id: "ALERT.TITLE.WARNING" }),
                    // text: "Data ini sudah digunakan pada pipelines. Lanjutkan?",
                    text: intl.formatMessage({ id: "ALERT.UNITS.USED_IN_PIPELINES" }, { title: (settings.pipeline_title).toLowerCase() }),
                    icon: "warning",
                    showCancelButton: !0,
                    buttonsStyling: !1,
                    confirmButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.YES" }),
                    cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-secondary me-2",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    preConfirm: async () => {
                        const response = await deleteUnit(unit.id, token)
                        if (response.status === 200) {
                            Swal.fire({
                                icon: "success",
                                title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                                heightAuto: false,
                            })
                            closeModalRef.current?.click();
                            setTablesData('units', (tablesData.units.filter((data) => data.id !== unit.id)))
                        }
                    }
                }).then(async (result) => {
                    if (result.isDismissed) closeModalRef.current?.click();;
                });
            } else { // kalau unit belum terpakai langsung hapus
                const response = await deleteUnit(unit.id, token)
                if (response.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                        heightAuto: false,
                    })
                    closeModalRef.current?.click();
                    setTablesData('units', (tablesData.units.filter((data) => data.id !== unit.id)))
                }
            } 
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-unit-modal-${unit.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })} {unit.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: 'FORM.CONFIRMATION' })} {intl.formatMessage({ id: 'FORM.ACTION.DELETE' }).toLowerCase()} {unit.name}?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                                id="close-delete-unit-modal"
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteLabel}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteUnit }