import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import {useSettingsStore} from '../../../stores/SettingsStore'
import useUser from '../../../hooks/User'
import {useIntl} from 'react-intl'
import {useCustomeStore} from '../../../stores/CustomeStore'
import {Bar, Pie} from 'react-chartjs-2'
import {DateRangePicker, Dropdown} from 'rsuite'
import axios from 'axios'
import useAccessToken from '../../../hooks/AccessToken'
import useSWR from 'swr'
import {IComponent, ISection, ITemplateAnalytic} from '../../../interfaces/Analytics/Analytics'
import {Modal} from 'bootstrap'
import useCalendarLocale from '../../../hooks/CalendarLocale'
import {endOfMonth, startOfMonth} from 'date-fns'
import usePredefinedRanges from '../../../hooks/PredefinedRanges'
import {useAnalyticsStore} from '../../../stores/Analytics/AnalyticStore'
import AddSection from '../../../components/Analytics/Section/AddSection'
import UpdateSection from '../../../components/Analytics/Section/UpdateSection'
import {DeleteSection} from '../../../components/Analytics/Section/DeleteSection'
import SectionAnalytics from '../../../components/Analytics/Section/SectionAnalytics'
import useModalEffect from '../../../hooks/useModalEffect'
import AddComponent from '../../../components/Analytics/Component/AddComponent'
import UpdateComponent from '../../../components/Analytics/Component/UpdateComponent'
import {DeleteComponent} from '../../../components/Analytics/Component/DeleteComponent'
import zoomPlugin from 'chartjs-plugin-zoom'
import {Chart as ChartJS} from 'chart.js'
import {jsPDF} from 'jspdf'
import html2canvas from 'html2canvas'
import {Link, useParams} from 'react-router-dom'

export declare type ValueType = [Date?, Date?]
export declare type DateRange = [Date, Date]
export interface RangeType {
  label: React.ReactNode
  value: DateRange | ((value?: ValueType) => DateRange)
  closeOverlay?: boolean
  placement?: 'bottom' | 'left'
}

const Analytics: FC = () => {
  const {component} = useAnalyticsStore()
  const intl = useIntl()
  const token = useAccessToken()

  useEffect(() => {
    ChartJS.register(zoomPlugin)
  }, [])

  const [showUpdateSectionModal, setShowUpdateSectionModal] = useState<boolean>(false)
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState<boolean>(false)
  const [section, setSection] = useState<ISection>()
  const API_URL = process.env.REACT_APP_API_URL
  const {
    showUpdateComponentModal,
    setShowUpdateComponentModal,
    components,
    setComponents,
    sections,
    setSections,
    showDeleteComponentModal,
    setShowDeleteComponentModal,
    templatesAnalytic,
    setComponentsLoading
  } = useAnalyticsStore()
  const [showAddComponentModal, setShowAddComponentModal] = useState(false)

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        throw error.response ? error.response.data : error
      })

  const {templateAnalyticId} = useParams()

  const {
    data: sectionData = [],
    error: sectionError,
    isLoading: sectionLoading,
    isValidating: sectionValidating,
  } = useSWR(`${API_URL}menu-analytics?templateId=${templateAnalyticId}`, fetcher)

  const {
    data: componentData = [],
    error: componentError,
    isLoading: componentLoading,
    isValidating: compoenentValidating,
  } = useSWR(`${API_URL}menu-analytics/data?templateId=${templateAnalyticId}`, fetcher)

  useEffect(()=>{
    setComponentsLoading(componentLoading)
  },[componentLoading])

  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    setSections(sectionData)
  }, [sectionData])

  const handleEditSection = (section: ISection) => {
    setShowUpdateSectionModal(true)
    setSection(section)
  }
  const handleAddComponent = (section: ISection) => {
    setShowAddComponentModal(true)
    setSection(section)
  }
  const handleDeleteSection = (section: ISection) => {
    setShowDeleteSectionModal(true)
    setSection(section)
  }

  useModalEffect({
    showModal: showDeleteSectionModal,
    setShowModal: setShowDeleteSectionModal,
    modalId: `delete-section-modal-${section?.sectionId}`,
  })

  useModalEffect({
    showModal: showAddComponentModal,
    setShowModal: setShowAddComponentModal,
    modalId: `add-component-modal`,
  })

  useModalEffect({
    showModal: showUpdateSectionModal,
    setShowModal: setShowUpdateSectionModal,
    modalId: `update-section-modal-${section?.sectionId}`,
  })

  useModalEffect({
    showModal: showUpdateComponentModal,
    setShowModal: setShowUpdateComponentModal,
    modalId: `update-component-modal-${component?.componentId}`,
  })
  useModalEffect({
    showModal: showDeleteComponentModal,
    setShowModal: setShowDeleteComponentModal,
    modalId: `delete-component-modal-${component?.componentId}`,
  })
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 666) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    // Initial check
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const componentToExportRef = useRef(null)

  useEffect(() => {
    setComponents(componentData)
  }, [componentData])

  const generatePDF = () => {
    if (componentToExportRef.current) {
      // Menggunakan html2canvas untuk mengubah elemen HTML menjadi gambar (canvas)
      html2canvas(componentToExportRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const doc = new jsPDF()

        const pageWidth = doc.internal.pageSize.width
        const pageHeight = doc.internal.pageSize.height

        let imgWidth = pageWidth - 20
        let imgHeight = (canvas.height * imgWidth) / canvas.width

        // Jika gambar lebih besar dari satu halaman, bagi menjadi beberapa halaman
        let yOffset = 10 // Offset Y untuk gambar
        while (imgHeight > pageHeight) {
          doc.addImage(imgData, 'PNG', 10, yOffset, imgWidth, pageHeight - 20)
          yOffset += pageHeight - 20
          imgHeight -= pageHeight - 20
          doc.addPage() // Tambah halaman baru jika gambar masih terpotong
        }

        // Menambahkan gambar sisa (jika ada)
        doc.addImage(imgData, 'PNG', 10, yOffset, imgWidth, imgHeight)

        doc.save('download.pdf')
      })
    } else {
      console.error('Elemen tidak ditemukan!')
    }
  }

  const [templateAnalyticName, setTemplateAnalyticName] = useState("")


  useEffect(() => {
    const name = templatesAnalytic?.find(
      (template: ITemplateAnalytic) => template.id === templateAnalyticId
    )?.name || ""; // Ambil properti "name" atau beri default ""
    
    setTemplateAnalyticName(name);
  }, [templatesAnalytic]);

  return (
    <>
      <div className='row justify-content-between mb-3'>
        <div className='col-sm-3 col-lg-4 col-xl-5' style={{alignSelf: 'center'}}>
          <ol className='breadcrumb text-muted fs-6 fw-bold'>
            <li className='breadcrumb-item pe-3'>
              <Link to={`/analytics`} className='text-muted text-hover-primary'>
                {intl.formatMessage({id: 'MENU.ANALYTICS'})}
              </Link>
            </li>
            <li className='breadcrumb-item text-dark'>{templateAnalyticName}</li>
          </ol>
        </div>
        <div className='col-sm-9 col-lg-8 col-xl-7'>
          <div className='d-flex px-3 float-end gap-2'>
            <AddSection />
          </div>
        </div>
      </div>
      {sectionLoading && (
        <div className='loading-overlay'>
          <div className='loader'></div>
        </div>
      )}
      <div ref={componentToExportRef}>
        {!sectionLoading &&
          sections
            ?.sort((a, b) => a.position - b.position)
            .map((menu) => {
              return (
                <SectionAnalytics
                  key={menu.sectionId}
                  handleDeleteSection={handleDeleteSection}
                  handleEditSection={handleEditSection}
                  menu={menu}
                  handleAddComponent={handleAddComponent}
                  components={components
                    .sort((a, b) => a.position - b.position)
                    .filter((component: IComponent) => component.sectionId === menu.sectionId)}
                />
              )
            })}
            {
               !sectionLoading && sectionData.length <= 0 && (
                <>
                <div className=' card p-8'>
                <div className='w-100 h-100 d-flex justify-content-center align-items-center gap-4 flex-column'>
                  <i className='bi bi-emoji-frown' style={{fontSize: '12rem'}} />{' '}
                  <span style={{fontSize: '1.5rem', color: '#A1A5B7'}}>
                    {intl.formatMessage(
                      {id: 'FORM.CONFIRM.DATA_NOT_FOUND'},
                      {
                        title: intl.formatMessage({id: 'MENU.ANALYTICS'})}
                      )}
                  </span>
                  </div>
                  </div>
                </>
              )
            }
        {showUpdateSectionModal && <UpdateSection section={section!} />}
        {showDeleteSectionModal && <DeleteSection section={section!} />}
        {showDeleteComponentModal && <DeleteComponent component={component!} />}
        {showAddComponentModal && <AddComponent section={section!} />}
        {showUpdateComponentModal && <UpdateComponent component={component!} section={section!} />}
      </div>
    </>
  )
}

export {Analytics}
