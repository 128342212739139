import axios from "axios"
import { MergeMerchantProps } from "../interfaces/Merchant"

const API_URL = process.env.REACT_APP_API_URL

const MERCHANT = `${API_URL}merchant`
const MERCHANTS = `${API_URL}merchants`
interface IMerchantLabel{
    id:string
}
export const insertMerchant = async (merchantLabels:IMerchantLabel[]|[] , ownerId: string, name: string, cityId: string | null, phone: string, isWhatsappPhone: boolean, token: string, email: string | null, customFields?: { [key: string]: string | string[] | null }) => {
    return axios.post(MERCHANT, {
        merchantLabels,
        ownerId,
        name,
        cityId,
        phone,
        isWhatsappPhone,
        email,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}
export const insertMerchantWithTemplate = async (merchantTemplateId: string|undefined,merchantLabels:IMerchantLabel[]|[] , ownerId: string, name: string, cityId: string | null, phone: string, isWhatsappPhone: boolean, token: string, email: string | null, customFields?: { [key: string]: string | string[] | null }) => {
    return axios.post(MERCHANT, {
        merchantTemplateId,
        merchantLabels,
        ownerId,
        name,
        cityId,
        phone,
        isWhatsappPhone,
        email,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}


export const updateMerchant = async (merchantLabels:IMerchantLabel[],ownerId: string, id: string, name: string, cityId: string, isWhatsappPhone: boolean, token: string, email: string | null, customFields?: { [key: string]: string | string[] | null }) => {
    return axios.patch(MERCHANT + "/" + id, {
        merchantLabels,
        ownerId,
        name,
        cityId,
        isWhatsappPhone,
        email,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteMerchant = async (id: string, token: string) => {
    return axios.delete(MERCHANT + "/" + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updatePhoneMerchant = async (id: string, phone: string, isWhatsappPhone: boolean, token: string) => {
    return axios.patch(MERCHANT + "/" + id + "/update-merchant-phone", {
        phone,
        isWhatsappPhone
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const getMerchants = async (token: string) => {
    return axios.get(MERCHANTS,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const mergeMerchants = async (merchantOriginId: string, merchantDestinationId: string, newMerchant: MergeMerchantProps, token: string) => {
    return axios.post(MERCHANTS + "/" + merchantOriginId + "/merge",
        {
            merchantDestinationId,
            newMerchant
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}