import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import 'moment-duration-format';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { CustomField } from '../../interfaces/Settings';
import { exportWorkbook, setColumnWidths, styleColumns, addWorkSheetInfo } from './helperTemplateExcel';
import { createWorkbook } from './helperTemplateExcel';
import { createWorksheet } from './helperTemplateExcel';

export const exportTemplatePipelineExcel = async (
    nameFile: string,
    customFields: CustomField,
    source: string,
    typeAction: string,
    settings: any,
    nameInfo: string,
    nameHeader: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    user?: UserModel,
    param?: any,
    type?: string,
) => {
    let exportData: any[] = [];
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, "Sheet1");
    const worksheetInfo = createWorksheet(workbook, "Info");
    var header: string[] = []
    var subHeader: string[] = []
    let singleOptionSheetsPipelines = {};

    const createSingleOptionWorksheets = (customFields: CustomField) => {
        let singleOptionSheets: { [key: string]: string } = {};
        let usedNames = new Set<string>();

        Object.entries(customFields).forEach(([key, field]) => {
            if (field.type === 'option') {
                let baseWorksheetName = `Daftar ${field?.name?.replace(/\s+/g, ' ')}`;
                let worksheetName = baseWorksheetName.replace(/[^a-zA-Z0-9\s-_]/g, '');
                let counter = 1;

                while (usedNames.has(worksheetName.toLowerCase())) {
                    worksheetName = `${baseWorksheetName} (${counter})`;
                    counter++;
                }

                usedNames.add(worksheetName.toLowerCase());
                let singleOptionSheet = workbook.addWorksheet(worksheetName);
                singleOptionSheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
                    formatColumns: true,
                    autoFilter: true,
                });
                singleOptionSheet.getCell('A1').value = `${field.name}`;

                // Cek apakah 'field.value' adalah array of string atau array of TypeSerial
                if (Array.isArray(field.value) && typeof field.value[0] === 'string') {
                    // Jika field.value adalah string[]
                    (field.value as string[]).forEach((option: string, index: number) => {
                        singleOptionSheet.getCell(`A${index + 2}`).value = option;
                    });
                }
                singleOptionSheets[key] = worksheetName;
            }
        });
        return singleOptionSheets;
    };

    if (user?.data.company_id === '53a8b3c0-2457-4d22-82a2-bdf8cef4fec1' || user?.data.company_id === 'e8c4ba54-c7b0-44d3-a40a-c12b152e6eaf') {
        header = [...nameHeader.insert.pipeline.header];
        subHeader = [...nameHeader.insert.pipeline.subHeader];
    } else {
        let headerSplice = nameHeader.insert.pipeline.header.splice(0, 9)
        let subHeaderSplice = nameHeader.insert.pipeline.subHeader.splice(0, 9)
        header = [...headerSplice];
        subHeader = [...subHeaderSplice];
    }

    let worksheetUser = workbook.addWorksheet('Daftar User');
    await worksheetUser.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetUser.getCell('A1').value = `Daftar User`;
    await param.dataUsers.forEach((user: any) => {
        worksheetUser.addRow([user.name]);
    });

    let worksheetMerchants = workbook.addWorksheet('Daftar Merchants');
    await worksheetMerchants.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetMerchants.getCell('A1').value = `Daftar Merchants`;
    await param.dataMerchants.forEach((user: any) => {
        worksheetMerchants.addRow([user.name]);
    });

    let worksheetProducts = workbook.addWorksheet('Daftar Produk');
    await worksheetProducts.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetProducts.getCell('A1').value = `Daftar Produk`;
    await param.dataProducts.forEach((user: any) => {
        worksheetProducts.addRow([user.name]);
    });

    let worksheetProgress = workbook.addWorksheet('Daftar Progress');
    await worksheetProgress.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetProgress.getCell('A1').value = `Daftar Progress`;
    await param.dataProgress.forEach((user: any) => {
        worksheetProgress.addRow([user.name]);
    });

    let worksheetSource = workbook.addWorksheet('Daftar Source');
    await worksheetSource.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetSource.getCell('A1').value = `Daftar Source`;
    await param.dataSources.forEach((user: any) => {
        worksheetSource.addRow([user.name]);
    });

    // Daftar Single Option
    singleOptionSheetsPipelines = createSingleOptionWorksheets(customFields);

    // kalau type nya error akan menambahkan kolom di bawah ini
    if (type === 'error') {
        header.push('Error');
        subHeader.push('Abaikan kolom ini');
        Array.from(param.listErrorInsert).map((row: any, index) => {
            var temp_excel = [];
            temp_excel.push(
                row.pic_name,
                row.merchant_name,
                row.nominal,
                row.product_name,
                row.progress_name,
                row.source_name,
                row.date_start,
                row.date_end,
                row.note,
                ...(user?.data.company_id === '53a8b3c0-2457-4d22-82a2-bdf8cef4fec1' ||
                    user?.data.company_id === 'e8c4ba54-c7b0-44d3-a40a-c12b152e6eaf'
                    ? Object.keys(customFields).map((field: any) => row[field])
                    : [])
            );

            // Menambahkan row.error di akhir
            temp_excel.push(row.error);
            exportData.push(temp_excel);
        });
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, customFields, source, type!, typeAction, user!, param, singleOptionSheetsPipelines);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);

    exportWorkbook(workbook, worksheet, source, `${nameFile}.xlsx`, setIsLoading);
};


export const addDataRows = (
    worksheet: ExcelJS.Worksheet,
    data: any[],
    customFields: CustomField,
    source: string,
    type: string,
    typeAction: string,
    user: UserModel,
    param?: any,
    singleOptionSheets?: any
) => {
    data.forEach((rowData, index) => {
        worksheet.addRow(rowData);
    });

    const addSingleOptionValidation = (startColumn: string, customFields: any) => {
        let currentColumn = startColumn.charCodeAt(0);
        Object.entries(customFields).forEach(([key, field]: [string, any]) => {
            if (field.type === 'option') {
                const columnLetter = String.fromCharCode(currentColumn);
                const worksheetName = singleOptionSheets[key]; // Use the unique worksheet name
                if (typeAction === 'update') {
                    var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;
                }
                for (let i = 3; i <= (typeAction === 'update' ? dataLength + 2 : 1000); i++) {
                    worksheet.getCell(`${columnLetter}${i}`).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        showErrorMessage: true,
                        formulae: [`='${worksheetName}'!$A$2:$A$${field.value.length + 1}`],
                        errorStyle: 'error',
                        error: `Pilih ${field.name}`,
                    };
                }
            }
            currentColumn++;
        });
    };

    for (let i = 3; i < 550; i++) {
        if (typeAction === 'insert') {
            worksheet.getCell('A' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar User'!$A$2:$A$${param.dataUsers.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih user',
            };
            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Merchants'!$A$2:$A$${param.dataMerchants.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih Merchants',
            };
            worksheet.getCell('D' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Produk'!$A$2:$A$${param.dataProducts.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih Produk',
            };
            worksheet.getCell('E' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Progress'!$A$2:$A$${param.dataProgress.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih Progress',
            };
            worksheet.getCell('F' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Source'!$A$2:$A$${param.dataSources.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih Source',
            };

            addSingleOptionValidation('J', customFields);

            worksheet.getCell('C' + i).numFmt = '#,##0;-#,##0';
        }
    }
};