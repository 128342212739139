import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { customNumberFormatString, handleCaps } from "../../functions/general";
import Select from "react-select"
import { nanoid } from "@reduxjs/toolkit";
import useSWR from "swr";
import axios from "axios";
import { Currency, ExpenseCategory, Reimburse } from "../../interfaces/Reimbursement";
import { DatePicker } from "rsuite";
import { updateReimbursement } from "../../api/ReimbursementCRUD";
import moment from "moment-timezone";
import { insertReimburseAttachment } from "../../api/ResourceAPI";
import { useIntl } from "react-intl";
import useTodayRange from "../../hooks/TodayRange";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import InputAttachment from "../Input/InputAttachment";

interface Props {
    reimburse: Reimburse
}

interface SelectOption {
    value: string
    label: string
}

const UpdateReimbursement: FC<Props> = ({ reimburse }) => {
    const todayRange = useTodayRange();

    const API_URL = process.env.REACT_APP_API_URL
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [categoryData, setCategoryData] = useState<SelectOption[]>([]);
    const [currencyData, setCurrencyData] = useState<SelectOption[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>(reimburse.attachmentsUrl);
    const intl = useIntl()

    function deleteFile(indexDelete: number) {
        const filteredFile = file.filter((file: File | string, index: number) => index !== indexDelete);
        setFile(filteredFile);
        formik.handleChange({
            target: {
                name: 'attachment',
                value: filteredFile
            }
        })
    }

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)
    const { data: categories = [], error: categoriesError, isLoading: categoriesLoading } = useSWR(`${API_URL}reimbursement-categories`, fetcher)
    const { data: currencies = [], error: currenciesError, isLoading: currenciesLoading } = useSWR(`${API_URL}currencies`, fetcher)

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormatString(e.target.value);
        formik.setFieldValue('nominal', formattedValue);
    };

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
        if (e.target.files && e.target.files.length > 0) {
            const files: File[] = Array.from(e.target.files);
            setFile([...file, ...files])
            formik.handleChange({
                target: {
                    name: key,
                    value: [...file, ...files]
                }
            })
        } else {
            setFile([]);
            formik.handleChange({
                target: {
                    name: key,
                    value: []
                }
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            name: reimburse.name,
            category: reimburse.reimbursementCategory.id,
            dateSpend: reimburse.dateSpend,
            currency: reimburse.companyCurrency.id,
            nominal: customNumberFormatString((reimburse.value).toString()),
            description: reimburse.description,
            attachment: reimburse.attachmentsUrl,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            category: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CATEGORY" }) })),
            nominal: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.EXPENSE_NOMINAL" }) }))  // Moved this up so it runs first
                .test('is-numeric', 'Invalid format', value => {
                    return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
                }),
            dateSpend: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.EXPENSE_DATE" }) })).nullable(),
            currency: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CURRENCY" }) })),
            attachment: Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_EXPENSE" }) })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_EXPENSE" }) })).nullable(),
            description: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DESCRIPTION" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)
            try {
                let attachments: string[] = []
                if (values.attachment.length > 0) {
                    for (let i = 0; i < values.attachment.length; i++) {
                        let file: any = values.attachment[i]
                        if (file instanceof File) {
                            const resourceResponse = await insertReimburseAttachment(file)
                            attachments.push(resourceResponse.data.data[0])
                        }
                        else attachments.push(file)
                    }
                }

                const response = await updateReimbursement(reimburse.id, values.name, values.description, parseInt(values.currency), moment(values.dateSpend).format('YYYY-MM-DD HH:mm:ss'), values.category, parseInt((values.nominal).split('.').join('')), attachments, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    setTablesData('my-reimbursements', (tablesData['my-reimbursements'].map(item => {
                        if (item.id === reimburse.id) {
                            return {
                                ...item,
                                name: response.data.data.name,
                                description: response.data.data.description,
                                value: response.data.data.value,
                                dateSpend: response.data.data.dateSpend,
                                reimbursementCategory: response.data.data.reimbursementCategory,
                                companyCurrency: response.data.data.companyCurrency,
                                attachmentsUrl: response.data.data.attachmentsUrl
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            // setFile(null)
            fileInputRef.current?.value && (fileInputRef.current.value = "")
            formik.setFieldValue('dateSpend', '')
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        if (!categoriesLoading) {
            const categoryData = categories.map((category: ExpenseCategory) => ({
                value: category.id,
                label: category.name
            }));

            // Prepend the two objects to the salesData array
            setCategoryData(categoryData);
        }
    }, [categories]);

    useEffect(() => {
        if (!currenciesLoading) {
            let sortCurrencies = currencies

            sortCurrencies.forEach(function (currency: Currency, index: number) {
                if (currency.code === "IDR" && currency.name === "Rupiah") {
                    sortCurrencies.splice(index, 1);
                    sortCurrencies.unshift(currency);
                }
            });

            const currencyData = sortCurrencies.map((currency: Currency) => ({
                value: currency.id,
                label: currency.code + ' - ' + currency.name + (currency.symbol ? (' (' + currency.symbol + ')') : '')
            }));

            // Prepend the two objects to the salesData array
            setCurrencyData(currencyData);
        }
    }, [currencies]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={`update-reimbursement-modal-${reimburse.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {reimburse.name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.NAME' })}
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                            {/* Category */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.CATEGORY' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    key={nanoid()}
                                    options={categoryData}
                                    placeholder={intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_CATEGORY' })}
                                    value={categoryData.find(option => option.value === formik.values.category) || null}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "category",
                                                value: option?.value || ""
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.category === "" && formik.setFieldTouched("category", true)
                                    }
                                    className="w-100"
                                    isDisabled={categoriesLoading}
                                />
                            </div>
                            {formik.touched.category && formik.errors.category && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.category}</span>
                                </div>
                            )}
                            {/* Date */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.EXPENSE_DATE' })}</label>
                            <div className="input-group mb-3">
                                <DatePicker
                                    oneTap
                                    ranges={todayRange}
                                    editable={false}
                                    format="dd MMM yyyy"
                                    value={formik.values.dateSpend ? new Date(formik.values.dateSpend) : null}
                                    style={{ width: "100%", zIndex: 0 }}
                                    container={document.querySelector(`#update-reimbursement-modal-${reimburse.id}`) as HTMLElement}
                                    onChange={(value: any) => {
                                        if (value) formik.setFieldValue('dateSpend', value)
                                        else formik.setFieldValue('dateSpend', null)
                                    }}
                                    shouldDisableDate={(date) => {
                                        return date > new Date();
                                    }}
                                />
                            </div>
                            {formik.touched.dateSpend && formik.errors.dateSpend && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.dateSpend}</span>
                                </div>
                            )}
                            {/* Currency */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.CURRENCY' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    key={nanoid()}
                                    options={currencyData}
                                    placeholder={intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_CURRENCY' })}
                                    value={currencyData.find(option => option.value === formik.values.currency) || null}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "currency",
                                                value: option?.value || ""
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.currency === "" && formik.setFieldTouched("currency", true)
                                    }
                                    className="w-100"
                                    isDisabled={currenciesLoading}
                                />
                            </div>
                            {formik.touched.currency && formik.errors.currency && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.currency}</span>
                                </div>
                            )}
                            {/* Nominal */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.EXPENSE_NOMINAL' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.EXPENSE_NOMINAL' })}
                                    value={formik.values.nominal}
                                    onChange={handleNominalChange}
                                    onBlur={formik.handleBlur}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.nominal && formik.errors.nominal },
                                    )}
                                    type='text'
                                    name='nominal'
                                    autoComplete='off'
                                    style={{ zIndex: 0 }}
                                />
                            </div>
                            {formik.touched.nominal && formik.errors.nominal && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.nominal}</span>
                                </div>
                            )}
                            {/* Description */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DESCRIPTION' })}</label>
                            <div className="input-group mb-3">
                                <textarea
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.DESCRIPTION' })}
                                    id="floatingTextarea"
                                    {...formik.getFieldProps('description')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.description && formik.errors.description },
                                    )}
                                    style={{ zIndex: 0 }}
                                    name="description"
                                    maxLength={255}
                                    onInput={(e) => handleCaps(e)}
                                >
                                </textarea>
                            </div>
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.description}</span>
                                </div>
                            )}
                            {/* Proof */}
                            {/* <span className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_EXPENSE' })}</span><br />
                            <small><i>*Format PNG, JPG, JPEG</i></small>
                            <div className="d-flex justify-content-between mb-3">
                                <input
                                    type="file"
                                    accept=".png, .jpg, .jpeg"
                                    style={{ zIndex: 0 }}
                                    ref={fileInputRef}
                                    onChange={(e) => handleFileChange(e, 'attachment')}
                                    onBlur={() => formik.setFieldTouched("attachment", true)}
                                    className={clsx(
                                        '',
                                        // { 'is-invalid': formik.touched.attachment && formik.errors.attachment },
                                    )}
                                    id="files-upload-update"
                                    multiple
                                />
                                <label htmlFor="files-upload-update" className="btn btn-light text-dark">{intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_IMAGE' })}</label>

                                <button type="button" className={`btn btn-light-danger ${formik.values.attachment && formik.values.attachment.length > 0 ? '' : 'd-none'}`} onClick={() => { formik.setFieldValue("attachment", []); fileInputRef.current!.value = ''; setFile([]); }}>
                                    {intl.formatMessage({ id: 'FORM.ACTION.REMOVE_ALL' })}
                                </button>
                            </div>
                            {formik.touched.attachment && formik.errors.attachment && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                </div>
                            )}
                            {formik.values.attachment && formik.values.attachment.length > 0 && (
                                <div className="d-flex flex-wrap gap-5">
                                    {formik.values.attachment.map((file: File | string, index: number) => {
                                        return (
                                            <div className="position-relative">
                                                <img src={file instanceof File ? URL.createObjectURL(file) : file} className="rounded border border-gray-400" alt={file instanceof File ? file.name : "bukti"} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                <div className="position-absolute translate-middle badge badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )} */}
                            <span className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_EXPENSE' })}</span><br />
                            <small><i>*Format PNG, JPG, JPEG</i></small>
                            <InputAttachment keyString={'attachment'} formik={formik} isMulti={true} type="update" accept="image/png, image/jpeg, image/jpg" />
                            {
                                formik.touched.attachment && formik.errors.attachment &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                    </div>
                                )
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateReimbursement }