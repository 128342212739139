import React, { ReactNode, useEffect, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Tooltip, Typography } from "@mui/material";
import moment from 'moment-timezone';
import { ContentTemplateAttachment, DetailBroadcastEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType';
import { useEmailStore } from '../../../../stores/EmailStore';
import DOMPurify from 'dompurify';
import { ModalAttachment } from '../ModalAttachment';
import { Modal } from 'bootstrap';
import { renderTableContent } from '../../UtilityComponent';
import { renderTooltipContent } from '../../UtilityComponent';
import { ParsedContent } from '../../../../interfaces/Email/Content';
import MasterTableEmail from './MasterTableEmail';
import useModalEffect from '../../../../hooks/useModalEffect';
import ModalViewListUrl from '../../ViewUrl/ModalViewListUrl';
import { ButtonEksporEmail } from '../../TableImport/ButtonEksporEmail';

const DetailBroadcastEmail = () => {
  const { idDetailBroadcastEmail } = useEmailStore();
  const [showModalAttachment, setShowModalAttachment] = useState<boolean>(false);
  const [url, setUrl] = useState<string[]>([]);
  const [isShowLink, setIsShowLink] = useState<boolean>(false);
  const [idsLink, setIdsLink] = useState<string[]>([]);

  const parseContent = (content: any): ParsedContent => {
    try {
      return JSON.parse(content);
    } catch (error) {
      console.error("Error parsing content:", error);
      return { subject: 'Error', message: 'Invalid content format', attachment: [] };
    }
  };

  const baseTableColumns: TableColumn<DetailBroadcastEmailTypes>[] = [
    {
      name: 'Message',
      width: '350px',
      sortable: true,
      allowOverflow: true,
      reorder: true,
      // selector: row => row.content || '',
      cell: row => {
        const parsedContent = parseContent(row.content);
        return (
          <Tooltip title={renderTooltipContent(parsedContent)} arrow>
            <div className="truncate-text">
              {renderTableContent(parsedContent)}
            </div>
          </Tooltip>
        );
      },
    },
    {
      name: 'Status Pengiriman',
      width: '150px',
      sortable: true,
      allowOverflow: true,
      reorder: true,
      selector: row => row.sendStatus,
      cell: row => {
        return (
          <span className={`status-badge status-${row.sendStatus.toLowerCase()}`}>
            {row.sendStatus}
          </span>
        );
      }
    },
    {
      name: 'Waktu Terkirim',
      width: '200px',
      sortable: true,
      selector: row => row.sendAt || '',
      cell: row => {
        return row.sendAt ? moment(row.sendAt).format("DD MMM YYYY HH:mm") : '-'
      },
      reorder: true,
    },
    {
      name: 'Status Terbaca',
      width: '150px',
      sortable: true,
      selector: row => row.emailStatus || '',
      cell: row => {
        return (
          <span className={`status-badge status-${row.emailStatus.toLowerCase()}`}>
            {row.emailStatus}
          </span>
        )
      },
      reorder: true,
    },
    {
      name: 'Email Penerima',
      width: '230px',
      sortable: true,
      selector: row => row.userGroupContactData.email || '',
      cell: row => row.userGroupContactData.email,
      reorder: true,
    },
    {
      name: 'Status Email',
      width: '230px',
      sortable: true,
      selector: row => row.bounceStatus || '',
      cell: row => {
        if (row.bounceStatus) {
          return (
            <span className='status-badge status-failed'>Invalid Email</span>
          )
        } else {
          return (
            <span className='status-badge status-success'>Valid Email</span>
          )
        }
      },
      reorder: true,
    },
    {
      name: 'Url',
      width: '200px',
      sortable: false,
      cell: row => {
        if (row.linkId && row.linkId.length > 0) {
          return (
            <span role="button" onClick={() => {
              setIsShowLink(true);
              setIdsLink(row.linkId);
            }}>
              <span className='d-flex justify-content-between gap-2'>
                {row.clicked ? (
                  <span className='status-badge status-success'>Clicked</span>
                ) : (
                  <span className='status-badge status-failed'>Not Clicked</span>
                )}
                <i className="fas fa-link fs-3 float-end"></i>
              </span>
            </span>
          )
        }
        return '-'
      }
    },
    {
      name: 'Attachment',
      width: '100px',
      sortable: false,
      cell: row => {
        const fileUrl = parseContent(row.content);
        return fileUrl.attachment.length > 0 ? (
          <span role="button" onClick={() => {
            setShowModalAttachment(true);
            setUrl(fileUrl.attachment as string[]);
          }}>
            <i className="fas fa-file-invoice fs-3 float-end"></i>
          </span>
        ) : '-';
      },
      reorder: true,
    },
  ];

  useModalEffect({
    showModal: showModalAttachment,
    setShowModal: setShowModalAttachment,
    modalId: `attachments-broadcast-modal-${idDetailBroadcastEmail}`
  })

  useModalEffect({
    showModal: isShowLink,
    setShowModal: setIsShowLink,
    modalId: `modal-view-list-url`
  })

  let actions: ReactNode[] = []
  actions.push(
    <ButtonEksporEmail source='export-broadcast-email' />,
  )

  return (
    <>
      <MasterTableEmail
        tableColumns={baseTableColumns}
        actions={actions}
        tableKey='detailBroadcastEmail'
        apiURL={`broadcast/get-broadcast-detail?broadcastId=${idDetailBroadcastEmail}`}
      />

      {
        showModalAttachment && <ModalAttachment id={idDetailBroadcastEmail} title="Attachment" url={url} />
      }

      {
        isShowLink && <ModalViewListUrl ids={idsLink} setShowModal={setIsShowLink} />
      }
    </>
  )
}

export default DetailBroadcastEmail