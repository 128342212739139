import React, { useRef } from 'react'
import { CustomFieldType } from '../../../api/LogsCRUD'
import { FormikProps } from 'formik'
import clsx from "clsx";
import { customNumberFormat, handleCaps } from '../../../functions/general';
import { useIntl } from 'react-intl';
import InputAttachment from '../../Input/InputAttachment';
import InputPhone from '../../Input/InputPhone';
import { DatePicker } from 'rsuite';
import Select, { components } from 'react-select';
import useTodayRange from '../../../hooks/TodayRange';
import moment from 'moment-timezone';
import { Merchant } from '../../../interfaces/Merchant';
import { User } from '../../../interfaces/User';
import { Pipeline } from '../../../interfaces/Pipeline';
import { SelectOption } from '../../../interfaces/SelectOption';


interface RenderCustomFieldLogProps {
    customFieldsLog: CustomFieldType
    formik: FormikProps<any>
    selectedProgress: string
    merchants: Merchant[]
    users: User[]
    pipelines: Pipeline[]
    merchantLoading: boolean
    userLoading: boolean
    pipelineLoading: boolean
}

const RenderCustomFieldLog = ({ customFieldsLog, formik, selectedProgress, merchants, users, pipelines, merchantLoading, userLoading, pipelineLoading }: RenderCustomFieldLogProps) => {
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const todayRange = useTodayRange();
    const intl = useIntl()

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.Option>
        );
    };

    const CustomSingleValue = (props: any) => {
        return (
            <components.SingleValue {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.SingleValue>
        );
    };

    return (
        <>
            {Object.entries(customFieldsLog).map(([key, field], index) => {

                // if (!field.progress.includes(selectedProgress)) return null
                if (field.type === 'text') {
                    return (
                        <div key={index} className='mb-3'>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group ">
                                <input
                                    placeholder={field.name}
                                    {...formik.getFieldProps(key)}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched[key] && formik.errors[key] },
                                    )}
                                    type='text'
                                    name={key}
                                    autoComplete='off'
                                    style={{ zIndex: 0 }}
                                    required={field.required}
                                    onInput={(e) => handleCaps(e)}
                                    ref={ref => {
                                        refArray.current[index] = ref; // took this from your guide's example.
                                    }}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'link') {
                    return (
                        <div key={index} className='mb-3'>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group ">
                                <input
                                    placeholder={field.name}
                                    {...formik.getFieldProps(key)}
                                    className={clsx(
                                        'form-control form-control-lg txt-link',
                                        { 'is-invalid': formik.touched[key] && formik.errors[key] },
                                    )}
                                    type='text'
                                    name={key}
                                    autoComplete='off'
                                    style={{ zIndex: 0 }}
                                    required={true}
                                    ref={ref => {
                                        refArray.current[index] = ref; // took this from your guide's example.
                                    }}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'phone') {
                    return (
                        <div className='mb-3'>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <InputPhone keyString={key} formik={formik} type="input" />
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'image') {
                    return (
                        <div className='mb-3'>
                            {/* Attachments */}
                            <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                            <small><i>*Format JPG, JPEG, PNG</i></small>
                            <InputAttachment keyLog='log' keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'images') {
                    return (
                        <div className='mb-3'>
                            {/* Attachments */}
                            <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                            <small><i>*Format JPG, JPEG, PNG</i></small>
                            <InputAttachment keyLog='log' keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'file') {
                    return (
                        <div className='mb-3'>
                            {/* Attachments */}
                            <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                            <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                            <InputAttachment keyLog='log' keyString={key} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'files') {
                    return (
                        <div className='mb-3'>
                            {/* Attachments */}
                            <span className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</span><br />
                            <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                            <InputAttachment keyLog='log' keyString={key} formik={formik} isMulti={true} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'date') {
                    return (
                        <div className='mb-3'>
                            {/* Date */}
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group ">
                                <DatePicker
                                    ranges={todayRange}
                                    oneTap
                                    editable={false}
                                    format="dd MMM yyyy"
                                    value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]) : null}
                                    defaultValue={null}
                                    style={{ width: "100%", zIndex: 0 }}
                                    container={document.querySelector('#tambah-log-modal') as HTMLElement}
                                    onChange={(value: any) => {
                                        if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                        else formik.setFieldValue(key, null)
                                    }}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'option') {
                    const options = Array.isArray(field.value) ? field.value.map((option: string, index: number) => ({
                        value: option,
                        label: option
                    })) : []

                    return (
                        <div className='mb-3'>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group ">
                                <Select
                                    // key={nanoid()}
                                    isClearable
                                    options={options}
                                    placeholder={`Pilih ${field.name}`}
                                    value={options.find((option: any) => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                    onChange={
                                        (option: any) => formik.handleChange({
                                            target: {
                                                name: key,
                                                value: option?.value || ""
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                    }
                                    className="w-100"
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                } else if (field.type === 'merchant') {
                    const options = merchants.map((option: Merchant) => ({
                        value: option.id,
                        label: option.name
                    }))

                    return (
                        <>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group mb-3">
                                <Select
                                    isMulti
                                    isClearable
                                    options={options}
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${field.name}`}
                                    value={
                                        options.filter(option =>
                                            formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                        )
                                    }
                                    onChange={(selectedOptions) =>
                                        formik.setFieldValue(
                                            key,
                                            selectedOptions ? selectedOptions.map((option) => option.value) : []
                                        )
                                    }
                                    onBlur={() => {
                                        if (formik.values[key as keyof typeof formik.values] === "") {
                                            formik.setFieldTouched(key, true);
                                        }
                                    }}
                                    className="w-100"
                                    isLoading={merchantLoading}
                                    isDisabled={merchantLoading}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </>
                    )
                } else if (field.type === 'user') {
                    const options = users.map((option: User) => ({
                        value: option.id,
                        label: option.name
                    }))

                    return (
                        <>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group mb-3">
                                <Select
                                    isMulti
                                    isClearable
                                    options={options}
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${field.name}`}
                                    value={
                                        options.filter(option =>
                                            formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                        )
                                    }
                                    onChange={(selectedOptions) =>
                                        formik.setFieldValue(
                                            key,
                                            selectedOptions ? selectedOptions.map((option) => option.value) : []
                                        )
                                    }
                                    onBlur={() => {
                                        if (formik.values[key as keyof typeof formik.values] === "") {
                                            formik.setFieldTouched(key, true);
                                        }
                                    }}
                                    className="w-100"
                                    isLoading={userLoading}
                                    isDisabled={userLoading}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </>
                    )
                } else if (field.type === 'pipeline') {

                    const options = pipelines.map((option: any) => ({
                        ...option,
                        value: option.id,
                        label: option.name
                    }))

                    return (
                        <>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group mb-3">
                                <Select
                                    isClearable
                                    options={options}
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${field.name}`}
                                    value={options.find((option: SelectOption) => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: key,
                                                value: option?.value || ""
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                    }
                                    components={{
                                        Option: CustomOption,
                                        SingleValue: CustomSingleValue,
                                    }}
                                    className="w-100"
                                    isLoading={pipelineLoading}
                                    isDisabled={pipelineLoading}
                                />
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </>
                    )
                } else if (field.type === 'multiple') {
                    return (
                        <div className='mb-3'>
                            <label className={`form-label fs-6 fw-bolder text-dark ${field.required && 'required'}`}>{field.name}</label>
                            <div className="input-group ">
                                <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
                                    {
                                        field.value.map((option: string, index: number) => (
                                            <div style={{ display: 'flex', padding: "3px", overflowWrap: "anywhere" }} key={index}>
                                                <input
                                                    key={index}
                                                    {...formik.getFieldProps(key)}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={option as string}
                                                    name={key}
                                                    checked={formik.values[key as keyof typeof formik.values]?.includes(option as string)}
                                                />
                                                <label className="form-check-label me-2">{option}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                formik.touched[key] && formik.errors[key] &&
                                (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors[key]}</span>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                return null
            })}
        </>
    )
}

export default RenderCustomFieldLog