import React, { FC, useRef, useState } from 'react';
import { handleCaps } from '../../functions/general';
import useSearch from '../../hooks/Search';
import { TableColumn } from 'react-data-table-component';
import { useIntl } from 'react-intl';
import { useDataTableStore } from '../../stores/DataTableStore';

interface Props {
    tableKey: string
    tableColumns: TableColumn<any>[]
    order?: 'asc' | 'desc'
    orderBy?: string | number
}

const InputSearch: FC<Props> = ({ tableKey, tableColumns, order, orderBy }) => {
    const { filterText, handleSearch } = useSearch(tableKey, tableColumns, order, orderBy);
    const intl = useIntl()

    return (
        <>
            <span className="svg-icon svg-icon-1 position-absolute ms-6 mt-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                </svg>
            </span>
            <input
                type="search"
                data-kt-lists-table-filter="search"
                className="form-control form-control-solid w-210px ps-15 skeleton mt-3"
                placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })}
                id="inputSearch"
                value={filterText[tableKey]}
                onChange={e => { handleSearch(e.target.value) }}
                onInput={(e) => handleCaps(e)}
            />
        </>
    );
};

export default InputSearch;


