import { FC, useRef, useEffect, useState, RefObject, CSSProperties } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { insertEvent } from "../../api/EventCRUD";
import { Calendar } from "../../interfaces/Calendar";
import axios from "axios";
import { SelectOption } from "../../interfaces/SelectOption";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import useSWR from "swr";
import { SketchPicker } from "react-color";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import TimezoneSelect from "./TimezoneSelect";
import { DatePicker } from 'rsuite';
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl } from "../../functions/general";
import { UserCompany } from "../../interfaces/UserCompany";
import { useSettingsStore } from "../../stores/SettingsStore";
import { Merchant } from "../../interfaces/Merchant";
import { useIntl } from "react-intl";
import useOptionReminder from "../../hooks/OptionReminder";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface eventProps {
    id: string,
    events: []
}

interface Props {
    startDate?: string
    calendarRef: RefObject<FullCalendar>
    selectedCalendars: string[]
    setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>
    listEvent: eventProps[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
    calendar: any
    sortCalendar: (a: { name: string }, b: { name: string }) => void
}

interface SelectAttendees {
    label: string
    value: string
}

interface GroupedAttendanceOption {
    label: string;
    value: string;
    options: (SelectOption)[];
};

const optionType = [
    {
        value: "OTHER",
        label: "OTHER",
    },
    {
        value: "MEETING",
        label: "MEETING",
    },
    {
        value: "PIPELINE",
        label: "PIPELINE",
    },
]

const AddEvent: FC<Props> = ({ startDate, calendarRef, setListEvent, listEvent, selectedCalendars, setSelectedCalendars, calendar, sortCalendar }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const token = useAccessToken()
    const { settings } = useSettingsStore()
    const [calendarData, setCalendarData] = useState<SelectOption[]>([]);
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [merchantsData, setMerchantsData] = useState<SelectOption[]>([]);
    const [selectedAttendees, setSelectedAttendees] = useState<SelectAttendees[]>([]);
    const [groupedAttendanceOptions, setGroupedAttendanceOptions] = useState<GroupedAttendanceOption[]>([]);
    const [selectedReminder, setSelectedReminder] = useState<number>(0);
    const [isAllDay, setIsAllDay] = useState(true);
    const [selectFilterAttendance, setSelectFilterAttendance] = useState("Users");
    const intl = useIntl()
    const optionReminder = useOptionReminder();

    const optionFilterAttendance = [
        {
            value: "Users",
            label: intl.formatMessage({ id: "CALENDAR.FILTER.USERS" }),
        },
        {
            value: settings.merchant_title,
            label: settings.merchant_title,
        }
    ];

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL
    const { data: users = [], isLoading: usersLoading } = useSWR(`${API_URL}calendars/company?includeSelf=false`, fetcher)
    const { data: merchants = [], isLoading: merchantsLoading } = useSWR(`${API_URL}merchants`, fetcher)

    const formik = useFormik({
        initialValues: {
            idCalendar: '',
            name: '',
            description: '',
            type: optionType[0].value,
            timezone: '',
            status: 'CONFIRMED',
            startDate: startDate ? moment(startDate).format("YYYY-MM-DD HH:mm") : moment().format("YYYY-MM-DD HH:mm"),
            endDate: startDate ? moment(startDate).add(1, 'hour').format("YYYY-MM-DD HH:mm") : moment().add(1, 'hour').format("YYYY-MM-DD HH:mm"),
            color: '#009CE0',
            reminderMinutes: 0,
            link: "",
            location: null,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TITLE" }) })),
            link: Yup.string().nullable().test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" }), function (value) {
                if (value && !isValidUrl(value)) {
                    return false;
                }
                return true;
            }),
        }),
        onSubmit: (values, { setStatus, setSubmitting }) => {
            Swal.fire({
                title: intl.formatMessage({ id: "FORM.VALIDATION.NOTIFICATION" }),
                html: intl.formatMessage({ id: 'FORM.VALIDATION.SEND_NOTIFICATION' }),
                icon: "question",
                showCancelButton: true,
                showDenyButton: true,
                buttonsStyling: false,
                confirmButtonText: intl.formatMessage({ id: 'FORM.ACTION.SEND_NOTIFICATION' }),
                denyButtonText: intl.formatMessage({ id: 'FORM.ACTION.DONT_SEND_NOTIFICATION' }),
                cancelButtonText: intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
                customClass: {
                    confirmButton: "btn btn-active-color-primary",
                    denyButton: "btn btn-active-color-gray-400",
                    cancelButton: "btn btn-active-color-gray-400",
                },
                reverseButtons: true,
                heightAuto: false,
                target: document.getElementById('add-event-modal'),
            }).then(async (result) => {
                var notification = false

                if (result.isConfirmed) {
                    notification = true
                } else if (result.isDenied) {
                    notification = false
                }

                if (!result.isDismissed) {
                    setSubmitting(true);
                    setModalBtnLoading(true)

                    try {
                        const response = await insertEvent({
                            name: values.name,
                            description: values.description,
                            type: values.type,
                            timezone: values.timezone,
                            status: values.status,
                            isAllDay: isAllDay,
                            start: {
                                date: isAllDay ? moment(`${values.startDate}`).format("YYYY-MM-DD") : null,
                                dateTime: !isAllDay ? moment(`${values.startDate}`).toISOString(true) : null
                            },
                            end: {
                                date: isAllDay ? moment(`${values.endDate}`).add(isAllDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                                dateTime: !isAllDay ? moment(`${values.endDate}`).add(isAllDay ? 1 : 0, 'days').toISOString(true) : null,
                            },
                            color: values.color,
                            userAttendees: selectedAttendees.reduce((acc: object[], e) => {
                                if (e.value.includes('-USER')) {
                                    acc.push({ id: e.value.replace(/-USER$/, '') });
                                }
                                return acc;
                            }, []),
                            merchantAttendees: selectedAttendees.reduce((acc: object[], e) => {
                                if (e.value.includes('-MERCHANT')) {
                                    acc.push({ id: e.value.replace(/-MERCHANT$/, '') });
                                }
                                return acc;
                            }, []),
                            reminderMinutes: selectedReminder === 0 ? null : selectedReminder,
                            location: values.location,
                            idCalendar: values.idCalendar,
                            sendNotification: notification,
                            onlineMeetLink: values.link ? values.link : null,
                            token: token,
                        })
                        if (response.data.success || response.status === 201) {
                            Swal.fire({
                                icon: 'success',
                                heightAuto: false,
                                title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" }),
                                timer: 4000,
                                // timerProgressBar: true
                            })

                            // calendarRef.current?.getApi().removeAllEventSources();
                            selectedCalendars.map((value) => {
                                calendarRef.current?.getApi().getEventSourceById(value)?.remove()
                            })
                            setListEvent([])

                            const updatedListEvent = listEvent.map((eventItem: eventProps) => {
                                if (eventItem.id === values.idCalendar) {
                                    return {
                                        ...eventItem,
                                        events: [...eventItem.events, response.data.data]
                                    };
                                }
                                return eventItem;
                            });

                            setListEvent(updatedListEvent as eventProps[])
                            var dataSelectCalendar = [...selectedCalendars, values.idCalendar]
                            updatedListEvent.forEach((e: any) => {
                                if (dataSelectCalendar.includes(e.id)) calendarRef.current?.getApi().addEventSource({ id: e.id, events: e.events })
                            })

                            if (dataSelectCalendar.length === calendar.length) setSelectedCalendars(["all", ...dataSelectCalendar])
                            else setSelectedCalendars(dataSelectCalendar);

                            closeModalRef.current?.click();
                        }
                        // else {
                        //     throw new Error(response.data.message)
                        // }
                    } catch (error: any) {
                        Swal.fire({
                            icon: 'error',
                            title: error.response.data.message,
                            confirmButtonText: 'Ok',
                            heightAuto: false,
                        })
                    } finally {
                        setModalBtnLoading(false)
                    }
                }
            });
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            calendarRef.current?.getApi().getEventById('tempEvent')?.remove()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        var data = calendar.filter((calendar: Calendar) => calendar.access !== "READER");
        const calendarData = data.sort(sortCalendar).map((calendar: Calendar) => ({
            value: calendar.id,
            label: calendar.name,
        }));

        calendarData.sort((a: any, b: any) => (a.label.includes("[PERSONAL]") ? -1 : b.label.includes("[PERSONAL]") ? 1 : 0));
        setCalendarData(calendarData)
    }, [calendar])

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: UserCompany) => ({
                value: user.id + "-USER",
                label: user.ownerName,
            }));
            setUsersData(usersData)
        }
    }, [users])

    useEffect(() => {
        if (!merchantsLoading) {
            const merchantsData = merchants.map((merchant: Merchant) => ({
                value: merchant.id + "-MERCHANT",
                label: merchant.name
            }));
            setMerchantsData(merchantsData)
        }
    }, [merchants])

    useEffect(() => {
        setGroupedAttendanceOptions([
            {
                label: intl.formatMessage({ id: "CALENDAR.FILTER.USERS" }),
                value: "Users",
                options: usersData
            },
            {
                label: settings.merchant_title,
                value: settings.merchant_title,
                options: merchantsData
            }
        ])
    }, [usersData, merchantsData])

    useEffect(() => {
        if (calendarData.length > 0) {
            formik.handleChange({
                target: {
                    name: "idCalendar",
                    value: calendarData[0].value
                }
            })
        }
    }, [calendarData])

    useEffect(() => {
        calendarRef.current?.getApi().addEvent({
            id: "tempEvent",
            title: '(No Title)',
            start: startDate,
            allDay: true
        })
    }, [])

    const handleAttendeesChange = (selectedOptions: any) => {
        setSelectedAttendees(selectedOptions)
    };

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={"add-event-modal"} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.ADD_EVENT" })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.CALENDAR' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    key={nanoid()}
                                    options={calendarData}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.CALENDAR' })}`}
                                    value={calendarData.find(option => option.value === formik.values.idCalendar)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "idCalendar",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.idCalendar === "" && formik.setFieldTouched("idCalendar", true)
                                    }
                                    className="w-100"
                                />
                            </div>
                            {formik.touched.idCalendar && formik.errors.idCalendar && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.idCalendar}</span>
                                </div>
                            )}

                            {/* Type */}
                            <label className='form-label fs-6 fw-bolder text-dark required d-none'>{intl.formatMessage({ id: 'FORM.LABEL.TYPE' })}</label>
                            <div className="input-group mb-3 d-none">
                                <Select
                                    key={nanoid()}
                                    options={optionType}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.TYPE' })}`}
                                    value={optionType.find(option => option.value === formik.values.type)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "type",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.type === "" && formik.setFieldTouched("type", true)
                                    }
                                    className="w-100"
                                />
                            </div>
                            {formik.touched.type && formik.errors.type && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.type}</span>
                                </div>
                            )}

                            {/* Title */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    style={{ zIndex: 0 }}
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}

                            {/* Description */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DESCRIPTION' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.DESCRIPTION' })}
                                    {...formik.getFieldProps('description')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.description && formik.errors.description },
                                    )}
                                    type='text'
                                    name='description'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.description && formik.errors.description && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.description}</span>
                                </div>
                            )}

                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: "" })}</label>
                            <div className="row mb-2">
                                <div className="col">
                                    {/* StartDate */}
                                    <div className="input-group">
                                        <DatePicker
                                            oneTap
                                            style={{ width: "100%" }}
                                            cleanable={false}
                                            editable={false}
                                            format="dd/MM/yyyy"
                                            container={document.querySelector('#add-event-modal') as HTMLElement}
                                            value={new Date(formik.values.startDate)}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    var date = moment(value).format('YYYY-MM-DD');
                                                    var time = new Date(formik.values.endDate).toTimeString().split(' ')[0];
                                                    formik.setFieldValue('startDate', value)
                                                    formik.setFieldValue('endDate', new Date(date + ' ' + time))
                                                }
                                                else formik.setFieldValue('startDate', null)
                                            }}
                                        />
                                    </div>
                                    {formik.touched.startDate && formik.errors.startDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                        </div>
                                    )}
                                </div>
                                {
                                    isAllDay &&
                                    <>
                                        <div className="col">
                                            {/* EndDatee */}
                                            <div className="input-group">
                                                <DatePicker
                                                    oneTap
                                                    style={{ width: "100%" }}
                                                    cleanable={false}
                                                    editable={false}
                                                    placement="bottomEnd"
                                                    format="dd/MM/yyyy"
                                                    container={document.querySelector('#add-event-modal') as HTMLElement}
                                                    value={new Date(formik.values.endDate)}
                                                    onChange={(value: any) => {
                                                        if (value) formik.setFieldValue('endDate', value)
                                                        else formik.setFieldValue('endDate', null)
                                                    }}
                                                />
                                            </div>
                                            {formik.touched.endDate && formik.errors.endDate && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.endDate}</span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                !isAllDay &&
                                <>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            {/* StartHour */}
                                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.HOUR_START' }, { title: "" })}</label>
                                            <div className="input-group">
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                    cleanable={false}
                                                    editable={false}
                                                    format="HH:mm"
                                                    container={document.querySelector('#add-event-modal') as HTMLElement}
                                                    value={new Date(formik.values.startDate)}
                                                    onChange={(value: any) => {
                                                        if (value) formik.setFieldValue('startDate', value)
                                                        else formik.setFieldValue('startDate', null)
                                                    }}
                                                />
                                            </div>
                                            {formik.touched.startDate && formik.errors.startDate && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-6">
                                            {/* EndHour */}
                                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.HOUR_END' }, { title: "" })}</label>
                                            <div className="input-group">
                                                <DatePicker
                                                    style={{ width: "100%" }}
                                                    cleanable={false}
                                                    editable={false}
                                                    placement="bottomEnd"
                                                    format="HH:mm"
                                                    container={document.querySelector('#add-event-modal') as HTMLElement}
                                                    value={new Date(formik.values.endDate)}
                                                    onChange={(value: any) => {
                                                        if (value) formik.setFieldValue('endDate', value)
                                                        else formik.setFieldValue('endDate', null)
                                                    }}
                                                />
                                            </div>
                                            {formik.touched.endDate && formik.errors.endDate && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.endDate}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="form-check mb-3">
                                <label className="form-check-label" htmlFor="isAllDay">
                                    {intl.formatMessage({ id: 'FORM.LABEL.ALL_DAY' })}
                                </label>
                                <input className="form-check-input" type="checkbox" id="isAllDay"
                                    checked={isAllDay}
                                    onChange={() => {
                                        setIsAllDay(!isAllDay)
                                        if (isAllDay) {
                                            formik.handleChange({
                                                target: {
                                                    name: "startHour",
                                                    value: moment().format("HH:mm")
                                                }
                                            })
                                            formik.handleChange({
                                                target: {
                                                    name: "endHour",
                                                    value: moment().add(1, 'hours').format("HH:mm")
                                                }
                                            })
                                        }

                                        formik.handleChange({
                                            target: {
                                                name: "endDate",
                                                value: formik.values.startDate
                                            }
                                        })
                                    }}
                                />
                            </div>

                            {/* Timezone */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TIME_ZONE' })}</label>
                            <div className="input-group mb-3">
                                <TimezoneSelect
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "timezone",
                                                value: option
                                            }
                                        })
                                    }
                                />
                            </div>
                            {formik.touched.timezone && formik.errors.timezone && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.timezone}</span>
                                </div>
                            )}

                            {/* Attendees */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.PARTICIPANT' })}</label>
                            <div className="input-group mb-3 row ms-0">
                                <div className="col-auto ps-0">
                                    <Select
                                        key={nanoid()}
                                        options={optionFilterAttendance}
                                        value={optionFilterAttendance.find((data) => data.value === selectFilterAttendance)}
                                        onChange={(value) => {
                                            setSelectFilterAttendance(value!.value)
                                        }}
                                    />
                                </div>
                                <div className="col" style={{ padding: "unset" }}>
                                    <Select
                                        openMenuOnClick
                                        closeMenuOnSelect={false}
                                        options={groupedAttendanceOptions.filter((data) => data.value === selectFilterAttendance)}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PARTICIPANT' })}`}
                                        isMulti
                                        className="w-100"
                                        value={selectedAttendees}
                                        isDisabled={usersLoading && merchantsLoading}
                                        onChange={handleAttendeesChange}
                                    />
                                </div>
                            </div>

                            {/* Reminder */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.REMINDER' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    key={nanoid()}
                                    options={optionReminder}
                                    value={optionReminder.find(option => option.value === selectedReminder)}
                                    onChange={
                                        option => setSelectedReminder(option?.value ?? 0)
                                    }
                                    className="w-100"
                                />
                            </div>
                            {formik.touched.reminderMinutes && formik.errors.reminderMinutes && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.reminderMinutes}</span>
                                </div>
                            )}

                            {/* Link */}
                            <label className='form-label fs-6 fw-bolder text-dark'>Link</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Link'
                                    {...formik.getFieldProps('link')}
                                    className={clsx(
                                        'form-control form-control-lg link',
                                        { 'is-invalid': formik.touched.link && formik.errors.link },
                                    )}
                                    type='text'
                                    name='link'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.link && formik.errors.link && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.link}</span>
                                </div>
                            )}

                            {/* Color */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                            <div className="input-group mb-3">
                                <SketchPicker
                                    color={formik.values.color}
                                    onChange={color => {
                                        formik.handleChange({
                                            target: {
                                                name: "color",
                                                value: color.hex
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                        <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AddEvent }