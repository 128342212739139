import axios from "axios"
import { AttachmentQuickReply } from "../interfaces/Chat/QuickReply"

const API_URL = process.env.REACT_APP_API_URL

const QUICK_REPLY = `${API_URL}/chat/quick-replies`

export const addQuickReply = (shortcut: string, message: string, attachments: AttachmentQuickReply[], token: string) => {
    return axios.post(QUICK_REPLY,
        {
            shortcut,
            message,
            attachments
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const updateQuickReply = (id: string, shortcut: string, message: string, attachments: AttachmentQuickReply[], token: string) => {
    return axios.put(QUICK_REPLY + "/" + id,
        {
            shortcut,
            message,
            attachments
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const deleteQuickReply = (id: string, token: string) => {
    return axios.delete(QUICK_REPLY + "/" + id,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}
