import { Socket, io } from 'socket.io-client';
import { create } from 'zustand';
import { persist, createJSONStorage, StateStorage } from 'zustand/middleware'
import { ClientToServerEvents, ServerToClientEvents } from '../interfaces/Socket';
import { Message } from '../interfaces/Chat/Message';
import { IncomingMessageNotification } from '../interfaces/Chat/IncomingMessageNotification';
import { RecentChatHistory, RecentChatHistoryPipeline } from '../interfaces/Chat/RecentChatHistory';
import { ImageBlob } from '../interfaces/Chat/ImageBlob';
import { VideoBlob } from '../interfaces/Chat/VideoBlob';
import { FileBlob } from '../interfaces/Chat/FileBlob';
import { MediaDownload } from '../interfaces/Chat/MediaDownload';
import { RawMessage } from '../interfaces/Chat/RawMessage';
import { PersistedMessage } from '../interfaces/Chat/PersistedMessage';
import { ReplacementChat } from '../interfaces/Chat/ReplacementChat';
import storage from '../db';
import { LabelChatProps } from '../interfaces/Chat/LabelChat';
import { ChatWindowData } from '../interfaces/Chat/ChatWindowData';
import { QuickReply } from '../interfaces/Chat/QuickReply';
import produce from 'immer';
import { IForwardChatPayload } from '../interfaces/Chat/ForwardChat';
import { ListChatPhone } from '../interfaces/Chat/ListChatPhone';

const API_URL = process.env.REACT_APP_API_URL

type ChatState = {
    mode: 'normal' | 'search',
    setMode: (mode: 'normal' | 'search') => void
    tokenChat: string,
    setTokenChat: (updater: (tokenChat: string) => string) => void;
    selectedChatPhone: ListChatPhone[],
    setSelectedChatPhone: (updater: (selectedChatPhone: ListChatPhone[]) => ListChatPhone[]) => void;
    listChatPhone: ListChatPhone[],
    setListChatPhone: (listChatPhone: ListChatPhone[]) => void
    loadingListChatPhone: boolean,
    setLoadingListChatPhone: (loadingListChatPhone: boolean) => void
    persistedMessagesCount: number,
    setPersistedMessagesCount: (persistedMessagesCount: number) => void
    isOpenCheckBoxForward: boolean,
    setIsOpenCheckBoxForward: (openCheckBoxForward: boolean) => void
    isOpenForwardChat: boolean
    setIsOpenForwardChat: (isOpenForwardChat: boolean) => void
    isWaitingForNetwork: boolean
    setIsWaitingForNetwork: (isWaitingForNetwork: boolean) => void
    attachmentMessageId: string
    setAttachmentMessageId: (attachmentMessageId: string) => void
    attachmentUrl: string
    setAttachmentUrl: (attachmentUrl: string) => void
    scrollToId: string
    setScrollToId: (scrollToId: string) => void
    lastSynced: string
    setLastSynced: (lastSynced: string) => void
    isFirstSyncing: boolean
    setIsFirstSyncing: (isFirstSyncing: boolean) => void
    recentChatIsSyncing: boolean
    setRecentChatIsSyncing: (recentChatIsSyncing: boolean) => void
    messagesIsSyncing: boolean
    setMessagesIsSyncing: (messagesIsSyncing: boolean) => void
    isLoading: boolean
    setIsLoading: (isLoading: boolean) => void
    interacted: boolean
    setInteracted: (interacted: boolean) => void
    agreedTNC: string
    setAgreedTNC: (agreedTNC: string) => void
    connected: boolean
    setConnected: (updater: (connected: boolean) => boolean) => void;
    merchantId: string
    setMerchantId: (merchantId: string) => void
    chatWindowName: string
    setChatWindowName: (chatWindowName: string) => void
    phoneNumber: string
    setPhoneNumber: (phoneNumber: string) => void
    temporaryChatIds: string[]
    setTemporaryChatIds: (updater: (temporaryChatIds: string[]) => string[]) => void
    replacedChatIds: string[]
    setReplacedChatIds: (updater: (replacedChatIds: string[]) => string[]) => void
    replacementChat: ReplacementChat
    setReplacementChat: (updater: (replacementChat: ReplacementChat) => void) => void;
    message: string
    setMessage: (message: string) => void
    persistedMessages: PersistedMessage
    setPersistedMessages: (updater: (persistedMessages: PersistedMessage) => PersistedMessage) => void;
    editMessage: string
    setEditMessage: (editMessage: string) => void
    replyMessage: Message | undefined
    setReplyMessage: (replyMessage: Message | undefined) => void
    chatMessages: RawMessage
    setChatMessages: (chatMessages: RawMessage) => void
    recentChatHistories: RecentChatHistory[]
    setRecentChatHistories: (updater: (recentChatHistories: RecentChatHistory[]) => RecentChatHistory[]) => void;
    recentChatSelectedPipeline: RecentChatHistoryPipeline
    setRecentChatSelectedPipeline: (recentChatSelectedPipeline: RecentChatHistoryPipeline) => void
    filteredRecentChatHistories: RecentChatHistory[]
    setFilteredRecentChatHistories: (updater: (filteredRecentChatHistories: RecentChatHistory[]) => RecentChatHistory[]) => void;
    incomingMessageNotifications: IncomingMessageNotification
    setIncomingMessageNotifications: (updater: (incomingMessageNotifications: IncomingMessageNotification) => IncomingMessageNotification) => void;
    socket: Socket<ServerToClientEvents, ClientToServerEvents>
    setSocket: (updater: (socket: Socket<ServerToClientEvents, ClientToServerEvents>) => Socket<ServerToClientEvents, ClientToServerEvents>) => void
    messages: Message[]
    setMessages: (updater: (messages: Message[]) => Message[]) => void
    showChat: boolean
    setShowChat: (showChat: boolean) => void
    imageBlobUrls: ImageBlob,
    setImageBlobUrls: (imageBlobUrls: ImageBlob) => void
    videoBlobUrls: VideoBlob,
    setVideoBlobUrls: (videoBlobUrls: VideoBlob) => void
    fileBlobUrls: FileBlob,
    setFileBlobUrls: (FileBlobUrls: FileBlob) => void
    mediaDownloads: MediaDownload
    setMediaDownloads: (updater: (mediaDownloads: MediaDownload) => MediaDownload) => void
    menuActive: string
    setMenuActive: (menuActive: string) => void
    chatLabels: LabelChatProps[]
    setChatLabels: (chatLabels: LabelChatProps[]) => void
    selectChatLabel: LabelChatProps
    setSelectChatLabel: (selectChatLabel: LabelChatProps) => void
    selectChatWindow: ChatWindowData[]
    setSelectChatWindow: (selectChatWindow: ChatWindowData[]) => void
    quickReplies: QuickReply[]
    setQuickReplies: (quickReplies: QuickReply[]) => void
    originalChatPhone: string
    setOriginalChatPhone: (originalChatPhone: string) => void
    isOpenDetailContact: boolean
    setIsOpenDetailContact: (isOpenDetailContact: boolean) => void
    selectedForwardMessages: Message[]
    setSelectedForwardMessages: (selectedForwardMessages: Message[]) => void
    currentMessageForward: Message | null
    setCurrentMessageForward: (currentMessageForward: Message | null) => void
    selectedChatsForward: string[]
    setSelectedChatsForward: (selectedChatsForward: string[]) => void
    forwardChatPayload: IForwardChatPayload
    setForwardChatPayload: (forwardChatPayload: IForwardChatPayload) => void
}

const tokenChat = await storage.getItem('tokenChat') || '';
const selectedChatPhone = await storage.getItem('selectedChatPhone') || [] as any;
const lastSynced = await storage.getItem('lastSynced') || '';
const isFirstSyncing = await storage.getItem('isFirstSyncing') === 'true';
const connected = await storage.getItem('connected') ? true : false;
const persistedMessages = await storage.getItem('persistedMessages') || {} as any;
const incomingMessageNotifications = await storage.getItem('incomingMessageNotifications') || {} as any;
const mediaDownloads = await storage.getItem('mediaDownloads') || {};
const recentChatHistories = await storage.getItem('recentChatHistories') || [] as any;

export const useChatStore = create<ChatState>()(
    persist(
        (set) => ({
            mode: 'normal',
            setMode: (mode: 'normal' | 'search') => { set({ mode }) },
            tokenChat,
            setTokenChat: (updater) =>
                set((state) => {
                    const updateTokenChat = updater(state.tokenChat);
                    storage.setItem('tokenChat', updateTokenChat as any);
                    return { tokenChat: updateTokenChat };
                }),
            selectedChatPhone,
            setSelectedChatPhone: (updater) =>
                set((state) => {
                    const updateSelectedChatPhone = updater(state.selectedChatPhone);
                    storage.setItem('selectedChatPhone', updateSelectedChatPhone as any);
                    return { selectedChatPhone: updateSelectedChatPhone };
                }),
            listChatPhone: [] as ListChatPhone[],
            setListChatPhone: (listChatPhone: ListChatPhone[]) => { set({ listChatPhone }) },
            loadingListChatPhone: false,
            setLoadingListChatPhone: (loadingListChatPhone: boolean) => { set({ loadingListChatPhone }) },
            persistedMessagesCount: 0,
            setPersistedMessagesCount: (persistedMessagesCount: number) => { set({ persistedMessagesCount }) },
            forwardChatPayload: {} as IForwardChatPayload,
            setForwardChatPayload: (forwardChatPayload: IForwardChatPayload) => { set({ forwardChatPayload }) },
            selectedChatsForward: [],
            setSelectedChatsForward: (selectedChatsForward: string[]) => { set({ selectedChatsForward }) },
            currentMessageForward: null,
            setCurrentMessageForward: (currentMessageForward: Message | null) => { set({ currentMessageForward }) },
            selectedForwardMessages: [] as Message[],
            setSelectedForwardMessages: (selectedForwardMessages: Message[]) => { set({ selectedForwardMessages }) },
            isOpenCheckBoxForward: false,
            setIsOpenCheckBoxForward: (isOpenCheckBoxForward: boolean) => { set({ isOpenCheckBoxForward }) },
            isOpenForwardChat: false,
            setIsOpenForwardChat: (isOpenForwardChat: boolean) => { set({ isOpenForwardChat }) },
            originalChatPhone: '',
            setOriginalChatPhone: (originalChatPhone: string) => { set({ originalChatPhone }) },
            isWaitingForNetwork: false,
            setIsWaitingForNetwork: (isWaitingForNetwork: boolean) => { set({ isWaitingForNetwork }) },
            attachmentMessageId: '',
            setAttachmentMessageId: (attachmentMessageId: string) => { set({ attachmentMessageId }) },
            attachmentUrl: '',
            setAttachmentUrl: (attachmentUrl: string) => { set({ attachmentUrl }) },
            scrollToId: '',
            setScrollToId: (scrollToId: string) => { set({ scrollToId }) },
            lastSynced,
            setLastSynced: (lastSynced: string) => { set({ lastSynced }); storage.setItem('lastSynced', lastSynced) },
            isFirstSyncing,
            setIsFirstSyncing: (isFirstSyncing: boolean) => { set({ isFirstSyncing }); storage.setItem('isFirstSyncing', isFirstSyncing.toString()) },
            recentChatIsSyncing: false,
            setRecentChatIsSyncing: (recentChatIsSyncing: boolean) => { set({ recentChatIsSyncing }) },
            messagesIsSyncing: false,
            setMessagesIsSyncing: (messagesIsSyncing: boolean) => { set({ messagesIsSyncing }) },
            isLoading: false,
            setIsLoading: (isLoading: boolean) => { set({ isLoading }) },
            interacted: false,
            setInteracted: (interacted: boolean) => { set({ interacted }) },
            agreedTNC: '',
            setAgreedTNC: (agreedTNC: string) => { set({ agreedTNC }) },
            connected,
            setConnected: (updater) =>
                set((state) => {
                    const updateConnected = updater(state.connected);
                    storage.setItem('connected', updateConnected as any);
                    return { connected: updateConnected };
                }),
            merchantId: '',
            setMerchantId: (merchantId: string) => { set({ merchantId }) },
            chatWindowName: '',
            setChatWindowName: (chatWindowName: string) => { set({ chatWindowName }) },
            phoneNumber: '',
            setPhoneNumber: (phoneNumber: string) => { set({ phoneNumber }) },
            temporaryChatIds: [],
            setTemporaryChatIds: (updater) => set((state) => ({ temporaryChatIds: updater(state.temporaryChatIds) })),
            replacedChatIds: [],
            setReplacedChatIds: (updater) => set((state) => ({ replacedChatIds: updater(state.replacedChatIds) })),
            replacementChat: {} as ReplacementChat,
            setReplacementChat: (updater) =>
                set((state) =>
                    produce(state, (draft) => {
                        updater(draft.replacementChat);
                    })
                ),
            message: '',
            setMessage: (message: string) => { set({ message }) },
            persistedMessages,
            setPersistedMessages: (updater) =>
                set((state) => {
                    const updatePersistedMessages = updater(state.persistedMessages);
                    storage.setItem('persistedMessages', updatePersistedMessages as any);
                    return { persistedMessages: updatePersistedMessages };
                }),
            editMessage: '',
            setEditMessage: (editMessage: string) => { set({ editMessage }) },
            replyMessage: undefined,
            setReplyMessage: (replyMessage: Message | undefined) => { set({ replyMessage }) },
            chatMessages: {},
            setChatMessages: (chatMessages: RawMessage) => { set({ chatMessages }) },
            recentChatSelectedPipeline: {} as RecentChatHistoryPipeline,
            setRecentChatSelectedPipeline: (recentChatSelectedPipeline: RecentChatHistoryPipeline) => { set({ recentChatSelectedPipeline }) },
            recentChatHistories,
            setRecentChatHistories: (updater) =>
                set((state) => {
                    const updateRecentChatHistories = updater(state.recentChatHistories);
                    storage.setItem('recentChatHistories', updateRecentChatHistories as any);
                    return { recentChatHistories: updateRecentChatHistories };
                }),
            filteredRecentChatHistories: [],
            setFilteredRecentChatHistories: (updater) =>
                set((state) => {
                    const updateFilteredRecentChatHistories = updater(state.filteredRecentChatHistories);
                    return { filteredRecentChatHistories: updateFilteredRecentChatHistories };
                }),
            incomingMessageNotifications,
            setIncomingMessageNotifications: (updater) =>
                set((state) => {
                    const updateIncomingMessageNotifications = updater(state.incomingMessageNotifications);
                    storage.setItem('incomingMessageNotifications', updateIncomingMessageNotifications as any);
                    return { incomingMessageNotifications: updateIncomingMessageNotifications };
                }),
            socket: io(`${API_URL}whatsapp-chat`),
            setSocket: (updater) => set((state) => ({ socket: updater(state.socket) })),
            messages: [],
            setMessages: (updater) => set((state) => ({ messages: updater(state.messages) })),
            showChat: false,
            setShowChat: (showChat: boolean) => { set({ showChat }) },
            imageBlobUrls: {},
            setImageBlobUrls: (imageBlobUrls: ImageBlob) => { set({ imageBlobUrls }) },
            videoBlobUrls: {},
            setVideoBlobUrls: (videoBlobUrls: VideoBlob) => { set({ videoBlobUrls }) },
            fileBlobUrls: {},
            setFileBlobUrls: (fileBlobUrls: FileBlob) => { set({ fileBlobUrls }) },
            mediaDownloads,
            setMediaDownloads: (updater) =>
                set((state) => {
                    const updatedMediaDownloads = updater(state.mediaDownloads);
                    storage.setItem('mediaDownloads', updatedMediaDownloads as any);
                    return { mediaDownloads: updatedMediaDownloads };
                }),
            menuActive: 'chat',
            setMenuActive: (menuActive: string) => { set({ menuActive }) },
            chatLabels: [],
            setChatLabels: (chatLabels: LabelChatProps[]) => { set({ chatLabels }) },
            selectChatLabel: {} as LabelChatProps,
            setSelectChatLabel: (selectChatLabel: LabelChatProps) => { set({ selectChatLabel }) },
            selectChatWindow: [],
            setSelectChatWindow: (selectChatWindow: ChatWindowData[]) => { set({ selectChatWindow }) },
            quickReplies: [],
            setQuickReplies: (quickReplies: QuickReply[]) => { set({ quickReplies }) },
            isOpenDetailContact: false,
            setIsOpenDetailContact: (isOpenDetailContact: boolean) => { set({ isOpenDetailContact }) },
        }),
        {
            partialize: (state) => ({
                // recentChatHistories: state.recentChatHistories,
            }),
            name: 'recent-chat-storage',
            storage: createJSONStorage(() => storage),
        },
    )
);
