import { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../functions/general";
import { useIntl } from "react-intl";
import moment from "moment";
import { CustomField } from "../../interfaces/Settings";
import { useSettingsStore } from "../../stores/SettingsStore";
import useUser from "../../hooks/User";
import { useCustomeStore } from "../../stores/CustomeStore";
import { ExpandableElement } from "../General/ExpandableElement";
import { Tooltip } from 'react-tippy';
import { Merchant } from "../../interfaces/Merchant";
import { Link } from "react-router-dom";
import { usePipelineStore } from "../../stores/PipelineStore";
import { useMerchantStore } from "../../stores/MerchantStore";

interface ItemTypeData {
    name: string
    value: string | JSX.Element | number
}

interface CustomData {
    [key: string]: ItemTypeData
}

interface DetailMerchantProps {
    data: Merchant
    redirectWhatsappValid?: (id: string, phone: string) => void
    redirectWhatsappNotValid?: (id: string, phone: string) => void
}

const DetailMerchant: FC<DetailMerchantProps> = ({ data, redirectWhatsappValid, redirectWhatsappNotValid }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { multiPipelines } = usePipelineStore()
    const intl = useIntl()
    const [customLead, setCustomLead] = useState({} as any)
    const [customFields, setCustomFields] = useState<CustomField>({})
    const { settings } = useSettingsStore()
    const {selectMultiMerchant} = useMerchantStore()
    const user = useUser()
    const { companyId } = useCustomeStore()

    useEffect(() => {
        const customData: CustomData = {
            name: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
                value: data.name
            },
            ownerName: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                value: data.ownerName
            },
            cityName: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
                value: data.cityName
            },
            phone: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
                value: data.phone
            },
            isWhatsappPhone: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }),
                value: returnWhatsapp(data)
            },
            email: {
                name: 'Email',
                value: data.email || '-'
            },
            lastMeetEnd: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_MEETING' }),
                value: data.lastMeetEnd
            },
            lastLeadCreatedTime: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LAST_ADD_MERCHANT' }, { title: settings.pipeline_title }),
                value: data.lastLeadCreatedTime
            },
            leadsCount: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: settings.pipeline_title }),
                value: returnPipelineCount(data)
            },
            meetingsCount: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COUNT' }, { title: intl.formatMessage({ id: 'MENU.MEETINGS' }) }),
                value: returnMeetingCount(data)
            },
            creatorName: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                value: data.creatorName
            },
            dateCreated: {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
                value: moment(data.dateCreated).format("DD MMM YYYY HH:mm")
            },
        }

        const newData = {
            ...customData,
            ...Object.keys(customFields).reduce((acc, key) => {
                let value;
                if (customFields[key].type === 'date') {
                    value = data.customFields[key] ? moment(data.customFields[key]).format("DD MMM YYYY") : "-";
                } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                    value = data.customFields[key] ? returnImage(data.customFields[key] as string[]) : "-";
                } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                    value = data.customFields[key] ? returnFile(data.customFields[key]) : "-";
                } else if (customFields[key].type === 'multiple') {
                    value = data.customFields[key] ? returnMultiple(data.customFields[key] as string) : "-";
                } else if (customFields[key].type === 'link') {
                    value = data.customFields[key] ? returnLink(data.customFields[key] as string) : "-";
                } else if (customFields[key].type === 'serial') {
                    value = data.customFields[key] ? (data.customFields[key] as string[]).join('') : "-";
                } else if (customFields[key].type === 'merchant' || customFields[key].type === 'user') {
                    value = (data as any)[`custom_fields.${key}`] ? (data as any)[`custom_fields.${key}`] : "-";
                } else {
                    value = data.customFields[key] ? data.customFields[key] : "-";
                }

                return {
                    ...acc,
                    [key]: {
                        name: customFields[key].name,
                        value
                    }
                };
            }, {})
        };

        setCustomLead(newData);
    }, [customFields])

    useEffect(() => {
        if (selectMultiMerchant) {
            setCustomFields(selectMultiMerchant.customFields)
        }
    }, [selectMultiMerchant])

    const returnImage = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnWhatsapp = (data: Merchant) => {
        if (data.isWhatsappPhone) return <img alt='Banner' style={{ width: "15px", height: "15px" }} onClick={() => redirectWhatsappValid && redirectWhatsappValid(data.id, data.phone)} className={`${redirectWhatsappValid && 'cursor-pointer'}`} src={toAbsoluteUrl('/media/assets/checked.png')} />
        else return <img alt='Banner' style={{ width: "15px", height: "15px" }} onClick={() => redirectWhatsappNotValid && redirectWhatsappNotValid(data.id, data.phone)} className={`${redirectWhatsappNotValid && 'cursor-pointer'}`} src={toAbsoluteUrl('/media/assets/remove.png')} />
    };

    const returnPipelineCount = (data: Merchant) => {
        if(multiPipelines.length > 0) {
            return (
                <Link to={`${data.id}/pipelines/${multiPipelines[0].id}`} target="_blank" className="text-black">
                    <span className="cursor-pointer text-primary">{data.leadsCount}</span>
                </Link>
            )
        } else {
            return <span>{data.leadsCount}</span>
        }
    }

    const returnMeetingCount = (data: Merchant) => {
        return (
            <Link to={`meetings/${data.id}`} target="_blank" className="text-black">
                <span className="cursor-pointer text-primary">{data.meetingsCount}</span>
            </Link>
        )
    }

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnLink = (data: string) => {
        return (
            <a href={data} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
        )
    }

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`detail-merchant-modal-${data.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Detail {data.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form noValidate>
                            <div className="modal-body">
                                <div className="row">
                                    {
                                        Object.keys(customLead).map((key, index) => {
                                            return (
                                                <div className="row pb-2 col-12 col-lg-6 col-xl-6">
                                                    <div className="col-3 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customLead[key].name}</div>
                                                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{customLead[key].value}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {
                                    data.reimbursements.length > 0 &&
                                    <>
                                        <div className="separator separator-dashed my-5"></div>
                                        <h5 className="card-title align-items-start flex-column mb-5">
                                            Detail Reimbursements
                                        </h5>
                                        {
                                            data.reimbursements.map((dataReimbursement, index) => {
                                                return (
                                                    <div className="row mb-5">
                                                        <div className="col-8 col-lg-4 col-xl-4">
                                                            <div className="d-flex gap-2">
                                                                <span className="text-gray-800 fw-bolder">{dataReimbursement.name}</span>
                                                                {(() => {
                                                                    switch (dataReimbursement.approvalStatus) {
                                                                        case 'REJECTED':
                                                                            return <span className="badge badge-light-danger" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.REJECTED' })}</span>;
                                                                        case 'APPROVED':
                                                                            return <span className="badge badge-light-success" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.APPROVED' })}</span>;
                                                                        case 'PAID_OFF':
                                                                            return <span className="badge badge-light-primary" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PAID_OFF' })}</span>;
                                                                        default:
                                                                            return <span className="badge badge-light-warning" style={{ height: "fit-content" }}>{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PENDING' })}</span>;
                                                                    }
                                                                })()}
                                                            </div>
                                                            <span className="text-gray-400 fw-bold d-block text-start ps-0">{dataReimbursement.reimbursementCategory.name}</span>
                                                        </div>

                                                        <div className="col-4 col-lg-2 col-2">
                                                            <div className="text-gray-400 fw-bolder text-end">
                                                                <span className="text-gray-800 fw-bolder d-block">{dataReimbursement.companyCurrency.symbol} {customNumberFormat(dataReimbursement.value)}</span>
                                                                {moment(dataReimbursement.dateSpend).format("DD MMM YYYY")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DetailMerchant }