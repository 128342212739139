import { useEffect } from 'react';
import { Modal } from 'bootstrap';

interface UseModalEffectProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  modalId: string;
}

const useModalEffect: any = ({ showModal, setShowModal, modalId }: UseModalEffectProps) => {
  useEffect(() => {
    if (showModal) {
      const modalElement = document.getElementById(modalId);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        const handleModalHide = () => {
          setShowModal(false);
        };

        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showModal, modalId, setShowModal]);
};

export default useModalEffect;