import React, { FC } from 'react';
import { Message } from '../../../interfaces/Chat/Message';
import { useChatStore } from '../../../stores/ChatStore';

interface CheckboxListProps {
  message: Message;
}

const CheckboxList: FC<CheckboxListProps> = ({ message }) => {
  const {
    selectedForwardMessages,
    setSelectedForwardMessages,
    setCurrentMessageForward,
    forwardChatPayload,
    setForwardChatPayload
  } = useChatStore();

  const handleCheckboxChange = () => {
    const isSelected = selectedForwardMessages.some(selectedMsg => selectedMsg.id === message.id);
    if (isSelected) {
      // Remove from selection
      setSelectedForwardMessages(selectedForwardMessages.filter(selectedMsg => selectedMsg.id !== message.id));
      setCurrentMessageForward(null);

      // Update forward payload
      setForwardChatPayload({
        ...forwardChatPayload,
        targets: forwardChatPayload.targets?.filter(target => target !== message.phone) || [],
        rawMessages: forwardChatPayload.rawMessages?.filter(msg => msg.chat_j_message !== message.chat_j_message) || []
      });
    } else {
      // Add to selection
      setSelectedForwardMessages([...selectedForwardMessages, message]);
      setCurrentMessageForward(message);

      // Update forward payload
      setForwardChatPayload({
        targets: [],
        rawMessages: [...(forwardChatPayload.rawMessages || []), { chat_j_message: message.chat_j_message ?? '', date_created: message.time ?? '' }]
      });
    }
  };

  return (
    <div className="checkbox-wrapper slide-in-left">
      <div className="form-check position-absolute d-flex align-items-center"
        style={{ left: '10px', top: '110%', transform: 'translateY(-110%)' }}>
        <input
          id={message.id as string}
          type="checkbox"
          className="form-check-input"
          checked={selectedForwardMessages.some(selectedMsg => selectedMsg.id === message.id)}
          onChange={handleCheckboxChange}
        />
      </div>
    </div>
  );
};

export default CheckboxList;