import { FC, ReactNode, useEffect, useRef } from "react";
import { TableColumn } from "react-data-table-component";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../functions/general";
import { useSettingsStore } from "../../stores/SettingsStore";
import { MasterDataTableNoUrl } from "../DataTable/MasterDataTableNoUrl";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { DetailStaticDashboardWidget, MerchantListWidget } from "../../interfaces/Dashboard";
import { useIntl } from "react-intl";
import useNameWidget from "../../hooks/NameWidget";
import { DataTableExport } from "../DataTable/DataTableExport";

interface WidgetStaticModalProps {
    data: DetailStaticDashboardWidget
    setShowWidgetStaticModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowWidgetDetailMerchantModal: React.Dispatch<React.SetStateAction<boolean>>
}

const WidgetDetailMerchantModal: FC<WidgetStaticModalProps> = ({ data, setShowWidgetStaticModal, setShowWidgetDetailMerchantModal }) => {
    const { settings } = useSettingsStore()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl()
    const nameWidget = data.leadLogProgressName;

    var tableColumns: TableColumn<MerchantListWidget>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
            width: '250px',
            wrap: true,
            selector: row => row.merchant_name,
            sortable: true,
            reorder: true,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            width: '150px',
            selector: row => row.merchant_phone,
            sortable: true,
            wrap: true,
            reorder: true,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
            width: '200px',
            selector: row => row.merchant_city_name,
            sortable: true,
            reorder: true,
            wrap: true,
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.AGE" }),
            width: 'auto',
            selector: row => Number(row.merchant_duration),
            sortable: true,
            cell(row) {
                return getDateSummary(Math.round(Number(row.merchant_duration)))
            }
        },
        {
            name: 'Total',
            selector: row => customNumberFormat(row.merchant_amount),
            right: true,
            sortable: true,
            reorder: true,
        },
    ];

    useEffect(() => {
        const modalElement = modalRef.current;

        // Fungsi untuk memanggil callback saat modal ditutup
        const handleModalClose = () => {
            console.log("close modal detail merchant")
            closeModalRef.current?.click();
            setShowWidgetStaticModal(true)
            setShowWidgetDetailMerchantModal(false)
        };

        // Tambahkan event listener saat modal ditutup
        if (modalElement) {
            modalElement.addEventListener("hidden.bs.modal", handleModalClose);
        }

        // Cleanup untuk menghapus event listener
        return () => {
            if (modalElement) {
                modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
            }
        };
    }, []);

    return (
        <div className="modal fade" tabIndex={-1} id="widget-detail-merchant-modal" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detail {intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PROGRESS" })} {nameWidget}</h5>
                        <div
                            ref={closeModalRef}
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <MasterDataTableNoUrl
                        tableKey={"widget-detail-merchant"}
                        tableColumns={tableColumns}
                        tableData={data.merchantList}
                    />
                </div>
            </div>
        </div>
    )
}

export { WidgetDetailMerchantModal }