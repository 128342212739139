import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { style } from '@mui/system';
import 'moment-duration-format';
import addDays from 'date-fns/addDays';
import { UserModel } from '../../app/modules/auth/models/UserModel';

interface Props {
    user: UserModel
    nameFile: string
    data: any[]
    dataBranch: any[]
    keys: string[]
}

export const exportExcelMeetingActivityCategoryPerformance = async ({ user, nameFile, data, dataBranch, keys }: Props) => {
    const workbook = createWorkbook();

    const worksheet = createWorksheet(workbook, user.data.is_superadmin ? 'Semua Cabang' : 'Semua Sales');

    worksheet.getRow(1).values = [
        `AKTIVITAS`,
        `Target`,
        `Pencapaian`,
        ``,
    ];

    worksheet.getRow(2).values = [
        ``,
        ``,
        `Q`,
        `%`,
    ];

    worksheet.mergeCells('A1', 'A2');
    worksheet.mergeCells('B1', 'B2');
    worksheet.mergeCells('C1', 'D1');

    addDataRows(worksheet, data);
    setColumnWidths(worksheet);
    styleColumns(worksheet);

    keys.map((key: string, index: number) => {
        if (dataBranch.find((item: any) => item.key === key)) {
            const worksheet = createWorksheet(workbook, key);

            worksheet.getRow(1).values = [
                `AKTIVITAS`,
                `Target`,
                `Pencapaian`,
                ``,
            ];

            worksheet.getRow(2).values = [
                ``,
                ``,
                `Q`,
                `%`,
            ];

            worksheet.mergeCells('A1', 'A2');
            worksheet.mergeCells('B1', 'B2');
            worksheet.mergeCells('C1', 'D1');

            addDataRows(worksheet, dataBranch.filter((item: any) => item.key === key));
            setColumnWidths(worksheet);
            styleColumns(worksheet);
        }
    })
    exportWorkbook(workbook, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
    let worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

const addDataRows = (worksheet: ExcelJS.Worksheet, dataBranch: any[],) => {
    dataBranch.forEach((rowData, index) => {
        var tempData: string[] = []
        tempData.push(
            rowData.desc,
            rowData.prospectTarget,
            rowData.prospectAchievementQ,
            rowData.prospectAchievementPercent,
        )

        worksheet.addRow(tempData);
    });

};

const setColumnWidths = (worksheet: ExcelJS.Worksheet) => {
    const colWidths = [35];
    worksheet.columns = colWidths.map((width) => ({ width }));
};

const styleColumns = (worksheet: ExcelJS.Worksheet) => {
    const allColumns = ['B', 'C', 'D'];
    allColumns.map((key) => {
        worksheet.getColumn(key).alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
        }
    });

    worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(2).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(1).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
    worksheet.getRow(2).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
};

const exportWorkbook = async (workbook: ExcelJS.Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    });
};
