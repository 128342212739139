import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const CATEGORY = `${API_URL}reimbursement-category/`;
const CATEGORIES = `${API_URL}reimbursement-categories/`;

export const insertReimbursementCategory = async (
  name: string,
  color: string,
  description: string,
  token: string
) => {
  return axios.post(
    CATEGORY,
    {
      name,
      color,
      description,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateReimbursementCategory = async (id: string, name: string, color: string, token: string) => {
  return axios.put(
    CATEGORIES + id,
    {
      name,
      color,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteReimbursementCategory = async (id: string, token: string) => {
  return axios.delete(CATEGORIES + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};
