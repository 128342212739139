import { useEffect, useRef, useState } from "react";
import { useChatStore } from "../../../stores/ChatStore";
import useAccessToken from "../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../../hooks/HandleCloseModal";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { KTSVG } from "../../../_metronic/helpers";
import { SketchPicker } from 'react-color';
import { insertChatLabel, updateChatLabel } from "../../../api/ChatLabelsCRUD";
import Swal from "sweetalert2";

const UpdateChatLabel = () => {
    const token = useAccessToken()
    const intl = useIntl()

    const { chatLabels, selectChatLabel, menuActive, setChatLabels, setSelectChatLabel } = useChatStore()

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: selectChatLabel.name,
            color: selectChatLabel.color,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            color: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.COLOR" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            try {
                const response = await updateChatLabel(selectChatLabel.id, values.name, values.color, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();

                    setChatLabels(chatLabels.map(item => {
                        if (item.id === selectChatLabel.id) {
                            return {
                                ...response.data.data,
                            }
                        } else {
                            return item
                        }
                    }));

                    if (menuActive === "settings-detail-label") {
                        setSelectChatLabel(response.data.data)
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id="update-chat-label-modal" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {intl.formatMessage({ id: 'FORM.MENU.LABELS' })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.MENU.LABELS' })}
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                            <div className="input-group mb-3">
                                <SketchPicker
                                    color={formik.values.color}
                                    onChange={color => {
                                        formik.handleChange({
                                            target: {
                                                name: "color",
                                                value: color.hex
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateChatLabel }