import clsx from "clsx";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import useSWR, { mutate } from "swr";
import { Merchant } from "../../interfaces/Merchant";
import { Source } from "../../interfaces/Source";
import { Product, ProductUnits } from "../../interfaces/Product";
import { getListMerchantLinkPipeline, insertLead, saveMerchantLinkPipeline } from "../../api/PipelineCRUD";
import moment from "moment";
import { nanoid } from "@reduxjs/toolkit";
import { Meeting } from "../../interfaces/Meeting";
import { User } from "../../interfaces/User";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, CustomFieldSerialProps, SettingMultiMerchants, SettingMultiPipelines } from "../../interfaces/Settings";
import { insertLeadFieldMultiAttachment, insertLeadFieldAttachment } from "../../api/ResourceAPI";
import { DatePicker } from "rsuite";
import { useChatStore } from "../../stores/ChatStore";
import { RecentChatHistory, RecentChatHistoryPipeline } from "../../interfaces/Chat/RecentChatHistory";
import useUser from "../../hooks/User";
import { AddMerchant } from "../Merchants/AddMerchant";
import { Modal } from "bootstrap";
import { KTSVG } from "../../_metronic/helpers";
import { isBefore, isAfter, subDays, startOfToday, addDays } from 'date-fns';
import { customNumberFormat, customNumberFormatString, getDataById, handleCaps, isUUID, isValidUrl } from "../../functions/general";
import { ListProduct, Pipeline } from "../../interfaces/Pipeline";
import { useIntl } from "react-intl";
import { TableColumn } from "react-data-table-component";
import { defaultScope } from "../DataTable/UserScopeModal";
import { UserScope } from "../../interfaces/UserScope";
import useTodayRange from "../../hooks/TodayRange";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useCustomeStore } from "../../stores/CustomeStore";
import { tailspin } from 'ldrs'
import { generateSerialApi } from "../../api/Generals";
import { debounce, round } from 'lodash';
import { VariableSizeList as List } from 'react-window';
import InputPhone from "../Input/InputPhone";
import { usePipelineStore } from "../../stores/PipelineStore";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useUserStore } from "../../stores/UserStore";
import { Subordinate } from "../../interfaces/Subordinate";
import { useLocation, useParams } from "react-router-dom";
import VirtualizedSelect, {  Option, VirtualizedSelectOptionsType } from "../Select/SelectVirtualized";
import { SelectOption, SelectOptionGroup, SelectOptionMerchantLink } from "../../interfaces/SelectOption";
import { CUSTOM_FIELD_NAME_MERCHANT_MASPION_SQUARE } from "../../constant/General";

interface SelectOptionProduct extends SelectOption {
    sku?: string;
    note?: string;
    productUnits?: ProductUnits[];
    alias?: string;
    price?: number;
    vatPercentage?: number
}

interface SelectProducUnit extends SelectOption {
    alias?: string;
    note?: string;
}

interface AddPipelineProps {
    meeting?: Meeting
    merchantId?: string[]
    manageColumns?: any
    setManageColumns?: any
    setScope?: any
    showAddPipelineModal?: boolean
    handleResetFilter?: () => void
}

interface DataProduct {
    productId: string
    productName: string
    productSku: string
    productNote: string
    productPrice: number
    productVatPercentage: number
    productUnits: ProductUnits[]
}

const AddPipeline: FC<AddPipelineProps> = ({ meeting, merchantId, manageColumns, setManageColumns, setScope, showAddPipelineModal, handleResetFilter }) => {
    const { pathname } = useLocation()
    const todayRange = useTodayRange();
    tailspin.register()

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData, fetchCounter, setFetchCounter } = useDataTableStore()
    const { pipelines, pipelineLoading, multiPipelines, selectMultiPipeline } = usePipelineStore()
    const { merchants, simpleMerchants, simpleMerchantLoading, merchantLoading, setMerchants, setMerchantLoading, setSimpleMerchants, setSimpleMerchantLoading, multiMerchants, setMultiMerchants } = useMerchantStore()
    const { users, userLoading, userSubordinates, userSubordinateLoading, setUserSubordinateLoading, setUserSubordinates } = useUserStore()
    const token = useAccessToken()
    const [isCustomeFieldDateDisabled, setIsCustomeFieldDateDisabled] = useState<boolean>(true);
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [merchantData, setMerchantData] = useState<SelectOptionGroup[]>([]);
    const [sourceData, setSourceData] = useState<SelectOption[]>([]);
    const [productData, setProductData] = useState<SelectOptionProduct[]>([]);
    const [unitData, setUnitData] = useState<SelectProducUnit[]>([]);
    const [optionMerchantLink, setOptionMerchantLink] = useState<SelectOptionMerchantLink[]>([]);
    const [selectMerchantLink, setSelectMerchantLink] = useState<SelectOptionMerchantLink>({} as SelectOptionMerchantLink);
    const { settings, setSettings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [customFieldsMerchant, setCustomFieldsMerchant] = useState<any>([])
    const [selectTemplatePipeline, setSelectTemplatePipeline] = useState<string>('')
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const user = useUser()
    const [showAddMerchantModal, setShowAddMerchantModal] = useState<boolean>(false);
    const [valSearchProduct, setValSearchProduct] = useState<string>("");
    const [listProducts, setListProducts] = useState<ListProduct[]>([]);
    const [dataProduct, setDataProduct] = useState<DataProduct>({
        productId: "",
        productName: "",
        productSku: "",
        productNote: "",
        productUnits: [],
        productPrice: 0,
        productVatPercentage: 0,
    });

    const [isDataProduct, setIsDataProduct] = useState<boolean>(false);
    const [qtyProduct, setQtyProduct] = useState<string>("");
    const [isQtyProduct, setIsQtyProduct] = useState<boolean>(false);
    const [priceProduct, setPriceProduct] = useState<string>("");
    const [vatProduct, setVatProduct] = useState<string>("");
    const [isVatProduct, setIsVatProduct] = useState<boolean>(false);
    const [isPriceProduct, setIsPriceProduct] = useState<boolean>(false);
    const [totalProduct, setTotalProduct] = useState<string>("");
    const [isTotalProduct, setIsTotalProduct] = useState<boolean>(false);
    const [hppProduct, setHppProduct] = useState<string>("");
    const [unitProduct, setUnitProduct] = useState<SelectProducUnit>({ value: "", label: "", alias: "" });
    const [isUnitProduct, setIsUnitProduct] = useState<boolean>(false);
    const { companyId } = useCustomeStore()
    const [hideFormProductBtn, setHideFormProductBtn] = useState(false);
    const [codeUrl, setCodeUrl] = useState((pathname).split('/').pop());
    const { templatePipelineId } = useParams()
    const [selectMerchantId, setSelectMerchantId] = useState<string>("")

    useEffect(() => {
        if (codeUrl !== (pathname).split('/').pop()) {
            setCodeUrl((pathname).split('/').pop()!)
        }
    }, [pathname])
    const intl = useIntl()

    useEffect(() => {
        if (user?.data?.company_id === companyId.maspionSquare) {
            if (settings.merchant_custom_fields) {
                setCustomFieldsMerchant(JSON.parse(settings.merchant_custom_fields))
            }
        }
    }, [settings])

    const MenuList = (props: any) => {
        const { options, children, maxHeight, getValue, itemSize } = props;
        const [value] = getValue();
        const listRef = useRef<any>(null);

        const sizeMap = useRef<{ [index: number]: number }>({});

        // Memoized function to measure the height of each item
        const getItemSize = useCallback((index: number) => {
            if (sizeMap.current[index]) {
                return sizeMap.current[index];
            }
            const item = children[index];
            const height = item ? item.props.label.length <= 20 ? 40 : 35 + item.props.label.length : 40; // Example logic for dynamic height
            sizeMap.current[index] = height;
            return height;
        }, [children, valSearchProduct]);

        useEffect(() => {
            if (listRef.current) {
                listRef.current.resetAfterIndex(0, true); // Reset the list after height measurement
            }
        }, [children, getItemSize, valSearchProduct]);

        const dynamicHeight = children.length * getItemSize(options.indexOf(value));

        return (
            <div style={{ width: '100%' }}>
                <List
                    ref={listRef}
                    width="100%"
                    height={dynamicHeight > maxHeight ? maxHeight : dynamicHeight}
                    itemCount={children.length}
                    itemSize={getItemSize}
                    initialScrollOffset={options.indexOf(value) * getItemSize(options.indexOf(value))}
                >
                    {({ index, style }) => (
                        <div style={{ ...style }}>
                            {children[index]}
                        </div>
                    )}
                </List>
            </div>
        );
    };

    const checkIsCompanyElgisa = () => {
        if (user?.data?.company_id === companyId.elgisa) {
            return true
        }
    }

    const checkIsCompanyEsb = () => {
        if (user?.data?.company_id === companyId.esb) {
            return true
        }
    }

    const doubleCheckIsCompanyElgisa = () => {
        if (checkIsCompanyElgisa() && hideFormProductBtn === true) {
            return true
        } else {
            return false
        }
    }

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value)
        setQtyProduct(formattedValue);
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setPriceProduct(formattedValue);

    };
    const handleVatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        if (!isNaN(Number(value)) && Number(value) > 100) {
            return;
        }
        const formattedValue = customNumberFormatString(value);

        setVatProduct(formattedValue);

    };

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setTotalProduct(formattedValue);
    };

    useEffect(() => {
        const total = Number(qtyProduct.toString().replaceAll(".", "")) * Number(priceProduct.toString().replaceAll(".", ""));
        const totalAfterVAT = Math.round(total + Math.round((Number(vatProduct) / 100) * total));
        if (isNaN(totalAfterVAT)) {
            setTotalProduct("")
        } else if (totalAfterVAT === 0) {
            setTotalProduct("0")
        }
        else {
            const formattedTotal = customNumberFormat(totalAfterVAT)
            setTotalProduct(formattedTotal)
        }
    }, [qtyProduct, priceProduct, vatProduct])

    useEffect(() => {
        const totalProductNumber = Number(totalProduct.toString().replaceAll(".", ""))
        const qtyProductNumber = Number(qtyProduct.toString().replaceAll(".", ""))
        const vatProductNumber = Number(vatProduct)
        const priceProductNumber = Number(priceProduct.toString().replaceAll(".", ""))

        // formula : totalbeforevat = totalaftervat / (100 + vat) % 
        //                          = 100 * totalaftervat / 100+vat
        // formula : product        = totalbeforevat / qty product 
        const totalBeforeVat = Math.round(100 * totalProductNumber / (100 + vatProductNumber))
        const productAfterTotalChange = totalBeforeVat / qtyProductNumber
        if (isNaN(productAfterTotalChange) || isNaN(priceProductNumber) || isNaN(qtyProductNumber) || qtyProductNumber === 0) {
            if (priceProduct) {
                setPriceProduct(priceProduct)
            } else {
                setPriceProduct("")
            }
        } else {
            const productAfterTotalChangeFormatted = customNumberFormat(productAfterTotalChange)
            setPriceProduct(productAfterTotalChangeFormatted)
        }



    }, [totalProduct])

    const handleHppChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setHppProduct(formattedValue);
    };

    useEffect(() => { // untuk menghilangkan btn product
        if (checkIsCompanyElgisa()) {
            if (listProducts.length > 0) {
                setHideFormProductBtn(true)
            } else {
                setHideFormProductBtn(false)
            }
        }

        doubleCheckIsCompanyElgisa()
    }, [listProducts, setListProducts])

    function handleAddProduct() {
        if (dataProduct.productId === "") setIsDataProduct(true)
        if (qtyProduct === "") setIsQtyProduct(true)
        if (priceProduct === "") setIsPriceProduct(true)
        if (totalProduct === "") setIsTotalProduct(true)
        // if (vatProduct === "") setIsVatProduct(true)
        if (dataProduct.productUnits.length > 0 && unitProduct.value === "") setIsUnitProduct(true)
        if (dataProduct.productId && qtyProduct && priceProduct &&
            // vatProduct && 
            totalProduct && ((dataProduct.productUnits.length > 0 && unitProduct.value) || dataProduct.productUnits.length == 0)) {
            setListProducts([
                ...listProducts,
                {
                    productId: dataProduct.productId,
                    leadProductVatPercentage: Number(vatProduct),
                    leadProductName: dataProduct.productName.indexOf(" (SKU:") !== -1 ? dataProduct.productName.substring(0, dataProduct.productName.indexOf(" (SKU:")) : dataProduct.productName,
                    productName: dataProduct.productName.indexOf(" (SKU:") !== -1 ? dataProduct.productName.substring(0, dataProduct.productName.indexOf(" (SKU:")) : dataProduct.productName,
                    leadProductQty: parseInt(qtyProduct.split('.').join('')),
                    leadProductNotes: "",
                    leadProductPrice: parseInt(priceProduct.split('.').join('')),
                    leadProductTotal: parseInt(totalProduct.split('.').join('')),
                    leadProductDiscount: null,
                    edit: false,
                    leadProductHpp: hppProduct ? parseInt(hppProduct.split('.').join('')) : null,
                    productSku: dataProduct.productSku,
                    productNote: dataProduct.productNote,
                    unitId: unitProduct.value && unitProduct.value !== 'pcs-id' ? unitProduct.value : null,
                    unitName: unitProduct.label && unitProduct.value !== 'pcs-id' ? unitProduct.label : null,
                    unitAlias: unitProduct.alias && unitProduct.value !== 'pcs-id' ? unitProduct.alias : null,
                    productUnits: dataProduct.productUnits
                }
            ])

            setDataProduct({ productId: "", productName: "", productSku: "", productNote: "", productUnits: [], productPrice: 0, productVatPercentage: 0 })
            setQtyProduct("")
            setPriceProduct("")
            setTotalProduct("")
            setHppProduct("")
            setVatProduct("")
            setUnitProduct({ value: "", label: "", alias: "" })
        }
    }

    function handleUnitChange(option: SelectOptionProduct, index: number) {
        listProducts[index].unitId = option.value;
        listProducts[index].unitName = option.label;
        listProducts[index].unitAlias = option.alias;

        setListProducts([...listProducts])
    }

    function handleInputProduct(type: string, index: number, e: React.ChangeEvent<HTMLInputElement>) {
        var value = e.target.value ?? 0
        value = customNumberFormatString(value)

        if (type === "qty") {
            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)

            const qty = Number(value.replaceAll(".", ""))
            const price = Number(listProducts[index].leadProductPrice)

            const total = Number(qty * price)
            const totalAfterVAT = Math.round(total + vatPercentage / 100 * total)

            listProducts[index].leadProductQty = qty
            listProducts[index].leadProductTotal = totalAfterVAT
        } else if (type === "price") {
            const price = Number(value.replaceAll(".", ""))

            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)
            const qty = Number(listProducts[index].leadProductQty)

            const total = Number(qty * price)
            const totalAfterVAT = Math.round(total + vatPercentage / 100 * total)


            listProducts[index].leadProductPrice = price
            listProducts[index].leadProductTotal = totalAfterVAT
        } else if (type === "vatPercentage") {
            const value = e.target.value ?? 0;
            if (!isNaN(Number(value)) && Number(value) > 100) {
                return;
            }
            const formattedValue = customNumberFormatString(value);
            const vatPercentage = Number(formattedValue)

            const price = Number(listProducts[index].leadProductPrice)
            const qty = Number(listProducts[index].leadProductQty)

            const total = Math.round(Number(qty * price))
            const totalAfterVAT = Math.round(total + vatPercentage / 100 * total)


            listProducts[index].leadProductVatPercentage = vatPercentage
            listProducts[index].leadProductTotal = totalAfterVAT
        } else if (type === "hpp") {
            listProducts[index].leadProductHpp = (value ? Number(value.replaceAll(".", "")) : null)
        } else {
            const totalAfterVAT = Number(value.replaceAll(".", ""))
            const qty = Number(listProducts[index].leadProductQty)
            const price = Number(listProducts[index].leadProductPrice)
            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)

            // formula : totalbeforevat = totalaftervat / (100 + vat) % 
            //                          = 100 * totalaftervat / 100+vat
            // formula : product        = totalbeforevat / qty product 

            const totalBeforeVat = 100 * totalAfterVAT / 100 + vatPercentage
            const newProductVAT = totalBeforeVat / qty


            if (totalAfterVAT === 0) {
                listProducts[index].leadProductPrice = 0
            } else {
                listProducts[index].leadProductPrice = Math.round(Number(newProductVAT)) ?? 0

            }

            listProducts[index].leadProductTotal = Number(totalAfterVAT)
        }

        setListProducts([...listProducts])
    }

    function handleUpdateProduct(index: number) {
        listProducts[index].edit = true
        setListProducts([...listProducts])
    }

    function handleSaveUpdateProduct(index: number) {
        listProducts[index].edit = false
        setListProducts([...listProducts])
    }

    function handleDeleteProduct(index: number) {
        setListProducts(listProducts.filter((_, i) => i !== index))
    }

    const filterProduct = (inputValue: string) => {
        return productData.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const handleSearchProduct = (
        inputValue: string,
        callback: (options: SelectOptionProduct[]) => void
    ) => {
        setValSearchProduct(inputValue)
        setTimeout(() => {
            callback(filterProduct(inputValue));
        }, 1000);
    };

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token,
            },
        });
        return response.data.data;
    };

    const API_URL = process.env.REACT_APP_API_URL
    const getUserURL = () => {
        if (user.data.permission_pipeline_read === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }


    const getProductURL = () => {
        if ((window.location.pathname.includes('/meetings/') || window.location.pathname === '/chat') && selectTemplatePipeline) {
            return `${API_URL}lead-template-product-accesses/${selectTemplatePipeline}`
        } else if (!window.location.pathname.includes('/meetings/') && window.location.pathname !== '/chat' && selectMultiPipeline.id) {
            return `${API_URL}lead-template-product-accesses/${selectMultiPipeline.id}`
        } else {
            return null
        }
    }

    const selectPIC = () => {
        if (usersData.find((userData) => userData.value === meeting?.owner_id)) {
            return meeting?.owner_id || ''
        } else if (usersData.find((userData) => userData.value === user.data.id)) {
            return user.data.id || ''
        } else if (meeting?.owner_id === user.data.id) {
            return user.data.id
        } else {
            return ''
        }
    }

    const { data: userSubordinatesData = [], error: subordinatesError, isLoading: userSubordinatesLoading } = useSWR(getUserURL(), fetcher)
    const { data: merchantsData = [], error: merchantsError, isLoading: merchantsLoading } = useSWR(user.data.permission_merchant_read !== 'NONE' ? `${API_URL}merchants${templatePipelineId ? `?leadTemplateId=` + templatePipelineId : ""}` : null, fetcher)
    const { data: simpleMerchantsData = [], error: simpleMerchantsError, isLoading: simpleMerchantsLoading } = useSWR(`${API_URL}simple-merchants`, fetcher)
    const { data: source = [], error: sourceError, isLoading: sourceLoading } = useSWR(`${API_URL}lead_sources`, fetcher)
    const { data: product = [], error: productError, isLoading: productLoading } = useSWR(getProductURL(), fetcher)
    const { data: multiMerchantsData = [], error: multiMerchantsError, isLoading: multiMerchantsLoading } = useSWR(`${API_URL}merchant-templates`, fetcher)
    const { data: merchantById = [], error: merchantByIdError, isLoading: merchantByIdLoading } = useSWR(selectMerchantId && user.data.company_id === companyId.maspionSquare ? `${API_URL}merchants/${selectMerchantId}` : null, fetcher)
    
    const initialValues = {
        templatePipeline: (window.location.pathname.includes('/meetings/') || window.location.pathname === '/chat') ? selectTemplatePipeline : selectMultiPipeline.id,
        pic: '',
        labels: '',
        startDate: '',
        merchantLink: '',
        merchant: merchants?.find((merchant: Merchant) => merchant.id === (merchantId ? merchantId[0] : ''))?.name ?? '',
        source: '',
        note: '',
        ...Object.fromEntries(Object.keys(customFields).flatMap((key) => {
            if (customFields[key].type === 'invoice_reminder') {
                return [
                    [`${key}_invoice_url`, null],
                    [`${key}_date_due`, null]
                ];
            } else {
                return [[key, null]];
            }
        })),
    }

    if (user.data.permission_pipeline_create !== 'TEAM' && user.data.permission_pipeline_create !== 'ALL') initialValues.pic = user.data.name
    if (meeting && (user.data.permission_pipeline_create === 'TEAM' || user.data.permission_pipeline_create === 'ALL')) {
        initialValues.pic = selectPIC()
        initialValues.merchant = meeting.merchant_name
        initialValues.startDate = moment(meeting.date_meet).format('YYYY-MM-DD')
        initialValues.note = meeting.note
    } else {
        if (meeting?.date_meet) {
            initialValues.merchant = meeting.merchant_name
            initialValues.startDate = moment(meeting!.date_meet).format('YYYY-MM-DD')
        }
    }

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (field.type === 'multiple' || field.type === 'images' || field.type === 'files' || field.type === 'merchant' || field.type === 'user') {
            obj[item] = field.required
                ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else if (field.type === 'date') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        } else if (field.type === 'link') {
            obj[item] = field.required
                ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                })
                : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                });
        } else if (field.type === 'serial') {
            obj[item] = Yup.array().notRequired().nullable();
        } else {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        templatePipeline: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: "Template " + settings.pipeline_title })).nullable(),
        labels: Yup.array().notRequired(),
        pic: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PIC" }) })).nullable(),
        startDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" }) })).nullable(),
        merchant: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: settings.merchant_title })),
        source: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.SOURCES" }) })),
        note: (user.data.company_id === companyId.elgisa || user.data.company_id === companyId.looyalIT) ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: "Opportunity" })) : Yup.string().notRequired().nullable(),
        ...formSchema
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (listProducts.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: intl.formatMessage({ id: "FORM.VALIDATION.ADD_FIRST" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }) }),
                    heightAuto: false,
                    target: document.getElementById('add-pipeline-modal'),
                    confirmButtonText: 'Ok'
                })

                return false
            }

            setSubmitting(true)
            setModalBtnLoading(true)

            const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [item]: (values as Record<string, string | string[] | null>)[item],
                }),
                {}
            );

            const convertCustomFields: Record<string, string> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [`custom_fields.${item}`]: (values as Record<string, string | string[] | null>)[`custom_fields.${item}`] ?? "",
                }),
                {}
            );

            // Use Promise.all to await all async operations
            var countResourceError = 0
            await Promise.all(
                Object.keys(customFields).map(async (key) => {
                    if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                        try {
                            if (values[key as keyof typeof values]) {
                                const resourceResponse = await insertLeadFieldAttachment(values[key as keyof typeof values]);
                                if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                        try {
                            if (values[key as keyof typeof values]) {
                                const resourceResponse = await insertLeadFieldMultiAttachment(values[key as keyof typeof values] as any);
                                if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'invoice_reminder') {
                        try {
                            const resourceResponse = await insertLeadFieldAttachment(values[key + "_invoice_url" as keyof typeof values]);
                            if (resourceResponse.data.success) customFieldsValues[key] = JSON.stringify({ date_due: values[key + "_date_due" as keyof typeof values], invoice_url: resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null })
                            else countResourceError++
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'multiple') {
                        var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                        customFieldsValues[key] = multipleValues as string
                    } else if (customFields[key].type === 'serial') {
                        try {
                            if (dataCustomFieldSerial[key].value.length === 0) {
                                const serialResponse = await generateSerialApi(key, "Pipeline", token);
                                customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                            } else {
                                customFieldsValues[key] = dataCustomFieldSerial[key].value
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'merchant') {
                        convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, simpleMerchants)?.map((item: any) => item.name).join(", ");
                    } else if (customFields[key].type === 'user') {
                        convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, users)?.map((item: any) => item.name).join(", ");
                    } else if (customFields[key].type === 'pipeline') {
                        if (values[key as keyof typeof values]) convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as string, pipelines)?.name + " / Rp." + customNumberFormat(getDataById(values[key as keyof typeof values] as string, pipelines)?.nominal) + " / " + moment(getDataById(values[key as keyof typeof values] as string, pipelines)?.dateCreated).format('DD MMM YYYY');
                        else convertCustomFields[`custom_fields.${key}`] = "-";
                    }
                })
            );

            if (countResourceError > 0) {
                Swal.fire({
                    icon: "error",
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" })}.`,
                    heightAuto: false,
                    target: document.getElementById('add-pipeline-modal'),
                    confirmButtonText: 'Ok'
                })
                setModalBtnLoading(false);
                setSubmitting(false);
            } else {
                try {
                    const leadLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label, name: labelsOption.filter((option: any) => option.value === label)[0]['label'] } }) : []
                    const startDate = moment(values.startDate).toISOString();
                    const response = await insertLead(
                        leadLabels,
                        values.templatePipeline,
                        isUUID(values.pic) ? values.pic : user.data.id,
                        meeting && !merchantId ? meeting.merchant_id : merchantId?.length === 1 ? merchantId[0] : values.merchant,
                        startDate,
                        values.source,
                        listProducts,
                        token,
                        values.note === '' ? undefined : values.note,
                        meeting ? meeting.id : undefined,
                        customFieldsValues
                    );
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                            heightAuto: false,
                        })
                        closeModalRef.current?.click()

                        if (meeting) {
                            setTablesData('meetings', (tablesData.meetings?.map(data => {
                                if (data.id === meeting.id) {
                                    return {
                                        ...data,
                                        meeting_is_pipeline: true,
                                    }
                                } else {
                                    return data
                                }
                            })));
                        }

                        let merchantLinkResult = null
                        if (values.merchantLink) {
                            merchantLinkResult = await Promise.resolve(handleSaveMerchantLink(response.data.data.id));
                        }

                        response.data.data.lead_logs_count = 0
                        mutate(`${API_URL}chat/merchants-leads`)
                        if (window.location.pathname === '/chat') {
                            
                            const { recentChatHistories, setRecentChatHistories, phoneNumber } = useChatStore.getState();
                            
                            const newData = recentChatHistories.map(recentChat => {
                                if (`${recentChat.chat_phone}-${recentChat.as}` === phoneNumber) {
                                    return {
                                        ...recentChat,
                                        pipelines: recentChat.pipelines ? [response.data.data, ...recentChat.pipelines] : [response.data.data],
                                    };
                                }
                                return recentChat;
                            })

                            setRecentChatHistories(() => newData);
                            
                            const getPipelineURL = () => {
                                if (user.data.is_superadmin) {
                                    return `${API_URL}leads?scope=all&merchant_phone=${phoneNumber.split('-')[0]}`
                                } else {
                                    return `${API_URL}leads?scope=${defaultScope(user.data.permission_pipeline_read)}&user_id=${user.data.id}&merchant_phone=${phoneNumber.split('-')[0]}`
                                }
                            }
                        
                            const getMeetingURL = () => {
                                if (user.data.is_superadmin) {
                                    return `${API_URL}meetings?scope=all&merchant_phone=${phoneNumber.split('-')[0]}`
                                } else {
                                    return `${API_URL}meetings?scope=${defaultScope(user.data.permission_meeting_read)}&user_id=${user.data.id}&merchant_phone=${phoneNumber.split('-')[0]}`
                                }
                            }

                            mutate(getPipelineURL())
                            mutate(getMeetingURL())
                        }

                        setTablesData('pipelines', ([{
                            ...response.data.data,
                            ...convertCustomFields,
                            // handle ketidaksusain round antar BE dan FE saat search Contoh : 3.99 -> 4//
                            nominalWithoutVatForSearch: response.data.data.nominal_without_vat ? Math.round(response.data.data.nominal_without_vat as number).toString() : null,
                            nominalForSearch: response.data.data.nominal ? Math.round(response.data.data.nominal as number).toString() : null,
                            merchant_link: merchantLinkResult,
                            lead_progress_name: response.data.data.lead_progress_name === "NEW" ? intl.formatMessage({ id: "FORM.LABEL.NEW" }) : response.data.data.lead_progress_name,
                            leadProducts: response.data.data.leadProducts?.map((product: ListProduct) => { //nambahin data yg gaada di response
                                const objProduct = { ...product }
                                const dataProductById = listProducts.filter((p) => p.productId === product.productId);
                                const dataProductByUnit = listProducts.filter((p) => product.leadProductUnitId && p.unitId === product.leadProductUnitId);
                                if (dataProductById.length > 0) {
                                    objProduct.productSku = dataProductById[0]?.productSku
                                    objProduct.productNote = dataProductById[0]?.productNote
                                }
                                if (dataProductByUnit.length > 0) {
                                    objProduct.unitId = dataProductByUnit[0]?.unitId
                                    objProduct.unitName = dataProductByUnit[0]?.unitName
                                    objProduct.productUnits = dataProductByUnit[0]?.productUnits
                                }
                                return objProduct
                            })
                        }, ...tablesData.pipelines]))
                        setFetchCounter(fetchCounter + 1)
                        handleResetFilter!()
                        setScope(user.data.permission_pipeline_read === 'OWNED' || user.data.permission_pipeline_read === 'ALL' ? defaultScope(user.data.permission_pipeline_read) : UserScope.WholeTeam)
                        // }
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        target: document.getElementById('add-pipeline-modal'),
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setSubmitting(false)
                    setModalBtnLoading(false)
                }
            }
        }
    });

    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=pipeline`, fetcher)

    useEffect(() => {
        if (formik.values.merchant) {
            setSelectMerchantId(formik.values.merchant)
        }
    }, [formik.values.merchant])

    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            if (!showAddMerchantModal) {
                formik.resetForm()
                formik.setFieldValue('startDate', '')
                setOptionMerchantLink([])
                setSelectMerchantLink({} as SelectOptionMerchantLink)
                setListProducts([])
                setDataProduct({ productId: "", productName: "", productSku: "", productNote: "", productUnits: [], productPrice: 0, productVatPercentage: 0 })
                setQtyProduct("")
                setPriceProduct("")
                setTotalProduct("")
                setHppProduct("")
                setUnitProduct({ value: "", label: "", alias: "" })
                setIsDataProduct(false)
                setIsQtyProduct(false)
                setIsPriceProduct(false)
                setIsTotalProduct(false)
                setIsUnitProduct(false)
                setSelectTemplatePipeline("")
                Object.keys(customFields).forEach((key, index) => {
                    // Reset 
                    const ref = refArray.current[index];
                    if (ref && ref.value) {
                        ref.value = "";
                    }

                    if (customFields[key].type === "multiple") {
                        var checkboxes = document.getElementsByName(key)
                        checkboxes.forEach((checkbox: any) => {
                            checkbox.checked = false
                        })
                    }
                    if (customFields[key].type === "date") {
                        formik.setFieldValue(key, null)
                    }
                });
            }
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik, showAddMerchantModal, setShowAddMerchantModal]);

    useEffect(() => {
        setUserSubordinateLoading(userSubordinatesLoading)
        if (!userSubordinatesLoading) {
            const data = userSubordinatesData.map((subordinate: Subordinate) => ({
                value: subordinate.id,
                label: subordinate.name
            }));

            if (user.data.permission_pipeline_read !== "ALL") {
                data.unshift({ value: user.data.id, label: user.data.name })
            }

            setUsersData(data)
            setUserSubordinates(userSubordinatesData)
        }
    }, [userSubordinatesData]);

    useEffect(() => {
        setMerchantLoading(merchantsLoading)
        if (!merchantsLoading) {
            setTimeout(() => {
                setMerchants(merchantsData)
            }, 100)
        }
    }, [merchantsLoading, merchantsData]);

    useEffect(() => {
        if (!multiMerchantsLoading) {
            setMultiMerchants(multiMerchantsData)
        }
    }, [multiMerchantsLoading]);

    useEffect(() => {
        setSimpleMerchantLoading(simpleMerchantsLoading)
        if (!simpleMerchantsLoading) {
            setSimpleMerchants(simpleMerchantsData)
        }
    }, [simpleMerchantsData]);

    useEffect(() => {
        setTimeout(() => {
            const groupMerchant: { label: string, id: string, options: SelectOptionProduct[] }[] = multiMerchants.map((group) => {
                return {
                    label: group.name,
                    id: group.id,
                    options: [] as SelectOptionProduct[]
                }
            })


            if (!merchantLoading && !merchantId) {
                merchants.forEach((merchant) => {
                    var customFieldValueCompanyCode = simpleMerchants.find((data: Merchant) => data.id === merchant!.id)

                    const isEsbCompany = user.data.company_id === companyId.esb
                    const index = groupMerchant.findIndex((group) => group.id === merchant.merchantTemplateId)
                    index !== -1 && groupMerchant[index].options.push({
                        ...merchant,
                        value: merchant.id,
                        label: `${merchant.name} / ${merchant.phone} / ${(isEsbCompany && customFieldValueCompanyCode?.customFieldCompanyCode) ? customFieldValueCompanyCode?.customFieldCompanyCode : "-"}`
                    })
                })
                setMerchantData(groupMerchant)
            }
            else if (!merchantLoading && merchantId) {
                const merchantData = merchants?.filter((merchant: Merchant) => merchantId.includes(merchant.id))
                merchantData.forEach((merchant) => {
                    var customFieldValueCompanyCode = simpleMerchants.find((data: Merchant) => data.id === merchant!.id)

                    const isEsbCompany = user.data.company_id === companyId.esb
                    const index = groupMerchant.findIndex((group) => group.id === merchant.merchantTemplateId)
                    index !== -1 && groupMerchant[index].options.push({
                        ...merchant,
                        value: merchant.id,
                        label: `${merchant.name} / ${merchant.phone} / ${(isEsbCompany && customFieldValueCompanyCode?.customFieldCompanyCode) ? customFieldValueCompanyCode?.customFieldCompanyCode : "-"}`
                    })
                })

                setMerchantData(groupMerchant)
            }
        }, 0)
    }, [merchants, merchantId, multiMerchants, simpleMerchants])

    useEffect(() => {
        if (!sourceLoading) {
            const sourceData = source.map((source: Source) => ({
                value: source.id,
                label: source.name
            }));
            setSourceData(sourceData)
        }
    }, [source])

    function mapUnitSelect(data: any) {
        const mappedData = data.map((x: ProductUnits) => ({
            value: x.unit.id,
            label: x.unit.name,
            alias: x.unit.alias
        }));
        return mappedData;
    }

    useEffect(() => {
        setUnitProduct({ value: "", label: "", alias: "" })
        setIsUnitProduct(false)
        if (dataProduct.productUnits.length > 0) {
            // const unitSelectData = dataProduct.productUnits.map((x: ProductUnits) => ({
            //     value: x.unit.id,
            //     label: x.unit.name,
            //     alias: x.unit.alias
            // }));
            const unitSelectData = mapUnitSelect(dataProduct.productUnits)
            setUnitData([...unitSelectData, { value: 'pcs-id', label: 'PCS', alias: 'PCS' }])
        }
    }, [dataProduct])

    useEffect(() => {
        if (!productLoading && product.length > 0) {
            const products = product.map((product: Product) => ({
                value: product.id,
                label: product.name + (product.sku ? (' (SKU: ' + product.sku + ')') : ''),
                sku: product.sku,
                note: product.note,
                productUnits: product.productUnits,
                vatPercentage: product.vatPercentage,
                price: product.price
            }));

            setProductData(products)
        }
    }, [product, productLoading])

    useEffect(() => {
        if (selectMultiPipeline && Object.keys(selectMultiPipeline).length !== 0) {
            var data = selectMultiPipeline.customFields
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: []
                };
                return acc;
            }, {});

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [selectMultiPipeline])

    useEffect(() => {
        if (showAddMerchantModal) {
            const modalElement = document.getElementById(`add-merchant-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddMerchantModal(false);
                    document.getElementById(`tambah-pipeline-button`)?.click()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddMerchantModal, setShowAddMerchantModal]);

    useEffect(() => {
        if (formik.values.templatePipeline) {
            setCustomFields(multiPipelines.find((pipeline: SettingMultiPipelines) => pipeline.id === formik.values.templatePipeline)!.customFields)
        }
    }, [formik.values.templatePipeline])

    useEffect(() => {
        if (formik.values.startDate === null || formik.values.startDate === '') {
            setIsCustomeFieldDateDisabled(true);
            Object.keys(customFields).forEach((key, index) => {
                // Reset 
                if (customFields[key].type === "date") {
                    formik.setFieldValue(key, null)
                }
            });
        } else {
            setIsCustomeFieldDateDisabled(false)
        }
    }, [formik.values.startDate])

    const addMerchant = (merchant: Merchant) => {
        // setMerchantData([...merchantData, {
        //     value: merchant.id,
        //     label: merchant.name + " / " + merchant.phone
        // }]);

        setMerchants([...merchants, merchant])
        formik.setFieldValue("merchant", merchant.id)
    }

    const handleChangeResetFilter = (value: boolean) => {
        const updatedColumns = manageColumns.map((data: TableColumn<Pipeline>) => ({
            ...data,
            omit: !value,
        }));

        setManageColumns(updatedColumns)
    }

    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Pipeline", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('add-pipeline-modal'),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }


    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.Option>
        );
    };

    const CustomSingleValue = (props: any) => {
        return (
            <components.SingleValue {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.SingleValue>
        );
    };

    const CustomSelectMerchantOptionReqESB = (props: { option: VirtualizedSelectOptionsType; onClick?: () => void }) => {
        const { option, onClick } = props;
        var result = simpleMerchants.find((merchant: Merchant) => merchant.id === option.value);
        var customFieldValueCompanyCode = result?.customFieldCompanyCode ? result.customFieldCompanyCode : "-";

        return (
            <div className="d-flex align-items-center px-3 h-100" style={{ cursor: 'pointer', background: option.value === 'chocolate' ? '#d9d9d9' : 'transparent', }}>
                {
                    user.data.company_id === companyId.esb ?
                        <span className="align-middle"><b>{result!.name}</b> / {customFieldValueCompanyCode}</span>
                        :
                        <span className="align-middle">{result!.name}</span>
                }
            </div>
        );
    }

    const CustomSelectSingleMerchantValueReqESB = (props: { value: Option; placeholder?: string }) => {
        const { value, placeholder } = props;
        if (!value?.value) return <span>{placeholder}</span>
        var result = simpleMerchants.find((merchant: Merchant) => merchant.id === value.value);
        var customFieldValueCompanyCode = result?.customFieldCompanyCode ? result.customFieldCompanyCode : "-";

        return (
            user.data.company_id === companyId.esb ?
                <span className="align-middle"><b>{result!.name}</b> / {customFieldValueCompanyCode}</span>
                :
                <span className="align-middle">{result!.name}</span>
        );
    }

    const CustomSelectMerchantOption = (props: { option: VirtualizedSelectOptionsType; onClick?: () => void }) => {
        const { option, onClick } = props;
        var result = merchants.find((merchant: Merchant) => merchant.id === option.value);

        return (
            <div className="d-flex align-items-center px-3 h-100" style={{ cursor: 'pointer', background: option.value === 'chocolate' ? '#d9d9d9' : 'transparent', }}>
                <span className="align-middle"><b>{result!.name}</b> / {result!.phone}</span>
            </div>
        );
    }

    const CustomSelectSingleMerchantValue = (props: { value: Option; placeholder?: string }) => {
        const { value, placeholder } = props;
        if (!value?.value) return <span>{placeholder}</span>
        var result = merchants.find((merchant: Merchant) => merchant.id === value.value);

        return (
            <span className="align-middle"><b>{result!.name}</b> / {result!.phone}</span>
        );
    }

    const CustomSelectMerchantLinkOption = (props: { option: VirtualizedSelectOptionsType; onClick?: () => void }) => {
        const { option, onClick } = props;

        return (
            <div className="d-flex align-items-center px-3 h-100" style={{ cursor: 'pointer', background: option.value === 'chocolate' ? '#d9d9d9' : 'transparent', }}>
                {
                    user.data.company_id === companyId.esb ?
                        <span className="align-middle">{option.nameTemplate} / {option.nameMerchant}</span>
                        :
                        <span className="align-middle">{option.nameTemplate} / {option.nameMerchant} / {option.nameCustomField}</span>
                }
            </div>
        );
    }

    const CustomSelectSingleMerchantLinkValue = (props: { value: Option; placeholder?: string }) => {
        const { value, placeholder } = props;
        if (!value?.value) return <span>{placeholder}</span>

        return (
            user.data.company_id === companyId.esb ?
                <span className="align-middle">{value.nameTemplate} / {value.nameMerchant}</span>
                :
                <span className="align-middle">{value.nameTemplate} / {value.nameMerchant} / {value.nameCustomField}</span>
        );
    }

    const handleChangeMerchant = async (option: Option) => {
        formik.handleChange({
            target: {
                name: "merchant",
                value: (option?.value || "")
            }
        })

        formik.handleChange({
            target: {
                name: "merchantLink",
                value: ""
            }
        })

        setSelectMerchantLink({} as SelectOptionMerchantLink)

        if (option === null) return

        try {
            var res = await getListMerchantLinkPipeline(option.value, token)
            if (res.data.success) {
                var listOption = res.data.data.merchantTemplateDestinationData.map((item: any) => ({
                    label: item.nameCustomField + " / " + item.templateName + " / " + item.merchantName,
                    value: item.idCustomField + "_" + item.templateId + "_" + item.merchantId,
                    nameCustomField: item.nameCustomField,
                    idCustomField: item.idCustomField,
                    nameMerchant: item.merchantName,
                    nameTemplate: item.templateName,
                    idTemplate: item.templateId,
                    idMerchant: item.merchantId,
                    idMerchantLink: res.data.data.id
                }))

                setOptionMerchantLink(listOption)
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('add-pipeline-modal'),
                heightAuto: false
            })
        }
    }

    const handleSaveMerchantLink = async (leadId: string) => {
        try {
            var res = await saveMerchantLinkPipeline(leadId, selectMerchantLink?.idMerchant!, selectMerchantLink?.idMerchantLink!, token)
            if (res.data.success) {
                return res.data.data
            }
        } catch (error: any) {
            console.log(error.response.data.message)
        }
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);


    return (
        <div>
            {
                !meeting && !window.location.pathname.includes('/chat') && !window.location.pathname.includes('/meetings') &&
                <button type="button"
                    className="btn btn-primary mt-3"
                    data-bs-toggle="modal"
                    data-bs-target="#add-pipeline-modal"
                    id="tambah-pipeline-button"
                    disabled={!settings.pipeline_abandoned_lock ? false : (tablesData.pipelinesAbandoned && tablesData.pipelinesAbandoned.length > 0) ? true : false}
                >
                    <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {selectMultiPipeline.name}
                </button>
            }
            <div className="modal fade" tabIndex={-1} id="add-pipeline-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ADD" })} {!meeting && !window.location.pathname.includes('/chat') ? selectMultiPipeline.name : settings.pipeline_title}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* Template Pipeline */}
                                {
                                    (window.location.pathname.includes('/meetings/') || window.location.pathname === '/chat') &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>Template {settings.pipeline_title}</label>
                                        <Select
                                            options={multiPipelines}
                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} Template ${settings.pipeline_title}`}
                                            value={multiPipelines.find(option => option.value === formik.values.templatePipeline) || null}
                                            onChange={(option) => {
                                                formik.handleChange({
                                                    target: {
                                                        name: "templatePipeline",
                                                        value: option?.value || ""
                                                    }
                                                })

                                                setSelectTemplatePipeline(option?.value || "")
                                                mutate(`${API_URL}lead-template-product-accesses/${option?.value}`)
                                            }}
                                            onBlur={
                                                () => formik.values.templatePipeline === "" && formik.setFieldTouched("templatePipeline", true)
                                            }
                                            className="w-100"
                                        />
                                        {formik.touched.templatePipeline && formik.errors.templatePipeline && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.templatePipeline}</span>
                                            </div>
                                        )}
                                    </>
                                }

                                {/* Users */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.PIC" })}</label>
                                <div className="input-group mb-3">
                                    {
                                        user.data.permission_pipeline_create === 'TEAM' || user.data.permission_pipeline_create === 'ALL' ?
                                            meeting ?
                                                <div className="input-group mb-3">
                                                    <input
                                                        value={(usersData.find(option => option.value === formik.values.pic)?.label) || formik.values.pic === user.data.id ? user.data.name : '???'}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched.pic && formik.errors.pic },
                                                        )}
                                                        type='text'
                                                        name='pic'
                                                        autoComplete='off'
                                                        onInput={(e) => handleCaps(e)}
                                                        disabled
                                                    />
                                                </div>
                                                :
                                                <VirtualizedSelect
                                                    className="w-100"
                                                    options={usersData}
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PIC" })}`}
                                                    value={usersData.find(option => option.value === formik.values.pic) || null}
                                                    onChange={
                                                        option => formik.handleChange({
                                                            target: {
                                                                name: "pic",
                                                                value: option?.value || ""
                                                            }
                                                        })
                                                    }
                                                    onBlur={
                                                        () => formik.values.pic === "" && formik.setFieldTouched("pic", true)
                                                    }
                                                    isDisabled={userSubordinateLoading}
                                                />
                                            :
                                            <div className="input-group mb-3">
                                                <input
                                                    {...formik.getFieldProps('pic')}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': formik.touched.pic && formik.errors.pic },
                                                    )}
                                                    type='text'
                                                    name='pic'
                                                    autoComplete='off'
                                                    onInput={(e) => handleCaps(e)}
                                                    disabled
                                                />
                                            </div>
                                    }
                                </div>
                                {formik.touched.pic && formik.errors.pic && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.pic}</span>
                                    </div>
                                )}

                                {/* startDate */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" })}</label>
                                <div className="input-group mb-3">
                                    {
                                        settings.pipeline_date_min && settings.pipeline_date_min === "1" ?
                                            <DatePicker
                                                ranges={todayRange}
                                                editable={false}
                                                format="dd MMM yyyy HH:mm"
                                                value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                                defaultValue={null}
                                                style={{ width: "100%", zIndex: 0 }}
                                                container={document.querySelector('#add-pipeline-modal') as HTMLElement}
                                                onChange={(value: any) => {
                                                    if (value) formik.setFieldValue('startDate', moment(value).format('YYYY-MM-DD HH:mm'))
                                                    else formik.setFieldValue('startDate', null)
                                                }}
                                                shouldDisableDate={date => isBefore(date, subDays(startOfToday(), 1)) || isAfter(date, new Date())}
                                            />
                                            :
                                            <DatePicker
                                                ranges={todayRange}
                                                editable={false}
                                                format="dd MMM yyyy HH:mm"
                                                value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                                defaultValue={null}
                                                style={{ width: "100%", zIndex: 0 }}
                                                container={document.querySelector('#add-pipeline-modal') as HTMLElement}
                                                onChange={(value: any) => {
                                                    if (value) formik.setFieldValue('startDate', moment(value).format('YYYY-MM-DD HH:mm'))
                                                    else formik.setFieldValue('startDate', null)
                                                }}
                                            />
                                    }
                                </div>
                                {formik.touched.startDate && formik.errors.startDate && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                    </div>
                                )}

                                {/* label */}
                                {
                                    user.data.company_id !== companyId.esb &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                        <div className="input-group mb-3">
                                            <Select
                                                closeMenuOnSelect={false}
                                                openMenuOnClick
                                                isMulti
                                                options={labelsOption}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                                value={labelsOption && Array.isArray(labelsOption)
                                                    ? labelsOption.filter((option: any) =>
                                                        formik.values.labels.includes(option.value)
                                                    )
                                                    : []}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions
                                                        ? selectedOptions.map((option: any) => option.value)
                                                        : []
                                                    formik.setFieldValue('labels', selectedValues)
                                                }}
                                                className='w-100'
                                                onBlur={() =>
                                                    formik.values.labels === '' &&
                                                    formik.setFieldTouched('labels', true)
                                                }
                                            />
                                        </div>
                                        {formik.touched.labels && formik.errors.labels && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                            </div>
                                        )}
                                    </>
                                }

                                {/* Merchants */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{settings.merchant_title}</label>
                                <div className="">
                                    {
                                        !meeting && !merchantId ?
                                            <div className="row mb-3">
                                                <VirtualizedSelect
                                                    title={settings.merchant_title}
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    options={merchantData}
                                                    isDisabled={merchantLoading || simpleMerchantLoading}
                                                    className={clsx("col-10")}
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${settings.merchant_title}`}
                                                    value={merchantData.map(merchanttemplate => merchanttemplate.options).flat().find((option) => option.value === formik.values.merchant) || null}
                                                    onChange={(option: Option) => { handleChangeMerchant(option) }}
                                                    onBlur={
                                                        () => formik.values.merchant === "" && formik.setFieldTouched("merchant", true)
                                                    }
                                                    components={
                                                        {
                                                            Option: user.data.company_id === companyId.esb ? CustomSelectMerchantOptionReqESB : CustomSelectMerchantOption,
                                                            SingleValue: user.data.company_id === companyId.esb ? CustomSelectSingleMerchantValueReqESB : CustomSelectSingleMerchantValue,
                                                        }
                                                    }
                                                />
                                                <button type="button" className="btn btn-secondary col-2 btn-sm" data-bs-dismiss="modal" onClick={() => {
                                                    setShowAddMerchantModal(true);
                                                }}>
                                                    <i className="fas fa-plus"></i>
                                                </button>
                                            </div>
                                            :
                                            merchantId && merchantId.length === 1 ?
                                                <div className="input-group mb-3">
                                                    <input
                                                        {...formik.getFieldProps('merchant')}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched.merchant && formik.errors.merchant },
                                                        )}
                                                        type='text'
                                                        name='merchant'
                                                        autoComplete='off'
                                                        onInput={(e) => handleCaps(e)}
                                                        disabled
                                                    />
                                                </div>
                                                :
                                                <div className="mb-3">
                                                    <VirtualizedSelect
                                                        title={settings.merchant_title}
                                                        key={
                                                            window.location.pathname === '/chat' ? nanoid() : ''
                                                        }
                                                        options={merchantData}
                                                        isDisabled={merchantLoading || simpleMerchantLoading}
                                                        className={clsx("col-12")}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${settings.merchant_title}`}
                                                        value={merchantData.map(merchanttemplate => merchanttemplate.options).flat().find((option) => option.value === formik.values.merchant) || null}
                                                        onChange={(option: Option) => { handleChangeMerchant(option) }}
                                                        onBlur={
                                                            () => formik.values.merchant === "" && formik.setFieldTouched("merchant", true)
                                                        }
                                                        components={
                                                            {
                                                                Option: user.data.company_id === companyId.esb ? CustomSelectMerchantOptionReqESB : CustomSelectMerchantOption,
                                                                SingleValue: user.data.company_id === companyId.esb ? CustomSelectSingleMerchantValueReqESB : CustomSelectSingleMerchantValue,
                                                            }
                                                        }
                                                    />
                                                </div>
                                    }
                                </div>
                                {formik.touched.merchant && formik.errors.merchant && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.merchant}</span>
                                    </div>
                                )}

                                {
                                    optionMerchantLink.length > 0 &&
                                    <div className="mb-3">
                                        <VirtualizedSelect
                                            title={settings.merchant_title}
                                            key={
                                                window.location.pathname === '/chat' ? nanoid() : ''
                                            }
                                            options={optionMerchantLink}
                                            isDisabled={merchantLoading}
                                            className={clsx("col-10")}
                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${settings.merchant_title} Link`}
                                            value={optionMerchantLink.find(data => data.value === formik.values.merchantLink) || null}
                                            onChange={(option: Option) => {
                                                setSelectMerchantLink(option)
                                                formik.handleChange({
                                                    target: {
                                                        name: "merchantLink",
                                                        value: (option?.value || "")
                                                    }
                                                })
                                            }}
                                            components={
                                                {
                                                    Option: CustomSelectMerchantLinkOption,
                                                    SingleValue: CustomSelectSingleMerchantLinkValue,
                                                }
                                            }
                                        />
                                    </div>
                                }

                                {/* Detail Select Merchant */}
                                {formik.values.merchant && merchantById && user.data.company_id === companyId.maspionSquare && (
                                    <div className="mb-5">
                                        <div
                                            className="d-flex align-items-center cursor-pointer mb-3"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#merchantDetails"
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="merchantDetails"
                                        >
                                            <i className="fas fa-chevron-right me-2 fs-6 toggle-icon"></i>
                                            <span className="fw-bold">{intl.formatMessage({ id: "FORM.LABEL.DETAILS" }, { title: settings.merchant_title })} </span>
                                        </div>

                                        <div className="collapse show" id="merchantDetails">
                                            <ul className="list-unstyled ms-4">
                                                {Object.keys(customFieldsMerchant || {}).map((key) => {
                                                    if (CUSTOM_FIELD_NAME_MERCHANT_MASPION_SQUARE.includes(customFieldsMerchant[key].name)) {
                                                        return (
                                                            <li key={key} className="d-flex align-items-center mb-3">
                                                                <span className="fw-bold" style={{ width: "200px" }}>{customFieldsMerchant[key].name}</span>
                                                                <span className="mx-2">:</span>
                                                                <span>
                                                                    {merchantById.customFields && merchantById.customFields[key] ?
                                                                        merchantById.customFields[key]
                                                                        :
                                                                        <span className="text-muted fst-italic">-</span>
                                                                    }
                                                                </span>
                                                            </li>
                                                        )
                                                    }
                                                    return null;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                )}

                                {/* Sources */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.MENU.SOURCES" })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        key={
                                            window.location.pathname === '/chat' ? nanoid() : ''
                                        }
                                        isClearable
                                        options={sourceData}
                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.SOURCES" })}`}
                                        value={sourceData.find(option => option.value === formik.values.source) || null}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "source",
                                                    value: option?.value || ""
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.source === "" && formik.setFieldTouched("source", true)
                                        }
                                        className="w-100"
                                        isDisabled={sourceLoading}
                                    />
                                </div>
                                {formik.touched.source && formik.errors.source && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.source}</span>
                                    </div>
                                )}
                                <div className="separator separator-dashed my-10"></div>
                                {doubleCheckIsCompanyElgisa() ? <></> : (
                                    <div className="row">
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Products */}
                                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</label>
                                            <div className="input-group mb-3">
                                                {/* <Select
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    options={productData}
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}`}
                                                    value={productData.find(option => option.value === dataProduct.productId) || null}
                                                    onChange={option => {
                                                        if (checkIsCompanyElgisa()) {
                                                            if (option) {
                                                                setQtyProduct('1')
                                                            }
                                                        }
                                                        setDataProduct(
                                                            { productId: option?.value || "", productName: option?.label || "", productSku: option?.sku || "", productNote: option?.note || "", productUnits: option?.productUnits || [] }
                                                        )
                                                    }}
                                                    onBlur={
                                                        () => dataProduct ? setIsDataProduct(false) : setIsDataProduct(true)
                                                    }
                                                    className="w-100"
                                                    isDisabled={productLoading}
                                                    isLoading={isLoadingSearchProduct}
                                                    inputValue={valSearchProduct}
                                                    onInputChange={(e) => { handleSearchProduct(e); setIsLoadingSearchProduct(true); setValSearchProduct(e) }}
                                                    components={{ MenuList }}
                                                /> */}
                                                <VirtualizedSelect
                                                    title={intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    options={productData}
                                                    // options={dummySingleOptions}
                                                    // options={dummyGroupOptions}
                                                    isLoading={productLoading}
                                                    isClearable={false}
                                                    className="w-100"
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}`}
                                                    value={productData.find(option => option.value === dataProduct.productId) || null}
                                                    onChange={option => {
                                                        if (checkIsCompanyElgisa()) {
                                                            if (option) {
                                                                setQtyProduct('1')
                                                            }
                                                        } else if (checkIsCompanyEsb()) {
                                                            setQtyProduct('1')
                                                            setHppProduct('0')
                                                        }
                                                        setDataProduct(
                                                            {
                                                                productId: option?.value || "",
                                                                productName: option?.label || "",
                                                                productSku: option?.sku || "",
                                                                productNote: option?.note || "",
                                                                productUnits: option?.productUnits || [],
                                                                productPrice: 0,
                                                                productVatPercentage: 0
                                                            }
                                                        )
                                                        if (option?.price) {
                                                            setPriceProduct(customNumberFormat(option?.price))
                                                        } else {
                                                            setPriceProduct("")
                                                        }
                                                        if (option?.vatPercentage) {
                                                            setVatProduct(option?.vatPercentage.toString())
                                                        } else if (option?.vatPercentage === 0) {
                                                            setVatProduct("0")
                                                        } else {
                                                            setVatProduct("")
                                                        }
                                                    }}
                                                    onBlur={
                                                        () => dataProduct ? setIsDataProduct(false) : setIsDataProduct(true)
                                                    }
                                                    components={{ MenuList }}
                                                />
                                                {/* <AsyncSelect
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}`}
                                                    value={productData.find(option => option.value === dataProduct.productId) || null}
                                                    onChange={option => {
                                                        if (checkIsCompanyElgisa() || checkIsCompanyEsb()) {
                                                            if (option) {
                                                                setQtyProduct('1')
                                                                setHppProduct('0')
                                                            }
                                                        }
                                                        setDataProduct(
                                                            {
                                                                productId: option?.value || "",
                                                                productName: option?.label || "",
                                                                productSku: option?.sku || "",
                                                                productPrice: option?.price || 0,
                                                                productVatPercentage: option?.price || 0,
                                                                productNote: option?.note || "",
                                                                productUnits: option?.productUnits || []
                                                            }
                                                        )
                                                        if (option?.price) {
                                                            setPriceProduct(customNumberFormat(option?.price))
                                                        }
                                                        if (option?.vatPercentage) {
                                                            setVatProduct(option?.vatPercentage.toString())
                                                        }
                                                    }}
                                                    onBlur={
                                                        () => dataProduct ? setIsDataProduct(false) : setIsDataProduct(true)
                                                    }
                                                    className="w-100"
                                                    // cacheOptions
                                                    loadOptions={handleSearchProduct}
                                                    defaultOptions={productData}
                                                    components={{ MenuList }}
                                                /> */}
                                            </div>
                                            {isDataProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Units */}
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.UNITS" })}</label>
                                            <div className="input-group mb-3">
                                                {dataProduct.productUnits.length > 0 ?
                                                    <>
                                                        <Select
                                                            key={
                                                                window.location.pathname === '/chat' ? nanoid() : ''
                                                            }
                                                            options={unitData}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.UNITS" })}`}
                                                            value={unitData.find(option => option.value === unitProduct.value) || null}
                                                            onChange={option => setUnitProduct({ value: option?.value || "", label: option?.label || "", alias: option?.alias || "" })}
                                                            onBlur={() => {
                                                                unitProduct.value ? setIsUnitProduct(false) : setIsUnitProduct(true);
                                                            }}
                                                            className="w-100"
                                                        // isDisabled={productLoading}
                                                        />
                                                        {isUnitProduct && (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.UNITS" }) })}</span>
                                                            </div>
                                                        )}
                                                    </>
                                                    : <span className="mt-3">PCS</span>}
                                            </div>
                                        </div>
                                        {
                                            user.data.is_allowed_access_hpp &&
                                            <div className="col-12 col-lg-1 col-xl-1 pe-0">
                                                {/* Hpp Product */}
                                                <label className='form-label fs-6 fw-bolder text-dark' style={{ float: "right" }}>HPP</label>
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        disabled={checkIsCompanyEsb()}
                                                        value={hppProduct}
                                                        onChange={handleHppChange}
                                                        // onBlur={() => {
                                                        //     qtyProduct ? setIsQtyProduct(false) : setIsQtyProduct(true);
                                                        //     totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                        // }}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            // { 'is-invalid': isQtyProduct },
                                                        )}
                                                        type='text'
                                                        autoComplete='off'
                                                        style={{ zIndex: 0, textAlign: "right" }}

                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 col-lg-1 col-xl-1 pe-0">
                                            {/* Qty Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    disabled={checkIsCompanyElgisa() || checkIsCompanyEsb()}
                                                    value={qtyProduct}
                                                    onChange={handleQtyChange}
                                                    onBlur={() => {
                                                        qtyProduct ? setIsQtyProduct(false) : setIsQtyProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isQtyProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}

                                                />
                                            </div>
                                            {isQtyProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.QUANTITY" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Price Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={priceProduct}
                                                    onChange={handlePriceChange}
                                                    onBlur={() => {
                                                        priceProduct ? setIsPriceProduct(false) : setIsPriceProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isPriceProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {isPriceProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-1 col-xl-1 pe-0">
                                            {/* VAT Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark ' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.VAT" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={vatProduct}
                                                    onChange={handleVatChange}
                                                    onBlur={() => {
                                                        vatProduct ? setIsVatProduct(false) : setIsVatProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        // { 'is-invalid': isVatProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {/* {isVatProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.VAT" }) })}</span>
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="col-12 col-lg-3 col-xl-3 pe-0">
                                            {/* Total */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={totalProduct}
                                                    onChange={handleNominalChange}
                                                    onBlur={() => {
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true);
                                                        priceProduct ? setIsPriceProduct(false) : setIsPriceProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isTotalProduct },
                                                    )}
                                                    type='text'
                                                    name='nominal'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {isTotalProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        {/* Btn Simpan Product Form */}
                                        <div className="col-12 col-lg-3 offset-lg-9 col-xl-3 offset-xl-9 my-2 ">
                                            {/* <label className='form-label fs-6 fw-bolder text-white'>t</label> */}
                                            <button type="button" className="btn btn-light-primary w-100" onClick={handleAddProduct}>
                                                {/* <i className="fas fa-plus"></i> */}
                                                {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
                                            </button>
                                        </div>
                                    </div>
                                )
                                }
                                <div className="table-responsive">
                                    <table className="table table-striped gs-7">
                                        <thead>
                                            <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                                <th>{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</th>
                                                <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.UNITS" })}</th>
                                                {
                                                    user.data.is_allowed_access_hpp &&
                                                    <th className="text-end">HPP</th>
                                                }
                                                <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}</th>
                                                <th className="text-end">{checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</th>
                                                <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.VAT" })}</th>
                                                <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</th>
                                                <th className="text-center"></th>
                                            </tr>
                                        </thead>
                                        {
                                            listProducts.length > 0 ?
                                                <tbody>
                                                    {
                                                        listProducts.map((product, index) => (
                                                            <tr key={index} style={{ verticalAlign: "middle" }}>
                                                                <td style={{ minWidth: "200px" }}>
                                                                    {product.productSku &&
                                                                        product.productName.indexOf(" (SKU:") !== -1 ?
                                                                        product.productName.substring(0, product.productName.indexOf(" (SKU:")) :
                                                                        product.productName
                                                                    }
                                                                    {product.productSku &&
                                                                        <>
                                                                            <br />
                                                                            <small className="text-muted fst-italic">SKU: {product.productSku}</small>
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                    {product.unitName ? product.unitName : "PCS"}
                                                                </td>
                                                                {
                                                                    user.data.is_allowed_access_hpp &&
                                                                    <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                        {
                                                                            product.edit ?
                                                                                <input type="text" disabled={checkIsCompanyEsb()} className='form-control form-control-sm float-end' value={product.leadProductHpp || product.leadProductHpp == 0 ? customNumberFormat(product.leadProductHpp) : ""} onChange={(e) => handleInputProduct("hpp", index, e)} style={{ textAlign: "right" }} />
                                                                                :
                                                                                (product.leadProductHpp || product.leadProductHpp == 0) ? customNumberFormat(product.leadProductHpp) : ""
                                                                        }
                                                                    </td>
                                                                }
                                                                <td className="text-end ps-0" style={{ minWidth: "70px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <input disabled={checkIsCompanyElgisa() || checkIsCompanyEsb()} type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductQty)} onChange={(e) => handleInputProduct("qty", index, e)} style={{ textAlign: "right" }} />
                                                                            :
                                                                            customNumberFormat(product.leadProductQty)
                                                                    }
                                                                </td>
                                                                <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <input type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductPrice)} onChange={(e) => handleInputProduct("price", index, e)} style={{ textAlign: "right" }} />
                                                                            :
                                                                            customNumberFormat(product.leadProductPrice)
                                                                    }
                                                                </td>
                                                                <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <input type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductVatPercentage)} onChange={(e) => handleInputProduct("vatPercentage", index, e)} style={{ textAlign: "right" }} />
                                                                            :
                                                                            customNumberFormat(product.leadProductVatPercentage)
                                                                    }
                                                                </td>
                                                                <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <input type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductTotal)} onChange={(e) => handleInputProduct("total", index, e)} style={{ textAlign: "right" }} />
                                                                            :
                                                                            customNumberFormat(product.leadProductTotal)
                                                                    }
                                                                </td>
                                                                <td className="text-center" style={{ minWidth: "70px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <i className="fas fa-check text-primary cursor-pointer me-3" onClick={() => handleSaveUpdateProduct(index)}></i>
                                                                            :
                                                                            <>
                                                                                <i className="fas fa-edit text-primary cursor-pointer me-3" onClick={() => handleUpdateProduct(index)}></i>
                                                                                <i className="fas fa-trash text-danger cursor-pointer" onClick={() => handleDeleteProduct(index)}></i>
                                                                            </>

                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={7} className="text-center">
                                                            {intl.formatMessage({ id: "FORM.VALIDATION.NO_CHOSEN" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }).toLowerCase() })}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                        }
                                    </table>
                                </div>
                                <div className="separator separator-dashed my-10"></div>
                                {/* Note */}
                                {
                                    (user.data.company_id === companyId.elgisa || user.data.company_id === companyId.looyalIT) ?
                                        <label className='form-label fs-6 fw-bolder text-dark required'>Opportunity</label>
                                        :
                                        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.NOTE" })}</label>
                                }
                                <div className="input-group mb-3">
                                    <textarea
                                        id="floatingTextarea"
                                        {...formik.getFieldProps('note')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.note && formik.errors.note },
                                        )}
                                        style={{ zIndex: 0 }}
                                        name="note"
                                        onInput={(e) => handleCaps(e)}
                                    >
                                    </textarea>
                                </div>
                                {formik.touched.note && formik.errors.note && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.note}</span>
                                    </div>
                                )}

                                {
                                    customFields && Object.keys(customFields).map((key: string, index: number) => {
                                        if (customFields[key].type === 'text') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            onInput={(e) => handleCaps(e)}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'serial') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                    {
                                                        dataCustomFieldSerial[key].loading ?
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                                <l-tailspin
                                                                    size="15"
                                                                    stroke="3"
                                                                    speed="0.9"
                                                                    color="#ffffff"
                                                                ></l-tailspin>
                                                            </span>
                                                            :
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                    }
                                                    <div className="d-flex mb-3 gap-3">
                                                        <input
                                                            disabled
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                            className={clsx('form-control form-control-lg')}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'link') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg txt-link',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'phone') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <InputPhone keyString={key} formik={formik} type="input" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'image') {
                                            return (
                                                <>
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PNG, JPG, JPEG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'images') {
                                            return (
                                                <>
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PNG, JPG, JPEG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'file') {
                                            return (
                                                <>
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'files') {
                                            return (
                                                <>
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'date') {
                                            var min: number | null = null
                                            var max: number | null = null

                                            if (customFields[key].min === undefined || customFields[key].min === null) min = null //Tidak ada batasan
                                            else if (customFields[key].min) min = Number(customFields[key].min)
                                            else min = 0 //Batasan hari ini

                                            if (customFields[key].max === undefined || customFields[key].max === null) max = null //Tidak ada batasan
                                            else if (customFields[key].max) max = Number(customFields[key].max)
                                            else max = 0 //Batasan hari ini

                                            return (
                                                <>
                                                    {/* Date */}
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <DatePicker
                                                            disabled={isCustomeFieldDateDisabled}
                                                            ranges={todayRange}
                                                            oneTap
                                                            editable={false}
                                                            format="dd MMM yyyy"
                                                            value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]) : null}
                                                            defaultValue={null}
                                                            style={{ width: "100%", zIndex: 0 }}
                                                            container={document.querySelector('#add-pipeline-modal') as HTMLElement}
                                                            onChange={(value: any) => {
                                                                if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                                else formik.setFieldValue(key, null)
                                                            }}
                                                            shouldDisableDate={date => {
                                                                var startDate = min === null ? null : min > 0 ? addDays(new Date(), min) : subDays(new Date(), min * -1);
                                                                var endDate = max === null ? null : max > 0 ? addDays(new Date(), max) : subDays(new Date(), max * -1);

                                                                date.setHours(0, 0, 0, 0);
                                                                if (startDate !== null && endDate !== null) {
                                                                    startDate.setHours(0, 0, 0, 0);
                                                                    endDate.setHours(0, 0, 0, 0);
                                                                    return date > endDate || date < startDate
                                                                } else if (startDate !== null && endDate === null) {
                                                                    startDate.setHours(0, 0, 0, 0);
                                                                    return date < startDate
                                                                } else if (startDate === null && endDate !== null) {
                                                                    endDate.setHours(0, 0, 0, 0);
                                                                    return date > endDate
                                                                } else return false
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'option') {

                                            const options = customFields[key].value.map((option, index) => ({
                                                value: option,
                                                label: option
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            key={
                                                                window.location.pathname === '/chat' ? nanoid() : ''
                                                            }
                                                            isClearable
                                                            options={options}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                            value={options.find(option => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: key,
                                                                        value: option?.value || ""
                                                                    }
                                                                })
                                                            }
                                                            onBlur={
                                                                () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                            }
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'merchant') {
                                            var filteredMerchants = simpleMerchants
                                            if ((customFields[key].value as string[]).length > 0) {
                                                filteredMerchants = simpleMerchants.filter((merchant: Merchant) => (customFields[key].value as string[]).includes(merchant.merchantTemplateId))
                                            }

                                            const options = filteredMerchants.map((option: Merchant) => ({
                                                value: option.id,
                                                label: option.name
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <VirtualizedSelect
                                                            // title="Custom Field Merchant"
                                                            key={
                                                                window.location.pathname === '/chat' ? nanoid() : ''
                                                            }
                                                            options={options}
                                                            // options={dummySingleOptions}
                                                            // options={dummyGroupOptions}
                                                            isLoading={simpleMerchantLoading}
                                                            isDisabled={simpleMerchantLoading}
                                                            className="w-100"
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} Merchant`}
                                                            value={
                                                                options.filter(option =>
                                                                    formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                                )
                                                            }
                                                            onChange={(selectedOptions) =>
                                                                formik.setFieldValue(
                                                                    key,
                                                                    selectedOptions ? selectedOptions.map((option: Option) => option.value) : []
                                                                )
                                                            }
                                                            onBlur={() => {
                                                                if (formik.values[key as keyof typeof formik.values] === "") {
                                                                    formik.setFieldTouched(key, true);
                                                                }
                                                            }}
                                                            components={{ MenuList }}
                                                            isMulti
                                                        />
                                                        {/* <Select
                                                            isMulti
                                                            isClearable
                                                            options={options}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                            value={
                                                                options.filter(option =>
                                                                    formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                                )
                                                            }
                                                            onChange={(selectedOptions) =>
                                                                formik.setFieldValue(
                                                                    key,
                                                                    selectedOptions ? selectedOptions.map((option) => option.value) : []
                                                                )
                                                            }
                                                            onBlur={() => {
                                                                if (formik.values[key as keyof typeof formik.values] === "") {
                                                                    formik.setFieldTouched(key, true);
                                                                }
                                                            }}
                                                            className="w-100"
                                                            isLoading={simpleMerchantLoading}
                                                            isDisabled={simpleMerchantLoading}
                                                        /> */}
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'user') {
                                            const options = users.map((option: User) => ({
                                                value: option.id,
                                                label: option.name
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            isMulti
                                                            isClearable
                                                            options={options}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                            value={
                                                                options.filter(option =>
                                                                    formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                                )
                                                            }
                                                            onChange={(selectedOptions) =>
                                                                formik.setFieldValue(
                                                                    key,
                                                                    selectedOptions ? selectedOptions.map((option) => option.value) : []
                                                                )
                                                            }
                                                            onBlur={() => {
                                                                if (formik.values[key as keyof typeof formik.values] === "") {
                                                                    formik.setFieldTouched(key, true);
                                                                }
                                                            }}
                                                            className="w-100"
                                                            isLoading={userLoading}
                                                            isDisabled={userLoading}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'pipeline') {

                                            const options = pipelines.map((option: any) => ({
                                                ...option,
                                                value: option.id,
                                                label: option.name
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            isClearable
                                                            options={options}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                            value={options.find((option: SelectOptionProduct) => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: key,
                                                                        value: option?.value || ""
                                                                    }
                                                                })
                                                            }
                                                            onBlur={
                                                                () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                            }
                                                            className="w-100"
                                                            components={{
                                                                Option: CustomOption,
                                                                SingleValue: CustomSingleValue,
                                                            }}
                                                            isLoading={pipelineLoading}
                                                            isDisabled={pipelineLoading}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'multiple') {
                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <div className="form-check form-check-custom form-check-solid" style={{ display: "contents" }}>
                                                            {
                                                                customFields[key].value.map((option, index) => (
                                                                    <div style={{ display: "flex", padding: "3px", overflowWrap: "anywhere" }} key={index}>
                                                                        <input
                                                                            key={index}
                                                                            {...formik.getFieldProps(key)}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={option as string}
                                                                            name={key}
                                                                        />
                                                                        <label className="form-check-label me-2">{option as string}</label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'invoice_reminder') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name + " (File)"}</span><br />
                                                    <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                    <InputAttachment keyString={`${key}_invoice_url`} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />

                                                    {/* Date Invoice */}
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name + " (Tgl Expired)"}</label>
                                                    <div className="input-group mb-3">
                                                        <DatePicker
                                                            disabled={isCustomeFieldDateDisabled}
                                                            ranges={todayRange}
                                                            oneTap
                                                            editable={false}
                                                            format="dd MMM yyyy"
                                                            value={formik.values[key + "_date_due" as keyof typeof formik.values] ? new Date(formik.values[key + "_date_due" as keyof typeof formik.values]) : null}
                                                            defaultValue={null}
                                                            style={{ width: "100%", zIndex: 0 }}
                                                            container={document.querySelector('#add-pipeline-modal') as HTMLElement}
                                                            onChange={(value: any) => {
                                                                if (value) formik.setFieldValue(`${key}_date_due`, moment(value).format('YYYY-MM-DD'))
                                                                else formik.setFieldValue(`${key}_date_due`, null)
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }

                                        return null
                                    })
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                showAddMerchantModal && <AddMerchant addMerchant={addMerchant} />
            }
        </div>
    )
}

export { AddPipeline }
