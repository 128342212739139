import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../_metronic/helpers'
import clsx from 'clsx'
import { isValidUrl } from '../../functions/general'
import ReactQuill from 'react-quill'

interface ModalInsertUrlProps {
    valueInsertUrl: string
    setValueInsertUrl: (value: string) => void
    editorRef: React.RefObject<ReactQuill>
    onClose?: () => void
}

const ModalInsertUrl = ({ valueInsertUrl, setValueInsertUrl, editorRef, onClose }: ModalInsertUrlProps) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const [invalidUrl, setInvalidUrl] = useState(false)

    const [savedSelection, setSavedSelection] = useState<{ index: number, length: number } | null>(null)

    const handleCloseModal = () => {
        setValueInsertUrl('')
        setInvalidUrl(false)
        modalRef.current?.click()
        onClose?.()
    }

    useEffect(() => {
        if (editorRef.current) {
            const editor = editorRef.current.getEditor()
            const range = editor.getSelection()
            if (range) {
                setSavedSelection(range)
            }
        }
    }, [])

    const handleSubmitInsertUrl = () => {
        if (valueInsertUrl.trim() === '' || !editorRef.current) {
            return
        }
        if (!isValidUrl(valueInsertUrl)) {
            setInvalidUrl(true)
            return
        }

        const editor = editorRef.current.getEditor()
        const insertPosition = savedSelection?.index ?? editor.getLength()
        editor.insertText(insertPosition, `{{{${valueInsertUrl}}}}`, 'user')
        editor.focus()

        handleCloseModal()
    }

    return (
        <div className="modal fade" tabIndex={-1} id={`insert-url-modal`} ref={modalRef} >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Insert Url</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <label className='form-label fs-6 fw-bolder text-dark required'>Masukan Url</label>
                        <div className="input-group mb-3">
                            <input
                                type="text"
                                className={clsx('form-control form-control-lg', { 'is-invalid': invalidUrl })}
                                value={valueInsertUrl}
                                onChange={(e) => setValueInsertUrl(e.target.value)}
                            />
                        </div>
                        {invalidUrl && (
                            <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">Invalid Url</span>
                            </div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" onClick={handleCloseModal}>Close</button>
                        <button type="button" onClick={handleSubmitInsertUrl} className="btn btn-primary">Insert Url</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalInsertUrl