import { ParsedContent } from "../../interfaces/Email/Content";
import DOMPurify from 'dompurify';
import { Typography } from "@mui/material";

export const renderTableContent = ({ subject, message }: ParsedContent) => {
    return <div className="email-content">
        <Typography variant="subtitle1" fontWeight="bold" className="email-subject" gutterBottom>
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subject) }} />
        </Typography>
        <Typography variant="body2" className="email-message">
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message?.length > 300 ? `${message.substring(0, 300)}...` : message) }} />
        </Typography>
    </div>
}

export const renderTooltipContent = ({ subject, message }: ParsedContent) => {
    return (
        <div className="email-content-tooltip">
            <Typography variant="subtitle1" fontWeight="bold" className="email-subject-tooltip" gutterBottom>
                <span className='text-white' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subject) }} />
            </Typography>
            <Typography variant="body2" className="email-message-tooltip">
                <span className='text-white' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
            </Typography>
        </div>
    )
}