import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import { insertMerchant, updatePhoneMerchant } from "../../api/MerchantsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select";
import useSWR from "swr";
import axios from "axios";
import { City } from "../../interfaces/City";
import { nanoid } from "@reduxjs/toolkit";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField } from "../../interfaces/Settings";
import moment from "moment";
import { DatePicker } from "rsuite";
import { ChatWindowData } from "../../interfaces/Chat/ChatWindowData";
import { Merchant } from "../../interfaces/Merchant";
import useUser from "../../hooks/User";
import { User } from "../../interfaces/User";
import { SelectOption } from "../../interfaces/SelectOption";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { checkWhatsapp } from "../../api/Whatsapp";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface UpdatePhoneMerchantProps {
    merchant: Merchant
}

const UpdatePhoneMerchant: FC<UpdatePhoneMerchantProps> = ({ merchant }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const [checkWa, setCheckWa] = useState("")
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const [prevCountryCode, setPrevCountryCode] = useState("62");
    const intl = useIntl()

    const validationSchema = Yup.object().shape({
        phone: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PHONE" }) })),
    });

    const initialValues = {
        phone: merchant.phone,
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                await checkWaValid(values.phone).then(async (valCheckWa) => {
                    if (values.phone.startsWith('0')) {
                        values.phone = '62' + values.phone.substring(1);
                    }

                    var isWhatsappPhone = valCheckWa === "true" ? true : false;

                    const response = await updatePhoneMerchant(
                        merchant.id,
                        values.phone.replace("+", ""),
                        isWhatsappPhone,
                        token,
                    );

                    if (response.status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                            heightAuto: false,
                        });
                        closeModalRef.current?.click();
                        setTablesData('merchants', (tablesData.merchants.map(item => {
                            if (item.id === merchant.id) {
                                return {
                                    ...item,
                                    isWhatsappPhone: isWhatsappPhone,
                                    phone: values.phone.replace("+", "")
                                }
                            } else {
                                return item
                            }
                        })));
                    }
                });
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            formik.setFieldValue('phone', "+62")
            setCheckWa("")
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const checkWaValid = async (phone: string) => {
        setCheckWa("loading")
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            setCheckWa(response.data.result.exists ? "true" : "false")
            return response.data.result.exists ? "true" : "false";
        } catch (error) {

        }
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`update-phone-merchant-modal-${merchant.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })} {intl.formatMessage({ id: "FORM.LABEL.PHONE" })} {merchant.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.PHONE" })}</label>
                                {
                                    checkWa === "true" ?
                                        <span className="text-success" style={{ float: "inline-end" }}>{intl.formatMessage({ id: "FORM.VALIDATION.VALID_WHATSAPP" })}</span>
                                        : checkWa === "false" ?
                                            <span className="text-danger" style={{ float: "inline-end" }}>{intl.formatMessage({ id: "FORM.VALIDATION.INVALID_WHATSAPP" })}</span>
                                            : checkWa === "loading" ?
                                                <span className="spinner-border spinner-border-sm align-middle" style={{ float: "inline-end" }}></span>
                                                : <></>
                                }
                                <div style={{ marginBottom: "0.75rem" }}>
                                    <PhoneInput
                                        countryCodeEditable={false}
                                        country="id"
                                        value={formik.values.phone}
                                        onChange={(value, country: any) => {
                                            if (country.dialCode !== prevCountryCode) {
                                                setPrevCountryCode(country.dialCode);
                                                setCheckWa("")
                                                formik.setFieldValue('phone', country.dialCode);
                                            } else {
                                                formik.setFieldValue('phone', value);
                                            }
                                        }}
                                        onBlur={() => checkWaValid(formik.values.phone)}
                                        enableSearch={true}
                                        inputStyle={{ width: "100%" }}
                                        containerClass="z-index-3"
                                    />
                                </div>
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.phone}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { UpdatePhoneMerchant }