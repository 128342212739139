import React from 'react'
import Select from 'react-select'

interface AddVariableDropdownProps {
    VariableColumns: string[]
    onVariableClick: (variable: string) => void
}

const AddVariableDropdown = ({ VariableColumns, onVariableClick }: AddVariableDropdownProps) => {
    const options = VariableColumns.map(variable => ({
        value: variable,
        label: variable
    }))

    const customStyles = {
        control: (base: any) => ({
            ...base,
            minHeight: '35px',
            backgroundColor: 'transparent',
            // border: 'none',
            boxShadow: 'none',
            marginBottom: '10px',
        }),
        menu: (base: any) => ({
            ...base,
            width: '150px',
            zIndex: 9999
        }),
        option: (base: any, state: any) => ({
            ...base,
            backgroundColor: state.isFocused ? '#f1faff' : 'white',
            color: '#3f4254',
            '&:hover': {
                backgroundColor: '#f1faff'
            }
        })
    }

    return (
        <Select
            options={options}
            onChange={(option) => option && onVariableClick(option.value)}
            placeholder="Pilih Variable"
            className="variable-select"
            styles={customStyles}
            menuPlacement="auto"
            maxMenuHeight={200}
        />
    )
}

export default AddVariableDropdown