import { ExpandedState, flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import React, { useState } from 'react'
import { getPinningStyles, renderWithFlexRender, renderWithouFlexRender } from '../../functions/general';

interface DataTableAccountReceivableProps {
    data: any
    tableColumns: any
}

const DataTableAccountReceivable = ({ data, tableColumns }: DataTableAccountReceivableProps) => {

    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [expandedRows, setExpandedRows] = useState<ExpandedState>({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const table = useReactTable({
        data: data || [],
        columns: tableColumns,
        state: {
            sorting,
            globalFilter,
            pagination,
            expanded: expandedRows,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onExpandedChange: setExpandedRows,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <div className="table-responsive">
                <table className="table table-row-bordered align-middle">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="fw-bolder">
                                {headerGroup.headers.map((header) => {
                                    const columnRelativeDepth = header.depth - header.column.depth;
                                    if (columnRelativeDepth > 1) {
                                        return null;
                                    }

                                    let rowSpan = 1;
                                    if (header.isPlaceholder) {
                                        const leafs = header.getLeafHeaders();
                                        rowSpan = leafs[leafs.length - 1].depth - header.depth;
                                    }

                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            rowSpan={rowSpan}
                                            style={{ padding: "1rem", ...(header.column.columnDef.meta as any)?.styleHeader }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row, rowIndex) => {
                            let rowSpan = 1;

                            return (
                                <tr key={row.id} className="border-b border-gray-300">
                                    {row.getVisibleCells().map((cell) => {
                                        const isDescColumn = cell.column.id === 'desc';

                                        if (isDescColumn && rowSpan === 0) return null;

                                        return (
                                            <td
                                                className='text-center'
                                                key={cell.id}
                                                rowSpan={isDescColumn ? rowSpan : 1}
                                                style={{ padding: "1rem", ...(cell.column.columnDef.meta as any)?.styleBody }}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-end align-items-center flex-wrap pt-3 pb-10">
                <div className="d-flex align-items-center gap-3">
                    <div className="d-flex align-items-center gap-5">
                        <select
                            className="form-select form-select-sm font-weight-bold mr-4 border-0 bg-light"
                            style={{ width: "75px" }}
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                        <div className="fs-7 text-gray-700">
                            {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{" "}
                            {Math.min(
                                (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                                table.getFilteredRowModel().rows.length
                            )}{" "}
                            of {table.getFilteredRowModel().rows.length}
                        </div>
                    </div>
                    <nav>
                        <ul className="pagination mb-0">
                            <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                <button
                                    className="page-link"
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <i className="fas fa-angle-double-left"></i>
                                </button>
                            </li>
                            <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                <button
                                    className="page-link"
                                    onClick={() => table.previousPage()}
                                    disabled={!table.getCanPreviousPage()}
                                >
                                    <i className="fas fa-angle-left"></i>
                                </button>
                            </li>
                            <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                <button
                                    className="page-link"
                                    onClick={() => table.nextPage()}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <i className="fas fa-angle-right"></i>
                                </button>
                            </li>
                            <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                <button
                                    className="page-link"
                                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                    disabled={!table.getCanNextPage()}
                                >
                                    <i className="fas fa-angle-double-right"></i>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default DataTableAccountReceivable