import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { insertBannerAttachment } from "../../api/ResourceAPI";
import { insertBanner } from "../../api/BannerCRUD";
import { Banner } from "../../interfaces/Banner";
import { CropperBanner } from "./CropperBanner";
import { Modal } from "bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface AddBannerProps {
    dataBanner: Banner[]
    setDataBanner: Function
}

const AddBanner: FC<AddBannerProps> = ({ dataBanner, setDataBanner }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const [modalCropper, setModalCropper] = useState(false);
    const [attachment, setAttachment] = useState<string>("");
    const token = useAccessToken()

    const intl = useIntl()

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike"],
            // ["bold", "italic", "underline", "strike", "blockquote"],
            // [{ list: "ordered" }, { list: "bullet" }],
            // ["link", "image"],
            // ["clean"],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        // "blockquote",
        // "list",
        // "bullet",
        // "link",
        // "image",
    ];

    const initialValues = {
        name: '',
        banner: '',
        description: '',
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(`${intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })}`),
        description: Yup.string().required(`${intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DESCRIPTION" }) })}`),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)
            try {
                const resourceResponse = await insertBannerAttachment(values.banner)
                const urlBanner = resourceResponse.data.data[0]


                const response = await insertBanner(
                    values.name,
                    urlBanner,
                    values.description,
                    token
                )
                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: `${intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" })}!`,
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                        heightAuto: false,
                    })
                    setDataBanner([...dataBanner, response.data.data])
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    const handleChange = (e: any, key: string) => {
        if (e.target.files && e.target.files[0]) {
            e.preventDefault();
            let files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            } else if (e.target) {
                files = e.target.files;
            }
            const reader = new FileReader();
            reader.onload = () => {
                setAttachment(reader.result as any);
            };
            reader.readAsDataURL(files[0]);

            setModalCropper(true)
        } else {
            setAttachment("");
            setModalCropper(false)
        }
    }

    useEffect(() => {
        // convert the banner into file and insert it to formik
        if (attachment) {
            const blob = fetch(attachment).then(r => r.blob())
            blob.then((blob) => {
                const file = new File([blob], "banner", { type: "image/png" })
                formik.handleChange({
                    target: {
                        name: 'banner',
                        value: file
                    }
                })
            })
        }
    }, [attachment])

    useEffect(() => {
        if (modalCropper) {
            const modalElement = document.getElementById(`cropper-banner-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setModalCropper(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [modalCropper, setModalCropper]);

    const deleteAttachment = () => {
        formik.setFieldValue("banner", '');
        fileInputRef.current!.value = ""
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id="tambah-banner-modal" ref={modalRef}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.BANNER" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {/* Name */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: "FORM.LABEL.NAME" })}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                                {/* Banner */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.BANNER" })}</label>
                                <div className="d-flex mb-3">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ zIndex: 0 }}
                                        ref={fileInputRef}
                                        onChange={(e) => handleChange(e, 'banner')}
                                        onBlur={() => formik.setFieldTouched("banner", true)}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.banner && formik.errors.banner },
                                        )}
                                    />

                                    <button type="button" className="btn btn-light-danger ms-3" onClick={() => { deleteAttachment() }}>
                                        {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                    </button>
                                </div>
                                {formik.touched.banner && formik.errors.banner && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.banner}</span>
                                    </div>
                                )}
                                {/* Description */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DESCRIPTION" })}</label>
                                <div className="input-group mb-3" style={{ height: "200px" }}>
                                    <ReactQuill
                                        style={{ height: "85%", width: "100%" }}
                                        theme="snow"
                                        value={formik.values.description}
                                        onChange={(value) => formik.setFieldValue('description', value)}
                                        modules={modules}
                                        formats={formats}
                                    />
                                </div>
                                {formik.touched.description && formik.errors.description && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.description}</span>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {
                modalCropper && <CropperBanner originalImage={attachment} setAttachment={setAttachment} deleteAttachment={deleteAttachment} />
            }
        </div>
    )
}

export { AddBanner }