import React, { useState } from 'react'
import { useDataTableStore } from '../../../../stores/DataTableStore';
import TabDraftBroadcastEmail from './TabDraftBroadcastEmail';
import TabBroadcastEmail from './TabBroadcastEmail';

const BroadcastEmail = () => {
  const [displayMode, setDisplayMode] = useState<'broadcastEmail' | 'draftBroadcastEmail'>('broadcastEmail');
  const { tablesData } = useDataTableStore();


  return (
    <>
      <div className='card'>
        <div className="card-header border-0">
          <div className="card-title pt-3 mb-0 gap-4 gap-lg-10 gap-xl-10 nav nav-tabs border-bottom-0">
            <div className={`fs-4 fw-bolder pb-3 cursor-pointer ${displayMode === 'broadcastEmail' ? 'border-bottom border-3 border-primary' : 'text-muted'}`} onClick={() => setDisplayMode('broadcastEmail')}>Broadcast ({tablesData['broadcastEmail'] ? tablesData['broadcastEmail'].length : 0})</div>
            <div className={`fs-4 fw-bolder pb-3 cursor-pointer ${displayMode === 'draftBroadcastEmail' ? 'border-bottom border-3 border-primary' : 'text-muted'}`} onClick={() => setDisplayMode('draftBroadcastEmail')}>Draft ({tablesData['draftBroadcastEmail'] ? tablesData['draftBroadcastEmail'].length : 0})</div>
          </div>
        </div>

        <div className={`${displayMode === 'broadcastEmail' ? 'd-block' : 'd-none'}`}>
          <TabBroadcastEmail />
        </div>

        <div className={`${displayMode === 'draftBroadcastEmail' ? 'd-block' : 'd-none'}`}>
          <TabDraftBroadcastEmail />
        </div>
      </div>
    </>
  )
}

export default BroadcastEmail