import { FC, useCallback, useEffect, useRef, useState } from "react";
import { FormikProps, replace, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import clsx from "clsx";
import Swal from "sweetalert2";
import useAccessToken from "../../../hooks/AccessToken";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { Progress } from "../../../interfaces/Progress";
import { CustomFieldType, insertLog } from "../../../api/LogsCRUD";
import { nanoid } from "@reduxjs/toolkit";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { HppLeadProducts, ListProduct, Pipeline, PipelineAttachment } from "../../../interfaces/Pipeline";
import { RecentChatHistoryPipeline } from "../../../interfaces/Chat/RecentChatHistory";
import { insertLeadFieldAttachment, insertLeadFieldMultiAttachment, insertMerchantFieldMultitAttachment, insertPaymentTerminAttachment, insertPaymentTerminAttachmentCustomField, insertPaymentTerminMultiAttachmentCustomField } from "../../../api/ResourceAPI";
import { Modal } from "bootstrap";
import { useChatStore } from "../../../stores/ChatStore";
import { KTSVG } from "../../../_metronic/helpers";
import { getDateRangeDuration, handleCaps, customNumberFormat, customNumberFormatString, isBeforeToday, toRupiah, removeDotNominal, isAfterToday, isValidUrl, getDataById } from "../../../functions/general";
import useUser from "../../../hooks/User";
import moment from "moment";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import InputAttachment from "../../Input/InputAttachment";
import { useCustomeStore } from "../../../stores/CustomeStore";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import ImageModal from "./ImageModal";
import useHandleCloseModal from "../../../hooks/HandleCloseModal";
import { showSerial } from "../../../functions/customCase";
import { DatePicker } from "rsuite";
import useTodayRange from "../../../hooks/TodayRange";
import { createPaymentTermin, ICreatePaymentTermin } from "../../../api/PipelineCRUD";
import { CustomField } from "../../../interfaces/Settings";
import PaymentTerminDisplay from "../../DataTableTermin/PaymentTerminDisplay";
import RenderTerminCustomFieldLog from "../RenderCustomFieldTermin/RenderTerminCustomFieldLog";
import { usePipelineStore } from "../../../stores/PipelineStore";
import RenderCustomFieldSinertek from "./RenderCustomFieldSinertek";
import { SinertekProgressType, progressToCustomFieldMap } from '../../../utils/customFieldSinertek'
import { Switch } from "@mui/material";
import InputAttachmentTermin from "../DetailTermin/InputAttachmentTermin";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { fetchWithToken } from "../../../api/General";
import { ALWAYS_REQUIRED_FIELDS, ALWAYS_REQUIRED_FIELDS_PROOF_OF_PAYMENT } from "../../../constant/General";
import RenderCustomFieldLog from "./RenderCustomFieldLog";
import { useMerchantStore } from "../../../stores/MerchantStore";
import { useUserStore } from "../../../stores/UserStore";
import { useDNDPipelineStore } from "../../../stores/DNDPipelineStore";

export interface AddLogFormValues {
    progress: string
    subProgress: string
    description: string
    attachment: null | File[]
    update_product_hpp: boolean
    isRequiredAttachment: boolean
    [key: string]: any
    terminForm: {
        isTerminEnabled: boolean
        inputType: string
        nominal: string
        vatPercentage: string
        dueDate: string
        datePaid: string
        receiptUrls: File[] | File | null
        customFields: CustomFieldType
        togglePayment: boolean
    }
}

interface SelectOption {
    value: string;
    label: string;
    isRequiredUpdateProductHpp?: boolean;
    isRequiredAttachment?: boolean;
    typeProgress?: string;
    isSplittable?: boolean;
}

interface AddLogProps {
    lead: Pipeline | RecentChatHistoryPipeline
    showAddLogButton?: boolean
    setLead?: any
    progress?: any
    setProgress?: any
    progresses?: any
    progressesLoading?: boolean
}

export interface TempPaymentTermin {
    id?: string
    nominal: number
    dueDate: string
    attachment?: File | null
    customFields?: { [key: string]: any }
    datePaid?: string
    receiptUrls?: string | File | File[]
    progress?: "PENDING" | "PAID"
    togglePayment?: boolean
    vatPercentage?: number
    total: number
}

const AddLog: FC<AddLogProps> = ({ lead, showAddLogButton, setLead, progress, setProgress }) => {
    const todayRange = useTodayRange();
    const { selectMultiPipeline, pipelines, pipelineLoading } = usePipelineStore()
    const { setCurrentDraggedPipelineCard, setIsCardDragging, currentProgressOver } = useDNDPipelineStore()
    const { recentChatHistories, setRecentChatHistories, recentChatSelectedPipeline, setRecentChatSelectedPipeline } = useChatStore()
    const { companyId, keyCustomfield } = useCustomeStore()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const [subProgressesLoading, setIsLoadingSubProgresses] = useState(false);
    const { tablesData, setTablesData, fetchCounter, setFetchCounter } = useDataTableStore()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [progressesData, setProgressesData] = useState<SelectOption[]>([]);
    const [subProgressesData, setSubProgressesData] = useState<SelectOption[]>([]);
    const token = useAccessToken()
    const user = useUser()
    const { code, templatePipelineId } = useParams();
    const [leadProductsData, setLeadProductsData] = useState<HppLeadProducts[]>([]);
    const navigate = useNavigate()
    const quillRef = useRef<ReactQuill>(null)
    const [modalImage, setModalImage] = useState({ show: false, url: '', title: '' });
    const [typeProgress, setTypeProgress] = useState('')
    const [toggleCheckbox, setToggleCheckbox] = useState(true)
    const [tempPaymentTermin, setTempPaymentTermin] = useState<TempPaymentTermin[]>([])
    const [customFieldTermin, setCustomFieldTermin] = useState<CustomField>({})
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [filteredCustomFields, setFilteredCustomFields] = useState<CustomField>({})
    const [remainingAmount, setRemainingAmount] = useState(typeof lead.nominal === 'number' ? lead.nominal : removeDotNominal(lead.nominal))
    const fileInputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
    const [isSplitTableTermin, setIsSplitTableTermin] = useState(false)
    const [terminErrors, setTerminErrors] = useState<any>({})
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const [shouldDate, setShouldDate] = useState<Date | null>()
    const { merchants, merchantLoading } = useMerchantStore()
    const { users, userLoading } = useUserStore()
    const [validationSchema, setValidationSchema] = useState<any>(null);
    const { settings } = useSettingsStore()
    const API_URL = process.env.REACT_APP_API_URL

    const { data: progresses = [], error: progressesError, isLoading: progressesLoading } = useSWR(
        [`${API_URL}lead_progresses?onlyShowAllowedForCreateLeadLog=true&leadTemplateId=${lead.lead_template_id}`, token],
        fetchWithToken
    );


    useEffect(() => {
        if (settings.termins_custom_fields) {
            setCustomFieldTermin(JSON.parse(settings.termins_custom_fields))
        }
    }, [settings])

    useEffect(() => {
        if (Object.keys(selectMultiPipeline).length !== 0) {
            setCustomFields(selectMultiPipeline.customFields)
        }
    }, [selectMultiPipeline])

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
    const intl = useIntl()

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'link'],
        ],
    }

    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'link', 'image']

    const handleHppChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        leadProductsData[index].hpp = (formattedValue ? Number(formattedValue.replaceAll(".", "")) : null)
        setLeadProductsData([...leadProductsData])
    };

    const validateTerminForm = (values: any, customFieldTermin: CustomField) => {
        const errors: any = {};
        // Validate nominal
        if (values.nominal === 0 || values.nominal === '0') {
            errors.nominal = intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT_0" })
        }

        if (values.nominal === '') {
            errors.nominal = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT" }) });
        }

        // Validate dueDate
        if (values.dueDate === '') {
            errors.dueDate = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DEADLINE_PAYMENT" }) });
        }

        if (ALWAYS_REQUIRED_FIELDS_PROOF_OF_PAYMENT.includes(formik.values.progress) && !values.receiptUrls) {
            errors.receiptUrls = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT_TERM" }) });
        }

        // Validate custom fields
        if (customFieldTermin) {
            const customFieldErrors: any = {};
            Object.entries(customFieldTermin).forEach(([key, field]) => {
                const value = values.customFields?.[key];

                // Check for required fields
                if (field.required) {
                    // For file type fields
                    if (['image', 'images', 'file', 'files'].includes(field.type)) {
                        if (!value || (Array.isArray(value) && value.length === 0)) {
                            customFieldErrors[key] = intl.formatMessage(
                                { id: "FORM.VALIDATION.FIELD_REQUIRED" },
                                { title: field.name || 'Custom Field' }
                            );
                        }
                    }
                    // For non-file type fields
                    else if (!values.customFields || !value) {
                        customFieldErrors[key] = intl.formatMessage(
                            { id: "FORM.VALIDATION.FIELD_REQUIRED" },
                            { title: field.name || 'Custom Field' }
                        );
                    }
                }

                // Validate URL for link type fields
                if (field.type === 'link' && value) {
                    try {
                        new URL(value);
                    } catch (e) {
                        customFieldErrors[key] = intl.formatMessage({ id: 'FORM.VALIDATION.MUST_LINK' });
                    }
                }
            });
            if (Object.keys(customFieldErrors).length > 0) {
                errors.customFields = customFieldErrors;
            }
        }

        return errors;
    };

    document.querySelectorAll('.ql-tooltip .ql-editing input').forEach(function (input) {
        input.classList.add('txt-log');
    });

    const processDescriptionImages = async (description: string): Promise<string> => {
        const base64Regex = /<img[^>]+src="(data:image\/[^;]+;base64,[^"]+)"/g;
        let processedDescription = description;
        const imageFiles: File[] = [];
        const base64Data: string[] = [];

        let match: RegExpExecArray | null;
        let index = 0;
        while ((match = base64Regex.exec(description)) !== null) {
            const fullBase64 = match[1];
            base64Data.push(fullBase64);
            const fileType = fullBase64.split(';')[0].split(':')[1];
            const fileName = `image-${Date.now()}-${index}.${fileType.split('/')[1]}`;

            // Convert base64 to File
            const byteCharacters = atob(fullBase64.split(',')[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const file = new File([byteArray], fileName, { type: fileType });

            imageFiles.push(file);
            index++;
        }

        if (imageFiles.length > 0) {
            try {
                const apiResponse = await insertLeadFieldMultiAttachment(imageFiles);

                if (apiResponse.data && apiResponse.data.data && Array.isArray(apiResponse.data.data)) {
                    apiResponse.data.data.forEach((fileUrl: string, index: number) => {
                        const originalBase64 = base64Data[index];
                        const replacementURL = `<a href="#" class="image-link text-primary" style="text-decoration: none; cursor: pointer;" onmouseover="this.style.textDecoration='underline'" onmouseout="this.style.textDecoration='none'" data-image-url="${fileUrl}" data-image-index="${index + 1}">Gambar ${index + 1}</a>`;
                        processedDescription = processedDescription.replace(`<img src="${originalBase64}"`, replacementURL);
                        // replace membuang tanda > 
                        processedDescription = processedDescription.replace(/(<a[^>]+>Gambar \d+<\/a>)>/g, '$1');
                    });
                } else {
                    console.error('Unexpected API response structure:', apiResponse);
                }
            } catch (error) {
                console.error('Failed to upload images:', error);
            }
        }

        return processedDescription;
    };

    const urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

    const convertUrlsToLinks = (text: string) => { // fungsi untuk mengconvert mengecek url dan mengubahnya menjadi link
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = text;

        const replaceText = (node: Node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const newFragment = document.createDocumentFragment();
                const matches = node.textContent?.matchAll(urlRegex);
                let lastIndex = 0;

                for (const match of matches || []) {
                    const url = match[0];
                    const index = match.index!;

                    // Add text before the URL
                    if (index > lastIndex) {
                        newFragment.appendChild(document.createTextNode(node.textContent!.substring(lastIndex, index)));
                    }

                    // Add the URL as a link
                    const link = document.createElement('a');
                    link.href = url;
                    link.textContent = url;
                    link.target = '_blank';
                    link.rel = 'noopener noreferrer';
                    newFragment.appendChild(link);

                    lastIndex = index + url.length;
                }

                // Add any remaining text after the last URL
                if (lastIndex < node.textContent!.length) {
                    newFragment.appendChild(document.createTextNode(node.textContent!.substring(lastIndex)));
                }

                node.parentNode?.replaceChild(newFragment, node);
            } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'A') {
                Array.from(node.childNodes).forEach(replaceText);
            }
        };

        replaceText(tempDiv);
        return tempDiv.innerHTML;
    };

    const formik = useFormik<AddLogFormValues>({
        initialValues: {
            progress: progress ? progress : "",
            subProgress: '',
            description: '',
            attachment: null,
            update_product_hpp: false,
            isRequiredAttachment: false,
            ...Object.fromEntries(Object.keys(customFields).flatMap((key) => {
                const leadPipeline = lead as Pipeline
                if (customFields[key].type === 'invoice_reminder') {
                    return [
                        [`${key}_invoice_url`, leadPipeline.custom_fields[key] && JSON.parse(leadPipeline.custom_fields[key] as string).invoice_url ? JSON.parse(leadPipeline.custom_fields[key] as string).invoice_url : null],
                        [`${key}_date_due`, leadPipeline.custom_fields[key] && JSON.parse(leadPipeline.custom_fields[key] as string).date_due ? JSON.parse(leadPipeline.custom_fields[key] as string).date_due : null]
                    ];
                } else {
                    return [
                        [key,
                            leadPipeline.custom_fields && leadPipeline.custom_fields[key] ?
                                customFields[key].type === 'date' ?
                                    moment(leadPipeline.custom_fields[key], 'YYYY-MM-DD', true).isValid() ?
                                        leadPipeline.custom_fields[key]
                                        : null
                                    : leadPipeline.custom_fields[key]
                                : null]
                    ];
                }
            })),
            terminForm: {
                isTerminEnabled: false,
                inputType: '',
                nominal: '',
                vatPercentage: '',
                dueDate: '',
                datePaid: '',
                receiptUrls: null,
                customFields: {},
                togglePayment: false
            }
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true)
            setModalBtnLoading(true)

            if (values.update_product_hpp) {
                const checkHppProduct = leadProductsData.some(product => product.hpp != 0 && !product.hpp);
                if (checkHppProduct) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.HPP" }) }),
                        confirmButtonText: 'Ok'
                    })
                    setSubmitting(false)
                    setModalBtnLoading(false)
                    return
                }
            }

            // if (remainingAmount > 0) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: intl.formatMessage({ id: intl.formatMessage({ id: "FORM.LABEL.REMAINING_AMOUNT_UNPAYMENT" }) }),
            //         text: intl.formatMessage({ id: intl.formatMessage({ id: "FORM.LABEL.DYNAMIC_REMAINING_AMOUNT" }, { title: `Rp ${customNumberFormatString(remainingAmount.toString())}` }) }),
            //         confirmButtonText: 'Ok'
            //     })
            //     setSubmitting(false)
            //     setModalBtnLoading(false)
            //     return
            // }

            try {
                const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                    (obj, item) => ({
                        ...obj,
                        [item]: (values as Record<string, string | string[] | null>)[item],
                    }),
                    {}
                );

                const convertCustomFields: Record<string, string> = Object.keys(customFields).reduce(
                    (obj, item) => ({
                        ...obj,
                        [`custom_fields.${item}`]: (values as Record<string, string | string[] | null>)[`custom_fields.${item}`] ?? "",
                    }),
                    {}
                );

                const descriptionWithLinks = convertUrlsToLinks(values.description);

                const processDescription = await processDescriptionImages(descriptionWithLinks);

                let attachment: { name: string, url: string }[] = []
                if (values.attachment) {
                    const resourceResponse = await insertLeadFieldMultiAttachment(values.attachment)
                    resourceResponse.data.data.forEach((fileData: string, index: number) => {
                        const fileName = `file-${Date.now()}-${index}`;
                        attachment.push({
                            name: fileName,
                            url: fileData
                        });
                    });
                }

                var countResourceError = 0
                await Promise.all(
                    Object.keys(customFields).map(async (key) => {
                        if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                            try {
                                try {
                                    if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                        const resourceResponse = await insertLeadFieldAttachment(values[key as keyof typeof values]!);
                                        if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                        else countResourceError++
                                    }
                                } catch (error) {
                                    countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                            try {
                                if (values[key as keyof typeof values]) {
                                    var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                    var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                    const resourceResponse = await insertLeadFieldMultiAttachment(dataMultiAttachment);
                                    if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                    else countResourceError++
                                }
                            } catch (error) {
                                countResourceError++
                            }
                        } else if (customFields[key].type === 'merchant') {
                            convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, merchants)?.map((item: any) => item.name).join(", ");
                        } else if (customFields[key].type === 'user') {
                            convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, users)?.map((item: any) => item.name).join(", ");
                        } else if (customFields[key].type === 'pipeline') {
                            if (values[key as keyof typeof values]) convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as string, pipelines)?.name + " / Rp." + customNumberFormat(getDataById(values[key as keyof typeof values] as string, pipelines)?.nominal) + " / " + moment(getDataById(values[key as keyof typeof values] as string, pipelines)?.dateCreated).format('DD MMM YYYY');
                            else convertCustomFields[`custom_fields.${key}`] = '-'
                        }
                    })
                );

                const response = values.update_product_hpp
                    ? await insertLog(
                        lead.id,
                        processDescription,
                        values.progress,
                        values.subProgress,
                        token,
                        attachment,
                        leadProductsData,
                        customFieldsValues
                    )
                    : await insertLog(
                        lead.id,
                        processDescription,
                        values.progress,
                        values.subProgress,
                        token,
                        attachment,
                        undefined,
                        customFieldsValues
                    );

                if (response.data.success) {

                    if (tempPaymentTermin.length > 0) {
                        for (let termin of tempPaymentTermin) {
                            if (termin.customFields) {
                                let processedCustomFields: { [key: string]: any } = { ...termin.customFields };

                                for (const [key, value] of Object.entries(termin.customFields)) {
                                    if (value instanceof File) {
                                        // Handle single file upload
                                        const response = await insertPaymentTerminAttachmentCustomField(value);
                                        if (!response.success) {
                                            Swal.fire({
                                                icon: 'error',
                                                title: response.data.message,
                                                confirmButtonText: 'Ok',
                                            })
                                            return;
                                        }
                                        processedCustomFields[key] = response.data[0];
                                    } else if (Array.isArray(value) && value.some(item => item instanceof File)) {
                                        // Handle multiple files upload
                                        const files = value.filter(item => item instanceof File);
                                        if (files.length > 0) {
                                            const response = await insertPaymentTerminMultiAttachmentCustomField(files);
                                            if (!response.success) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: response.data.message,
                                                    confirmButtonText: 'Ok',
                                                })
                                                return;
                                            }
                                            processedCustomFields[key] = response.data;
                                        }
                                    }
                                }
                                termin.customFields = processedCustomFields;
                            }
                            if (termin.receiptUrls && typeof termin.receiptUrls === 'object' && termin.receiptUrls instanceof File) {
                                const attachment = await insertPaymentTerminAttachment(termin.receiptUrls);
                                if (!attachment.success) {
                                    Swal.fire({
                                        icon: 'error',
                                        title: attachment.data.message,
                                        confirmButtonText: 'Ok',
                                    })
                                    return;
                                }
                                termin.receiptUrls = [attachment.data[0]];
                            }

                            const newValues: ICreatePaymentTermin = {
                                leadId: lead.id,
                                nominal: termin.nominal,
                                dateDue: termin.dueDate,
                                datePaid: termin.datePaid || null,
                                progress: termin.progress === 'PAID' ? 'PAID' : 'PENDING',
                                receiptUrls: termin.receiptUrls || [],
                                customFields: termin.customFields,
                                vatPercentage: termin.vatPercentage,
                            }

                            const response = await createPaymentTermin(newValues, token);
                            if (!response.success) {
                                Swal.fire({
                                    icon: 'error',
                                    title: response.data.message,
                                    confirmButtonText: 'Ok',
                                })
                                return;
                            }
                            termin.receiptUrls = []; // reset ketika sudah berhasil menambahkan row pertama
                        }
                    }
                    // find user.data.roleLeadProgressAccesses with access type 'VIEW_LEAD'
                    const isViewLead = user.data.roleLeadProgressAccesses.find((access) => access.accessType === 'VIEW_LEAD')

                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" }),
                        heightAuto: false,
                        willClose: () => {
                            if (window.location.pathname.includes('log') && !isViewLead?.leadProgresses?.find((leadProgress) => leadProgress.id === progresses)) {
                                // redirect using react router
                                navigate(`/pipelines/${templatePipelineId}`)
                            }
                        }
                    })

                    closeModalRef.current?.click();

                    if (tablesData.logs) {
                        var resultData = [response.data.data, ...tablesData.logs]

                        const sortedLeadData = resultData.sort((a, b) => {
                            return new Date(a.date_created).getTime() - new Date(b.date_created).getTime();
                        });

                        sortedLeadData.forEach((eachData: any, index: number) => {
                            if (index < sortedLeadData.length - 1) {
                                eachData.duration = getDateRangeDuration(sortedLeadData[index].date_created, sortedLeadData[index + 1].date_created);
                            } else {
                                eachData.duration = "-"
                            }
                        })

                        sortedLeadData.sort((a, b) => {
                            return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
                        });
                        setTablesData('logs', resultData)
                    }

                    setRecentChatHistories((prevState) => prevState.map(recentChatHistory => {
                        return {
                            ...recentChatHistory,
                            pipelines: recentChatHistory.pipelines?.map(pipeline => {
                                if (pipeline.id === lead.id) {
                                    return {
                                        ...pipeline,
                                        merchant_id: response.data.data.merchant_id,
                                        merchant_name: response.data.data.merchant_name,
                                        lead_progress_name: response.data.data.lead_progress_name,
                                        lead_progress_color: response.data.data.lead_progress_color
                                    }
                                } else {
                                    return pipeline
                                }
                            })
                        }
                    }))

                    setRecentChatSelectedPipeline({
                        ...recentChatSelectedPipeline,
                        merchant_id: response.data.data.merchant_id,
                        merchant_name: response.data.data.merchant_name,
                        lead_progress_name: response.data.data.lead_progress_name,
                        lead_progress_color: response.data.data.lead_progress_color,
                        lead_progress_id: response.data.data.lead_progress_id,
                        lead_progress_type: response.data.data.lead_progress_type,
                    })

                    if (user.data.company_id === companyId.juraganMaterial || user.data.company_id === companyId.elgisa) {
                        if ((window.location.pathname).includes('log')) navigate(`/pipelines/${templatePipelineId}`)
                        else mutate(`${API_URL}v2/leads`);
                    }

                    if (tablesData.pipelines) {
                        setTablesData('pipelines', (tablesData.pipelines.map(item => {
                            if (item.id === lead.id) {
                                return {
                                    ...item,
                                    lead_progress_name: response.data.data.lead_progress_name,
                                    lead_progress_progress_type: response.data.data.lead_progress_progress_type,
                                    lead_progress_color: response.data.data.lead_progress_color,
                                    hot_warm_cold_status: response.data.data.lead_progress_progress_type !== 'ONGOING' ? "" : item.hot_warm_cold_status,
                                    lead_logs_count: item.lead_logs_count + 1,
                                    lead_subprogress_name: response.data.data.lead_sub_progress_name,
                                    date_end: response.data.data.lead_progress_progress_type === 'ONGOING' ? "" : moment().format("YYYY-MM-DD HH:mm:ss"),
                                    leadProducts: item.leadProducts?.map((product: ListProduct) => {
                                        const newLeadProductData = leadProductsData.filter((p) => p.id === product.leadProductId);
                                        return {
                                            ...product,
                                            leadProductHpp: newLeadProductData[0]?.hpp
                                        }
                                    })
                                }
                            } else {
                                return item
                            }
                        })));
                        setTablesData('pipelinesAbandoned', (tablesData.pipelinesAbandoned?.filter((data) => data.id !== lead.id)))
                        // mutate(`${API_URL}v2/leads`);
                        setFetchCounter(fetchCounter + 1)
                    } else {
                        if (response.data.data.lead_id === lead.id) {
                            setLead({
                                ...lead,
                                lead_progress_name: response.data.data.lead_progress_name,
                                lead_progress_progress_type: response.data.data.lead_progress_progress_type,
                                lead_progress_color: response.data.data.lead_progress_color,
                                lead_subprogress_name: response.data.data.lead_sub_progress_name,
                            })
                        }
                        mutate(API_URL + `lead/${code}`)
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                mutate(`${API_URL}v2/leads`)
                setSubmitting(false)
                setModalBtnLoading(false)
                setProgress(undefined)
                setIsCardDragging(false)
                setCurrentDraggedPipelineCard(undefined)
                const modalChatLogs = document.getElementById('chat-logs-modal')

                if (modalChatLogs) {
                    const modalInstance = Modal.getInstance(modalChatLogs)
                    modalInstance?.show()
                }
            }
        }
    });

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data: customFieldLog, isLoading: customFieldLogLoading } = useSWR(
        formik.values.progress ?
            `${API_URL}lead_logs/custom-field?lead_id=${lead.id}&progress_id=${formik.values.progress}`
            : null,
        fetcher
    );

    useEffect(() => {
        if (customFieldLog) {
            const formSchema = Object.keys(customFieldLog).reduce((obj: any, item: string) => {
                const field = customFieldLog[item];
                const isRequiredMessage = intl.formatMessage(
                    { id: "FORM.VALIDATION.FIELD_REQUIRED" },
                    { title: field.name }
                );
                const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });
                if (field.type === 'multiple' || field.type === 'images' || field.type === 'files') {
                    obj[item] = field.required
                        ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                        : Yup.array().notRequired().nullable();
                } else if (field.type === 'date') {
                    obj[item] = field.required
                        ? Yup.string().required(isRequiredMessage).nullable()
                        : Yup.string().notRequired().nullable();
                } else if (field.type === 'link') {
                    obj[item] = field.required
                        ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                            return !value || isValidUrl(value);
                        })
                        : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                            return !value || isValidUrl(value);
                        });
                } else if (field.type === 'serial') {
                    obj[item] = Yup.array().notRequired().nullable();
                } else if (field.type === 'user' || field.type === 'merchant' || field.type === 'pipeline') {
                    obj[item] = field.required
                        ? Yup.mixed().required(isRequiredMessage).nullable()
                        : Yup.mixed().notRequired().nullable();
                }
                else {
                    obj[item] = field.required
                        ? Yup.string().required(isRequiredMessage).nullable()
                        : Yup.string().notRequired().nullable();
                }
                return obj;
            }, {});
            // Create complete validation schema
            const newValidationSchema = Yup.object().shape({
                progress: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.PROGRESSES" }) })),
                subProgress: (subProgressesData.length > 0) ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.SUBPROGRESS" }) })) : Yup.string().notRequired().nullable(),
                description: Yup.string()
                    .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DESCRIPTION" }) }))
                    .test('image-size', 'One or more images exceed the maximum size of 5MB', function (value: string | undefined) {
                        if (!value) return true;

                        const images = value.match(/<img[^>]+src="(data:image\/[^;]+;base64,[^"]+)"/g);
                        if (!images) return true;

                        for (const img of images) {
                            const match = img.match(/src="(data:image\/[^;]+;base64,[^"]+)"/);
                            if (match && match[1]) {
                                const base64 = match[1];
                                const sizeInBytes = atob(base64.split(',')[1]).length;
                                if (sizeInBytes > MAX_IMAGE_SIZE) return false;
                            }
                        }
                        return true;
                    }),
                attachment: Yup.mixed().when('isRequiredAttachment', {
                    is: true,
                    then: Yup.mixed().test('is-non-empty-array', intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ATTACHMENT" }) }), function (value) {
                        return Array.isArray(value) && value.length > 0;
                    }),
                    otherwise: Yup.mixed().nullable().test('is-valid-attachment', 'Invalid attachment value', function (value) {
                        return value === null || (Array.isArray(value) && value.length >= 0);
                    })
                }),
                isRequiredAttachment: Yup.boolean(),
                ...formSchema
            });
            setValidationSchema(newValidationSchema);
        }
    }, [customFieldLog, subProgressesData, intl]);

    useEffect(() => {
        if (tempPaymentTermin && tempPaymentTermin.length > 0) {
            const latestPayment = tempPaymentTermin[tempPaymentTermin.length - 1];
            const latestDueDate = latestPayment.progress === 'PAID' ? null : new Date(latestPayment.dueDate);
            latestDueDate && latestDueDate.setHours(0, 0, 0, 0);
            setShouldDate(latestDueDate);
        } else {
            setShouldDate(null);
        }
    }, [tempPaymentTermin]);

    // Function untuk memvalidasi tanggal


    const isDateDisabled = (date: Date) => {
        if (!date) return false;

        const checkDate = new Date(date);
        checkDate.setHours(0, 0, 0, 0);

        if (shouldDate) {
            return checkDate <= shouldDate;
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return checkDate < today;
        }
    };

    useEffect(() => { // reset tanggal ketika terjadi perubahan togglePayment
        if (!formik.values.terminForm.togglePayment) {
            formik.setFieldValue('terminForm.dueDate', '')
            formik.setFieldValue('terminForm.datePaid', '')
        }
    }, [formik.values.terminForm.togglePayment])

    useEffect(() => {
        formik.setFieldValue('terminForm.nominal', '')
    }, [formik.values.terminForm.inputType])

    const insertImageToEditor = useCallback((base64: string) => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            const range = editor.getSelection();
            if (range) {
                editor.insertEmbed(range.index, 'image', base64, 'user');
            }
        }
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function getBase64(file: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    const handlePaste = useCallback(async (event: ClipboardEvent) => {
        const items = event.clipboardData?.items;

        if (items) {
            const itemsArray = Array.from(items);
            const fileItems = itemsArray.filter(item => item.kind === 'file');
            const textItems = itemsArray.filter(item => item.kind === 'string');

            if (fileItems.length > 0) {
                const filePromises = fileItems.map((file) => {
                    return new Promise<File | null>(async (resolve, reject) => {
                        const blob = file.getAsFile();
                        if (blob) {
                            try {
                                if (file.type.startsWith('image/')) {
                                    event.preventDefault();
                                    if (blob.size > MAX_IMAGE_SIZE) {
                                        Swal.fire({
                                            icon: 'error',
                                            title: "Image size exceeds 5MB limit",
                                            text: "Please use an image smaller than 5MB.",
                                            confirmButtonText: 'Ok',
                                        });
                                        resolve(null);
                                    } else {
                                        const base64 = await getBase64(blob);
                                        insertImageToEditor(base64);
                                        resolve(null);
                                    }
                                } else {
                                    event.preventDefault();
                                    resolve(blob);
                                }
                            } catch (error) {
                                console.error('Error processing file:', error)
                                reject(error)
                            }
                        } else {
                            resolve(null);
                        }
                    })
                });

                try {
                    const results = await Promise.all(filePromises);
                    const validFiles = results.filter((file): file is File => file !== null);
                    const currentAttachments = formik.values.attachment || [];
                    formik.setFieldValue('attachment', [...currentAttachments, ...validFiles]);
                } catch (error) {
                    console.error('Error processing pasted items:', error);
                    Swal.fire({
                        icon: 'error',
                        title: "Error ketika copy file",
                        confirmButtonText: 'Ok',
                    });
                }
            } else if (textItems.length > 0) {
                textItems[0].getAsString((text) => {
                    const editor = quillRef.current?.getEditor();
                    if (editor) {
                        const range = editor.getSelection();
                        if (range) {
                            editor.insertText(range.index, text);
                        }
                    }
                });
            }
        }
    }, [formik, getBase64, insertImageToEditor]);

    document.querySelectorAll('.ql-tooltip.ql-editing input[type="text"]').forEach(input => {
        if (!input.classList.contains('txt-log')) {
            input.classList.add('txt-log');
        }
    });

    const handleImageClick = useCallback((e: MouseEvent) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains('image-link')) {
            e.preventDefault();
            const imageUrl = target.getAttribute('data-image-url');
            const imageIndex = target.getAttribute('data-image-index');
            if (imageUrl && imageIndex) {
                setModalImage({ show: true, url: imageUrl, title: `Gambar ${imageIndex}` });
            }
        }
    }, []);

    const handleNominalPembayaranChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Hapus semua karakter non-digit (termasuk titik)
        const rawValue = e.target.value.replace(/[^\d]/g, '');

        // Check if input type is percentage and value is greater than 100
        if (formik.values.terminForm.inputType === 'percentage') {
            const numValue = parseInt(rawValue, 10);
            if (numValue > 100) {
                setTerminErrors({ nominal: intl.formatMessage({ id: "FORM.LABEL.PERCENTAGE_MAX" }) });
                return;
            }
        }

        // Format angka dengan pemisah ribuan
        const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        formik.setFieldValue('terminForm.nominal', formattedValue);
        // Clear error when valid input is entered
        if (terminErrors.nominal) {
            setTerminErrors({ ...terminErrors, nominal: '' });
        }
    };
    const handleVATTerminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Hapus semua karakter non-digit (termasuk titik)
        const rawValue = e.target.value.replace(/[^\d]/g, '');


        const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        if (Number(rawValue) > 100) {
            return;
        }
        formik.setFieldValue('terminForm.vatPercentage', formattedValue);
        // Clear error when valid input is entered
        if (terminErrors.vatPercentage) {
            setTerminErrors({ ...terminErrors, vatPercentage: '' });
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleImageClick);
        return () => {
            document.removeEventListener('click', handleImageClick);
        };
    }, [handleImageClick]);

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [handlePaste]);


    const loadSubProgresses = useCallback(async (progressId: string) => {
        if (!progressId) return;
        setIsLoadingSubProgresses(true);
        try {
            const result = await fetchWithToken([`${API_URL}lead_sub_progresses/${progressId}`, token]);
            const subProgressesData = result.map((progress: Progress) => ({
                value: progress.id,
                label: progress.name
            }));
            setSubProgressesData(subProgressesData);
        } catch (error) {
            console.error("Error loading sub-progresses:", error);
        } finally {
            setIsLoadingSubProgresses(false);
        }
    }, [API_URL]);
    const [defaultValueProgress, setDefaultValueProgress] = useState()

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            fileInputRef.current?.value && (fileInputRef.current.value = "")
            setLeadProductsData([])
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        if (!progressesLoading) {
            const progressesData = progresses.map((progress: Progress) => ({
                value: progress.id,
                label: progress.name,
                isRequiredUpdateProductHpp: progress.isRequiredUpdateProductHpp,
                isRequiredAttachment: progress.isRequiredAttachment,
                typeProgress: progress.progress_type,
                isSplittable: progress.isSplittable
            }));
            setProgressesData(progressesData)
            if (progress) {

                const getProgress = progressesData.filter((data: any) => data.value === progress)[0]
                formik.setFieldValue('progress', getProgress)
                setDefaultValueProgress(progressesData.filter((data: any) => data.value == progress)[0])

                const option = progressesData.find((opt: any) => opt.value === progress);
                setIsSplitTableTermin(option?.isSplittable || false);
                setTypeProgress(option?.typeProgress || '');
                formik.setFieldValue('subProgress', '');

                formik.setFieldValue('progress', option?.value || '');
                formik.setFieldValue('update_product_hpp', option?.isRequiredUpdateProductHpp);
                formik.setFieldValue('isRequiredAttachment', option?.isRequiredAttachment);

                setLeadProductsData(lead.leadProducts ? lead.leadProducts.map((product) => ({
                    id: product.leadProductId ?? '',
                    hpp: product.leadProductHpp || product.leadProductHpp === 0 ? product.leadProductHpp : null,
                    name: product.leadProductName ?? '',
                })) : []);

                // Optionally, trigger onChange
                formik.handleChange({
                    target: { name: "progress", value: option?.value || '' },
                });

                formik.handleChange({
                    target: { name: "update_product_hpp", value: option?.isRequiredUpdateProductHpp },
                });

                formik.handleChange({
                    target: { name: "isRequiredAttachment", value: option?.isRequiredAttachment },
                });
            }
        }
    }, [progresses, progress])

    useEffect(() => {
        if (formik.values.progress) {
            loadSubProgresses(formik.values.progress);
        } else {
            setSubProgressesData([]);
        }
    }, [formik.values.progress, loadSubProgresses, defaultValueProgress]);

    useEffect(() => {
        mutate(`${API_URL}lead_sub_progresses/${formik.values.progress}`)
        formik.setFieldValue('subProgress', '')
    }, [formik.values.progress])

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik, () => {
        setProgress(undefined)
        setCurrentDraggedPipelineCard(undefined)
        setIsCardDragging(false)
    });

    const handlePaymentDate = (date: Date | null, isTogglePayment: boolean) => {
        const filterDate = moment(date).format('YYYY-MM-DD');

        if (date === null && !isTogglePayment) {
            formik.setFieldValue('terminForm.dueDate', '');
        } else if (date === null && isTogglePayment) {
            formik.setFieldValue('terminForm.datePaid', '');
        } else if (date !== null && !isTogglePayment) {
            formik.setFieldValue('terminForm.dueDate', filterDate);
        } else if (date !== null && isTogglePayment) {
            formik.setFieldValue('terminForm.datePaid', filterDate);
        }
    };

    const handleAddPaymentTermin = () => {

        const terminFormValues = {
            nominal: formik.values.terminForm.nominal,
            dueDate: formik.values.terminForm.togglePayment ? formik.values.terminForm.datePaid : formik.values.terminForm.dueDate,
            customFields: formik.values.terminForm.customFields,
            progress: formik.values.terminForm.togglePayment ? 'PAID' : 'PENDING',
            datePaid: formik.values.terminForm.togglePayment ? formik.values.terminForm.datePaid : '',
            receiptUrls: formik.values.terminForm.receiptUrls || null,
            vatPercentage: formik.values.terminForm.vatPercentage,
        };

        const errors = validateTerminForm(terminFormValues, customFieldTermin);
        setTerminErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        let currentPaymentAmount: number = 0;
        if (formik.values.terminForm.inputType === 'nominal') {
            currentPaymentAmount = parseInt(formik.values.terminForm.nominal.replace(/\./g, ''), 10);
        } else {
            const percentageValue = parseFloat(formik.values.terminForm.nominal);
            const checkLeadNominal = typeof lead.nominal === 'number' ? lead.nominal : removeDotNominal(lead.nominal)
            currentPaymentAmount = Math.round((percentageValue / 100) * checkLeadNominal);
        }
        if (currentPaymentAmount === 0) {
            setTerminErrors({ nominal: intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT_0" }) });
            return;
        }

        if (currentPaymentAmount > remainingAmount) {
            setTerminErrors({ nominal: intl.formatMessage({ id: "FORM.LABEL.TERMIN_FULLFILLED" }) });
            return;
        }

        let vatPercentage = Number(formik.values.terminForm.vatPercentage.replaceAll(".", "")) ? Number(formik.values.terminForm.vatPercentage) : 0


        let nominalWithVAT = vatPercentage ? Math.round(currentPaymentAmount + currentPaymentAmount * vatPercentage / 100) : currentPaymentAmount



        // Add new termin
        setTempPaymentTermin(prevState => [
            ...prevState,
            {
                nominal: currentPaymentAmount,
                dueDate: formik.values.terminForm.togglePayment ? formik.values.terminForm.datePaid : formik.values.terminForm.dueDate,
                customFields: formik.values.terminForm.customFields,
                progress: formik.values.terminForm.togglePayment ? 'PAID' : 'PENDING',
                datePaid: formik.values.terminForm.togglePayment ? formik.values.terminForm.datePaid : '',
                receiptUrls: formik.values.terminForm.receiptUrls || [],
                vatPercentage: Number(formik.values.terminForm.vatPercentage),
                total: Math.round(nominalWithVAT)
            }
        ]);

        setRemainingAmount(prevAmount => Math.round(prevAmount - currentPaymentAmount));

        formik.setFieldValue('terminForm', {
            inputType: formik.values.terminForm.inputType,
            nominal: '',
            dueDate: '',
            datePaid: '',
            togglePayment: false,
            customFields: {},
            vatPercentage: ""
        });

        refArray.current.forEach(ref => {
            if (ref) {
                ref.value = '';
            }
        });

        setTerminErrors({});
    };

    // Delete Item
    const handleDeleteItem = (index: number) => {
        setTempPaymentTermin(prevTermins => {
            const newTermins = [...prevTermins];
            const deletedItem = newTermins.splice(index, 1)[0];
            setRemainingAmount(prevAmount => Math.round(prevAmount + deletedItem.nominal));
            return newTermins;
        });
    };

    // useEffect(() => {
    //     const currentCustomFields = getCustomFieldsByProgress(formik.values.progress);
    //     const currentCustomFieldKeys = currentCustomFields.map(([key]) => key);

    //     // Reset custom fields that are not associated with the current progress
    //     Object.keys(formik.values.customFields).forEach(key => {
    //         if (!currentCustomFieldKeys.includes(key)) {
    //             formik.setFieldValue(`customFields.${key}`, undefined);
    //             formik.setFieldError(`customFields.${key}`, undefined);
    //             formik.setFieldTouched(`customFields.${key}`, false);
    //         }
    //     });
    //     // Initialize custom fields for the current progress
    //     currentCustomFields.forEach(([key, field]) => {
    //         if (formik.values.customFields[key] === undefined) {
    //             formik.setFieldValue(`customFields.${key}`, field.type === 'file' ? null : '');
    //         }
    //     });

    //     // Validate the form after resetting fields
    //     formik.validateForm();
    // }, [formik.values.progress, customFields]);

    useEffect(() => {
        const progressOption = progressesData.find((p) => p.value === progress)
        setIsSplitTableTermin(progressOption?.isSplittable || false)
        setTypeProgress(progressOption?.typeProgress || '')
        formik.setFieldValue('subProgress', ''); // Menetapkan nilai subProgress ke kosong saat progress berubah
        formik.handleChange({
            target: {
                name: "progress",
                value: progressOption?.value || ''
            }
        });

        formik.handleChange({
            target: {
                name: "update_product_hpp",
                value: progressOption?.isRequiredUpdateProductHpp
            }
        });

        formik.handleChange({
            target: {
                name: "isRequiredAttachment",
                value: progressOption?.isRequiredAttachment
            }
        });

        setLeadProductsData(lead.leadProducts ? (lead.leadProducts).map((product: any) => ({
            id: (product.leadProductId ?? ''),
            hpp: (product.leadProductHpp || product.leadProductHpp === 0 ? product.leadProductHpp : null),
            name: (product.leadProductName ?? '')
        })) : []);
    }, [progress, progressesData])

    return (
        <>
            {
                ((window.location.pathname).includes('log') && showAddLogButton) &&
                <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#tambah-log-modal"
                >
                    {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.MENU.LOGS" })}
                </button>
            }
            <div className="modal fade" tabIndex={-1} id="tambah-log-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.MENU.LOGS" })} {lead.merchant_name} {!(window.location.pathname).includes('chat') && showSerial(lead as Pipeline, keyCustomfield)}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <ImageModal
                            show={modalImage.show}
                            onHide={() => setModalImage({ ...modalImage, show: false })}
                            imageUrl={modalImage.url}
                            title={modalImage.title}
                        />
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.MENU.PROGRESSES" })}</label>
                                <div className="input-group mb-3">
                                    <Select
                                        isDisabled={progress !== undefined}
                                        name="progress"
                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PROGRESSES" })}`}
                                        options={progressesData}
                                        value={progressesData.find(option => {
                                            return option.value === formik.values.progress
                                        }) || null}
                                        onChange={(option) => {
                                            setIsSplitTableTermin(option?.isSplittable || false)
                                            setTypeProgress(option?.typeProgress || '')
                                            formik.setFieldValue('subProgress', ''); // Menetapkan nilai subProgress ke kosong saat progress berubah
                                            formik.handleChange({
                                                target: {
                                                    name: "progress",
                                                    value: option?.value || ''
                                                }
                                            });

                                            formik.handleChange({
                                                target: {
                                                    name: "update_product_hpp",
                                                    value: option?.isRequiredUpdateProductHpp
                                                }
                                            });

                                            formik.handleChange({
                                                target: {
                                                    name: "isRequiredAttachment",
                                                    value: option?.isRequiredAttachment
                                                }
                                            });

                                            setLeadProductsData(lead.leadProducts ? (lead.leadProducts).map((product: any) => ({
                                                id: (product.leadProductId ?? ''),
                                                hpp: (product.leadProductHpp || product.leadProductHpp === 0 ? product.leadProductHpp : null),
                                                name: (product.leadProductName ?? '')
                                            })) : []);
                                        }}
                                        onBlur={
                                            () => formik.values.progress === "" && formik.setFieldTouched("progress", true)
                                        }
                                        className="w-100"
                                        isLoading={progressesLoading}
                                        styles={{
                                            menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                            })
                                        }}
                                    />
                                </div>
                                {formik.touched.progress && formik.errors.progress && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.progress}</span>
                                    </div>
                                )}
                                {
                                    (formik.values.progress && subProgressesData.length > 0) &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.SUBPROGRESS" })}</label>
                                        <div className="input-group mb-3">
                                            <Select
                                                name="subProgress"
                                                placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.SUBPROGRESS" })}`}
                                                options={subProgressesData}
                                                value={subProgressesData.find(option => {
                                                    return option.value === formik.values.subProgress
                                                }) || null}
                                                onChange={
                                                    option => formik.handleChange({
                                                        target: {
                                                            name: "subProgress",
                                                            value: option?.value || ''
                                                        }
                                                    })
                                                }
                                                onBlur={
                                                    () => formik.values.subProgress === "" && formik.setFieldTouched("subProgress", true)
                                                }
                                                className="w-100"
                                                isLoading={subProgressesLoading}
                                                isDisabled={subProgressesLoading}
                                                styles={{
                                                    menu: (base) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    })
                                                }}
                                            />
                                        </div>
                                        {formik.touched.subProgress && formik.errors.subProgress && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.subProgress}</span>
                                            </div>
                                        )}
                                    </>
                                }
                                {customFieldLog && !customFieldLogLoading && Object.keys(customFieldLog).length > 0 && (
                                    <RenderCustomFieldLog
                                        customFieldsLog={customFieldLog}
                                        formik={formik}
                                        selectedProgress={formik.values.progress}
                                        merchants={merchants}
                                        users={users}
                                        pipelines={pipelines}
                                        merchantLoading={merchantLoading}
                                        userLoading={userLoading}
                                        pipelineLoading={pipelineLoading}
                                    />
                                )}
                                {
                                    (formik.values.progress && typeProgress === 'SUCCESS')
                                    && isSplitTableTermin && !(lead as Pipeline).date_end &&
                                    <div>
                                        <div className='fv-plugins-message-container d-flex justify-content-start align-content-center my-5'>
                                            <input id="checkListProgress" type="checkbox"
                                                className="me-2" checked={toggleCheckbox} onChange={() => {
                                                    setToggleCheckbox(!toggleCheckbox);
                                                }} />
                                            <label className="form-check-label" htmlFor="checkListProgress">{intl.formatMessage({ id: 'FORM.LABEL.PAID_OFF' })}</label>
                                        </div>

                                        {!toggleCheckbox && (
                                            <div className="row g-2 align-items-center mb-4">
                                                <span className="mb-3">
                                                    <h5>{intl.formatMessage({ id: 'FORM.LABEL.REMAINING_AMOUNT' })} : {typeof remainingAmount === 'number' ? toRupiah(Math.round(remainingAmount)) : toRupiah(removeDotNominal(remainingAmount))}</h5>
                                                </span>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label className="form-label fs-6 fw-bolder text-dark">{intl.formatMessage({ id: "FORM.LABEL.INPUT_TYPE" })}</label>
                                                            <select
                                                                className="form-select"
                                                                value={formik.values.terminForm.inputType}
                                                                onChange={(e) => formik.setFieldValue('terminForm.inputType', e.target.value)}
                                                            >
                                                                <option value="nominal">Nominal</option>
                                                                <option value="percentage">Persentase</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group h-100">
                                                            <div className="d-flex align-items-center h-100 pt-6">
                                                                <label className='form-label fs-6 fw-bolder text-dark mb-0 me-3'>{intl.formatMessage({ id: 'FORM.ACTION.ALREADY_PAID' })}</label>
                                                                <Switch
                                                                    checked={formik.values.terminForm.togglePayment}
                                                                    onChange={(e) => formik.setFieldValue('terminForm.togglePayment', e.target.checked)}
                                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label fs-6 fw-bolder text-dark required">
                                                                {formik.values.terminForm.inputType === 'nominal' ? intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT" }) : intl.formatMessage({ id: "FORM.LABEL.PAYMENT_PERCENTAGE" })}
                                                            </label>
                                                            <div className="input-group">
                                                                <span className="input-group-text">
                                                                    {formik.values.terminForm.inputType === 'nominal' ? 'Rp' : '%'}
                                                                </span>
                                                                <input
                                                                    type="text"
                                                                    inputMode="numeric"
                                                                    className="form-control"
                                                                    placeholder={formik.values.terminForm.inputType === 'nominal' ? intl.formatMessage({ id: "FORM.LABEL.ENTER_PAYMENT_AMOUNT" }, { title: 'Nominal' }) : intl.formatMessage({ id: "FORM.LABEL.ENTER_PERCENTAGE" })}
                                                                    value={formik.values.terminForm.nominal}
                                                                    onChange={handleNominalPembayaranChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ppn termin */}
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="form-label fs-6 fw-bolder text-dark ">
                                                                {intl.formatMessage({ id: "FORM.LABEL.VAT" })}
                                                            </label>
                                                            <div className="input-group">
                                                                <span className="input-group-text">%</span>
                                                                <input
                                                                    type="text"
                                                                    inputMode="numeric"
                                                                    className="form-control"
                                                                    value={formik.values.terminForm.vatPercentage}
                                                                    onChange={handleVATTerminChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            {
                                                                formik.values.terminForm.togglePayment ? (
                                                                    <>
                                                                        <label className="form-label fs-6 fw-bolder text-dark required">{intl.formatMessage({ id: "FORM.LABEL.PAYMENT_DATE" })}</label>
                                                                        <DatePicker
                                                                            value={formik.values.terminForm.datePaid ? new Date(formik.values.terminForm.datePaid) : null}
                                                                            onChange={(date) => handlePaymentDate(date, formik.values.terminForm.togglePayment)}
                                                                            ranges={todayRange}
                                                                            oneTap
                                                                            editable={false}
                                                                            format="dd MMM yyyy"
                                                                            defaultValue={null}
                                                                            style={{ width: "100%" }}
                                                                            className="form-control p-1"
                                                                            container={document.querySelector('#tambah-log-modal') as HTMLElement}
                                                                            shouldDisableDate={isAfterToday}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <label className="form-label fs-6 fw-bolder text-dark required">{intl.formatMessage({ id: "FORM.LABEL.DEADLINE_PAYMENT" })}</label>
                                                                        <DatePicker
                                                                            value={formik.values.terminForm.dueDate ? new Date(formik.values.terminForm.dueDate) : null}
                                                                            onChange={(date) => handlePaymentDate(date, formik.values.terminForm.togglePayment)}
                                                                            ranges={todayRange}
                                                                            oneTap
                                                                            editable={false}
                                                                            format="dd MMM yyyy"
                                                                            defaultValue={null}
                                                                            style={{ width: "100%" }}
                                                                            className="form-control p-1"
                                                                            container={document.querySelector('#tambah-log-modal') as HTMLElement}
                                                                            shouldDisableDate={isDateDisabled}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Menampilkan error message */}
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        {/* Error messages */}
                                                        {terminErrors.nominal && (
                                                            <span className="text-danger mt-3">{terminErrors.nominal}</span>
                                                        )}
                                                    </div>
                                                    <div className="col-md-4">
                                                        {/* Error messages */}
                                                        {terminErrors.vatPercentage && (
                                                            <span className="text-danger mt-3">{terminErrors.vatPercentage}</span>
                                                        )}
                                                    </div>
                                                    <div className="col-md-4">
                                                        {terminErrors.dueDate && (
                                                            <span className="text-danger mt-3">{terminErrors.dueDate}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="row mt-3">
                                                    {formik.values.terminForm.togglePayment &&
                                                        <div className="col-md-8">
                                                            <label className={`form-label fs-6 fw-bolder text-dark ${ALWAYS_REQUIRED_FIELDS_PROOF_OF_PAYMENT.includes(formik.values.progress) ? 'required' : ''}`}>{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_PAYMENT_TERM' })}</label>
                                                            <InputAttachmentTermin key={`formik.terminForm.receiptUrls`} keyString={`formik.terminForm.receiptUrls`} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" typeCustomField='proofOfPayment' />
                                                            {terminErrors.receiptUrls && (
                                                                <span className="text-danger mt-3">{terminErrors.receiptUrls}</span>
                                                            )}
                                                        </div>
                                                    }
                                                </div>
                                                {/* Render Custom Field Termin */}
                                                <RenderTerminCustomFieldLog
                                                    customFieldTermin={customFieldTermin}
                                                    formik={formik}
                                                    errors={terminErrors}
                                                    inputRefs={refArray}
                                                />
                                                {/* Button Termin */}
                                                <div className='col-md-12'>
                                                    <label className='form-label fs-6 fw-bolder text-white'>t</label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-light-primary w-100"
                                                        onClick={handleAddPaymentTermin}
                                                    >
                                                        <i className="fas fa-plus"></i>
                                                        {intl.formatMessage({ id: "FORM.LABEL.ADD_GENERAL" }, { title: 'Termin' })}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {tempPaymentTermin && tempPaymentTermin.length > 0 && (
                                            <PaymentTerminDisplay onDeleteItem={handleDeleteItem} tempPaymentTermin={tempPaymentTermin} customFieldTermin={customFieldTermin} pipelineId={(lead as Pipeline).id} />
                                        )}
                                    </div>
                                }
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DESCRIPTION" })}</label>
                                <div className="input-group mb-15">
                                    <ReactQuill
                                        theme="snow"
                                        value={formik.values.description}
                                        onChange={(content) => formik.setFieldValue('description', content)}
                                        formats={formats}
                                        modules={modules}
                                        ref={quillRef}
                                        style={{ height: 170, width: '100%' }}
                                        onBlur={() => formik.setFieldTouched('description', true)}
                                        className={clsx('link',
                                            { 'is-invalid': formik.touched.description && formik.errors.description },
                                        )}
                                    />
                                </div>
                                {formik.touched.description && formik.errors.description && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.description}</span>
                                    </div>
                                )}
                                <label className={`form-label fs-6 fw-bolder text-dark ${formik.values.isRequiredAttachment ? "required" : ""}`}>
                                    {intl.formatMessage({ id: "FORM.LABEL.ATTACHMENT" })}
                                </label>
                                <InputAttachment
                                    keyString={"attachment"}
                                    formik={formik}
                                    isMulti={true}
                                    type="update"
                                    accept="image/png, image/jpeg, image/jpg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                />
                                {formik.touched.attachment && formik.errors.attachment && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.attachment as string}</span>
                                    </div>
                                )}
                                {formik.values.update_product_hpp && leadProductsData?.length > 0 && (
                                    <>
                                        <div className="separator separator-dashed my-10"></div>
                                        <div className="row">
                                            <div className="col-7">
                                                <label className={`form-label fs-6 fw-bolder text-dark`}>{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</label>
                                            </div>
                                            <div className="col-5">
                                                <label className={`form-label fs-6 fw-bolder text-dark required`}>HPP</label>
                                            </div>
                                        </div>
                                        {leadProductsData?.map((product, index) => (
                                            <div className="row mb-3">
                                                <label className="col-7 col-form-label font-weight-bold">{lead.leadProducts ? lead.leadProducts[index].leadProductName : ""}</label>
                                                <div className="col-5">
                                                    <input
                                                        value={product.hpp || product.hpp == 0 ? customNumberFormat(product.hpp) : ""}
                                                        onChange={(e) => handleHppChange(e, index)}
                                                        className={'form-control'}
                                                        type='text'
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}

                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} disabled={subProgressesLoading ? true : false || (!toggleCheckbox && tempPaymentTermin.length === 0) ? true : false}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div >
                </div >
            </div >
        </>

    )
}

export { AddLog }