import axios from "axios"
import { FileAttachment, LocationAttachment, MediaAttachment, ReplyAttachment } from "../interfaces/Chat/MessageData"

const API_URL = process.env.REACT_APP_API_URL

const CHAT = `${API_URL}chat`
const GET_TOTAL_CHAT = `${API_URL}chat/total-count`
const GET_INIT_SYNC = `${API_URL}chat/init`
const GET_CHAT_ROOMS = `${API_URL}chat/rooms`
const SESSION_CHAT = `${API_URL}v2/chat/auth`

export const getTotalChatData = (phone: string, tokenChat: string, token: string) => {
    return axios.get(GET_TOTAL_CHAT + `?mode=omnichannel&as=${phone}`,
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const getInitSyncData = (limit: number, offset: number, phone: string, tokenChat: string, token: string) => {
    return axios.get(GET_INIT_SYNC + `?limit=${limit}&offset=${offset}&mode=omnichannel&as=${phone}`,
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const getChatRoomsData = (lastSynced: string, phone: string, tokenChat: string, token: string, params?: { offset: number, limit: number }) => {
    return axios.get(GET_CHAT_ROOMS + `?mode=omnichannel&as=${phone}&lastChatDate=${lastSynced}${params ? `&offset=${params.offset}&limit=${params.limit}` : ''}`,
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const getChatMessagesData = (roomPhone: string, phone: string, token: string, tokenChat: string, lastChatId?: string) => {
    return axios.get(`${GET_CHAT_ROOMS}/${roomPhone}?mode=omnichannel&as=${phone}${lastChatId ? `&lastChatId=${lastChatId}` : ''}`,
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const postSendChat = (phone: string, as_phone: string, message: string, token: string, tokenChat: string, attachment?: MediaAttachment | FileAttachment | LocationAttachment) => {
    return axios.post(`${CHAT}?mode=omnichannel&as=${as_phone}`,
        attachment ?
            {
                phone: phone,
                message: message,
                attachment: attachment
            }
            :
            {
                phone: phone,
                message: message,
            },
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const postReplyChat = (messageId: string, phone_receiver: string, as_phone: string, is_sender_chat: boolean, raw_message: string, reply_message: string, token: string, tokenChat: string, attachment?: ReplyAttachment) => {
    return axios.post(`${CHAT}/${messageId}/reply?mode=omnichannel&as=${as_phone}`,
        attachment ?
            {
                phone_receiver: phone_receiver,
                is_sender_chat: is_sender_chat,
                rawMessage: raw_message,
                reply_message: reply_message,
                attachment: attachment
            }
            :
            {
                phone_receiver: phone_receiver,
                is_sender_chat: is_sender_chat,
                rawMessage: raw_message,
                reply_message: reply_message
            },
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const postForwardChat = (targets: string[], rawMessages: string[], as_phone: string, token: string, tokenChat: string) => {
    return axios.post(`${CHAT}/forward?mode=omnichannel&as=${as_phone}`,
        {
            targets,
            rawMessages
        },
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const putEditChat = (messageId: string, phone_receiver: string, as_phone: string, new_message: string, token: string, tokenChat: string) => {
    return axios.put(`${CHAT}/${messageId}?mode=omnichannel&as=${as_phone}`,
        {
            phone_receiver: phone_receiver,
            new_message: new_message,
        },
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    )
}

export const deleteUnsendChat = (messageId: string, phone_receiver: string, as_phone: string, token: string, tokenChat: string) => {
    return axios.delete(`${CHAT}/${messageId}?mode=omnichannel&as=${as_phone}`, {
        data: {
            phone_receiver: phone_receiver
        },
        headers: {
            'X-Auth-token': token,
            'X-Chat-Auth-Token': tokenChat,
        }
    });
};

export const createSessionChat = (phones: string[], token: string) => {
    return axios.post(SESSION_CHAT,
        {
            phones
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    );
};

export const deleteSessionChat = (as_phone: string, token: string, tokenChat: string) => {
    return axios.delete(`${SESSION_CHAT}?mode=omnichannel&as=${as_phone}`,
        {
            headers: {
                'X-Auth-token': token,
                'X-Chat-Auth-Token': tokenChat,
            }
        }
    );
};