import { useState, useCallback } from 'react';
import { insertEmailAttachmentMulti } from '../../../api/ResourceAPI';

export const useAttachmentGeneral = (initialTotalFileSize = 0) => {
  const [temporaryCopyFile, setTemporaryCopyFile] = useState<File[]>([]);
  const [dragAndDropFile, setDragAndDropFile] = useState<File[]>([]);
  const [totalFileSize, setTotalFileSize] = useState(initialTotalFileSize);
  const [showModal, setShowModal] = useState(false);
  const [attachments, setAttachments] = useState<File[][]>([[]]);

  const handleOpenAttachment = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file: File) => file);
      const newTotalFileSize = newFiles.reduce((total: number, file: File) => total + file.size, 0);
      setTotalFileSize((prevSize: number) => prevSize + newTotalFileSize);
    },
    []
  );

  const removeFile = (index: number) => {
    const removedFile = temporaryCopyFile.splice(index, 1)[0];
    setTotalFileSize((prevSize: number) => prevSize - removedFile.size);
    setTemporaryCopyFile([...temporaryCopyFile]);
  };

  const uploadAttachments = async () => {
    if (attachments.length === 0) return [];

    const uploadedAttachments: string[][] = [];

    for (let i = 0; i < attachments.length; i++) {
      const templateAttachments = attachments[i];
      if (templateAttachments.length === 0) {
        uploadedAttachments.push([]);
        continue;
      }

      const formData = new FormData();
      templateAttachments.forEach(file => {
        formData.append('files', file);
      });

      try {
        const response = await insertEmailAttachmentMulti(formData);
        uploadedAttachments.push(response.data.data);
      } catch (error) {
        console.error(`Error uploading attachments for template ${i}:`, error);
        uploadedAttachments.push([]);
      }
    }

    return uploadedAttachments;
  };

  /*
  Berdasarkan attachment jika terdapat attachment maka values attachment di ambil berdasarkan index attachment 
  */

  return {
    temporaryCopyFile,
    setTemporaryCopyFile,
    dragAndDropFile,
    setDragAndDropFile,
    totalFileSize,
    setTotalFileSize,
    showModal,
    handleOpenAttachment,
    handleCloseModal,
    onDrop,
    removeFile,
    uploadAttachments,
    attachments,
    setAttachments,
  };
};