import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import moment from 'moment';
import 'moment-duration-format';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { CustomField } from '../../interfaces/Settings';
import { exportWorkbook, setColumnWidths, styleColumns } from './helperTemplateExcel';
import { addWorkSheetInfo } from './helperTemplateExcel';
import { createWorksheet } from './helperTemplateExcel';
import { createWorkbook } from './helperTemplateExcel';

export const exportTemplateMerchantExcel = async (
    nameFile: string,
    customFields: CustomField,
    source: string,
    typeAction: string,
    settings: any,
    nameInfo: string,
    nameHeader: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    user?: UserModel,
    param?: any,
    type?: string,
) => {
    let exportData: any[] = [];
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, "Sheet1");
    const worksheetInfo = createWorksheet(workbook, "Info");
    var header: string[] = []
    var subHeader: string[] = []
    let singleOptionSheetsMerchants = {};

    const createSingleOptionWorksheets = (customFields: CustomField) => {
        let singleOptionSheets: { [key: string]: string } = {};
        let usedNames = new Set<string>();

        Object.entries(customFields).forEach(([key, field]) => {
            if (field.type === 'option') {
                let baseWorksheetName = `Daftar ${field?.name?.replace(/\s+/g, ' ')}`;
                let worksheetName = baseWorksheetName;
                let counter = 1;

                while (usedNames.has(worksheetName.toLowerCase())) {
                    worksheetName = `${baseWorksheetName} (${counter})`;
                    counter++;
                }

                usedNames.add(worksheetName.toLowerCase());

                let singleOptionSheet = workbook.addWorksheet(worksheetName);
                singleOptionSheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
                    formatColumns: true,
                    autoFilter: true,
                });
                singleOptionSheet.getCell('A1').value = `${field.name}`;

                // Cek apakah 'field.value' adalah array of string atau array of TypeSerial
                if (Array.isArray(field.value) && typeof field.value[0] === 'string') {
                    // Jika field.value adalah string[]
                    (field.value as string[]).forEach((option: string, index: number) => {
                        singleOptionSheet.getCell(`A${index + 2}`).value = option;
                    });
                }
                singleOptionSheets[key] = worksheetName;
            }
        });
        return singleOptionSheets;
    };

    header = [...nameHeader.insert.merchant.header];
    subHeader = [...nameHeader.insert.merchant.subHeader];

    let worksheetUser = workbook.addWorksheet('Daftar User');
    await worksheetUser.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetUser.getCell('A1').value = `Daftar User`;
    await param.dataUsers.forEach((user: any) => {
        worksheetUser.addRow([user.name]);
    });

    let worksheetMerchants = workbook.addWorksheet('Daftar Merchants');
    await worksheetMerchants.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    let worksheetCity = workbook.addWorksheet('Daftar Kota');
    await worksheetCity.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });

    worksheetCity.getCell('A1').value = `Daftar Kota`;
    await param.dataCities.forEach((user: any) => {
        worksheetCity.addRow([user.name]);
    });

    // Daftar Single Option
    singleOptionSheetsMerchants = createSingleOptionWorksheets(customFields);

    // kalau type nya error akan menambahkan kolom di bawah ini
    if (type === 'error') {
        header.push('Error');
        subHeader.push('Abaikan kolom ini');
        Array.from(param.listErrorInsert).map((row: any, index) => {
            var temp_excel = [];
            temp_excel.push(
                row.name,
                row.pic_name,
                row.city_name,
                row.phone,
                row.email,
                ...Object.keys(customFields).map((field: any) => row[field])
            );

            // Menambahkan row.error di akhir
            temp_excel.push(row.error);
            exportData.push(temp_excel);
        });
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows(worksheet, exportData, customFields, source, type!, typeAction, user!, param, singleOptionSheetsMerchants);
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);

    exportWorkbook(workbook, worksheet, source, `${nameFile}.xlsx`, setIsLoading);
};


const addDataRows = (
    worksheet: ExcelJS.Worksheet,
    data: any[],
    customFields: CustomField,
    source: string,
    type: string,
    typeAction: string,
    user: UserModel,
    param?: any,
    singleOptionSheets?: any
) => {
    data.forEach((rowData, index) => {
        worksheet.addRow(rowData);
    });

    const addSingleOptionValidation = (startColumn: string, customFields: any) => {
        let currentColumn = startColumn.charCodeAt(0);
        Object.entries(customFields).forEach(([key, field]: [string, any]) => {
            if (field.type === 'option') {
                const columnLetter = String.fromCharCode(currentColumn);
                const worksheetName = singleOptionSheets[key]; // Use the unique worksheet name
                if (typeAction === 'update') {
                    var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;
                }
                for (let i = 3; i <= (typeAction === 'update' ? dataLength + 2 : 1000); i++) {
                    worksheet.getCell(`${columnLetter}${i}`).dataValidation = {
                        type: 'list',
                        allowBlank: true,
                        showErrorMessage: true,
                        formulae: [`='${worksheetName}'!$A$2:$A$${field.value.length + 1}`],
                        errorStyle: 'error',
                        error: `Pilih ${field.name}`,
                    };
                }
            }
            currentColumn++;
        });
    };

    for (let i = 3; i < 550; i++) {
        if (typeAction === 'insert') {
            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar User'!$A$2:$A$${param.dataUsers.length + 1}`],
                errorStyle: 'error',
                error: 'Pilih user',
            };

            if (user?.data.company_id !== 'd2f2a3da-3829-4872-8891-cae921d555b5') {
                worksheet.getCell('C' + i).dataValidation = {
                    type: 'list',
                    allowBlank: false,
                    showErrorMessage: true,
                    formulae: [`='Daftar Kota'!$A$2:$A$${param.dataCities.length + 1}`],
                    errorStyle: 'error',
                    error: 'Pilih kota',
                };
            }

            addSingleOptionValidation('F', customFields);
        }
    }
};
