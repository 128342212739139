/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { useSettingsStore } from '../../../../stores/SettingsStore'
import { useCompanyStore } from '../../../../stores/CompanyStore'
import { checkIsSubMenuActive } from '../../../helpers'
import { useLocation } from 'react-router-dom'
import { isEqual } from 'lodash'
import { useSubordinateStore } from '../../../../stores/SubordinateStore'
import { useIntl } from 'react-intl'
import { useCustomeStore } from '../../../../stores/CustomeStore'
import { usePipelineStore } from '../../../../stores/PipelineStore'
import { useMerchantStore } from '../../../../stores/MerchantStore'
import { AsideMenuChat } from './AsideMenuChat'
import { HIDE_MENU_CHAT_CUSTOM_COMPANY, USER_MANAGER_HAPPY_PLAY } from '../../../../constant/General'
import { useChatStore } from '../../../../stores/ChatStore'
import { useMeetingStore } from '../../../../stores/MeetingsStore'

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { listChatPhone } = useChatStore()
  const { settings } = useSettingsStore()
  const { multiPipelines } = usePipelineStore()
  const { multiMerchants } = useMerchantStore()
  const { multiMeetings } = useMeetingStore()
  const { companies, setCompanies } = useCompanyStore()
  const { pathname } = useLocation()
  const isSubMenuActive = checkIsSubMenuActive(pathname)
  const { subordinates } = useSubordinateStore()
  const { companyId, roleId } = useCustomeStore()

  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon='bi-app-indicator'
      />
      {
        (user.data.company_id === companyId.tkd || user.data.company_id === companyId.looyalIT|| user.data.company_id === companyId.QA|| user.data.company_id === companyId.QAProd)&& user.data.is_superadmin
        && <AsideMenuItem
        to='/analytics'
        icon='/media/icons/duotune/general/gen032.svg'
        title={intl.formatMessage({ id: "MENU.ANALYTICS" })}
        fontIcon='bi-app-indicator'
      />
      }
      

      {(settings.feature_pipeline && (user.data.permission_pipeline_read !== "NONE")) && (
        <AsideMenuItemWithSub
          to='/pipelines'
          title={`${settings.pipeline_title}`}
          icon='/media/icons/duotune/abstract/abs027.svg'
          isSubMenuActive={isEqual(isSubMenuActive, { pipeline: true }) ? true : false}
        >
          {
            multiPipelines.map((pipeline, index) => {
              return (
                <AsideMenuItem
                  key={index}
                  hasBullet={true}
                  to={`/pipelines/${pipeline.id}`}
                  title={pipeline.name}
                  fontIcon='bi-layers'
                />
              )
            })
          }
        </AsideMenuItemWithSub>
      )}
      {
        <AsideMenuItemWithSub
          title={`${settings.merchant_title}`}
          to='/merchants'
          icon='/media/icons/duotune/communication/com014.svg'
          isSubMenuActive={isEqual(isSubMenuActive, { merchant: true }) ? true : false}
        >
          {multiMerchants.map((data: any, index: number) => {
            return (
              <AsideMenuItem
                key={index}
                hasBullet={true}
                to={`/merchants/${data.id}`}
                title={data.name}
                fontIcon='bi-layers'

              />
            )
          })}
        </AsideMenuItemWithSub>
      }

      {(settings.feature_meeting && (user.data.permission_meeting_read !== "NONE")) && (
        <>
            <AsideMenuItemWithSub
              to='/meetings'
              icon='/media/icons/duotune/general/gen056.svg'
              title={settings.meeting_title}
              isSubMenuActive={isEqual(isSubMenuActive, {meetings: true}) ? true : false}
            >
              {multiMeetings.map((data: any, index: number) => {
                return (
                  <AsideMenuItem
                    key={index}
                    hasBullet={true}
                    to={`/meetings/${data.id}`}
                    title={data.name}
                    fontIcon='bi-layers'
                  />
                )
              })}
            </AsideMenuItemWithSub>
        </>
      )}
      {/* {(settings.feature_meeting && (user.data.permission_meeting_read !== "NONE")) && (
        <AsideMenuItem
          to='/meetings'
          icon='/media/icons/duotune/general/gen056.svg'
          title={settings.meeting_title+2}
          fontIcon='bi-layers'
        />
      )} */}

      <AsideMenuItemWithSub
        to='/master'
        title='Master'
        icon='/media/svg/general/puzzle.svg'
        isSubMenuActive={isEqual(isSubMenuActive, { master: true }) ? true : false}
      >
        {
          user.data.permission_product_read !== 'NONE' && user.data.is_superadmin && (
            <>
              {
                settings.feature_product && (
                  <AsideMenuItem
                    hasBullet={true}
                    to='/products'
                    // icon='/media/icons/duotune/art/art002.svg'
                    title={intl.formatMessage({ id: 'MENU.PRODUCTS' })}
                    fontIcon='bi-layers'
                  />
                )
              }
              {
                settings.feature_label && (
                  <AsideMenuItem
                    hasBullet={true}
                    to='/labels'
                    // icon='/media/icons/duotune/maps/map001.svg'
                    title={intl.formatMessage({ id: 'MENU.LABELS' })}
                    fontIcon='bi-layers'
                  />
                )
              }
              {
                settings.feature_unit && (
                  <AsideMenuItem
                    hasBullet={true}
                    to='/units'
                    // icon='/media/icons/duotune/maps/map001.svg'
                    title={intl.formatMessage({ id: 'MENU.UNITS' })}
                    fontIcon='bi-layers'
                  />
                )
              }
            </>
          )}

        {settings.feature_progress && user.data.permission_pipeline_progress_read !== 'NONE' && user.data.is_superadmin && (
          <AsideMenuItem
            hasBullet={true}
            to='/progresses'
            // icon='/media/icons/duotune/general/gen059.svg'
            title={intl.formatMessage({ id: 'MENU.PROGRESSES' })}
            fontIcon='bi-layers'
          />
        )}

        {settings.feature_source && user.data.permission_pipeline_source_read !== 'NONE' && user.data.is_superadmin && (
          <AsideMenuItem
            hasBullet={true}
            to='/sources'
            // icon='/media/icons/duotune/abstract/abs027.svg'
            title={intl.formatMessage({ id: 'MENU.SOURCES' })}
            fontIcon='bi-layers'
          />
        )}

        {settings.feature_conference && user.data.companyLimitMasterRoom > 0 && (
          <AsideMenuItem
            hasBullet={true}
            to='/conferences'
            // icon='/media/icons/duotune/general/gen029.svg'
            title={intl.formatMessage({ id: 'MENU.CONFERENCES' })}
            fontIcon='bi-layers'
          />
        )}

        {user.data.companyLimitMasterEmail > 0 && (
          <AsideMenuItem
            hasBullet={true}
            to='/emails'
            // icon='/media/icons/duotune/general/gen029.svg'
            title={intl.formatMessage({ id: 'MENU.EMAILS' })}
            fontIcon='bi-layers'
          />
        )}

        {user.data.username === 'PT_TKD' && (
          <AsideMenuItem
            hasBullet={true}
            to='/company'
            // icon='/media/icons/duotune/general/gen001.svg'
            title='Company'
            fontIcon='bi-layers'
          />
        )}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/master'
        title={intl.formatMessage({ id: 'MENU.USERS' }) + " & " + intl.formatMessage({ id: 'MENU.ROLES' })}
        icon='/media/svg/general/user.svg'
        isSubMenuActive={isEqual(isSubMenuActive, { userRole: true }) ? true : false}
      >
        {
          settings.feature_activities &&
          <AsideMenuItem
            hasBullet={true}
            to='/activities'
            // icon='/media/icons/duotune/maps/map001.svg'
            title={intl.formatMessage({ id: 'MENU.ACTIVITY' })}
            fontIcon='bi-layers'
          />
        }

        {settings.feature_target && user.data.permission_user_target_set !== "NONE" && !((user.data.permission_user_target_set === "WHOLE TEAM EXCEPT SELF" || user.data.permission_user_target_set === "TEAM EXCEPT SELF") && subordinates.length === 0) && (
          <AsideMenuItem
            hasBullet={true}
            to='/targets'
            // icon='/media/icons/duotune/communication/com009.svg'
            title={intl.formatMessage({ id: 'MENU.TARGETS' })}
            fontIcon='bi-layers'
          />
        )}

        {user.data.permission_user_read !== 'NONE' && user.data.is_superadmin && (
          <AsideMenuItem
            hasBullet={true}
            to='/users'
            // icon='/media/icons/duotune/communication/com013.svg'
            title={intl.formatMessage({ id: 'MENU.USERS' })}
            fontIcon='bi-layers'
          />
        )}

        {user.data.is_superadmin && (
          <AsideMenuItem
            hasBullet={true}
            to='/roles'
            // icon='/media/icons/duotune/general/gen029.svg'
            title={intl.formatMessage({ id: 'MENU.ROLES' })}
            fontIcon='bi-layers'
          />
        )}
      </AsideMenuItemWithSub>

      {settings.feature_reimbursement &&
        <AsideMenuItemWithSub
          to='/reimburse'
          title={intl.formatMessage({ id: 'MENU.REIMBURSEMENTS' })}
          icon='/media/icons/duotune/finance/fin007.svg'
          isSubMenuActive={isEqual(isSubMenuActive, { reimbursement: true }) ? true : false}
        >

          {
            <AsideMenuItem
              hasBullet={true}
              to='/reimbursement'
              title={intl.formatMessage({ id: 'MENU.REIMBURSEMENTS' })}
              fontIcon='bi-layers'
            />
          }

          {
            <AsideMenuItem
              hasBullet={true}
              to='/limits'
              title={intl.formatMessage({ id: 'MENU.LIMITS' })}
              fontIcon='bi-layers'
            />
          }

          {
            user.data.is_superadmin &&
            <AsideMenuItem
              hasBullet={true}
              to='/categories'
              title={intl.formatMessage({ id: 'MENU.CATEGORIES' })}
              fontIcon='bi-layers'
            />
          }
        </AsideMenuItemWithSub>
      }

      {
        <AsideMenuItemWithSub
          to='/report-attendance'
          title={intl.formatMessage({ id: 'MENU.REPORT' })}
          icon='/media/svg/general/clipboard.svg'
          isSubMenuActive={isEqual(isSubMenuActive, { report: true }) ? true : false}
        >
          {
            settings.feature_attendance && user.data.is_superadmin && Number(companies.companyFeatureLimitUserAttendance) > 0 && (
              <>
                <AsideMenuItem
                  hasBullet={true}
                  to='/report-daily-attendance'
                  // icon='/media/icons/duotune/general/gen013.svg'
                  title={intl.formatMessage({ id: 'MENU.DAILY_ATTENDANCE' })}
                  fontIcon='bi-layers'
                />
                <AsideMenuItem
                  hasBullet={true}
                  to='/report-attendance-recap'
                  // icon='/media/icons/duotune/general/gen014.svg'
                  title={intl.formatMessage({ id: 'MENU.ATTENDANCE_RECAP' })}
                  fontIcon='bi-layers'
                />
              </>
            )
          }
          {
            (user.data.company_id === companyId.maspionSquare || user.data.company_id === companyId.giaGroup) && (
              <AsideMenuItem
                hasBullet={true}
                to='/report-account-receivable'
                // icon='/media/icons/duotune/general/gen014.svg'
                title={intl.formatMessage({ id: 'MENU.ACCOUNT_RECEIVABLE' })}
                fontIcon='bi-layers'
              />
            )
          }
        </AsideMenuItemWithSub>
      }

      {settings.feature_check_number && (
        <AsideMenuItem
          to='/validation-phone'
          icon='/media/icons/duotune/communication/com005.svg'
          title='Validasi Telepon'
          fontIcon='bi-layers'
        />
      )}

      {user.data.is_superadmin && (
        <AsideMenuItem
          to='/settings'
          icon='/media/icons/duotune/coding/cod001.svg'
          title={intl.formatMessage({ id: "MENU.SETTING" })}
          fontIcon='bi-layers'
        />
      )}

      {!HIDE_MENU_CHAT_CUSTOM_COMPANY.includes(user.data.id) && (
          <AsideMenuItem
            to='/chat'
            icon='/media/icons/duotune/communication/com007.svg'
            title='Chat'
            fontIcon='bi-layers'
          />
        )}

      {/* {listChatPhone.length > 1 && (
          <AsideMenuChat
            icon='/media/icons/duotune/communication/com007.svg'
            title='Chat'
            fontIcon='bi-layers'
          />
        )} */}

      {settings.feature_email && (user.data.is_superadmin || user?.data.company_id === companyId.tkd) && (
        <AsideMenuItem
          to='/email'
          icon='/media/icons/duotune/communication/com011.svg'
          title='Email'
          fontIcon='bi-layers'
        />
      )}
      {
        settings.feature_attendance && !user.data.is_superadmin && companies.companyFeatureLimitUserAttendance && user.data.attendance_template_id && (
          <AsideMenuItem
            to='/attendance'
            icon='/media/icons/duotune/general/gen013.svg'
            title={intl.formatMessage({ id: "MENU.ATTENDANCE" })}
            fontIcon='bi-layers'
          />
        )
      }
    </>
  )
}
