import Swal from "sweetalert2";
import { KTSVG } from "../../_metronic/helpers";
import { useChatStore } from "../../stores/ChatStore";
import { useIntl } from "react-intl";
import { clearDB, clearDBExceptIncomingMessageNotifications } from "../../db";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTokenCustomCompany } from "../../stores/useTokenCustomCompany";
import { deleteSessionChat } from "../../api/ChatCRUD";
import useAccessToken from "../../hooks/AccessToken";

const ChatAside = () => {
    const intl = useIntl()
    const token = useAccessToken()
    const { clearTokenAndNumber } = useTokenCustomCompany()
    const { menuActive, setMenuActive, tokenChat, selectedChatPhone, setTokenChat, setConnected } = useChatStore()
    const listMenu = [
        {
            id: "chat",
            name: "Chat",
            icon: "/media/icons/duotune/communication/com003.svg",
            to: "list-chat"
        },
        {
            id: "settings",
            name: "Pengaturan",
            icon: "/media/icons/duotune/coding/cod001.svg",
            to: "settings-chat"
        },
        {
            id: "info",
            name: "Info",
            icon: "",
            to: ""
        },
        {
            id: "refresh",
            name: "Refresh Data",
            icon: "",
            to: ""
        },
        {
            id: "logout",
            name: "Logout",
            icon: "",
            to: ""
        },
    ]

    const handleRefresh = () => {
        Swal.fire({
            title: intl.formatMessage({ id: "ALERT.TITLE.REFRESH_CHAT" }),
            text: intl.formatMessage({ id: "ALERT.TEXT.REFRESH_CHAT" }),
            icon: "question",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.YES" }),
            cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-active-light me-3',
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: async () => {
                await clearDBExceptIncomingMessageNotifications()
                window.location.reload()
            },
        })
    }

    const handleLogoutChat = () => {
        Swal.fire({
            title: intl.formatMessage({ id: "ALERT.TITLE.LOG_OUT" }),
            text: intl.formatMessage({ id: "ALERT.TEXT.LOG_OUT" }),
            icon: "warning",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: intl.formatMessage({ id: "FORM.ACTION.LOG_OUT" }),
            cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
            showLoaderOnConfirm: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-active-light',
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: async () => {
                try {
                    const response = await deleteSessionChat(selectedChatPhone[0].phone, token, tokenChat);
                    if (response.data.success) {
                        await clearDB();
                        setConnected(() => false);
                        setTokenChat(() => "");
                
                        window.location.reload();
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response?.data?.message || 'Terjadi kesalahan',
                        confirmButtonText: 'Ok',
                    });
                }
                
            },
        })
    }

    return (
        <div className='card card-flush py-5 justify-content-between' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)", width: "50px", backgroundColor: "#f4f7f9" }}>
            <div className="d-flex flex-column gap-3">
                {
                    listMenu.map((item, index) => {
                        return (
                            item.to &&
                            <div key={index} className={`menu-icon cursor-pointer align-self-center`} onClick={() => setMenuActive(item.id)}>
                                <OverlayTrigger
                                    key={`menu-${index}`}
                                    placement='right'
                                    overlay={
                                        <Tooltip id='tooltip-labels'>
                                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {item.name}
                                            </span>
                                        </Tooltip>}
                                >
                                    <span
                                        id={`menu-${index}`}
                                        className='cursor-pointer'
                                        data-toggle='tooltip'
                                        data-placement='right'
                                        title=''
                                    >
                                        <span key={index}>
                                            <KTSVG path={item.icon} className={`svg-icon-1 ${menuActive.includes(item.id) && "svg-icon-dark"}`} />
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        )
                    })
                }
            </div>
            <div className="d-flex flex-column gap-3">
                {
                    listMenu.map((item, index) => {
                        if ((item.id === 'info') && selectedChatPhone.length === 1) return <></>

                        return (
                            !item.to &&
                            <div key={index} className={`menu-icon cursor-pointer align-self-center`} onClick={(e) => item.id === 'refresh' ? handleRefresh() : item.id === 'logout' ? handleLogoutChat() : e.stopPropagation()}>
                                <OverlayTrigger
                                    key={`menu-${index}`}
                                    placement='right'
                                    overlay={
                                        <Tooltip id='tooltip-labels'>
                                            {
                                                item.id === 'info' ?
                                                    <>
                                                        {
                                                            selectedChatPhone.map((item, index) => {
                                                                return (
                                                                    <div className="mb-2 text-start">
                                                                        <span className="d-flex align-items-center gap-2">
                                                                            <span className="bullet bullet-dot h-10px w-10px" style={{ backgroundColor: item.color }}></span>
                                                                            <b>{item.phone}</b>
                                                                        </span>
                                                                        <span className="text-start d-block">{item?.ownersName.join(', ')}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                        {item.name}
                                                    </span>
                                            }
                                        </Tooltip>}
                                >
                                    <span
                                        id={`menu-${index}`}
                                        className='cursor-pointer'
                                        data-toggle='tooltip'
                                        data-placement='right'
                                        title=''
                                    >
                                        <span key={index}>
                                            {
                                                item.id === 'refresh' ?
                                                    <i className="fas fa-sync-alt fs-4 menu-icon"></i>
                                                    :
                                                    item.id === 'logout' ?
                                                        <i className="fas fa-sign-out-alt fs-4 menu-icon"></i>
                                                        :
                                                        <i className="fas fa-info-circle fs-4 menu-icon"></i>
                                            }
                                        </span>
                                    </span>
                                </OverlayTrigger>
                            </div>
                        )
                    })
                }
            </div >
        </div >
    )
}

export { ChatAside }