import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import { Merchant } from "../../interfaces/Merchant";
import { updateMerchant } from "../../api/MerchantsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import axios from "axios";
import useSWR, { mutate } from "swr";
import Select from "react-select";
import { City } from "../../interfaces/City";
import { nanoid } from "@reduxjs/toolkit";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, CustomFieldSerialProps } from "../../interfaces/Settings";
import moment from "moment";
import { insertMerchantFieldMultitAttachment, insertMerchantFieldtAttachment } from "../../api/ResourceAPI";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { DatePicker } from "rsuite";
import useUser from "../../hooks/User";
import { SelectOption } from "../../interfaces/SelectOption";
import { User } from "../../interfaces/User";
import { getDataById, handleCaps, isValidUrl } from "../../functions/general";
import { useIntl } from "react-intl";
import useTodayRange from "../../hooks/TodayRange";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useCustomeStore } from "../../stores/CustomeStore";
import { tailspin } from 'ldrs'
import { generateSerialApi } from "../../api/Generals";
import InputPhone from "../Input/InputPhone";
import { useMerchantStore } from "../../stores/MerchantStore";
import VirtualizedSelect, { Option, VirtualizedSelectOptionsType } from "../Select/SelectVirtualized";
import { useParams } from "react-router-dom";

interface Props {
    merchant: Merchant
}

interface CityOption {
    value: string;
    label: string;
}

const UpdateMerchant: FC<Props> = ({ merchant }) => {
    tailspin.register()
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
        const { templateMerchantId } = useParams()
    const todayRange = useTodayRange();
    const { companyId } = useCustomeStore()
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const [cityData, setCityData] = useState<CityOption[]>([]);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const { settings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});
    const user = useUser()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const { selectMultiMerchant, simpleMerchants, simpleMerchantLoading } = useMerchantStore()

    const intl = useIntl()

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getUserURL = () => {
        if (user.data.permission_merchant_create === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data = [], error, isLoading } = useSWR(`${API_URL}cities`, fetcher)
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (field.type === 'multiple' || field.type === 'images' || field.type === 'files' || field.type === 'merchant') {
            obj[item] = field.required
                ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else if (field.type === 'date') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        } else if (field.type === 'link') {
            obj[item] = field.required
                ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                })
                : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                });
        } else if (field.type === 'serial') {
            obj[item] = Yup.array().notRequired().nullable();
        } else {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        ownerId: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PIC" }) })).nullable(),
        name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })).nullable(),
        city: user.data.company_id === companyId.elgisa ? Yup.string().notRequired().nullable() : Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })).nullable(),
        email: Yup.string().email(intl.formatMessage({ id: "FORM.VALIDATION.EMAIL" })).notRequired().nullable(),
        labels: Yup.array().notRequired().nullable(),
        ...formSchema
    });
    const initialValues = {
        ownerId: merchant.ownerId,
        name: merchant.name,
        city: merchant.cityName,
        email: merchant.email,
        labels: merchant.labels.map((label) => { return label.id }),
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key,
            merchant.customFields && merchant.customFields[key] ?
                customFields[key].type === 'date' ?
                    moment(merchant.customFields[key], 'YYYY-MM-DD', true).isValid() ?
                        merchant.customFields[key]
                        : null
                    : merchant.customFields[key]
                : null]
        )),
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
            const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [item]: (values as Record<string, string | string[] | null>)[item],
                }),
                {}
            );

            const convertCustomFields: Record<string, string> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [`custom_fields.${item}`]: (values as Record<string, string | string[] | null>)[`custom_fields.${item}`] ?? "",
                }),
                {}
            );

            // Use Promise.all to await all async operations
            var countResourceError = 0
            await Promise.all(
                Object.keys(customFields).map(async (key) => {
                    if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                        try {
                            if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                const resourceResponse = await insertMerchantFieldtAttachment(values[key as keyof typeof values] as string | File);
                                if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                        try {
                            if (values[key as keyof typeof values]) {
                                var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                const resourceResponse = await insertMerchantFieldMultitAttachment(dataMultiAttachment);
                                if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'multiple') {
                        var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                        customFieldsValues[key] = multipleValues as string
                    } else if (customFields[key].type === 'serial') {
                        try {
                            if (dataCustomFieldSerial[key].value.length === 0) {
                                const serialResponse = await generateSerialApi(key, "Merchant", token);
                                customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                            } else {
                                customFieldsValues[key] = dataCustomFieldSerial[key].value
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'merchant') {
                        convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, simpleMerchants)?.map((item: any) => item.name).join(", ");
                    }
                })
            );

            if (countResourceError > 0) {
                Swal.fire({
                    icon: "error",
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_FAILED" })}.`,
                    heightAuto: false,
                    confirmButtonText: 'Ok'
                })
                setModalBtnLoading(false);
            } else {
                try {
                    const merchantLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []

                    const response = await updateMerchant(
                        merchantLabels,
                        values.ownerId,
                        merchant.id,
                        values.name,
                        values.city,
                        merchant.isWhatsappPhone,
                        token,
                        values.email ? values.email : null,
                        customFieldsValues
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            heightAuto: false,
                            title: intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_SUCCESS' }),
                            timer: 2000,
                            // target: document.getElementById(`update-merchant-modal-${merchant.id}`),
                            timerProgressBar: true
                        })
                        closeModalRef.current?.click();
                        mutate(`${API_URL}simple-merchants`)
                        mutate(`${API_URL}merchants${templateMerchantId ? `?merchantTemplateId=${templateMerchantId}` : ""}`)
                        setTablesData('merchants', (tablesData.merchants.map(item => {
                            if (item.id === merchant.id) {
                                return {
                                    ...item,
                                    ...convertCustomFields,
                                    name: response.data.data.name,
                                    cityName: response.data.data.cityName,
                                    ownerId: response.data.data.ownerId,
                                    ownerName: response.data.data.ownerName,
                                    phone: response.data.data.phone,
                                    email: response.data.data.email,
                                    customFields: response.data.data.customFields,
                                    labels: response.data.data.labels
                                }
                            } else {
                                return item
                            }
                        })));
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        // target: document.getElementById(`update-merchant-modal-${merchant.id}`),
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setModalBtnLoading(false)
                }
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();
        };

        const handleModalShown = () => {
            // Find the city object that matches sales.cityName
            const matchingCity = cityData.find(
                (city: CityOption) => city.label?.toLowerCase() === merchant.cityName?.toLowerCase()
            );

            // If a matching city is found, update the Formik form values
            if (matchingCity) {
                formik.setFieldValue("city", matchingCity.value);
            }

        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);
        modalElement?.addEventListener('shown.bs.modal', handleModalShown);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
            modalElement?.removeEventListener('shown.bs.modal', handleModalShown);
        };
    }, [formik, cityData, merchant.cityName]);

    useEffect(() => {
        if (!isLoading) {
            const cityData = data.map((city: City) => ({
                value: city.id,
                label: city.name
            }));
            cityData.sort((a: CityOption, b: CityOption) => a.label.localeCompare(b.label));
            setCityData(cityData);
        }
    }, [data]);

    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=merchant`, fetcher)


    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])


    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));

            if (user.data.permission_merchant_create !== 'ALL') {
                usersData.unshift({ value: user.data.id, label: user.data.name })
            }
            setUsersData(usersData)
        }
    }, [users])

    useEffect(() => {
        if (Object.keys(selectMultiMerchant).length !== 0) {
            var data = selectMultiMerchant.customFields
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: []
                };
                return acc;
            }, {});

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [selectMultiMerchant])


    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Merchant", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('update-merchant-modal' + merchant.id),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }

    const CustomSelectMerchantOption = (props: { option: VirtualizedSelectOptionsType; onClick?: () => void }) => {
        const { option, onClick } = props;

        return (
            <div className="d-flex align-items-center px-3 h-100" style={{ cursor: 'pointer', background: option.value === 'chocolate' ? '#d9d9d9' : 'transparent', }}>
                {user.data.company_id === companyId.esb ? (
                    <span className="align-middle"><b>{option.nameMerchant}</b> / {option.customFieldCompanyCode ? option.customFieldCompanyCode : "-"}</span>
                ) : (
                    <span className="align-middle">{option.nameMerchant}</span>
                )}
            </div>
        );
    };

    const CustomSelectMultiMerchantValue = (props: { value: Option; placeholder?: string }) => {
        const { value, placeholder } = props;
        if (!value?.value) return <span>{placeholder}</span>

        return (
            user.data.company_id === companyId.esb ?
                <span className="align-middle"><b>{value.nameMerchant}</b> / {value.customFieldCompanyCode ? value.customFieldCompanyCode : "-"}</span>
                :
                <span className="align-middle">{value.nameMerchant}</span>
        );
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);


    return (
        <div className="modal fade" tabIndex={-1} id={`update-merchant-modal-${merchant.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {merchant.name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            {/* Users */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.PIC' })}</label>
                            <div className="input-group mb-3">
                                {
                                    user.data.permission_merchant_update === 'TEAM' || user.data.permission_merchant_update === 'ALL' ?
                                        <Select
                                            key={
                                                window.location.pathname === '/chat' ? nanoid() : ''
                                            }
                                            options={usersData}
                                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PIC' })}`}
                                            value={usersData.find(option => option.value === formik.values.ownerId)}
                                            onChange={
                                                option => formik.handleChange({
                                                    target: {
                                                        name: "ownerId",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                            onBlur={
                                                () => formik.values.ownerId === "" && formik.setFieldTouched("ownerId", true)
                                            }
                                            className="w-100 z-index-3"
                                            isLoading={usersLoading}
                                        />
                                        :
                                        <div className="input-group mb-3">
                                            <input
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                )}
                                                type='text'
                                                name='ownerId'
                                                autoComplete='off'
                                                onInput={(e) => handleCaps(e)}
                                                value={merchant.ownerName}
                                                disabled
                                            />
                                        </div>
                                }
                            </div>
                            {formik.touched.ownerId && formik.errors.ownerId && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.ownerId}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                            {/* City */}
                            <label className={`form-label fs-6 fw-bolder text-dark ${user.data.company_id !== companyId.elgisa && "required"}`}>{intl.formatMessage({ id: 'FORM.LABEL.CITY' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    // key={nanoid()}
                                    options={cityData}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.CITY' })}`}
                                    value={cityData.find(option => option.value === formik.values.city)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "city",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.city === "" && formik.setFieldTouched("city", true)
                                    }
                                    className="w-100"
                                    isDisabled={isLoading}
                                />
                            </div>
                            {formik.touched.city && formik.errors.city && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.city}</span>
                                </div>
                            )}
                            {/* label */}
                            {
                                user.data.company_id !== companyId.esb &&
                                <>
                                    <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                    <div className="input-group mb-3">
                                        <Select
                                            closeMenuOnSelect={false}
                                            openMenuOnClick
                                            isMulti
                                            options={labelsOption}
                                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                            value={labelsOption.filter((option: any) =>
                                                formik.values.labels.includes(option.value)
                                            )
                                            }
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions
                                                    ? selectedOptions.map((option: any) => option.value)
                                                    : []
                                                formik.setFieldValue('labels', selectedValues)
                                            }}
                                            className='w-100'
                                            onBlur={() =>
                                                formik.values.labels.length == 0 &&
                                                formik.setFieldTouched('labels', true)
                                            }
                                        />
                                    </div>
                                    {formik.touched.labels && formik.errors.labels && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                        </div>
                                    )}
                                </>
                            }
                            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder='Email'
                                    {...formik.getFieldProps('email')}
                                    className={clsx(
                                        'form-control form-control-lg txt-email',
                                        { 'is-invalid': formik.touched.email && formik.errors.email }
                                    )}
                                    type='email'
                                    name='email'
                                    autoComplete='off'
                                />
                            </div>
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.email}</span>
                                </div>
                            )}
                            {
                                customFields && Object.keys(customFields).map((key, index) => {
                                    if (customFields[key].type === 'text') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        onInput={(e) => handleCaps(e)}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'serial') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                {
                                                    dataCustomFieldSerial[key].loading ?
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                            <l-tailspin
                                                                size="15"
                                                                stroke="3"
                                                                speed="0.9"
                                                                color="#ffffff"
                                                            ></l-tailspin>
                                                        </span>
                                                        :
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                }
                                                <div className="d-flex mb-3 gap-3">
                                                    <input
                                                        disabled
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                        className={clsx('form-control form-control-lg')}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'link') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg txt-link',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'phone') {
                                        return (
                                            <div>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <InputPhone keyString={key} formik={formik} type="update" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'image') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format JPG, JPEG, PNG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'images') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format JPG, JPEG, PNG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'file') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLS, XLSX, DOCX, TXT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'files') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLS, XLSX, DOCX, TXT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'date') {
                                        return (
                                            <>
                                                {/* Date */}
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        ranges={todayRange}
                                                        oneTap
                                                        editable={false}
                                                        format="dd MMM yyyy"
                                                        // value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]) : null}
                                                        value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values] as string) : null}
                                                        defaultValue={null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#update-merchant-modal-' + merchant.id) as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                            else formik.setFieldValue(key, null)
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'option') {

                                        const options = customFields[key].value.map((option, index) => ({
                                            value: option,
                                            label: option
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        key={nanoid()}
                                                        isClearable
                                                        options={options}
                                                        placeholder={`Pilih ${customFields[key].name}`}
                                                        value={options.find(option => option.value === formik.values[key as keyof typeof formik.values])}
                                                        onChange={
                                                            option => formik.handleChange({
                                                                target: {
                                                                    name: key,
                                                                    value: option?.value
                                                                }
                                                            })
                                                        }
                                                        onBlur={
                                                            () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                        }
                                                        className="w-100"
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'merchant') {
                                        var filteredMerchants = simpleMerchants
                                        if ((customFields[key].value as string[]).length > 0) {
                                            filteredMerchants = simpleMerchants.filter((merchant: Merchant) => (customFields[key].value as string[]).includes(merchant.merchantTemplateId))
                                        }

                                        const isEsbCompany = user.data.company_id === companyId.esb
                                        const options = filteredMerchants.map((option: Merchant) => ({
                                            value: option.id,
                                            label: `${option.name}${isEsbCompany ? " / " + (option.customFieldCompanyCode || "-") : ""}`,
                                            nameMerchant: option.name,
                                            customFieldCompanyCode: option.customFieldCompanyCode
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <VirtualizedSelect
                                                        isMulti={true}
                                                        options={options}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={
                                                            options.filter(option =>
                                                                formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                            )
                                                        }
                                                        onChange={(selectedOptions) =>
                                                            formik.setFieldValue(
                                                                key,
                                                                selectedOptions ? selectedOptions.map((option: any) => option.value) : []
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (formik.values[key as keyof typeof formik.values] === "") {
                                                                formik.setFieldTouched(key, true);
                                                            }
                                                        }}
                                                        className="w-100"
                                                        isLoading={simpleMerchantLoading}
                                                        isDisabled={simpleMerchantLoading}
                                                        components={
                                                            {
                                                                Option: CustomSelectMerchantOption,
                                                                MultiValue: CustomSelectMultiMerchantValue,
                                                            }
                                                        }
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'multiple') {
                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
                                                        {
                                                            customFields[key].value.map((option, index) => (
                                                                <div style={{ display: 'flex', padding: "3px" }}>
                                                                    <input
                                                                        key={index}
                                                                        {...formik.getFieldProps(key)}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={option as string}
                                                                        name={key}
                                                                        checked={formik.values[key as keyof typeof formik.values]?.includes(option as string)}
                                                                    />
                                                                    <label className="form-check-label me-2">{option}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    return null
                                })
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateMerchant }