import { FC } from "react";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { MasterPipelinesContent } from "./MasterPipelinesContent";
import { PageNoPermisson } from "../general/PageNoPermission";

const MasterPipelines: FC = () => {
    const { settings } = useSettingsStore()
    
    return (
        <>
            {
                settings.feature_pipeline ?
                    <MasterPipelinesContent />
                    :
                    <PageNoPermisson title={settings.pipeline_title} />
            }
        </>
    )
}

export { MasterPipelines }