import { FC, useEffect, useRef, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import OtpInput from 'react-otp-input';
import { useTimer } from "../../hooks/Timer";
import { formatCountdown } from "../../functions/general";
import { confirmOTP, resendOTP, sendOTP } from "../../api/OTP";
import { useDispatch } from "react-redux";
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { Modal } from "bootstrap";
import { resetPassword, userLogin } from "../../app/modules/auth/redux/AuthCRUD";
import useAccessToken from "../../hooks/AccessToken";
import Swal from "sweetalert2";
import { ChangePassword } from "../ChangePasswordModal";
import { agreeTNC, loginWooblazz, verifyWooblazz } from "../../api/ChatHelper";
import { useChatStore } from "../../stores/ChatStore";
import moment from "moment";
import { UAParser } from "ua-parser-js";
import { useIntl } from "react-intl";

interface OTPModalProps {
    username: string
    password: string
    phone: string
    type: string
    oldPassword?: string
    code?: string
}

const OTPModal: FC<OTPModalProps> = ({ username, password, phone, type, oldPassword, code }) => {
    const intl = useIntl()
    const [errorMessage, setErrorMessage] = useState<string>(intl.formatMessage({ id: "FORM.VALIDATION.INCORRECT_OTP_CODE" }))
    const { setAgreedTNC } = useChatStore()
    const modalRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);
    const dispatch = useDispatch()
    const token = useAccessToken()
    const [modalShown, setModalShown] = useState(false);
    const { countDown, resetCountDown } = useTimer(60, modalShown);
    const [isLoading, setIsLoading] = useState(false);
    const [codeOtp, setCodeOtp] = useState("");
    const [resToken, setResToken] = useState("");

    const [otp, setOtp] = useState<string>('');
    const [userOtp, setUserOtp] = useState<string>('');
    const [otpError, setOtpError] = useState(false);
    let parser = new UAParser();

    const hiddenButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (otp.length === 4 && type !== 'toc' && hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
            setOtp('')
        }
        if (otp.length === 6 && type === 'toc' && hiddenButtonRef.current) {
            hiddenButtonRef.current.click();
            setOtp('')
        }
    }, [otp]);

    const submitOTP = async (codeOtp: string, otp: string) => {
        setIsLoading(true)
        var device = parser.getBrowser().name + " (" + parser.getOS().name + ")"
        var os = parser.getOS().name

        if (parser.getBrowser().name === "Chrome") {
            device = `Google Chrome (${os})`
        } else if (parser.getBrowser().name === "Firefox") {
            device = `Mozilla Firefox (${os})`
        } else if (parser.getBrowser().name === "Safari") {
            device = `Apple Safari (${os})`
        } else if (parser.getBrowser().name === "Opera") {
            device = `Opera (${os})`
        } else if (parser.getBrowser().name === "Edge") {
            device = `Microsoft Edge (${os})`
        }

        try {
            if (type === "dashboard") {
                const response = await resetPassword(username, password, otp, token);
                if (response.data.success) {
                    closeButtonRef.current?.click();
                    Swal.fire({
                        title: 'Success',
                        text: intl.formatMessage({ id: "FORM.VALIDATION.SUCCESS" }, { title: intl.formatMessage({ id: "FORM.LABEL.PASSWORD" }) }),
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            } else if (type === "login") {
                const response = await userLogin(username, password, otp, device);
                if (response.data.success) {
                    if (response.data.data.date_activated == "") {
                        setResToken(response.data.data.token)
                        setUserOtp(otp)
                        const modalElement = document.getElementById(`modal-change-password`);

                        if (modalElement) {
                            const modalInstance = new Modal(modalElement);
                            modalInstance.show();
                        }
                    } else {
                        dispatch(auth.actions.login(response.data.data.token))
                    }
                    closeButtonRef.current?.click();
                }
            } else if (type === "toc") {
                try {
                    const response = await verifyWooblazz(phone, otp);
                    if (response.data.success) {
                        try {
                            const response = await agreeTNC(token);
                            if (response.data.success) {
                                closeButtonRef.current?.click();
                                setAgreedTNC(moment().format('YYYY-MM-DD HH:mm:ss'))
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } catch (error: any) {
                    setOtpError(true)
                    setErrorMessage(error.response.data.message)
                }
            }
        } catch (error) {
            setOtpError(true)
        } finally {
            setIsLoading(false)
        }
    }

    const btnResendOTP = async (codeOtp: string) => {
        try {
            if (type == "login") {
                setIsLoading(true)
                try {
                    const response = await sendOTP(username, password);
                    if (response.data.success) {
                        resetCountDown()
                        // setCodeOtp(response.data.data.otp_code)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setIsLoading(false)
                }
            } else if (type == "dashboard" && oldPassword) {
                setIsLoading(true)
                try {
                    const response = await sendOTP(username, oldPassword);
                    if (response.data.success) {
                        resetCountDown()
                        // setCodeOtp(response.data.data.otp_code)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setIsLoading(false)
                }
            } else {
                const response = await loginWooblazz(phone)
                if (response.data.success) {
                    resetCountDown()
                    setCodeOtp(response.data.data.otp_code)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        const handleModalShow = () => {
            setModalShown(true);
        };

        // Define the event listener
        const handleModalHidden = () => {
            setOtp('');
            setOtpError(false);
            resetCountDown();
            setModalShown(false);
        };

        // Attach the event listener
        modalElement?.addEventListener('show.bs.modal', handleModalShow);
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('show.bs.modal', handleModalShow);
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [modalRef]);

    return (
        <>
            <div className="modal fade" data-bs-backdrop="static" tabIndex={-1} id={`otp-modal-${type}`} ref={modalRef} style={{ zIndex: "9999" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.ENTER_OTP" })} {type}</h4>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="text-center mb-10">
                                {
                                    isLoading &&
                                    <div className="loading-overlay">
                                        <div className="loader"></div>
                                    </div>
                                }
                                {otpError &&
                                    <div className='mb-lg-15 alert alert-danger w-50 mx-auto'>
                                        <h5 className='alert-text font-weight-bold'>{errorMessage}</h5>
                                    </div>
                                }
                                <img alt="Logo" className="mh-125px" src={toAbsoluteUrl("/media/svg/misc/smartphone.svg")} />
                                <p className="fs-5 p-5 pb-0">
                                    <strong>{intl.formatMessage({ id: "FORM.LABEL.ENTER_VERIFICATION_OTP" })}</strong>
                                </p>
                                {
                                    type === 'toc' ?
                                        <div className="mb-3">
                                            <label className='form-label fw-bolder text-dark fs-3 mb-0'>{phone?.toString().slice(0, -3).replace(/\d/g, '*') + phone?.toString().slice(-3)}</label>
                                        </div>
                                        :
                                        <div className="mb-3">
                                            <label className='form-label fw-bolder text-dark fs-3 mb-0'>**********</label>
                                            <label className='form-label fw-bolder text-dark fs-3 mb-0'>{phone.replaceAll("*", "")}</label>
                                        </div>

                                }
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={type !== 'toc' ? 4 : 6}
                                    inputType="tel"
                                    renderSeparator={<span></span>}
                                    containerStyle={{ justifyContent: 'center' }}
                                    renderInput={(props) =>
                                        <input
                                            {...props}
                                            maxLength={1}
                                            className="form-control form-control-solid h-60px mw-60px fs-2hx text-center border-active-primary mx-1 my-2"
                                            style={{ width: '16%' }}
                                            disabled={isLoading}
                                        />}
                                />
                                <div className="text-center fw-bold fs-5 mt-5">
                                    <span className="text-muted me-1">{intl.formatMessage({ id: "FORM.LABEL.NOT_RECEIVE_CODE" })}</span>
                                    <span
                                        className="link-primary fw-bolder fs-5 me-1"
                                        style={{ cursor: countDown > 0 ? 'not-allowed' : 'pointer' }}
                                        id={`resendOTP-${codeOtp}`}
                                        role="button"
                                        tabIndex={countDown ? -1 : 0}
                                        onClick={countDown ? undefined : () => {
                                            btnResendOTP(codeOtp);
                                        }}
                                        data-kt-indicator={isLoading ? 'on' : 'off'}
                                    >
                                        <span className="indicator-label">{countDown === 0 ? intl.formatMessage({ id: "FORM.ACTION.RESEND" }) : `${formatCountdown(countDown)}`}</span>
                                        <span className="indicator-progress">
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </span>
                                    <span className="text-primary fw-bolder me-1" id="counter">
                                    </span>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-light d-none"
                            data-bs-dismiss="modal"
                            ref={closeButtonRef}
                        >
                        </button>
                        <button type="button" className="d-none" onClick={() => { submitOTP(codeOtp, otp) }}
                            {...otp.length !== 4 && type !== 'toc' && { disabled: true }}
                            {...otp.length !== 6 && type === 'toc' && { disabled: true }}
                            ref={hiddenButtonRef}
                        >
                        </button>
                    </div>
                </div>
            </div>

            <ChangePassword type={"login"} firstToken={resToken} username={username} />
        </>
    )
}

export { OTPModal }