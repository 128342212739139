import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";
import { Conference } from "../../../interfaces/Conference";
import { AddConference } from "../../../components/Conference/AddConference";
import { customNumberFormat } from "../../../functions/general";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import useSWR from "swr";
import moment from 'moment'
import { createConference } from "../../../api/ConferenceCRUD";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { MasterScheduleConferences } from "./MasterScheduleConferences";

const MasterConferences: FC = () => {
    const user = useUser();
    const intl = useIntl();
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const [displayMode, setDisplayMode] = useState<string>("table");
    const API_URL = process.env.REACT_APP_API_URL

    const [totalRooms, setTotalRooms] = useState<number>(0);
    const [limitRooms, setLimitRooms] = useState<number>(0);
    const [url, setUrl] = useState(API_URL === 'https://sales-api.looyal.id/' ? 'https://vc-dev.wooblazz.com/' : 'https://vc.wooblazz.com/');

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)
    const { data: limit, isLoading, error } = useSWR(API_URL + 'conference/company-room-limit?companyId=' + user.data.company_id, fetcher)

    const handleStartConference = async (data: Conference) => {
        Swal.fire({
            title: 'Loading',
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        try {
            const response = await createConference(data.roomCode, user.data.id, user.data.name, user.data.company_id, data.roomCategory, "", "", token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    // text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SESSION_SUCCESS" }),
                    heightAuto: false,
                })

                setTablesData('conferences', (tablesData.conferences.map(item => {
                    if (item.roomCode === data.roomCode) {
                        return {
                            ...item,
                            roomStatus: "ACTIVE",
                        }
                    } else {
                        return item
                    }
                })));

                handleJoinConference(data.roomCode)
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        }
    }

    const handleJoinConference = (roomCode: string) => {
        window.open(`${url}company/${user.data.id}/${roomCode}`, "_blank");
    }

    const tableColumns: TableColumn<Conference>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '160px',
            cell: (row) => {
                if (row.roomStatus === "INACTIVE" && row.roomCategory === "NON_SCHEDULE") {
                    return (
                        <button className="btn btn-sm btn-primary" onClick={() => handleStartConference(row)}>
                            <span>{intl.formatMessage({ id: 'FORM.ACTION.START' })}</span>
                        </button>
                    )
                } else if (row.roomStatus === "ACTIVE" && row.roomCategory === "NON_SCHEDULE") {
                    return (
                        <button className="btn btn-sm btn-success" onClick={() => handleJoinConference(row.roomCode)}>
                            <span>{intl.formatMessage({ id: 'FORM.ACTION.JOIN' })}</span>
                        </button>
                    )
                } else {
                    return "-"
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROOM_CODE' }),
            width: '25%',
            selector: row => row.roomCode,
            sortable: true,
            cell: row => row.roomCode
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SESSION_CREATOR' }) + " (Host)",
            width: '25%',
            selector: row => row.SessionCreatorName,
            sortable: true,
            cell: row => row.SessionCreatorName === "" ? "-" : row.SessionCreatorName
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TYPE' }),
            selector: row => row.roomCategory,
            sortable: true,
            cell(row) {
                if (row.roomCategory === "SCHEDULE") {
                    return intl.formatMessage({ id: 'FORM.LABEL.SCHEDULED' })
                } else {
                    return intl.formatMessage({ id: 'FORM.LABEL.INSTANT' })
                }
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROOM_STATUS' }),
            selector: row => row.roomStatus,
            sortable: true,
            cell(row) {
                if (row.roomStatus === "ACTIVE") {
                    return <span className="badge badge-light-success" >{intl.formatMessage({ id: 'CONFERENCE.ROOM_STATUS.ACTIVE' })}</span>
                } else {
                    return <span className="badge badge-light-danger" >{intl.formatMessage({ id: 'CONFERENCE.ROOM_STATUS.INACTIVE' })}</span>
                }
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_PARTICIPANTS' }),
            selector: row => row.countUserRoom,
            sortable: true,
            cell: row => row.countUserRoom
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED_ROOM' }),
            width: '200px',
            selector: row => row.dateCreated,
            sortable: true,
            cell: row => moment(row.dateCreated).locale('id').format("DD MMM YYYY HH:mm")
        },
    ];

    useEffect(() => {
        if (!isLoading && limit) {
            setTotalRooms(Number(limit.totalRooms))
            setLimitRooms(Number(limit.limitRooms))
        }
    }, [limit, isLoading])

    let actions: ReactNode[] = []
    if (user.data.is_superadmin) {
        actions.push(<AddConference totalRooms={totalRooms} limitRooms={limitRooms} setTotalRooms={setTotalRooms} />)
    }

    return (
        <>
            <div className="row mb-2" style={{ zIndex: "3", flexDirection: "row", marginTop: "-10px" }}>
                <div className="col-sm-3 col-lg-4 col-xl-5">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                        <li className="nav-item cursor-pointer" onClick={() => { setDisplayMode("table"); }}>
                            <div className={`nav-link text-active-primary ms-0 me-5 ${displayMode === "table" ? "active" : ""}`}>{intl.formatMessage({ id: "FORM.LABEL.TABLE" })}</div>
                        </li>
                        <li className="nav-item cursor-pointer" onClick={() => { setDisplayMode("calendar"); }}>
                            <div className={`nav-link text-active-primary ms-0 me-5 ${displayMode === "calendar" ? "active" : ""}`}>{intl.formatMessage({ id: "FORM.LABEL.CALENDAR" })}</div>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-9 col-lg-8 col-xl-7 pt-1">
                    <div className="d-flex align-items-center w-250px flex-column float-end">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                            <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'FORM.LABEL.CONFERENCE_ROOM_LIMIT' })}</span>
                            <span className="fw-bolder fs-6">{customNumberFormat(totalRooms)} / {customNumberFormat(limitRooms)}</span>
                        </div>
                        <div className="h-5px mx-3 w-100" style={{ backgroundColor: "white" }}>
                            <div className="bg-success rounded h-5px" role="progressbar" style={{ width: Math.round(totalRooms / limitRooms * 100) + "%" }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row card px-5" style={{ zIndex: "3", flexDirection: "row", margin: "0px 0px -20px 0px" }}>
                <div className="col-sm-3 col-lg-4 col-xl-5">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
                        <li className="nav-item mt-2 cursor-pointer" onClick={() => { setDisplayMode("table"); }}>
                            <div className={`nav-link text-active-primary ms-0 me-10 ${displayMode === "table" ? "active" : ""}`}>{intl.formatMessage({ id: "FORM.LABEL.TABLE" })}</div>
                        </li>
                        <li className="nav-item mt-2 cursor-pointer" onClick={() => { setDisplayMode("calendar"); }}>
                            <div className={`nav-link text-active-primary ms-0 me-10 ${displayMode === "calendar" ? "active" : ""}`}>{intl.formatMessage({ id: "FORM.LABEL.CALENDAR" })}</div>
                        </li>
                    </ul>
                </div>
                <div className="col-sm-9 col-lg-8 col-xl-7 pt-3">
                    <div className="d-flex align-items-center w-250px flex-column float-end">
                        <div className="d-flex justify-content-between w-100 mt-auto">
                            <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'FORM.LABEL.CONFERENCE_ROOM_LIMIT' })}</span>
                            <span className="fw-bolder fs-6">{customNumberFormat(totalRooms)} / {customNumberFormat(limitRooms)}</span>
                        </div>
                        <div className="h-5px mx-3 w-100" style={{ backgroundColor: "#f5f8fa" }}>
                            <div className="bg-success rounded h-5px" role="progressbar" style={{ width: Math.round(totalRooms / limitRooms * 100) + "%" }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className={`${displayMode === "table" ? "" : "d-none"}`}>
                <MasterDataTable
                    tableKey="conferences"
                    tableColumns={tableColumns}
                    actions={actions}
                    apiURL={`conference/company-room-list?companyId=${user.data.company_id}`}
                />
            </div>
            <div className={`${displayMode === "calendar" ? "" : "d-none"}`} style={{ height: "100%" }}>
                <MasterScheduleConferences />
            </div>
        </>
    )
}

export { MasterConferences }