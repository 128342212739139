import {FC, ReactNode, useEffect, useRef, useState} from 'react'

import {useIntl} from 'react-intl'

import axios from 'axios'
import useAccessToken from '../../../hooks/AccessToken'
import useSWR from 'swr'

import AddTemplate from '../../../components/Analytics/Template/AddTemplate'
import {ISection, ITemplateAnalytic} from '../../../interfaces/Analytics/Analytics'
import clsx from 'clsx'
import {Dropdown} from 'rsuite'
import CardTemplate from '../../../components/Analytics/Template/CardTemplate'
import { useAnalyticsStore } from '../../../stores/Analytics/AnalyticStore'
import UpdateTemplate from '../../../components/Analytics/Template/UpdateTemplate'
import useModalEffect from '../../../hooks/useModalEffect'
import { DeleteTemplate } from '../../../components/Analytics/Template/DeleteTemplate'

export declare type ValueType = [Date?, Date?]
export declare type DateRange = [Date, Date]
export interface RangeType {
  label: React.ReactNode
  value: DateRange | ((value?: ValueType) => DateRange)
  closeOverlay?: boolean
  placement?: 'bottom' | 'left'
}

const AnalyticsTemplate: FC = () => {
  const intl = useIntl()
  const token = useAccessToken()

  const API_URL = process.env.REACT_APP_API_URL

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        throw error.response ? error.response.data : error
      })

  const {
    data: analyticsTemplateData = [],
    error: analyticsTemplateError,
    isLoading: analyticsTemplateLoading,
    isValidating: analyticsTemplateValidating,
  } = useSWR(`${API_URL}analytics/template`, fetcher)


  const {templatesAnalytic, showUpdateTemplateModal, templateAnalytic, setShowUpdateTemplateModal, showDeleteTemplateModal, setShowDeleteTemplateModal}= useAnalyticsStore()

  const [isMobile, setIsMobile] = useState<boolean>(false)

  useModalEffect({
    showModal: showUpdateTemplateModal,
    setShowModal: setShowUpdateTemplateModal,
    modalId: `update-template-modal-${templateAnalytic?.id}`,
  })
  useModalEffect({
    showModal: showDeleteTemplateModal,
    setShowModal: setShowDeleteTemplateModal,
    modalId: `delete-template-modal-${templateAnalytic?.id}`,
  })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 666) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }

    // Initial check
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <div className='row justify-content-between mb-3'>
        <div className='col-sm-3 col-lg-4 col-xl-5' style={{alignSelf: 'center'}}>
          <ol className='breadcrumb text-muted fs-6 fw-bold'>
            <li className='breadcrumb-item text-dark'>
              {intl.formatMessage({id: 'MENU.ANALYTICS'})}
            </li>
          </ol>
        </div>
        <div className='col-sm-9 col-lg-8 col-xl-7'>
          <div className='d-flex px-3 float-end gap-2'>
            <AddTemplate />
          </div>
        </div>
      </div>
      {analyticsTemplateLoading && (
        <div className='loading-overlay'>
          <div className='loader'></div>
        </div>
      )}

      <div className='d-flex gap-5 w-100 h-100 p-8 card my-8 flex-wrap flex-row '>
        {!analyticsTemplateLoading &&
          templatesAnalytic.map((template: ITemplateAnalytic) => {
            return <CardTemplate template={template} />
          })}
        {!analyticsTemplateLoading && templatesAnalytic.length <= 0 && (
          <>
          <div className='w-100 h-100 d-flex justify-content-center align-items-center gap-4 flex-column'>
            <i className='bi bi-emoji-frown' style={{fontSize: '12rem'}} />{' '}
            <span style={{fontSize: '1.5rem', color: '#A1A5B7'}}>
              {intl.formatMessage(
                {id: 'FORM.CONFIRM.DATA_NOT_FOUND'},
                {
                  title: intl.formatMessage(
                    {id: 'FORM.LABEL.TEMPLATE_TITLE'},
                    {title: intl.formatMessage({id: 'MENU.ANALYTICS'})}
                  ),
                }
              )}
            </span>
            </div>
          </>
        )}
      </div>
      {showUpdateTemplateModal && <UpdateTemplate template={templateAnalytic!}/>}
      {showDeleteTemplateModal && <DeleteTemplate template={templateAnalytic!}/>}
    </>
  )
}

export {AnalyticsTemplate}
