import { FC, useEffect, useRef, useState } from "react"
import { exportExcel } from "../../functions/exportExcel"
import { useDataTableStore } from "../../stores/DataTableStore"
import { useSettingsStore } from "../../stores/SettingsStore"
import { Pipeline } from "../../interfaces/Pipeline"
import { TableColumn } from "react-data-table-component"
import { Merchant } from "../../interfaces/Merchant"
import { Meeting } from "../../interfaces/Meeting"
import { ResponseSuccessValidationPhoneProp } from "../../interfaces/ValidationPhone"
import { UserModel } from "../../app/modules/auth/models/UserModel"
import { KTSVG } from "../../_metronic/helpers"
import * as ExcelJS from 'exceljs';
import { FileUploader } from "react-drag-drop-files";
import { useFormik } from "formik"
import { DataTableExport } from "./DataTableExport"
import useUser from "../../hooks/User"
import Swal from "sweetalert2"
import { DataTableExportTemplate } from "./DataTableExportTemplate"
import useAccessToken from "../../hooks/AccessToken"
import { getAttendanceTemplates, getCities, getLeaders, getRoles } from "../../api/General"
import { getUsers, insertUser, updateUser } from "../../api/UsersCRUD"
import { checkWhatsapp } from "../../api/Whatsapp"
import { insertMerchant, getMerchants, insertMerchantWithTemplate } from "../../api/MerchantsCRUD"
import { Dropdown, Message, toaster } from "rsuite"
import { CustomField } from "../../interfaces/Settings"
import moment from "moment";
import { isEmail, isFileUrl, isImageUrl, isValidUrl, normalizeDate, randomColor } from "../../functions/general"
import { User } from "../../interfaces/User"
import { useIntl } from "react-intl"
import { insertLeadViaExcel } from "../../api/PipelineCRUD"
import { getAccessProductPipeline, getProducts, insertProduct, updateProduct } from "../../api/ProductsCRUD"
import { getAccessProgressesPipeline, getProgresses } from "../../api/ProgressesCRUD"
import { getLeadSources } from "../../api/SourcesCRUD"
import useSWR, { mutate } from "swr"
import { useCustomeStore } from "../../stores/CustomeStore"
import { Unit } from "../../interfaces/Unit"
import { getUnit, insertUnit, updateUnit } from "../../api/UnitsCRUD"
import { Product } from "../../interfaces/Product"
import { getLabel } from "../../api/LabelsCRUD"
import { usePipelineStore } from "../../stores/PipelineStore"
import { useParams } from "react-router-dom"
import { useProgressStore } from "../../stores/ProgressStore"
import { LeadProgress } from "../../interfaces/Progress"
import { useMerchantStore } from "../../stores/MerchantStore"
import useHandleImportPipeline from "./HandleImport/useHandleImportPipeline"
import useHandleImportMerchant from "./HandleImport/useHandleImportMerchant"
import useHandleImportProduct from "./HandleImport/useHandleImportProduct"


const renderButton = (props: any, ref: any, displayMode: string) => {
  return (
    <button
      {...props}
      ref={ref}
      type='button'
      className={`btn btn-light-success ${displayMode === 'card' ? 'btn-sm' : ' mt-3'}`}
    >
      <i className='fas fa-file-download fs-2 me-2'></i> Impor Excel
    </button>
  )
}

const INDEX_NOT_FOUND = -1

interface DataTableImportProps {
  source: 'template-merchant' | 'template-user' | 'template-pipeline' | 'template-unit' | 'template-product',
  columns?: TableColumn<Merchant>[] | TableColumn<User>[] | TableColumn<Pipeline>[] | TableColumn<Unit>[] | TableColumn<Product>[],
  displayMode?: string
}

const API_URL = process.env.REACT_APP_API_URL

const DataTableImport: FC<DataTableImportProps> = ({ source, columns, displayMode }) => {
  const {progress, setProgress} = useProgressStore()
  const { tablesData, setTablesData, setFetchCounter, fetchCounter } = useDataTableStore()
  const { multiPipelines, selectMultiPipeline } = usePipelineStore()
  const { multiMerchants, selectMultiMerchant } = useMerchantStore()
  const { settings } = useSettingsStore()
  const { companyId, roleId } = useCustomeStore()
  const { templatePipelineId, templateMerchantId } = useParams()
  const user = useUser()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const token = useAccessToken()
  const [indexUpload, setIndexUpload] = useState(0)
  const [listData, setListData] = useState<any>([])
  const [dataUsers, setDataUsers] = useState([])
  const [dataCities, setDataCities] = useState([])
  const [dataRoles, setDataRoles] = useState([])
  const [dataLeaders, setDataLeaders] = useState([])
  const [dataAttendanceTemplates, setDataAttendanceTemplates] = useState([])
  const [dataMerchants, setDataMerchants] = useState([])
  const [dataProducts, setDataProducts] = useState([])
  const [dataProgress, setDataProgress] = useState<LeadProgress[] | []>([])
  const [dataSources, setDataSources] = useState([])
  const [dataLabels, setDataLabels] = useState([])
  const [dataUnits, setDataUnits] = useState([])
  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false)
  const [isLoadingCity, setIsLoadingCity] = useState<boolean>(false)
  const [isLoadingRole, setIsLoadingRole] = useState<boolean>(false)
  const [isLoadingLeader, setIsLoadingLeader] = useState<boolean>(false)
  const [isLoadingAttendanceTemplate, setIsLoadingAttendanceTemplate] = useState<boolean>(false)
  const [isLoadingMerchant, setIsLoadingMerchant] = useState<boolean>(false)
  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false)
  const [isLoadingProgres, setIsLoadingProgres] = useState<boolean>(false)
  const [isLoadingSource, setIsLoadingSource] = useState<boolean>(false)
  const [isLoadingLabel, setIsLoadingLabel] = useState<boolean>(false)
  const [isLoadingUnit, setIsLoadingUnit] = useState<boolean>(false)
  const [customFields, setCustomFields] = useState<CustomField>({})
  const [listErrorInsert, setListErrorInsert] = useState<any>([])
  const [listErrorUpdate, setListErrorUpdate] = useState<any>([])
  const [typeAction, setTypeAction] = useState<"insert" | "update">("insert")
  const intl = useIntl()
  let dataSuccess: any[] = []
  let dataError: any[] = []
  const { handleChangePipeline, handleInsertPipeline } = useHandleImportPipeline({
    listData, setListData, customFields, templatePipelineId: templatePipelineId ?? "", dataUsers, dataMerchants, dataProgress, dataProducts, dataSources, dataError, dataSuccess, selectMultiPipeline
  })
  const { handleChangeMerchant, handleInsertMerchant } = useHandleImportMerchant({
    customFields, setListData, templateMerchantId: templateMerchantId ?? "", dataUsers, dataCities, selectMultiMerchant, dataSuccess, dataError
  })
  const { handleChangeProduct, handleInsertProduct, handleUpdateProduct } = useHandleImportProduct({
    setListData, dataLabels, dataUnits, dataSuccess
  })

  let filteredCustomFieldUsers = Object.fromEntries(
    Object.entries(customFields).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files';
    })
  );

  let filteredCustomFieldMerchants = Object.fromEntries(
    Object.entries(customFields).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'user' && value.type !== 'merchant' && value.type !== 'pipeline'
    })
  );

  let filteredCustomFieldPipelines = Object.fromEntries(
    Object.entries(customFields).filter(([key, value]) => {
      return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'user' && value.type !== 'merchant' && value.type !== 'pipeline'
    })
  );

  const renderButton = (props: any, ref: any, displayMode: string) => {
    return (
      <button
        {...props}
        ref={ref}
        type='button'
        className={`btn btn-light-success ${displayMode === 'card' ? 'btn-sm' : ' mt-3'}`}
      >
        <i className='fas fa-file-download fs-2 me-2'></i>{' '}
        {intl.formatMessage({ id: 'FORM.LABEL.IMPORT_EXCEL' })}
      </button>
    )
  }

  // GET DATA FORM API
  const getUserURL = () => {
    if (user.data.permission_merchant_create === 'ALL') {
      return `${API_URL}users`
    } else {
      return `${API_URL}user/subordinates`
    }
  }

  const getUser = async () => {
    try {
      const response = await getUsers(token, getUserURL())
      if (response.data.success) {
        var data = response.data.data

        if (user.data.permission_merchant_create !== 'ALL') {
          data.unshift({ id: user.data.id, name: user.data.name })
        }

        data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        if (
          user.data.permission_merchant_create === 'ALL' ||
          user.data.permission_merchant_create === 'TEAM'
        ) {
          setDataUsers(data)
        } else {
          setDataUsers(data.filter((item: any) => item.id === user.data.id))
        }
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getCity = async () => {
    try {
      const response = await getCities(token)
      if (response.data.success) {
        var data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        setDataCities(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getRole = async () => {
    try {
      const response = await getRoles(token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        setDataRoles(data)
      }
    } catch (error: any) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getLeader = async () => {
    try {
      const response = await getLeaders(token)
      if (response.data.success) {
        var data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        setDataLeaders(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getAttendanceTemplate = async () => {
    try {
      const response = await getAttendanceTemplates(token)
      if (response.data.success) {
        var data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name))
        setDataAttendanceTemplates(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    }
  }

  const getMerchant = async () => {
    try {
      const response = await getMerchants(token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataMerchants(data)
      }
    } catch (error: any) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    }
  }

  const getProduct = async () => {
    setIsLoadingProduct(true)
    try {
      const response = await getAccessProductPipeline(templatePipelineId ?? "", token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataProducts(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsLoadingProduct(false)
    }
  }

  const getProgres = async () => {
    try {
      const response = await getAccessProgressesPipeline(templatePipelineId ?? "", token)
      if (response.data.success) {
        let data = response.data.data
        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataProgress(data)
      }
    } catch (error: any) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    }
  }

  const getSources = async () => {
    try {
      const response = await getLeadSources(token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataSources(data)
      }
    } catch (error: any) {
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    }
  }

  const getLabels = async () => {
    setIsLoadingLabel(true)
    try {
      const response = await getLabel(token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataLabels(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsLoadingLabel(false)
    }
  }

  const getUnits = async () => {
    setIsLoadingUnit(true)
    try {
      const response = await getUnit(token)
      if (response.data.success) {
        let data = response.data.data

        data.sort((a: any, b: any) => a.name.localeCompare(b.name));
        setDataUnits(data)
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok'
      })
    } finally {
      setIsLoadingUnit(false)
    }
  }

  // FORM IMPORT EXCEL
  const formik = useFormik({
    initialValues: {
      data: '',
    },
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      dataError = [];
      setSubmitting(true);

      if (source === 'template-pipeline') {
        try {
          await handleCheckExcelNew(listData, 200);
        } catch (error: any) {
          if (error.response.data) {
            dataError = listData.map((data: any) => ({
              ...data,
              error: error.response.data.message,
            }));
          } else {
            dataError = listData.map((data: any) => ({
              ...data,
              error: error.message,
            }));
          }
        } finally {
          // getPipelines(token)
          mutate(`${API_URL}v2/leads`);
          setFetchCounter(fetchCounter + 1)
        }
      } else {
        for (var i = 0; i < listData.length; i++) {
          try {
            setIndexUpload(i);
            await handleCheckExcel(listData[i]);
          } catch (error: any) {
            if (error.response.data) {
              let mergeError = {
                ...listData[i],
                error: error.response.data.message,
              };
              dataError.push(mergeError);
            } else {
              let mergeError = { ...listData[i], error: error.message };
              dataError.push(mergeError);
            }
          }
        }
      }

      if (typeAction === 'insert') setListErrorInsert(dataError)
      else if (typeAction === 'update') setListErrorUpdate(dataError)
      setListData([])
      setSubmitting(false);

      if (source === 'template-merchant') setTablesData('merchants', ([...dataSuccess, ...tablesData.merchants]));
      else if (source === 'template-user') {
        if (typeAction === 'insert') setTablesData('users', ([...dataSuccess, ...tablesData.users]));
        else if (typeAction === 'update') {
          setTablesData('users', (tablesData.users.map(item => {
            var dataSame = dataSuccess.find((data) => data.id === item.id)

            if (dataSame) {
              return {
                ...item,
                name: dataSame.name,
                nik: dataSame.nik,
                city_id: dataSame.city_id,
                city_name: dataSame.city_name,
                role_id: dataSame.role_id,
                role_name: dataSame.role_name,
                leader_id: dataSame.leader_id,
                leader_name: dataSame.leader_name,
                attendanceTemplateId: dataSame.attendanceTemplateId,
                attendanceTemplateName: dataSame.attendanceTemplateName,
              }
            } else {
              return item
            }
          })));
        }
      }
      else if (source === 'template-unit') {
        if (typeAction === 'insert') {
          let slicedData = [...tablesData.units].slice(0, -1) // remove PCS (default) data
          setTablesData('units', ([...slicedData, ...dataSuccess, {
            name: "PIECES",
            alias: "PCS"
          }]))
        }
        else if (typeAction === 'update') {
          setTablesData('units', (tablesData.units.map(item => {
            var dataSame = dataSuccess.find((data) => data.id === item.id)

            if (dataSame) {
              return {
                ...item,
                name: dataSame.name,
                alias: dataSame.alias,
                color: dataSame.color,
              }
            } else {
              return item
            }
          })));
        }
      }
      else if (source === 'template-product') {
        if (typeAction === 'insert') setTablesData('products', ([...tablesData.products, ...dataSuccess]))
        else if (typeAction === 'update') {
          setTablesData('products', (tablesData.products.map(item => {
            var dataSame = dataSuccess.find((data) => data.id === item.id)

            if (dataSame) {
              return {
                ...item,
                name: dataSame.name,
                price: dataSame.price,
                vatPercentage: dataSame.vatPercentage,
                color: dataSame.color,
                productLabels: dataSame.productLabels,
                productUnits: dataSame.productUnits,
                sku: dataSame.sku,
                note: dataSame.note
              }
            } else {
              return item
            }
          })));
        }
      }
      if (dataSuccess.length > 0) {
        Swal.fire({
          icon: 'success',
          title: intl.formatMessage({ id: 'ALERT.TITLE.SUCCESS' }),
          html: `<b>${dataSuccess.length} Data</b> ${typeAction === 'insert'
            ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_SUCCESS' })
            : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_SUCCESS' })
            }.
                <br>
                ${dataError.length > 0
              ? `<b style="color: red;">${dataError.length} Data </b>${typeAction === 'insert'
                ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_FAILED' })
                : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_FAILED' })
              }`
              : ''
            }
                `,
          heightAuto: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
          html: `<b>${dataError.length} Data</b> ${typeAction === 'insert' ? intl.formatMessage({ id: 'FORM.VALIDATION.ADD_FAILED' }) : intl.formatMessage({ id: 'FORM.VALIDATION.UPDATE_FAILED' })}.`,
          heightAuto: false,
        });
      }

      if (dataError.length === 0) {
        closeModalRef.current?.click();
      }
    }
  });

  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listeners
    const handleModalHidden = () => {
      formik.resetForm()
      setListData([])
      dataSuccess = []
    }

    // Attach the event listeners
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  const handleCheckExcel = async (data: any) => {
    const customFieldErrors: string[] = []
    if (source === 'template-merchant') {
      Object.keys(filteredCustomFieldMerchants).forEach((key) => {
        const field = filteredCustomFieldMerchants[key];
        const fieldType = filteredCustomFieldMerchants[key].type;
        const value = data[key];

        if (field.required && !value && fieldType !== 'serial') {
          customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: field.name }));
        } else if (value) {
          if (field.type === 'image') {
            if (!isImageUrl(value)) {
              customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
            }
          } else if (field.type === 'file') {
            if (!isFileUrl(value)) {
              customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
            }
          } else if (field.type === 'date') {
            if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
              customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_CORRECTLY' }));
            }
          } else if (field.type === 'link') {
            if (!isValidUrl(value)) {
              customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.LINK_FORMAT_CORRECTLY' }));
            }
          }
        }
      });
      if (!data.pic_id) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PIC' }) }));
      if (!data.name) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }));
      if (!data.city_id) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.CITY' }) }));
      if (!data.phone) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PHONE' }) }));
      if (data.email) {
        if (!isEmail(data.email)) {
          customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.EMAIL' }));
        }
      }
    } else if (source === 'template-user') {
      if (typeAction === 'insert') {
        Object.keys(filteredCustomFieldUsers).forEach((key) => {
          const field = filteredCustomFieldUsers[key];
          const value = data[key];

          if (field.required && !value) {
            customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: field.name }));
          } else if (value) {
            if (field.type === 'image') {
              if (!isImageUrl(value)) {
                customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
              }
            } else if (field.type === 'file') {
              if (!isFileUrl(value)) {
                customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
              }
            } else if (field.type === 'date') {
              if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
                customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_CORRECTLY' }));
              }
            } else if (field.type === 'link') {
              if (!isValidUrl(value)) {
                customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.LINK_FORMAT_CORRECTLY' }));
              }
            }
          }
        });
        if (!data.username)
          customFieldErrors.push(
            intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Username' })
          )
        if (data.username && !data.username.startsWith(user.data.company_prefix + '-'))
          customFieldErrors.push(`Username harus dimulai dengan ${user.data.company_prefix}-`)
        if (!data.phone)
          customFieldErrors.push(
            intl.formatMessage(
              { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
              { title: intl.formatMessage({ id: 'FORM.LABEL.PHONE' }) }
            )
          )
        if (!data.password)
          customFieldErrors.push(
            intl.formatMessage(
              { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
              { title: intl.formatMessage({ id: 'FORM.LABEL.PASSWORD' }) }
            )
          )
      } else if (typeAction === 'update') {
        Object.keys(filteredCustomFieldUsers).forEach((key) => {
          const field = filteredCustomFieldUsers[key]
          const value = data[key]

          if (field.required && !value) {
            customFieldErrors.push(
              intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: field.name })
            )
          } else if (value) {
            if (field.type === 'image') {
              if (!isImageUrl(value)) {
                customFieldErrors.push(
                  intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name })
                )
              }
            } else if (field.type === 'file') {
              if (!isFileUrl(value)) {
                customFieldErrors.push(
                  intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name })
                )
              }
            } else if (field.type === 'date') {
              if (!moment(value, 'YYYY-MM-DD', true).isValid()) {
                customFieldErrors.push(
                  intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_CORRECTLY' })
                )
              }
            } else if (field.type === 'link') {
              if (!isValidUrl(value)) {
                customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.LINK_FORMAT_CORRECTLY' }));
              }
            }
          }
        })
      }
      if (!data.name)
        customFieldErrors.push(
          intl.formatMessage(
            { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
            { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }
          )
        )
      if (!data.city_id)
        customFieldErrors.push(
          intl.formatMessage(
            { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
            { title: intl.formatMessage({ id: 'FORM.LABEL.CITY' }) }
          )
        )
      if (!data.role_id)
        customFieldErrors.push(
          intl.formatMessage(
            { id: 'FORM.VALIDATION.FIELD_REQUIRED' },
            { title: intl.formatMessage({ id: 'FORM.LABEL.ROLE' }) }
          )
        )
    } else if (source === 'template-unit') {
      if (!data.name) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }));
      if (!data.alias) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Alias' }));
    } else if (source === 'template-product') {
      if (!data.name) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.NAME' }) }));
      if (!data.sku) customFieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'SKU' }));
    }
    if (customFieldErrors.length === 0) {
      // Masukkan ke newData hanya jika tidak ada kesalahan
      if (source === 'template-merchant') await handleInsertMerchant(data);
      else if (source === 'template-user' && typeAction === 'insert') await handleInsertUser(data);
      else if (source === 'template-user' && typeAction === 'update') await handleUpdatetUser(data);
      else if (source === 'template-unit' && typeAction === 'insert') await handleInsertUnit(data);
      else if (source === 'template-unit' && typeAction === 'update') await handleUpdateUnit(data);
      else if (source === 'template-product' && typeAction === 'insert') await handleInsertProduct(data);
      else if (source === 'template-product' && typeAction === 'update') await handleUpdateProduct(data);
    } else {
      // Jika ada custom field yang gagal, tambahkan ke dalam array error
      dataError.push({
        ...data,
        error: customFieldErrors.join(", ")
      });

      return customFieldErrors.join(", ")
    }
  }

  const handleCheckExcelNew = async (data: any[], batchSize: number = 200) => {
    const validData: any[] = [];
    const invalidData: any[] = [];
    for (const item of data) {
      const fieldErrors: string[] = [];
      const normalizeDateStart = normalizeDate(item.date_start)
      const normalizeDateEnd = normalizeDate(item.date_end)

      if (typeAction === 'insert') {
        Object.keys(filteredCustomFieldPipelines).forEach((key) => {
          const field = filteredCustomFieldPipelines[key];
          const value = item[key];
          if (typeAction === 'insert') {
            if (user.data.company_id === companyId.vedaPraxis || user.data.company_id === companyId.happyPlay) {
              if (field.required && !value && field.type !== 'serial') {
                fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: field.name }));
              } else if (value) {
                if (field.type === 'file') {
                  if (!isFileUrl(value)) {
                    fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
                  }
                } else if (field.type === 'image') {
                  if (!isImageUrl(value)) {
                    fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.URL_CORRECTLY' }, { title: field.name }));
                  }
                } else if (field.type === 'date') {
                  if (!moment(normalizeDate(value), 'YYYY-MM-DD', true).isValid()) {
                    fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_CORRECTLY_DYNAMIC' }, { title: field.name }));
                  }
                } else if (field.type === 'link') {
                  if (!isValidUrl(value)) {
                    fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.LINK_FORMAT_CORRECTLY_DYNAMIC' }, { title: field.name }));
                  }
                }
              }
            }
          }
        })

        if (!moment(normalizeDateStart, 'YYYY-MM-DD', true).isValid()) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_DYNAMIC' }, { title: intl.formatMessage({ id: 'FORM.VALIDATION.TITLE_DATE_START' }) }));
        }
        if ((normalizeDateEnd < normalizeDateStart) && normalizeDateEnd) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_END_AFTER_START' }));
        }

        if (item.progress_type === "SUCCESS" || item.progress_type === 'FAIL') {
          if (!normalizeDateEnd || !item.date_end) {
            fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_PIPELINE' }, { title: intl.formatMessage({ id: 'FORM.VALIDATION.TITLE_DATE_END' }) })) // pengecekan jika date_end tidak ada padahal type 'SUCCESS'
          } else if (!moment(normalizeDateEnd, 'YYYY-MM-DD', true).isValid()) {
            fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_DYNAMIC' }, { title: intl.formatMessage({ id: 'FORM.VALIDATION.TITLE_DATE_END' }) }));
          }
        } else {
          if (item.date_end && item.date_end.trim() !== '') {
            if (!moment(normalizeDateEnd, 'YYYY-MM-DD', true).isValid()) {
              fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.DATE_FORMAT_DYNAMIC' }, { title: intl.formatMessage({ id: 'FORM.VALIDATION.TITLE_DATE_END' }) }));
            }
          }
        }
        if (!item.source_id) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Source' }));
        }
        if (!item.merchant_id) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Merchant' }));
        }
        if (!item.pic_id) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'PIC' }));
        }
        if (item.nominal === undefined || item.nominal === null || item.nominal === '') {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Nominal' }));
        } else if (isNaN(Number(item.nominal))) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.INVALID_NUMBER' }, { title: 'Nominal' }));
        }
        // if (!item.progress_id) {
        //     fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Progress' }));
        // }
        if (!item.product_id) {
          fieldErrors.push(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: 'Product' }));
        }
      }

      if (fieldErrors.length > 0) {
        invalidData.push({
          ...item,
          error: fieldErrors.join(", ")
        });
      } else {
        validData.push(item);
      }
    }

    // Proses data yang valid
    if (validData.length > 0) {
      try {
        await handleInsertPipeline(validData, batchSize);
      } catch (error) {
        console.error('Error inserting valid data:', error);
        // Handle error sesuai kebutuhan
      }
    }

    // Tambahkan data yang tidak valid ke dalam dataError
    if (invalidData.length > 0) {
      dataError.push(...invalidData);
    }
  };

  // HANDLE USER
  const handleChangeUser = (e: any) => {
    setListData([])
    var check_excel = true
    var empty_excel = false
    var success = false
    const file = e
    const wb = new ExcelJS.Workbook()
    const reader = new FileReader()

    var filteredCustomFieldUsers = Object.fromEntries(
      Object.entries(customFields).filter(([key, value]) => {
        // Ensure value is an object and has a type property before filtering
        return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files';
      })
    );

    reader.readAsArrayBuffer(file)
    reader.onload = () => {
      const buffer: any = reader.result
      wb.xlsx.load(buffer).then((workbook: any) => {
        const newData: any = []
        var nameWorksheet = workbook.getWorksheet('Sheet1')
        var nameWorksheetInfo = workbook.getWorksheet('Info')
        if (nameWorksheetInfo !== undefined) {
          nameWorksheetInfo.eachRow(async (rowInfo: any, rowIndexInfo: number) => {
            if (rowIndexInfo > 3) {
              if (
                rowInfo.values[2] === 'Template Insert User' ||
                rowInfo.values[2] === 'Template Error Insert User'
              ) {
                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                  if (rowIndex > 2) {
                    const rowData = row.values
                    if (Array.isArray(rowData)) {
                      var leader: any = dataLeaders.find((data: any) => data.name === rowData[6])
                      var city: any = dataCities.find((data: any) => data.name === rowData[7])
                      var role: any = dataRoles.find((data: any) => data.name === rowData[8])
                      var attendanceTemplate: any = dataAttendanceTemplates.find(
                        (data: any) => data.name === rowData[9]
                      )

                      const customFieldsStartIndex = 11
                      const customFieldsData = Object.fromEntries(Object.keys(filteredCustomFieldUsers).map((key, index) => {
                        const rowIndex = customFieldsStartIndex + index;
                        const rowValue = rowData[rowIndex];
                        const fieldType = filteredCustomFieldUsers[key].type;
                        let fieldValue;

                        if (fieldType === 'image' || fieldType === 'file' || fieldType === 'text' || fieldType === 'link') {
                          fieldValue = typeof rowValue === 'object' ? rowValue.text ? rowValue.text : rowValue.url : rowValue;
                        } else {
                          fieldValue = rowValue;
                        }

                        return [key, fieldValue];
                      }));

                      newData.push({
                        username: typeof rowData[1] === 'object' ? rowData[1].text ? rowData[1].text : rowData[1].url : rowData[1],
                        name: typeof rowData[2] === 'object' ? rowData[2].text ? rowData[2].text : rowData[2].url : rowData[2],
                        nik: typeof rowData[3] === 'object' ? rowData[3].text ? rowData[3].text : rowData[3].url : rowData[3],
                        phone: typeof rowData[4] === 'object' ? rowData[4].text ? rowData[4].text : rowData[4].url : rowData[4],
                        password: typeof rowData[5] === 'object' ? rowData[5].text ? rowData[5].text : rowData[5].url : rowData[5],
                        leader_name: leader?.name,
                        leader_id: leader?.id,
                        city_name: city?.name,
                        city_id: city?.id,
                        role_name: role?.name,
                        role_id: role?.id,
                        attendanceTemplate_name: attendanceTemplate?.name,
                        attendanceTemplate_id: attendanceTemplate?.id,
                        date_join: typeof rowData[10] === 'object' ? rowData[10].text ? rowData[10].text : rowData[10].url : rowData[10],
                        ...customFieldsData
                      })

                      check_excel = true
                      success = true
                      empty_excel = false
                    }
                  } else {
                    empty_excel = true
                    return false
                  }
                })
              } else if (
                rowInfo.values[2] === 'Template Update User' ||
                rowInfo.values[2] === 'Template Error Update User'
              ) {
                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                  if (rowIndex > 2) {
                    const rowData = row.values
                    if (Array.isArray(rowData)) {
                      var leader: any = dataLeaders.find((data: any) => data.name === rowData[4])
                      var city: any = dataCities.find((data: any) => data.name === rowData[5])
                      var role: any = dataRoles.find((data: any) => data.name === rowData[6])
                      var attendanceTemplate: any = dataAttendanceTemplates.find(
                        (data: any) => data.name === rowData[7]
                      )
                      var id = atob(rowData[1])
                      const customFieldsStartIndex = 8

                      const customFieldsData = Object.fromEntries(Object.keys(filteredCustomFieldUsers).map((key, index) => {
                        const rowIndex = customFieldsStartIndex + index;
                        const rowValue = rowData[rowIndex];
                        const fieldType = filteredCustomFieldUsers[key].type;
                        let fieldValue;

                        if (fieldType === 'image' || fieldType === 'file' || fieldType === "text" || fieldType === 'link') {
                          fieldValue = typeof rowValue === 'object' ? rowValue.text ? rowValue.text : rowValue.url : rowValue;
                        } else {
                          fieldValue = rowValue;
                        }

                        return [key, fieldValue];
                      }));

                      newData.push({
                        id: id.slice(0, 3) + id.slice(6),
                        name: typeof rowData[2] === 'object' ? rowData[2].text ? rowData[2].text : rowData[2].url : rowData[2],
                        nik: typeof rowData[3] === 'object' ? rowData[3].text ? rowData[3].text : rowData[3].url : rowData[3],
                        leader_name: leader?.name,
                        leader_id: leader?.id,
                        city_name: city?.name,
                        city_id: city?.id,
                        role_name: role?.name,
                        role_id: role?.id,
                        attendanceTemplate_name: attendanceTemplate?.name,
                        attendanceTemplate_id: attendanceTemplate?.id,
                        ...customFieldsData,
                      })
                      check_excel = true
                      success = true
                      empty_excel = false
                    }
                  } else {
                    empty_excel = true
                    return false
                  }
                })
              } else {
                check_excel = false
                return false
              }
            }
          })
        } else {
          check_excel = false
        }

        if (empty_excel) {
          Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
            confirmButtonText: 'Ok',
          })
        }

        if (!check_excel) {
          Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
            confirmButtonText: 'Ok',
          })
        }

        if (success) setListData(newData)
      })
    }
  }

  const handleInsertUser = async (data: any) => {
    const customFieldsValues: Record<string, string> = Object.keys(customFields).reduce(
      (obj, item) => ({
        ...obj,
        [item]: (data as Record<string, string>)[item]
          ? (data as Record<string, string>)[item]
          : null,
      }),
      {}
    )

    const response = await insertUser({
      name: data.name,
      username: data.username,
      password: data.password,
      nik: data.nik === '' ? undefined : data.nik,
      phone: data.phone.replace('+', ''),
      leaderId: data.leader_id === '' ? undefined : data.leader_id,
      cityId: data.city_id,
      dateJoined: data.date_join,
      roleId: data.role_id,
      attendanceTemplateId:
        data.attendanceTemplate_id === '' ? undefined : data.attendanceTemplate_id,
      token: token,
      customFields: customFieldsValues,
    })

    if (response.data.success) {
      dataSuccess.push(response.data.data)
    } else {
    }
  }

  const handleUpdatetUser = async (data: any) => {
    const customFieldsValues: Record<string, string> = Object.keys(customFields).reduce(
      (obj, item) => ({
        ...obj,
        [item]: (data as Record<string, string>)[item]
          ? (data as Record<string, string>)[item]
          : null,
      }),
      {}
    )

    const response = await updateUser({
      id: data.id,
      name: data.name,
      nik: data.nik === '' ? undefined : data.nik,
      leaderId: data.leader_id === '' ? undefined : data.leader_id,
      cityId: data.city_id,
      roleId: data.role_id,
      attendanceTemplateId:
        data.attendanceTemplate_id === '' ? undefined : data.attendanceTemplate_id,
      token: token,
      customFields: customFieldsValues,
    })
    if (response.data.success) {
      dataSuccess.push(response.data.data)
    } else {
      // var mergeError = { ...data, error: response.data.message }
      // dataError.push(mergeError)
    }
  }

  // HANDLE UNIT
  const handleChangeUnit = (e: any) => {
    setListData([]);
    var check_excel = true;
    var empty_excel = false;
    var success = false;
    const file = e;
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const buffer: any = reader.result;
      wb.xlsx.load(buffer).then((workbook: any) => {
        const newData: any = [];
        var nameWorksheet = workbook.getWorksheet("Sheet1")
        var nameWorksheetInfo = workbook.getWorksheet("Info")
        if (nameWorksheetInfo !== undefined) {
          nameWorksheetInfo.eachRow(async (rowInfo: any, rowIndexInfo: number) => {
            if (rowIndexInfo > 3) {
              if (rowInfo.values[2] === `Template Insert Unit` || rowInfo.values[2] === `Template Error Insert Unit`) {
                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                  if (rowIndex > 2) {
                    const rowData = row.values
                    if (Array.isArray(rowData)) {
                      newData.push({
                        name: rowData[1],
                        alias: rowData[2]
                      })

                      check_excel = true
                      success = true
                      empty_excel = false
                    }
                  } else {
                    empty_excel = true
                    return false
                  }
                });
              } else if (rowInfo.values[2] === `Template Update Unit` || rowInfo.values[2] === `Template Error Update Unit`) {
                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                  if (rowIndex > 2) {
                    const rowData = row.values
                    if (Array.isArray(rowData)) {
                      var id = atob(rowData[1])

                      newData.push({
                        id: id.slice(0, 3) + id.slice(6),
                        name: rowData[2],
                        alias: rowData[3],
                        color: rowData[5]
                      })

                      check_excel = true
                      success = true
                      empty_excel = false
                    }
                  } else {
                    empty_excel = true
                    return false
                  }
                });
              } else {
                check_excel = false
                return false
              }
            }
          })
        } else {
          check_excel = false
        }

        if (empty_excel) {
          Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
            confirmButtonText: 'Ok'
          });
        }

        if (!check_excel) {
          Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
            confirmButtonText: 'Ok'
          });
        }

        if (success) setListData(newData);
      });
    };
  };

  const handleInsertUnit = async (data: any) => {
    const response = await insertUnit(data.name, data.alias, randomColor(), token)

    if (response.data.success) {
      dataSuccess.push(response.data.data)
    } else {
      // var mergeError = { ...data, error: response.data.message }
      // dataError.push(mergeError)
    }
  }

  const handleUpdateUnit = async (data: any) => {
    const response = await updateUnit(data.id, data.name, data.alias, data.color, token)

    if (response.data.success) {
      dataSuccess.push(response.data.data)
    } else {
      // var mergeError = { ...data, error: response.data.message }
      // dataError.push(mergeError)
    }
  }

  useEffect(() => {
    const updatedPresistProgress = progress.map((progres: LeadProgress) => {
      const index = dataProgress.findIndex((data: LeadProgress) => data.id === progres.id)
      if (index !== INDEX_NOT_FOUND) {
        const pickDataProgress = dataProgress[index]
        progres.color = pickDataProgress.color
        progres.name = pickDataProgress.name
      }
      return progres;
    })

    setProgress(updatedPresistProgress)

  },[dataProgress]
  )

  useEffect(() => {
    if (source === 'template-merchant') {
      getUser()
      getCity()
    } else if (source === 'template-user') {
      getCity()
      getRole()
      getLeader()
      getAttendanceTemplate()
    } else if (source === 'template-pipeline') {
      getUser()
      user.data.permission_merchant_read !== 'NONE' && getMerchant()
      getProduct()
      getProgres()
      getSources()
    } else if (source === 'template-product') {
      getLabels()
      getUnits()
    }
  }, [templatePipelineId])

  useEffect(() => {
    if (source === 'template-merchant' && templateMerchantId && multiMerchants.length > 0) {
      var data = multiMerchants.find(item => item.id === templateMerchantId)?.customFields ?? {}
      setCustomFields(data)
    } else if (source === 'template-user' && settings.users_custom_fields) {
      setCustomFields(JSON.parse(settings.users_custom_fields))
    } else if (source === 'template-pipeline' && templatePipelineId && multiPipelines.length > 0) {
      var data = multiPipelines.find(item => item.id === templatePipelineId)?.customFields ?? {}
      setCustomFields(data)
    }
  }, [settings, source, multiPipelines, multiMerchants])

  return (
    <>
      {
        source === 'template-user' || source === 'template-unit' || source === 'template-product' ?
          <Dropdown renderToggle={(props, ref) => renderButton(props, ref, displayMode!)} noCaret placement="bottomEnd">
            <Dropdown.Item data-bs-toggle="modal" data-bs-target={`#import-excel-${source}-modal`} onClick={() => setTypeAction("insert")}>{intl.formatMessage({ id: 'FORM.ACTION.ENTER_DATA' })}</Dropdown.Item>
            <Dropdown.Item data-bs-toggle="modal" data-bs-target={`#import-excel-${source}-modal`} onClick={() => setTypeAction("update")}>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_DATA' })}</Dropdown.Item>
          </Dropdown>
          :
          <button
            type="button"
            className={`btn btn-light-success ${displayMode === "card" ? "btn-sm" : " mt-3"}`}
            data-bs-toggle="modal"
            data-bs-target={`#import-excel-${source}-modal`}
            onClick={() => setTypeAction("insert")}
          >
            <i className="fas fa-file-download fs-2 me-2"></i> {intl.formatMessage({ id: 'FORM.LABEL.IMPORT_EXCEL' })}
          </button>
      }


      <div className="modal fade" tabIndex={-1} id={`import-excel-${source}-modal`} ref={modalRef} >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.LABEL.IMPORT_EXCEL' })}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            {
              (source === 'template-merchant' && !isLoadingCity && !isLoadingUser) ||
                (source === 'template-user' && !isLoadingCity && !isLoadingRole && !isLoadingLeader && !isLoadingAttendanceTemplate) ||
                (source === 'template-pipeline' && !isLoadingUser && !isLoadingMerchant && !isLoadingProduct && !isLoadingProgres && !isLoadingSource) ||
                (source === 'template-unit') ||
                (source === 'template-product' && !isLoadingLabel && !isLoadingUnit) ?
                <form onSubmit={formik.handleSubmit} noValidate>
                  <div className="modal-body" style={{ height: "300px" }}>
                    <DataTableExportTemplate source={source} typeAction={typeAction} user={user} param={{ dataUsers, dataCities, dataRoles, dataLeaders, dataAttendanceTemplates, dataMerchants, dataProducts, dataProgress, dataSources, dataLabels, dataUnits }} />

                    {
                      listErrorInsert.length > 0 && typeAction === 'insert' &&
                      <DataTableExportTemplate source={source} typeAction={typeAction} user={user} param={{ dataUsers, dataCities, dataRoles, dataLeaders, dataAttendanceTemplates, dataMerchants, dataProducts, dataProgress, dataSources, dataLabels, dataUnits, listErrorInsert }} type="error" />
                    }

                    {
                      listErrorUpdate.length > 0 && typeAction === 'update' &&
                      <DataTableExportTemplate source={source} typeAction={typeAction} user={user} param={{ dataUsers, dataCities, dataRoles, dataLeaders, dataAttendanceTemplates, dataMerchants, dataProducts, dataProgress, dataSources, dataLabels, dataUnits, listErrorUpdate }} type="error" />
                    }

                    <FileUploader
                      fileOrFiles={listData.length === 0 ? null : listData}
                      classes="h-50"
                      handleChange={
                        source === 'template-merchant'
                          ? handleChangeMerchant
                          : source === 'template-pipeline'
                            ? handleChangePipeline
                            : source === 'template-unit'
                              ? handleChangeUnit
                              : source === 'template-product'
                                ? handleChangeProduct
                                : handleChangeUser
                      }
                      name="file"
                      types={["XLSX", "XLS", "CSV"]}
                    />

                    {
                      listData.length > 0 &&
                      <div style={{ padding: "10px" }}>{listData.length} {intl.formatMessage({ id: 'FORM.LABEL.DATA_UPLOAD' })}</div>
                    }
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                      ref={closeModalRef}
                    >
                      {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                    </button>
                    <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? "disabled" : ""}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                      <span className="indicator-label">
                        {intl.formatMessage({ id: 'FORM.ACTION.UPLOAD' })}
                      </span>
                      {source !== 'template-pipeline' ? (
                        <span className="indicator-progress">
                          Loading... &nbsp;
                          {indexUpload + " / " + listData.length}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-progress">
                          Loading... &nbsp;
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
                :
                <div className="modal-body" style={{ height: "200px" }}>
                  <div className="loading-overlay">
                    <div className="loader"></div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export { DataTableImport }
