import React, { FC, createRef, useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import Swal from "sweetalert2";
import { UserSession } from "../../interfaces/User";
import moment from "moment";
import { UAParser } from "ua-parser-js";
import { deleteUserSession } from "../../api/UsersCRUD";
import useAccessToken from "../../hooks/AccessToken";
import { useUserSessionStore } from "../../stores/UserSessionStore";
import { useDispatch } from "react-redux";
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useIntl } from "react-intl";

interface Props {
    data: UserSession
}

const ListLinkDevice: FC<Props> = ({ data }) => {
    const token = useAccessToken()
    const { userSessions, setUserSessions } = useUserSessionStore()
    const dispatch = useDispatch()
    const intl = useIntl()

    // let parser = new UAParser();
    // var device = parser.getBrowser().name + " (" + parser.getOS().name + ")"
    // var os = parser.getOS().name
    var imgDevice = toAbsoluteUrl('/media/logos/browser/browser.png')

    // if (parser.getBrowser().name === "Chrome") {
    //     device = `Google Chrome (${os})`
    // } else if (parser.getBrowser().name === "Firefox") {
    //     device = `Mozilla Firefox (${os})`
    // } else if (parser.getBrowser().name === "Safari") {
    //     device = `Apple Safari (${os})`
    // } else if (parser.getBrowser().name === "Opera") {
    //     device = `Opera (${os})`
    // } else if (parser.getBrowser().name === "Edge") {
    //     device = `Microsoft Edge (${os})`
    // }

    if (data.device?.toLowerCase().includes("android")) {
        imgDevice = toAbsoluteUrl('/media/logos/android.png')
    } else if (data.device?.toLowerCase().includes("ios")) {
        imgDevice = toAbsoluteUrl('/media/logos/apple.png')
    } else if (data.device?.toLowerCase().includes("chrome")) {
        imgDevice = toAbsoluteUrl('/media/logos/browser/chrome.png')
    } else if (data.device?.toLowerCase().includes("firefox")) {
        imgDevice = toAbsoluteUrl('/media/logos/browser/firefox.png')
    } else if (data.device?.toLowerCase().includes("safari")) {
        imgDevice = toAbsoluteUrl('/media/logos/browser/safari.png')
    } else if (data.device?.toLowerCase().includes("opera")) {
        imgDevice = toAbsoluteUrl('/media/logos/browser/opera.png')
    } else if (data.device?.toLowerCase().includes("edge")) {
        imgDevice = toAbsoluteUrl('/media/logos/browser/edge.png')
    }

    const btnLogoutLinkDevice = (data: UserSession) => {
        Swal.fire({
            title: intl.formatMessage({ id: "ALERT.TITLE.LOG_OUT" }),
            html: `${intl.formatMessage({ id: "ALERT.TEXT.LOG_OUT" })} <br/> ${intl.formatMessage({ id: "FORM.LABEL.FROM_THIS_DEVICE" })}`,
            icon: "warning",
            showCancelButton: !0,
            buttonsStyling: !1,
            confirmButtonText: intl.formatMessage({ id: "FORM.ACTION.LOG_OUT" }),
            cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-active-light",
            },
            reverseButtons: true,
            heightAuto: false,
            preConfirm: async () => {
                try {
                    const response = await deleteUserSession(data.id, token)
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            title: response.data.message,
                            confirmButtonText: 'Ok'
                        })
                        setUserSessions(userSessions.filter((user) => user.id !== data.id))

                        if (token === data.id) {
                            dispatch(auth.actions.logout())
                        }
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        confirmButtonText: 'Ok'
                    })
                }
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-stack mb-3">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-3">
                        <div className="symbol-label" style={{ backgroundColor: "unset" }}>
                            <img src={imgDevice} alt="" style={{ height: "75%" }} />
                        </div>
                    </div>
                    <div className="py-1">
                        {
                            token === data.id &&
                            <>
                                <span className="badge badge-light-success">{intl.formatMessage({ id: "FORM.LABEL.THIS_DEVICE" })}</span><br />
                            </>
                        }
                        <span className="fs-6 text-gray-800 fw-bolder">{data.device}</span>
                        <div className="fs-7 text-muted fw-bold mt-1">{intl.formatMessage({ id: "FORM.LABEL.LAST_ACTIVE" })} {moment(data.dateLastUsed).format("DD MMM YYYY HH:mm")}</div>
                    </div>
                </div>
                <div className="badge badge-light fw-bold py-4 px-3 bg-hover-light-danger" onClick={() => { btnLogoutLinkDevice(data) }}>
                    <img src={toAbsoluteUrl('/media/logos/logout.png')} alt="" style={{ height: "25px" }} />
                </div>
            </div>
        </>
    )
}

export { ListLinkDevice }