import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { changePhoneUser, createPrimaryPhone } from "../../app/modules/auth/redux/AuthCRUD";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import useAccessToken from "../../hooks/AccessToken";
import useUser from "../../hooks/User";
import * as auth from '../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from "react-redux";
import { PhonesType } from "../../app/modules/auth/models/UserModel";
import { clearDB } from "../../db";
import InputPhoneNative from "../Input/InputPhoneNative";
import useCheckWa from "../../hooks/useCheckWa";
import { useChatStore } from "../../stores/ChatStore";

const ChangePhoneModal: FC = () => {
    const user = useUser();
    const getPrimaryPhone = user.data.phones ? user.data.phones.find(phone => phone.isPrimary) : null;
    const token = useAccessToken();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl();
    const closeModalRef = useRef<HTMLButtonElement | null>(null);
    const [isPrimaryPhoneChanged, setIsPrimaryPhoneChanged] = useState(false);
    const [additionalPhones, setAdditionalPhones] = useState<PhonesType[]>([]);
    const [valuePrimaryPhone, setValuePrimaryPhone] = useState(getPrimaryPhone?.number || '');
    const [isLoadingPrimaryPhone, setIsLoadingPrimaryPhone] = useState(false);
    const [primaryPhoneError, setPrimaryPhoneError] = useState('');
    const { checkWa, checkWaValid, setCheckWa } = useCheckWa({
        phoneNumber: valuePrimaryPhone,
    });
    const dispatch = useDispatch();
    const [quickChangeValuePhone, setQuickChangeValuePhone] = useState<PhonesType>({ number: '', label: '', isPrimary: false });
    const { originalChatPhone } = useChatStore()

    const validationSchema = Yup.object().shape({
        additionalPhones: Yup.array().of(
            Yup.object().shape({
                label: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.REQUIRED" })),
                number: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.REQUIRED" }))
            })
        )
    });

    const fetchUserData = () => {
        if (user && user.data.phones) {
            const filteredPhones = user.data.phones.filter(phone => !phone.isPrimary);
            setAdditionalPhones(filteredPhones);
            setValuePrimaryPhone(getPrimaryPhone?.number || '');
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [user]);

    useEffect(() => {
        if (user && user.data.phones) {
            const filteredPhones = user.data.phones.filter(phone => !phone.isPrimary);
            setAdditionalPhones(filteredPhones);
        }
    }, [user]);

    const validatePrimaryPhone = () => {
        if (!valuePrimaryPhone) {
            setPrimaryPhoneError(intl.formatMessage({ id: "FORM.VALIDATION.REQUIRED" }));
            return false;
        }
        if (valuePrimaryPhone.length < 10) {
            setPrimaryPhoneError(intl.formatMessage({ id: "FORM.VALIDATION.PHONE_MIN_LENGTH" }));
            return false;
        }
        setPrimaryPhoneError('');
        return true;
    };

    const handleQuickChangePhone = (value: string, index: number) => {
        Swal.fire({
            icon: 'warning',
            title: intl.formatMessage({ id: "FORM.VALIDATION.CHANGE_PRIMARY_PHONE" }, { title: value }),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.YES" }),
        }).then((result) => {
            if (result.isConfirmed) {
                const newPhone = { number: value, label: `Primary Phone ${index + 1}`, isPrimary: true };
                setQuickChangeValuePhone(newPhone);
                handleSubmitPrimaryPhone(newPhone);
            }
        })
    }

    const handleSubmitPrimaryPhone = async (quickChangeValuePhone: PhonesType) => {
        if (!validatePrimaryPhone()) return;

        setIsLoadingPrimaryPhone(true);
        try {
            const checkQuickChangePhone = quickChangeValuePhone.number !== '' ? quickChangeValuePhone : null;
            console.log(checkQuickChangePhone, 'checkQuickChangePhone')
            const response = await createPrimaryPhone(checkQuickChangePhone ? checkQuickChangePhone.number : valuePrimaryPhone, token);
            if (response.data.success) {
                const updatedUser = {
                    ...user,
                    data: { ...user.data, phone: response.data.data.phone, phones: response.data.data.phones }
                };
                dispatch(auth.actions.setUser(updatedUser));
                setValuePrimaryPhone(response.data.data.phone);
                clearDB();
                Swal.fire({
                    icon: 'success',
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: intl.formatMessage({ id: "FORM.VALIDATION.CHANGED_PRIMARY_PHONE" }),
                });
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message || 'An error occurred while updating phone numbers'
            });
            setValuePrimaryPhone(getPrimaryPhone?.number || ''); // ketika error mengembalikan nilai primary phone sebelumnya
        } finally {
            setIsLoadingPrimaryPhone(false);
            setIsPrimaryPhoneChanged(false);
            setCheckWa('');
            setQuickChangeValuePhone({ number: '', label: '', isPrimary: false });
        }
    };

    const formik = useFormik({
        initialValues: {
            additionalPhones: additionalPhones,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);

                const newValues = {
                    phones: values.additionalPhones.map(phone => ({
                        number: phone.number,
                        label: phone.label,
                    }))
                };
                const response = await changePhoneUser(newValues, token);
                if (response.data.success) {
                    const updatedUser = {
                        ...user,
                        data: { ...user.data, phones: response.data.data.phones }
                    }
                    dispatch(auth.actions.setUser(updatedUser));
                    Swal.fire({
                        icon: 'success',
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.CHANGED_SECONDARY_PHONE" }),
                    });
                }
                closeModalRef.current?.click();
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message || 'An error occurred while updating phone numbers'
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    useEffect(() => {
        const modalElement = modalRef.current;
        const handleModalHidden = () => {
            formik.resetForm();
            setIsPrimaryPhoneChanged(false);
            fetchUserData();
        };

        const handleModalShow = () => {
            fetchUserData();
        };

        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);
        modalElement?.addEventListener('show.bs.modal', handleModalShow);

        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
            modalElement?.removeEventListener('show.bs.modal', handleModalShow);
        };
    }, [formik, user]);

    const handleIsPhoneChanged = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsPrimaryPhoneChanged(!isPrimaryPhoneChanged);
    };

    const handleAddNewPhone = () => {
        formik.setFieldValue('additionalPhones', [...formik.values.additionalPhones, { label: '', number: '', isPrimary: false }]);
    };

    const handleRemovePhone = (index: number) => {
        const updatedPhones = [...formik.values.additionalPhones];
        updatedPhones.splice(index, 1);
        formik.setFieldValue('additionalPhones', updatedPhones);
    };

    return (
        <div className="modal fade" tabIndex={-1} id="modal-change-phone-user" ref={modalRef}>
            {
                isLoadingPrimaryPhone && (
                    <div className="modal-backdrop fade show" style={{ zIndex: 1000 }}>
                        <div className="spinner spinner-md spinner-primary" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1000 }}></div>
                    </div>
                )
            }
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: "MENU.MANAGE_PHONES" })}</h5>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal} >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body fs-6">
                            <label className="form-label fs-6 fw-bolder text-dark">{intl.formatMessage({ id: "FORM.LABEL.PRIMARY_PHONE" })}</label>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-center align-items-center gap-3">
                                    <InputPhoneNative
                                        value={valuePrimaryPhone}
                                        setValue={(val) => {
                                            setValuePrimaryPhone(val);
                                            setPrimaryPhoneError('');
                                        }}
                                        hasDisabled={!isPrimaryPhoneChanged}
                                        checkWaValid={checkWaValid}
                                        validatePrimaryPhone={validatePrimaryPhone}
                                    />
                                    {isPrimaryPhoneChanged ? (
                                        <>
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-success ${isLoadingPrimaryPhone ? "disabled" : ""}`}
                                                data-kt-indicator={isLoadingPrimaryPhone ? 'on' : 'off'}
                                                onClick={() => handleSubmitPrimaryPhone(quickChangeValuePhone)}
                                                disabled={!!primaryPhoneError || checkWa === 'false'}
                                            >
                                                <span className="indicator-label">
                                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                                </span>
                                                <span className="indicator-progress">
                                                    Loading..
                                                </span>
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-light"
                                                onClick={handleIsPhoneChanged}
                                            >
                                                {intl.formatMessage({ id: "FORM.VALIDATION.CANCEL" })}
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            onClick={handleIsPhoneChanged}
                                        >
                                            {intl.formatMessage({ id: "FORM.LABEL.CHANGE" })}
                                        </button>
                                    )}
                                </div>
                                {primaryPhoneError && (
                                    <div className="text-danger mt-1">
                                        {primaryPhoneError}
                                    </div>
                                )}
                                {checkWa && (
                                    <div className={`mt-1 ${checkWa === 'true' ? 'text-success' : 'text-danger'}`}>
                                        {checkWa === 'true'
                                            ? intl.formatMessage({ id: "FORM.VALIDATION.VALID_WHATSAPP" })
                                            : checkWa === 'false'
                                                ? intl.formatMessage({ id: "FORM.VALIDATION.INVALID_WHATSAPP" })
                                                : null
                                        }
                                    </div>
                                )}
                                <div className="mt-3">
                                    <label className="form-label fs-6 fw-bolder text-dark">{intl.formatMessage({ id: "FORM.LABEL.CHAT_PHONE" })}</label>
                                    <InputPhoneNative value={originalChatPhone} hasDisabled={true} />
                                </div>
                            </div>
                            <div className="separator m-3"></div>
                            <div className="mb-3">
                                <button type="button" className="btn btn-primary btn-sm" onClick={handleAddNewPhone}>
                                    <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                                </button>
                            </div>

                            {formik.values.additionalPhones.map((phone, index) => (
                                <div key={index} className="mb-3">
                                    <div className="input-group mb-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Label"
                                            name={`additionalPhones.${index}.label`}
                                            value={phone.label}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                    {formik.errors.additionalPhones && formik.errors.additionalPhones[index] && (
                                        <div className="text-danger">{(formik.errors.additionalPhones[index] as any).label}</div>
                                    )}
                                    <div className="d-flex flex-column justify-content-center align-items-center gap-3 w-100">
                                        <div className="d-flex justify-content-center align-items-center gap-3 w-100">
                                            <InputPhoneNative 
                                                value={phone.number} 
                                                setValue={(val) => formik.setFieldValue(`additionalPhones.${index}.number`, val)} 
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm d-flex justify-content-center align-items-center"
                                                style={{ height: '40px' }}
                                                onClick={() => handleRemovePhone(index)}
                                            >
                                                <i className="fas fa-trash" style={{ padding: '0px' }}></i>
                                            </button>
                                        </div>

                                        {phone.number && phone.label && (
                                            <div className="d-flex justify-content-start w-100">
                                                <span 
                                                    className="text-primary cursor-pointer" 
                                                    onClick={() => handleQuickChangePhone(phone.number, index)}
                                                >
                                                    {intl.formatMessage({ id: "FORM.LABEL.CHANGE_PRIMARY_PHONE" })}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    {formik.errors.additionalPhones && formik.errors.additionalPhones[index] && (
                                        <div className="text-danger">{(formik.errors.additionalPhones[index] as any).number}</div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? "disabled" : ""}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export { ChangePhoneModal };