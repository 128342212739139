import React from 'react'
import { KTSVG } from '../../../_metronic/helpers';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import { getFileType, isFileUrl, getNameUrl } from '../../../functions/general';
import { Pipeline } from '../../../interfaces/Pipeline';

interface TerminAttachmentProps {
    pipeline: Pipeline
    title: string
    url: string | string[]
}

const TerminAttachment = ({ pipeline, title, url }: TerminAttachmentProps) => {
    const intl = useIntl();

    return (
        <div className="modal fade" tabIndex={-1} id={`attachments-detail-termin-modal-${pipeline.id}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title">{title}</h3>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body text-center w-100">
                        {
                            typeof url == "string" ?
                                <img src={url} alt={title} className="img-fluid" />
                                :
                                isFileUrl(url[0]) ?
                                    url.map((data: string, index: number) =>
                                        <>
                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                                {
                                                    getFileType(data) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                                                        : getFileType(data) === "doc" || getFileType(data) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                                                            : getFileType(data) === "ppt" || getFileType(data) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                                                                : getFileType(data) === "xlsx" || getFileType(data) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                                                                    : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                                                }
                                                <a key={index} href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                            </div>
                                        </>
                                    )
                                    :
                                    url.map((data: string, index: number) =>
                                        <>
                                            <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                                                <img src={toAbsoluteUrl('/media/logos/image.png')} alt="" style={{ width: "20px" }} />
                                                <a key={index} href={data} className="m-3" target="_blank" rel="noopener noreferrer">{getNameUrl(data)}</a>
                                            </div>
                                        </>
                                    )
                        }
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TerminAttachment