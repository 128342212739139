import React, { useEffect, useState } from 'react';
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps"
import { KTSVG } from '../../_metronic/helpers';
import { AttendanceTemplate } from '../../interfaces/Attendance';
import { useIntl } from 'react-intl';
interface MapModalProps {
    latitude: number
    longitude: number
    attendance: AttendanceTemplate
}

const MapAttendance: React.FC<MapModalProps> = ({ latitude, longitude, attendance }) => {
    const color = `hsl(${0 % 360}deg 39% 70%)`
    const [geoJsonFeatureLocation, setGeoJsonFeatureLocation] = useState<Object>({})

    const intl = useIntl()

    useEffect(() => {
        let radius = attendance.radiusClockIn && attendance.radiusClockIn.length > 0 && attendance.radiusClockIn[0] ? attendance.radiusClockIn[0] : 0;
        if (radius > 0) {
            const circleToPolygon = require("circle-to-polygon");

            const coordinates = [longitude, latitude]; //[lon, lat]
            const numberOfEdges = 32; //optional that defaults to 32

            let polygon = circleToPolygon(coordinates, radius, numberOfEdges);

            setGeoJsonFeatureLocation({
                type: "Feature",
                geometry: polygon,
                properties: { prop0: "value0" },
            });
        }
    }, [attendance.radiusClockIn, latitude, longitude])

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`map-attendance-modal-${attendance.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ lineBreak: 'anywhere' }}>{intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_LOCATION" })} {attendance.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <Map height={300} defaultCenter={[latitude, longitude]} defaultZoom={15} attribution={false}>
                                {Object.keys(geoJsonFeatureLocation).length > 0 ?
                                    <GeoJson
                                        svgAttributes={{
                                            fill: "#c9909080",
                                            strokeWidth: "1",
                                            stroke: "white",
                                            r: "20",
                                        }}
                                    >
                                        <GeoJsonFeature feature={geoJsonFeatureLocation} />
                                    </GeoJson> : <></>
                                }
                                {
                                    <Marker
                                        width={50}
                                        height={50}
                                        anchor={[latitude, longitude]}
                                        color={color}
                                    />
                                }
                            </Map>
                            {Object.keys(geoJsonFeatureLocation).length > 0 &&
                                <p className='mb-0 mt-2'>Radius: {attendance.radiusClockIn[0]} {intl.formatMessage({ id: "METERS" })}</p>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { MapAttendance }