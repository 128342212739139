import { useState, useEffect } from 'react';

interface Coordinates {
  latitude: number;
  longitude: number;
}

const useGeoLocation = (localStorageKey = 'is-location-granted') => {
  const [shouldRequestLocation, setShouldRequestLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(true);
  const [coords, setCoords] = useState<Coordinates>();

  const onSuccess = (location: GeolocationPosition) => {
    setLoading(false);
    setError(false);
    setCoords({
      latitude: location.coords.latitude,
      longitude: location.coords.longitude,
    });
    if (!('permissions' in navigator)) {
      localStorage.setItem(localStorageKey, JSON.stringify(true));
    }
  };

  const onError = (error: GeolocationPositionError) => {
    setLoading(false);
    setError(true);
  };

  useEffect(() => {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
          setShouldRequestLocation(true);
        } else {
          setLoading(false);
          setError(true);
        }
      });
    } else {
      const isLocationGranted = JSON.parse(localStorage.getItem(localStorageKey) || 'false');
      if (!isLocationGranted) {
        setShouldRequestLocation(true);
      }
    }
  }, []);

  useEffect(() => {
    if (shouldRequestLocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  }, [shouldRequestLocation]);

  return { loading, error, coords };
};

export default useGeoLocation;
