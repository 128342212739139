import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { customNumberFormat } from "../../functions/general";
import { useSettingsStore } from "../../stores/SettingsStore";
import { MasterDataTableNoUrl } from "../DataTable/MasterDataTableNoUrl";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { DetailMonthlyDashboardWidget, MonthlyDashboardWidget } from "../../interfaces/Dashboard";
import { useIntl } from "react-intl";
import useNameWidget from "../../hooks/NameWidget";
import { ExpandableElement } from "../General/ExpandableElement";
import { DataTableExport } from "../DataTable/DataTableExport";
import useUser from "../../hooks/User";
import { useCustomeStore } from "../../stores/CustomeStore";

interface WidgetMonthlyModalProps {
    data: MonthlyDashboardWidget
    dateStart: string
    dateEnd: string
}

const WidgetMonthlyModal: FC<WidgetMonthlyModalProps> = ({ data, dateStart, dateEnd }) => {
    const { settings } = useSettingsStore()
    const user = useUser()
    const { companyId } = useCustomeStore()
    const nameWidget = data.alias;
    // const nameWidget = useNameWidget(data.title);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl()

    var tableColumns: TableColumn<DetailMonthlyDashboardWidget>[] = [];

    if (data.title === 'Monthly Created Pipelines Count') {
        tableColumns = [
            {
                name: settings.merchant_title,
                width: '200px',
                selector: row => row.merchant_name,
                sortable: true,
                allowOverflow: true,
                cell: (row) => {
                    return row.merchant_name
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.OWNER_NAME" }),
                width: '200px',
                selector: row => row.owner_name,
                sortable: true,
                allowOverflow: true,
                cell: (row) => {
                    return row.owner_name
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOMINAL" }),
                right: true,
                width: '150px',
                selector: row => row.nominal,
                cell: (row) => {
                    return customNumberFormat(Math.ceil(row.nominal) ?? 0);
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_STARTED" }),
                right: true,
                width: '150px',
                selector: row => row.date_start,
                cell: (row) => {
                    return moment(row.date_start).format("DD MMM YYYY")
                },
                sortable: true,
            },
        ]
    } else if (data.title === 'Monthly Completed Meetings Count') {
        tableColumns = [
            {
                name: settings.merchant_title,
                width: '200px',
                selector: row => row.merchant_name,
                sortable: true,
                allowOverflow: true,
                cell: (row) => {
                    return row.merchant_name
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.OWNER_NAME" }),
                allowOverflow: true,
                width: '200px',
                selector: row => row.owner_name,
                sortable: true,
                cell: (row) => {
                    return row.owner_name
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_STARTED" }),
                right: true,
                width: '150px',
                selector: row => row.date_meet,
                cell: (row) => {
                    return moment(row.date_meet).format("DD MMM YYYY")
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_ENDED" }),
                right: true,
                width: '150px',
                selector: row => row.date_meet_end,
                cell: (row) => {
                    return row.all_day ? moment(row.date_meet_end).subtract(1, 'days').local().format("DD MMM YYYY") : moment(row.date_meet_end).format("DD MMM YYYY")
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" }),
                width: '150px',
                allowOverflow: true,
                selector: row => row.note,
                cell: (row) => {
                    return row.note ? <ExpandableElement text={(row.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
                },
                sortable: true,
            },
        ]
    } else if (data.title === 'Monthly Award Pipelines Count' || data.title === 'Monthly Loss Pipelines Count' || data.title === 'Monthly Prospect Pipelines Count' || data.title === 'Monthly Lead-Gen Pipelines Count') {
        tableColumns = [
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.OWNER_NAME" }),
                allowOverflow: true,
                width: '200px',
                selector: row => row.owner_name,
                sortable: true,
                cell: (row) => {
                    return row.owner_name
                },
            },
            {
                name: `Total ${settings.merchant_title}`,
                allowOverflow: true,
                selector: row => row.merchant_count,
                sortable: true,
                right: true,
                cell: (row) => {
                    return customNumberFormat(row.merchant_count ?? 0);
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOMINAL" }),
                right: true,
                allowOverflow: true,
                wrap: true,
                selector: row => row.nominal,
                cell: (row) => {
                    return customNumberFormat(Math.ceil(row.nominal) ?? 0);
                },
                sortable: true,
            },
        ]
    } else {
        tableColumns = [
            {
                name: settings.merchant_title,
                width: '200px',
                selector: row => row.merchant_name,
                sortable: true,
                allowOverflow: true,
                cell: (row) => {
                    return row.merchant_name
                },
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.OWNER_NAME" }),
                width: '200px',
                selector: row => row.owner_name,
                sortable: true,
                allowOverflow: true,
                cell: (row) => {
                    return row.owner_name
                },
            },
            {
                name: (data.title === 'Monthly Difference Pipelines Nominal' && user.data.company_id === companyId.vedaPraxis) ?  intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOMINAL_DIFFERENCE" }) : intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOMINAL" }),
                width: '150px',
                right: true,
                allowOverflow: true,
                wrap: true,
                selector: row => row.nominal,
                cell: (row) => {
                    return <a href={`/pipelines/${row.lead_template_id}/log/${row.lead_id}`} target="_blank" rel="noopener noreferrer">{customNumberFormat(Math.ceil(row.nominal) ?? 0)}</a>
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DATE_STARTED" }),
                right: true,
                width: '150px',
                selector: row => row.date_start,
                cell: (row) => {
                    return moment(row.date_start).format("DD MMM YYYY")
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.DATE_ENDED" }),
                right: true,
                width: '150px',
                selector: row => row.date_end,
                cell: (row) => {
                    return (row.date_end !== "-" && row.date_end) ? moment(row.date_end).format("DD MMM YYYY") : "-"
                },
                sortable: true,
            },
        ]
    }

    let actions: ReactNode[] = [<DataTableExport keyString={data.title} source={"WidgetMonthlyDashboard"} nameFile={`Detail ${nameWidget}`} columns={tableColumns} dateStart={dateStart} dateEnd={dateEnd} />];

    return (
        <div className="modal fade" tabIndex={-1} id="widget-monthly-modal" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detail {nameWidget}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <MasterDataTableNoUrl
                        tableKey={"widget-monthly"}
                        tableColumns={tableColumns}
                        tableData={data.detail}
                        actions={actions}
                    />
                </div>
            </div>
        </div>
    )
}

export { WidgetMonthlyModal }