import { FC, useEffect, useRef, useState } from "react";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { deleteUserSession } from "../../api/UsersCRUD";
import useUser from "../../hooks/User";
import { useDispatch } from "react-redux";
import * as auth from "../../app/modules/auth/redux/AuthRedux";
import { clearDB } from "../../db";
import Swal from "sweetalert2";
import { Modal } from "bootstrap";

interface ExpiredModalProps {
}

const ExpiredModal: FC<ExpiredModalProps> = ({ }) => {
    const user = useUser()
    const dispatch = useDispatch()
    const intl = useIntl()
    const modalRef = useRef<HTMLDivElement>(null);
    const token = useAccessToken()
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const handleChatWhatsapp = () => {
        window.open(`https://api.whatsapp.com/send?phone=6282330779799&text=Halo Admin Wooblazz, %0A%0ASaya ${user.data.name} dari perusahaan ${user.data.company_name} ingin memperpanjang masa aktif Wooblazz CRM`)
    }

    const handleLogout = async () => {
        try {
            const response = await deleteUserSession(token, token)
            if (response.data.success) {
                closeModalRef.current?.click();
                dispatch(auth.actions.logout())
                localStorage.clear()
                clearDB()
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        }
    }

    return (
        <>
            <div className="modal fade" data-bs-backdrop="static" tabIndex={-1} id={`expired-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ borderRadius: "1rem" }}>
                        <div className="modal-body text-center" style={{ padding: "50px 0px" }}>
                            <i className="far fa-frown" style={{ color: "#e45050", fontSize: "90px", paddingBottom: "10px" }}></i>
                            <h3 style={{ fontWeight: "700", fontSize: "45px" }}>Ooops!</h3>
                            <p className="fs-5 mb-5 pb-5" style={{ fontWeight: "500" }}>{intl.formatMessage({ id: "ALERT.ACCOUNT_EXPIRED.TITLE" })} <br />{intl.formatMessage({ id: "ALERT.ACCOUNT_EXPIRED.DESC" })}</p>
                            <button className="btn btn-success w-50 rounded-pill mb-3" onClick={handleChatWhatsapp}>
                                <i className="fab fs-1 me-1 fa-whatsapp"></i>Chat Whatsapp
                            </button>
                            <div className="text-dark cursor-pointer" onClick={handleLogout}>{intl.formatMessage({ id: "FORM.ACTION.LOG_OUT" })}</div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-light d-none"
                        data-bs-dismiss="modal"
                        ref={closeModalRef}
                    >
                        {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                    </button>
                </div>
            </div>
        </>
    )
}

export { ExpiredModal }