import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { TempPaymentTermin } from '../Pipelines/Logs/AddLog';
import { customNumberFormatString, getPinningStyles } from '../../functions/general';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { CustomField } from '../../interfaces/Settings';
import { useEffect, useState } from 'react';
import PreviewAttachmentTermin from '../Pipelines/DetailTermin/PreviewAttachmentTermin';
import { Modal } from 'bootstrap';
import { PaymentTermin } from '../../interfaces/Termin/Termin';
import { Column } from '@tanstack/react-table';


interface PaymentDisplayProps {
    tempPaymentTermin: TempPaymentTermin[];
    onDeleteItem: (index: number) => void;
    customFieldTermin: CustomField;
    pipelineId: string;
}



const PaymentTerminDisplay: React.FC<PaymentDisplayProps> = ({
    tempPaymentTermin,
    onDeleteItem,
    customFieldTermin,
    pipelineId
}) => {
    const intl = useIntl()
    const columnHelper = createColumnHelper<TempPaymentTermin>()
    const [previewFiles, setPreviewFiles] = useState<File | File[]>([]);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);

    const handlePreviewClick = (files: File | File[]) => {
        setPreviewFiles(files);
        setShowAttachmentModal(true);
    };

    const handleClosePreview = () => {
        setShowAttachmentModal(false);
        setPreviewFiles([]);
    };


    const columnCustomField = Object.keys(customFieldTermin).map(key => {
        const fieldType = customFieldTermin[key].type

        if (fieldType === 'text' || fieldType === 'option' || fieldType === 'multiple') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 30,
                cell: (info) => {
                    const row = info.row.original;
                    const values = row.customFields && row.customFields[key] && row.customFields[key].toString();
                    return values ? values.split(',').join(', ') : '-';
                },
            })
        } else if (fieldType === 'date') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                size: 25,
                header: () => customFieldTermin[key].name,
                cell: (info) => moment(info.getValue()).format("DD MMM YYYY"),
            })
        } else if (fieldType === 'image' || fieldType === 'images') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 25,
                cell: (info) => {
                    const row = info.row.original;

                    const files = row.customFields && row.customFields[key];
                    return files ? (
                        <span role="button" onClick={() => handlePreviewClick(files as any)}>
                            <i className="fas fs-2 fa-eye"></i>
                        </span>
                    ) : '-';
                },
            })
        } else if (fieldType === 'file' || fieldType === 'files') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 25,
                cell: (info) => {
                    const row = info.row.original;
                    const files = row.customFields && row.customFields[key];
                    if (!files) return '-';

                    return (
                        <span role="button" onClick={() => handlePreviewClick(files as any)}>
                            <i className="fas fa-file-invoice fs-3"></i>
                        </span>
                    );
                },
            })
        } else if (fieldType === 'serial') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 30,
                cell: (info) => {
                    const row = info.row.original;
                    const values = row.customFields && row.customFields[key];
                    return typeof values === 'string' ? values : (values ?? [] as string[])?.length > 0 ? (values ?? [] as string[]).join('') : '-';
                },
            })
        } else if (fieldType === 'link') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 30,
                cell: (info) => {
                    const row = info.row.original;
                    const values = row.customFields && row.customFields[key];
                    return values ? (
                        <a href={values} target="_blank" rel="noreferrer"
                            className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600">{intl.formatMessage({ id: 'FORM.LABEL.LOOK' })}
                        </a>
                    ) : '-';
                },
            })
        } else if (fieldType === 'phone') {
            return columnHelper.accessor(row => row.customFields?.[key], {
                id: key,
                header: () => customFieldTermin[key].name,
                size: 30,
                cell: (info) => {
                    const row = info.row.original;
                    const values = row.customFields && row.customFields[key];
                    return values ? values : '-';
                },
            })
        }

        return null;
    }).filter((column): column is NonNullable<typeof column> => column !== null);

    const columns = [
        columnHelper.accessor(row => row.id, {
            id: 'actions',
            header: 'Aksi',
            size: 25,
            cell: (info) => (
                <button
                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
                    onClick={() => onDeleteItem(info.row.index)}
                >
                    <i className="fas fa-trash"></i>
                </button>
            ),
        }),
        columnHelper.accessor(row => row.id, {
            id: 'termNo',
            header: () => 'Termin ke',
            size: 25,
            cell: (info) => info.row.index + 1,
        }),
        columnHelper.accessor('nominal', {
            header: () => 'Nominal',
            size: 30,
            cell: (info) => (
                <span className='d-flex justify-content-between align-items-center gap-3'>
                    <span>Rp</span>
                    <span>{customNumberFormatString(info.getValue().toString())}</span>
                </span>
            ),
        }),
        columnHelper.accessor('vatPercentage', {
            header: () => 'PPN',
            size: 30,
            cell: (info) => (
                <span className='d-flex justify-content-between align-items-center gap-3'>
                    <span>{customNumberFormatString(info?.getValue()?.toString()?? '')}</span>
                    <span>%</span>
                    </span>
            ),
        }),
        columnHelper.accessor('total', {
            header: () => 'Total',
            size: 30,
            cell: (info) => (
                <span className='d-flex justify-content-between align-items-center gap-3'>
                    <span>Rp</span>
                    <span>{customNumberFormatString(info?.getValue()?.toString() ?? '')}</span>
                </span>
            ),
        }),
        columnHelper.accessor('dueDate', {
            header: () => intl.formatMessage({ id: 'FORM.LABEL.DEADLINE_PAYMENT' }),
            size: 25,
            cell: (info) => {
                const getValue = info.getValue();
                if (getValue) {
                    return moment(getValue).format("DD MMM YYYY")
                }
                return <span>-</span>
            },
        }),
        columnHelper.accessor('datePaid', {
            header: () => intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_DATE' }),
            size: 25,
            cell: (info) => {
                const getValue = info.getValue();
                if (getValue) {
                    return moment(getValue).format("DD MMM YYYY")
                }
                return <span>-</span>
            },
        }),
        columnHelper.accessor('progress', {
            header: () => 'Status',
            size: 30,
            cell: (info) => {
                if (info.getValue() === 'PAID') {
                    return <span className='badge badge-success'>{info.getValue()}</span>
                } else {
                    return <span className='badge badge-warning'>{info.getValue()}</span>
                }
            },
        }),
        columnHelper.accessor('receiptUrls', {
            header: () => 'Bukti Pembayaran',
            size: 30,
            cell: (info) => {
                const receiptUrls = info.getValue();
                const checkTypeReceiptUrls = typeof receiptUrls === 'object' && receiptUrls instanceof File;
                if (receiptUrls && checkTypeReceiptUrls) {
                    return <span role="button" onClick={() => handlePreviewClick(receiptUrls as File)}><i className="fas fa-file-invoice fs-3"></i></span>
                } else {
                    return <span>{intl.formatMessage({ id: 'FORM.LABEL.NO_PROOF' })}</span>
                }
            }
        }),
        ...columnCustomField,
    ]


    const table = useReactTable({
        data: tempPaymentTermin,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            pagination: {
                pageSize: 5,
            },
        },
    })


    if (!tempPaymentTermin || tempPaymentTermin.length === 0) {
        return null;
    }

    return (
        <>
            <div className="card">
                <div className="card-header border-0 py-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">{intl.formatMessage({ id: 'FORM.LABEL.TERMIN' })}</span>
                    </h3>
                </div>
                <div className="card-body py-3">
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 text-center" style={{ overflowX: 'auto' }}>
                            <thead>
                                <tr className="fw-bolder text-dark" style={{ backgroundColor: '#D4D4D4' }}>
                                    {table.getHeaderGroups().map(headerGroup => (
                                        headerGroup.headers.map(header => (
                                            <th
                                                key={header.id}
                                                style={{
                                                    padding: `12px ${header.column.columnDef.size}px`,
                                                    whiteSpace: 'nowrap',
                                                    minWidth: 'fit-content',
                                                    ...getPinningStyles(header.column as Column<TempPaymentTermin | PaymentTermin>, true)
                                                }}
                                                className="align-middle"
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </th>
                                        ))
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <td
                                                key={cell.id}
                                                style={{
                                                    padding: `12px ${cell.column.columnDef.size}px`,
                                                    whiteSpace: 'nowrap',
                                                    minWidth: 'fit-content',
                                                    ...getPinningStyles(cell.column as Column<TempPaymentTermin | PaymentTermin>, false)
                                                }}
                                                className="align-middle"
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-between align-items-center flex-wrap pt-5">
                        <div className="fs-6 fw-semibold text-gray-700">
                            Showing {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} to{' '}
                            {Math.min(
                                (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                                tempPaymentTermin.length
                            )}{' '}
                            of {tempPaymentTermin.length} entries
                        </div>
                        <div className="d-flex">
                            <button
                                className="btn btn-light btn-sm me-2"
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </button>
                            <span className="btn btn-light btn-sm me-2">
                                Page {table.getState().pagination.pageIndex + 1} of{' '}
                                {table.getPageCount()}
                            </span>
                            <button
                                className="btn btn-light btn-sm"
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                            >
                                <i className="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showAttachmentModal && previewFiles && (
                <PreviewAttachmentTermin
                    files={previewFiles}
                    pipelineId={pipelineId}
                    onClose={handleClosePreview}
                />
            )}
        </>
    )
}

export default PaymentTerminDisplay