import { useCallback, useEffect, useState } from 'react'
import { CardSendEmail } from '../../../components/Email/CardSendEmail'
import { customNumberFormat } from '../../../functions/general'
import { KTSVG } from '../../../_metronic/helpers'
import { useEmailStore } from '../../../stores/EmailStore'
import Swal from 'sweetalert2'
import { DetailEmail } from '../../../components/Email/DetailEmail'
import axios from 'axios'
import useSWR from 'swr'
import { deleteEmail, saveDraft } from '../../../api/EmailCRUD'
import { ThreadedEmailDetail } from '../../../components/Email/ThreadedEmailDetail'
import { LoginEmail } from '../../../components/Email/LoginEmail'
import GroupEmail from '../../../components/Email/BroadcastEmail/table/GroupEmail'
import ProfileAvatar from '../../../components/Email/ProfileAvatar'
import DetailGroupEmail from '../../../components/Email/BroadcastEmail/table/DetailGroupEmail'
import { useLocation } from 'react-router-dom';
import { CardEmail } from '../../../components/Email/CardEmail'
import DetailBroadcastEmail from '../../../components/Email/BroadcastEmail/table/DetailBroadcastEmail'
import DetailDraftBroadcast from '../../../components/Email/BroadcastEmail/table/DetailDraftBroadcast'
import { useUserStore } from '../../../hooks/UserEmail'
import BroadcastEmail from '../../../components/Email/BroadcastEmail/table/BroadcastEmail'
import { noNavbar } from '../../../constant/email'
import SideBarMobile from '../../../components/Email/SideBar/SideBarMobile'
import SideBarDesktop from '../../../components/Email/SideBar/SideBarDesktop'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL
const Emails = () => {
  const [qtySidebar, setQtySidebar] = useState({
    inbox: 0,
    // sent: 0,
    draft: 0,
    trash: 0,
    archive: 0,
    spam: 0,
  })
  const {
    openSendEmail,
    setValuesFromDraft,
    typeMenu,
    setTypeMenu,
    activeSidebar,
    setActiveSidebar,
    tokenEmail,
    setTokenEmail,
    typeEmail,
    setTypeEmail,
    valueSaveDraft,
    setValuesSaveDraft,
    valuesFromDraft,
    setOpenSendEmail,
    threadEmail,
    setShowSideBar,
    currentSubject,
    setDataEditValuesBroadcast,
    dataEditValuesBroadcast,
    isApiError,
  } = useEmailStore()
  const { clearUserEmail, userEmail } = useUserStore()
  const location = useLocation()

  function checkCountZeroQtySidebar(value: number) {
    if (value === 0) {
      return ''
    } else {
      return `(${value})`
    }
  }

  useEffect(() => {
    if (!userEmail) {
      setTokenEmail('');
      clearUserEmail();
      setTypeEmail('formLogin');
      setTypeMenu('formLogin');
    }
  }, [])

  useEffect(() => {
    const updateTitle = () => {
      if (typeEmail === 'detailEmail' || typeEmail === 'threadedEmail') {
        const truncatedSubject = currentSubject.length > 30 ? currentSubject.slice(0, 30) + '...' : currentSubject;
        document.title = `${truncatedSubject} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'formLogin') {
        document.title = 'Wooblazz Mail - Login'
      } else if (typeMenu === 'inbox') {
        document.title = `Inbox ${checkCountZeroQtySidebar(qtySidebar.inbox)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'sent') {
        document.title = `Sent - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'draft') {
        document.title = `Draft ${checkCountZeroQtySidebar(qtySidebar.draft)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'trash') {
        document.title = `Trash ${checkCountZeroQtySidebar(qtySidebar.trash)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'archive') {
        document.title = `Archive ${checkCountZeroQtySidebar(qtySidebar.archive)} - ${userEmail?.email} - Wooblazz Mail`
      } else if (typeMenu === 'spam') {
        document.title = `Spam ${checkCountZeroQtySidebar(qtySidebar.spam)} - ${userEmail?.email} - Wooblazz Mail`
      } else {
        document.title = 'Wooblazz Mail'
      }
    }

    const timeoutId = setTimeout(updateTitle, 100)

    return () => clearTimeout(timeoutId)
  }, [typeMenu, setTypeMenu, typeEmail, currentSubject, userEmail?.email, qtySidebar])

  const fetcher = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          'X-auth-token': 'Bearer ' + tokenEmail,
        },
      })
      return response.data.data
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        setTypeEmail('formLogin')
        setTypeMenu('formLogin')
        setTokenEmail('')
        clearUserEmail()
      } else {
        throw error
      }
    }
  }

  const {
    data: dataCountEmails = [], // berisi sebuah data yang menampung object type mailbox : sent/inbox/archive, dan unseenCount
    mutate: countEmailsMutate,
    isValidating: countEmailIsValidating,
  } = useSWR(!isApiError && tokenEmail ? `${API_URL_EMAIL}dashboard/count-emails` : null, fetcher)

  const updateQtySidebar = useCallback(() => {
    if (!countEmailIsValidating) {
      setQtySidebar({
        inbox: dataCountEmails[0]?.unseenCount || 0,
        // sent: dataCountEmails[1]?.unseenCount || 0, 
        trash: dataCountEmails[2]?.unseenCount || 0,
        draft: dataCountEmails[5]?.unseenCount || 0,
        archive: dataCountEmails[4]?.unseenCount || 0,
        spam: dataCountEmails[3]?.unseenCount || 0,
      });
    }
  }, [typeEmail, countEmailIsValidating]);

  useEffect(() => {
    updateQtySidebar();
  }, [updateQtySidebar]);

  useEffect(() => {
    if (tokenEmail) {
      setTypeEmail('listEmail')
    } else setTypeEmail('formLogin')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const btnLogoutEmail = () => {
    Swal.fire({
      title: 'Keluar',
      text: 'Apakah Anda yakin ingin keluar?',
      icon: 'warning',
      showCancelButton: !0,
      buttonsStyling: !1,
      confirmButtonText: 'Ya, keluar',
      cancelButtonText: 'Tidak',
      customClass: {
        confirmButton: 'btn btn-danger',
        cancelButton: 'btn btn-active-light me-3',
      },
      reverseButtons: true,
      heightAuto: false,
      preConfirm: () => {
        setTokenEmail('')
        clearUserEmail()
        setTypeEmail('formLogin')
      },
    })
  }

  const handleSaveEmailToDraft = async () => {
    try {
      const response = await saveDraft(
        valueSaveDraft.from,
        valueSaveDraft.to,
        valueSaveDraft.subject,
        valueSaveDraft.body.trim(),
        valueSaveDraft.cc,
        valueSaveDraft.bcc,
        valueSaveDraft.files
      )
      if (response?.status === 200) {
        setValuesSaveDraft({
          from: userEmail?.email || '',
          to: [],
          subject: '',
          body: '',
          cc: '',
          bcc: '',
          files: [],
        })
      }
    } catch (error) {
      console.log(error)
    } finally {
      countEmailsMutate()
    }
  }

  useEffect(() => {
    countEmailsMutate()
  }, [typeMenu])

  useEffect(() => { // clear value ketika pindah menu
    if (dataEditValuesBroadcast && typeMenu !== 'sendMail') {
      setDataEditValuesBroadcast(null)
    }
  }, [typeMenu])

  useEffect(() => {
    if (typeEmail !== 'sendEmail') {
      setValuesFromDraft({ // untuk clear values from draft ketika pindah menu
        from: '',
        to: '',
        cc: '',
        bcc: '',
        subject: '',
        body: '',
        attachments: [],
        date: '',
      })
    }
  }, [typeMenu, openSendEmail])

  const handleDeleteEmailOnDraft = async () => {
    try {
      const emailId = valuesFromDraft?.id
      const response = await deleteEmail(emailId, 'draft')
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // ini untuk ketika pindah menu email akan save ke draft
    if (
      typeMenu !== 'sendMail' &&
      (valueSaveDraft.to.length > 0 ||
        valueSaveDraft.body.length > 1 ||
        valueSaveDraft.subject ||
        (valueSaveDraft.files && valueSaveDraft.files.length >= 1))
    ) {
      if (valuesFromDraft?.id) {
        handleDeleteEmailOnDraft()
      }
      handleSaveEmailToDraft()
      countEmailsMutate()
      // ketika pindah menu setOpenSendEmail(false) <- ini di gunakan untuk conditional render ketika detailEmail ingin di edit jika true maka render emailValueFromDraft
      setOpenSendEmail(false)
    }
  }, [typeMenu])

  const handleRouteBroadcast = () => {
    if (location.pathname === '/broadcast') {
      setTypeEmail('broadcastEmail')
    } else if (location.pathname === '/group-contact') {
      setTypeEmail('groupEmail')
    }
  }

  useEffect(() => {
    handleRouteBroadcast()
  }, [location])

  const handleShowSideBar = () => {
    setShowSideBar((prevState: boolean) => !prevState)
  }

  return (
    <>
      {typeEmail === '' ? (
        <></>
      ) : typeEmail === 'formLogin' ? (
        <LoginEmail />
      ) : (
        <div className='d-flex flex-column flex-lg-row'>
          <SideBarMobile
            activeSidebar={activeSidebar}
            btnLogoutEmail={btnLogoutEmail}
            qtySidebar={qtySidebar}
          />
          <SideBarDesktop
            qtySidebar={qtySidebar}
            btnLogoutEmail={btnLogoutEmail}
          />
          <div className='d-flex flex-column flex-grow-1'>
            <div className={`email-body-content pb-5 ${noNavbar.includes(typeEmail) ? '' : 'container-fluid mt-5'}`}>
              {
                noNavbar.includes(typeEmail) && (
                  <div style={{ height: '5em', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }} className='d-flex bg-white container-fluid sticky-top mb-5'>
                    <span className='cursor-pointer nav-hamburg d-lg-none d-block my-auto' onClick={handleShowSideBar}>
                      <img width={30} src='/media/svg/general/navHamburg.svg' alt='hamburg-nav' />
                    </span>
                    <span className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
                      {/* Navbar untuk mobile */}
                      <ProfileAvatar />
                    </span>
                  </div>
                )
              }
              {typeEmail === 'listEmail' ? (
                <CardEmail typeMenu={typeMenu} />
              ) : typeEmail === 'sendEmail' ? (
                <div className='m-5'>
                  <CardSendEmail countEmailsMutate={countEmailsMutate} />
                </div>
                // <CardSendEmail />
              ) : typeEmail === 'threadedEmail' ? (
                <ThreadedEmailDetail threadedEmails={Array.isArray(threadEmail) ? threadEmail : [threadEmail]} />
                // <ThreadedEmailDetail threadedEmails={Array.isArray(threadEmail) ? threadEmail : [threadEmail]} />
              ) : typeEmail === 'detailEmail' ? (
                <DetailEmail typeMenu={typeMenu} countEmailsMutate={countEmailsMutate} />
                // <DetailEmail typeMenu={typeMenu} />
              ) : typeEmail === 'groupEmail' ? (
                <div className='m-5'>
                  <GroupEmail />
                </div>
              ) : typeEmail === 'detailGroupEmail' ? (
                <div className='m-5'>
                  <DetailGroupEmail />
                </div>
              ) : typeEmail === 'broadcastEmail' ? (
                <div className='m-5'>
                  <BroadcastEmail />
                </div>
              ) : typeEmail === 'detailBroadcastEmail' ? (
                <div className='m-5'>
                  <DetailBroadcastEmail />
                </div>
              ) : typeEmail === 'detailDraftBroadcastEmail' ? (
                <div className='m-5'>
                  <DetailDraftBroadcast />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { Emails }
