import React, { useEffect, useRef } from 'react'
import { ScanQR } from '../ScanQR'
import useUser from '../../../hooks/User'
import { useChatStore } from '../../../stores/ChatStore'
import useModalEffect from '../../../hooks/useModalEffect'
import { Modal } from 'bootstrap'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { ListChatPhone } from '../../../interfaces/Chat/ListChatPhone'
import { checkSession, checkSessionByPhone } from '../../../api/ChatHelper'
import useAccessToken from '../../../hooks/AccessToken'
import Swal from 'sweetalert2'
import { mutate } from 'swr'

interface ModalSqanQrProps {
    data: ListChatPhone
}

const ModalSqanQr = ({ data }: ModalSqanQrProps) => {
    const API_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const { connected } = useChatStore()
    const modalRef = useRef<HTMLDivElement>(null)
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    const checkConnection = async () => {
        try {
            const response = await checkSessionByPhone(data.phone)

            if (response.data.status) {
                Swal.fire({
                    icon: 'success',
                    title: 'Berhasil terhubung ke WhatsApp',
                    showConfirmButton: false,
                    timer: 1500
                })

                mutate(`${API_URL}v2/chat/auth/phones`)
                modalRef.current?.click()
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!connected) {
            intervalRef.current = setInterval(checkConnection, 3000);
        } else if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [connected]);

    return (
        <div className='modal' tabIndex={-1} id='scan-qr-modal' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Scan QR Code Nomor {data.phone}</h5>
                        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={() => modalRef.current?.click()}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className='modal-body'>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="card card-custom w-100" style={{ maxWidth: "800px" }}>
                                <div className="card-header text-center">
                                    <img className="img-fluid py-5" src={toAbsoluteUrl('/media/logos/wooblazz-chat.png')} alt='WooBlazz CRM Logo' style={{ width: "24%", height: "fit-content" }} />
                                </div>
                                <div className="card-body row">
                                    <div className="col-md-7">
                                        <h1>Cara Scan QR Code di WhatsApp</h1>
                                        <br />
                                        <ol className="fs-5">
                                            <li>Buka WhatsApp di ponsel Anda.</li>
                                            <li>Tekan Menu untuk di Android, atau Settings di iPhone</li>
                                            <li>Tekan Linked devices lalu Link a device</li>
                                            <li>Arahkan ponsel Anda ke layar ini untuk mengscan kode QR</li>
                                        </ol>
                                    </div>
                                    <div className="col-md-5 d-flex justify-content-center align-items-center">
                                        <ScanQR phone={data.phone} />
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSqanQr