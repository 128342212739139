import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { customNumberFormat, getProgressColor } from "../../functions/general";
import { TopUser } from "../../interfaces/TopUser";
import { useIntl } from "react-intl";
import { useSettingsStore } from "../../stores/SettingsStore";
import { useCustomeStore } from "../../stores/CustomeStore";
import useUser from "../../hooks/User";

interface UsersPerformanceProps {
    topUsers: TopUser[],
    filter: 'nominal' | 'progress' | 'product' | 'meeting',
    performanceLoading: boolean,
    performanceValidating: boolean,
}

const TopUsersWidget: FC<UsersPerformanceProps> = ({ topUsers, filter, performanceLoading, performanceValidating }) => {
    const intl = useIntl()
    const { settings } = useSettingsStore()
    const { companyId } = useCustomeStore()
    const user = useUser()

    return (
        <> {
            (performanceLoading || performanceValidating) ?
                <Skeleton width={60} height={60} inline /> :
                <>
                    <div className="row align-items-center mt-6">
                        {
                            topUsers.map((dataUser, index) => {
                                return (
                                    <div className="col-sm-4 text-center">
                                        {/* <div className="card-ranking p-5 mb-2">
                                            <img alt="badge" className="h-40px badge-top" src={`/media/assets/top-${index + 1}.png`} />
                                            <img className="img-fluid rounded-circle border border-primary border-3 img-user mb-5" src={topUsers[index].user_profile_picture ?? "/media/assets/default-profile.jpg"} onError={(e) => { e.currentTarget.src = "/media/assets/default-profile.jpg"; e.currentTarget.onerror = null; }} alt="user" />

                                            <h3 className="heading">{topUsers[index].user_name}</h3>
                                            <span className="text-1">{intl.formatMessage({ id: "FORM.LABEL.TOTAL_REVENUE" })}</span>
                                            {
                                                filter === 'nominal' || filter === 'product' || filter === 'meeting' ?
                                                    <>
                                                        <h3 className="text-nominal">Rp {customNumberFormat(topUsers[index].progress)}</h3>
                                                        <span className="text-2">
                                                            Target Rp {customNumberFormat(topUsers[index].target ?? 0)}
                                                        </span>
                                                    </>
                                                    :
                                                    <div className='d-flex flex-column w-100 me-2'>
                                                        <div className='d-flex justify-content-center flex-stack mb-2'>
                                                            <span className='text-muted me-2 fs-7 fw-bold'>{progressBar <= 100 ? progressBar : 100}%</span>
                                                        </div>
                                                        <div className='progress h-6px w-100'>
                                                            <div
                                                                className={`progress-bar ${getProgressColor(progressBar)}`}
                                                                role='progressbar'
                                                                style={{ width: `${progressBar}%` }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                            }
                                        </div> */}

                                        <div className="card mb-5" style={{ backgroundColor: "#f5f8fa", borderRadius: "20px" }}>
                                            <div className="card-body d-flex flex-center flex-column pt-12 px-5 py-9">
                                                <div className="symbol symbol-100px symbol-circle mb-5">
                                                    <img className="img-fluid rounded-circle img-user mb-5" src={topUsers[index].user_profile_picture ?? "/media/assets/default-profile.jpg"} onError={(e) => { e.currentTarget.src = "/media/assets/default-profile.jpg"; e.currentTarget.onerror = null; }} alt="user" />
                                                    <img alt="badge" className="position-absolute h-40px w-30px translate-middle start-100 top-75 ms-n3 mt-n3 badge-top" src={`/media/assets/top-${index + 1}.png`} />
                                                </div>
                                                <div className="fs-4 text-gray-800 fw-bolder">{topUsers[index].user_name}</div>
                                                <div className="fw-bold text-gray-400 mb-6">{topUsers[index].role_name}</div>
                                                {
                                                    !(user.data.company_id === companyId.kawasaki1 || user.data.company_id === companyId.kawasaki2) ?
                                                        <>
                                                            <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                                <div className="fw-bold text-gray-400 mb-3">{intl.formatMessage({ id: "FORM.LABEL.TOTAL_REVENUE" })}</div>
                                                                {
                                                                    filter === 'nominal' || filter === 'product' || filter === 'meeting' ?
                                                                        <div>
                                                                            <div className="fs-5 fw-bolder text-primary">Rp {customNumberFormat(topUsers[index].progress_revenue)}</div>
                                                                            {user.data.company_id !== companyId.tkd && <div className="fs-7 fw-bold text-gray-700">Target Rp {customNumberFormat(topUsers[index].target_revenue ?? 0)}</div>}
                                                                        </div>
                                                                        :
                                                                        <div className='d-flex flex-column w-100 me-2'>
                                                                            <div className='d-flex justify-content-center flex-stack mb-2'>
                                                                                <span className='text-gray-700 fs-7 fw-bolder'>{topUsers[index].progress_revenue <= 100 ? topUsers[index].progress_revenue : 100}%</span>
                                                                            </div>
                                                                            <div className='progress h-6px w-100 bg-white'>
                                                                                <div className={`progress-bar ${getProgressColor(topUsers[index].progress_revenue)}`} role='progressbar' style={{ width: `${topUsers[index].progress_revenue}%` }}></div>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>

                                                            <div className="d-flex flex-center flex-wrap">
                                                                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                                    <div className="fw-bold text-gray-400 mb-3">{intl.formatMessage({ id: "FORM.LABEL.PRODUCT_SOLD" })}</div>
                                                                    {
                                                                        filter === 'nominal' || filter === 'product' || filter === 'meeting' ?
                                                                            <div>
                                                                                <div className="fs-5 fw-bolder text-primary">{customNumberFormat(topUsers[index].progress_sold_product)}</div>
                                                                                {user.data.company_id !== companyId.tkd && <div className="fs-7 fw-bold text-gray-700">Target {customNumberFormat(topUsers[index].target_sold_product ?? 0)}</div>}
                                                                            </div>
                                                                            :
                                                                            <div className='d-flex flex-column w-100 me-2'>
                                                                                <div className='d-flex justify-content-center flex-stack mb-2'>
                                                                                    <span className='text-gray-700 fs-7 fw-bolder'>{topUsers[index].progress_sold_product <= 100 ? topUsers[index].progress_sold_product : 100}%</span>
                                                                                </div>
                                                                                <div className='progress h-6px w-100 bg-white'>
                                                                                    <div className={`progress-bar ${getProgressColor(topUsers[index].progress_sold_product)}`} role='progressbar' style={{ width: `${topUsers[index].progress_sold_product}%` }}></div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                                    <div className="fw-bold text-gray-400 mb-3">{intl.formatMessage({ id: "FORM.LABEL.COUNT_MEETING" }, { title: settings.meeting_title })}</div>
                                                                    {
                                                                        filter === 'nominal' || filter === 'product' || filter === 'meeting' ?
                                                                            <div>
                                                                                <div className="fs-5 fw-bolder text-primary">{customNumberFormat(topUsers[index].progress_meeting)}</div>
                                                                                {user.data.company_id !== companyId.tkd && <div className="fs-7 fw-bold text-gray-700">Target {customNumberFormat(topUsers[index].target_meeting ?? 0)}</div>}
                                                                            </div>
                                                                            :
                                                                            <div className='d-flex flex-column w-100 me-2'>
                                                                                <div className='d-flex justify-content-center flex-stack mb-2'>
                                                                                    <span className='text-gray-700 fs-7 fw-bolder'>{topUsers[index].progress_meeting <= 100 ? topUsers[index].progress_meeting : 100}%</span>
                                                                                </div>
                                                                                <div className='progress h-6px w-100 bg-white'>
                                                                                    <div className={`progress-bar ${getProgressColor(topUsers[index].progress_meeting)}`} role='progressbar' style={{ width: `${topUsers[index].progress_meeting}%` }}></div>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                            <div className="fw-bold text-gray-400 mb-3">{intl.formatMessage({ id: "FORM.LABEL.PRODUCT_SOLD" })}</div>
                                                            {
                                                                filter === 'nominal' || filter === 'product' || filter === 'meeting' ?
                                                                    <div>
                                                                        <div className="fs-5 fw-bolder text-primary">{customNumberFormat(topUsers[index].progress_sold_product)}</div>
                                                                    </div>
                                                                    :
                                                                    <div className='d-flex flex-column w-100 me-2'>
                                                                        <div className='d-flex justify-content-center flex-stack mb-2'>
                                                                            <span className='text-gray-700 fs-7 fw-bolder'>{topUsers[index].progress_sold_product <= 100 ? topUsers[index].progress_sold_product : 100}%</span>
                                                                        </div>
                                                                        <div className='progress h-6px w-100 bg-white'>
                                                                            <div className={`progress-bar ${getProgressColor(topUsers[index].progress_sold_product)}`} role='progressbar' style={{ width: `${topUsers[index].progress_sold_product}%` }}></div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
        } </>
    );
}

export { TopUsersWidget }