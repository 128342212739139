import { useState, useCallback, useEffect } from 'react';
import { insertEmailAttachmentMulti } from '../../../api/ResourceAPI';
import { ContentTemplateAttachment } from '../../../interfaces/Email/BroadCastEmailType';

export const useAttachment = (initialAttachments: (File | ContentTemplateAttachment)[][] = [[]]) => {
  const [temporaryCopyFile, setTemporaryCopyFile] = useState<(File | ContentTemplateAttachment)[][]>(initialAttachments);
  const [dragAndDropFile, setDragAndDropFile] = useState<(File | ContentTemplateAttachment)[]>([]);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [attachments, setAttachments] = useState<(File | ContentTemplateAttachment)[][]>(initialAttachments);

  useEffect(() => {
    const newTotalSize = attachments.reduce((total, templateAttachments) => 
      total + templateAttachments.reduce((templateTotal, file) => 
        templateTotal + (file instanceof File ? file.size : file.size), 0
      ), 0
    );
    setTotalFileSize(newTotalSize);
  }, [attachments]);

  const handleOpenAttachment = (index: number) => {
    setShowModal(true);
    setDragAndDropFile(attachments[index] || []);
  };

  const handleCloseModal = () => setShowModal(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file: File) => file);
      setDragAndDropFile(newFiles);
    },
    []
  );

  // melakukan copy dari temporaryCopyFile ke attachments
  useEffect(() => {
    setAttachments(temporaryCopyFile);
  }, [temporaryCopyFile]);

  const removeFile = (templateIndex: number, fileIndex: number) => {
    setAttachments(prevAttachments => {
      const newAttachments = [...prevAttachments];
      newAttachments[templateIndex] = newAttachments[templateIndex].filter((_, i) => i !== fileIndex);
      return newAttachments;
    });
  };

  const uploadAttachments = async () => {
    if (attachments.length === 0) return [];

    const uploadedAttachments: ContentTemplateAttachment[][] = [];

    for (let i = 0; i < attachments.length; i++) {
      const templateAttachments = attachments[i];
      if (templateAttachments.length === 0) {
        uploadedAttachments.push([]);
        continue;
      }

      const formData = new FormData();
      const filesToUpload = templateAttachments.filter((item): item is File => item instanceof File);
      filesToUpload.forEach(file => {
        formData.append('files', file);
      });

      try {
        if (filesToUpload.length > 0) {
          const response = await insertEmailAttachmentMulti(formData);
          const newUploadedFiles = response.data.data.map((item: string, index: number) => ({
            source: item,
            name: filesToUpload[index].name,
            size: filesToUpload[index].size
          }));
          uploadedAttachments.push([
            ...templateAttachments.filter((item): item is ContentTemplateAttachment => 'source' in item),
            ...newUploadedFiles
          ]);
        } else {
          uploadedAttachments.push(templateAttachments as ContentTemplateAttachment[]);
        }
      } catch (error) {
        console.error(`Error uploading attachments for template ${i}:`, error);
        uploadedAttachments.push([]);
      }
    }

    return uploadedAttachments;
  };

  return {
    temporaryCopyFile,
    setTemporaryCopyFile,
    dragAndDropFile,
    setDragAndDropFile,
    totalFileSize,
    setTotalFileSize,
    showModal,
    handleOpenAttachment,
    handleCloseModal,
    onDrop,
    removeFile,
    uploadAttachments,
    attachments,
    setAttachments,
  };
};