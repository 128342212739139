import { FC, useEffect, useState } from "react";
import { useChatStore } from "../../stores/ChatStore";
import useUser from "../../hooks/User";
import { customNumberFormat, getDateSummary } from "../../functions/general";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { SelectOption } from "../../interfaces/SelectOption";
import { RecentChatHistory, RecentChatHistoryPipeline } from "../../interfaces/Chat/RecentChatHistory";
import { useSettingsStore } from "../../stores/SettingsStore";
import { LogWidget } from "../Pipelines/Logs/LogWidget";
import { AddLog } from "../Pipelines/Logs/AddLog";
import { useIntl } from "react-intl";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface Props {
    setOpenDetailContact: (value: boolean) => void
}

const ChatWindowTitle: FC<Props> = ({ setOpenDetailContact }) => {
    const { settings } = useSettingsStore()
    const user = useUser()
    const intl = useIntl()
    const { phoneNumber, recentChatHistories, recentChatSelectedPipeline, setRecentChatSelectedPipeline, selectedChatPhone } = useChatStore()
    const [pipelines, setPipelines] = useState<SelectOption[]>([])
    const recentChat = recentChatHistories.find(recentChat => `${recentChat.chat_phone}-${recentChat.as}` === phoneNumber)

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <span className="d-flex gap-2 align-items-center" style={{ fontSize: 'small' }}>
                    <span className="badge" style={{ backgroundColor: props.data.progress_color ?? 'grey' }}>
                        {props.data.lead_progress_name}
                    </span>
                    <b>{props.data.merchant_name}</b>
                    <span>/</span>
                    <span>Rp. {customNumberFormat(Math.round(props.data.nominal))}</span> - <span>{props.data.age}</span>
                </span>
            </components.Option>
        );
    };

    const CustomSingleValue = (props: any) => {
        return (
            <components.SingleValue {...props}>
                <span className="d-flex gap-2 align-items-center" style={{ fontSize: 'small' }}>
                    <span className="badge" style={{ backgroundColor: props.data.progress_color ?? 'grey' }}>
                        {props.data.lead_progress_name}
                    </span>
                    <b>{props.data.merchant_name}</b>
                    <span>/</span>
                    <span>Rp. {customNumberFormat(Math.round(props.data.nominal))}</span> - <span>{props.data.age}</span>
                </span>
            </components.SingleValue>
        );
    };

    const handlePipelineChange = (selectedOptions: any) => {
        setRecentChatSelectedPipeline(recentChat?.pipelines?.find(pipeline => pipeline.id === selectedOptions.value)!)
        let pipeline = recentChat?.pipelines?.find(pipeline => pipeline.id === selectedOptions.value)
        if (pipeline) pipeline.merchant_name = recentChat?.merchant_name!
        setRecentChatSelectedPipeline(pipeline ?? {} as RecentChatHistoryPipeline);
    }

    const handleDetailContact = () => {
        setOpenDetailContact(true)
    }

    useEffect(() => {
        if (recentChat?.pipelines) {
            let dataRecentChat = [...recentChat.pipelines].sort((a: any, b: any) => {
                const dateA = new Date(a.date_created).getTime();
                const dateB = new Date(b.date_created).getTime();
                return dateB - dateA;
            });

            setPipelines(
                dataRecentChat.map(pipeline => ({
                    value: pipeline.id,
                    label: `${pipeline.merchant_name}-${pipeline.lead_progress_name}-${customNumberFormat(pipeline.nominal)}-${getDateSummary(pipeline.age)}`,
                    merchant_name: pipeline.merchant_name,
                    lead_progress_name: pipeline.lead_progress_name,
                    nominal: pipeline.nominal,
                    age: getDateSummary(pipeline.age),
                    progress_color: pipeline.lead_progress_color,
                }))
            );

            setRecentChatSelectedPipeline(recentChat.pipelines[0]);
        } else {
            setRecentChatSelectedPipeline({} as RecentChatHistoryPipeline);
        }
    }, [phoneNumber, recentChat?.pipelines]);

    let name = ''
    if (recentChat) {
        if (recentChat?.chat_phone !== user.data.chat_phone) {
            if (recentChat?.chat_name) name = recentChat?.chat_name
            else name = recentChat?.chat_phone
        } else {
            name = user.data.name + ' (You)'
        }
    }

    return (
        <div className='card-title'>
            <div className='d-flex justify-content-center flex-column'>
                <div className="d-flex gap-2 align-items-center mb-2">
                    {
                        recentChat && selectedChatPhone.length > 1 &&
                        <OverlayTrigger
                            key={`bullet-${recentChat.chat_id}`}
                            placement='bottom'
                            overlay={
                                <Tooltip id='tooltip-labels'>
                                    <p><b>{recentChat.as}</b></p>
                                    <span>{selectedChatPhone.find(item => item.phone === recentChat.as)?.ownersName.join(', ')}</span>
                                </Tooltip>}
                        >
                            <div className="d-flex align-items-center">
                                <span className="bullet bullet-dot h-10px w-10px" style={{ backgroundColor: recentChat.color }}></span>
                            </div>
                        </OverlayTrigger>
                    }

                    <span className='fs-4 fw-bolder text-gray-900 lh-1 text-hover-primary cursor-pointer' onClick={handleDetailContact}>
                        {name}
                    </span>
                </div>
                {
                    recentChat?.merchant_name_active &&
                    <i>{recentChat?.merchant_name_active}</i>
                }
                {/* {
                    (settings.chat_show_pic_only && !user.data.is_superadmin && recentChat?.merchant_id) ?
                        <i>{recentChat?.merchant_id}</i>
                        : recentChat?.merchant_name_active &&
                        <i>{recentChat?.merchant_name_active}</i>
                } */}
                {
                    recentChat?.pipelines &&
                    recentChat?.pipelines.length > 0 &&
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: "#F5F8FA",
                                backgroundColor: "#F5F8FA",
                                borderRadius: "0.475rem",
                                width: "fit-content",
                                minWidth: "150px",
                                boxShadow: 'none',
                                '&:hover': {
                                    borderColor: "#F5F8FA",
                                    backgroundColor: "#F5F8FA",
                                },
                            }),
                            option: (baseStyles, { isSelected, isFocused }) => ({
                                ...baseStyles,
                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                cursor: 'pointer',
                            }),
                            indicatorSeparator: (baseStyles, state) => ({
                                ...baseStyles,
                                display: 'none',
                            }),
                            menuList: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "fit-content",
                                minWidth: "150px",
                            }),
                            menu: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "fit-content",
                                minWidth: "150px",
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'none',
                            },
                        })}
                        value={pipelines.find(pipeline => pipeline.value === recentChatSelectedPipeline?.id)}
                        // closeMenuOnSelect={true}
                        placeholder={`${intl.formatMessage({ id: "FORM.LABEL.CHOOSE" })} ${settings.pipeline_title}`}
                        onChange={handlePipelineChange}
                        options={pipelines}
                        components={{
                            Option: CustomOption,
                            SingleValue: CustomSingleValue,
                        }}
                        className="my-2"
                    />
                }
                {/* <div className='mb-0 lh-1'>
                    <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                    <span className='fs-7 fw-bold text-gray-400'>Active</span>
                </div> */}
            </div>
        </div>
    )
}

export { ChatWindowTitle }