import {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useDataTableStore} from '../../../stores/DataTableStore'
import Swal from 'sweetalert2'
import {Product} from '../../../interfaces/Product'
import useAccessToken from '../../../hooks/AccessToken'
import {useIntl} from 'react-intl'
import {ISection} from '../../../interfaces/Analytics/Analytics'
import {deleteSection} from '../../../api/Analitik/SectionCRUD'
import { useAnalyticsStore } from '../../../stores/Analytics/AnalyticStore'

interface IProps {
  section: ISection
}

const DeleteSection: FC<IProps> = ({section}) => {
  const closeModalRef = useRef<HTMLButtonElement>(null)

  const [modalBtnLoading, setModalBtnLoading] = useState(false)
  const {tablesData, setTablesData} = useDataTableStore()
  const token = useAccessToken()
  const {setSections, sections}= useAnalyticsStore()

  const intl = useIntl()

  const handleDeleteSection = async () => {
    setModalBtnLoading(true)
    try {
      const response = await deleteSection(section.sectionId, token)
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: intl.formatMessage({id: 'ALERT.TITLE.SUCCESS'}),
          text: intl.formatMessage({id: 'FORM.VALIDATION.DELETE_SUCCESS'}),
          heightAuto: false,
        })
        closeModalRef.current?.click()
        setSections(sections.filter((s:ISection)=>s.sectionId!==section.sectionId))

        // setTablesData('products', (tablesData.products.filter((data) => data.id !== product.id)))
      }
    } catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: error.response.data.message,
        confirmButtonText: 'Ok',
      })
    } finally {
      setModalBtnLoading(false)
    }
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id={`delete-section-modal-${section.sectionId}`}>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {intl.formatMessage({id: 'FORM.ACTION.DELETE'})} {section.name}
              </h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <p>
                {intl.formatMessage({id: 'FORM.CONFIRMATION'})}{' '}
                {intl.formatMessage({id: 'FORM.ACTION.DELETE'}).toLowerCase()} {section.name}
                ?
              </p>
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-light'
                data-bs-dismiss='modal'
                ref={closeModalRef}
              >
                {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})}
              </button>
              <button
                type='button'
                className={`btn btn-danger me-10 ${modalBtnLoading ? 'disabled' : ''}`}
                data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                onClick={handleDeleteSection}
              >
                <span className='indicator-label'>
                  {intl.formatMessage({id: 'FORM.ACTION.DELETE'})}
                </span>
                <span className='indicator-progress'>
                  Loading...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {DeleteSection}
