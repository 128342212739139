import { addDays } from 'date-fns'
import React from 'react'
import { DateRangePicker } from 'rsuite'
import { DateRangePickerProps} from 'rsuite/esm/DateRangePicker'

interface IDateRangePickerInterval {
    timeSeries: string
    value: [Date, Date] | null
    onChange: DateRangePickerProps['onChange']
  }

export default function DateRangePickerInterval  ({timeSeries, value, onChange}: IDateRangePickerInterval) {

    if (timeSeries === 'WEEKLY') {
      return (
        <DateRangePicker
          hoverRange='week'
          isoWeek
          ranges={[]}
          value={value}
          onChange={onChange}
          placement='topStart'
        />
      )
    }
    if (timeSeries === 'MONTHLY') {
      return (
        <DateRangePicker
          hoverRange='month'
          ranges={[]}
          value={value}
          onChange={onChange}
          placement='topStart'
        />
      )
    }
    if(timeSeries ==="DAILY"){
      return (
        <DateRangePicker ranges={[]} value={value} onChange={onChange} placement='topStart' 
        disabledDate={(date, selectValue) => {
          if (selectValue?.length === 1 && selectValue[0]) {
            const startDate = selectValue[0];
            return date > addDays(startDate, 29) || date < startDate;
          }
          return false;
        }}
        />
      )
    }
    return <DateRangePicker ranges={[]} value={value} onChange={onChange} placement='topStart' />
  }
