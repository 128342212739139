import React, {useState} from 'react'
import {Dropdown} from 'rsuite'
import {IComponent, ISection} from '../../../interfaces/Analytics/Analytics'
import {useIntl} from 'react-intl'
import ComponentAnalytics from '../Component/ComponentAnalytics'
import { useAnalyticsStore } from '../../../stores/Analytics/AnalyticStore'

interface ISectionAnalytics {
  handleEditSection: (section: ISection) => void
  menu: ISection
  handleDeleteSection: (section: ISection) => void
  handleAddComponent: (section: ISection) => void
  components: IComponent[]
}

export default function SectionAnalytics({
  handleEditSection,
  menu,
  handleDeleteSection,
  handleAddComponent,
  components,
}: ISectionAnalytics) {
  const intl = useIntl()
  const renderIconButtonSection = (props: any, ref: any) => {
    return (
      <button {...props} ref={ref} className='btn btn-sm btn-icon btn-light'>
        <i className={`fas fs-8 fa-ellipsis-v`}></i>
      </button>
    )
  }
  const [showComponent, setShowComponent] = useState(true)
  const handleShowComponent = () => {
    setShowComponent(!showComponent)
  }

  const {iscomponentsLoading} = useAnalyticsStore() 

  return (
    <div>
      <div className='card mt-5'>
        <div className='card-body py-3 px-5 d-flex justify-content-between align-items-center'>
          <span className='text-gray-800 fs-3 fw-bolder'>{menu.name}</span>
          <div className='d-flex gap-2 align-items-center'>
            <button
              className='btn btn-sm btn-light me-2'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              onClick={() => handleAddComponent(menu)}
            >
              <i className='fas fa-plus'></i> {intl.formatMessage({id: 'FORM.ACTION.ADD'})}{' '}
              {intl.formatMessage({id: 'FORM.LABEL.COMPONENT'})}
            </button>
            <button className='btn btn-sm btn-icon btn-light' onClick={handleShowComponent}>
              {showComponent ? (
                <i className='fa fa-eye' aria-hidden='true'></i>
              ) : (
                <i className='fa fa-eye-slash' aria-hidden='true'></i>
              )}
            </button>

            <Dropdown renderToggle={renderIconButtonSection} placement='bottomEnd'>
              <Dropdown.Item
                onClick={() => {
                  handleEditSection(menu)
                }}
              >
                {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  handleDeleteSection(menu)
                }}
              >
                {intl.formatMessage({id: 'FORM.ACTION.DELETE'})}
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>
      <div>
        <div className='row py-3 px-5'>
        {iscomponentsLoading && showComponent && (
         <div className="loading-overlay" style={{ position: 'unset', background: 'unset' }}>
         <div className="loader" style={{ border: '8px solid #ffffff', borderTop: '8px solid #3498db' }}></div>
        </div>
          )}
          {showComponent && !iscomponentsLoading &&
            components.map((component, index) => (
              <div
                key={index}
                className={`mb-3 ${
                  components.length === 1
                    ? 'col-12 col-md-6'
                    : components.length === 2
                    ? 'col-12 col-md-6'
                    : 'col-12 col-md-6 col-lg-6 col-xl-6'
                }`}
              >
                <ComponentAnalytics component={component} section={menu} />
              </div>
            ))}
             {
               !iscomponentsLoading && components.length <= 0 && showComponent && (
                <>
                <div className=' card p-8'>
                <div className='w-100 h-100 d-flex justify-content-center align-items-center gap-4 flex-column'>
                  <i className='bi bi-emoji-frown' style={{fontSize: '12rem'}} />{' '}
                  <span style={{fontSize: '1.5rem', color: '#A1A5B7'}}>
                    {intl.formatMessage(
                      {id: 'FORM.CONFIRM.DATA_NOT_FOUND'},
                      {
                        title: intl.formatMessage({id: 'MENU.ANALYTICS'})}
                      )}
                  </span>
                  </div>
                  </div>
                </>
              )
            }
        </div>
      </div>
    </div>
  )
}
