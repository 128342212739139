import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import { DetailLimit } from "../../interfaces/ReimbursementLimit";
import moment from "moment";
import { getLimitDetail, updateLimit } from "../../api/ReimbursementLimitCRUD";
import { customNumberFormat, customNumberFormatString } from "../../functions/general";

interface Props {
    limit: DetailLimit
}

const UpdateReimbursementLimit: FC<Props> = ({ limit }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()
    const [detailLoading, setDetailLoading] = useState(true);
    const [checkHasReimbursement, setCheckHasReimbursement] = useState(true);

    async function handleUpdateLimit(values: any) {
        try {
            const response = await updateLimit(limit.id, Number(values.amount.replaceAll(".", "")), token)
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    heightAuto: false,
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                    timer: 2000,
                    timerProgressBar: true
                })
                closeModalRef.current?.click();
                setTablesData('reimbursement-limits', (tablesData['reimbursement-limits'].map(item => {
                    if (item.id === limit.id) {
                        return {
                            ...item,
                            amount: response.data.data.amount
                        }
                    } else {
                        return item
                    }
                })));
            }
        } catch (error: any) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            amount: customNumberFormat(limit.amount),
        },
        validationSchema: Yup.object().shape({
            amount: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LIMIT_NOMINAL" }) }))  // Moved this up so it runs first
                .test('is-numeric', 'Invalid format', value => {
                    return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
                }),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            
            if (checkHasReimbursement) {
                Swal.fire({
                    title: intl.formatMessage({ id: "ALERT.TITLE.WARNING" }),
                    text: intl.formatMessage({ id: "REIMBURSEMENT_LIMIT.WARNING.UPDATE" }),
                    icon: "warning",
                    showCancelButton: !0,
                    buttonsStyling: !1,
                    confirmButtonText: `${intl.formatMessage({ id: "FORM.CONFIRMATION.YES" })}, ${intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}!`,
                    cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-secondary me-2",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                }).then(async (result) => {
                    if (result.isConfirmed) await handleUpdateLimit(values)
                    else setModalBtnLoading(false)
                });
            }
            else await handleUpdateLimit(values)
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormatString(e.target.value);
        formik.setFieldValue('amount', formattedValue);
    };

    useEffect(() => {
        if (detailLoading) {
            console.log("fetch")
            const fetchDetailData = async () => {
                try {
                    const response = await getLimitDetail(
                        limit.id,
                        token
                    )
                    if (response.data.success) {
                        setCheckHasReimbursement(response.data.data.hasReimbursement)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        text: error.response.data.text?? "",
                        confirmButtonText: 'Ok',
                        heightAuto: false,
                    })
                } finally {
                    setDetailLoading(false)
                }
            }

            fetchDetailData()
        }
    }, [])

    return (
        <div className="modal fade" tabIndex={-1} id={`update-limit-modal-${limit.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {limit.userName}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_STARTED' })}</label>
                            <div className="input-group mb-4">
                                {moment(limit.period.start, 'YYYY-MM-DD').format('DD MMM YYYY')}
                            </div>
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_END' })}</label>
                            <div className="input-group mb-4">
                                {moment(limit.period.end, 'YYYY-MM-DD').subtract(1, "days").format('DD MMM YYYY')}
                            </div>
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LIMIT_NOMINAL' })}</label>
                            <div className="input-group mb-3">
                                {/* <div className="input-group group-sm me-3">
                                    <input type="text" className="form-control" value={customNumberFormat(data.qty) === "0" ? '' : customNumberFormat(data.qty)} onInput={(e) => handleCaps(e)} onChange={(e) => handleInputQtyUnit(index, e)} />
                                </div> */}
                                <span className="input-group-text">{limit.currency.symbol}</span>
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.LIMIT_NOMINAL' })}
                                    value={formik.values.amount}
                                    onChange={handleAmountChange}
                                    onBlur={formik.handleBlur}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.amount && formik.errors.amount },
                                    )}
                                    type='text'
                                    name='amount'
                                    autoComplete='off'
                                    style={{ zIndex: 0 }}
                                />
                            </div>
                            {formik.touched.amount && formik.errors.amount && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.amount}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading || detailLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading || detailLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateReimbursementLimit }