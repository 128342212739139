import React, { FC, useEffect, useMemo, useState } from "react";
import { CardPipeline } from "../../../components/Pipelines/CardPipeline";
import { LeadProgress } from "../../../interfaces/Progress";
import { Pipeline } from "../../../interfaces/Pipeline";
import {useSortable } from "@dnd-kit/sortable";
import { useProgressStore } from "../../../stores/ProgressStore";
import { Tooltip } from 'react-tippy';
import { useIntl } from "react-intl";
import { tailspin } from 'ldrs'
import {  DragOverlay} from "@dnd-kit/core";
import { useDNDPipelineStore } from "../../../stores/DNDPipelineStore";
import {createPortal} from 'react-dom';
import { useVirtualizer } from "@tanstack/react-virtual";
import { FakeCardPipeline } from "../../../components/Pipelines/FakeCardPipeline";

import { toRupiah } from "../../../functions/general";

interface MasterBoardPipelinesContentProps {
    id: string;
    dataProgress: LeadProgress;
    data: Pipeline[];
    setShowUpdateModal: (value: boolean) => void;
    setShowDeleteModal: (value: boolean) => void;
    setShowAddLogModal: (value: boolean) => void;
    setShowDetailProductModal: (value: boolean) => void;
    setShowDetailModal: (value: boolean) => void;
    setPipeline: (value: Pipeline) => void;
    style?:any
}


const MasterBoardPipelinesContent: FC<MasterBoardPipelinesContentProps> =
        ({
            id,
            dataProgress,
            data,
            setShowUpdateModal,
            setShowDeleteModal,
            setShowAddLogModal,
            setShowDetailProductModal,
            setShowDetailModal,
            setPipeline,
        }) => {

            tailspin.register()
            const intl = useIntl()
            const {currentDraggedPipelineCard, setCurrentDraggedPipelineCard, isCardDraggingg, setIsCardDragging, allowedContainerToDrop, currentProgressOver} = useDNDPipelineStore()
            const { progress, setProgress } = useProgressStore()
            const [isHovered, setIsHovered] = useState(false);
            const [isLoading, setIsLoading] = useState(false);
            const [isOver, setIsOver] = useState(false)
            const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
                useSortable({
                    id,
                    transition: {
                        duration: 300, 
                        easing: "cubic-bezier(0.25, 0.1, 0.25, 1)", 
                    },
                });
            
            const totalNominalPerProgress = data.reduce((acc,card)=>{return acc+=card.nominal},0)

            useEffect(()=>{
                if(id===currentProgressOver){
                    setIsOver(true)
                }else{
                    setIsOver(false)
                }
            },[currentProgressOver, id])    
                const style = useMemo(() => {
                    const isThisContainerAllowedtoDrop = allowedContainerToDrop.findIndex((idCandrop)=> idCandrop===id) === -1 ? false : true
                    const isThisContainerNotAllowedtoDrop = !isThisContainerAllowedtoDrop
                    let newStyle :React.CSSProperties= {
                      transition,
                      borderRadius: "15px",
                    };
                
                    if (isDragging) {
                      newStyle = {
                        ...newStyle,
                        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                        backgroundColor: "#ffffff",
                        zIndex: 1,
                      };
                    } else {
                      newStyle = {
                        ...newStyle,
                        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
                        backgroundColor: isOver && isThisContainerAllowedtoDrop ? "#eff7fb" : "#ffffff",
                        opacity:isThisContainerNotAllowedtoDrop && isCardDraggingg ? "30%": "100%",
                        cursor:isThisContainerNotAllowedtoDrop && isCardDraggingg ? "no-drop !important": "grabbing",

                      };
                    }
                
                    return newStyle;
                  }, [isDragging, transform, isOver, transition, isCardDraggingg, allowedContainerToDrop,id]);

            const sortedData = useMemo(
                () => data.sort((a, b) => dataProgress.sort === "desc" ? b.lead_age - a.lead_age : a.lead_age - b.lead_age),
                [data, dataProgress]
            );

            const handleSort = async(typeSort: string) => {
                setIsLoading(true)
                try {
                    var sortProgress = progress.map(item => {
                        if (item.id === dataProgress.id) {
                            return {
                                ...item,
                                sort: typeSort
                            };
                        }

                        return item;
                    });

                    setProgress(sortProgress);
                } finally {
                    setIsLoading(false)
                }
            }


            const handleDrag = (dataPipeline: Pipeline) => {
                    setCurrentDraggedPipelineCard(dataPipeline);
                    setIsCardDragging(true)
            };

            const parentRef = React.useRef<HTMLElement>(null);

            const rowVirtualizer = useVirtualizer({
                count: sortedData.length,
                getScrollElement: () => parentRef.current ?? null,
                estimateSize: () => 150, 
                overscan: 3,
                enabled:true,
            });
            const items = rowVirtualizer.getVirtualItems()
            return (
                <div ref={setNodeRef} style={style} className="list position-relative z-2" 
                onMouseEnter={() => setIsHovered(true)}
                 onMouseLeave={() => setIsHovered(false)}
                 >
                    <div  className="d-flex justify-content-between align-items-center" style={{ margin: "7px 0" }} >
                        <label className="list-title">
                            <span className="bullet bullet-dot h-10px w-10px me-3" style={{ backgroundColor: dataProgress.color ? dataProgress.color : "#7239EA" }} ></span>
                            {dataProgress.name}
                            <div className="btn btn-sm btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px ms-1">
                                {
                                    !isLoading ?
                                        dataProgress.sort === "desc" ?
                                            <i className="fas fa-sort-amount-up" onClick={() => handleSort("asc")}></i>
                                            :
                                            <i className="fas fa-sort-amount-down-alt" onClick={() => handleSort("desc")}></i>
                                        :
                                        <l-tailspin
                                            size="15"
                                            stroke="3"
                                            speed="0.9"
                                            color="#a1a5b7"
                                        ></l-tailspin>
                                }
                            </div>
                        </label>
                        <div className="d-flex align-items-center">
                            <label className={`list-count ${isHovered ? "me-1" : "me-3"}`}>{data.length}</label>
                            <Tooltip
                                followCursor={true}
                                title={`${intl.formatMessage({ id: 'FORM.ACTION.HOLD_AND_DRAG_TO_MOVE' })}`}
                                theme="light"
                                arrow={true}
                                className={`${isHovered ? "d-block" : "d-none"}`}
                                style={{ cursor: "default", display: "flex" }}
                            >
                                <div className="btn btn-sm btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px" {...attributes} {...listeners} >
                                    <i className="fas fa-grip-vertical"></i>
                                </div>
                            </Tooltip>

                        </div>
                    </div>
                    <section ref={parentRef} className="list-items  position-relative">
                    <div
                        style={{
                            height: rowVirtualizer.getTotalSize(),
                            width: '100%',
                            position: 'relative',
                        }}
                        >
                        <div
                            style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                            }}
                        >
                            {
                            rowVirtualizer.getVirtualItems().map((virtualRow) => {
                                const dataPipeline = sortedData[virtualRow.index];
                                return (
                                    <CardPipeline
                                    id={dataPipeline.id}
                                    key={dataPipeline.id}
                                    data={dataPipeline}
                                    setShowUpdateModal={setShowUpdateModal}
                                    setShowDeleteModal={setShowDeleteModal}
                                    setShowAddLogModal={setShowAddLogModal}
                                    setShowDetailProductModal={setShowDetailProductModal}
                                    setShowDetailModal={setShowDetailModal}
                                    setPipeline={setPipeline}
                                    onDrag={()=> {handleDrag(dataPipeline)}}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        transform: `translateY(${virtualRow.start}px)`
                                        }}
                                    />
                                )
                            })
                        }
                            </div>
                            </div>
                    </section>
                    {createPortal(<DragOverlay dropAnimation={null} style={{ opacity: 1 }}>
                        { currentDraggedPipelineCard ?
                        <div style={{background:"white", opacity:1}}>
                            <FakeCardPipeline data={currentDraggedPipelineCard}/>
                        </div>
                        :null} 
                        </DragOverlay> , document.body )}
                        <div className="h-100 d-flex justify-content-center list-title" >
                        <div className=" py-2 mt-5 ">
                        Total : {toRupiah(Math.round(totalNominalPerProgress))}
                        </div>
                    </div>
                </div>
            );
        }


export { MasterBoardPipelinesContent };