import React, { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { useDataTableStore } from "../../stores/DataTableStore";
import { SelectOption } from "../../interfaces/SelectOption";
import { TableColumn } from "react-data-table-component";
import { Pipeline } from "../../interfaces/Pipeline";
import { handleCaps, handleCapsSelect } from "../../functions/general";
import { useIntl } from "react-intl";

interface DataTableSelectProps {
    placeholder: string
    tableKey?: string
    filterKey: string
    options: SelectOption[]
    onChange: (value: any) => void // Change the onChange type to handle an array of values
    isNotMulti?: boolean
    closeMenuOnSelect?: boolean
    selectedOption?: object
    setSelectOption?: React.Dispatch<React.SetStateAction<SelectOption[]>>
    manageColumns?: TableColumn<Pipeline>[]
    setManageColumns?: React.Dispatch<React.SetStateAction<TableColumn<Pipeline>[]>>
}

const DataTableSelect: React.FC<DataTableSelectProps> = ({ placeholder, tableKey, filterKey, options, onChange, isNotMulti, closeMenuOnSelect, selectedOption, setSelectOption, manageColumns, setManageColumns }) => {
    const { selectedFilters, setSelectedFilter, selectedColumns, setSelectColumn } = useDataTableStore();
    const selected = options?.filter(option => selectedFilters?.[tableKey!]?.[filterKey]?.includes(option.value));
    const intl = useIntl()

    const handleChange = (selectedOptions: any) => {
        if (!isNotMulti) {
            const selectedValues = (selectedOptions as SelectOption[]).map(option => option.value);
            filterKey && setSelectedFilter(tableKey!, filterKey, selectedValues);

            onChange(selectedValues);

            if (filterKey === 'lead_progress_progress_type') {
                setSelectedFilter('pipelines', 'lead_progress_name', [])
            }
        }
        else onChange(selectedOptions)
    };

    const handleColumnChange = (selectedOptions: any, e: any) => {
        if (manageColumns && setManageColumns) {
            if (selectedOptions.length === 0) {
                const updatedColumns = manageColumns.map((data: TableColumn<Pipeline>) => ({
                    ...data,
                    omit: false,
                }));

                setManageColumns(updatedColumns)
            }
            else {
                var newData = manageColumns.map(item => ({ ...item, omit: true }));
                newData.map((data1: TableColumn<Pipeline>) =>
                    selectedOptions.map((data2: any) => {
                        if (data1.name === data2.value) {
                            data1.omit = false
                        }
                    }
                    ))

                setManageColumns([...newData])
            }

            setSelectColumn(tableKey!, selectedOptions)
        }

    }

    return (
        <>
            {
                filterKey === 'user_targets' ?
                    (
                        <div className="d-flex align-items-center">
                            <span className="me-2">{intl.formatMessage({ id: "FORM.LABEL.TARGET_TYPE" })}:</span>
                            <div className="p-1 flex-grow-1">
                                <Select
                                    openMenuOnClick
                                    closeMenuOnSelect={closeMenuOnSelect ?? false}
                                    placeholder={placeholder}
                                    isMulti={isNotMulti ? false : true}
                                    value={selectedOption} // Pass the selected option for the initial value
                                    onChange={handleChange}
                                    options={options}
                                    onInputChange={handleCapsSelect}
                                />
                            </div>
                        </div>
                    )
                    :
                    filterKey === 'column_pipelines' || filterKey === 'column_meetings' || filterKey === 'column_merchants' ?
                        (
                            <div className="p-1">
                                <Select
                                    openMenuOnClick
                                    closeMenuOnSelect={closeMenuOnSelect ?? false}
                                    placeholder={placeholder}
                                    isMulti={isNotMulti ? false : true}
                                    value={selectedColumns[tableKey!]} // Pass the selected option for the initial value
                                    onChange={handleColumnChange}
                                    options={options}
                                    onInputChange={handleCapsSelect}
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 1100
                                        })
                                    }}
                                />
                            </div>
                        )
                        :
                        (
                            <div className="p-1">
                                <Select
                                    openMenuOnClick
                                    closeMenuOnSelect={closeMenuOnSelect ?? false}
                                    placeholder={placeholder}
                                    isMulti={isNotMulti ? false : true}
                                    value={selectedOption ? selectedOption : selected} // Pass the selected option for the initial value
                                    onChange={handleChange}
                                    options={options}
                                    onInputChange={handleCapsSelect}
                                    // menuPortalTarget={filterKey == 'role_name' ? document.querySelector('body') : null}
                                    styles={{
                                        menu: (base) => ({
                                            ...base,
                                            zIndex: 1100
                                        })
                                    }}
                                />
                            </div>
                        )
            }
        </>
    );
};

export { DataTableSelect };
