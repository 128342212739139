import React, { ReactNode, useEffect, useState } from 'react'
import MasterTableEmail from '../table/MasterTableEmail'
import { useIntl } from 'react-intl'
import { TableColumn } from 'react-data-table-component'
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment-timezone'
import { Modal } from 'bootstrap'
import { useEmailStore } from '../../../../stores/EmailStore';
import { KTSVG } from '../../../../_metronic/helpers';
import { DeleteBroadcastEmail } from '../deleteModal/DeleteBroadcastEmail';
import { BroadcastEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType';
import Swal from 'sweetalert2';
import { getCreditBalance, pauseBroadcastEmail, resumeBroadcastEmail, stopBroadcastEmail } from '../../../../api/EmailCRUD';
import useSWR, { mutate } from 'swr';
import { useUserStore } from '../../../../hooks/UserEmail';
import useModalEffect from '../../../../hooks/useModalEffect';

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL;

const TabBroadcastEmail = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showUpdateDetailGroup, setShowUpdateDetailGroup] = useState<boolean>(false);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [selectedBroadcast, setSelectedBroadcast] = useState<BroadcastEmailTypes | null>(null);
  const intl = useIntl()
  const { setActiveSidebar, setTypeMenu, setTypeEmail, setToggleBroadcast, setIdDetailBroadcastEmail, setIsDetailActive, setDataEditValuesBroadcast, setTypeEditBroadcast, tokenEmail } = useEmailStore()
  const handleClick = (menuId: string) => setOpenMenuId(menuId);
  const handleClose = () => setOpenMenuId(null);
  const { userEmail } = useUserStore()

  const { data: creditBalance, isLoading: isLoadingCreditBalance } = useSWR(tokenEmail ? `${API_URL_EMAIL}dashboard/corporate-credit-balance?phone=${userEmail?.phone}` : null, () => getCreditBalance(userEmail?.phone || ''))


  const showWarning = (message: string) => {
    Swal.fire({
      icon: 'warning',
      title: 'Peringatan',
      text: message,
    });
  };

  const navigateToBroadcastCore = (row: BroadcastEmailTypes) => {
    setTypeEmail('sendEmail');
    setTypeMenu('sendMail');
    setToggleBroadcast(true);
    setActiveSidebar({
      inbox: '',
      sent: '',
      draft: '',
      trash: '',
      archive: '',
      spam: '',
      groupEmail: '',
      broadcastEmail: ''
    });
    if (row.broadcastStatus === 'PENDING') {
      setDataEditValuesBroadcast(row);
    }
    setTypeEditBroadcast('broadcastEmail');
  };

  const handleUpdateClick = (row: BroadcastEmailTypes) => {
    handleClose();
    if (row.broadcastStatus === 'PENDING') {
      navigateToBroadcastCore(row);
    } else {
      showWarning('Broadcast email ini tidak dapat diubah, Status Broadcast bukan pending!');
    }
  };

  const handleDeleteClick = (row: BroadcastEmailTypes) => {
    handleClose();
    if (row.broadcastStatus === 'RUNNING') {
      showWarning('Broadcast email ini tidak dapat dihapus, Broadcast sedang berjalan!');
    } else {
      setShowDeleteModal(true);
      setSelectedBroadcast(row);
    }
  };

  const handleActionClick = async (row: BroadcastEmailTypes, action: string) => {
    if (action === 'pause') {
      const response = await pauseBroadcastEmail(row.id)
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Broadcast berhasil di pause!',
        })
        mutate(`${API_URL_EMAIL}broadcast/get-broadcast?userId=${userEmail?.id}`)
        handleClose()
      } else {
        handleClose()
        showWarning('Broadcast gagal di pause!');
      }
    } else if (action === 'stop') {
      const response = await stopBroadcastEmail(row.id)
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Broadcast berhasil di stop!',
        })
        mutate(`${API_URL_EMAIL}broadcast/get-broadcast?userId=${userEmail?.id}`)
        handleClose()
      } else {
        handleClose()
        showWarning('Broadcast gagal di stop!');
      }
    } else if (action === 'resume') {
      const response = await resumeBroadcastEmail(row.id)
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Berhasil',
          text: 'Broadcast berhasil di resume!',
        })
        mutate(`${API_URL_EMAIL}broadcast/get-broadcast?userId=${userEmail?.id}`)
        handleClose()
      } else {
        handleClose()
        showWarning('Broadcast gagal di resume!');
      }
    }
  }

  const baseTableColumns: TableColumn<BroadcastEmailTypes>[] = [
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
      allowOverflow: true,
      width: '70px',
      cell: (row) => (
        <>
          <IconButton
            aria-label={`more-${row.id}`}
            id={`menu-anchor-${row.id}`}
            onClick={() => handleClick(row.id)}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id={`menu-${row.id}`}
            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
            open={openMenuId === row.id}
            onClose={handleClose}
            disableScrollLock={true}
          >
            {(row.broadcastStatus === 'RUNNING' || row.broadcastStatus === 'PENDING') && (
              <>
                <MenuItem onClick={() => handleActionClick(row, 'pause')}>
                  <i className="fas fa-pause fs-4 me-3"></i>
                  <span>{'Pause'}</span>
                </MenuItem>
                <MenuItem onClick={() => handleActionClick(row, 'stop')}>
                  <i className="fas fa-stop fs-4 me-3"></i>
                  <span>{'Stop'}</span>
                </MenuItem>
              </>
            )}

            {row.broadcastStatus === 'PAUSED' && (
              <>
                <MenuItem onClick={() => handleActionClick(row, 'resume')}>
                  <i className="fas fa-play fs-4 me-3"></i>
                  <span>{'Resume'}</span>
                </MenuItem>
                <MenuItem onClick={() => handleActionClick(row, 'stop')}>
                  <i className="fas fa-stop fs-4 me-3"></i>
                  <span>{'Stop'}</span>
                </MenuItem>
              </>
            )}

            <MenuItem onClick={() => handleUpdateClick(row)}>
              <i className="fas fa-edit fs-4 me-3"></i>
              <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_BROADCAST' })}</span>
            </MenuItem>

            <MenuItem onClick={() => handleDeleteClick(row)}>
              <i className="fas fa-trash fs-3 me-4"></i>
              <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
            </MenuItem>
          </Menu>
        </>
      ),
    },
    {
      name: 'Nama Broadcast',
      width: '250px',
      sortable: true,
      selector: row => row.broadcastName || '',
      cell: (row) => (
        <span
          className='text-primary cursor-pointer'
          onClick={() => {
            setIdDetailBroadcastEmail(row.id);
            setTypeEmail('detailBroadcastEmail');
            setIsDetailActive(true);
          }}
        >
          {row.broadcastName}
        </span>
      )
    },
    {
      name: 'Jadwal',
      width: '200px',
      sortable: true,
      selector: row => row.schedule || '',
      cell: row => moment(row.schedule).format("DD MMM YYYY HH:mm"),
    },
    {
      name: 'Broadcast Status',
      width: '150px',
      sortable: true,
      selector: row => row.broadcastStatus || '',
      cell: row => <span className={`status-badge status-${row.broadcastStatus.toLowerCase()}`}>{row.broadcastStatus}</span>,
    },
    {
      name: 'Tanggal dibuat',
      width: '150px',
      sortable: true,
      selector: row => row.createdAt || '',
      cell: row => moment(row.createdAt).format("DD MMM YYYY HH:mm"),
    },
    {
      name: 'Terkirim/Penerima',
      width: '150px',
      sortable: true,
      selector: row => row.successCount || '',
      cell: (row) => <span>{row.successCount}/{row.totalCount}</span>,
    },
  ];

  useModalEffect({
    showModal: showDeleteModal,
    setShowModal: setShowDeleteModal,
    modalId: `delete-broadcast-email-modal-${selectedBroadcast?.id}`
  })

  useModalEffect({
    showModal: showUpdateDetailGroup,
    setShowModal: setShowUpdateDetailGroup,
    modalId: `update-broadcast-contact-modal-${selectedBroadcast?.id}`
  })

  const btnNavigateToBc = () => (
    <button
      className='btn btn-primary fs-bold px-6'
      onClick={() => {
        if (creditBalance?.data?.creditBalance > 0) {
          navigateToBroadcastCore({} as BroadcastEmailTypes)
        } else {
          showWarning('Credit Token Habis')
        }
      }}
    >
      <span className='svg-icon svg-icon-2'>
        <KTSVG path='/media/icons/duotune/general/gen016.svg' />
      </span>
      <span className='indicator-label'>Broadcast</span>
    </button>
  );

  const actionsBroadcastEmail: ReactNode[] = [btnNavigateToBc()];

  return (
    <>
      <MasterTableEmail
        tableColumns={baseTableColumns}
        actions={actionsBroadcastEmail}
        tableKey='broadcastEmail'
        apiURL={`broadcast/get-broadcast?userId=${userEmail?.id}`}
      />

      {showDeleteModal && <DeleteBroadcastEmail data={selectedBroadcast!} tableKey='broadcastEmail' />}
    </>
  )
}

export default TabBroadcastEmail