import { FC, useRef, useEffect, useState, useCallback } from 'react'
import { useDataTableStore } from '../../stores/DataTableStore'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import clsx from 'clsx'
import axios from 'axios'
import useAccessToken from '../../hooks/AccessToken'
import useSWR from 'swr'
import Select from 'react-select'
import { City } from '../../interfaces/City'
import moment from 'moment'
import { nanoid } from '@reduxjs/toolkit'
import { UserModel } from '../../app/modules/auth/models/UserModel'
import { RootState } from '../../setup'
import { shallowEqual, useSelector } from 'react-redux'
import { checkUsername, insertUser } from '../../api/UsersCRUD'
import { Role } from '../../interfaces/Role'
import { AttendanceTemplate } from '../../interfaces/Attendance'
import 'react-phone-input-2/lib/style.css'
import { SelectOption } from '../../interfaces/SelectOption'
import { Leader } from '../../interfaces/Leader'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DatePicker } from 'rsuite'
import { KTSVG } from '../../_metronic/helpers'
import { handleCaps, isValidUrl } from '../../functions/general'
import { useCompanyStore } from '../../stores/CompanyStore'
import { useIntl } from 'react-intl'
import useTodayRange from '../../hooks/TodayRange'
import { useSettingsStore } from '../../stores/SettingsStore'
import { CustomField } from '../../interfaces/Settings'
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { insertUserFieldAttachment, insertUserFieldMultiAttachment } from '../../api/ResourceAPI'
import InputAttachment from '../Input/InputAttachment'
import InputPhone from '../Input/InputPhone'
import debounce from 'lodash.debounce'

interface CityOption {
  value: string
  label: string
}

interface Props {
  displayMode: string
}

const AddUser: FC<Props> = ({ displayMode }) => {
  const datePickerRef = useRef<HTMLInputElement>(null)

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { settings } = useSettingsStore()
  const [customFields, setCustomFields] = useState<CustomField>({})
  const [file, setFile] = useState<string | null>(null)
  const refArray = useRef<(HTMLInputElement | null)[]>([])

  const [modalBtnLoading, setModalBtnLoading] = useState(false)
  const [cityData, setCityData] = useState<SelectOption[]>([])
  const [leadersData, setLeadersData] = useState<SelectOption[]>([])
  const [roleData, setRoleData] = useState<SelectOption[]>([])
  const [templateData, setTemplateData] = useState<SelectOption[]>([])
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const { tablesData, setTablesData } = useDataTableStore()
  const token = useAccessToken()
  const [usernameAvailable, setUsernameAvailable] = useState(true)
  const { companies, setCompanies } = useCompanyStore()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const intl = useIntl()
  const todayRange = useTodayRange()

  const handleDateOnClick = () => {
    datePickerRef.current?.showPicker()
  }

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)

  const API_URL = process.env.REACT_APP_API_URL

  const { data: city = [], error: cityError, isLoading: cityLoading } = useSWR(`${API_URL}cities`, fetcher)
  const { data: leaders = [], error: leadersError, isLoading: leadersLoading } = useSWR(`${API_URL}user/leaders`, fetcher)
  const { data: role = [], error: roleError, isLoading: roleLoading } = useSWR(`${API_URL}roles`, fetcher)
  const { data: template = [], error: templateError, isLoading: templateLoading } = useSWR(`${API_URL}attendance-templates`, fetcher)

  const formSchema = Object.keys(customFields).reduce(
    (obj, item) => ({
      ...obj,
      [item]:
        (customFields[item].type === 'multiple' || customFields[item].type === 'images' || customFields[item].type === 'files')
          ? customFields[item].required ? Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable()
            : Yup.array().notRequired().nullable()
          : customFields[item].type === 'date'
            ? customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
            : customFields[item].type === 'link' ?
              customFields[item].required ?
                Yup.string().nullable().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" }), function (value) {
                  if (value && !isValidUrl(value)) return false;
                  return true;
                })
                :
                Yup.string().nullable().test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" }), function (value) {
                  if (value && !isValidUrl(value)) return false;
                  return true;
                })
              : customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
    }),
    {}
  )

  const initialValues: { [key: string]: any } = {
    txtUsername: '',
    name: '',
    nik: '',
    phone: '',
    password: '',
    confirmPassword: '',
    city: '',
    dateJoined: null,
    role: '',
    leader: '',
    template: '',
    ...Object.fromEntries(Object.keys(customFields).map((key) => [key, null])),
  }

  const validationSchema = Yup.object().shape({
    txtUsername: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.USERNAME" }) })),
    name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
    nik: Yup.string().min(16, intl.formatMessage({ id: "FORM.VALIDATION.MINIMAL" }, { value: 16 })).max(16, intl.formatMessage({ id: "FORM.VALIDATION.MAXIMAL" }, { value: 16 })).notRequired().nullable(),
    // phone: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PHONE" }) })),
    password: Yup.string().min(8, intl.formatMessage({ id: "FORM.VALIDATION.MINIMAL" }, { value: 8 })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PASSWORD" }) })),
    confirmPassword: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.RECONFIRM_PASSWORD" }) })).oneOf([Yup.ref('password'), null], intl.formatMessage({ id: "FORM.VALIDATION.PASSWORD_NOT_MATCH" })),
    city: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })),
    dateJoined: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_JOINED" }) })).nullable(),
    role: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.ROLES" }) })),
    leader: Yup.string(),
    template: Yup.string().notRequired().nullable(),
    ...formSchema,
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true)
      setModalBtnLoading(true)

      const customFieldsValues: Record<string, string | null> = Object.keys(customFields).reduce(
        (obj, item) => ({
          ...obj,
          [item]: (values as Record<string, string | null>)[item],
        }),
        {} as Record<string, string | null>
      )

      let countResourceError = 0
      await Promise.all(
        Object.keys(customFields).map(async (key) => {
          if (customFields[key].type === 'image' || customFields[key].type === 'file') {
            try {
              const resourceResponse = await insertUserFieldAttachment(values[key as keyof typeof values]);
              if (resourceResponse.data.success) {
                customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
              } else {
                countResourceError++
              }
            } catch (error) {
              countResourceError++
            }
          } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
            try {
              if (values[key as keyof typeof values]) {
                const resourceResponse = await insertUserFieldMultiAttachment(values[key as keyof typeof values] as any);
                if (resourceResponse.data.success) {
                  customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                } else {
                  countResourceError++
                }
              }
            } catch (error) {
              countResourceError++
            }
          } else if (customFields[key].type === 'multiple') {
            var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
            customFieldsValues[key] = multipleValues as string
          }
        })
      );

      if (countResourceError > 0) {
        Swal.fire({
          icon: "error",
          text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" })}.`,
          heightAuto: false,
          // target: document.getElementById(`update-pipeline-modal-${pipeline.id}`),
          confirmButtonText: 'Ok'
        })
        setSubmitting(false)
        setModalBtnLoading(false)
        return
      } else {
        try {
          const date = moment(values.dateJoined).format('YYYY-MM-DD')
          const response = await insertUser({
            name: values.name,
            username: user.data.company_prefix + '-' + values.txtUsername,
            password: values.password,
            nik: values.nik === '' ? undefined : values.nik,
            phone: values.phone,
            leaderId: values.leader === '' ? undefined : values.leader,
            cityId: values.city,
            dateJoined: date,
            roleId: values.role,
            attendanceTemplateId: values.template === '' ? undefined : values.template,
            token: token,
            customFields: customFieldsValues,
          })
          if (response.data.success) {
            Swal.fire({
              icon: 'success',
              title: intl.formatMessage({ id: 'ALERT.TITLE.SUCCESS' }),
              text: intl.formatMessage({ id: 'FORM.VALIDATION.ADD_SUCCESS' }),
              heightAuto: false,
            })
            closeModalRef.current?.click()
            setTablesData('users', [response.data.data, ...tablesData.users])
            setCompanies({ ...companies, usersCount: companies.usersCount + 1 })
          } else {
            throw new Error(response.data.message)
          }
        } catch (error: any) {
          Swal.fire({
            icon: 'error',
            title: error.response.data.message,
            confirmButtonText: 'Ok',
          })
        } finally {
          setSubmitting(false)
          setModalBtnLoading(false)
        }
      }
    },
  })

  const checkUsernameAvailability = useCallback(
    debounce(async (txtUsername: string) => {
      try {
        const response = await checkUsername(txtUsername, token)
        if (response.data.success) {
          setUsernameAvailable(true)
        } else {
          setUsernameAvailable(false)
        }
      } catch (error: any) {
        setUsernameAvailable(false)
      }
    }, 1000),
    []
  );

  useEffect(() => {
    return () => {
      checkUsernameAvailability.cancel();
    };
  }, [checkUsernameAvailability]);

  // const checkUsernameAvailability = async (txtUsername: string) => {
  //   try {
  //     const response = await checkUsername(txtUsername, token)
  //     if (response.data.success) {
  //       setUsernameAvailable(true)
  //     } else {
  //       setUsernameAvailable(false)
  //     }
  //   } catch (error: any) {
  //     setUsernameAvailable(false)
  //   }
  // }

  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
      formik.resetForm()
      formik.setFieldValue('dateJoined', null)
      formik.setFieldValue('phone', '')
      setUsernameAvailable(true)

      Object.keys(customFields).map((key, index) => {
        // Reset
        const ref = refArray.current[index]
        if (ref && ref.value) {
          ref.value = ''
        }

        if (customFields[key].type === 'multiple') {
          var checkboxes = document.getElementsByName(key)
          checkboxes.forEach((checkbox: any) => {
            checkbox.checked = false
          })
        }

        if (customFields[key].type === 'date') {
          formik.setFieldValue(key, null)
        }
      })
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  useEffect(() => {
    if (formik.values.txtUsername === '') return
    checkUsernameAvailability(`${user.data.company_prefix}-${formik.values.txtUsername}`)
  }, [formik.values.txtUsername, usernameAvailable, setUsernameAvailable])

  useEffect(() => {
    if (!roleLoading) {
      const roleData = role.map((role: Role) => ({
        value: role.id,
        label: role.name,
      }))
      setRoleData(roleData)
    }
  }, [role])

  useEffect(() => {
    if (!templateLoading) {
      let templateData = template.map((template: AttendanceTemplate) => ({
        value: template.id,
        label: template.name,
      }))
      setTemplateData(templateData)
    }
  }, [template])

  useEffect(() => {
    if (!leadersLoading) {
      const leadesData = leaders.map((leader: Leader) => ({
        value: leader.id,
        label: leader.name,
      }))
      setLeadersData(leadesData)
    }
  }, [leaders])

  useEffect(() => {
    if (!cityLoading) {
      const citiesData = city.map((city: City) => ({
        value: city.id,
        label: city.name,
      }))
      citiesData.sort((a: CityOption, b: CityOption) => a.label.localeCompare(b.label))
      setCityData(citiesData)
    }
  }, [city])

  useEffect(() => {
    if (settings.users_custom_fields) {
      setCustomFields(JSON.parse(settings.users_custom_fields))
    }
  }, [settings])

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  if (cityError) return <div>error...</div>

  return (
    <div>
      <button type="button"
        className={`btn ${displayMode === "card" ? "btn-sm" : "mt-3"} btn-primary`}
        data-bs-toggle="modal"
        data-bs-target="#tambah-user-modal"
      >
        <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.USER" })}
      </button>
      <div className="modal fade" tabIndex={-1} id="tambah-user-modal" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.USER" })}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                onClick={handleCloseModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="modal-body">
                {/* Username */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.USERNAME" })}</label>
                <div className="input-group mb-3">
                  <span className="input-group-text px-1" id="basic-addon1">{user.data.company_prefix}-</span>
                  <OverlayTrigger
                    key="txtUsername"
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-username">
                        {intl.formatMessage({ id: "FORM.LABEL.TOOLTIP.USERNAME" })}
                      </Tooltip>
                    }>
                    <input
                      placeholder='Username'
                      {...formik.getFieldProps('txtUsername')}
                      className={clsx(
                        'form-control form-control-lg txtUsername',
                        { 'is-invalid': formik.touched.txtUsername && formik.errors.txtUsername },
                      )}
                      type='text'
                      name='txtUsername'
                      // onInput prevent space, special char
                      onInput={(event) => {
                        event.currentTarget.value = event.currentTarget.value.replace(/[^a-zA-Z0-9_-]/g, '');
                      }}
                      autoComplete='off'
                    />
                  </OverlayTrigger>
                </div>
                {formik.touched.txtUsername && formik.errors.txtUsername && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.txtUsername}</span>
                  </div>
                )}
                {!usernameAvailable && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{intl.formatMessage({ id: 'FORM.VALIDATION.USERNAME_ALREADY_EXISTS' })}</span>
                  </div>
                )}
                {/* Nama */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
                <div className="input-group mb-3">
                  <input
                    placeholder={intl.formatMessage({ id: "FORM.LABEL.USER" })}
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-lg',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                    onInput={(e) => handleCaps(e)}
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                  </div>
                )}
                {/* NIK */}
                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.ID_NUMBER" })}</label>
                <div className="input-group mb-3">
                  <input
                    placeholder={intl.formatMessage({ id: "FORM.PLACEHOLDER.ID_NUMBER" })}
                    {...formik.getFieldProps('nik')}
                    className={clsx(
                      'form-control form-control-lg',
                      { 'is-invalid': formik.touched.nik && formik.errors.nik },
                    )}
                    type='text'
                    name='nik'
                    autoComplete='off'
                    maxLength={16}
                    onInput={(event) => {
                      // Replace non-digits
                      event.currentTarget.value = event.currentTarget.value.replace(/[^0-9]/g, '');

                      // Remove leading zeros
                      while (event.currentTarget.value.startsWith("0")) {
                        event.currentTarget.value = event.currentTarget.value.substring(1);
                      }
                    }}
                  />
                </div>
                {formik.touched.nik && formik.errors.nik && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.nik}</span>
                  </div>
                )}
                {/* Phone */}
                <div className='mb-3'>
                  <label className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: "FORM.LABEL.PHONE" })}</label>
                  <InputPhone keyString={'phone'} formik={formik} type="input" />
                  {
                    formik.touched.phone && formik.errors.phone &&
                    (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert' className="text-danger">{formik.errors.phone}</span>
                      </div>
                    )
                  }
                </div>
                {/* Password */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.PASSWORD" })}</label>
                <div className="input-group mb-3">
                  <input
                    placeholder='********'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg txt-password',
                      { 'is-invalid': formik.touched.password && formik.errors.password },
                    )}
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    autoComplete='off'
                    aria-describedby="groupPassword"
                    style={{ borderRight: "none", borderTop: "1px solid #e5e7ef", borderBottom: "1px solid #e5e7ef", borderLeft: "1px solid #e5e7ef" }}
                  />
                  <span className="input-group-text" id="groupPassword" onClick={() => setShowPassword(!showPassword)} style={{ backgroundColor: "white", borderLeft: "none" }}>
                    {showPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.password}</span>
                  </div>
                )}
                {/* Confirm Password */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.RECONFIRM_PASSWORD" })}</label>
                <div className="input-group mb-3">
                  <input
                    placeholder='********'
                    {...formik.getFieldProps('confirmPassword')}
                    className={clsx(
                      'form-control form-control-lg txt-password',
                      { 'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword },
                    )}
                    type={showConfirmPassword ? 'text' : 'password'}
                    name='confirmPassword'
                    autoComplete='off'
                    aria-describedby="groupConfirmPassword"
                    style={{ borderRight: "none", borderTop: "1px solid #e5e7ef", borderBottom: "1px solid #e5e7ef", borderLeft: "1px solid #e5e7ef" }}
                  />
                  <span className="input-group-text" id="groupConfirmPassword" onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ backgroundColor: "white", borderLeft: "none" }}>
                    {showConfirmPassword ? <i className="fas fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                  </span>
                </div>
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.confirmPassword}</span>
                  </div>
                )}
                {/* Leader */}
                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.LEADER" })}</label>
                <div className="input-group mb-3">
                  <Select
                    key={nanoid()}
                    options={leadersData}
                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.LEADER" })}`}
                    value={leadersData.find(option => option.value === formik.values.leader)}
                    onChange={
                      option => formik.handleChange({
                        target: {
                          name: "leader",
                          value: option?.value
                        }
                      })
                    }
                    onBlur={
                      () => formik.values.leader === "" && formik.setFieldTouched("leader", true)
                    }
                    className="w-100"
                    isDisabled={leadersLoading}
                    isClearable={true}
                  />
                </div>
                {formik.touched.leader && formik.errors.leader && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.leader}</span>
                  </div>
                )}
                {/* City */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.CITY" })}</label>
                <div className="input-group mb-3">
                  <Select
                    key={nanoid()}
                    options={cityData}
                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.CITY" })}`}
                    value={cityData.find(option => option.value === formik.values.city)}
                    onChange={
                      option => formik.handleChange({
                        target: {
                          name: "city",
                          value: option?.value
                        }
                      })
                    }
                    onBlur={
                      () => formik.values.city === "" && formik.setFieldTouched("city", true)
                    }
                    className="w-100"
                    isDisabled={cityLoading}
                  />
                </div>
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.city}</span>
                  </div>
                )}
                {/* Role */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.ROLE" })}</label>
                <div className="input-group mb-3">
                  <Select
                    key={nanoid()}
                    options={roleData}
                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}`}
                    value={roleData.find(option => option.value === formik.values.role)}
                    onChange={
                      option => formik.handleChange({
                        target: {
                          name: "role",
                          value: option?.value
                        }
                      })
                    }
                    onBlur={
                      () => formik.values.role === "" && formik.setFieldTouched("role", true)
                    }
                    className="w-100"
                    isDisabled={roleLoading}
                  />
                </div>
                {formik.touched.role && formik.errors.role && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.role}</span>
                  </div>
                )}
                {/* Attendance Template */}
                {companies.companyFeatureLimitUserAttendance &&
                  <>
                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}</label>
                    <div className="input-group mb-3">
                      <Select
                        key={nanoid()}
                        options={templateData}
                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_TEMPLATE" })}`}
                        value={templateData.find(option => option.value === formik.values.template)}
                        onChange={
                          option => formik.handleChange({
                            target: {
                              name: "template",
                              value: option?.value
                            }
                          })
                        }
                        onBlur={
                          () => formik.values.template === "" && formik.setFieldTouched("template", true)
                        }
                        className="w-100"
                        isDisabled={templateLoading}
                      />
                    </div>
                    {formik.touched.template && formik.errors.template && (
                      <div className='fv-plugins-message-container text-danger'>
                        <span role='alert' className="text-danger">{formik.errors.template}</span>
                      </div>
                    )}
                  </>
                }
                {/* Date Joined */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DATE_JOINED" })}</label>
                <div className="input-group mb-3">
                  <DatePicker
                    placement="topStart"
                    ranges={todayRange}
                    oneTap
                    editable={false}
                    format="dd MMM yyyy"
                    value={formik.values.dateJoined}
                    style={{ width: "100%", zIndex: 0 }}
                    container={document.querySelector('#tambah-user-modal') as HTMLElement}
                    onChange={(value: any) => {
                      if (value) formik.setFieldValue('dateJoined', value)
                      else formik.setFieldValue('dateJoined', null)
                    }}
                  />
                </div>
                {formik.touched.dateJoined && formik.errors.dateJoined && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.dateJoined}</span>
                  </div>
                )}
                {/* Custom Fields */}
                {customFields &&
                  Object.keys(customFields).map((key, index) => {
                    if (customFields[key].type === 'text') {
                      return (
                        <div key={index}>
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'
                              }`}
                          >
                            {customFields[key].name}
                          </label>
                          <div className='input-group mb-3'>
                            <input
                              placeholder={customFields[key].name}
                              {...formik.getFieldProps(key)}
                              className={clsx('form-control form-control-lg', {
                                'is-invalid':
                                  formik.touched[key as keyof typeof formik.touched] &&
                                  formik.errors[key as keyof typeof formik.errors],
                              })}
                              type='text'
                              name={key}
                              autoComplete='off'
                              style={{ zIndex: 0 }}
                              required={true}
                              onInput={(e) => handleCaps(e)}
                              ref={(ref) => {
                                refArray.current[index] = ref // took this from your guide's example.
                              }}
                            />
                          </div>
                          {formik.touched[key as keyof typeof formik.touched] &&
                            formik.errors[key as keyof typeof formik.errors] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors[key as keyof typeof formik.errors]}
                                </span>
                              </div>
                            )}
                        </div>
                      )
                    } else if (customFields[key].type === 'link') {
                      return (
                        <div key={index}>
                          <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                          <div className="input-group mb-3">
                            <input
                              placeholder={customFields[key].name}
                              {...formik.getFieldProps(key)}
                              className={clsx(
                                'form-control form-control-lg txt-link',
                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                              )}
                              type='text'
                              name={key}
                              autoComplete='off'
                              style={{ zIndex: 0 }}
                              required={true}
                              ref={ref => {
                                refArray.current[index] = ref; // took this from your guide's example.
                              }}
                            />
                          </div>
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </div>
                      )
                    } else if (customFields[key].type === 'image') {
                      return (
                        <>
                          {/* Attachments */}
                          <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                          <small><i>*Format JPG, JPEG, PNG</i></small>
                          <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </>
                      )

                    } else if (customFields[key].type === 'images') {
                      return (
                        <>
                          {/* Attachments */}
                          <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                          <small><i>*Format JPG, JPEG, PNG</i></small>
                          <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </>
                      )
                    } else if (customFields[key].type === 'file') {
                      return (
                        <>
                          {/* Attachments */}
                          <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                          <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                          <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </>
                      )
                    } else if (customFields[key].type === 'files') {
                      return (
                        <>
                          {/* Attachments */}
                          <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                          <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                          <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </>
                      )
                    } else if (customFields[key].type === 'date') {

                      return (
                        <>
                          {/* Date */}
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'
                              }`}
                          >
                            {customFields[key].name}
                          </label>
                          <div className='input-group mb-3'>
                            <DatePicker
                              ranges={todayRange}
                              oneTap
                              editable={false}
                              format='dd MMM yyyy'
                              value={formik.values[key] ? new Date(formik.values[key]) : null}
                              defaultValue={null}
                              style={{ width: '100%', zIndex: 0 }}
                              container={
                                document.querySelector('#tambah-user-modal') as HTMLElement
                              }
                              onChange={(value: any) => {
                                if (value)
                                  formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                else formik.setFieldValue(key, null)
                              }}
                            />
                          </div>
                          {formik.touched[key as keyof typeof formik.touched] &&
                            formik.errors[key as keyof typeof formik.errors] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors[key as keyof typeof formik.errors]}
                                </span>
                              </div>
                            )}
                        </>
                      )
                    } else if (customFields[key].type === 'option') {
                      const options = customFields[key].value.map((option, index) => ({
                        value: option,
                        label: option,
                      }))

                      return (
                        <>
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'
                              }`}
                          >
                            {customFields[key].name}
                          </label>
                          <div className='input-group mb-3'>
                            <Select
                              key={nanoid()}
                              isClearable
                              options={options}
                              placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${customFields[key].name
                                }`}
                              value={
                                options.find(
                                  (option) =>
                                    option.value ===
                                    formik.values[key as keyof typeof formik.values]
                                ) || null
                              }
                              onChange={(option) =>
                                formik.handleChange({
                                  target: {
                                    name: key,
                                    value: option?.value || '',
                                  },
                                })
                              }
                              onBlur={() =>
                                formik.values[key as keyof typeof formik.values] === '' &&
                                formik.setFieldTouched(key, true)
                              }
                              className='w-100'
                            />
                          </div>
                          {formik.touched[key as keyof typeof formik.touched] &&
                            formik.errors[key as keyof typeof formik.errors] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors[key as keyof typeof formik.errors]}
                                </span>
                              </div>
                            )}
                        </>
                      )
                    } else if (customFields[key].type === 'multiple') {
                      return (
                        <>
                          <label
                            className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'
                              }`}
                          >
                            {customFields[key].name}
                          </label>
                          <div className='input-group mb-3'>
                            <div
                              className='form-check form-check-custom form-check-solid'
                              style={{ display: 'contents' }}
                            >
                              {customFields[key].value.map((option, index) => (
                                <div
                                  style={{
                                    display: 'flex',
                                    padding: '3px',
                                    overflowWrap: 'anywhere',
                                  }}
                                  key={index}
                                >
                                  <input
                                    key={index}
                                    {...formik.getFieldProps(key)}
                                    className='form-check-input'
                                    type='checkbox'
                                    value={option as string}
                                    name={key}
                                  />
                                  <label className='form-check-label me-2'>{option}</label>
                                </div>
                              ))}
                            </div>
                          </div>
                          {formik.touched[key as keyof typeof formik.touched] &&
                            formik.errors[key as keyof typeof formik.errors] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className='text-danger'>
                                  {formik.errors[key as keyof typeof formik.errors]}
                                </span>
                              </div>
                            )}
                        </>
                      )
                    } else if (customFields[key].type === 'phone') {
                      return (
                        <div>
                          <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                          <InputPhone keyString={key} formik={formik} type="input" />
                          {
                            formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                            (
                              <div className='fv-plugins-message-container text-danger'>
                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                              </div>
                            )
                          }
                        </div>
                      )
                    } return null
                  })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light d-none"
                  data-bs-dismiss="modal"
                  ref={closeModalRef}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleCloseModal}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                  <span className="indicator-label">
                    {intl.formatMessage({ id: "FORM.ACTION.ADD" })}
                  </span>
                  <span className="indicator-progress">
                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export { AddUser }
