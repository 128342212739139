import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { pipeline } from "stream";
import { ListProduct, Pipeline, ProductLabels } from "../../../interfaces/Pipeline";
import { CustomField } from "../../../interfaces/Settings";
import { useSettingsStore } from "../../../stores/SettingsStore";
import useUser from "../../../hooks/User";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../../functions/general";
import { ExpandableElement } from "../../General/ExpandableElement";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import useSWR from "swr";
import BadgeTooltip from "../../General/BadgeTooltip";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Label } from "../../../interfaces/Label";

interface DetailPipelineProps {
    dataPipeline: Pipeline
    setShowDetailPipelineModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ChatDetailPipeline: FC<DetailPipelineProps> = ({ dataPipeline, setShowDetailPipelineModal }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const modalRef = useRef<HTMLDivElement | null>(null);
    const token = useAccessToken();
    const intl = useIntl()
    const [customLead, setCustomLead] = useState({} as any)
    const [customFields, setCustomFields] = useState<CustomField>({})
    const { settings } = useSettingsStore()
    const user = useUser()
    const { companyId, keyCustomfield } = useCustomeStore()
    const [data, setData] = useState<Pipeline>()
    const { pipelines, selectMultiPipeline } = usePipelineStore()

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data).catch(error => {
        throw error.response ? error.response.data : error;
    })

    const { data: dataDetailPipeline = {}, error: dataDetailPipelineError, isValidating: isValidatingDataDetailPipeline } = useSWR(dataPipeline ? `${API_URL}v2/lead/${dataPipeline.id}` : null, fetcher)

    useEffect(() => {
        if (!isValidatingDataDetailPipeline) {
            setData(dataDetailPipeline)
        }
    }, [dataDetailPipeline])

    useEffect(() => {
        if (data) {
            const customData: any = {
                templateName: {
                    name: 'Template',
                    value: dataPipeline.lead_template_name
                },
                date_created: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
                    value: moment(data.date_created).format("DD MMM YYYY HH:mm")
                },
                merchant: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
                    value: returnMerchantName(data)
                },
                pic: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                    value: returnOwnerName(data.owner_name, data.labels)
                },
                total_log: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }),
                    value: data.lead_logs_count && customNumberFormat(data.lead_logs_count)
                },
                progress: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
                    value: returnProgress(data)
                },
                phone: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
                    value: data.merchant_phone
                },
                age: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
                    value: data.lead_age
                },
                source: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
                    value: returnSource(data)
                },
                product: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
                    value: returnProduct(data)
                },
                label: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
                    value: returnLabel(data)
                },
                nominal: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
                    value: customNumberFormat(data.nominal)
                },
                product_qty: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
                    value: returnQtyProduct(data)
                },
                creator: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                    value: data.creator_name
                },
                start_date: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
                    value: moment(data.date_start).format("DD MMM YYYY HH:mm")
                },
                end_date: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
                    value: data.date_end ? moment(data.date_end).format("DD MMM YYYY HH:mm") : "-"
                },
                note: {
                    name: (user.data.company_id === companyId.elgisa || user.data.company_id === companyId.looyalIT) ? "Opportunity" : intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
                    value: data.note ? <ExpandableElement text={(data.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
                },
            }

            var newData = {
                ...customData,
                ...Object.keys(customFields).reduce((acc, key) => {
                    let value;
                    if (customFields[key].type === 'invoice_reminder' && data.custom_fields[key]) {
                        if (JSON.parse(data.custom_fields[key] as string).invoice_url && JSON.parse(data.custom_fields[key] as string).date_due) {
                            value = returnInvoiceReminder(JSON.parse(data.custom_fields[key] as string).invoice_url, JSON.parse(data.custom_fields[key] as string).date_due)
                        } else if (JSON.parse(data.custom_fields[key] as string).invoice_url && !JSON.parse(data.custom_fields[key] as string).date_due) {
                            value = <a href={JSON.parse(data.custom_fields[key] as string).invoice_url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
                        } else if (!JSON.parse(data.custom_fields[key] as string).invoice_url && JSON.parse(data.custom_fields[key] as string).date_due) {
                            value = moment(JSON.parse(data.custom_fields[key] as string).date_due).format("DD MMM YYYY")
                        } else value = "-"
                    }
                    else if (customFields[key].type === 'date') {
                        value = data.custom_fields[key] ? moment(data.custom_fields[key]).format("DD MMM YYYY") : "-";
                    } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                        value = data.custom_fields[key] ? returnImage(data.custom_fields[key]) : "-";
                    } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                        value = data.custom_fields[key] ? returnFile(data.custom_fields[key]) : "-";
                    } else if (customFields[key].type === 'multiple') {
                        value = data.custom_fields[key] ? returnMultiple(data.custom_fields[key] as string) : "-";
                    } else if (customFields[key].type === 'link') {
                        value = data.custom_fields[key] ? returnLink(data.custom_fields[key] as string) : "-";
                    } else if (customFields[key].type === 'serial') {
                        value = typeof data.custom_fields[key] === 'string' ? data.custom_fields[key] : (data.custom_fields[key] as string[])?.length > 0 ? (data.custom_fields[key] as string[]).join('') : '-';
                    } else if (customFields[key].type === 'merchant' || customFields[key].type === 'user' || customFields[key].type === 'pipeline') {
                        value = returnCustomFieldMaster(data, key, customFields[key].type);
                    } else {
                        value = data.custom_fields[key] ? data.custom_fields[key] : "-";
                    }

                    return {
                        ...acc,
                        [key]: {
                            name: customFields[key].name,
                            value
                        }
                    };
                }, {})
            };

            if (user.data.company_id === companyId.enterpriseSales) {
                newData = {
                    ...newData,
                    [keyCustomfield.serial.enterpriseSales]: {
                        name: 'Invoice',
                        value: data.custom_fields[keyCustomfield.serial.enterpriseSales] ? (data.custom_fields[keyCustomfield.serial.enterpriseSales] as string[]).join('') : "-"
                    }
                }
            }

            if (user.data.company_id === companyId.enterpriseService) {
                newData = {
                    ...newData,
                    [keyCustomfield.serial.enterpriseService]: {
                        name: 'Invoice',
                        value: data.custom_fields[keyCustomfield.serial.enterpriseService] ? (data.custom_fields[keyCustomfield.serial.enterpriseService] as string[]).join('') : "-"
                    }
                }
            }

            setCustomLead(newData);
        }
    }, [customFields, data])

    useEffect(() => {
        if (Object.keys(selectMultiPipeline).length !== 0) {
            setCustomFields(selectMultiPipeline.customFields)
        }
    }, [selectMultiPipeline])

    const returnOwnerName = (ownerName: string, labels: Label[]) => {
        return (
            <div className="d-flex gap-2 w-100">
                <div>{ownerName}</div>
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip id='tooltip-labels'>
                            {labels?.map((label, index) => (
                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }} key={index}>
                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color || "black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                    </svg>
                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                                        {label.name}
                                    </span>
                                </div>
                            ))
                            }
                        </Tooltip>
                    }
                >
                    <span
                        className='cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title=''
                    >
                        {(labels?.length && labels.length > 0) ? (
                            labels.length === 1 ? (
                                <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: labels[0].color || "black" }} >
                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                </svg>
                            ) : (
                                <div className="ps-1" style={{ color: labels[1].color || "black" }}>
                                    <svg width="18px" height="15px" viewBox="0 0 18 15">
                                        <path fill={`${labels[0].color}` || "black"} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                        <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                    </svg>
                                </div>

                            )
                        ) : <></>}
                    </span>
                </OverlayTrigger>
            </div>
        )
    }

    const returnMerchantName = (data: Pipeline) => {
        switch (data.hot_warm_cold_status) {
            case 'hot':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-danger ms-3">Hot</span></>
            case 'warm':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-warning ms-3">Warm</span></>
            case 'cold':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-primary ms-3">Cold</span></>
            default:
                return data.merchant_name
        }
    }

    const returnProgress = (data: Pipeline) => {
        if (data.lead_subprogress_name) {
            if (data.lead_progress_color) return <span className="badge" style={{ backgroundColor: data.lead_progress_color, color: isColorDarkFromHex(data.lead_progress_color) ? "white" : "black" }}>{data.lead_progress_name + " (" + data.lead_subprogress_name + ")"}</span>
            else return <span className="badge badge-light-info" >{data.lead_progress_name + " (" + data.lead_subprogress_name + ")"}</span>
        } else {
            if (data.lead_progress_color) return <span className="badge" style={{ backgroundColor: data.lead_progress_color, color: isColorDarkFromHex(data.lead_progress_color) ? "white" : "black" }}>{data.lead_progress_name}</span>
            else return <span className="badge badge-light-info" >{data.lead_progress_name}</span>
        }
    }

    const returnProduct = (data: Pipeline) => {
        return <div dangerouslySetInnerHTML={{ __html: data.leadProducts?.map((dataProduct: ListProduct) => dataProduct.productName).join(', ') }} />
    }

    const returnLabel = (data: Pipeline) => {
        const uniqueLabels = [...new Set(data.leadProducts.flatMap(product => product.productLabels?.map(label => label.name) || []))];
        return uniqueLabels.length > 0 ? uniqueLabels.join(', ') : "-";
    }

    const returnQtyProduct = (data: Pipeline) => {
        if (data.leadProducts) return customNumberFormat(data.leadProducts?.reduce((total, dataProduct) => total + Number(dataProduct.leadProductQty), 0))
        else return 0
    }

    const returnSource = (data: Pipeline) => {
        switch (data.lead_source_name) {
            case 'INBOUND':
                return <span className="badge badge-light-primary">Inbound</span>
            case 'OUTBOUND':
                return <span className="badge badge-light-success">Outbound</span>
            default:
                return <span className="badge badge-light-info">{data.lead_source_name}</span>
        }
    }

    const returnImage = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnLink = (data: string) => {
        return (
            <a href={data} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">Lihat<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
        )
    }

    const returnInvoiceReminder = (url: string, date: string) => {
        return (
            <div>
                {moment(date).format("DD MMM YYYY")}, <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
            </div>
        )
    }

    const returnCustomFieldMaster = (row: Pipeline, key: string, type: string) => {
        const values = row.info_custom_fields && row.info_custom_fields[key];

        if (type === 'pipeline') {
            return (
                (Array.isArray(values) && values.length > 0) ?
                    <>
                        <span><b>{(values[0] as any).name}</b> / Rp.{customNumberFormat((values[0] as any).nominal)} / {moment((values[0] as any).dateCreated).format("DD MMM YYYY")}</span>
                    </>
                    :
                    '-'
            )
        } else if (type === 'merchant') {
            return (
                (Array.isArray(values) && values.length > 0) ?
                    <span>{values.map((item: any) => item.merchant_name).join(', ')}</span>
                    :
                    '-'

            )
        } else if (type === 'user') {
            return (
                (Array.isArray(values) && values.length > 0) ?
                    <span>{values.map((item: any) => item.name).join(', ')}</span>
                    :
                    '-'

            )
        } else return '-'
    }

    return (
        <div className="card" style={{ minHeight: "calc(100vh - 1rem)" }}>
            <h3 className="py-3 px-5 d-flex align-items-center gap-3">
                <i className="fas fa-times fs-3 text-hover-primary cursor-pointer" onClick={() => setShowDetailPipelineModal(false)}></i>
                Detail {dataPipeline.merchant_name}
            </h3>
            <div className="card-body row" style={{ flex: "none" }}>
                {
                    isValidatingDataDetailPipeline ?
                        <div className="loading-overlay">
                            <div className="loader"></div>
                        </div>
                        :
                        Object.keys(customLead).map((key, index) => {
                            return (
                                <div className="row pb-2 col-12" key={index}>
                                    <div className="col-4 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customLead[key].name}</div>
                                    <div className="col-8" style={{ fontWeight: "500", color: "black" }}>{customLead[key].value}</div>
                                </div>
                            )
                        })
                }
            </div>
        </div>
    )
}

export { ChatDetailPipeline }