import { FC } from "preact/compat";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Modal } from "bootstrap";
import { useIntl } from "react-intl";
import { UpdateUnit } from "../../../components/Units/UpdateUnit";
import { DeleteUnit } from "../../../components/Units/DeleteUnit";
import { AddUnit } from "../../../components/Units/AddUnit";
import { Unit } from "../../../interfaces/Unit";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";

const MasterUnits: FC = () => {

    const user = useUser()

    const [unit, setUnit] = useState<Unit>();
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const [openMenuId, setOpenMenuId] = useState<string | null>(null);

    const intl = useIntl()

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Unit>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            (
                                (user.data.is_superadmin) && row.id
                            ) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setShowUpdateModal(true);
                                        setUnit(row);
                                    }}>
                                        <i className="fas fa-edit fs-4 me-4"></i>
                                        <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                    </MenuItem>

                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setShowDeleteModal(true);
                                        setUnit(row);
                                    }}>
                                        <i className="fas fa-trash fs-3 me-5"></i>
                                        <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            selector: row => row.name,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ALIAS' }),
            selector: row => row.alias,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.COLOR' }),
            width: '75px',
            center: true,
            selector: row => row.color,
            cell: row => {
                return (
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '6px',
                            backgroundColor: row.color,
                        }}
                    />
                );
            },
        }
    ]

    const actions = [
        <DataTableImport source={"template-unit"} columns={tableColumns} />,
        <AddUnit />
    ]

    useEffect(() => {
        if (showUpdateModal && unit) {
            const modalElement = document.getElementById(`update-unit-modal-${unit.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, unit, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && unit) {
            const modalElement = document.getElementById(`delete-unit-modal-${unit.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, unit, setShowDeleteModal]);

    return (
        <>
            <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.UNITS' })}</li>
            </ol>
            <MasterDataTable
                tableKey="units"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="units"
            />
            {
                showUpdateModal && <UpdateUnit unit={unit!} />
            }
            {
                showDeleteModal && <DeleteUnit unit={unit!} />
            }
        </>
    )
}

export { MasterUnits }