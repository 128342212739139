import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

interface ProductLog {
    date_created: string;
    hpp: string;
    price_product: string;
    product_name: string;
    qty: string;
    total_price: number;
}

interface ExpandableElementProductProps {
    data: JSX.Element[];
    maxHeight: number;
}

const ExpandableElementProduct: FC<ExpandableElementProductProps> = ({ data, maxHeight }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showExpandButton, setShowExpandButton] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const intl = useIntl();

    const toggleExpand = () => setIsExpanded(!isExpanded);

    useEffect(() => {
        const checkOverflow = () => {
            if (contentRef.current) {
                const isOverflowing = contentRef.current.scrollHeight > maxHeight;
                setShowExpandButton(isOverflowing);
            }
        };

        const onResize = () => {
            requestAnimationFrame(checkOverflow);
        };

        window.addEventListener('resize', onResize);
        checkOverflow();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [data, maxHeight]);

    return (
        <div className="my-3">
            <div
                ref={contentRef}
                style={{
                    maxHeight: showExpandButton && !isExpanded ? `${maxHeight}px` : 'none',
                    overflow: showExpandButton && !isExpanded ? 'hidden' : 'visible',
                }}
            >
                {data}
            </div>
            {showExpandButton && (
                <span
                    onClick={toggleExpand}
                    style={{ color: '#3292FF', cursor: 'pointer', display: 'block', marginTop: '5px' }}
                >
                    {isExpanded ? intl.formatMessage({ id: "FORM.ACTION.HIDE" }) : intl.formatMessage({ id: "FORM.ACTION.READ_MORE" })}
                    <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} style={{ fontSize: '0.8em', color: '#3292FF', marginLeft: '5px' }}></i>
                </span>
            )}
        </div>
    );
}

export { ExpandableElementProduct };