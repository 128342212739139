import { UserModel } from "../app/modules/auth/models/UserModel";
import { Pipeline } from "../interfaces/Pipeline";
import { Settings } from "../interfaces/Settings";
import { CustomeStoreCompanyIdProps, CustomStoreRoleIdProps, keyCustomfieldProps } from "../stores/CustomeStore";

// General
export function isActionUpdatePipeline(user: UserModel, merchantId: string) {
    return (user.data.is_superadmin || user.data.permission_pipeline_update !== 'NONE') && !merchantId
}
export function isActionDeletePipeline(user: UserModel, merchantId: string) {
    return (user.data.is_superadmin || user.data.permission_pipeline_delete !== 'NONE') && !merchantId
}
export function isActionLogPipeline(user: UserModel) {
    return (user.data.is_superadmin || user.data.permission_pipeline_log_read !== 'NONE')
}
export function isActionAddLogPipeline(user: UserModel, row: Pipeline, merchantId: string, settings: Settings, companyId: CustomeStoreCompanyIdProps) {
    const { is_superadmin, permission_pipeline_log_create, company_id } = user.data;
    const isAuthorized = is_superadmin || permission_pipeline_log_create !== 'NONE';
    const isLeadProgressTerm1 = row?.lead_progress_name.toLocaleLowerCase() === "termin 1";
    const isCompanyMatch = company_id === companyId.uec || company_id === companyId.looyalIT;
    const isNotMerchant = !merchantId;
    const isLeadInProgress = row?.lead_progress_progress_type !== "FAIL" && row?.lead_progress_progress_type !== "SUCCESS";

    if (settings.unlimited_add_lead_log) return true

    if (isAuthorized && isLeadProgressTerm1 && isCompanyMatch && isNotMerchant) {
        return true;
    }

    if (isAuthorized && isLeadInProgress && isNotMerchant) {
        return true;
    }

    return false;
}
export function isActionDetailProductPipeline(merchantId: string) {
    return !merchantId
}
export function isActionActivitiyPipeline(user: UserModel, companyId: CustomeStoreCompanyIdProps, roleId: CustomStoreRoleIdProps, merchantId: string) {
    return user.data.company_id === companyId.juraganMaterial && (user.data.is_superadmin || user.data.role_id === roleId.juraganMaterial.salesProductManager || user.data.role_id === roleId.juraganMaterial.scm) && !merchantId
}

// ... existing code ...

export function isActionDetailTerminPipeline(row: Pipeline) {
    return Array.isArray(row?.paymentTermins) && row.paymentTermins.length > 0 && row.paymentTermins[0]?.nominal! > 0
}

// ... existing code ...


// PS Enterprise
export function showSerial(lead: Pipeline, keyCustomfield: keyCustomfieldProps) {
    if (lead.custom_fields[keyCustomfield.serial.enterpriseService]) {
        return '- ' + (lead.custom_fields[keyCustomfield.serial.enterpriseService] as string[]).join('')
    } else if (lead.custom_fields[keyCustomfield.serial.enterpriseSales]) {
        return '- ' + (lead.custom_fields[keyCustomfield.serial.enterpriseSales] as string[]).join('')
    } else return ''
}


// UEC
// export function isActionAddLogPipelineUec(user: UserModel, row: Pipeline, merchantId: string, companyId: CustomeStoreCompanyIdProps) {
//     return (user.data.is_superadmin || user.data.permission_pipeline_log_create !== 'NONE') && (row?.lead_progress_name)?.toLocaleLowerCase() === "termin 1" && (user.data.company_id === companyId.uec || user.data.company_id === companyId.looyalIT) && !merchantId
// }