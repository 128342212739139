import { useFormik } from 'formik'
import { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import { nanoid } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import { SelectOption } from '../../interfaces/SelectOption'
import { useSettingsStore } from '../../stores/SettingsStore'
import useAccessToken from '../../hooks/AccessToken'
import { CustomField } from '../../interfaces/Settings'
import { saveSetting } from '../../api/SettingCRUD'
import { useIntl } from 'react-intl'
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from './RowCustomField'

interface Props {
  optionType: SelectOption[]
  optionsResetSerial: SelectOption[]
}

const CustomFieldUser: FC<Props> = ({ optionType, optionsResetSerial }) => {
  const { settings, setSettings } = useSettingsStore()
  const token = useAccessToken()

  const [keysUser, setKeysUser] = useState<string[]>([])
  const [fieldUser, setFieldUser] = useState<CustomField>({})
  const [loadingUser, setLoadingUser] = useState(false)

  const intl = useIntl()

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const formik = useFormik({
    initialValues: {
      key: '',
    },
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let isDuplicate = false
      let isNameColumnEmpty = false
      let isOptionEmpty = false

      Object.keys(fieldUser).map((key1) => {
        if (fieldUser[key1].type === "option" || fieldUser[key1].type === "multiple") {
          if ((fieldUser[key1].value as string[]).includes("")) {
            isOptionEmpty = true
          }
        }

        if (fieldUser[key1].name === "") {
          isNameColumnEmpty = true
        }

        Object.keys(fieldUser).map((key2) => {
          if ((fieldUser[key1].name)?.trim() === (fieldUser[key2].name)?.trim() && key1 !== key2) {
            isDuplicate = true
          }
        })
      })

      const modifiedObject = Object.fromEntries(
        Object.entries(fieldUser).map(([key, value]) => {
          const { new: _, ...rest } = value;
          return [key, rest];
        })
      );

      if (isOptionEmpty) return false
      if (isNameColumnEmpty) return false
      if (isDuplicate) {
        Swal.fire({
          title: intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME_COLUMN" }) }),
          icon: 'error',
          confirmButtonText: 'Ok',
          heightAuto: false
        })
        return false
      }

      setLoadingUser(true)

      try {
        const res = await saveSetting("users_custom_fields", JSON.stringify(modifiedObject), token)
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
            timer: 2000,
            timerProgressBar: true
          })
          Object.values(fieldUser).forEach(field => {
            field.new = false;
          });
          setSettings({ ...settings, users_custom_fields: JSON.stringify(fieldUser) })
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.respons.data.message,
          confirmButtonText: 'Ok'
        })
      } finally {
        setLoadingUser(false)
      }
    }
  });

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const updateColumns = (field: CustomField) => {
        // Ambil urutan kunci lama
        const keys = Object.keys(field);
        // Temukan indeks lama dan baru
        const oldIndex = keys.indexOf(active.id);
        const newIndex = keys.indexOf(over.id);

        // Urutkan kunci dengan arrayMove
        const newKeys = arrayMove(keys, oldIndex, newIndex);

        // Buat objek baru dengan urutan kunci baru
        const newField: CustomField = {};
        newKeys.forEach(key => {
          newField[key] = field[key];
        });

        return newField;
      };

      // Perbarui urutan fieldUser
      const newColumns = updateColumns(fieldUser);
      setFieldUser(newColumns);
    }
  };

  const addUserColumn = () => {
    var key = crypto.randomUUID()
    const addColumnUser = { ...fieldUser, [key]: { name: `${intl.formatMessage({ id: "FORM.LABEL.COLUMN" })} ` + (keysUser.length + 1), type: 'text', required: false, value: [], score: "", new: true } };
    setFieldUser(addColumnUser);
    setKeysUser([...keysUser, key])
  }

  useEffect(() => {
    if (settings.users_custom_fields) {
      setFieldUser(JSON.parse(`${settings.users_custom_fields}`))
      setKeysUser(Object.keys(JSON.parse(`${settings.users_custom_fields}`)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  return (
    <>
      <div className="card mb-5 mb-xl-10">
        <div className="card-header border-0">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.CUSTOM_COLUMN" })}</h3>
          </div>
        </div>
        <div id="kt_account_settings_email_preferences">
          <form onSubmit={formik.handleSubmit} noValidate>
            <div className="card-body border-top px-9 py-9">
              {
                <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
                  <SortableContext items={Object.keys(fieldUser) as any} strategy={verticalListSortingStrategy}>
                    {
                      Object.keys(fieldUser).map((key, index) => {
                        return (
                          <RowCustomField
                            key={key}
                            id={key}
                            type="user"
                            customField={fieldUser}
                            index={index}
                            optionType={optionType}
                            optionsResetSerial={optionsResetSerial}
                            setCustomField={setFieldUser}
                          />
                        )
                      })
                    }
                  </SortableContext>
                </DndContext>
              }
            </div>
            <div className="card-footer py-6 px-9">
              <div className="row">
                <label className="col-xl-3"></label>
                <div className="col-lg-9 col-xl-9" style={{ display: "flex", justifyContent: "space-between" }}>
                  <button type="button" className="btn btn-secondary" onClick={() => addUserColumn()}>
                    <i className="fas fa-plus"></i>
                    {intl.formatMessage({ id: "FORM.ACTION.ADD" })} {intl.formatMessage({ id: "FORM.LABEL.COLUMN" })}
                  </button>
                  <button type="submit" className={`btn btn-primary ${loadingUser ? "disabled" : ""}`} data-kt-indicator={loadingUser ? 'on' : 'off'}>
                    <span className="indicator-label">
                      {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                    </span>
                    <span className="indicator-progress">
                      Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export { CustomFieldUser }
