import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import { FC, useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import useAccessToken from "../../../hooks/AccessToken";
import FullCalendarComponent from '@fullcalendar/react';
import { Button, ButtonGroup, DropdownButton } from "react-bootstrap";
import { Modal } from "bootstrap";
import { AddCalendar } from "../../../components/Schedule/AddCalendar";
import { AddEvent } from "../../../components/Schedule/AddEvent";
import { DetailEvent } from "../../../components/Schedule/DetailEvent";
import { EventImpl } from "@fullcalendar/core/internal";
import Swal from 'sweetalert2'
import { updateEventTime } from '../../../api/EventCRUD'
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment'
import Select from "react-select";
import { SelectOption } from '../../../interfaces/SelectOption'
import useUser from '../../../hooks/User'
import { DatasetController } from 'chart.js'
import { isUUID } from '../../../functions/general'
import { getCalendar, getEvent } from '../../../api/ScheduleCRUD'
import { settings } from 'cluster'
import { useSettingsStore } from '../../../stores/SettingsStore'
import { getScheduleConference } from '../../../api/ConferenceCRUD'
import { Conference, ScheduleConference } from '../../../interfaces/Conference'
import { useIntl } from 'react-intl'
import { AddScheduleConference } from '../../../components/Conference/AddScheduleConference'
import { DetailScheduleConference } from '../../../components/Conference/DetailScheduleConference'
import { DeleteScheduleConference } from '../../../components/Conference/DeleteScheduleConference'
import { useLanguageStore } from '../../../stores/LanguageStore'
import { useDataTableStore } from '../../../stores/DataTableStore'

interface eventProps {
    id: string,
    events: any
}

const MasterScheduleConferences: FC = () => {
    const { settings } = useSettingsStore()
    const user = useUser()
    const modalRef = useRef<any>(null);
    const { tablesData } = useDataTableStore()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isShowMorePopover, setIsShowMorePopover] = useState<boolean>(false);
    const [showAddCalendarModal, setShowAddCalendarModal] = useState<boolean>(false);
    const [showAddEventModal, setShowAddEventModal] = useState<boolean>(false);
    const [showDetailEventModal, setShowDetailEventModal] = useState<boolean>(false);
    const [event, setEvent] = useState<EventImpl>();
    const [listEvent, setListEvent] = useState<eventProps[]>([]);
    const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
    const [startDate, setStartDate] = useState<string>();
    const calendarRef = useRef<FullCalendarComponent>(null);
    const [viewTitle, setViewTitle] = useState<string>(calendarRef.current?.getApi().view.title ?? '');
    const [viewMode, setViewMode] = useState<string>("month");
    const [dataCalendars, setDataCalendars] = useState<ScheduleConference[]>([]);
    const [roomList, setRoomList] = useState<Conference[]>([]);
    const token = useAccessToken()
    const intl = useIntl()
    const { locale } = useLanguageStore()

    useEffect(() => {
        if (showAddCalendarModal) {
            const modalElement = document.getElementById('tambah-calendar-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddCalendarModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddCalendarModal, setShowAddCalendarModal]);

    useEffect(() => {
        if (showAddEventModal) {
            const modalElement = document.getElementById('add-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddEventModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddEventModal, setShowAddEventModal]);

    useEffect(() => {
        if (showDetailEventModal) {
            const modalElement = document.getElementById('detail-event-modal');

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailEventModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailEventModal, setShowDetailEventModal]);

    const getScheduleConferences = async () => {
        try {
            const response = await getScheduleConference(token)
            if (response.data.success) {
                var data = response.data.data
                setDataCalendars(data)

                data.forEach((room: ScheduleConference) => {
                    var dataEvent = {
                        id: room.id,
                        title: room.roomTitle,
                        start: room.start,
                        end: room.end,
                        color: '#2e95d1',
                    }

                    calendarRef.current?.getApi().addEventSource({ id: room.roomId, events: [dataEvent] })
                    setListEvent((prev) => [...prev, { id: room.roomId, events: [dataEvent] }])
                })
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setIsLoading(false)
            fetchNationalHoliday()
        }
    }

    useEffect(() => {
        if (tablesData.conferences) {
            const filteredRooms = tablesData.conferences.filter((room: Conference) => room.roomCategory === 'SCHEDULE');

            setRoomList(filteredRooms);
            setSelectedCalendars(['all', ...filteredRooms.map((calendar: Conference) => calendar.id)]);
        }
    }, [tablesData.conferences]);

    const fetchNationalHoliday = async () => {
        try {
            const response = await fetch(`https://www.googleapis.com/calendar/v3/calendars/id.indonesian%23holiday@group.v.calendar.google.com/events?key=AIzaSyAc73HE8m42QuBt7aNdiVmpIKv9aThqTNM`);
            const data = await response.json();

            data.items.map((item: any) => {
                var dataEvent = {
                    id: item.id,
                    title: item.summary,
                    start: item.start.date || item.start.dateTime,
                    end: item.end.date || item.end.dateTime,
                    color: '#d92929',
                }

                if (calendarRef.current?.getApi().getEventSourceById(dataEvent.id)?.id !== dataEvent.id) {
                    calendarRef.current?.getApi().addEventSource({ id: dataEvent.id, events: [dataEvent] });
                    setListEvent((prev) => [...prev, { id: dataEvent.id, events: [dataEvent] }])
                }
            });
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    useEffect(() => {
        setIsLoading(true)

        getScheduleConferences()
        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    }, [])

    const handleTodayButtonClick = () => {
        calendarRef.current?.getApi().gotoDate(moment().toISOString());
        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    };

    const handleScroll = (event: any) => {
        const calendarElement = calendarRef.current?.getApi();
        const scrollDirection = event.deltaY > 0 ? 'down' : 'up';

        if (calendarElement?.view.type === "dayGridMonth" && !isShowMorePopover) {
            if (scrollDirection === 'down') {
                calendarElement?.next();
            } else {
                calendarElement?.prev();
            }
        }

        setViewTitle(calendarRef.current?.getApi().view.title ?? '')
    };

    function sortCalendar(a: { roomCode: string }, b: { roomCode: string }) {
        const hasSymbol = (roomCode: string) => /\[.*?\]/.test(roomCode);
        if (hasSymbol(a.roomCode) && !hasSymbol(b.roomCode)) {
            return 1; // Pindahkan entitas dengan simbol ke bawah
        }
        if (!hasSymbol(a.roomCode) && hasSymbol(b.roomCode)) {
            return -1; // Pindahkan entitas tanpa simbol ke atas
        }
        return a.roomCode.localeCompare(b.roomCode);
    }

    const handleCheckboxChange = (id: string) => {
        if (selectedCalendars.includes(id)) {
            if (id === "all") {
                dataCalendars.map((value: ScheduleConference) => {
                    calendarRef.current?.getApi().getEventSourceById(value.roomId)?.remove()
                })
                setSelectedCalendars([])
                localStorage.setItem("selectedCalendars", JSON.stringify([]))
            } else {
                dataCalendars.map((value: ScheduleConference) => {
                    if (value.roomId === id) calendarRef.current?.getApi().getEventSourceById(id)?.remove()
                })

                setSelectedCalendars(selectedCalendars.filter((item: any) => item !== id && item !== "all"));
                localStorage.setItem("selectedCalendars", JSON.stringify(selectedCalendars.filter((item: any) => item !== id && item !== "all")))
            }
        } else {
            if (id === "all") {
                dataCalendars.map((value: ScheduleConference) => {
                    calendarRef.current?.getApi().getEventSourceById(value.roomId)?.remove()
                })
                setSelectedCalendars(["all", ...roomList.map((value: any) => value.id)])
                localStorage.setItem("selectedCalendars", JSON.stringify(["all", ...roomList.map((value: any) => value.id)]))
                listEvent.forEach((e: any) => {
                    if (isUUID(e.id)) calendarRef.current?.getApi().addEventSource({ id: e.id, events: e.events })
                })
            } else {
                listEvent.map((value: any) => {
                    if (value.id === id) calendarRef.current?.getApi().addEventSource(value)
                })
                var dataSelectCalendar = [...selectedCalendars, id]

                if (dataSelectCalendar.length === roomList.length) {
                    setSelectedCalendars(["all", ...dataSelectCalendar])
                    localStorage.setItem("selectedCalendars", JSON.stringify(["all", ...dataSelectCalendar]))
                } else {
                    setSelectedCalendars(dataSelectCalendar);
                    localStorage.setItem("selectedCalendars", JSON.stringify(dataSelectCalendar))
                }
            }
        }
    };

    const renderChecklist = () => {
        return (
            <>
                <div className="form-check m-3" style={{ width: "300px" }}>
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-all`}
                        value={"all"}
                        checked={selectedCalendars.includes("all")}
                        onChange={() => handleCheckboxChange("all")}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-all`}>
                        {intl.formatMessage({ id: "CALENDAR.FILTER.ALL" })}
                    </label>
                </div>

                {
                    roomList.sort(sortCalendar).map((item: Conference, index: number) => {
                        return (
                            <div key={index} className="form-check m-3" style={{ width: "300px" }}>
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`checkbox-${index}`}
                                    value={item.id}
                                    checked={selectedCalendars.includes(item.id)}
                                    onChange={() => handleCheckboxChange(item.id)}
                                />
                                <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                    {item.roomCode}
                                </label>
                            </div>

                        )
                    })
                }
            </>
        )
    };

    const handleDateClick = (dateStr: any) => {
        setStartDate(dateStr.allDay ? moment(dateStr.dateStr + " " + moment().format('HH:mm')).toISOString() : moment(dateStr.dateStr).subtract(1, 'hours').toISOString())
        setShowAddEventModal(true)
    }

    const handleEventClick = (info: any) => {
        if (isUUID(info.event.id)) {
            setEvent(info.event)
            setShowDetailEventModal(true)
        }
    }

    useEffect(() => {
        const handleClickOutsideModal = (event: any) => {
            const moreLink = event?.target?.closest('.fc-more-link');
            if (moreLink && !isShowMorePopover) setIsShowMorePopover(true)
            else setIsShowMorePopover(false)
        };

        document.addEventListener('click', handleClickOutsideModal);

        return () => {
            document.removeEventListener('click', handleClickOutsideModal);
        };
    }, [isShowMorePopover, setIsShowMorePopover]);

    return (
        <>
            <div className="card px-3" style={{ marginBottom: "-10px", zIndex: "2", display: "flex", flexFlow: "row", justifyContent: "space-between", paddingTop: "10px" }}>
                <DropdownButton size="sm" title={intl.formatMessage({ id: 'FORM.LABEL.CALENDAR_LIST' })} id="dropdown" className='col-4 col-sm-3 col-lg-2 col-xl-2' >
                    <div style={{ maxHeight: "350px", overflowY: "auto" }}>
                        {renderChecklist()}
                    </div>
                </DropdownButton>
                <div className='col-8 col-sm-6 col-lg-8 col-xl-8'>
                    <div className="nav rounded-pill" style={{ width: "300px", margin: "auto", backgroundColor: "white" }}>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "month" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('dayGridMonth')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("month")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.MONTH" })}
                        </button>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "week" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('timeGridWeek')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("week")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.WEEK" })}
                        </button>
                        <button style={{ width: "100px", padding: "0.6rem 0px" }} className={`nav-link btn btn-active btn-active-primary fw-bolder btn-color-gray-600 rounded-pill ${viewMode === "day" && "active"}`}
                            onClick={() => {
                                calendarRef.current?.getApi().changeView('timeGridDay')
                                setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                setViewMode("day")
                            }}
                        >
                            {intl.formatMessage({ id: "CALENDAR.LABEL.DAY" })}
                        </button>
                    </div>
                </div>
                <div className='col-12 col-sm-3 col-lg-2 col-xl-2 text-end'>
                    <Button variant="primary" size="sm"
                        onClick={() => {
                            setStartDate(moment().toISOString())
                            setShowAddEventModal(true)
                        }}
                    >
                        <span>
                            <i className="fas fa-plus me-2"></i>
                            {intl.formatMessage({ id: "CALENDAR.ACTION.CREATE_NEW" })}
                        </span>
                    </Button>

                </div>
            </div>

            <div className="card p-3 mt-1" onWheel={handleScroll} style={{ height: "100%" }} ref={modalRef}>
                <div className='d-flex justify-content-between align-items-center' style={{ marginBottom: "-17px" }}>
                    <label style={{ fontSize: "x-large", fontWeight: "600" }}>{viewTitle}</label>
                    <div className='d-flex'>
                        <button className="btn btn-sm me-3" style={{ color: "#009ef7", border: "solid 2px #009ef7", padding: "6px 15px" }} onClick={handleTodayButtonClick}>{intl.formatMessage({ id: "CALENDAR.LABEL.TODAY" })}</button>
                        <div>
                            <button className="btn btn-sm me-1"
                                style={{ border: "solid 2px #009ef7", padding: "6px 15px" }}
                                onClick={() => {
                                    calendarRef.current?.getApi().prev()
                                    setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                }}
                            >
                                <i className="fas fa-chevron-left" style={{ color: "#009ef7" }}></i>
                            </button>
                            <button className="btn btn-sm"
                                style={{ border: "solid 2px #009ef7", padding: "6px 15px" }}
                                onClick={() => {
                                    calendarRef.current?.getApi().next()
                                    setViewTitle(calendarRef.current?.getApi().view.title ?? '')
                                }}
                            >
                                <i className="fas fa-chevron-right" style={{ color: "#009ef7" }}></i>
                            </button>
                        </div>
                    </div>
                </div>

                {
                    <FullCalendar
                        nowIndicator={true}
                        timeZone={moment.tz.guess()}
                        ref={calendarRef}
                        plugins={[momentTimezonePlugin, rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView='dayGridMonth'
                        droppable={true}
                        dayMaxEventRows={1}
                        dayMaxEvents={1}
                        eventMaxStack={1}
                        navLinks={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        height={"100%"}
                        headerToolbar={{
                            left: '',
                            center: '',
                            right: ''
                        }}
                        locale={locale}
                        eventTimeFormat={{
                            hour: "numeric",
                            minute: "2-digit",
                            meridiem: false,
                        }}
                        allDaySlot={false}
                        themeSystem="Flatly"
                    />
                }
            </div >

            {
                showAddEventModal && <AddScheduleConference startDate={startDate} calendarRef={calendarRef} roomList={roomList} dataCalendars={dataCalendars} selectedCalendars={selectedCalendars} setSelectedCalendars={setSelectedCalendars} listEvent={listEvent} setListEvent={setListEvent} setDataCalendars={setDataCalendars} />
            }
            {
                showDetailEventModal && <DetailScheduleConference roomList={roomList} calendarRef={calendarRef} eventData={event!} dataCalendars={dataCalendars} listEvent={listEvent} selectedCalendars={selectedCalendars} setListEvent={setListEvent} setShowDetailEventModal={setShowDetailEventModal} setDataCalendars={setDataCalendars} />
            }

        </>
    );
}

export { MasterScheduleConferences }