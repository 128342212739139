import axios from 'axios';
import { MasterDataTable } from '../DataTable/MasterDataTable';
import { useIntl } from 'react-intl';
import useUser from '../../hooks/User';
import React, { ReactNode, useState, useEffect } from 'react'
import useAccessToken from '../../hooks/AccessToken';
import useSWR from 'swr';
import { IconButton, Menu, MenuItem, Tab } from "@mui/material";
import { TableColumn } from "react-data-table-component";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AccountReceivableType } from '../../interfaces/AccountReceivable';
import { DataTableExport } from '../DataTable/DataTableExport';
import { usePipelineStore } from '../../stores/PipelineStore';
import { useReceivableStore } from '../../stores/Receivable';
import Tabs from '@mui/material/Tabs';
import { customNumberFormat } from '../../functions/general';
import DetailAccountReceivable from './DetailAccountReceivable';
import { Modal } from 'bootstrap';
import useModalEffect from '../../hooks/useModalEffect';
import { useCustomeStore } from '../../stores/CustomeStore';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL

export interface LeadTemplate {
    id: string;
    name: string;
    customFields: {
        [key: string]: {
            name: string;
            type: string;
            required: boolean;
            value: any[];
            score: string;
        }
    }
}

const AccountReceivable = () => {
    const user = useUser();
    const token = useAccessToken();
    const intl = useIntl();
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [hideColumns, setHideColumns] = useState<TableColumn<AccountReceivableType>[]>([]);
    const [isShowDetailListTermin, setIsShowDetailListTermin] = useState<boolean>(false);
    const [idDetailReceivable, setIdDetailReceivable] = useState<string>('');
    const { multiPipelines } = usePipelineStore()
    const { companyId } = useCustomeStore()
    const {
        selectTemplateIdPipeline,
        setSelectTemplateIdPipeline,
        setSelectMultiPipelineCustomField,
        selectMultiPipelineCustomField,
    } = useReceivableStore();
    const [activeTab, setActiveTab] = useState(0);

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    useEffect(() => {
        if (multiPipelines && multiPipelines.length > 0) {
            setSelectTemplateIdPipeline(multiPipelines[0].id);
            setSelectMultiPipelineCustomField(multiPipelines[0] as LeadTemplate);
        }
    }, [multiPipelines]);

    const fetcher = async (url: string) => {
        if (!selectTemplateIdPipeline) return null;

        try {
            const response = await axios.get(url, {
                headers: {
                    'X-Auth-token': token
                }
            })
            return response.data
        } catch (error) {
            console.log(error)
        }
    }

    const { data: dataExport } = useSWR(
        selectTemplateIdPipeline ? `${API_URL}receivable-reports/list?leadTemplateId=${selectTemplateIdPipeline}` : null,
        fetcher
    )

    const { data: leadTemplates = [] } = useSWR<LeadTemplate[]>(
        `${API_URL}lead-templates`,
        fetcher
    );

    useEffect(() => {
        if (leadTemplates && leadTemplates.length > 0) {
            setSelectTemplateIdPipeline(leadTemplates[0].id);
        }
    }, [leadTemplates]);

    var tableColumns: TableColumn<AccountReceivableType>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            allowOverflow: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                const leadId = row.leadId
                return (
                    <>
                        <IconButton
                            aria-label={`more-${row.id}`}
                            id={`menu-anchor-${row.id}`}
                            onClick={() => handleClick(row.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id={`menu-${row.id}`}
                            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                            open={openMenuId === row.id}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            <MenuItem onClick={() => {
                                setIsShowDetailListTermin(true);
                                setIdDetailReceivable(leadId)
                                handleClose();
                            }}>
                                <i className="fas fa-eye fs-4 me-3"></i>
                                <span>{intl.formatMessage({ id: 'FORM.LABEL.DETAILS' }, { title: intl.formatMessage({ id: 'MENU.ACCOUNT_RECEIVABLE' }) })}</span>
                            </MenuItem>

                        </Menu>
                    </>
                )
            },
            omit: hideColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }))?.omit
        },
        {
            name: 'Nama Merchant',
            width: '270px',
            selector: row => row.merchantName,
            sortable: true,
            wrap: true,
            style: {
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Nama Merchant')?.omit
        },
        {
            name: 'Nominal Termin',
            width: '170px',
            selector: row => row.terminNominal,
            sortable: true,
            wrap: true,
            style: {
                whiteSpace: 'normal'
            },
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Nominal Termin')?.omit,
            cell: (row) => {
                return (
                    <span className='d-flex justify-content-between' style={{ width: '100%' }}>
                        <span>Rp</span><span>{row.terminNominal && customNumberFormat(Math.round(row.terminNominal))}</span>
                    </span>
                )
            }
        },
        {
            name: 'Produk',
            width: '170px',
            selector: row => row.leadName,
            sortable: true,
            wrap: true,
            style: {
                whiteSpace: 'normal'
            },
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Produk')?.omit,
            cell: (row) => {
                return (
                    <span className='d-flex justify-content-between' style={{ width: '100%' }}>
                        <span>{row.leadName}</span>
                    </span>
                )
            }
        },
        {
            name: 'Nama Template Merchant',
            width: '270px',
            selector: row => row.merchantTemplateName,
            sortable: true,
            wrap: true,
            style: {
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            allowOverflow: true,
            reorder: true,
            omit: hideColumns.find(item => item.name === 'Nama Merchant')?.omit
        },
        ...(user.data.company_id === companyId.giaGroup) ? [
            {
                name: 'No Resi',
                width: '150px',
                selector: (row: AccountReceivableType) => {
                    const noUnitField = row?.['Nomor Resi'];
                    return noUnitField || '-';
                },
                sortable: true,
                wrap: true,
                style: {
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                allowOverflow: true,
                reorder: true,
                omit: hideColumns.find(item => item.name === 'No Unit')?.omit
            },
            {
                name: 'Jatuh Tempo Pembayaran',
                width: '170px',
                selector: (row: AccountReceivableType) => row.tanggalJatuhTempo,
                cell: (row: AccountReceivableType) => {
                    return (
                        <span className='d-flex justify-content-between' style={{ width: '100%' }}>
                            <span>{row.tanggalJatuhTempo ? moment(row.tanggalJatuhTempo).format('DD-MMM-YYYY') : '-'}</span>
                        </span>
                    )
                },
                sortable: true,
                wrap: true,
                style: {
                    whiteSpace: 'normal'
                },
                allowOverflow: true,
                reorder: true,
            }
        ] : []
    ];

    console.log(dataExport, 'dataExport')

    let actions: ReactNode[] = []
    actions.push(<DataTableExport source={"account-receivable"} dataExportCustom={dataExport} user={user} />)

    useModalEffect({
        showModal: isShowDetailListTermin,
        setShowModal: setIsShowDetailListTermin,
        modalId: `detail-receivable-account-${idDetailReceivable}`
    })

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        const selectedTemplate = multiPipelines[newValue];
        if (selectedTemplate) {
            setSelectTemplateIdPipeline(selectedTemplate.id);
            setSelectMultiPipelineCustomField(selectedTemplate as LeadTemplate);
        }
    };

    return (
        <>
            <div className='row mb-5'>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="template tabs"
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': { textTransform: 'none' }
                    }}
                >
                    {multiPipelines.map((template, index) => (
                        <Tab
                            key={template.id}
                            label={template.name}
                            id={`template-tab-${index}`}
                            aria-controls={`template-tabpanel-${index}`}
                        />
                    ))}
                </Tabs>
            </div>

            <div className='row'>
                {
                    multiPipelines && multiPipelines.length > 0 && selectTemplateIdPipeline ? (
                        <MasterDataTable
                            tableKey="account-receivable"
                            tableColumns={tableColumns}
                            actions={actions}
                            expanded
                            apiURL={`receivable-reports/list?leadTemplateId=${selectTemplateIdPipeline}`}
                            order='desc'
                        />
                    ) : (
                        <div className="alert alert-info">
                            {intl.formatMessage({ id: 'No template available' })}
                        </div>
                    )
                }
            </div>
            {
                isShowDetailListTermin && (
                    <DetailAccountReceivable receivableId={idDetailReceivable} />
                )
            }
        </>
    )
}

export default AccountReceivable