import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const CALENDAR = `${API_URL}calendars/`

type InsertCalendar = {
    name: string
    description?: string
    token: string
}

type UpdateCalendar = {
    id: string
    name: string
    description?: string
    token: string
}

export const insertCalendar = async ({
    name,
    description,
    token,
}: InsertCalendar) => {
    return axios.post(CALENDAR, {
        name,
        description,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateCalendar = async ({
    id,
    name,
    description,
    token,
}: UpdateCalendar) => {
    return axios.patch(CALENDAR + id, {
        name,
        description,
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteCalendar = async (id: string, token: string) => {
    return axios.delete(CALENDAR + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const invitationResponse = async (idCalendar: string, idEvent: string, response: string, token: string) => {
    return axios.put(CALENDAR + idCalendar + '/events/' + idEvent + '/invitation-response',
        {
            response
        },
        {
            headers: {
                'X-Auth-token': token
            }
        })
}