import { FC, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { customNumberFormat, getProgressColor } from "../../functions/general";
import { Summary } from "../../interfaces/Summary";
import { UserTargetAchievement } from "../../interfaces/Dashboard";
import { useIntl } from "react-intl";
import { useSettingsStore } from "../../stores/SettingsStore";
import useProgressPhrase from "../../hooks/ProgressPhrase";

interface SummaryProgressProps {
    summary: UserTargetAchievement[]
    summaryLoading: boolean
    date: string
}

const SummaryProgressWidget: FC<SummaryProgressProps> = ({ summary, summaryLoading, date }) => {
    const { settings } = useSettingsStore()
    const [index, setIndex] = useState(0)
    const [progressBar, setProgressBar] = useState(0)
    const intl = useIntl()
    const progressPhrase = useProgressPhrase(progressBar);

    useEffect(() => {
        if (!summaryLoading) {
            let dataProgress = Math.round((summary[index].progress / summary[index].target) * 100)
            if (dataProgress > 100) setProgressBar(100)
            else setProgressBar(dataProgress)
        }
    }, [index, summaryLoading, summary])

    const handlePrevious = () => {
        if (index > 0) {
            setIndex(index - 1)
        } else if (index === 0) {
            setIndex(summary.length - 1)
        }
    }

    const handleNext = () => {
        if (index < summary.length - 1) {
            setIndex(index + 1)
        } else if (index === summary.length - 1) {
            setIndex(0)
        }
    }
    
    return (
        <div className="card-body my-3 mx-3">
            <div className="d-flex justify-content-between">
                <span className={`card-title text-light fs-5 mb-3 d-block`}>
                    {
                        summary[index].name === "Jumlah Meeting" ?
                        `${intl.formatMessage({ id: "FORM.LABEL.TOTAL_QUANTITY_MEETINGS_MONTH" }, {title: settings.meeting_title})}` :
                        summary[index].name === "Produk Terjual" ?
                        `${intl.formatMessage({ id: "FORM.LABEL.TOTAL_PRODUCTS_SOLD_MONTH" })}` :
                        `${intl.formatMessage({ id: "FORM.LABEL.TOTAL_NOMINAL_LEADS_SUCCESS_MONTH" }, {title: settings.pipeline_title})}`
                    }
                    <br />
                    {date}
                </span>
                <span className="ms-5 fs-5 fw-bolder">
                    <i className="fas fa-chevron-left text-white" onClick={handlePrevious} style={{ cursor: "pointer" }}></i>
                    <span className="mx-3">
                        {index + 1}
                    </span>
                    <i className="fas fa-chevron-right text-white" onClick={handleNext} style={{ cursor: "pointer" }}></i>
                </span>
            </div>
            <span className="fs-1 fw-bolder">
                {
                    summaryLoading ?
                        <Skeleton width={60} inline />
                        :
                        customNumberFormat(summary[index].progress ?? 0)
                }
            </span>
            <span className="fs-1 ">&nbsp;/&nbsp;</span>
            <span className="fs-1 ">
                {
                    summaryLoading ?
                        <Skeleton width={60} inline />
                        :
                        `${customNumberFormat(summary[index].target)}`
                }
            </span>
            <div className="d-flex justify-content-end mt-1 w-100">
                <span className="fs-5 pe-1 percentageCredit2">
                    {
                        summaryLoading ?
                            <Skeleton />
                            :
                            progressPhrase
                    }
                </span>
            </div>
            <div className="h-8px my-5 w-100 light-green rounded">
                <div className={`bg-light rounded h-8px`} role="progressbar" style={{
                    width: `${progressBar <= 100 ? progressBar : 100}%`
                }}></div>
            </div>
        </div>
    )
}

export { SummaryProgressWidget }