import { FC} from "react";
import {  Pipeline } from "../../interfaces/Pipeline";
import moment from "moment-timezone";
import { customNumberFormat, getDateSummary, truncateText } from "../../functions/general";

import { useIntl } from "react-intl";

import { IconButton} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';




interface FakeCardPipelineProps {
    data: Pipeline
}



const FakeCardPipeline: FC<FakeCardPipelineProps> = ({  data }) => {
    const intl = useIntl()

    return (
        <div 
        >
            <IconButton
                aria-label={`more-${data.id}`}
                id={`menu-kanban-anchor-${data.id}`}
                style={{ position: "absolute", right: "3px", top: "5px" }}
            >
                <MoreVertIcon />
            </IconButton>

         

            <div className="card-body grabbable-comp">
                <div className="d-flex flex-stack mb-3">
                    <div className="badge badge-light-dark">{data.owner_name}</div>

                </div>
                <label className="fs-4 fw-bolder text-gray-900 mb-3">{data.merchant_name}</label>
                <div className="d-flex flex-stack flex-wrapr">
                    <div className="fs-7 fw-bolder text-gray-600">
                        <span className="svg-icon svg-icon-3 me-2">
                            <i className="far fa-calendar"></i>
                        </span>
                        {intl.formatMessage({ id: 'FORM.LABEL.DATE_STARTED' })} {moment(data.date_start).format("DD MMM YYYY")}
                    </div>
                    <div className="fs-7 fw-bolder text-gray-600">
                        <span className="svg-icon svg-icon-3 me-2">
                            <i className="fas fa-book"></i>
                        </span>
                        {customNumberFormat(data.lead_logs_count)}
                    </div>
                </div>
                <div className="d-flex flex-stack flex-wrapr">
                    <span className="ms-1 fs-7 fw-bolder">Rp. {customNumberFormat(data.nominal)}</span>
                    <span className="ms-1 fs-7 fw-bolder"><i>{getDateSummary(data.lead_age)}</i></span>
                </div>
                {
                    data.note &&
                    <label className="fs-8 text-gray-900">*{truncateText(data.note, 50)}</label>
                }
            </div>
        </div>
    )
}

export { FakeCardPipeline }