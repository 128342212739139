import { create } from 'zustand';
import { Subordinate } from '../interfaces/Subordinate';

type SubordinateState = {
  subordinates: Subordinate[];
  setSubordinates: (subordinate: Subordinate[]) => void;
};

export const useSubordinateStore = create<SubordinateState>((set, getState) => ({
  subordinates: [],

  setSubordinates: (subordinates) => set({ subordinates }),
}));
