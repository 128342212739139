import { useIntl } from "react-intl"

const useCalendarLocale = () => {

    const intl = useIntl();

    return {
        sunday: intl.formatMessage({ id: "CALENDAR.LOCALE.SUNDAY" }),
        monday: intl.formatMessage({ id: "CALENDAR.LOCALE.MONDAY" }),
        tuesday: intl.formatMessage({ id: "CALENDAR.LOCALE.TUESDAY" }),
        wednesday: intl.formatMessage({ id: "CALENDAR.LOCALE.WEDNESDAY" }),
        thursday: intl.formatMessage({ id: "CALENDAR.LOCALE.THURSDAY" }),
        friday: intl.formatMessage({ id: "CALENDAR.LOCALE.FRIDAY" }),
        saturday: intl.formatMessage({ id: "CALENDAR.LOCALE.SATURDAY" }),
        ok: intl.formatMessage({ id: "DATEPICKER.APPLY" }),
    }
}

export default useCalendarLocale;