import React, { useCallback, useEffect, useState, useRef } from 'react'
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table'
import { useTokenCustomCompany } from '../../../stores/useTokenCustomCompany'
import { useChatStore } from '../../../stores/ChatStore'
import Swal from 'sweetalert2'
import useUser from '../../../hooks/User'
import ModalSqanQr from './ModalSqanQr'
import useModalEffect from '../../../hooks/useModalEffect'
import { clearDB } from '../../../db'
import { useDispatch } from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import { UserModel } from '../../../app/modules/auth/models/UserModel'
import { checkSession } from '../../../api/ChatHelper'
import useAccessToken from '../../../hooks/AccessToken'
import { Socket, io } from 'socket.io-client';
import useSWR, { mutate } from 'swr'
import useShowCompanyById from '../../../hooks/useShowCompanyById'
import { useIntl } from 'react-intl'
import { ListChatPhone } from '../../../interfaces/Chat/ListChatPhone'
import axios from 'axios'
import { createSessionChat } from '../../../api/ChatCRUD'
import { generateColorChatPhone } from '../../../functions/chat'

const SelectUser = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const { setSelectedChatPhone, selectedChatPhone, loadingListChatPhone, setConnected, listChatPhone, setTokenChat, setSocket } = useChatStore()
    const { setTokenCustomCompany, setNumberCustomCompany } = useTokenCustomCompany()
    const [globalFilter, setGlobalFilter] = useState('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const token = useAccessToken()
    const user = useUser()
    const [showModalSqanQr, setShowModalSqanQr] = useState(false)
    const [dataChatPhone, setDataChatPhone] = useState<ListChatPhone>({} as ListChatPhone)
    const { company, companyChatToken } = useShowCompanyById(user.data.company_id)
    const [sorting, setSorting] = useState<SortingState>([])
    const intl = useIntl()

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, val: ListChatPhone) => {
        let checked = e.target.checked

        if (checked) {
            setSelectedChatPhone(() => [...selectedChatPhone, val])
        } else {
            setSelectedChatPhone(() => selectedChatPhone.filter((item) => item.phone !== val.phone))
        }
    }

    const handleShowQr = (val: ListChatPhone) => {
        setDataChatPhone(val)
        setShowModalSqanQr(true)
    }

    const createColumns = () => {
        const columnHelper = createColumnHelper<ListChatPhone>()
        return [
            columnHelper.accessor('phone', {
                id: 'action',
                header: 'Aksi',
                size: 10,
                enableSorting: false,
                cell: (info) => {
                    if (info.row.original.connected) {
                        return (
                            <div className="form-check form-check-custom form-check-solid" style={{ display: "contents" }}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={info.row.original.phone}
                                    checked={selectedChatPhone.some((item) => item.phone === info.row.original.phone)}
                                    onChange={(e) => handleCheckboxChange(e, info.row.original)}
                                    disabled={!info.row.original.connected}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <button type="button" onClick={() => handleShowQr(info.row.original)} className={`btn btn-sm btn-primary`}>
                                Scan QR Code
                            </button>
                        )
                    }
                }
            }),
            columnHelper.accessor('ownersName', {
                header: 'Nama',
                cell: (info) => info.getValue().join(', '),
            }),
            columnHelper.accessor('phone', {
                header: 'Nomor Telepon',
                size: 30,
                cell: (info) => info.getValue(),
            }),
            columnHelper.accessor('connected', {
                header: 'Status',
                size: 30,
                cell: (info) => {
                    const data = info.row.original;
                    return data.connected ? (
                        <span className="badge badge-light-success fs-8 fw-bolder" >
                            <i className="fas fa-link me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CONNECTED' })}
                        </span >
                    ) : (
                        <span className="badge badge-light-danger fs-8 fw-bolder">
                            <i className="fas fa-unlink me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DISCONNECTED' })}
                        </span>
                    )
                }
            }),
        ]
    }

    useModalEffect({
        showModal: showModalSqanQr,
        setShowModal: setShowModalSqanQr,
        modalId: `scan-qr-modal`
    })

    const handleCreateSession = async () => {
        const { selectedChatPhone } = useChatStore.getState();
        setIsLoading(true)

        try {
            const res = await createSessionChat(selectedChatPhone.map((item) => item.phone), token)
            if (res.data.success) {
                const socket = io(`${API_URL}whatsapp-chat`, {
                    reconnection: true,
                    reconnectionDelayMax: 10000,
                    auth: { CRMToken: token, token: res.data.data.token }
                });
                const updateSelectedChatPhone = selectedChatPhone.map((item: ListChatPhone, index: number) => ({
                    ...item,
                    color: generateColorChatPhone(index)
                }))
                setSelectedChatPhone(() => updateSelectedChatPhone)
                setSocket(() => socket)
                setTokenChat(() => res.data.data.token)
                setConnected(() => true)
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.response.data.message,
            })
        } finally {
            setIsLoading(false)
        }
    }

    const columns = createColumns()

    const table = useReactTable({
        data: listChatPhone,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            globalFilter,
            sorting,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: (row, columnId, filterValue) => {
            const searchValue = filterValue.toLowerCase()
            const userName = String(row.original.ownersName).toLowerCase()
            const userPhone = String(row.original.phone).toLowerCase()
            return userName.includes(searchValue) || userPhone.includes(searchValue)
        },
        initialState: {
            pagination: {
                pageSize: 5,
                pageIndex: 0,
            },
        },
    })

    useEffect(() => {
        if(listChatPhone.length === 1) {
            if(listChatPhone[0].connected) {
                setSelectedChatPhone(() => listChatPhone)
                handleCreateSession()
            }
        }
    }, [listChatPhone])

    return (
        <>
            <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", backgroundColor: 'white' }}>
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: "url('/media/assets/bg_chat.png')",
                    backgroundRepeat: "round",
                    backgroundSize: "contain",
                    opacity: 0.3,
                }}></div>
                <div className="card card-custom w-100" style={{ maxWidth: "1000px", boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.05)' }}>
                    {
                        loadingListChatPhone && (
                            <div className="loading-overlay">
                                <div className="loader"></div>
                            </div>
                        )
                    }
                    <div className="card-header">
                        <div className='d-flex justify-content-between align-items-center w-100'>
                            <div className="d-flex align-items-center position-relative my-1 skeleton-button" style={{ width: 'fit-content' }}>
                                <span className="svg-icon svg-icon-1 position-absolute ms-6 mt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                    </svg>
                                </span>
                                <input
                                    type="search"
                                    data-kt-lists-table-filter="search"
                                    className="form-control form-control-solid w-210px ps-15 skeleton mt-3"
                                    placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })}
                                    id="inputSearch"
                                    value={globalFilter}
                                    onChange={(e) => setGlobalFilter(e.target.value)}
                                />
                            </div>
                            {
                                selectedChatPhone.length > 0 &&
                                <button type="button" onClick={handleCreateSession} className={`btn btn-primary ${isLoading ? "disabled" : ""}`} data-kt-indicator={isLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        Masuk ke Chat
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            }
                        </div>
                    </div>
                    <div className="card-body row">
                        <table className="table table-row-dashed table-row-gray-230 align-middle gs-0 gy-4 text-center">
                            <thead className="table-light" style={{ fontWeight: 'bold' }}>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <tr key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                key={header.id}
                                                style={{ textAlign: 'center' }}
                                                onClick={header.column.getToggleSortingHandler()}
                                                className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                        {header.column.getCanSort() && (
                                                            <span className="ms-2">
                                                                {{
                                                                    asc: <i className="fas fa-sort-up"></i>,
                                                                    desc: <i className="fas fa-sort-down"></i>,
                                                                }[header.column.getIsSorted() as string] ?? <i className="fas fa-sort"></i>}
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody>
                                {table.getRowModel().rows.length === 0 ? (
                                    <tr>
                                        <td colSpan={columns.length} className="text-center py-4">
                                            No data available
                                        </td>
                                    </tr>
                                ) : (
                                    table.getRowModel().rows.map((row) => (
                                        <tr key={row.id} style={{ textAlign: 'center' }}>
                                            {row.getVisibleCells().map((cell) => (
                                                <td key={cell.id}>
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <div className="d-flex align-items-center gap-5">
                                <select
                                    className="form-select form-select-sm font-weight-bold mr-4 border-0 bg-light"
                                    style={{ width: "75px" }}
                                    value={table.getState().pagination.pageSize}
                                    onChange={(e) => {
                                        table.setPageSize(Number(e.target.value));
                                    }}
                                >
                                    {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <nav className='d-flex justify-content-center align-items-center'>
                                <div className="fs-7 text-gray-700">
                                    {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{" "}
                                    {Math.min(
                                        (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                                        table.getFilteredRowModel().rows.length
                                    )}{" "}
                                    of {table.getFilteredRowModel().rows.length}
                                </div>
                                <ul className="pagination mb-0">
                                    <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => table.setPageIndex(0)}
                                            disabled={!table.getCanPreviousPage()}
                                        >
                                            <i className="fas fa-angle-double-left"></i>
                                        </button>
                                    </li>
                                    <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => table.previousPage()}
                                            disabled={!table.getCanPreviousPage()}
                                        >
                                            <i className="fas fa-angle-left"></i>
                                        </button>
                                    </li>
                                    <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => table.nextPage()}
                                            disabled={!table.getCanNextPage()}
                                        >
                                            <i className="fas fa-angle-right"></i>
                                        </button>
                                    </li>
                                    <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                            disabled={!table.getCanNextPage()}
                                        >
                                            <i className="fas fa-angle-double-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div >

            {
                showModalSqanQr && <ModalSqanQr data={dataChatPhone} />
            }
        </>
    )
}

export default SelectUser