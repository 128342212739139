export enum accessType {
    CREATE_LEAD_LOG = 'CREATE_LEAD_LOG',
    VIEW_LEAD = 'VIEW_LEAD'
}

export interface ProgressSettings {
    id: string
    roleId: string
    roleName: string
    accessType: accessType
    leadProgresses: [
        {
            id: string
            name: string
        }
    ]
}