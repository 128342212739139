import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const CHANGE_LANGUAGE = `${API_URL}/user/language`;

export const changeLanguage = async (language: string, accessToken: string) => {
  return axios.put(CHANGE_LANGUAGE, { language }, {
    headers: {
      'x-auth-token': accessToken
    }
  });
};