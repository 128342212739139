import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const UNIT = `${API_URL}units/`;

export const getUnit = (token: string) => {
  return axios.get(UNIT, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const insertUnit = (name: string, alias: string, color: string, token: string) => {
  return axios.post(
    UNIT,
    {
      name,
      alias,
      color,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateUnit = (id: string, name: string, alias: string, color: string, token: string) => {
  return axios.put(
    UNIT + id,
    {
      name,
      alias,
      color,
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const deleteUnit = (id: string, token: string) => {
  return axios.delete(UNIT + id, {
    headers: {
      'X-Auth-token': token,
    },
  });
};

export const getLeadProductList = (unitId: string, token: string) => {
  return axios.get(`${API_URL}lead-products?unitId=` + unitId, {
    headers: {
      'X-Auth-token': token,
    },
  });
};
