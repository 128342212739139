import { FC, useEffect, useState } from "react";
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import { SelectOption } from "../../interfaces/SelectOption";
import axios from "axios";
import useSWR, { mutate } from "swr";
import useAccessToken from "../../hooks/AccessToken";
import useUser from "../../hooks/User";
import { Product } from "../../interfaces/Product";
import { User } from "../../interfaces/User";
import { Progress } from "../../interfaces/Progress";
import { DataTableSelect } from "../DataTable/DataTableSelect";
import { TableColumn } from "react-data-table-component";
import { ContentPipelineProps, Pipeline, TemplatePipelineProps } from "../../interfaces/Pipeline";
import { Modal } from "bootstrap";
import { ModifyTemplate } from "./Template/ModifyTemplate";
import Swal from "sweetalert2";
import { getSettingAll, saveSetting } from "../../api/SettingCRUD";
import { DeleteTemplatePipeline } from "./Template/DeleteTemplate";
import { useIntl } from "react-intl";
import { useCustomeStore } from "../../stores/CustomeStore";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { InputCheckbox } from "../Input/InputCheckbox";
import { useTemplatePipeline } from "../../stores/TemplatePipeline";
import { updateTemplatePipeline } from "../../api/PipelineCRUD";
import { Dropdown } from "rsuite";
import { handleCaps } from "../../functions/general";
import { usePipelineStore } from "../../stores/PipelineStore";
import { LabelOption } from "../../interfaces/Label";
import useModalEffect from "../../hooks/useModalEffect";
import { Source } from "../../interfaces/Source";
import { Drawer, RadioGroup, Radio, ButtonToolbar, Button, IconButton, Placeholder } from 'rsuite';

interface FilterPipelineProps {
    manageColumns: TableColumn<Pipeline>[],
    setManageColumns: React.Dispatch<React.SetStateAction<TableColumn<Pipeline>[]>>
    handleResetFilter: () => void
    selectAllColumn: boolean
    setSelectAllColumn: React.Dispatch<React.SetStateAction<boolean>>
}

const FilterPipeline: FC<FilterPipelineProps> = ({ manageColumns, selectAllColumn, setSelectAllColumn, setManageColumns, handleResetFilter }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const { userTemplatePipeline, companyTemplatePipeline, setUserTemplatePipeline, setCompanyTemplatePipeline } = useTemplatePipeline()
    const { selectMultiPipeline } = usePipelineStore()
    const { settings, setSettings } = useSettingsStore()
    const { selectedFilters, setSelectedFilter } = useDataTableStore();
    const token = useAccessToken()
    const user = useUser()
    const { companyId } = useCustomeStore()
    const [showModifyTemplateModal, setShowModifyTemplateModal] = useState<boolean>(false);
    const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState<boolean>(false);
    const [type, setType] = useState<string>("");
    const [searchColumn, setSearchColumn] = useState<string>("");
    const [productData, setProductData] = useState<SelectOption[]>([]);
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [progressData, setProgressData] = useState<SelectOption[]>([]);
    const [sourceData, setSourceData] = useState<SelectOption[]>([]);
    const [template, setTemplate] = useState<TemplatePipelineProps>({} as TemplatePipelineProps);
    const intl = useIntl()
    const [labelsData, setLabelsData] = useState<SelectOption[]>([]);
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const progressTypesData: SelectOption[] = [
        {
            value: "ONGOING",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.ONGOING" })
        },
        {
            value: "SUCCESS",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.SUCCESS" })
        },
        {
            value: "FAIL",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.FAIL" })
        }
    ]

    const selectConfigs = [
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "MENU.PRODUCTS" })}`,
            tableKey: 'pipelines',
            filterKey: 'leadProducts',
            options: productData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'leadProducts', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.LABELS" })} ${intl.formatMessage({ id: "FORM.OPTION.TYPE.PRODUCT" })}`,
            tableKey: 'pipelines',
            filterKey: 'leadProductLabels',
            options: labelsData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'leadProductLabels', values)
        },

        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PROGRESS_TYPE" })}`,
            tableKey: 'pipelines',
            filterKey: 'lead_progress_progress_type',
            options: progressTypesData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'lead_progress_progress_type', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "MENU.PROGRESSES" })}`,
            tableKey: 'pipelines',
            filterKey: 'lead_progress_name',
            options: progressData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'lead_progress_name', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PIC" })}`,
            tableKey: 'pipelines',
            filterKey: 'owner_name',
            options: usersData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'owner_name', values)
        },
        {
            placeholder: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.SOURCES" })}`,
            tableKey: 'pipelines',
            filterKey: 'lead_source_name',
            options: sourceData,
            onChange: (values: string[]) => setSelectedFilter('pipelines', 'lead_source_name', values)
        }
    ];

    const sensors = useSensors(
        useSensor(MouseSensor, {
            // Require the mouse to move by 10 pixels before activating
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(TouchSensor, {
            // Press delay of 250ms, with tolerance of 5px of movement
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const getUserURL = () => {
        if (user.data.permission_pipeline_create === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data: products = [], error: productsError, isLoading: productsLoading } = useSWR(selectMultiPipeline.id ? `${API_URL}lead-template-product-accesses/${selectMultiPipeline.id}` : null, fetcher)
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)
    const { data: progress = [], error: progressError, isLoading: progressLoading, mutate: mutateProgress } = useSWR(`${API_URL}lead_progresses`, fetcher)
    const { data: labelsProduct = [], error: labelsError, isLoading: labelsLoading } = useSWR<LabelOption[]>(`${API_URL}labels?usableBy[]=product`, fetcher)
    const { data: sources = [], error: sourcesError, isLoading: sourcesLoading } = useSWR(`${API_URL}lead_sources`, fetcher)

    const renderButton = (props: any, ref: any) => {
        return (
            <button {...props} ref={ref} className="btn btn-sm btn-light-linkedin" style={{ padding: "7px 12px", fontSize: "x-small" }}>
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: "FILTER.LABEL.TEMPLATE.ADD" })}
            </button>
        );
    };

    const handleUseTemplate = (template: TemplatePipelineProps) => {
        setSelectedFilter('pipelines', 'leadProducts', template.content.filter.leadProducts || [])
        setSelectedFilter('pipelines', 'lead_progress_progress_type', template.content.filter.lead_progress_progress_type ? template.content.filter.lead_progress_progress_type : ['ONGOING', 'SUCCESS'])
        setSelectedFilter('pipelines', 'lead_progress_name', template.content.filter.lead_progress_name || [])
        setSelectedFilter('pipelines', 'owner_name', template.content.filter.owner_name || [])
        setSelectedFilter('pipelines', 'leadProductLabels', template.content.filter.leadProductLabels || [])
        setSelectedFilter('pipelines', 'lead_source_name', template.content.filter.lead_source_name || [])

        setUserTemplatePipeline(
            userTemplatePipeline.map(item => {
                if (item.id === template.id) {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            use: true
                        }
                    }
                } else {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            use: false
                        }
                    }
                }
            })
        )
        setCompanyTemplatePipeline(
            companyTemplatePipeline.map(item => {
                if (item.id === template.id) {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            use: true
                        }
                    }
                } else {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            use: false
                        }
                    }
                }
            })
        )

        manageColumns.map((columnItem: TableColumn<Pipeline>) => {
            columnItem.omit = template.content.hide.includes(columnItem.id as string);
        });

        manageColumns.sort((a: any, b: any) => {
            const indexA = template.content.sort.indexOf(a.id);
            const indexB = template.content.sort.indexOf(b.id);

            // Jika salah satu ID tidak ada dalam template, tetap di posisi awal
            if (indexA === -1 || indexB === -1) {
                return 0;
            }

            return indexA - indexB;
        });

        if (manageColumns.filter(column => column.omit !== true).length === manageColumns.length) {
            setSelectAllColumn(true)
        } else {
            setSelectAllColumn(false)
        }

        setManageColumns([...manageColumns])
    }

    const handleUpdateUserTemplate = async (typeTemplate: string, template: TemplatePipelineProps) => {
        var dataSort: string[] = []
        var dataHide: string[] = []
        manageColumns.map(item => {
            dataSort.push(item.id as string);

            if (item.omit === true) {
                dataHide.push(item.id as string);
            }
        });

        const dataTemplate: ContentPipelineProps = {
            hide: dataHide,
            sort: dataSort,
            use: false,
            filter: {
                leadProducts: [...selectedFilters['pipelines'].leadProducts],
                lead_progress_name: [...selectedFilters['pipelines'].lead_progress_name],
                lead_progress_progress_type: [...selectedFilters['pipelines'].lead_progress_progress_type],
                owner_name: [...selectedFilters['pipelines'].owner_name],
                leadProductLabels: [...selectedFilters['pipelines'].leadProductLabels],
                lead_source_name: [...selectedFilters['pipelines'].lead_source_name]
            }
        }

        try {
            const response = await updateTemplatePipeline(template.id, selectMultiPipeline.id, template.name, JSON.stringify(dataTemplate), token)
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    heightAuto: false,
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" })}`,
                    timer: 2000,
                    timerProgressBar: true
                })

                if (typeTemplate === 'update-template-user') {
                    setUserTemplatePipeline(userTemplatePipeline.map(item => {
                        if (item.id === template.id) {
                            return {
                                ...item,
                                content: {
                                    ...dataTemplate,
                                    use: template.content.use
                                }
                            }
                        } else {
                            return item
                        }
                    }));
                } else if (typeTemplate === 'update-template-company') {
                    setCompanyTemplatePipeline(companyTemplatePipeline.map(item => {
                        if (item.id === template.id) {
                            return {
                                ...item,
                                content: {
                                    ...dataTemplate,
                                    use: template.content.use
                                }
                            }
                        } else {
                            return item
                        }
                    }));
                }
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            });
        }
    }

    const handleDragEnd = (event: any) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const updateColumns = (manageColumns: TableColumn<Pipeline>[]) => {
                const oldIndex = manageColumns.findIndex(item => item.id === active.id);
                const newIndex = manageColumns.findIndex(item => item.id === over.id);
                return arrayMove(manageColumns, oldIndex, newIndex);
            };

            const newColumns = updateColumns(manageColumns);
            setManageColumns(newColumns);
        }
    };

    const handleCheckUseTemplate = () => {
        const pipelines = selectedFilters.pipelines;
        if (!pipelines) return false;

        const hasOmittedColumns = manageColumns.some((data: TableColumn<Pipeline>) => data.omit === true);
        const hasRelevantFilters = pipelines.leadProducts?.length > 0 || pipelines.lead_progress_name?.length > 0 || pipelines.owner_name?.length > 0 || pipelines.leadProductLabels?.length > 0 || pipelines.labels?.length > 0 || pipelines.lead_source_name?.length > 0;
        const isLeadProgressTypeIncomplete = () => {
            const progressTypes: string[] | string = pipelines.lead_progress_progress_type || [];
            return !(progressTypes.includes("ONGOING") && progressTypes.includes("SUCCESS") && progressTypes.length === 2);
        };

        return hasOmittedColumns || hasRelevantFilters || isLeadProgressTypeIncomplete();
    };

    const handleClickAllColumn = () => {
        const newManageColumns = [...manageColumns];
        newManageColumns.map((data: TableColumn<Pipeline>) => (data.omit = selectAllColumn));
        setManageColumns(newManageColumns);
        setSelectAllColumn(!selectAllColumn);
    }

    useEffect(() => {
        if (!productsLoading) {
            const productsData = products.map((products: Product) => ({
                value: products.name,
                label: products.name
            }));

            setProductData(productsData)
        }
    }, [products])

    useEffect(() => {
        if (!sourcesLoading) {
            const sourcesData = sources.map((source: Source) => ({
                value: source.name,
                label: source.name
            }));

            setSourceData(sourcesData)
        }
    }, [sources])

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.name,
                label: user.name
            }));

            if (user.data.permission_pipeline_create !== "ALL") {
                usersData.unshift({ value: user.data.name, label: user.data.name })
            }

            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [users]);

    useEffect(() => {
        if (!progressLoading) {
            if (selectedFilters?.pipelines?.lead_progress_progress_type.length > 0) {
                const filteredData = progress.filter((progress: Progress) => selectedFilters?.pipelines?.lead_progress_progress_type.includes(progress.progress_type));
                const progressData = filteredData.map((progress: Progress) => ({
                    value: progress.name,
                    label: progress.name
                }));

                if (selectedFilters?.pipelines?.lead_progress_progress_type.includes('ONGOING') && user.data.company_id !== companyId.elgisa) {
                    progressData.unshift(
                        { label: `${intl.formatMessage({ id: "FORM.LABEL.NEW" })}`, value: `${intl.formatMessage({ id: "FORM.LABEL.NEW" })}` },
                    )
                }

                if (user.data.company_id === companyId.elgisa) {
                    progressData.unshift(
                        { label: `New-Sales`, value: "NEW-Sales" },
                        { label: `New-Service`, value: "NEW-Service" },
                    )
                }

                setProgressData(progressData)
            } else {
                const progressData = progress.map((progress: Progress) => ({
                    value: progress.name,
                    label: progress.name
                }));

                if (user.data.company_id !== companyId.elgisa) {
                    progressData.unshift(
                        { label: `${intl.formatMessage({ id: "FORM.LABEL.NEW" })}`, value: `${intl.formatMessage({ id: "FORM.LABEL.NEW" })}` },
                    )
                }

                if (user.data.company_id === companyId.elgisa) {
                    progressData.unshift(
                        { label: `New-Sales`, value: "NEW-Sales" },
                        { label: `New-Service`, value: "NEW-Service" },
                    )
                }
                setProgressData(progressData)
            }
        }
    }, [progress, selectedFilters?.pipelines?.lead_progress_progress_type])

    useEffect(() => {
        if (!labelsLoading) {
            setLabelsData(labelsProduct.map((label: LabelOption) => ({
                value: label.name,
                label: label.name
            })))
        }
    }, [labelsProduct])

    useModalEffect({
        showModal: showModifyTemplateModal,
        setShowModal: setShowModifyTemplateModal,
        modalId: 'add-template-pipeline-modal'
    })

    useModalEffect({
        showModal: showDeleteTemplateModal,
        setShowModal: setShowDeleteTemplateModal,
        modalId: `delete-template-pipeline-modal-${template.id}`
    })

    useEffect(() => {
        setSelectedFilter('pipelines', 'leadProducts', [])
        setSelectedFilter('pipelines', 'lead_progress_progress_type', ['ONGOING', 'SUCCESS'])
        setSelectedFilter('pipelines', 'lead_progress_name', [])
        setSelectedFilter('pipelines', 'owner_name', [])
        setSelectedFilter('pipelines', 'leadProductLabels', [])
        setSelectedFilter('pipelines', 'labels', [])
        setSelectedFilter('pipelines', 'lead_source_name', [])
    }, [])

    return (
        <div>
            {
                (handleCheckUseTemplate()) ?
                    <>
                        <button className="btn mt-3 me-3" style={{ backgroundColor: "#f1faff", border: "2px solid #009EF7", padding: "calc(0.75rem) calc(1.5rem)" }} onClick={() => setIsOpenDrawer(true)}>
                            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 svg-icon-primary m-0' />
                        </button>
                        <button type="button"
                            className="btn btn-white mt-3 text-primary"
                            onClick={() => { handleResetFilter() }}
                        >
                            Reset Filter
                        </button>
                    </>
                    :
                    <button type="button" className="btn btn-light mt-3" onClick={() => setIsOpenDrawer(true)}>
                        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2 m-0' />
                    </button>
            }

            <Drawer
                className="bg-white"
                size="sm"
                placement="right"
                open={isOpenDrawer}
                onClose={() => setIsOpenDrawer(false)}
                style={{ height: "100%" }}
            >
                <div className="card w-100 rounded-0" style={{ height: "100%" }}>
                    <div className="card-header pe-5">
                        <div className="card-title">
                            <div className="d-flex justify-content-center flex-column me-3">
                                <span className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1">Filter {settings.pipeline_title}</span>
                            </div>
                        </div>
                        <div className="card-toolbar">
                            <div className="btn btn-sm btn-icon btn-active-light-primary" onClick={() => setIsOpenDrawer(false)}>
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                        <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="card-body" style={{ overflowY: "auto", height: 'calc(100% - 56px)' }}>
                        <div className="d-flex justify-content-between align-items-center">
                            <div style={{ fontWeight: "600" }}>{intl.formatMessage({ id: "FILTER.LABEL.TEMPLATE.LIST" })}</div>
                            <div>
                                {
                                    user.data.is_superadmin ?
                                        <Dropdown renderToggle={renderButton} title={`${intl.formatMessage({ id: 'FORM.ACTION.ADD' })} ${settings.meeting_title}`} noCaret placement="bottomEnd">
                                            <Dropdown.Item onClick={() => { setShowModifyTemplateModal(true); setType("insert-template-user") }}>Personal</Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setShowModifyTemplateModal(true); setType("insert-template-company") }}>Company</Dropdown.Item>
                                        </Dropdown>
                                        :
                                        <button className="btn btn-sm btn-light-linkedin" onClick={() => { setShowModifyTemplateModal(true); setType("insert-template-user") }} style={{ padding: "7px 12px", fontSize: "x-small" }}>{intl.formatMessage({ id: "FILTER.LABEL.TEMPLATE.ADD" })}</button>
                                }
                            </div>
                        </div>
                        <div className="separator separator-dashed my-3"></div>
                        {
                            companyTemplatePipeline.length > 0 &&
                            <small>Company</small>
                        }
                        {
                            companyTemplatePipeline.map((dataCompanyTemplate: TemplatePipelineProps, index) => (
                                <div className={`d-flex cursor-pointer ${!dataCompanyTemplate.content.use && "bg-hover-light"} p-1 my-1 rounded-2`} style={dataCompanyTemplate.content.use ? { backgroundColor: "#009EF7" } : { backgroundColor: "white" }}>
                                    {
                                        dataCompanyTemplate.content.use ?
                                            <div className="align-items-center w-100 ps-2" style={{ display: "inherit" }}>
                                                <div className="bullet w-8px h-6px rounded-2 me-2" style={{ backgroundColor: "#E4E6EF" }}></div>
                                                <span className={`text-light fs-6`}>{dataCompanyTemplate.name}</span>
                                            </div>
                                            :
                                            <div className="align-items-center w-100 ps-2" style={{ display: "inherit" }} onClick={() => { handleUseTemplate(dataCompanyTemplate) }}>
                                                <div className="bullet w-8px h-6px rounded-2 me-2" style={{ backgroundColor: "#E4E6EF" }}></div>
                                                <span className={`text-gray-800" fs-6`}>{dataCompanyTemplate.name}</span>
                                            </div>
                                    }

                                    {
                                        user.data.is_superadmin &&
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-center">
                                                <div className="dropup-center dropup">
                                                    <span className="badge px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className={`fas fs-8 fa-ellipsis-v ${dataCompanyTemplate.content.use && "text-light"}`}></i>
                                                    </span>
                                                    <ul className="dropdown-menu">
                                                        <li>
                                                            <div
                                                                className="dropdown-item pointer-cursor"
                                                                onClick={() => {
                                                                    setShowModifyTemplateModal(true)
                                                                    setType("update-template-company")
                                                                    setTemplate(dataCompanyTemplate)
                                                                }}
                                                            >
                                                                {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })} {intl.formatMessage({ id: "FORM.LABEL.NAME" })}
                                                            </div>
                                                        </li>
                                                        {
                                                            dataCompanyTemplate.content.use &&
                                                            <li>
                                                                <div
                                                                    className="dropdown-item pointer-cursor"
                                                                    onClick={() => {
                                                                        handleUpdateUserTemplate("update-template-company", dataCompanyTemplate)
                                                                    }}
                                                                >
                                                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                                                </div>
                                                            </li>
                                                        }
                                                        <li onClick={() => { setShowDeleteTemplateModal(true); setTemplate(dataCompanyTemplate); setType("delete-template-company") }}>
                                                            <div className="dropdown-item pointer-cursor">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))
                        }

                        {
                            userTemplatePipeline.length > 0 &&
                            <small>Personal</small>
                        }
                        {
                            userTemplatePipeline.map((dataUserTemplate: TemplatePipelineProps, index) => (
                                <div className={`d-flex cursor-pointer ${!dataUserTemplate.content.use && "bg-hover-light"} p-1 my-1 rounded-2`} style={dataUserTemplate.content.use ? { backgroundColor: "#009EF7" } : { backgroundColor: "white" }}>
                                    {
                                        dataUserTemplate.content.use ?
                                            <div className="align-items-center w-100 ps-2" style={{ display: "inherit" }}>
                                                <div className="bullet w-8px h-6px rounded-2 me-2" style={{ backgroundColor: "#E4E6EF" }}></div>
                                                <span className={`text-light fs-6`}>{dataUserTemplate.name}</span>
                                            </div>
                                            :
                                            <div className="align-items-center w-100 ps-2" style={{ display: "inherit" }} onClick={() => { handleUseTemplate(dataUserTemplate) }}>
                                                <div className="bullet w-8px h-6px rounded-2 me-2" style={{ backgroundColor: "#E4E6EF" }}></div>
                                                <span className={`text-gray-800" fs-6`}>{dataUserTemplate.name}</span>
                                            </div>
                                    }
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-center">
                                            <div className="dropup-center dropup">
                                                <span className="badge px-3" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className={`fas fs-8 fa-ellipsis-v ${dataUserTemplate.content.use && "text-light"}`}></i>
                                                </span>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <div
                                                            className="dropdown-item pointer-cursor"
                                                            onClick={() => {
                                                                setShowModifyTemplateModal(true)
                                                                setType("update-template-user")
                                                                setTemplate(dataUserTemplate)
                                                            }}
                                                        >
                                                            {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })} {intl.formatMessage({ id: "FORM.LABEL.NAME" })}
                                                        </div>
                                                    </li>
                                                    {
                                                        dataUserTemplate.content.use &&
                                                        <li>
                                                            <div
                                                                className="dropdown-item pointer-cursor"
                                                                onClick={() => {
                                                                    handleUpdateUserTemplate("update-template-user", dataUserTemplate)
                                                                }}
                                                            >
                                                                {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                                            </div>
                                                        </li>
                                                    }
                                                    <li onClick={() => { setShowDeleteTemplateModal(true); setTemplate(dataUserTemplate); setType("delete-template-user") }}>
                                                        <div className="dropdown-item pointer-cursor">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="mt-10" style={{ fontWeight: "600" }}>Filter Data</div>
                        <div className="separator separator-dashed my-3"></div>
                        {
                            selectConfigs.map((config, index) => (
                                <DataTableSelect
                                    placeholder={config.placeholder}
                                    tableKey={config.tableKey}
                                    filterKey={config.filterKey}
                                    key={index}
                                    options={config.options}
                                    onChange={config.onChange}
                                    manageColumns={manageColumns}
                                    setManageColumns={setManageColumns}
                                />
                            ))
                        }

                        <div className="mt-10" style={{ fontWeight: "600" }}>{intl.formatMessage({ id: "FORM.LABEL.MANAGE_COLUMN" })}</div>
                        <div className="separator separator-dashed my-3"></div>
                        <div className="d-flex align-items-center bg-hover-light-primary rounded-2 w-100" style={{ padding: "5px" }} onClick={handleClickAllColumn}>
                            <div className="form-check form-check-custom form-check-solid">
                                <input className="form-check-input" type="checkbox" checked={selectAllColumn} />
                            </div>
                            <span className="form-check-label fw-bolder ms-2 w-100">{intl.formatMessage({ id: "FORM.ACTION.SELECT_ALL" })}</span>
                        </div>
                        <DndContext onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={closestCenter}>
                            <SortableContext items={manageColumns as any} strategy={verticalListSortingStrategy}>
                                {
                                    manageColumns.map((data: TableColumn<Pipeline>, index: number) => {
                                        return (
                                            <InputCheckbox
                                                key={index}
                                                id={data.id as string}
                                                manageColumns={manageColumns}
                                                setManageColumns={setManageColumns}
                                                setSelectAllColumn={setSelectAllColumn}
                                                index={index}
                                            />
                                        )
                                    })
                                }
                            </SortableContext>
                        </DndContext>
                    </div>
                </div>
            </Drawer>

            {
                showModifyTemplateModal && <ModifyTemplate type={type} manageColumns={manageColumns} template={template} />
            }
            {
                showDeleteTemplateModal && <DeleteTemplatePipeline type={type} template={template} reset={handleResetFilter} />
            }
        </div>
    )
}

export { FilterPipeline }