import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'
import { CustomFieldType } from '../interfaces/Email/BroadCastEmailType';

type SettingsState = {
    customFieldStore: { [key: string]: CustomFieldType }
    setCustomFieldStore: (customFieldStore: {}) => void;
}

export const useCustomFieldStore = create<SettingsState>()(
    persist(
        (set) => ({
            customFieldStore: {
            },
            setCustomFieldStore: (customFieldStore) => {
                set({ customFieldStore });
            },
        }),
        {
            name: 'custom-field-store-email',
            storage: createJSONStorage(() => localStorage),
        }
    )
)