import { FC, useRef, useEffect, useState, RefObject, CSSProperties } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { insertEvent } from "../../api/EventCRUD";
import { Calendar } from "../../interfaces/Calendar";
import axios from "axios";
import { SelectOption } from "../../interfaces/SelectOption";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import useSWR from "swr";
import { SketchPicker } from "react-color";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import TimezoneSelect from "../Schedule/TimezoneSelect";
import { DatePicker } from 'rsuite';
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl } from "../../functions/general";
import { UserCompany } from "../../interfaces/UserCompany";
import { useSettingsStore } from "../../stores/SettingsStore";
import { Merchant } from "../../interfaces/Merchant";
import { useIntl } from "react-intl";
import useOptionReminder from "../../hooks/OptionReminder";
import { Conference, ScheduleConference } from "../../interfaces/Conference";
import useUser from "../../hooks/User";
import { insertScheduleConference } from "../../api/ConferenceCRUD";
import { isBefore, subDays, startOfToday } from 'date-fns';

interface eventProps {
    id: string,
    events: []
}

interface Props {
    startDate?: string
    calendarRef: RefObject<FullCalendar>
    roomList: Conference[]
    dataCalendars: ScheduleConference[]
    selectedCalendars: string[]
    setSelectedCalendars: React.Dispatch<React.SetStateAction<string[]>>
    listEvent: eventProps[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
    setDataCalendars: React.Dispatch<React.SetStateAction<ScheduleConference[]>>
}

const AddScheduleConference: FC<Props> = ({ startDate, calendarRef, roomList, dataCalendars, selectedCalendars, setSelectedCalendars, listEvent, setListEvent, setDataCalendars }) => {
    const user = useUser()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const token = useAccessToken()
    const { settings } = useSettingsStore()
    const [conferencesData, setConferencesData] = useState<SelectOption[]>([]);
    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            idConference: '',
            roomTitle: '',
            startDate: startDate ? moment(startDate).add(1, 'hour').format("YYYY-MM-DD HH:mm") : moment().add(1, 'hour').format("YYYY-MM-DD HH:mm"),
            endDate: startDate ? moment(startDate).add(2, 'hour').format("YYYY-MM-DD HH:mm") : moment().add(2, 'hour').format("YYYY-MM-DD HH:mm"),
        },
        validationSchema: Yup.object().shape({
            idConference: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROOM" }) })),
            roomTitle: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TITLE" }) })),
            startDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" }) })),
            endDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_ENDED" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            try {
                const response = await insertScheduleConference(
                    values.idConference,
                    user.data.id,
                    moment(values.startDate).toISOString(true),
                    moment(values.endDate).toISOString(true),
                    values.roomTitle,
                    token,
                )
                if (response.data.success || response.status === 201) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" }),
                        timer: 4000,
                        // timerProgressBar: true
                    })

                    // calendarRef.current?.getApi().removeAllEventSources();
                    dataCalendars.map((value: ScheduleConference) => {
                        calendarRef.current?.getApi().getEventSourceById(value.roomId)?.remove()
                    })
                    setListEvent([])

                    var dataEvent = {
                        id: response.data.data.id,
                        title: response.data.data.roomTitle,
                        start: response.data.data.start,
                        end: response.data.data.end,
                        color: '#2e95d1',
                    }

                    const updatedListEvent: any = [...listEvent, { id: response.data.data.roomId, events: [dataEvent] }]

                    setListEvent(updatedListEvent as eventProps[])
                    setDataCalendars([...dataCalendars, response.data.data])
                    var dataSelectCalendar = [...selectedCalendars, values.idConference]

                    updatedListEvent.map((value: ScheduleConference) => {
                        if (dataSelectCalendar.includes(value.id)) calendarRef.current?.getApi().addEventSource(value)
                    })

                    if (dataSelectCalendar.length === roomList.length) {
                        setSelectedCalendars(["all", ...dataSelectCalendar])
                        localStorage.setItem("selectedCalendars", JSON.stringify(["all", ...dataSelectCalendar]))
                    } else {
                        setSelectedCalendars(dataSelectCalendar);
                        localStorage.setItem("selectedCalendars", JSON.stringify(dataSelectCalendar))
                    }

                    closeModalRef.current?.click();
                }
                // else {
                //     throw new Error(response.data.message)
                // }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            calendarRef.current?.getApi().getEventById('tempEvent')?.remove()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        const conferencesData = roomList.reduce((acc: SelectOption[], conference: Conference) => {
            if (conference.roomCategory === 'SCHEDULE') {
                acc.push({
                    value: conference.id,
                    label: conference.roomCode,
                });
            }
            return acc;
        }, []);

        setConferencesData(conferencesData)
    }, [roomList])

    useEffect(() => {
        calendarRef.current?.getApi().addEvent({
            id: "tempEvent",
            title: '(No Title)',
            start: startDate,
            allDay: true
        })
    }, [])

    return (
        <div className="modal fade" tabIndex={-1} id={"add-event-modal"} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.ADD_EVENT" })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.ROOM' })}</label>
                            <div className="input-group mb-3">
                                <Select
                                    options={conferencesData}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.ROOM' })}`}
                                    value={conferencesData.find(option => option?.value === formik.values.idConference) || null}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "idConference",
                                                value: option?.value || ""
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.idConference === "" && formik.setFieldTouched("idConference", true)
                                    }
                                    className="w-100"
                                />
                            </div>
                            {formik.touched.idConference && formik.errors.idConference && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.idConference}</span>
                                </div>
                            )}

                            {/* Title */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.TITLE' })}
                                    {...formik.getFieldProps('roomTitle')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.roomTitle && formik.errors.roomTitle },
                                    )}
                                    type='text'
                                    name='roomTitle'
                                    style={{ zIndex: 0 }}
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.roomTitle && formik.errors.roomTitle && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.roomTitle}</span>
                                </div>
                            )}

                            <div className="row mb-2">
                                <div className="col">
                                    {/* StartDate */}
                                    <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_STARTED' }, { title: "" })}</label>
                                    <div className="input-group">
                                        <DatePicker
                                            style={{ width: "100%", zIndex: 0 }}
                                            cleanable={false}
                                            editable={false}
                                            format="dd/MM/yyyy HH:mm"
                                            container={document.querySelector('#add-event-modal') as HTMLElement}
                                            value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                    var now = moment().format('YYYY-MM-DD HH:mm');
                                                    var isBeforeDate = moment(val).isBefore(now)

                                                    if (isBeforeDate) formik.setFieldValue('startDate', new Date())
                                                    else formik.setFieldValue('startDate', value)
                                                } else formik.setFieldValue('startDate', null)
                                            }}
                                            shouldDisableDate={(date: Date) => {
                                                const currentDate = moment(date);
                                                return currentDate.isBefore(moment(), 'day');
                                            }}
                                            shouldDisableHour={(hour: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'day')) {
                                                    return hour < now.hour();
                                                }
                                                return false;
                                            }}
                                            shouldDisableMinute={(minute: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'hour')) {
                                                    return minute < now.minute();
                                                }
                                                return false;
                                            }}
                                        />
                                    </div>
                                    {formik.touched.startDate && formik.errors.startDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                        </div>
                                    )}
                                </div>
                                <div className="col">
                                    {/* EndDatee */}
                                    <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE_ENDED' }, { title: "" })}</label>
                                    <div className="input-group">
                                        <DatePicker
                                            style={{ width: "100%", zIndex: 0 }}
                                            cleanable={false}
                                            editable={false}
                                            placement="bottomEnd"
                                            format="dd/MM/yyyy HH:mm"
                                            container={document.querySelector('#add-event-modal') as HTMLElement}
                                            value={formik.values.endDate ? new Date(formik.values.endDate) : null}
                                            onChange={(value: any) => {
                                                if (value) {
                                                    var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                    var now = moment().format('YYYY-MM-DD HH:mm');
                                                    var isBeforeDate = moment(val).isBefore(now)

                                                    if (isBeforeDate) formik.setFieldValue('endDate', new Date())
                                                    else formik.setFieldValue('endDate', value)
                                                } else formik.setFieldValue('endDate', null)
                                            }}
                                            shouldDisableDate={(date: Date) => {
                                                const currentDate = moment(date);
                                                return currentDate.isBefore(moment(), 'day');
                                            }}
                                            shouldDisableHour={(hour: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'day')) {
                                                    return hour < now.hour();
                                                }
                                                return false;
                                            }}
                                            shouldDisableMinute={(minute: number, date: Date) => {
                                                const selectedDate = moment(date);
                                                const now = moment();

                                                if (selectedDate.isSame(now, 'hour')) {
                                                    return minute < now.minute();
                                                }
                                                return false;
                                            }}
                                        />
                                    </div>
                                    {formik.touched.endDate && formik.errors.endDate && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.endDate}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AddScheduleConference }