import { create } from 'zustand';
import { SettingMultiMeetings } from '../interfaces/Meeting';
import { Meeting } from '../interfaces';

type MeetingState = {
    meetings: Meeting[];
    meetingLoading: boolean;
    multiMeetings: SettingMultiMeetings[];
    selectMultiMeeting: SettingMultiMeetings;
    setMeetingLoading: (meetingLoading: boolean) => void;
    setMeetings: (meetings: Meeting[]) => void;
    setMultiMeetings: (multiMeetings: SettingMultiMeetings[]) => void;
    setSelectMultiMeeting: (selectMultiMeeting: SettingMultiMeetings) => void;
}

export const useMeetingStore = create<MeetingState>((set, getState) => ({
    meetings: [],
    meetingLoading: false,
    multiMeetings: [],
    selectMultiMeeting: {} as SettingMultiMeetings,

    setMeetings: (meetings) => {
        set({ meetings });
    },
    setMeetingLoading: (meetingLoading) => {
        set({ meetingLoading });
    },
    setMultiMeetings: (multiMeetings) => {
        set({ multiMeetings });
    },
    setSelectMultiMeeting: (selectMultiMeeting) => {
        set({ selectMultiMeeting });
    },
}));