import React, {useEffect, useState} from 'react'
import {
  Table,
  Column,
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  getPaginationRowModel,
} from '@tanstack/react-table'

type ModalTableProps<TData> = {
  getPinningStyles?: (column: Column<TData, unknown>, isHeader: boolean) => React.CSSProperties
  renderCustomCell?: (cellValue: any, cell: Column<TData, unknown>) => React.ReactNode
  usePagination?: boolean
  data: any
  columns: ColumnDef<TData, unknown>[]
}

export default function TableLite<TData>({
  getPinningStyles = () => ({}),
  renderCustomCell,
  usePagination,
  data,
  columns,
}: ModalTableProps<TData>) {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: usePagination?10:30,
  })


  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
  })
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-230 align-middle gs-0 gy-4 text-center'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className='fw-bolder text-dark'
              style={{backgroundColor: '#D4D4D4'}}
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    padding: `12px ${header.column.columnDef.size || 8}px`,
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content',
                    ...getPinningStyles(header.column, true),
                  }}
                  className='align-middle'
                >
                  {header.column.columnDef.header &&
                  typeof header.column.columnDef.header === 'function'
                    ? header.column.columnDef.header(header.getContext())
                    : header.column.columnDef.header}
                  {{
                    asc: <span className='pl-2'>↑</span>,
                    desc: <span className='pl-2'>↓</span>,
                  }[header.column.getIsSorted() as string] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{
                    padding: `12px ${cell.column.columnDef.size || 8}px`,
                    whiteSpace: 'nowrap',
                    minWidth: 'fit-content',
                    ...getPinningStyles(cell.column, false),
                  }}
                  className='align-middle'
                >
                  {typeof cell.column.columnDef.cell === 'function'
                    ? cell.column.columnDef.cell(cell.getContext())
                    : cell.column.columnDef.cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {usePagination && (
        <div className='d-flex justify-content-end align-items-center flex-wrap pt-3 pb-10'>
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex align-items-center gap-5'>
              <select
                className='form-select form-select-sm font-weight-bold mr-4 border-0 bg-light'
                style={{width: '75px'}}
                value={table.getState().pagination.pageSize}
                onChange={(e) => {
                  table.setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <div className='fs-7 text-gray-700'>
                {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{' '}
                {Math.min(
                  (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize,
                  table.getFilteredRowModel().rows.length
                )}{' '}
                of {table.getFilteredRowModel().rows.length}
              </div>
            </div>

            <div className=' d-flex justify-content-end'>
              <nav>
                <ul className='pagination mb-0'>
                  <li className={`page-item ${!table.getCanPreviousPage() ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <i className='fas fa-angle-double-left'></i>
                    </button>
                  </li>
                  <li className={`page-item ${!table.getCanPreviousPage() ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <i className='fas fa-angle-left'></i>
                    </button>
                  </li>
                  <li className={`page-item ${!table.getCanNextPage() ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      <i className='fas fa-angle-right'></i>
                    </button>
                  </li>
                  <li className={`page-item ${!table.getCanNextPage() ? 'disabled' : ''}`}>
                    <button
                      className='page-link'
                      onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                      disabled={!table.getCanNextPage()}
                    >
                      <i className='fas fa-angle-double-right'></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
