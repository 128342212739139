import clsx from 'clsx';
import React, { CSSProperties, ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface BadgeWithTooltipProps {
  children: ReactNode;  // Menerima komponen atau teks
  tooltipText: ReactNode;  // Teks untuk tooltip
  badgeClass?: string;  // Kelas CSS untuk badge, default 'badge-light-info'
  style?: CSSProperties
}

const BadgeTooltip: React.FC<BadgeWithTooltipProps> = ({ children, tooltipText, badgeClass = "badge-light-info" , style}) => {
  return (
    <OverlayTrigger
      placement='top'
      overlay={<Tooltip id='tooltip-labels'>{tooltipText}</Tooltip>}
    >
      <span
        data-toggle='tooltip'
        data-placement='right'
        className={clsx(`cursor-pointer my-2 mx-1  badge-ellipsis badge `, badgeClass)}
        style={style}
      >
        {children}  {/* Menampilkan child yang diteruskan */}
      </span>
    </OverlayTrigger>
  );
};

export default BadgeTooltip;
