import { FC, useRef } from "react";
import { TableColumn } from "react-data-table-component";
import { customNumberFormat, roundNominal } from "../../functions/general";
import { MasterDataTableNoUrl } from "../DataTable/MasterDataTableNoUrl";
import { KTSVG } from "../../_metronic/helpers";
import { DetailPieChartDashboardWidget } from "../../interfaces/Dashboard";
import { useIntl } from "react-intl";

interface WidgetPieChartModalProps {
    tableData: any
    type: string
    title: string
}

const WidgetPieChartModal: FC<WidgetPieChartModalProps> = ({ tableData, title, type }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl()

    const tableColumns: TableColumn<DetailPieChartDashboardWidget>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PRODUCT" }),
            selector: row => row.name,
            cell: (row) => {
                return row.name;
            },
            sortable: true,
        },
        {
            name: type === 'nominal' ? intl.formatMessage({ id: "FORM.LABEL.NOMINAL" }) : intl.formatMessage({ id: "FORM.LABEL.QUANTITY" }),
            right: true,
            selector: row => row.value,
            cell: (row) => {
                return customNumberFormat(roundNominal(row.value ?? 0) as number);
            },
            sortable: true,
        },
    ];

    return (
        <div className="modal fade" tabIndex={-1} id="pie-chart-modal" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <MasterDataTableNoUrl
                        tableKey={"pending-leads"}
                        tableColumns={tableColumns}
                        tableData={tableData}
                    />
                </div>
            </div>
        </div>
    )
}

export { WidgetPieChartModal }