import React, { useRef, useEffect, useState } from 'react';
// import Map from '@/components/Map';
import { Map, Marker, Overlay } from "pigeon-maps"
import { Meeting } from '../../interfaces/Meeting';
import { KTSVG } from '../../_metronic/helpers';
import { useIntl } from 'react-intl';

interface MapModalProps {
    latitude: string
    longitude: string
    meeting: Meeting
}

const MapMeeting: React.FC<MapModalProps> = ({ latitude, longitude, meeting }) => {
    const intl = useIntl()
    const dialogRef = useRef<HTMLDialogElement | null>(null);

    const color = `hsl(${0 % 360}deg 39% 70%)`

    const [showTooltip, setShowTooltip] = useState(false);

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`map-meeting-modal-${meeting.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ lineBreak: 'anywhere' }}>{intl.formatMessage({ id: "FORM.LABEL.MAP" }, { title: meeting.merchant_name })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <Map height={300} defaultCenter={[parseFloat(latitude), parseFloat(longitude)]} defaultZoom={18} attribution={false}>
                                {
                                    <Marker
                                        width={50}
                                        height={50}
                                        anchor={[parseFloat(latitude), parseFloat(longitude)]}
                                        color={color}
                                    />
                                }
                            </Map>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { MapMeeting }