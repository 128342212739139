import { get } from 'http';
import React, { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useAccessToken from '../../../hooks/AccessToken';
import { getPaidSchedule } from '../../../api/PipelineCRUD';
import Swal from 'sweetalert2';
import { createColumnHelper } from "@tanstack/react-table";
import { DetailPaidScheduleTermin, PaidScheduleTermin } from '../../../interfaces/Termin/Termin';
import { customNumberFormat } from '../../../functions/general';
import moment from 'moment-timezone';
import { MasterDataTableTanstack } from '../../DataTable/MasterDataTableTanstack';
import { useDataTableStore } from '../../../stores/DataTableStore';
import { DataTableExport } from '../../DataTable/DataTableExport';
import useUser from '../../../hooks/User';
import { Pipeline } from '../../../interfaces';

interface Props {
    pipeline: Pipeline;
}

const PreviewPaidSchedule: React.FC<Props> = ({ pipeline }) => {
    const token = useAccessToken()
    const intl = useIntl()
    const user = useUser()
    const [dataPaidScheduleTermin, setDataPaidScheduleTermin] = useState<PaidScheduleTermin>({} as PaidScheduleTermin);

    const columnHelper = createColumnHelper<DetailPaidScheduleTermin>()
    const columns = [
        columnHelper.accessor("no", {
            cell: (info) => info.getValue(),
            header: "No",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                },
                styleBody: {
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor("tanggalJatuhTempo", {
            cell: (info) => info.getValue(),
            header: "Tanggal Jatuh Tempo",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor("keterangan", {
            cell: (info) => info.getValue(),
            header: "Keterangan",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.group({
            header: "Pembayaran",
            columns: [
                columnHelper.accessor("dpp", {
                    header: "DPP",
                    cell: (info) => 'Rp. ' + customNumberFormat(info.getValue()),
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("ppn", {
                    header: "PPN",
                    cell: (info) => 'Rp. ' + customNumberFormat(info.getValue()),
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor("total", {
                    header: "Jumlah",
                    cell: (info) => 'Rp. ' + customNumberFormat(info.getValue()),
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
            ],
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor("tanggalPembayaran", {
            cell: (info) => info.getValue(),
            header: "Tanggal Pembayaran",
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
    ];

    let actions: ReactNode[] = [
        <DataTableExport
            source={'Paid Schedule Termin'}
            columns={columns}
            user={user!}
            type={'pdf'}
            nameFile={`Jadwal Bayar ${pipeline.merchant_name}`}
            customParams={{ dataPaidScheduleTermin: dataPaidScheduleTermin }}
        />
    ]

    return (
        <>
            <div style={{ padding: "0 2.25rem" }}>
                <div className="row pb-2">
                    <div className="col-3">No. Unit</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.merchantCustomFieldNoUnit ? dataPaidScheduleTermin.merchantCustomFieldNoUnit : "-"}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Nama</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.merchantName ? dataPaidScheduleTermin.merchantName : "-"}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Nama Toko</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.merchantCustomFieldNamaToko ? dataPaidScheduleTermin.merchantCustomFieldNamaToko : "-"}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Luas</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.leadCustomFieldLuas ? dataPaidScheduleTermin.leadCustomFieldLuas : "-"}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Jangka Waktu Sewa</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.leadCustomFieldJangkaWaktuSewa ? dataPaidScheduleTermin.leadCustomFieldJangkaWaktuSewa : "-"}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Periode Sewa</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{moment(dataPaidScheduleTermin.leadCustomFieldPeriodeMulaiSewa).format('DD MMMM YYYY')} - {moment(dataPaidScheduleTermin.leadCustomFieldPeriodeAkhirSewa).format('DD MMMM YYYY')}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Harga Sewa & SC</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>Rp. {dataPaidScheduleTermin.totalNominalPipeline ? customNumberFormat(Math.ceil(dataPaidScheduleTermin.totalNominalPipeline)) : 0}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Jaminan Sewa</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>Rp. {dataPaidScheduleTermin.leadCustomFieldNilaiJaminan ? customNumberFormat(Math.ceil(Number(dataPaidScheduleTermin.leadCustomFieldNilaiJaminan))) : 0}</div>
                </div>
                <div className="row pb-2">
                    <div className="col-3">Termin</div>
                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{dataPaidScheduleTermin.totalTermin ? customNumberFormat(dataPaidScheduleTermin.totalTermin) : 0}</div>
                </div>
            </div>

            <div style={{ paddingTop: "2.25rem" }}>
                <MasterDataTableTanstack
                    tableKey="paid-schedule-termin"
                    apiURL={`payment-termins/data-dokumen/maspion?leadId=${pipeline.id}`}
                    tableColumns={columns}
                    autoReload={false}
                    search={false}
                    actions={actions}
                    customParams={{ setDataPaidScheduleTermin: setDataPaidScheduleTermin }}
                />
            </div>
        </>
    );
};

export default PreviewPaidSchedule;