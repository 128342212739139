import { FC, useCallback, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { payOffReimbursement } from "../../api/ReimbursementCRUD";
import { Reimburse } from "../../interfaces/Reimbursement";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { insertPaidOffReimburseAttachment } from "../../api/ResourceAPI";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import InputAttachment from "../Input/InputAttachment";

interface Props {
    reimburse: Reimburse
}

const PayOffReimbursement: FC<Props> = ({ reimburse }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<any>([]);
    const intl = useIntl()

    function deleteFile(indexDelete: number) {
        const filteredFile = file.filter((file: File, index: number) => index !== indexDelete);
        setFile(filteredFile);
        formik.handleChange({
            target: {
                name: 'attachment',
                value: filteredFile
            }
        })
    }

    function handleFileChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
        if (e.target.files && e.target.files.length > 0) {
            const files: File[] = Array.from(e.target.files);
            setFile([...file, ...files])
            formik.handleChange({
                target: {
                    name: key,
                    value: [...file, ...files]
                }
            })
        } else {
            setFile([]);
            formik.handleChange({
                target: {
                    name: key,
                    value: []
                }
            })
        }
    }

    const formik = useFormik({
        initialValues: {
            attachment: []
        },
        validationSchema: Yup.object().shape({
            attachment: Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT" }) })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT" }) })).nullable(),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)
            try {
                let attachments: string[] = []
                if (values.attachment.length > 0) {
                    for (let file of values.attachment) {
                        const resourceResponse = await insertPaidOffReimburseAttachment(file)
                        attachments.push(resourceResponse.data.data[0])
                    }
                }

                const response = await payOffReimbursement(reimburse.id, attachments, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.PAID_SUCCESS" })}.`,
                        heightAuto: false,
                    })
                    closeModalRef.current?.click();

                    setTablesData('reimbursements', (tablesData['reimbursements'].map(item => {
                        if (item.id === reimburse.id) {
                            return {
                                ...item,
                                approvalStatus: response.data.data.approvalStatus,
                                datePaidOff: response.data.data.datePaidOff,
                                paidOffAttachmentUrls: response.data.data.paidOffAttachmentUrls,
                                reference: response.data.data.reference
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    const handlePaste = useCallback(async (event: ClipboardEvent) => {
        event.preventDefault(); // Prevent paste default behavior

        const items = event.clipboardData?.items;
        if (!items) return;

        const itemsArray = Array.from(items);
        const fileItems = itemsArray.filter(item => item.kind === "file");

        if (fileItems.length > 0) {
            const filePromises = fileItems.map((file) =>
                new Promise<File | null>((resolve) => {
                    const blob = file.getAsFile();
                    if (!blob) return resolve(null);

                    if (file.type.startsWith("image/")) {
                        if (blob.size > 5 * 1024 * 1024) {
                            Swal.fire({
                                icon: "error",
                                title: "Image size exceeds 5MB limit",
                                text: "Please use an image smaller than 5MB.",
                                confirmButtonText: "Ok",
                            });
                            return resolve(null);
                        }
                    }
                    resolve(blob);
                })
            );

            const results = await Promise.all(filePromises);
            const validFiles = results.filter((file): file is File => file !== null);

            if (validFiles.length > 0) {
                const currentAttachments = formik.values.attachment || [];
                formik.setFieldValue("attachment", [...currentAttachments, ...validFiles]);
            }
        }
    }, [formik]);

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [handlePaste]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`payoff-reimbursement-modal-${reimburse.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPLOAD_PROOF_OF_PAYMENT' })} {reimburse.name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* Proof */}
                                {/* <span className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT" })}</span><br />
                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                <div className="d-flex justify-content-between mb-3">
                                    <input
                                        type="file"
                                        accept=".png, .jpg, .jpeg"
                                        style={{ zIndex: 0 }}
                                        ref={fileInputRef}
                                        onChange={(e) => handleFileChange(e, 'attachment')}
                                        onBlur={() => formik.setFieldTouched("attachment", true)}
                                        className={clsx(
                                            '',
                                            // { 'is-invalid': formik.touched.attachment && formik.errors.attachment },
                                        )}
                                        id="files-upload-pay"
                                        multiple
                                    />
                                    <label htmlFor="files-upload-pay" className="btn btn-light text-dark">{intl.formatMessage({ id: 'FORM.ACTION.CHOOSE_IMAGE' })}</label>

                                    <button type="button" className={`btn btn-light-danger ${formik.values.attachment && formik.values.attachment.length > 0 ? '' : 'd-none'}`} onClick={() => { formik.setFieldValue("attachment", []); fileInputRef.current!.value = ''; setFile([]); }}>
                                        {intl.formatMessage({ id: 'FORM.ACTION.REMOVE_ALL' })}
                                    </button>
                                </div>
                                {formik.touched.attachment && formik.errors.attachment && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                    </div>
                                )}
                                {formik.values.attachment && formik.values.attachment.length > 0 && (
                                    <div className="d-flex flex-wrap gap-5">
                                        {formik.values.attachment.map((file: File, index: number) => {
                                            return (
                                                <div className="position-relative">
                                                    <img src={URL.createObjectURL(file)} className="rounded border border-gray-400" alt={file.name} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
                                                    <div className="position-absolute translate-middle badge badge-circle badge-dark opacity-75 cursor-pointer" style={{ top: '15%', left: '85%' }} onClick={() => deleteFile(index)}><i className="fas fa-times text-white"></i></div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )} */}
                                <span className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: "FORM.LABEL.PROOF_OF_PAYMENT" })}</span><br />
                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                <InputAttachment keyString={'attachment'} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                                {
                                    formik.touched.attachment && formik.errors.attachment &&
                                    (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { PayOffReimbursement }