type Props = {
    className: string
    color: string
    icon: string
    title: string
    text1: string
    text2: string
}

const DataTableCard: React.FC<Props> = ({ className, color, icon, title, text1, text2 }) => {
    return (
        <div className={`card bg-${color} ${className}`}>
            {/* begin::Body */}
            <div className='card-body my-3'>
                <div className="d-flex justify-content-between">
                    <span className={`card-title fw-bolder text-light-${color} fs-5 mb-3 d-block`}>
                        {title}
                    </span>
                    <i className={`${icon} fs-1 text-white`}></i>
                </div>
                <div>
                    <span className="text-white">{text1}</span>
                    <br />
                    <span className="text-white">{text2}</span>
                </div>
            </div>
            {/* end:: Body */}
        </div>
    )
}

export { DataTableCard }