import Swal from "sweetalert2";

import { useState } from "react";
import { CustomField, SettingMultiPipelines } from "../../../interfaces/Settings";
import * as ExcelJS from 'exceljs';
import { useIntl } from "react-intl";
import { insertLeadViaExcel } from "../../../api/PipelineCRUD";
import useAccessToken from "../../../hooks/AccessToken";
import moment from "moment-timezone";
import { useCustomeStore } from "../../../stores/CustomeStore";
import useUser from "../../../hooks/User";
import { insertProduct, updateProduct } from "../../../api/ProductsCRUD";
import { randomColor } from "../../../functions/general";

interface useHandleImportProductProps {
    setListData: any
    dataLabels: any
    dataUnits: any
    dataSuccess: any
}


const useHandleImportProduct = ({ setListData, dataLabels, dataUnits, dataSuccess }: useHandleImportProductProps) => {
    const intl = useIntl()
    const token = useAccessToken()

    const handleChangeProduct = (e: any) => {
        setListData([]);
        var check_excel = true;
        var empty_excel = false;
        var success = false;
        const file = e;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        const readUnitsAndLabels = (workbook: any, arrayData: any) => {
            workbook.getWorksheet("Label Produk (Opsional)").eachRow((row: any, rowIndex: number) => {
                if (rowIndex > 2) {
                    const rowData = row.values
                    // kalau sku berupa formula
                    if (typeof rowData[1] === 'object' && rowData[1].hasOwnProperty('formula')) rowData[1] = rowData[1].result

                    if (Array.isArray(rowData)) {
                        var label: any = dataLabels.find((data: any) => data.name === rowData[2]);

                        arrayData.forEach(function (item: any) {
                            if (item.sku === rowData[1] && rowData[1] && label)
                                item.productLabels = [...item.productLabels, {
                                    labelId: label.id,
                                    labelName: label.name
                                }];
                        }
                        )
                    }
                }
            });
            workbook.getWorksheet("Satuan Produk (Opsional)").eachRow((row: any, rowIndex: number) => {
                if (rowIndex > 2) {
                    const rowData = row.values
                    // kalau sku berupa formula
                    if (typeof rowData[1] === 'object' && rowData[1].hasOwnProperty('formula')) rowData[1] = rowData[1].result

                    if (Array.isArray(rowData)) {
                        var unit: any = dataUnits.find((data: any) => data.name === rowData[2]);

                        arrayData.forEach(function (item: any) {
                            if (item.sku === rowData[1] && rowData[1] && unit && rowData[3])
                                item.productUnits = [...item.productUnits, {
                                    unitId: unit.id,
                                    unitName: unit.name,
                                    convertionToPieces: rowData[3]
                                }]
                        }
                        )
                    }
                }
            });
        }

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer: any = reader.result;
            wb.xlsx.load(buffer).then((workbook: any) => {
                const newData: any = [];
                var nameWorksheet = workbook.getWorksheet("Sheet1")
                var nameWorksheetInfo = workbook.getWorksheet("Info")
                if (nameWorksheetInfo !== undefined) {
                    nameWorksheetInfo.eachRow(async (rowInfo: any, rowIndexInfo: number) => {
                        if (rowIndexInfo > 3) {
                            if (rowInfo.values[2] === `Template Insert Product` || rowInfo.values[2] === `Template Error Insert Product`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            newData.push({
                                                name: rowData[1],
                                                price: Number(rowData[2]),
                                                vat: Number(rowData[3]),
                                                sku: rowData[4],
                                                note: rowData[5],
                                                productLabels: [],
                                                productUnits: []
                                            })

                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                                readUnitsAndLabels(workbook, newData)

                            } else if (rowInfo.values[2] === `Template Update Product` || rowInfo.values[2] === `Template Error Update Product`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            var id = atob(rowData[1])

                                            newData.push({
                                                id: id.slice(0, 3) + id.slice(6),
                                                name: rowData[2],
                                                price: Number(rowData[3]) || 0,
                                                vat: Number(rowData[4]) || 0,
                                                sku: rowData[5],
                                                note: rowData[6],
                                                color: rowData[7],
                                                productLabels: [],
                                                productUnits: []
                                            })

                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                                readUnitsAndLabels(workbook, newData)

                            } else {
                                check_excel = false
                                return false
                            }
                        }
                    })
                } else {
                    check_excel = false
                }

                if (empty_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
                        confirmButtonText: 'Ok'
                    });
                }

                if (!check_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
                        confirmButtonText: 'Ok'
                    });
                }

                if (success) setListData(newData);
            });
        };
    };

    const handleInsertProduct = async (data: any) => {
        const response = await insertProduct(data.name, randomColor(), token, data.productLabels, data.productUnits, data.sku, data.note, data.price, data.vat)

        if (response.data.success) {
            dataSuccess.push(response.data.data)
        } else {
            // var mergeError = { ...data, error: response.data.message }
            // dataError.push(mergeError)
        }
    }

    const handleUpdateProduct = async (data: any) => {
        const response = await updateProduct(data.id, data.name, data.color, token, data.productLabels, data.productUnits, data.sku, data.note, data.price, data.vat)

        if (response.data.success) {
            dataSuccess.push(response.data.data)
        } else {
            // var mergeError = { ...data, error: response.data.message }
            // dataError.push(mergeError)
        }
    }

    return { handleChangeProduct, handleInsertProduct, handleUpdateProduct }
}

export default useHandleImportProduct