import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import { Target } from "../../interfaces/Target";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import { SelectOption } from "../../interfaces/SelectOption";
import moment from "moment";
import { insertTarget } from "../../api/TargetCRUD";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { User } from "../../interfaces/User";
import { UserModel } from "../../app/modules/auth/models/UserModel";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "../../setup";
import { DatePicker } from "rsuite";
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

const customNumberFormat = (valueString: string) => {
    const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
    const parts = onlyNumbers.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}
interface Props {
    target: Target
}

const UpdateTarget: FC<Props> = ({ target }) => {
    const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const { settings } = useSettingsStore()

    const intl = useIntl()

    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const typeTargetData = [{
        label: intl.formatMessage({ id: "TARGET.TYPE.PAID_PIPELINE_NOMINAL" }, { title: settings.pipeline_title }),
        value: "PIPELINE SUCCESS NOMINAL"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.PRODUCTS_SOLD_QUANTITY" }),
        value: "PRODUCT SOLD QTY"
    }, {
        label: intl.formatMessage({ id: "TARGET.TYPE.MEETINGS_COUNT" }),
        value: "MEETING COUNT"
    }]

    let url = ""
    if (user.data.is_superadmin) url = `${API_URL}users`
    else url = `${API_URL}user/subordinates`
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(url, fetcher)

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));
            setUsersData(usersData)
        }
    }, [users])

    const formik = useFormik({
        initialValues: {
            user: target.user_id ?? target.userId,
            date: moment(target.date_start),
            nominal: target.value,
            type: target.target_type
        },
        validationSchema: Yup.object().shape({
            nominal: Yup.string()
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TARGET_VALUE" }) }))  // Moved this up so it runs first
                .test('is-numeric', 'Invalid format', value => {
                    return value ? !isNaN(Number(value.replace(/\./g, ''))) : true;  // If value is present, then check format
                }),
            user: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })),
            date: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PERIOD" }, { title: "" }) })).nullable(),
            type: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TARGET_TYPE" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true);
            setModalBtnLoading(true)
            try {
                const response = await insertTarget(values.user, (values.nominal).split('.').join(''), moment(values.date).format("YYYY-MM") + "-01", values.type, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SUCCESS_UPDATE_TARGET" }, { title: values.nominal }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    mutate(`${API_URL}user-targets`);
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false);
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);
    
    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('nominal', formattedValue);
    };
    
    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id={`update-target-modal-${target.user_id ?? target.userId}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {intl.formatMessage({ id: 'FORM.MENU.TARGETS' })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.USER' })}</label>
                            <div className="input-group mb-4">
                                {/* <Select
                                    key={nanoid()}
                                    options={usersData}
                                    placeholder="Pilih User"
                                    value={usersData.find(option => option.value === formik.values.user)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "user",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.user === "" && formik.setFieldTouched("user", true)
                                    }
                                    className="w-100"
                                    isDisabled={true}
                                /> */}
                                {usersData.find(option => option.value === formik.values.user)?.label}
                            </div>
                            {/* {formik.touched.user && formik.errors.user && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.user}</span>
                                </div>
                            )} */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: "" })}</label>
                            <div className="input-group mb-4">
                                {/* <DatePicker
                                    editable={false}
                                    oneTap
                                    readOnly
                                    value={formik.values.date}
                                    format="MM-yyyy"
                                    style={{ width: "100%", zIndex: 0 }}
                                    container={document.querySelector('#update-target-modal-' + target.user_id) as HTMLElement}
                                    onChange={(value: any) => {
                                        if(value) formik.setFieldValue('date', value)
                                        else formik.setFieldValue('date', null)
                                    }}
                                /> */}
                                {moment(formik.values.date).locale('id').format('MMMM YYYY')}
                            </div>
                            {/* {formik.touched.date && formik.errors.date && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.date}</span>
                                </div>
                            )} */}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.TARGET_TYPE' })}</label>
                            <div className="input-group mb-4">
                                {/* <Select
                                    key={nanoid()}
                                    options={typeTargetData}
                                    placeholder="Pilih Tipe target"
                                    value={typeTargetData.find(option => option.value === formik.values.type)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "type",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.type === "" && formik.setFieldTouched("type", true)
                                    }
                                    className="w-100"
                                    isDisabled={true}
                                /> */}
                                {typeTargetData.find(option => option.value === formik.values.type)?.label}
                            </div>
                            {/* {formik.touched.type && formik.errors.type && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.type}</span>
                                </div>
                            )} */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.TARGET_VALUE' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.TARGET_VALUE' })}
                                    value={formik.values.nominal}
                                    onChange={handleNominalChange}
                                    onBlur={formik.handleBlur}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.nominal && formik.errors.nominal },
                                    )}
                                    type='text'
                                    name='nominal'
                                    autoComplete='off'
                                    style={{ zIndex: 0 }}
                                />
                            </div>
                            {formik.touched.nominal && formik.errors.nominal && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.nominal}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                        <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateTarget }