import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const SCHEDULE = `${API_URL}schedules/`
const CALENDAR = `${API_URL}calendar-list/`
const EVENT = `${API_URL}calendars`

type GetCalendar = {
    token: string,
}

type GetEvent = {
    token: string,
    id: string
}

type InsertSchedule = {
    title: string
    description?: string,
    start_date: string
    end_date: string
    reminder_date?: string,
    recurrence_rules?: string,
    location?: string,
    token: string,
}

type UpdateSchedule = {
    id: string,
    title: string
    description?: string,
    start_date: string
    end_date: string
    reminder_date?: string,
    recurrence_rules?: string,
    location?: string,
    token: string,
}

export const getCalendar = async ({token} : GetCalendar) => {
    return axios.get(CALENDAR, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const getEvent = async ({token, id} : GetEvent) => {
    return axios.get(EVENT+"/"+id+"/events", {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const insertSchedule = async ({
    title,
    description,
    start_date,
    end_date,
    reminder_date,
    recurrence_rules,
    location,
    token,
} : InsertSchedule) => {
    return axios.post(SCHEDULE, {
        title,
        description,
        start_date,
        end_date,
        reminder_date,
        recurrence_rules,
        location
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateSchedule = async ({
    id,
    title,
    description,
    start_date,
    end_date,
    reminder_date,
    recurrence_rules,
    location,
    token,
} : UpdateSchedule) => {
    return axios.patch(SCHEDULE + id, {
        title,
        description,
        start_date,
        end_date,
        reminder_date,
        recurrence_rules,
        location
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteSchedule = async (id: string, token: string) => {
    return axios.delete(SCHEDULE + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}