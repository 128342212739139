import React, { useState, useEffect } from 'react'
import ComponentModal from './ComponentModal'
import { RecentChatHistory } from '../../../../interfaces/Chat/RecentChatHistory'
import { getInitialsOrPhonePrefix } from '../../../../functions/chat'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { useChatStore } from '../../../../stores/ChatStore'
import Swal from 'sweetalert2'
import { postForwardChat } from '../../../../api/ChatCRUD'
import useAccessToken from '../../../../hooks/AccessToken'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface ModalForwardChatProps {
    isOpen: boolean
    onClose: () => void
    userChats: RecentChatHistory[]
    user: UserModel
}



const ModalForwardChat = ({ isOpen, onClose, userChats, user }: ModalForwardChatProps) => {
    const token = useAccessToken()
    const { selectedChatsForward, selectedChatPhone, setSelectedChatsForward, tokenChat, forwardChatPayload, setForwardChatPayload, setIsOpenCheckBoxForward, phoneNumber, setMessages } = useChatStore()
    const [searchQuery, setSearchQuery] = useState('')
    const [isForwarding, setIsForwarding] = useState(false)

    const handleForwardChat = async (targets: string[], rawMessages: string[]) => {
        try {
            const response = await postForwardChat(targets, rawMessages, phoneNumber.split('-')[1], token, tokenChat);
            setIsForwarding(false)
            if (response.data.success) {
                setSelectedChatsForward([])
                setForwardChatPayload({
                    targets: [],
                    rawMessages: []
                })
                // onClose()
                // setIsOpenCheckBoxForward(false)
                setSearchQuery('')
                Swal.fire({
                    icon: 'success',
                    title: 'Messages Forwarded',
                    text: `Successfully to forward messages`,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                })
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    }

    const handleSelectChat = (chat: RecentChatHistory) => {
        if (selectedChatsForward.includes(chat.chat_phone)) {
            setSelectedChatsForward(selectedChatsForward.filter(phone => phone !== chat.chat_phone))
        } else {
            setSelectedChatsForward([...selectedChatsForward, chat.chat_phone])
            setForwardChatPayload({
                ...forwardChatPayload,
                targets: [...(forwardChatPayload.targets || []), chat.chat_phone],
            })
        }
    }

    const handleForwardMessages = () => {
        if (selectedChatsForward.length === 0) return
        setIsForwarding(true)

        const newForwardChatPayload = forwardChatPayload.rawMessages?.sort((a, b) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime())
        const newForwardChat = newForwardChatPayload?.map(msg => msg.chat_j_message)
        handleForwardChat(selectedChatsForward, newForwardChat)
        onClose()
        setIsOpenCheckBoxForward(false)
    }


    const filteredChats = userChats.filter(chat =>
        chat.as === phoneNumber.split('-')[1] &&
        (chat.chat_name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            chat.chat_phone.includes(searchQuery))
    )

    if (!isOpen) return null

    return (
        <ComponentModal>
            <div className='modal-header border-bottom'>
                <h5 className='modal-title fw-bold d-flex gap-2'>
                    {
                        selectedChatPhone.length > 1 &&
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id='tooltip-labels'>
                                    <p><b>{phoneNumber.split('-')[1]}</b></p>
                                    <span>{selectedChatPhone.find(item => item.phone === phoneNumber.split('-')[1])?.ownersName.join(', ')}</span>
                                </Tooltip>}
                        >
                            <div className="d-flex align-items-center">
                                <span className="bullet bullet-dot h-10px w-10px" style={{ backgroundColor: selectedChatPhone.find(item => item.phone === phoneNumber.split('-')[1])?.color }}></span>
                            </div>
                        </OverlayTrigger>
                    }

                    Forward Chat
                </h5>
            </div>

            {/* Search Input */}
            <div className='p-4 border-bottom'>
                <div className='position-relative'>
                    <i className='bi bi-search position-absolute top-50 translate-middle-y ms-3 text-gray-600'></i>
                    <input
                        type='text'
                        className='form-control form-control-solid ps-10'
                        placeholder='Search by name or phone number'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
            </div>

            <div className='modal-body' style={{ maxHeight: '350px', overflowY: 'auto' }}>
                {filteredChats.map((chat: RecentChatHistory) => (
                    <div
                        key={chat.chat_id}
                        className={`d-flex align-items-center gap-3 p-3 border-bottom cursor-pointer hover-bg-light ${selectedChatsForward.includes(chat.chat_phone) ? 'bg-light-primary' : ''}`}
                        onClick={() => handleSelectChat(chat)}
                    >
                        <div className="form-check form-check-custom form-check-solid" onClick={(e) => e.stopPropagation()}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedChatsForward.includes(chat.chat_phone)}
                                onChange={() => handleSelectChat(chat)}
                            />
                        </div>

                        <div className='symbol symbol-45px symbol-circle'>
                            <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                {chat.profile_picture_url ? (
                                    <img
                                        src={chat.profile_picture_url}
                                        className='symbol symbol-45px symbol-circle img-fluid'
                                        alt='profile'
                                    />
                                ) : (
                                    chat.chat_phone === user.data.chat_phone
                                        ? user.data.name.split(' ').map((word: string) => word[0]).join('')
                                        : getInitialsOrPhonePrefix(chat)
                                )}
                            </span>
                        </div>
                        <div className='d-flex flex-column flex-grow-1'>
                            <span className='fs-5 fw-bold text-dark'>{chat.chat_name}</span>
                            <span className='fs-7 text-gray-600'>{chat.chat_phone}</span>
                        </div>
                        {selectedChatsForward.includes(chat.chat_phone) && (
                            <i className='bi bi-check-circle-fill text-primary fs-4'></i>
                        )}
                    </div>
                ))}
            </div>

            <div className='modal-footer border-top'>
                <button
                    type='button'
                    className='btn btn-light'
                    onClick={() => {
                        onClose()
                        setSearchQuery('')
                    }}
                    disabled={isForwarding}
                >
                    Cancel
                </button>
                <button
                    type='button'
                    className='btn btn-primary'
                    disabled={selectedChatsForward.length === 0 || isForwarding}
                    onClick={handleForwardMessages}
                >
                    {/* {isForwarding ? (
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Forwarding...
                        </>
                    ) : (
                        <>
                            <i className='fa fa-paper-plane me-2'></i>
                            Forward ({selectedChatsForward.length})
                        </>
                    )} */}
                    <i className='fa fa-paper-plane me-2'></i>
                    Forward ({selectedChatsForward.length})
                </button>
            </div>
        </ComponentModal>
    )
}

export default ModalForwardChat