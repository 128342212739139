import React, { useEffect, useRef, useState } from 'react'
import SelectUser from './SelectUser'
import useSWR from 'swr'
import axios from 'axios'
import useUser from '../../../hooks/User'
import useAccessToken from '../../../hooks/AccessToken'
import { useCustomeStore } from '../../../stores/CustomeStore'
import { UserChat } from '../../../interfaces/Chat/UserChat'
import { KTSVG } from '../../../_metronic/helpers'
import useModalEffect from '../../../hooks/useModalEffect'
import { useCustomChatStore } from '../../../stores/CustomChatStore'
import useShowCompanyById from '../../../hooks/useShowCompanyById'

const API_URL = process.env.REACT_APP_API_URL

const ModalSelectUser = () => {
    const modalRef = useRef<HTMLDivElement>(null)
    const user = useUser()
    const token = useAccessToken()
    const { showModalSelectUser, setShowModalSelectUser } = useCustomChatStore()
    const { companyChatToken } = useShowCompanyById(user.data.company_id)

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-auth-token': token
            }
        })
        return response.data.data
    }

    useModalEffect({
        showModal: showModalSelectUser,
        setShowModal: setShowModalSelectUser,
        modalId: 'modal-select-user'
    })


    return (
        <div className='modal' tabIndex={-1} id='modal-select-user' ref={modalRef}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Select User</h5>
                        <div className='btn btn-icon btn-sm btn-active-light-primary ms-2' onClick={() => modalRef.current?.click()}>
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className='modal-body'>
                        {/* <SelectUser /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSelectUser