import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationPipelineSourceProps = ({intl, settings}:IVarDocumentationProps )=>{    
 

    // ===================== GET PRODUCTS =====================
    const getPipelineSourcesExample =  {
        success: true,
        message: "Berhasil mendapatkan daftar sumber Pipe.",
        data: [
          {
            id: "2e998ebd-c513-4996-9e71-be9da5a6c44a",
            name: "INBOUND",
            date_created: "2023-11-16 16:37:16",
            creator_id: "92001099-56f8-4dc9-888b-e8de64c47b85",
            creator_name: "Reynaldi Kindarto"
          },
          {
            id: "8a60d49f-7d26-41c7-952c-72ef3acb3df6",
            name: "OUTBOUND",
            date_created: "2023-12-27 07:15:17",
            creator_id: "4129f29e-5649-4a52-b8d2-52e3e0225f0a",
            creator_name: "LIT Shabrina Super Admin"
          },
          {
            id: "9a4fc2a6-a7a3-435a-9747-f23e77dd5ee6",
            name: "OUTER",
            date_created: "2024-02-05 15:19:10",
            creator_id: "4129f29e-5649-4a52-b8d2-52e3e0225f0a",
            creator_name: "LIT Shabrina Super Admin"
          },
          {
            id: "534fa800-1d00-4f43-acab-f7a4c6499d5f",
            name: "NEW SOURCE",
            date_created: "2024-07-09 10:26:28",
            creator_id: "4129f29e-5649-4a52-b8d2-52e3e0225f0a",
            creator_name: "LIT Shabrina Super Admin"
          }
        ]
    };
    

    const python_get_pipeline_sources = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/lead_sources"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)
    print(response.text)
    `

    const javascript_get_pipeline_sources = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/lead_sources";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error(error);
        });

    `

    const php_get_pipeline_sources = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/lead_sources";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init();

    curl_setopt($ch, CURLOPT_URL, $reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if (curl_errno($ch)) {
        echo 'Error: ' . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);

    ?>

    `

    const bash_get_pipeline_sources = `

    #!/bin/bash

    REQ_URL="https://crm-api.wooblazz.com/lead_sources"
    TOKEN="//${intl.formatMessage({ id: 'OPEN_API.DESC_TOKEN' })}"

    curl -X GET "$REQ_URL" \
    --header "Accept: */*" \
    --header "Content-Type: application/json" \
    --header "X-Auth-token: $TOKEN"

    `

    return   {  bash_get_pipeline_sources, javascript_get_pipeline_sources, getPipelineSourcesExample, php_get_pipeline_sources, python_get_pipeline_sources}
}