import { FC, useState } from "react"
import { useDataTableStore } from "../../stores/DataTableStore"
import { useSettingsStore } from "../../stores/SettingsStore"
import { UserModel } from "../../app/modules/auth/models/UserModel"
import { exportTemplateExcel } from "../../functions/exportTemplateExcel"
import { useIntl } from "react-intl"
import useNameColumnExcel from "../../hooks/NameColumnExcel"
import { usePipelineStore } from "../../stores/PipelineStore"
import { useParams } from "react-router-dom"
import { CustomField } from "../../interfaces/Settings"
import { useMerchantStore } from "../../stores/MerchantStore"
import { exportTemplateMerchantExcel } from "../../functions/ExportTemplateExcel/merchant"
import { exportTemplatePipelineExcel } from "../../functions/ExportTemplateExcel/pipeline"
import { exportTemplateProductExcel } from "../../functions/ExportTemplateExcel/product"

interface DataTableExportTemplateProps {
    source: 'template-merchant' | 'template-user' | 'template-pipeline' | 'template-unit' | 'template-product'
    typeAction: 'insert' | 'update'
    user?: UserModel
    type?: string
    param?: any
}

const DataTableExportTemplate: FC<DataTableExportTemplateProps> = ({ source, typeAction, user, param, type }) => {
    const { filteredTablesData } = useDataTableStore()
    const { settings } = useSettingsStore()
    const { multiPipelines, selectMultiPipeline } = usePipelineStore()
    const { multiMerchants, selectMultiMerchant} = useMerchantStore()
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()
    const nameHeader: any = useNameColumnExcel();
    const { templatePipelineId, templateMerchantId } = useParams()

    const handleButton = (typeBtn: string) => {
        setIsLoading(true)
        if (source === 'template-merchant') {
            var arrayCustomFieldMerchant: CustomField = templateMerchantId && multiMerchants.length > 0 ? multiMerchants.find(item => item.id === templateMerchantId)?.customFields! : {};
            let filteredCustomFieldMerchant = Object.fromEntries(
                Object.entries(arrayCustomFieldMerchant).filter(([key, value]) => {
                    return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant';
                })
            );

            if (typeAction === 'insert') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateMerchantExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: settings.merchant_title }), filteredCustomFieldMerchant, source, typeAction, settings, `Template Error Insert Merchant ${templateMerchantId}`, nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateMerchantExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: settings.merchant_title }), filteredCustomFieldMerchant, source, typeAction, settings, `Template Insert Merchant ${templateMerchantId}`, nameHeader.import, setIsLoading, user, param), 100)
            } else if (typeAction === 'update') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateMerchantExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: settings.merchant_title }), filteredCustomFieldMerchant, source, typeAction, settings, `Template Error Update Merchant ${templateMerchantId}`, nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateMerchantExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: settings.merchant_title }), filteredCustomFieldMerchant, source, typeAction, settings, `Template Update Merchant ${templateMerchantId}`, nameHeader.import, setIsLoading, user, { ...param, dataTables: filteredTablesData[`merchants`] }), 100)
            }
        } else if (source === 'template-user') {
            if (typeAction === 'insert') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.LABEL.USER' }) }), source, typeAction, settings, 'Template Error Insert User', nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.LABEL.USER' }) }), source, typeAction, settings, 'Template Insert User', nameHeader.import, setIsLoading, user, param), 100)
            } else if (typeAction === 'update') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.LABEL.USER' }) }), source, typeAction, settings, 'Template Error Update User', nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.LABEL.USER' }) }), source, typeAction, settings, 'Template Update User', nameHeader.import, setIsLoading, user, { ...param, dataTables: filteredTablesData['users'] }), 100)
            }
        } else if (source === 'template-pipeline') {
            var arrayCustomFieldPipeline: CustomField = templatePipelineId && multiPipelines.length > 0 ? multiPipelines.find(item => item.id === templatePipelineId)?.customFields! : {};
            let filteredCustomFieldPipeline = Object.fromEntries(
                Object.entries(arrayCustomFieldPipeline).filter(([key, value]) => {
                    return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'merchant' && value.type !== 'pipeline' && value.type !== 'user';
                })
            );

            if (typeAction === 'insert') {
                if (typeBtn === 'error') setTimeout(() => exportTemplatePipelineExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: settings.pipeline_title+"-"+selectMultiPipeline.name }), filteredCustomFieldPipeline, source, typeAction, settings, `Template Error Insert Pipeline ${templatePipelineId}`, nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplatePipelineExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: settings.pipeline_title+"-"+selectMultiPipeline.name }), filteredCustomFieldPipeline, source, typeAction, settings, `Template Insert Pipeline ${templatePipelineId}`, nameHeader.import, setIsLoading, user, param), 100)
            } else if (typeAction === 'update') {
                if (typeBtn === 'error') setTimeout(() => exportTemplatePipelineExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: settings.pipeline_title }), filteredCustomFieldPipeline, source, typeAction, settings, `Template Error Update Pipeline ${templatePipelineId}`, nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplatePipelineExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: settings.pipeline_title }), filteredCustomFieldPipeline, source, typeAction, settings, `Template Update Pipeline ${templatePipelineId}`, nameHeader.import, setIsLoading, user, { ...param, dataTables: filteredTablesData['pipeline'] }), 100)
            }
        } else if (source === 'template-unit') {
            if (typeAction === 'insert') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.LABEL.UNITS' }) }), source, typeAction, settings, 'Template Error Insert Unit', nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.LABEL.UNITS' }) }), source, typeAction, settings, 'Template Insert Unit', nameHeader.import, setIsLoading, user, param), 100)
            } else if (typeAction === 'update') {
                if (typeBtn === 'error') setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.LABEL.UNITS' }) }), source, typeAction, settings, 'Template Error Update Unit', nameHeader.import, setIsLoading, user, param, type), 100)
                else setTimeout(() => exportTemplateExcel(intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.LABEL.UNITS' }) }), source, typeAction, settings, 'Template Update Unit', nameHeader.import, setIsLoading, user, { ...param, dataTables: filteredTablesData['units'] }), 100)
            }
        } else if (source === 'template-product') {
            if (typeAction === 'insert') {
                setTimeout(() => exportTemplateProductExcel({
                    nameFile: `${typeBtn === 'error' ? intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.MENU.PRODUCTS' }) }) : intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.ADD' }), title2: intl.formatMessage({ id: 'FORM.MENU.PRODUCTS' }) })}`,
                    nameInfo: `${typeBtn === 'error' ? 'Template Error Insert Product' : 'Template Insert Product'}`,
                    nameHeader: nameHeader.import,
                    source,
                    typeAction,
                    user: user!,
                    param,
                    type: type!,
                    setIsLoading
                }), 100)
            } else if (typeAction === 'update') {
                setTimeout(() => exportTemplateProductExcel({
                    nameFile: `${typeBtn === 'error' ? intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE_ERROR' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.MENU.PRODUCTS' }) }) : intl.formatMessage({ id: 'FORM.LABEL.TITLE_FILE' }, { title1: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }), title2: intl.formatMessage({ id: 'FORM.MENU.PRODUCTS' }) })}`,
                    nameInfo: `${typeBtn === 'error' ? 'Template Error Update Product' : 'Template Update Product'}`,
                    nameHeader: nameHeader.import,
                    source,
                    typeAction,
                    user: user!,
                    param: { ...param, dataTables: filteredTablesData['products'] },
                    type: type!,
                    setIsLoading
                }), 100)
            }
        }
    }

    if (type === 'error') {
        return (
            <button
                type="button"
                className={`btn btn-light-danger skeleton skeleton-button mb-3 w-100 ${isLoading ? 'disabled' : ""}`}
                data-kt-indicator={isLoading ? 'on' : 'off'}
                id="optionImport"
                onClick={() => handleButton('error')}
            >
                <i className="fas fa-file-download fs-2 me-2"></i>
                <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.DOWNLOAD_TEMPLATE_ERROR' })}
                </span>
                <span className="indicator-progress">
                    Loading... &nbsp;
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        )
    } else {
        return (
            <button
                type="button"
                className={`btn btn-success skeleton skeleton-button mb-3 w-100 ${isLoading ? 'disabled' : ""}`}
                data-kt-indicator={isLoading ? 'on' : 'off'}
                id="optionImport"
                onClick={() => handleButton('success')}
            >
                <i className="fas fa-file-download fs-2 me-2"></i>
                <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.DOWNLOAD_TEMPLATE' })}
                </span>
                <span className="indicator-progress">
                    Loading... &nbsp;
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        )
    }
}

export { DataTableExportTemplate }