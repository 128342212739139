import { FC, ReactNode, useEffect, useRef, useState } from "react";
import moment from "moment";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { UserActivity } from "../../../interfaces/UserActivity";
import { DateRangePicker } from "rsuite";
import useSWR, { mutate } from "swr";
import { DataTableSelect } from "../../../components/DataTable/DataTableSelect";
import { SelectOption } from "../../../interfaces/SelectOption";
import { useDataTableStore } from "../../../stores/DataTableStore";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import useUser from "../../../hooks/User";
import { useIntl } from "react-intl";
import { startOfMonth } from "date-fns";
import predefinedRanges from "../../../hooks/PredefinedRanges";
import usePredefinedRanges from "../../../hooks/PredefinedRanges";
import useCalendarLocale from "../../../hooks/CalendarLocale";
import { ActivityAttachments } from "../../../components/Activities/ActivityAttachment";
import { Modal } from "bootstrap";

const MasterActivities: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL

    // const { code } = useParams();
    const today = new Date()

    const [startDate, setStartDate] = useState<Date>(startOfMonth(today));
    const [endDate, setEndDate] = useState<Date>(today);
    const [loading, setLoading] = useState<boolean>(true);
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [activity, setActivity] = useState<UserActivity>();

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [url, setUrl] = useState('');
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const { tablesData, setSelectedFilter } = useDataTableStore();
    const token = useAccessToken()
    const user = useUser()

    const intl = useIntl()

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (showAttachmentModal && activity) {
            const modalElement = document.getElementById(`attachments-activity-modal-${activity.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, activity, setShowAttachmentModal]);

    const tableColumns: TableColumn<UserActivity>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TIME' }),
            selector: row => row.date_created?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                return row.date_created && moment(row.date_created).format('dddd, DD MMM YYYY HH:mm')
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            width: '20%',
            selector: row => row.user_name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.user_name
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTIVITY' }),
            width: '60%',
            selector: row => row.description?.toLowerCase() ?? "-",
            sortable: true,
            cell: (row) => {
                if (row.activity_type === 'INSERT' && row.table_type === 'PIPELINE LOG') {
                    return <div>
                        <span>{row.description}</span><br />
                        <p dangerouslySetInnerHTML={{ __html: row.new_data.Deskripsi }}></p>
                    </div>
                } else {
                    return <p>{row.description}</p>
                }
            }
        },
    ];

    const selectConfigs = [
        {
            placeholder: intl.formatMessage({ id: 'MASTERS.DATATABLE.ACTIONS.CHOOSE_USER' }),
            filterKey: 'user_name',
            tableKey: 'user-activities',
            options: usersData,
            onChange: (values: string[]) => setSelectedFilter('user-activities', 'user_name', values)
        },
    ];

    let actions = [
        ...selectConfigs.map((config, index) => (
            <DataTableSelect
                placeholder={config.placeholder}
                filterKey={config.filterKey}
                tableKey={config.tableKey}
                key={index}
                options={config.options}
                onChange={config.onChange}
            />
        ))
    ];

    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}user-activities`);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (!usersLoading) {
            const distinctThings = tablesData["user-activities"]?.filter(
                (thing, i, arr) => arr.findIndex(t => t.user_id === thing.user_id) === i
            )

            const usersData = distinctThings?.map((user: UserActivity) => ({
                value: user.user_name,
                label: user.user_name
            }));
            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [tablesData["user-activities"]]);

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <div className="text-gray-900 fs-2x fw-bold">
                    <ol className="breadcrumb text-muted fs-6 fw-bold">
                        <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.ACTIVITY' })}</li>
                    </ol>
                </div>
                <DateRangePicker
                    cleanable={false}
                    character=" - "
                    format="dd MMM yyyy"
                    locale={calendarLocale}
                    value={[startDate, endDate]}
                    ranges={predefinedRanges}
                    placement="bottomEnd"
                    shouldDisableDate={(date) => {
                        return date > today;
                    }}
                    onChange={(value) => {
                        if (value && value.length === 2) {
                            setStartDate(value[0]);
                            setEndDate(value[1]);
                        }
                    }}
                    showOneCalendar={isMobile}
                    disabled={loading}
                    isoWeek={true}
                />
            </div>

            <MasterDataTable
                tableKey="user-activities"
                tableColumns={tableColumns}
                apiURL="user-activities"
                dateStart={moment(startDate).format('YYYY-MM-DD')}
                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                // user_id={code}
                orderBy={1}
                order="desc"
                expanded
                autoReload={false}
                setLoading={setLoading}
                actions={!usersData ? [] : usersData.length > 1 ? actions : []}
                customParams={{ setUrl: setUrl, setActivity: setActivity, setShowAttachmentModal: setShowAttachmentModal }}
            />

            {
                showAttachmentModal && <ActivityAttachments activity={activity!} url={url!} />
            }
        </>
    )
}

export { MasterActivities }