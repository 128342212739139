import React, { ReactNode, useEffect, useState } from 'react'
import MasterTableEmail from '../table/MasterTableEmail'
import { useIntl } from 'react-intl'
import { TableColumn } from 'react-data-table-component'
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment-timezone'
import { Modal } from 'bootstrap'
import { useEmailStore } from '../../../../stores/EmailStore';
import { BroadcastEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType';
import Swal from 'sweetalert2';
import { DeleteBroadcastEmail } from '../deleteModal/DeleteBroadcastEmail';
import { useUserStore } from '../../../../hooks/UserEmail';

const TabDraftBroadcastEmail = () => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUpdateDetailGroup, setShowUpdateDetailGroup] = useState<boolean>(false);
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [selectedBroadcast, setSelectedBroadcast] = useState<BroadcastEmailTypes | null>(null);
    const intl = useIntl()
    const { setActiveSidebar, setTypeMenu, setTypeEmail, setToggleBroadcast, setIdDetailBroadcastEmail, setIsDetailActive, setDataEditValuesBroadcast, setTypeEditBroadcast } = useEmailStore()
    const handleClick = (menuId: string) => setOpenMenuId(menuId);
    const handleClose = () => setOpenMenuId(null);
    const { userEmail } = useUserStore()

    const showWarning = (message: string) => {
        Swal.fire({
            icon: 'warning',
            title: 'Peringatan',
            text: message,
        });
    };

    const navigateToBroadcastCore = (row: BroadcastEmailTypes) => {
        setTypeEmail('sendEmail');
        setTypeMenu('sendMail');
        setToggleBroadcast(true);
        setActiveSidebar({
            inbox: '',
            sent: '',
            draft: '',
            trash: '',
            archive: '',
            spam: '',
            groupEmail: '',
            broadcastEmail: ''
        });
        setDataEditValuesBroadcast(row);
        setTypeEditBroadcast('draftBroadcastEmail');
    };

    const handleUpdateClick = (row: BroadcastEmailTypes) => {
        handleClose();
        navigateToBroadcastCore(row);
    };

    const handleDeleteClick = (row: BroadcastEmailTypes) => {
        handleClose();
        setShowDeleteModal(true);
        setSelectedBroadcast(row);
    };

    const baseTableColumns: TableColumn<BroadcastEmailTypes>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            allowOverflow: true,
            width: '70px',
            cell: (row) => (
                <>
                    <IconButton
                        aria-label={`more-${row.id}`}
                        id={`menu-anchor-${row.id}`}
                        onClick={() => handleClick(row.id)}
                    >
                        <MoreVertIcon />
                    </IconButton>

                    <Menu
                        id={`menu-${row.id}`}
                        anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                        open={openMenuId === row.id}
                        onClose={handleClose}
                        disableScrollLock={true}
                    >
                        <MenuItem onClick={() => handleUpdateClick(row)}>
                            <i className="fas fa-edit fs-4 me-3"></i>
                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_BROADCAST' })}</span>
                        </MenuItem>

                        <MenuItem onClick={() => handleDeleteClick(row)}>
                            <i className="fas fa-trash fs-3 me-4"></i>
                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
        {
            name: 'Nama Broadcast',
            width: '250px',
            sortable: true,
            selector: row => row.broadcastName || '',
            cell: (row) => (
                <span
                    className='text-primary cursor-pointer'
                    onClick={() => {
                        setIdDetailBroadcastEmail(row.id);
                        setTypeEmail('detailDraftBroadcastEmail');
                        setIsDetailActive(true);
                    }}
                >
                    {row.broadcastName}
                </span>
            )
        },
        {
            name: 'Jadwal',
            width: '200px',
            sortable: true,
            selector: row => row.schedule || '',
            cell: row => moment(row.schedule).format("DD MMM YYYY HH:mm"),
        },
        {
            name: 'Tanggal dibuat',
            width: '150px',
            sortable: true,
            selector: row => row.createdAt || '',
            cell: row => moment(row.createdAt).format("DD MMM YYYY HH:mm"),
        },
    ];

    useEffect(() => {
        if (showDeleteModal && selectedBroadcast) {
            const modalElement = document.getElementById(`delete-broadcast-email-modal-${selectedBroadcast.id}`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                const handleModalHide = () => setShowDeleteModal(false);
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);
                return () => modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        }
    }, [showDeleteModal, selectedBroadcast]);

    useEffect(() => {
        if (showUpdateDetailGroup && selectedBroadcast) {
            const modalElement = document.getElementById(`update-broadcast-contact-modal-${selectedBroadcast.id}`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                const handleModalHide = () => setShowUpdateDetailGroup(false);
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);
                return () => modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            }
        }
    }, [showUpdateDetailGroup, selectedBroadcast]);

    const actionsDraftBroadcastEmail: ReactNode[] = [];


    return (
        <>
            <MasterTableEmail
                tableColumns={baseTableColumns}
                actions={actionsDraftBroadcastEmail}
                tableKey='draftBroadcastEmail'
                apiURL={`broadcast/get-broadcast-draft?userId=${userEmail?.id}`}
            />

            {showDeleteModal && <DeleteBroadcastEmail data={selectedBroadcast!} tableKey='draftBroadcastEmail' />}
        </>
    )
}

export default TabDraftBroadcastEmail