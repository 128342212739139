
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const COMPONENT = `${API_URL}menu-analytics/component`;


interface IComponentPost{
    sectionId: string
    name : string
    dataType : string 
    chartType : string 
    timeFrame: string
    startDate : string|Date
    endDate : string |Date
    dataCore: any
    token : string
}

interface IComponentUpdate extends IComponentPost{
  componentId : string
  position: number
}
export const inserComponent = async ({
    sectionId, name, dataType, chartType, timeFrame, startDate, endDate, dataCore, token
}:IComponentPost,
) => {
  return axios.post(
    COMPONENT,
    {
        sectionId,
        name,
        dataType,
        chartType,
        timeFrame,
        startDate, 
        endDate,
        dataCore,
        token
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateComponent = async ({
  sectionId, name, dataType, chartType, timeFrame, startDate, endDate, dataCore, token, componentId, position
}:IComponentUpdate,
) => {
return axios.put(
  COMPONENT,
  { 
      componentId,
      sectionId,
      name,
      dataType,
      chartType,
      timeFrame,
      startDate, 
      endDate,
      dataCore,
      token,
      position,
  },
  {
    headers: {
      'X-Auth-token': token,
    },
  }
);
};

export const deleteComponent = async (id: string, token: string) => {
  return axios.delete(
      COMPONENT+"/"+id, 
      {
    headers: {
      'X-Auth-token': token,
    },
  });
};
