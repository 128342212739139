import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import useAccessToken from "../../../hooks/AccessToken";
import Swal from "sweetalert2";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField, SettingMultiMerchants, SettingMultiPipelines } from "../../../interfaces/Settings";
import { saveSetting } from "../../../api/SettingCRUD";
import { SelectOption } from "../../../interfaces/SelectOption";
import { CustomFieldPipeline } from "../../../components/Setting/CustomFieldPipeline";
import { CustomFieldMerchant } from "../../../components/Setting/CustomFieldMerchant";
import { CustomFieldMeeting } from "../../../components/Setting/CustomFieldMeeting";
import { CustomFieldDashboard } from "../../../components/Setting/CustomFieldDashboard";
import { SettingAttendanceTemplate } from '../../pages/attendance-template/SettingAttendanceTemplate'
import { useCompanyStore } from "../../../stores/CompanyStore";
import { useIntl } from "react-intl";
import useUser from "../../../hooks/User";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { CustomFieldUser } from "../../../components/Setting/CustomFieldUser";
import { CustomFieldPaymentTermin } from "../../../components/Setting/CustomFieldPaymentTermin";
import axios from "axios";
import useSWR from "swr";
import { Role } from "../../../interfaces/Role";
import { Progress } from "../../../interfaces/Progress";
import { Product } from "../../../interfaces/Product";
import { Pipeline } from "../../../interfaces/Pipeline";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { useMerchantStore } from "../../../stores/MerchantStore";

const MasterSettings: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const { settings } = useSettingsStore()
    const token = useAccessToken()
    const { companies, setCompanies } = useCompanyStore()
    const intl = useIntl()
    const { multiPipelines } = usePipelineStore()
    const { multiMerchants } = useMerchantStore()
    const [tabActive, setTabActive] = useState<string>('dashboard')
    const [optionProgres, setOptionProgress] = useState<SelectOption[]>([])
    const [optionPipeline, setOptionPipeline] = useState<SelectOption[]>([])
    const [optionMerchant, setOptionMerchant] = useState<SelectOption[]>([])
    const [optionProduct, setOptionProduct] = useState<SelectOption[]>([])
    const [optionRole, setOptionRole] = useState<SelectOption[]>([])

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const optionType: SelectOption[] = [
        {
            value: 'text',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.TEXT" })
        },
        {
            value: 'date',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.DATE" })
        },
        {
            value: 'option',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.SINGLE" })
        },
        {
            value: 'multiple',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.MULTIPLE" })
        },
        {
            value: 'image',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.IMAGE" })
        },
        {
            value: 'file',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.FILE" })
        },
        {
            value: 'images',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.IMAGES" })
        },
        {
            value: 'files',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.FILES" })
        },
        {
            value: 'link',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.LINK" })
        },
        {
            value: 'phone',
            label: intl.formatMessage({ id: "FORM.OPTION.TYPE.PHONE" })
        },
    ]

    const optionTypePipeline: SelectOption[] = [
        ...optionType,
        { value: 'merchant', label: settings.merchant_title ? settings.merchant_title : "Merchant" },
        { value: 'user', label: intl.formatMessage({ id: "FORM.LABEL.USER" }) },
        { value: 'pipeline', label: settings.pipeline_title ? settings.pipeline_title : "Pipeline" },
    ]

    const optionTypeMerchant: SelectOption[] = [
        ...optionType,
        { value: 'merchant', label: settings.merchant_title ? settings.merchant_title : "Merchant" },
    ]

    const optionsResetSerial = [
        { value: 'no', label: intl.formatMessage({ id: "FORM.OPTION.TYPE.SERIAL.RESET.NO" }) },
        { value: 'day', label: intl.formatMessage({ id: "FORM.OPTION.TYPE.SERIAL.RESET.DAY" }) },
        { value: 'month', label: intl.formatMessage({ id: "FORM.OPTION.TYPE.SERIAL.RESET.MONTH" }) },
        { value: 'year', label: intl.formatMessage({ id: "FORM.OPTION.TYPE.SERIAL.RESET.YEAR" }) },
    ]

    const { data: progress = [], error: progressError, isLoading: progressLoading } = useSWR(`${API_URL}lead_progresses`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })
    const { data: products = [], error: productsError, isLoading: productsLoading } = useSWR(`${API_URL}products`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })
    const { data: roles = [], error: rolesError, isLoading: rolesLoading } = useSWR(`${API_URL}roles`, fetcher, { revalidateOnFocus: false, revalidateOnReconnect: false })

    useEffect(() => {
        if (!progressLoading) {
            const progresData = progress.map((progres: Progress) => ({
                value: progres.id,
                label: progres.name,
            }))

            const allProgress = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) }) }
            setOptionProgress([allProgress, ...progresData])
        }
    }, [progress])

    useEffect(() => {
        const pipelineData = multiPipelines.map((pipeline: SettingMultiPipelines) => ({
            value: pipeline.id,
            label: pipeline.name,
        }))
        const allPipeline = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: settings.pipeline_title }) }
        setOptionPipeline([allPipeline, ...pipelineData])
    }, [multiPipelines])

    useEffect(() => {
        const merchantData = multiMerchants.map((merchant: SettingMultiMerchants) => ({
            value: merchant.id,
            label: merchant.name,
        }))
        const allMercahnt = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: settings.merchant_title }) }
        setOptionMerchant([allMercahnt, ...merchantData])
    }, [multiMerchants])

    useEffect(() => {
        if (!productsLoading) {
            const productsData = products.map((product: Product) => ({
                value: product.id,
                label: product.name,
            }))

            const allProducts = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PRODUCTS" }) }) }
            setOptionProduct([allProducts, ...productsData])
        }
    }, [products])

    useEffect(() => {
        if (!rolesLoading) {
            const roleData = roles.map((role: Role) => ({
                value: role.id,
                label: role.name,
            }))

            const allRoles = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROLE" }) }) }
            setOptionRole([allRoles, ...roleData])
        }
    }, [roles])

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: "FORM.MENU.SETTING" })}</li>
                </ol>
            </div>

            <div className="flex-lg-row-fluid">
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                    <li className="nav-item">
                        <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#tab_dashboard" onClick={() => setTabActive('dashboard')}>
                            {intl.formatMessage({ id: "MENU.DASHBOARD" })}
                        </a>
                    </li>

                    {
                        settings.feature_pipeline &&
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_pipeline" onClick={() => setTabActive('pipeline')}>
                                {settings.pipeline_title ? settings.pipeline_title : 'Pipeline'}
                            </a>
                        </li>
                    }

                    {
                        settings.feature_meeting &&
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_meeting" onClick={() => setTabActive('meeting')}>
                            {settings.meeting_title ? settings.meeting_title : intl.formatMessage({ id: "MENU.MEETINGS" })}
                            </a>
                        </li>
                    }

                    {
                        settings.feature_merchant &&
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_merchant" onClick={() => setTabActive('merchant')}>
                                {settings.merchant_title ? settings.merchant_title : "Merchant"}
                            </a>
                        </li>
                    }

                    <li className="nav-item">
                        <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_user" onClick={() => setTabActive('user')}>
                            {intl.formatMessage({ id: "MENU.USERS" })}
                        </a>
                    </li>

                    {
                        settings.feature_attendance && Number(companies.companyFeatureLimitUserAttendance) > 0 &&
                        <li className="nav-item">
                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_attendance" onClick={() => setTabActive('attendance')}>
                                {intl.formatMessage({ id: "MENU.ATTENDANCE" })}
                            </a>
                        </li>
                    }

                    <li className="nav-item">
                        <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#tab_termin" onClick={() => setTabActive('termin')}>
                            {intl.formatMessage({ id: "FORM.LABEL.TERMIN" })}
                        </a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="tab_dashboard" role="tabpanel">
                        {
                            tabActive === 'dashboard' && <CustomFieldDashboard optionType={optionType} />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_pipeline" role="tabpanel">
                        {
                            tabActive === 'pipeline' && <CustomFieldPipeline optionType={optionTypePipeline} dataMerchant={optionMerchant} optionsResetSerial={optionsResetSerial} dataProgres={optionProgres} dataProduct={optionProduct} dataRole={optionRole} />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_meeting" role="tabpanel">
                        {
                            tabActive === 'meeting' && <CustomFieldMeeting dataPipeline={optionPipeline} dataMerchant={optionMerchant} optionType={optionType} optionsResetSerial={optionsResetSerial} />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_merchant" role="tabpanel">
                        {
                            tabActive === 'merchant' && <CustomFieldMerchant dataPipeline={optionPipeline} dataMerchant={optionMerchant} optionType={optionTypeMerchant} optionsResetSerial={optionsResetSerial} />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_user" role="tabpanel">
                        {
                            tabActive === 'user' && <CustomFieldUser optionType={optionType} optionsResetSerial={optionsResetSerial} />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_attendance" role="tabpanel">
                        {
                            tabActive === 'attendance' && <SettingAttendanceTemplate />
                        }
                    </div>
                    <div className="tab-pane fade" id="tab_termin" role="tabpanel">
                        {
                            tabActive === 'termin' && <CustomFieldPaymentTermin optionType={optionType} optionsResetSerial={optionsResetSerial} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export { MasterSettings }