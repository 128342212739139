import moment from 'moment-timezone'
import React, { ReactNode, useState } from 'react'
import { customNumberFormat } from '../../functions/general'
import useAccessToken from '../../hooks/AccessToken'
import { useIntl } from 'react-intl'
import useUser from '../../hooks/User'
import { createColumnHelper } from '@tanstack/react-table'
import DataTableAccountReceivable from './DataTableAccountReceivable'
import useSWR from 'swr'
import Swal from 'sweetalert2'
import axios from 'axios'
import { KTSVG } from '../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL

interface DetailAccountReceivableProps {
    receivableId: string
}

const DetailAccountReceivable = ({ receivableId }: DetailAccountReceivableProps) => {
    const token = useAccessToken()
    const intl = useIntl()
    const user = useUser()

    const fetcher = (token: string) => async (url: string) => {
        try {
            const response = await axios.get(url, {
                headers: {
                    'x-auth-token': token
                }
            })
            return response.data.data
        } catch (error: any) {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error'
            })
        }
    }

    const { data: dataDetailAccountReceivable, isLoading } = useSWR<any[]>(`${API_URL}receivable-reports/detail?leadId=${receivableId}`, fetcher(token))

    const columnHelper = createColumnHelper<any>()
    const columns = [
        columnHelper.display({
            id: 'no',
            header: 'No',
            cell: (info) => info.row.index + 1,
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                },
                styleBody: {
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor('tanggalJatuhTempo', {
            header: 'Tanggal Jatuh Tempo',
            cell: (info) => info.getValue() ? moment(info.getValue()).format('DD-MMM-YYYY') : '-',
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor('keterangan', {
            header: 'Keterangan',
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.group({
            header: 'Pembayaran',
            columns: [
                columnHelper.accessor('dpp', {
                    header: 'DPP',
                    cell: (info) => `Rp. ${Math.ceil(info.getValue()).toLocaleString('id-ID')}`,
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor('ppn', {
                    header: 'PPN',
                    cell: (info) => `Rp. ${Math.ceil(info.getValue()).toLocaleString('id-ID')}`,
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                }),
                columnHelper.accessor('total', {
                    header: 'Jumlah',
                    cell: (info) => `Rp. ${Math.ceil(info.getValue()).toLocaleString('id-ID')}`,
                    meta: {
                        styleHeader: {
                            alignContent: "center",
                            textAlign: "center"
                        },
                        styleBody: {
                            textAlign: "right"
                        }
                    },
                })
            ],
            meta: {
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
        columnHelper.accessor('tanggalPembayaran', {
            header: 'Tanggal Pembayaran',
            cell: (info) => info.getValue() ? moment(info.getValue()).format('DD-MMM-YYYY') : '-',
            meta: {
                rowSpan: 3,
                styleHeader: {
                    alignContent: "center",
                    textAlign: "center"
                }
            },
        }),
    ];

    const terminArray = Object.keys((dataDetailAccountReceivable as any)?.dataTermin || {}).map((key, index) => ({
        no: index + 1,
        ...(dataDetailAccountReceivable as any)?.dataTermin[key],
    }));

    const listKeyForHide = ['dataTermin', 'totalTermin']

    const filteredData = Object.entries(dataDetailAccountReceivable || {}).filter(
        ([key]) => !listKeyForHide.includes(key)
    );

    const handleChangeKeyName = (key: string) => {
        if (key === 'merchantName') {
            return 'Nama Merchant'
        } else if (key === 'totalNominalPipeline') {
            return 'Total Nominal Pipeline'
        }
        return key
    }

    const handleChangeValue = (key: string, value: any) => {
        if (key === 'totalNominalPipeline') {
            return <span style={{ fontWeight: "700", color: "black" }}>Rp. {Math.ceil(value).toLocaleString('id-ID')}</span>
        }
        return value || "-"
    }

    return (
        <div className='modal fade' tabIndex={-1} id={`detail-receivable-account-${receivableId}`}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    {isLoading && (
                        <div className="loading-overlay">
                            <div className="loader"></div>
                        </div>
                    )}
                    <div className="modal-header">
                        <h5 className="modal-title w-100">{intl.formatMessage({ id: "FORM.MENU.TERM_DETAILS" })} {intl.formatMessage({ id: 'MENU.ACCOUNT_RECEIVABLE' })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                        // onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div style={{ padding: "0 2.25rem" }}>
                            {filteredData.map(([key, value]) => (
                                <div className="row pb-2" key={key}>
                                    <div className="col-3">
                                        {handleChangeKeyName(key)}
                                    </div>
                                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>
                                        {handleChangeValue(key, value)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ paddingTop: "2.25rem" }}>
                            <DataTableAccountReceivable data={terminArray || []} tableColumns={columns} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailAccountReceivable