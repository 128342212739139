import axios from "axios"
import { ListProduct, PipelineAttachment } from "../interfaces/Pipeline"
import Swal from "sweetalert2"

const API_URL = process.env.REACT_APP_API_URL

const LEAD = `${API_URL}lead/`
const LEADV2 = `${API_URL}v2/lead/`
const LEADS_BULK = `${API_URL}leads/bulk`
const INSERT_USER_TEMPLATE = `${API_URL}user-view-templates`
const MODIFY_TEMPLATE = `${API_URL}view-templates`
const INSERT_COMPANY_TEMPLATE = `${API_URL}company-view-templates`
const GET_LIST_MERCHANT_LINK = `${API_URL}merchant-link/destination`
const SAVE_MERCHANT_LINK = `${API_URL}/merchant-link/save`
const GET_PAID_SCHEDULE = `${API_URL}/payment-termins/data-dokumen/maspion`

interface Product {
    id: string;
    unitId?: string | null;
    name: string;
    qty?: number;
    price?: number;
}

interface Lead {
    merchantId: string;
    dateStart: string;
    dateEnd?: string | null;
    note?: string | null;
    progressId?: string | null;
    subProgressId?: string | null;
    product: Product;
    sourceId: string;
    ownerId: string;
    customFields?: { [key: string]: string | string[] | null };
}

interface LeadRequest {
    leadTemplateId: string,
    token: string;
    leads: Lead[];
}

interface ILabelReq {
    id: string,
    name: string
}
type TLabelsReq = ILabelReq[]

export const insertLead = async (
    leadLabels: TLabelsReq = [], leadTemplateId: string, ownerId: string, merchantId: string, dateStart: string,
    leadSourceId: string, leadProducts: ListProduct[],
    token: string, note?: string, meetingId?: string, customFields?: { [key: string]: string | string[] | null }) => {
    return axios.post(LEADV2, {
        leadLabels,
        leadTemplateId,
        merchantId,
        dateStart,
        leadProducts,
        leadSourceId,
        ownerId,
        note,
        meetingId,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateLead = async (
    leadLabels: TLabelsReq = [],
    leadTemplateId: string, id: string, dateStart: string, ownerId: string, note: string, leadProducts: ListProduct[],
    token: string, dateInvoice?: string, dateInvoiceDue?: string, attachments?: PipelineAttachment[],
    customFields?: { [key: string]: string | string[] | null }) => {
    return axios.patch(LEADV2 + id, {
        leadLabels,
        leadTemplateId,
        dateStart,
        ownerId,
        leadProducts,
        note,
        dateInvoice,
        dateInvoiceDue,
        attachments,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deletePipeline = async (id: string, token: string) => {
    return axios.delete(LEAD + id, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const insertLeadViaExcel = async ({ leadTemplateId, token, leads }: LeadRequest) => {
    return axios.post(LEADS_BULK, { leads, leadTemplateId }, {
        headers: {
            'X-Auth-token': token
        }
    });
}

export const insertTemplatePipeline = async (type: string, leadTemplateId: string, name: string, content: string, token: string) => {
    return axios.post(type === 'user' ? INSERT_USER_TEMPLATE : INSERT_COMPANY_TEMPLATE,
        {
            leadTemplateId,
            name,
            content
        },
        {
            headers: {
                'X-Auth-token': token
            }
        });
}

export const updateTemplatePipeline = async (id: string, leadTemplateId: string, name: string, content: string, token: string) => {
    return axios.patch(MODIFY_TEMPLATE + '/' + id,
        {
            leadTemplateId,
            name,
            content
        },
        {
            headers: {
                'X-Auth-token': token
            }
        });
}

export const deleteTemplatePipeline = async (id: string, token: string) => {
    return axios.delete(MODIFY_TEMPLATE + '/' + id,
        {
            headers: {
                'X-Auth-token': token
            }
        });
}
// Begin Api Payment Termin

export interface ICreatePaymentTermin {
    leadId: string
    nominal: number
    dateDue: string
    vatPercentage?: number | null
    datePaid?: string | null
    progress?: 'PENDING' | 'PAID'
    receiptUrls?: string | string[] | File | File[]
    customFields?: { [key: string]: string }
}

export interface IFinishPaymentTermin {
    progress: string
    receiptUrls: File | File[] | null
    datePaid: string | null
}

export const createPaymentTermin = async (data: ICreatePaymentTermin, token: string) => {
    try {
        const response = await axios.post(`${API_URL}payment-termins`, data, {
            headers: {
                'x-auth-token': token
            }
        })
        return response.data
    } catch (error: any) {
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${error.response.data.message}`
        })
    }
}

export const updatePaymentTermin = async (id: string, data: ICreatePaymentTermin, token: string) => {
    try {
        const response = await axios.patch(`${API_URL}payment-termins/${id}`, data, {
            headers: {
                'x-auth-token': token
            }
        })
        return response.data
    } catch (error: any) {
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${error.response.data.message}`
        })
    }
}

export const finishPaymentTermin = async (id: string, data: IFinishPaymentTermin, token: string) => {
    try {
        const response = await axios.post(`${API_URL}payment-termins/${id}/receipt-urls`, data, {
            headers: {
                'x-auth-token': token
            }
        })
        return response.data
    } catch (error: any) {
        console.log(error)
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${error.response.data.message}`
        })
    }
}

export const deletePaymentTermin = async (id: string, token: string) => {
    try {
        const response = await axios.delete(`${API_URL}payment-termins/${id}`, {
            headers: {
                'x-auth-token': token
            }
        })
        return response.data
    } catch (error: any) {
        console.log(error)
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: `${error.response.data.message}`
        })
    }
}

export const saveMerchantLinkPipeline = (leadId: string, merchantId: string, merchantLinkId: string, token: string) => {
    return axios.post(SAVE_MERCHANT_LINK,
        {
            leadId,
            merchantId,
            merchantLinkId
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const getListMerchantLinkPipeline = (merchantSourceId: string, token: string) => {
    return axios.post(GET_LIST_MERCHANT_LINK,
        {
            merchantSourceId
        },
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}

export const getPaidSchedule = (leadId: string, token: string) => {
    return axios.get(GET_PAID_SCHEDULE + `?leadId=${leadId}`,
        {
            headers: {
                'X-Auth-token': token
            },
        }
    )
}