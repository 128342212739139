import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useAccessToken from "../../../../hooks/AccessToken";
import axios from "axios";
import useSWR from "swr";
import { ListProduct, Pipeline } from "../../../../interfaces/Pipeline";
import { useSettingsStore } from "../../../../stores/SettingsStore";
import { MasterLog } from "../../../../components/Pipelines/Logs/MasterLog";
import moment from "moment";
import { customNumberFormat, getDataById, getDateSummary, isColorDarkFromHex } from "../../../../functions/general";
import { CustomField, SettingMultiPipelines } from "../../../../interfaces/Settings";
import { RecentChatHistoryPipeline } from "../../../../interfaces/Chat/RecentChatHistory";
import { useIntl } from "react-intl";
import { ExpandableElement } from "../../../../components/General/ExpandableElement";
import { usePipelineStore } from "../../../../stores/PipelineStore";
import { useMerchantStore } from "../../../../stores/MerchantStore";
import { useUserStore } from "../../../../stores/UserStore";
import { Label } from "../../../../interfaces/Label";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useUser from "../../../../hooks/User";
import BadgeTooltip from "../../../../components/General/BadgeTooltip";

interface IProducLabelMerge{
    productName: string
    productLabelName:  string
    productLabelColor:  string
}
interface IProductLabelAcc {
    [key: string]: {
      productLabelColor: string;
      productLabelName: string;
      productSName: string[];
    };
  }

const Logs: FC = () => {
    const user = useUser()
    const { code, templatePipelineId } = useParams();
    const { settings } = useSettingsStore()
    const { multiPipelines } = usePipelineStore()
    const [lead, setLead] = useState<Pipeline | RecentChatHistoryPipeline>({} as Pipeline)
    const [customLead, setCustomLead] = useState({} as any)
    const [dataPipeline, setDataPipeline] = useState({} as SettingMultiPipelines)
    const intl = useIntl()
    const API_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const [customFields, setCustomFields] = useState<CustomField>({})

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token,
            },
        });
        return response.data.data;
    };

    const { data = [], error, isLoading } = useSWR(API_URL + `v2/lead/${code}`, fetcher)
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: simpleMerchants = [], error: simpleMerchantsError, isLoading: simpleMerchantsLoading } = useSWR(`${API_URL}simple-merchants`, fetcher)
    const { data: leads = [], error: leadsError, isLoading: leadsLoading } = useSWR(`${API_URL}simple-leads`, fetcher)
    

    const  returnProductName =(productsName:string[])=>{
        return productsName.map((name, index)=> index !== 0 ? `, ${name}` : name)
      } 
    useEffect(() => {
        if (!isLoading && !leadsLoading && !usersLoading && !simpleMerchantsLoading) {
            const customData: any = {
                date_created: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.TYPE.DATE_CREATED' }),
                    value: moment(data.date_created).format("DD MMM YYYY HH:mm")
                },
                merchant: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
                    value: returnMerchantName(data)
                },
                pic: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
                    value: data.owner_name
                },
                total_log: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }),
                    value: data.lead_logs_count && customNumberFormat(data.lead_logs_count)
                },
                progress: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
                    value: returnProgress(data)
                },
                phone: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
                    value: data.merchant_phone
                },
                age: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
                    value: getDateSummary(data.lead_age)
                },
                source: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
                    value: returnSource(data)
                },
                product: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
                    value: returnProduct(data)
                },
                label: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
                    value: returnLabel(data)
                },
                // ...(user?.data.company_name === "Maspion Square" || true
                //     ?   {
                //               nominal_without_vat: {
                //                   name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL_BEFORE_VAT' }),
                //                   value: `Rp. ${data.nominal_without_vat && customNumberFormat(Math.round(data.nominal_without_vat))}`,
                //               },
                //           }
                //     : {}),
                nominal: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
                    value: `Rp. ${data.nominal && customNumberFormat(Math.round(data.nominal))}`
                },  
                product_qty: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
                    value: returnQtyProduct(data)
                },
                creator: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
                    value: data.creator_name
                },
                start_date: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
                    value: moment(data.date_start).format("DD MMM YYYY HH:mm")
                },
                end_date: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
                    value: data.date_end ? moment(data.date_end).format("DD MMM YYYY HH:mm") : "-"
                },
                note: {
                    name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
                    value: data.note ? <ExpandableElement text={(data.note).replaceAll('\n', '<br/>')} maxHeight={100} /> : "-"
                },
            }

            const newData = {
                ...customData,
                ...Object.keys(customFields).reduce((acc, key) => {
                    let value;
                    if (customFields[key].type === 'invoice_reminder' && data.custom_fields[key]) {
                        if (JSON.parse(data.custom_fields[key]).invoice_url && JSON.parse(data.custom_fields[key]).date_due) {
                            value = returnInvoiceReminder(JSON.parse(data.custom_fields[key]).invoice_url, JSON.parse(data.custom_fields[key]).date_due)
                        } else if (JSON.parse(data.custom_fields[key]).invoice_url && !JSON.parse(data.custom_fields[key]).date_due) {
                            value = <a href={JSON.parse(data.custom_fields[key]).invoice_url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
                        } else if (!JSON.parse(data.custom_fields[key]).invoice_url && JSON.parse(data.custom_fields[key]).date_due) {
                            value = moment(JSON.parse(data.custom_fields[key]).date_due).format("DD MMM YYYY")
                        } else value = "-"
                    }
                    else if (customFields[key].type === 'date') {
                        value = data.custom_fields[key] ? moment(data.custom_fields[key]).format("DD MMM YYYY") : "-";
                    } else if (customFields[key].type === 'image' || customFields[key].type === 'images') {
                        value = data.custom_fields[key] ? returnImage(data.custom_fields[key]) : "-";
                    } else if (customFields[key].type === 'file' || customFields[key].type === 'files') {
                        value = data.custom_fields[key] ? returnFile(data.custom_fields[key]) : "-";
                    } else if (customFields[key].type === 'multiple') {
                        value = data.custom_fields[key] ? returnMultiple(data.custom_fields[key]) : "-";
                    } else if (customFields[key].type === 'merchant') {
                        value = data.custom_fields[key] ? getDataById(data.custom_fields[key] as any, simpleMerchants)?.map((item: any) => item.name).join(", ") : '-';
                    } else if (customFields[key].type === 'user') {
                        value = data.custom_fields[key] ? getDataById(data.custom_fields[key] as any, users)?.map((item: any) => item.name).join(", ") : '-';
                    } else if (customFields[key].type === 'pipeline') {
                        if (getDataById(data.custom_fields[key] as any, leads)) {
                            var result = getDataById(data.custom_fields[key] as any, leads)?.name + " / Rp." + customNumberFormat(getDataById(data.custom_fields[key] as any, leads) ? getDataById(data.custom_fields[key] as any, leads).nominal : 0) + " / " + moment(getDataById(data.custom_fields[key] as any, leads)?.dateCreated).format('DD MMM YYYY')
                            var idPipeline = getDataById(data.custom_fields[key] as any, leads)?.id
                            var idTemplate = leads.find((item: any) => item.id === idPipeline)?.leadTemplateId
                            value = returnCustomFieldPipeline(idTemplate, idPipeline, result);
                        } else value = "-"
                    } else {
                        value = data.custom_fields[key] ? data.custom_fields[key] : "-";
                    }

                    return {
                        ...acc,
                        [key]: {
                            name: customFields[key].name,
                            value
                        }
                    };
                }, {})
            };

            setCustomLead(newData);

            setLead(data)
        }
    }, [data, leads, users, simpleMerchants])

    useEffect(() => {
        var data = multiPipelines.find(pipeline => pipeline.id === templatePipelineId)
        if (data) {
            setDataPipeline(data)
            setCustomFields(data.customFields)
        }
    }, [multiPipelines, templatePipelineId, isLoading, leadsLoading, usersLoading, simpleMerchantsLoading])

    const returnMerchantName = (data: Pipeline) => {
        switch (data.hot_warm_cold_status) {
            case 'hot':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-danger ms-3">Hot</span></>
            case 'warm':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-warning ms-3">Warm</span></>
            case 'cold':
                return <><span>{data.merchant_name}</span><span className="badge badge-light-primary ms-3">Cold</span></>
            default:
                return data.merchant_name
        }
    }

    const returnProgress = (data: Pipeline) => {
        const progressName = data.lead_progress_name;
        const subProgressName = data.lead_subprogress_name;
        const progressColor = data.lead_progress_color;
    
        const badgeContent = subProgressName
            ? `${progressName} (${subProgressName})`
            : progressName;
    
        const badgeStyle = progressColor
            ? { backgroundColor: progressColor, color: isColorDarkFromHex(progressColor) ? "white" : "black" }
            : {};
    
        return (
            <BadgeTooltip tooltipText={badgeContent} badgeClass={progressColor ? '' : 'badge-light-info'} style={badgeStyle}>
                    {badgeContent}
            </BadgeTooltip>
        );
    }

    const returnProduct = (data: Pipeline) => {
        const labels = Object.values(
            data.leadProducts
              .flatMap((leadProduct) =>
                leadProduct.productLabels?.map((productLabel): IProducLabelMerge => {
                  return {
                    productName: leadProduct.productName,
                    productLabelName: productLabel.name,
                    productLabelColor: productLabel.color,
                  };
                })
              )
              .reduce((acc: IProductLabelAcc, item) => {
                const { productLabelColor, productLabelName, productName } = item as IProducLabelMerge;
                if (!acc[productLabelName]) {
                  acc[productLabelName] = {
                    productLabelColor,
                    productLabelName,
                    productSName: [],
                  };
                }                  
                acc[productLabelName].productSName.push(productName);
                return acc;
              }, {} as IProductLabelAcc) 
          ) 
          return (
            <div className=" d-flex">
            <div dangerouslySetInnerHTML={{ __html: data.leadProducts?.map((dataProduct: ListProduct) => dataProduct.productName).join(', ') }} />
            <OverlayTrigger
                placement='right'
                
                overlay={<Tooltip id='tooltip-labels' className="over-modal">
                                {labels?.map((label, index) => (
                                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' , zIndex:10}} className="over-modal">
                                            <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.productLabelColor||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                            </svg>
                                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                                                {label.productLabelName}
                                                <div style={{fontSize:'8px'}}>{returnProductName(label.productSName)}</div>
                                            </span>
                                        </div>
                                    ))
                            }
                            </Tooltip>}
                        >
                            <span
                                className='cursor-pointer'
                                data-toggle='tooltip'
                                data-placement='right'
                                title=''
                            >
                                {(labels?.length && labels.length > 0) ? (
                                    labels.length === 1 ? (
                                        <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: labels[0].productLabelColor||"black" }} >
                                            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                        </svg>
                                    ) : (
                                        <div className="ps-1" style={{ color: labels[1].productLabelColor||"black" }}>
                                            <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                <path fill={`${labels[0].productLabelColor}` ||"black"} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                            </svg>
                                        </div>
    
                                    )
                                ) : <></>}
                            </span>
                        </OverlayTrigger>    
            </div>
        )
    }

    const returnLabel = (data : Pipeline)=>{
        return  (
        <OverlayTrigger 
            placement='right'
            overlay={<Tooltip id='tooltip-labels'>                                        
                {    data?.labels?.map((label, index) => (
                        <span style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                            <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color||"black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                            </svg>
                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {label.name}
                            </span>
                        </span>
                    ))
            }
            </Tooltip>}
        >
            <span
                // id={`labels-${data.id}`}
                className='cursor-pointer'
                data-toggle='tooltip'
                data-placement='right'
                title=''
            >
                {(data.labels?.length && data.labels.length > 0) ? (
                    data.labels.length === 1 ? (
                        <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: data.labels[0].color||"black" }} >
                            <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                        </svg>
                    ) : (
                        <span className="ps-1" style={{ color: data.labels[1].color||"black" }}>
                            <svg width="18px" height="15px" viewBox="0 0 18 15">
                                <path fill={`${data.labels[0].color||"black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                            </svg>
                        </span>
                    )
                ) : <></>}
            </span>
        </OverlayTrigger>   
        )
    }

    const returnQtyProduct = (data: Pipeline) => {
        if (data.leadProducts) return customNumberFormat(data.leadProducts?.reduce((total, dataProduct) => total + Number(dataProduct.leadProductQty), 0))
        else return 0
    }

    const returnSource = (data: Pipeline) => {
        let badgeText = data.lead_source_name;
        let badgeClass = 'badge-light-info'; // Default class
    
        switch (data.lead_source_name) {
            case 'INBOUND':
                badgeClass = 'badge-light-primary';
                badgeText = 'Inbound';
                break;
            case 'OUTBOUND':
                badgeClass = 'badge-light-success';
                badgeText = 'Outbound';
                break;
            default:
                break;
        }
    
        return (
            <BadgeTooltip tooltipText={badgeText} badgeClass={badgeClass}>
                    {badgeText}
            </BadgeTooltip>
        );
    };

    const returnImage = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_PICTURE" })}</a>
            );
        }
    };

    const returnFile = (url: string | string[]) => {
        if (Array.isArray(url)) {
            return url.map((data: string, index: number) => (
                <span key={index}>
                    <a href={data} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })} {index + 1}</a>
                    {index < url.length - 1 && ', '}
                </span>
            ));
        } else {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_FILE" })}</a>
            );
        }
    };

    const returnMultiple = (data: string) => {
        const values = data && data.toString()
        return values ? values.split(',').join(', ') : '-'
    }

    const returnInvoiceReminder = (url: string, date: string) => {
        return (
            <div>
                {moment(date).format("DD MMM YYYY")}, <a href={url} target="_blank" rel="noopener noreferrer">{intl.formatMessage({ id: "FORM.ACTION.VIEW_INVOICE" })}</a>
            </div>
        )
    }

    const returnCustomFieldPipeline = (idTemplate: string, idPipeline: string, name: string) => {
        return (
            <a href={`/pipelines/${idTemplate}/log/${idPipeline}`} target="_blank" rel="noopener noreferrer">{name}</a>
        )
    }

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <div className="text-gray-900 fs-2x fw-bold">
                    <ol className="breadcrumb text-muted fs-6 fw-bold">
                        <li className="breadcrumb-item pe-3">
                            {settings.pipeline_title}
                        </li>
                        <li className="breadcrumb-item pe-3">
                            <Link to={`/pipelines/${dataPipeline.id}`} className="text-muted text-hover-primary">
                                {dataPipeline.name}
                            </Link>
                        </li>
                        <li className="breadcrumb-item text-dark">Log {lead.merchant_name}</li>
                    </ol>
                </div>
            </div>
            <div className="card shadow-sm mb-5">
                <div className="card-header collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" data-bs-target="#kt_detail_pipeline" style={{ justifyContent: "left", minHeight: "50px" }}>
                    <div className="card-toolbar rotate-90">
                        <span className="svg-icon svg-icon-1">
                            <span className="svg-icon svg-icon-dark svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z" fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <h5 className="card-title ms-3 fs-4">{intl.formatMessage({ id: "FORM.LABEL.DETAILS" }, { title: settings.pipeline_title })}</h5>
                </div>
                <div id="kt_detail_pipeline" className="collapse">
                    <div className="card-body">
                        {
                            isLoading ?
                                <div className="loading-overlay">
                                    <div className="loader"></div>
                                </div>
                                :
                                <div className="row">
                                    {
                                        Object.keys(customLead).map((key, index) => {
                                            return (
                                                <div className="row pb-2 col-12 col-lg-6 col-xl-6">
                                                    <div className="col-3 text-end" style={{ fontWeight: "500", color: "#767373" }}>{customLead[key].name}</div>
                                                    <div className="col-9" style={{ fontWeight: "500", color: "black" }}>{customLead[key].value}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="card shadow-sm mb-5">
                <div className="card-header collapsible cursor-pointer rotate collapsed" data-bs-toggle="collapse" data-bs-target="#kt_activity_pipeline" style={{ justifyContent: "left", minHeight: "50px" }}>
                    <div className="card-toolbar rotate-90">
                        <span className="svg-icon svg-icon-1">
                            <span className="svg-icon svg-icon-dark svg-icon-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.9 10.7L7 5V19L16.9 13.3C17.9 12.7 17.9 11.3 16.9 10.7Z" fill="black" />
                                </svg>
                            </span>
                        </span>
                    </div>
                    <h5 className="card-title ms-3 fs-4">{intl.formatMessage({ id: "FORM.LABEL.ACTIVITIES" }, { title: settings.pipeline_title })}</h5>
                </div>
                <div id="kt_activity_pipeline" className="collapse">
                    <div className="card-body pt-0">
                        {
                            isLoading ?
                                <div className="loading-overlay">
                                    <div className="loader"></div>
                                </div>
                                :
                                <MasterDataTable
                                    tableKey="pipeline-activities"
                                    tableColumns={tableColumns}
                                    apiURL={`user-activities?tableType=PIPELINE&activitySourceId=${code}`}
                                    orderBy={1}
                                    order="desc"
                                    expanded
                                />
                        }
                    </div>
                </div>
            </div> */}

            {
                !isLoading && lead.id &&
                <MasterLog lead={lead} setLead={setLead} key={code} />
            }
        </>
    )
}

export { Logs }