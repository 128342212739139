import { useRef, useState } from "react";
import { useChatStore } from "../../../../stores/ChatStore";
import useAccessToken from "../../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../../../hooks/HandleCloseModal";
import { useFormik } from "formik";
import * as Yup from 'yup';
import clsx from "clsx";
import { KTSVG } from "../../../../_metronic/helpers";
import Swal from "sweetalert2";
import { AttachmentQuickReply, QuickReply } from "../../../../interfaces/Chat/QuickReply";
import { updateQuickReply } from "../../../../api/quickReplyCRUD";
import InputAttachment from "../../../Input/InputAttachment";
import { insertQuickReplyAttachment } from "../../../../api/ResourceAPI";
import { isImage, isVideo } from "../../../../functions/general";

interface UpdateQuickReplyProps {
    quickReply: QuickReply
}

const UpdateQuickReply = ({ quickReply }: UpdateQuickReplyProps) => {
    const token = useAccessToken()
    const intl = useIntl()

    const { quickReplies, setQuickReplies } = useChatStore()

    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)

    const formik = useFormik({
        initialValues: {
            shortcut: quickReply.shortcut,
            message: quickReply.message,
            attachment: quickReply.attachments !== null ? quickReply.attachments.map((attachment) => attachment.url) : []
        },
        validationSchema: Yup.object().shape({
            shortcut: Yup.string()
                .matches(/^[a-zA-Z0-9]*$/, intl.formatMessage({ id: "FORM.VALIDATION.ALPHANUMERIC_ONLY" }, { title: intl.formatMessage({ id: "FORM.LABEL.QUICK_REPLY_SHORTCUT" }) }))
                .required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.QUICK_REPLY_SHORTCUT" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const isDuplicate = quickReplies.some((data) => {
                return (data.shortcut).trim().toLowerCase() !== (quickReply.shortcut).trim().toLowerCase() && (data.shortcut).trim().toLowerCase() === (values.shortcut).trim().toLowerCase();
            });

            if (isDuplicate) {
                Swal.fire({
                    icon: 'error',
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.DUPLICATE_LABEL" })}`,
                    confirmButtonText: 'Ok',
                    heightAuto: false
                });

                return;
            }

            if (!values.message && !values.attachment) {
                Swal.fire({
                    icon: 'error',
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.MESSAGE_AND_ATTACHMENT_EMPTY" })}`,
                    confirmButtonText: 'Ok',
                    heightAuto: false
                });

                return;
            }

            setModalBtnLoading(true)

            try {
                let attachments: AttachmentQuickReply[] = []
                if (values.attachment) {
                    await Promise.all(
                        values.attachment.map(async (attachment) => {
                            if (typeof attachment === 'object') {
                                const resourceResponse = await insertQuickReplyAttachment([attachment])
                                if (resourceResponse.data.length > 0) {
                                    const url = resourceResponse.data[0]
                                    const parts = url.split('/');
                                    const fileName = parts[parts.length - 1];
                                    attachments.push({
                                        type: isImage(url) ? "image" : isVideo(url) ? "video" : "file",
                                        name: fileName,
                                        url: url,
                                        mimeType: (attachment as File).type
                                    })
                                }
                            } else {
                                const url = attachment
                                const parts = url.split('/');
                                const fileName = parts[parts.length - 1];
                                attachments.push({
                                    type: isImage(url) ? "image" : isVideo(url) ? "video" : "file",
                                    name: fileName,
                                    url: url,
                                    mimeType: quickReply.attachments?.find((attachment) => attachment.url === url)?.mimeType!
                                })
                            }
                        })
                    )
                }

                console.log(attachments)
                const response = await updateQuickReply(quickReply.id, values.shortcut, values.message, attachments, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();

                    setQuickReplies(quickReplies.map(item => {
                        if (item.id === quickReply.id) {
                            return {
                                ...response.data.data,
                            }
                        } else {
                            return item
                        }
                    }));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id="update-chat-quick-reply-modal" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} {intl.formatMessage({ id: 'FORM.MENU.QUICK_REPLIES' })}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.QUICK_REPLY_SHORTCUT' })}</label>
                            <div className="input-group mb-3">
                                {/* add slash to simulate slash command */}
                                <span className="input-group-text">/</span>
                                <input
                                    placeholder="hello"
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.shortcut && formik.errors.shortcut },
                                    )}
                                    type='text'
                                    autoComplete='off'
                                    {...formik.getFieldProps('shortcut')}
                                />
                            </div>
                            {formik.touched.shortcut && formik.errors.shortcut && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.shortcut}</span>
                                </div>
                            )}
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.QUICK_REPLY_MESSAGE' })}</label>
                            <div className="input-group mb-3">
                                <textarea
                                    id="quick-reply-message"
                                    placeholder="Hello, how can I help you?"
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.message && formik.errors.message },
                                    )}
                                    {...formik.getFieldProps('message')}
                                >
                                </textarea>
                            </div>
                            {formik.touched.message && formik.errors.message && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.message}</span>
                                </div>
                            )}
                            <label className={`form-label fs-6 fw-bolder text-dark`}>
                                {intl.formatMessage({ id: "FORM.LABEL.ATTACHMENT" })}
                            </label>
                            <InputAttachment
                                keyString={"attachment"}
                                formik={formik}
                                isMulti={true}
                                type={`update-${quickReply}`}
                                accept="image/png, image/jpeg, image/jpg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, video/mp4, video/x-matroska, video/webm, video/quicktime, text/plain"
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { UpdateQuickReply }