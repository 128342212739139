import React, { useEffect, useRef, useState } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import clsx from "clsx";
import useAccessToken from '../../../../hooks/AccessToken';
import { useIntl } from "react-intl";
import useUser from '../../../../hooks/User';
import useHandleCloseModal from '../../../../hooks/HandleCloseModal';
import { handleCaps } from "../../../../functions/general";
import { useDataTableStore } from '../../../../stores/DataTableStore';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { createGroupContactEmail } from '../../../../api/EmailCRUD';
import { useEmailStore } from '../../../../stores/EmailStore';
import { useUserStore } from '../../../../hooks/UserEmail';

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL


const AddGroupEmail = () => {
  const user = useUser();
  const intl = useIntl();
  const token = useAccessToken();
  const { tablesData, setTablesData } = useDataTableStore();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const { userEmail } = useUserStore()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameGroup: '',
    },
    validationSchema: Yup.object().shape({
      nameGroup: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const data = {
          name: values.nameGroup,
          userId: userEmail?.id || '',
        }
        const response = await createGroupContactEmail(data);
        if (response) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_EMAIL_SUCCESS" })}`,
            timer: 1500,
            timerProgressBar: true
          });
          setTablesData('groupEmail', ([response.data, ...tablesData.groupEmail]));

          closeModalRef.current?.click();
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message || 'An error occurred',
          confirmButtonText: 'Ok',
          heightAuto: false,
        });
      } finally {
        setSubmitting(false);
      }
    }
  });


  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      formik.resetForm();
    };

    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [formik]);

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  return (
    <div>
      <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#add-group-bc">
        <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} Group Email
      </button>
      <div className="modal fade" tabIndex={-1} id="add-group-bc" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} Group Email</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                onClick={handleCloseModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="modal-body">
                {/* Nama Group Email */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })} Group Email</label>
                <div className="input-group mb-3">
                  <input
                    placeholder={`${intl.formatMessage({ id: 'FORM.LABEL.NAME' })} Group Email`}
                    {...formik.getFieldProps('nameGroup')}
                    className={clsx(
                      'form-control form-control-lg',
                      { 'is-invalid': formik.touched.nameGroup && formik.errors.nameGroup },
                    )}
                    type='text'
                    name='nameGroup'
                    autoComplete='off'
                    onInput={(e) => handleCaps(e)}
                  />
                </div>
                {formik.touched.nameGroup && formik.errors.nameGroup && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.nameGroup}</span>
                  </div>
                )}
                {/* End: Group Email */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light d-none"
                  data-bs-dismiss="modal"
                  ref={closeModalRef}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleCloseModal}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? 'disabled' : ''}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                  <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.CREATE' })}
                  </span>
                  <span className="indicator-progress">
                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddGroupEmail