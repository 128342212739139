import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getLinkChecker, updateUrlChecker } from '../../../../api/EmailCRUD'
import useSWR from 'swr'
import { UrlType } from '../../../../interfaces/Email'

const RedirectBlankPage = () => {
  const [searchParams] = useSearchParams()
  const queryId = searchParams.get('id')
  const [dataUrlChecker, setDataUrlChecker] = useState<UrlType[]>([])
  const [dataUpdateUrlChecker, setDataUpdateUrlChecker] = useState<any>(null)

  const submitPost = async () => {
    if (queryId) {
      const data = await getLinkChecker([queryId])
      if (data.success) {
        setDataUrlChecker(data.data)
        const dataUpdateUrlChecker = await updateUrlChecker([queryId])
        if (dataUpdateUrlChecker.success) {
          setDataUpdateUrlChecker(dataUpdateUrlChecker)
          if (dataUrlChecker[0].link) {
            window.location.href = dataUrlChecker[0].link
          }
        }
      }
    }
  }

  useEffect(() => {
    submitPost()
  }, [queryId, dataUpdateUrlChecker])

  return null
}

export default RedirectBlankPage