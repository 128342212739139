import axios from "axios"
import { MeetingAttachment } from "../interfaces/Meeting"
import { Label } from "../interfaces/Label"

const API_URL = process.env.REACT_APP_API_URL

const MEETING = `${API_URL}meeting/`
const VISIT = `${API_URL}visits/`

interface IMerchantLabel{
    id:string
}

type InsertMeeting = {
    meetingTemplateId: string,
    merchantId: string,
    start: {
        date: string | null
        dateTime: string | null
    }
    end?: {
        date: string | null
        dateTime: string | null
    }
    ownerId: string,
    attachments: MeetingAttachment[],
    token: string,
    status: string,
    sendNotification: boolean,
    note?: string,
    reminderMinutes?: string | null,
    userAttendees?: object,
    latitude?: string,
    longitude?: string,
    location?: string,
    meetingLabels?: IMerchantLabel[],
    customFields?: {
        [key: string]: string | string[] | null
    }
}

type InsertVisitMeeting = {
    meetingTemplateId: string,
    merchantId: string,
    note: string,
    ownerId: string,
    latitude: number,
    longitude: number,
    location: string,
    attachmentLink: string | null,
    token: string,
    meetingLabels?: IMerchantLabel[],
    customFields?: {
        [key: string]: string | string[] | null
    }
}

type UpdateVisitMeeting = {
    meetingTemplateId: string,
    idMeeting: string,
    note: string,
    attachmentLink: string | null,
    token: string,
    customFields?: {
        [key: string]: string | string[] | null
    }
    meetingLabels:{
        id:string
    }[]
}

type UpdateVisitOutMeeting = {
    meetingTemplateId: string,
    idMeeting: string,
    note: string,
    location: string,
    latitude: number,
    longitude: number,
    attachmentLink: string | null,
    token: string,
    customFields?: {
        [key: string]: string | string[] | null | number
    }
}

type UpdateMeeting = {
    meetingTemplateId: string,
    id: string,
    start: {
        date: string | null
        dateTime: string | null
    }
    end?: {
        date: string | null
        dateTime: string | null
    }
    ownerId: string,
    attachments: MeetingAttachment[],
    status: string,
    reminderMinutes: string,
    userAttendees: object,
    token: string,
    notification: boolean,
    note?: string,
    latitude?: string,
    longitude?: string,
    location?: string,
    meetingLabels?: IMerchantLabel[]
    customFields?: {
        [key: string]: string | string[] | null
    }
}

export const insertMeeting = async ({ meetingTemplateId, meetingLabels,merchantId, start, end, ownerId, attachments, token, status, note, reminderMinutes, userAttendees, sendNotification, latitude, longitude, location, customFields }: InsertMeeting) => {
    return axios.post(MEETING + "?sendNotification=" + sendNotification, {
        meetingTemplateId,
        meetingLabels,
        merchantId,
        start,
        end,
        ownerId,
        attachments,
        note,
        reminderMinutes,
        userAttendees,
        status,
        location: {
            lat: latitude,
            lng: longitude,
            detail: location
        },
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateMeeting = async ({ meetingTemplateId, meetingLabels,id, start, end, ownerId, attachments, status, reminderMinutes, userAttendees, token, notification, note, latitude, longitude, location, customFields }: UpdateMeeting) => {
    return axios.patch(MEETING + id + "?sendNotification=" + notification, {
        meetingTemplateId,
        meetingLabels,
        start,
        end,
        ownerId,
        attachments,
        status,
        reminderMinutes,
        userAttendees,
        note,
        location: {
            lat: latitude,
            lng: longitude,
            detail: location
        },
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteMeeting = async (id: string, token: string, notification: boolean) => {
    return axios.delete(MEETING + id + "?sendNotification=" + notification, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const insertVisitMeeting = async ({ meetingTemplateId, meetingLabels, merchantId, ownerId, note, latitude, longitude, location, attachmentLink, customFields, token }: InsertVisitMeeting) => {
    return axios.post(VISIT, {
        meetingTemplateId,
        meetingLabels,
        merchantId,
        note,
        ownerId,
        location: {
            lat: latitude,
            lng: longitude,
            detail: location
        },
        attachmentLink,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateVisitMeeting = async ({ meetingTemplateId, meetingLabels,idMeeting, note, attachmentLink, token, customFields }: UpdateVisitMeeting) => {
    return axios.put(VISIT + idMeeting, {
        meetingTemplateId,
        meetingLabels,
        note,
        attachmentLink,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateVisitOutMeeting = async ({ meetingTemplateId, idMeeting, note, location, latitude, longitude, attachmentLink, token, customFields }: UpdateVisitOutMeeting) => {
    return axios.post(VISIT + idMeeting + "/complete", {
        meetingTemplateId,
        idMeeting,
        note,
        location: {
            lat: latitude,
            lng: longitude,
            detail: location
        },
        attachmentLink,
        customFields
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}