import 'moment/locale/id';
import { FC, useEffect, useState, ReactNode } from "react"
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import moment from "moment";
import { Modal } from "bootstrap";
import useUser from '../../../hooks/User'
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Reimburse } from '../../../interfaces/Reimbursement';
import { customNumberFormat, isColorDarkFromHex } from '../../../functions/general';
import { AddReimbursement } from '../../../components/Reimbursement/AddReimbursement';
import { DeleteReimbursement } from '../../../components/Reimbursement/DeleteReimbursement';
import { UpdateReimbursement } from '../../../components/Reimbursement/UpdateReimbursement';
import { ApproveReimbursement } from '../../../components/Reimbursement/ApproveReimbursement';
import { RejectReimbursement } from '../../../components/Reimbursement/RejectReimbursement';
import { PayOffReimbursement } from '../../../components/Reimbursement/PayOffReimbursement';
import { FilterReimbursement } from '../../../components/Reimbursement/FilterReimbursement';
import { DetailReimbursement } from '../../../components/Reimbursement/DetailReimbursement';
import { useIntl } from 'react-intl';
import { DateRangePicker } from 'rsuite';
import usePredefinedRanges from '../../../hooks/PredefinedRanges';
import useCalendarLocale from '../../../hooks/CalendarLocale';
import { endOfMonth, startOfMonth } from 'date-fns';
import { mutate } from 'swr';
import { settings } from 'cluster';
import { useSettingsStore } from '../../../stores/SettingsStore';
import { DetailMeeting } from '../../../components/Meetings/DetailMeeting';
import { DetailMerchant } from '../../../components/Merchants/DetailMerchant';

const Reimbursement: FC = () => {

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
    const [showPayOffModal, setShowPayOffModal] = useState<boolean>(false);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [showDetailMeetingModal, setShowDetailMeetingModal] = useState<boolean>(false);
    const [showDetailMerchantModal, setShowDetailMerchantModal] = useState<boolean>(false);
    const [reimburse, setReimburse] = useState<Reimburse>();
    const user = useUser()
    const { settings } = useSettingsStore()
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const intl = useIntl()
    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();
    const today = new Date()
    const [startDate, setStartDate] = useState<Date>(startOfMonth(today));
    const [endDate, setEndDate] = useState<Date>(endOfMonth(today));
    const [loading, setLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const API_URL = process.env.REACT_APP_API_URL

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<Reimburse>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            // ((user.data.is_superadmin && row.approvalStatus !== 'PAID_OFF' && row.approvalStatus !== 'REJECTED') || (!user.data.is_superadmin && row.approvalStatus === 'PENDING')) &&
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    <MenuItem onClick={() => {
                                        handleClose();
                                        setReimburse(row);
                                        setShowDetailModal(true);
                                    }}>
                                        <i className="fas fa-info-circle fs-4 me-4"></i>
                                        <span>{intl.formatMessage({ id: 'FORM.ACTION.DETAIL' })}</span>
                                    </MenuItem>

                                    {!user.data.is_superadmin && row.approvalStatus === 'PENDING' &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setReimburse(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }

                                    {!user.data.is_superadmin && row.approvalStatus === 'PENDING' &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setReimburse(row);
                                        }}>
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }

                                    {user.data.is_superadmin && row.approvalStatus === 'PENDING' &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowApproveModal(true);
                                            setReimburse(row);
                                        }}>
                                            <i className="fas fa-check-circle fs-3 me-5 text-success"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.APPROVE' })}</span>
                                        </MenuItem>
                                    }

                                    {user.data.is_superadmin && row.approvalStatus === 'PENDING' &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowRejectModal(true);
                                            setReimburse(row);
                                        }}>
                                            <i className="fas fa-times-circle fs-3 me-5 text-danger"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.REJECT' })}</span>
                                        </MenuItem>
                                    }

                                    {user.data.is_superadmin && row.approvalStatus === 'APPROVED' &&
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowPayOffModal(true);
                                            setReimburse(row);
                                        }}>
                                            <i className="fas fa-file-upload fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPLOAD_PROOF_OF_PAYMENT' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_SUBMITTED' }),
            selector: row => row.dateCreated,
            sortable: true,
            width: '150px',
            cell: row => moment(row.dateCreated).format('DD MMMM YYYY'),
            style: {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
            selector: row => row.name?.toLowerCase(),
            width: '250px',
            sortable: true,
            cell: (row) => {
                return (
                    row.reference.type !== 'NONE' ?
                        <>
                            {row.name}
                            <span className="badge badge-light-primary ms-3" >{row.reference.type === 'MEETING' ? settings.meeting_title : settings.merchant_title}</span>
                        </>
                        :
                        <span>{row.name}</span>
                )
            },
            style: {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CATEGORY' }),
            selector: row => row.reimbursementCategory.name?.toLowerCase(),
            sortable: true,
            cell: (row) => {
                return (
                    row.reimbursementCategory.color ?
                        <span className="badge" style={{ backgroundColor: row.reimbursementCategory.color, color: isColorDarkFromHex(row.reimbursementCategory.color) ? "white" : "black" }}>{row.reimbursementCategory.name}</span>
                        :
                        <span className="badge badge-light-info" >{row.reimbursementCategory.name}</span>
                )
            },
            style: {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
            width: '170px',
            style: {
                justifyContent: 'space-between',
                '&:hover': {
                    cursor: 'pointer'
                }
            },
            selector: row => row.value,
            sortable: true,
            cell(row) {
                return (
                    <>
                        <span>{row.companyCurrency.symbol ?? ''}</span><span>{row.value && customNumberFormat(row.value)}</span>
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }),
            selector: row => row.userOwner.name,
            sortable: true,
            omit: (user.data.is_superadmin === false),
            cell: row => row.userOwner.name,
            style: {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.STATUS' }),
            selector: row => row.approvalStatus,
            sortable: true,
            width: '150px',
            cell(row) {
                switch (row.approvalStatus) {
                    case 'REJECTED':
                        return <span className="badge badge-light-danger">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.REJECTED' })}</span>
                    case 'APPROVED':
                        return <span className="badge badge-light-success">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.APPROVED' })}</span>
                    case 'PAID_OFF':
                        return <span className="badge badge-light-primary">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PAID_OFF' })}</span>
                    default:
                        return <span className="badge badge-light-warning">{intl.formatMessage({ id: 'REIMBURSEMENT.STATUS.PENDING' })}</span>
                }
            },
            style: {
                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
    ];

    let actions: ReactNode[] = []
    if (!user.data.is_superadmin) actions.push(<AddReimbursement />)
    actions.push(<FilterReimbursement />)

    const onRowClicked = (row: any, event: any) => {
        setReimburse(row)
        setShowDetailModal(true);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (showUpdateModal && reimburse) {
            const modalElement = document.getElementById(`update-reimbursement-modal-${reimburse.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, reimburse, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && reimburse) {
            const modalElement = document.getElementById(`delete-reimbursement-modal-${reimburse.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, reimburse, setShowDeleteModal]);

    useEffect(() => {
        if (showApproveModal && reimburse) {
            const modalElement = document.getElementById(`approve-reimbursement-modal-${reimburse.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowApproveModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showApproveModal, reimburse, setShowApproveModal]);

    useEffect(() => {
        if (showRejectModal && reimburse) {
            const modalElement = document.getElementById(`reject-reimbursement-modal-${reimburse.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowRejectModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showRejectModal, reimburse, setShowRejectModal]);

    useEffect(() => {
        if (showPayOffModal && reimburse) {
            const modalElement = document.getElementById(`payoff-reimbursement-modal-${reimburse.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowPayOffModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showPayOffModal, reimburse, setShowPayOffModal]);

    useEffect(() => {
        if (showDetailModal) {
            const modalElement = document.getElementById(`detail-reimbursement-modal-${reimburse?.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailModal, setShowDetailModal]);

    useEffect(() => {
        if (showDetailMeetingModal) {
            const modalElement = document.getElementById(`detail-meeting-modal-${reimburse?.reference.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailMeetingModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailMeetingModal, setShowDetailMeetingModal]);

    useEffect(() => {
        if (showDetailMerchantModal) {
            const modalElement = document.getElementById(`detail-merchant-modal-${reimburse?.reference.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailMerchantModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailMerchantModal, setShowDetailMerchantModal]);

    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}${!user.data.is_superadmin ? "my-reimbursements" : "reimbursements"}`);
        }
    }, [startDate, endDate]);

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                    <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'FORM.MENU.REIMBURSEMENTS' })}</li>
                </ol>
                <DateRangePicker
                    cleanable={false}
                    character=" - "
                    format="dd MMM yyyy"
                    locale={calendarLocale}
                    value={[startDate, endDate]}
                    ranges={predefinedRanges}
                    placement="bottomEnd"
                    onChange={(value) => {
                        if (value && value.length === 2) {
                            setStartDate(value[0]);
                            setEndDate(value[1]);
                        }
                    }}
                    showOneCalendar={isMobile}
                    disabled={loading}
                    isoWeek={true}
                />
            </div>
            <MasterDataTable
                tableKey={!user.data.is_superadmin ? "my-reimbursements" : "reimbursements"}
                tableColumns={tableColumns}
                actions={actions}
                apiURL={!user.data.is_superadmin ? "my-reimbursements" : "reimbursements"}
                dateStart={moment(startDate).format('YYYY-MM-DD')}
                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                orderBy={2}
                order="desc"
                onRowClicked={onRowClicked}
                setLoading={setLoading}
            />
            {
                showUpdateModal && <UpdateReimbursement reimburse={reimburse!} />
            }
            {
                showDeleteModal && <DeleteReimbursement reimburse={reimburse!} />
            }
            {
                showApproveModal && <ApproveReimbursement reimburse={reimburse!} />
            }
            {
                showRejectModal && <RejectReimbursement reimburse={reimburse!} />
            }
            {
                showPayOffModal && <PayOffReimbursement reimburse={reimburse!} />
            }
            {
                showDetailModal && <DetailReimbursement data={reimburse!} setShowDetailMeetingModal={setShowDetailMeetingModal} setShowDetailMerchantModal={setShowDetailMerchantModal}/>
            }
            {
                showDetailMeetingModal && <DetailMeeting data={reimburse!.reference.meeting} />
            }
            {
                showDetailMerchantModal && <DetailMerchant data={reimburse!.reference.merchant} />
            }
        </>
    )
}

export { Reimbursement }