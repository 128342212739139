import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import clsx from "clsx";
import { handleCaps } from "../../functions/general";
import { insertDescriptionClockIn, insertDescriptionClockOut } from "../../api/AttendanceCRUD";
import useAccessToken from "../../hooks/AccessToken";
import { useNavigate } from 'react-router-dom'
import { useIntl } from "react-intl";

interface AttendanceDescriptionProps {
    id: string
    isClockInLate: boolean
    isClockOutEarly: boolean
    isOutOfRange: boolean
    isUnrecognizedFace: boolean
}

const AttendanceDescription: FC<AttendanceDescriptionProps> = ({ id, isClockInLate, isClockOutEarly, isOutOfRange, isUnrecognizedFace }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const navigate = useNavigate()
    const intl = useIntl()
    
    const formik = useFormik({
        initialValues: {
            note: '',
        },
        validationSchema: Yup.object().shape({
            note: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, {title: intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}))
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            try {
                if (window.location.pathname === '/attendance/clock-in') {
                    const response = await insertDescriptionClockIn(
                        id,
                        values.note,
                        token,
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            heightAuto: false,
                            title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                            timer: 2000,
                            timerProgressBar: true
                        })
                            .then(() => {
                                closeModalRef.current?.click();
                                navigate('/attendance')
                            })
                    }
                }
                else if (window.location.pathname === '/attendance/clock-out') {
                    const response = await insertDescriptionClockOut(
                        id,
                        values.note,
                        token,
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            heightAuto: false,
                            title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                            timer: 2000,
                            timerProgressBar: true
                        })
                            .then(() => {
                                closeModalRef.current?.click();
                                navigate('/attendance')
                            })
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    function titleText() {
        let text = ""
        if (isClockInLate && isOutOfRange && isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.LATE_RANGE_FACE' })
        else if (isClockOutEarly && isOutOfRange && isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.EARLY_RANGE_FACE' })
        else if (isClockInLate && isOutOfRange) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.LATE_RANGE' })
        else if (isClockOutEarly && isOutOfRange) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.EARLY_RANGE' })
        else if (isClockInLate && isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.LATE_FACE' })
        else if (isClockOutEarly && isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.EARLY_FACE' })
        else if (isOutOfRange && isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.RANGE_FACE' })
        else if (isClockInLate) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.LATE' })
        else if (isClockOutEarly) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.EARLY' })
        else if (isOutOfRange) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.RANGE' })
        else if (isUnrecognizedFace) text = intl.formatMessage({ id: 'ATTENDANCE.NOTE.FACE' })
        return text
    }

    return (
        <div className="modal fade" tabIndex={-1} id={`description-attendance-modal`} data-bs-backdrop="static" data-bs-keyboard="false" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header d-none">
                        <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_NOTE" })}</h5>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            {/* Note */}
                            <label className='form-label fs-6 fw-bold text-dark mb-6'>{titleText()}&nbsp;{intl.formatMessage({ id: 'ATTENDANCE.NOTE.GIVE' })}</label>
                            <div className="input-group mb-3">
                                <textarea
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}
                                    id="floatingTextarea"
                                    {...formik.getFieldProps('note')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.note && formik.errors.note },
                                    )}
                                    style={{ zIndex: 0 }}
                                    name="note"
                                    maxLength={255}
                                    onInput={(e) => handleCaps(e)}
                                    rows={6}
                                >
                                </textarea>
                            </div>
                            {formik.touched.note && formik.errors.note && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.note}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary w-100 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AttendanceDescription }