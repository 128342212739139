import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { insertRole, setAccessRolePipeline } from "../../api/RolesCRUD";
import Select from "react-select"
import { Switch } from "@mui/material";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { usePipelineStore } from "../../stores/PipelineStore";
import { SelectOption } from "../../interfaces/SelectOption";
import { SettingMultiPipelines } from "../../interfaces/Settings";

const optionPipeline = [
    {
        value: "NONE",
        label: "NONE",
    },
    {
        value: "PERSONAL",
        label: "PERSONAL",
    },
    {
        value: "TEAM",
        label: "TEAM",
    },
    {
        value: "ALL",
        label: "ALL",
    },
]

const AddRole: FC = () => {
    const { settings } = useSettingsStore()
    const { multiPipelines } = usePipelineStore()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [optionMultiPipeline, setOptionMultiPipeline] = useState<SelectOption[]>([]);
    const [allowedExport, setAllowedExport] = useState(false);
    const [allowedSendReport, setAllowedSendReport] = useState(true);
    const intl = useIntl()
    const allOptionPipeline = [{ label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: `Template ${settings.pipeline_title}` }), value: 'all' }]

    const optionFull = [
        {
            value: "NONE",
            label: intl.formatMessage({ id: "ROLES.NONE" })
        },
        {
            value: "OWNED",
            label: intl.formatMessage({ id: "ROLES.PERSONAL" }),
        },
        {
            value: "TEAM",
            label: intl.formatMessage({ id: "ROLES.TEAM" }),
        },
        {
            value: "ALL",
            label: intl.formatMessage({ id: "ROLES.ALL" })
        },
    ]

    const optionLimited = [
        {
            value: "NONE",
            label: intl.formatMessage({ id: "ROLES.NONE" })
        },
        {
            value: "ALL",
            label: intl.formatMessage({ id: "ROLES.ALL" })
        },
    ]

    const optionTarget = [
        {
            value: "NONE",
            label: intl.formatMessage({ id: "ROLES.NONE" }),
        },
        {
            value: "TEAM",
            label: intl.formatMessage({ id: "ROLES.TEAM" }),
        },
        {
            value: "TEAM EXCEPT SELF",
            label: intl.formatMessage({ id: "ROLES.TEAM_EXCEPT_SELF" }),
        },
        {
            value: "WHOLE TEAM",
            label: intl.formatMessage({ id: "ROLES.WHOLE_TEAM" }),
        },
        {
            value: "WHOLE TEAM EXCEPT SELF",
            label: intl.formatMessage({ id: "ROLES.WHOLE_TEAM_EXCEPT_SELF" }),
        },
        {
            value: "ALL",
            label: intl.formatMessage({ id: "ROLES.ALL" }),
        },
    ]

    const formik = useFormik({
        initialValues: {
            name: '',
            has_subordinate: false,
            permission_pipeline_create: 'NONE',
            permission_pipeline_read: 'NONE',
            permission_pipeline_update: 'NONE',
            permission_pipeline_delete: 'NONE',
            permission_meeting_create: 'NONE',
            permission_meeting_read: 'NONE',
            permission_meeting_update: 'NONE',
            permission_meeting_delete: 'NONE',
            permission_merchant_create: 'NONE',
            permission_merchant_read: 'NONE',
            permission_merchant_update: 'NONE',
            permission_merchant_delete: 'NONE',
            permission_pipeline_log_create: 'NONE',
            permission_user_target_set: 'TEAM EXCEPT SELF',
            is_allowed_export_excel: false,
            is_allowed_send_report: true,
            is_allowed_access_hpp: true,
            accessMultiPipeline: []
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            accessMultiPipeline: settings.feature_pipeline ? Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: `Template ${settings.pipeline_title}` })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: `Template ${settings.pipeline_title}` })).nullable() : Yup.array().notRequired().nullable(),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            let accessMultiPipeline = values.accessMultiPipeline.map((item: SettingMultiPipelines) => item.value);

            if (values.accessMultiPipeline.find((pipeline: SettingMultiPipelines) => pipeline.value === 'all')) accessMultiPipeline = multiPipelines.filter(item => item.value !== 'all').map(item => item.value);
            else accessMultiPipeline = values.accessMultiPipeline.map((item: SettingMultiPipelines) => item.value);

            try {
                const response = await insertRole(
                    values.name,
                    values.has_subordinate,
                    values.permission_pipeline_create,
                    values.permission_pipeline_read,
                    values.permission_pipeline_update,
                    values.permission_pipeline_delete,
                    values.permission_meeting_create,
                    values.permission_meeting_read,
                    values.permission_meeting_update,
                    values.permission_meeting_delete,
                    values.permission_merchant_create,
                    values.permission_merchant_read,
                    values.permission_merchant_update,
                    values.permission_merchant_delete,
                    values.permission_pipeline_log_create,
                    values.permission_user_target_set,
                    values.is_allowed_export_excel,
                    values.is_allowed_send_report,
                    values.is_allowed_access_hpp,
                    token,
                );
                if (response.data.success) {
                    const responseMultiPipeline = await setAccessRolePipeline(accessMultiPipeline, response.data.data.id, token);
                    if (responseMultiPipeline.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                            heightAuto: false,
                        })
                        closeModalRef.current?.click();
                        setTablesData('roles', ([...tablesData.roles, response.data.data]))
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        const data = multiPipelines.map((pipeline: SettingMultiPipelines) => ({
            value: pipeline.id,
            label: pipeline.name,
        }))

        setOptionMultiPipeline([...allOptionPipeline, ...data])
    }, [multiPipelines])

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#tambah-role-modal"
            >
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.ROLES' })}
            </button>
            <div className="modal fade" tabIndex={-1} id="tambah-role-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.ROLES' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert'>{formik.errors.name}</span>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.HAS_SUBORDINATES' })}</label>
                                    <div>
                                        <Switch
                                            checked={formik.values.has_subordinate}
                                            onChange={(e) => formik.setFieldValue('has_subordinate', e.target.checked)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.EXPORT_EXCEL' })}</label>
                                    <div>
                                        <Switch
                                            checked={formik.values.is_allowed_export_excel}
                                            onChange={(e) => formik.setFieldValue('is_allowed_export_excel', e.target.checked)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.ACTION.SEND' }, { title: intl.formatMessage({ id: 'FORM.LABEL.REPORT' }) })}</label>
                                    <div>
                                        <Switch
                                            checked={formik.values.is_allowed_send_report}
                                            onChange={(e) => formik.setFieldValue('is_allowed_send_report', e.target.checked)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark align-self-center'>HPP</label>
                                    <div>
                                        <Switch
                                            checked={formik.values.is_allowed_access_hpp}
                                            onChange={(e) => formik.setFieldValue('is_allowed_access_hpp', e.target.checked)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                    </div>
                                </div>
                                {
                                    settings.feature_pipeline &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark align-self-center required'>Template {settings.pipeline_title}</label>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: "1px solid #E4E6EF",
                                                    backgroundColor: "none",
                                                }),
                                                option: (baseStyles, { isSelected, isFocused }) => ({
                                                    ...baseStyles,
                                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                    cursor: 'pointer'
                                                }),
                                                indicatorSeparator: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    display: 'none',
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: 'none',
                                                },
                                            })}
                                            isMulti
                                            onChange={(selectedOptions) => {
                                                var endIndex = selectedOptions.length - 1
                                                if (selectedOptions.length > 0 && selectedOptions[endIndex].value === 'all') {
                                                    formik.setFieldValue('accessMultiPipeline', allOptionPipeline)
                                                } else {
                                                    formik.setFieldValue('accessMultiPipeline', selectedOptions.filter(item => item.value !== 'all'))
                                                }
                                            }}
                                            onBlur={
                                                () => formik.values.accessMultiPipeline.length === 0 && formik.setFieldTouched("accessMultiPipeline", true)
                                            }
                                            value={formik.values.accessMultiPipeline as any}
                                            className='react-select-styled z-index-3 mb-3'
                                            classNamePrefix='react-select'
                                            options={optionMultiPipeline}
                                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} Template ${settings.pipeline_title}`}
                                        />
                                        {formik.touched.accessMultiPipeline && formik.errors.accessMultiPipeline && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.accessMultiPipeline}</span>
                                            </div>
                                        )}
                                    </>
                                }

                                <div className="accordion" id="accordionRole">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordionHeaderPipeline">
                                            <button className="accordion-button fs-6 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBodyPipeline" aria-expanded="false" aria-controls="accordionBodyPipeline">
                                                {settings.pipeline_title}
                                            </button>
                                        </h2>
                                        <div id="accordionBodyPipeline" className="accordion-collapse collapse" aria-labelledby="accordionHeaderPipeline" data-bs-parent="#accordionRole">
                                            <div className="accordion-body">
                                                <div className="row mb-3">
                                                    <div className="col col-form-label d-flex justify-content-between align-items-center">
                                                        {intl.formatMessage({ id: "ROLES.CREATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="pipeline-create-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.CREATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.CREATE" }).toLowerCase()} ${settings.pipeline_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.NONE" })} ${settings.pipeline_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PERSONAL" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.TEAM" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.ALL" }, { title: settings.pipeline_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="pipeline-create-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_pipeline_create)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_pipeline_create",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label d-flex justify-content-between align-items-center">
                                                        {intl.formatMessage({ id: "ROLES.READ" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="pipeline-view-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.READ" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.READ" }).toLowerCase()} ${settings.pipeline_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.NONE" })} ${settings.pipeline_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.PERSONAL" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.TEAM" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.ALL" }, { title: settings.pipeline_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="pipeline-view-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_pipeline_read)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_pipeline_read",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.UPDATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="pipeline-update-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.UPDATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.UPDATE" }).toLowerCase()} ${settings.pipeline_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.NONE" })} ${settings.pipeline_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.PERSONAL" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.TEAM" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.ALL" }, { title: settings.pipeline_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="pipeline-update-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_pipeline_update)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_pipeline_update",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.DELETE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="pipeline-delete-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.DELETE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.DELETE" }).toLowerCase()} ${settings.pipeline_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.NONE" })} ${settings.pipeline_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.PERSONAL" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.TEAM" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.ALL" }, { title: settings.pipeline_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="pipeline-delete-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_pipeline_delete)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_pipeline_delete",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.CREATE_LOGS" }, { title: settings.pipeline_title })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="pipeline-pipeline-create-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.CREATE_LOGS" }, { title: settings.pipeline_title })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.CREATE" }).toLowerCase()} log ${settings.pipeline_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PIPELINE_LOGS.NONE" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PIPELINE_LOGS.PERSONAL" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PIPELINE_LOGS.TEAM" }, { title: settings.pipeline_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PIPELINE_LOGS.ALL" }, { title: settings.pipeline_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="pipeline-pipeline-create-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_pipeline_log_create)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_pipeline_log_create",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordionHeaderMeeting">
                                            <button className="accordion-button fs-6 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBodyMeeting" aria-expanded="false" aria-controls="accordionBodyMeeting">
                                                {settings.meeting_title ?? intl.formatMessage({ id: "MENU.MEETINGS" })}
                                            </button>
                                        </h2>
                                        <div id="accordionBodyMeeting" className="accordion-collapse collapse" aria-labelledby="accordionHeaderMeeting" data-bs-parent="#accordionRole">
                                            <div className="accordion-body">
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.CREATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="meeting-create-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.CREATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.CREATE" }).toLowerCase()} ${settings.meeting_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.NONE" })} ${(settings.meeting_title).toLowerCase()}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PERSONAL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.TEAM" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.ALL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="meeting-create-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_meeting_create)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_meeting_create",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.READ" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="meeting-view-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.READ" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.READ" }).toLowerCase()} ${settings.meeting_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.NONE" })} ${(settings.meeting_title).toLowerCase()}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.PERSONAL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.TEAM" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.ALL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="meeting-view-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_meeting_read)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_meeting_read",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.UPDATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="meeting-update-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.UPDATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.UPDATE" }).toLowerCase()} ${settings.meeting_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.NONE" })} ${(settings.meeting_title).toLowerCase()}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.PERSONAL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.TEAM" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.ALL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="meeting-update-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_meeting_update)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_meeting_update",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.DELETE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="meeting-delete-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.DELETE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.DELETE" }).toLowerCase()} ${settings.meeting_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.NONE" })} ${(settings.meeting_title).toLowerCase()}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.PERSONAL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.TEAM" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.ALL" }, { title: (settings.meeting_title).toLowerCase() })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="meeting-delete-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_meeting_delete)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_meeting_delete",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordionHeaderMerchant">
                                            <button className="accordion-button fs-6 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBodyMerchant" aria-expanded="false" aria-controls="accordionBodyMerchant">
                                                {settings.merchant_title}
                                            </button>
                                        </h2>
                                        <div id="accordionBodyMerchant" className="accordion-collapse collapse" aria-labelledby="accordionHeaderMerchant" data-bs-parent="#accordionRole">
                                            <div className="accordion-body">
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.CREATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="merchant-create-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.CREATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.CREATE" }).toLowerCase()} ${settings.merchant_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.NONE" })} ${settings.merchant_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.PERSONAL" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.TEAM" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.CREATE.ALL" }, { title: settings.merchant_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="merchant-create-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_merchant_create)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_merchant_create",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.READ" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="merchant-view-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.READ" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.READ" }).toLowerCase()} ${settings.merchant_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.NONE" })} ${settings.merchant_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.PERSONAL" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.TEAM" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.READ.ALL" }, { title: settings.merchant_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="merchant-view-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_merchant_read)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_merchant_read",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.UPDATE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="merchant-update-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.UPDATE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.UPDATE" }).toLowerCase()} ${settings.merchant_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.NONE" })} ${settings.merchant_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.PERSONAL" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.TEAM" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.UPDATE.ALL" }, { title: settings.merchant_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="merchant-update-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_merchant_update)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_merchant_update",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.DELETE" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="merchant-delete-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.DELETE" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.DELETE" }).toLowerCase()} ${settings.merchant_title}.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.NONE" })} ${settings.merchant_title}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.PERSONAL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.PERSONAL" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.TEAM" }, { title: settings.merchant_title })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.DELETE.ALL" }, { title: settings.merchant_title })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="merchant-delete-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionFull.find(option => option.value === formik.values.permission_merchant_delete)}
                                                            options={optionFull}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_merchant_delete",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="accordionHeaderTarget">
                                            <button className="accordion-button fs-6 fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordionBodyTarget" aria-expanded="false" aria-controls="accordionBodyTarget">
                                                {intl.formatMessage({ id: "MENU.TARGETS" })}
                                            </button>
                                        </h2>
                                        <div id="accordionBodyTarget" className="accordion-collapse collapse" aria-labelledby="accordionHeaderTarget" data-bs-parent="#accordionRole">
                                            <div className="accordion-body">
                                                <div className="row mb-3">
                                                    <div className="col col-form-label">
                                                        {intl.formatMessage({ id: "ROLES.SET" })}
                                                        <i
                                                            className="fas fa-info-circle float-end"
                                                            data-tooltip-id="target-set-tooltip"
                                                            data-tooltip-html={`
                                                            <div>
                                                                <h3>
                                                                    <strong><u>${intl.formatMessage({ id: "ROLES.SET" })}</u></strong>
                                                                </h3>
                                                                <p>
                                                                    ${intl.formatMessage({ id: "ROLES.PERMISSION.FOR" })} ${intl.formatMessage({ id: "ROLES.PERMISSION.SET" }).toLowerCase()} target.
                                                                </p>
                                                                <h4>${intl.formatMessage({ id: "ROLES.PERMISSION.HEADER" })}</h4>
                                                                <ul>
                                                                <li>${intl.formatMessage({ id: "ROLES.NONE" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.NONE" })} ${intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase()}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.TEAM" }, { title: intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.TEAM_EXCEPT_SELF" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.TEAM_EXCEPT_SELF" }, { title: intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.WHOLE_TEAM" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.WHOLE_TEAM" }, { title: intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.WHOLE_TEAM_EXCEPT_SELF" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.WHOLE_TEAM_EXCEPT_SELF" }, { title: intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase() })}.</li>
                                                                <li>${intl.formatMessage({ id: "ROLES.ALL" })} - ${intl.formatMessage({ id: "ROLES.EXPLANATION.SET.ALL" }, { title: intl.formatMessage({ id: "MENU.TARGETS" }).toLowerCase() })}.</li>
                                                                </ul>
                                                            </div>`}
                                                            data-tooltip-place="top"
                                                            data-tooltip-variant="light"
                                                        />

                                                        <Tooltip id="target-set-tooltip" style={{ zIndex: 100 }} border={'1px solid black'} />
                                                    </div>
                                                    <div className="col">
                                                        <Select
                                                            value={optionTarget.find(option => option.value === formik.values.permission_user_target_set)}
                                                            options={optionTarget}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: "permission_user_target_set",
                                                                        value: option?.value
                                                                    }
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddRole }