import React, { useRef, useEffect, useState } from 'react';
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps"
import { KTSVG } from '../../_metronic/helpers';
import { AttendanceTemplate, MyAttendance } from '../../interfaces/Attendance';
import { DatePicker } from 'rsuite';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import TimeIcon from '@rsuite/icons/Time';
import useHandleCloseModal from '../../hooks/HandleCloseModal';
import { useIntl } from 'react-intl';

interface NoteModalProps {
    changeAllHours: (hourStart: string, hourEnd: string) => void;
}

const UpdateWorkHours: React.FC<NoteModalProps> = ({ changeAllHours }) => {
    const dialogRef = useRef<HTMLDialogElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            hourStart: '',
            hourEnd: ''
        },
        validationSchema: Yup.object().shape({
            hourStart: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.START_HOUR' }) })),
            hourEnd: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.END_HOUR' }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                changeAllHours(values.hourStart, values.hourEnd)
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                    heightAuto: false,
                });
                closeModalRef.current?.click();
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: intl.formatMessage({ id: "FORM.VALIDATION.TRY_AGAIN" }),
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`update-work-hours-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ lineBreak: 'anywhere' }}>{intl.formatMessage({ id: "FORM.LABEL.MASS_UPDATE_WORK_HOURS" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-lg-5 col-xl-5">
                                        <DatePicker
                                            editable={false}
                                            format="HH:mm"
                                            value={formik.values.hourStart ? new Date(formik.values.hourStart) : null}
                                            style={{ width: "100%", zIndex: 0 }}
                                            container={document.querySelector('#update-work-hours-modal') as HTMLElement}
                                            onChange={(value: any) => {
                                                if (value) formik.setFieldValue(`hourStart`, value)
                                                else formik.setFieldValue(`hourStart`, null)
                                            }}
                                            caretAs={TimeIcon}
                                        />
                                        {formik.touched.hourStart && formik.errors.hourStart && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.hourStart}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-xl-2 col-lg-2 text-center col-form-label fw-normal">{intl.formatMessage({ id: "FORM.LABEL.TO" }).toLowerCase()}</div>
                                    <div className="col-xl-5 col-lg-5">
                                        <DatePicker
                                            editable={false}
                                            format="HH:mm"
                                            value={formik.values.hourEnd ? new Date(formik.values.hourEnd) : null}
                                            style={{ width: "100%", zIndex: 0 }}
                                            container={document.querySelector('#update-work-hours-modal') as HTMLElement}
                                            onChange={(value: any) => {
                                                if (value) formik.setFieldValue(`hourEnd`, value)
                                                else formik.setFieldValue(`hourEnd`, null)
                                            }}
                                            caretAs={TimeIcon}
                                        />
                                        {formik.touched.hourEnd && formik.errors.hourEnd && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.hourEnd}</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { UpdateWorkHours }