import {  IVarDocumentationProps, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationAuthProps = ({intl}:IVarDocumentationProps )=>{    
    // ===================== SEND OTP =====================
    const sendOTPResponse = {
        data: {
            userPhoneCensored: "**********417",
        },
        success: true,
        message: 'Berhasil mengirim OTP'
    }

    const sendOTPBody: RequestBody[] = [
        {
            field: 'username',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_USERNAME" })
        },
        {
            field: 'password',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_PASSWORD" })
        },
    ]

    const python_send_otp = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/user-send-otp"
    username = "tes-username"
    password = "pass123"

    payload = json.dumps({
    "username": username,
    "password": password,
    })

    response = requests.request("POST", reqUrl, data=payload)

    print(response.text)
    `

    const javascript_send_otp = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/user-send-otp";
    const username = "tes-username";
    const password = "pass123";

    const payload = {
    "username": username,
    "password": password,
    };

    axios.post(reqUrl, payload)
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_send_otp = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/user-send-otp";
    $username = "tes-username";
    $password = "pass123";

    $data = [
        "username" => $username,
        "password" => $password,
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_send_otp = `
    username="tes-username"
    password="pass123"

    curl -X POST "https://crm-api.wooblazz.com/user-send-otp"
    -d '{
    "username": "'"$username"'",
    "password": "'"$password"'"
    }'
    `


    // ===================== LOGIN =====================
    const loginResponse = {
        data: {
            token: "454fd8c5-87cd-4617-8cba-ced92ad48faa",
            firebaseToken: null,
            date_activated: "2023-11-15 14:44:26"
        },
        success: true,
        message: 'Berhasil login'
    }

    const loginBody: RequestBody[] = [
        {
            field: 'username',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_USERNAME" })
        },
        {
            field: 'password',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_PASSWORD" })
        },
        {
            field: 'otp',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_OTP" }),
            target: 'sendOTP',
            target_name: intl.formatMessage({ id: "OPEN_API.SEND_OTP_LOGIN" })
        },
        {
            field: 'device',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_DEVICE" })
        },
    ]

    const python_login = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/user-login"
    username = "tes-username"
    password = "pass123"
    device = "Google Chrome (Windows)"
    otp = "1234"

    payload = json.dumps({
    "username": username,
    "password": password,
    "device": device,
    "otp": otp,
    })

    response = requests.request("POST", reqUrl, data=payload)

    print(response.text)
    `

    const javascript_login = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/user-login";
    const username = "tes-username";
    const password = "pass123";
    const device = "Google Chrome (Windows)";
    const otp = "1234";

    const payload = {
    "username": username,
    "password": password,
    "device": device,
    "otp": otp,
    };

    axios.post(reqUrl, payload)
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_login = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/user-login";
    $username = "tes-username";
    $password = "pass123";
    $device = "Google Chrome (Windows)";
    $otp = "1234";

    $data = [
        "username" => $username,
        "password" => $password,
        "device" => $device,
        "otp" => $otp,
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_login = `
    username = "tes-username"
    password = "pass123"
    device = "Google Chrome (Windows)"
    otp = "1234"

    curl -X POST "https://crm-api.wooblazz.com/user-login"
    -d '{
    "username": "'"$username"'",
    "password": "'"$password"'",
    "device": "'"$device"'",
    "otp": "'"$otp"'",
    }'
    `

    return {sendOTPResponse,sendOTPBody,python_send_otp,javascript_send_otp,php_send_otp, bash_send_otp,loginResponse,loginBody, python_login, javascript_login,php_login, bash_login}
}