import { UserModel } from "../../app/modules/auth/models/UserModel";
import { exportWorkbook, setColumnWidths } from "./helperTemplateExcel";
import * as ExcelJS from 'exceljs';
import { addWorkSheetInfo, createWorksheet, styleColumns } from "./helperTemplateExcel";
import { createWorkbook } from "./helperTemplateExcel";

interface ExportTemplateProductExcelProps {
    nameFile: string,
    nameInfo: string,
    nameHeader: any,
    source: string,
    typeAction: string,
    user: UserModel,
    param: any,
    type: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const exportTemplateProductExcel = async ({ nameFile, nameInfo, nameHeader, source, typeAction, user, param, type, setIsLoading }: ExportTemplateProductExcelProps) => {
    let exportData: any[] = [];
    const workbook = createWorkbook();
    const worksheet = createWorksheet(workbook, "Sheet1");
    const worksheetInfo = createWorksheet(workbook, "Info");
    var header: string[] = []
    var subHeader: string[] = []

    // Data Label
    let worksheetLabel = workbook.addWorksheet('Daftar Label');
    await worksheetLabel.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });
    worksheetLabel.getCell('A1').value = `Daftar Label`;
    await param.dataLabels.forEach((user: any) => {
        worksheetLabel.addRow([user.name]);
    });

    // Data Satuan
    let worksheetUnit = workbook.addWorksheet('Daftar Satuan');
    await worksheetUnit.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });
    worksheetUnit.getCell('A1').value = `Daftar Satuan`;
    await param.dataUnits.forEach((user: any) => {
        worksheetUnit.addRow([user.name]);
    });

    // Input Label Produk
    let worksheetProductLabel = workbook.addWorksheet('Label Produk (Opsional)');
    worksheetProductLabel.getRow(1).values = [...nameHeader.insert.productLabel.header];
    worksheetProductLabel.getRow(2).values = [...nameHeader.insert.productLabel.subHeader];
    setColumnWidths(worksheetProductLabel, source, [...nameHeader.insert.productLabel.header], exportData);
    styleColumns(worksheetProductLabel, source, 1);
    styleColumns(worksheetProductLabel, source, 2);

    // Input Unit Produk
    let worksheetProductUnit = workbook.addWorksheet('Satuan Produk (Opsional)');
    worksheetProductUnit.getRow(1).values = [...nameHeader.insert.productUnit.header];
    worksheetProductUnit.getRow(2).values = [...nameHeader.insert.productUnit.subHeader];
    setColumnWidths(worksheetProductUnit, source, [...nameHeader.insert.productUnit.header], exportData);
    styleColumns(worksheetProductUnit, source, 1);
    styleColumns(worksheetProductUnit, source, 2);

    if (typeAction === 'insert') {
        addDataRows({ worksheet: worksheetProductLabel, data: [], source, type, typeAction, user, param });
        addDataRows({ worksheet: worksheetProductUnit, data: [], source, type, typeAction, user, param });

        header = [...nameHeader.insert.product.header];
        subHeader = [...nameHeader.insert.product.subHeader];

        if (type === 'error') {
            header.push('Error');
            subHeader.push(nameHeader.error);
            Array.from(param.listErrorInsert).map((row: any, index) => {
                var temp_excel = [];

                temp_excel.push(row.name, row.sku, row.note, row.error);

                exportData.push(temp_excel);
            });
        }
    } else if (typeAction === 'update') {
        await worksheet.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
            formatColumns: true,
            autoFilter: true,
        });
        await worksheetProductLabel.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
            formatColumns: true,
            autoFilter: true,
        });
        await worksheetProductUnit.protect('mjioerh3uiqWHEIOPHQWIOPEJHQWIO', {
            formatColumns: true,
            autoFilter: true,
        });

        header = [...nameHeader.update.product.header];
        subHeader = [...nameHeader.update.product.subHeader];

        if (type === 'error') {
            header.push('Error');
            subHeader.push(nameHeader.error);
            Array.from(param.listErrorUpdate).map((row: any, index) => {
                var temp_excel = [];

                temp_excel.push(
                    btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
                    row.name,
                    row.price,
                    row.vatPercentage,
                    row.sku,
                    row.note,
                    row.error,
                );

                exportData.push(temp_excel);
            });
        } else {
            let productLabelsData: any[] = [];
            let productUnitsData: any[] = [];

            param.dataTables.map((row: any) => {
                if (row.sku) {
                    var temp_excel = [];

                    temp_excel.push(
                        btoa(row.id.slice(0, 3) + Math.floor(100 + Math.random() * 900) + row.id.slice(3)),
                        row.name,
                        row.price,
                        row.vatPercentage,
                        row.sku,
                        row.note,
                    );

                    exportData.push(temp_excel);

                    if (row.productLabels.length > 0) {
                        row.productLabels.map((l: any) => {
                            productLabelsData.push([{ formula: `Sheet1!C${exportData.length + 2}` }, l.label.name]);
                        });
                    }

                    if (row.productUnits.length > 0) {
                        row.productUnits.map((u: any) => {
                            productUnitsData.push([
                                { formula: `Sheet1!C${exportData.length + 2}` },
                                u.unit.name,
                                u.convertionToPieces,
                            ]);
                        });
                    }
                }
            });

            addDataRows({ worksheet: worksheetProductLabel, data: productLabelsData, source, type, typeAction, user, param });
            addDataRows({ worksheet: worksheetProductUnit, data: productUnitsData, source, type, typeAction, user, param });
        }
    }

    worksheet.getRow(1).values = header;
    worksheet.getRow(2).values = subHeader;
    setColumnWidths(worksheet, source, header!, exportData);
    styleColumns(worksheet, source, 1);
    styleColumns(worksheet, source, 2);
    addDataRows({ worksheet, data: exportData, source, type, typeAction, user, param });
    addWorkSheetInfo(worksheetInfo, source, nameFile, nameInfo, nameHeader, user!);

    exportWorkbook(workbook, worksheet, source, `${nameFile}.xlsx`, setIsLoading);
}

interface AddDataRowsProps {
    worksheet: ExcelJS.Worksheet,
    data: any[],
    source: string,
    type: string,
    typeAction: string,
    user: UserModel,
    param: any
}

const addDataRows = ({ worksheet, data, source, type, typeAction, user, param }: AddDataRowsProps) => {
    data.forEach((rowData, index) => {
        worksheet.addRow(rowData);
    });

    if (worksheet.name === 'Sheet1') {
        const priceColumn = worksheet.getColumn(typeAction === 'insert' ? 2 : 3);
        priceColumn.numFmt = '#,##0;-#,##0';

        for (let i = 3; i <= 500; i++) {
            worksheet.getCell(`${typeAction === 'insert' ? 'C' : 'C'}${i}`).numFmt = '#,##0;-#,##0';
        }
    }

    if (worksheet.name === 'Label Produk (Opsional)') {
        for (let i = 3; i <= 500; i++) {
            worksheet.getCell('A' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [
                    `='Sheet1'!$${typeAction === 'insert' ? 'D' : 'E'}$3:$${typeAction === 'insert' ? 'D' : 'E'}$${500 + 3
                    }`,
                ],
                errorStyle: 'error',
                error: 'Pilih SKU',
            };

            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Label'!$A$2:$A$${param.dataLabels.length + 2}`],
                errorStyle: 'error',
                error: 'Pilih label',
            };
        }
    } else if (worksheet.name === 'Satuan Produk (Opsional)') {
        for (let i = 3; i <= 500; i++) {
            worksheet.getCell('A' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [
                    `='Sheet1'!$${typeAction === 'insert' ? 'D' : 'E'}$3:$${typeAction === 'insert' ? 'D' : 'E'}$${500 + 3
                    }`,
                ],
                errorStyle: 'error',
                error: 'Pilih SKU',
            };

            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: false,
                showErrorMessage: true,
                formulae: [`='Daftar Satuan'!$A$2:$A$${param.dataUnits.length + 2}`],
                errorStyle: 'error',
                error: 'Pilih satuan',
            };
        }
    }

    if (typeAction === 'update') {
        if (worksheet.name === 'Sheet1') {
            var dataLength = type === 'error' ? param.listErrorUpdate.length : param.dataTables.length;

            worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
                if (rowIndex > 2 && rowIndex <= dataLength + 2) {
                    const cell2 = row.getCell(2);
                    const cell3 = row.getCell(3);
                    const cell4 = row.getCell(4);
                    const cell5 = row.getCell(5);
                    const cell6 = row.getCell(6);

                    cell3.numFmt = '#,##0';
                    cell2.protection = { locked: false };
                    cell3.protection = { locked: false };
                    cell4.protection = { locked: false };
                    cell5.protection = { locked: false };
                    cell6.protection = { locked: false };
                }
            });

        } else if (
            worksheet.name === 'Label Produk (Opsional)' ||
            worksheet.name === 'Satuan Produk (Opsional)'
        ) {
            // worksheet.eachRow({ includeEmpty: true }, function (row, rowIndex) {
            for (let i = 3; i <= 500; i++) {
                const cell1 = worksheet.getCell('A' + i);
                const cell2 = worksheet.getCell('B' + i);

                cell1.protection = { locked: false };
                cell2.protection = { locked: false };

                if (worksheet.name === 'Satuan Produk (Opsional)') {
                    const cell3 = worksheet.getCell('C' + i);
                    cell3.protection = { locked: false };
                }
            }
        }
    }

}