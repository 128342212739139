import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { ListProduct } from '../../interfaces/Pipeline';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { Merchant } from '../../interfaces/Merchant';
import 'moment-duration-format';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { generateLabel } from './exportExcelUtil';
import { sortColumnDatatable } from '../general';

const formatCustomField = (source: string, row: any, field: string, type: string) => {
  if (!row.customFields || !row.customFields[field] || row.customFields[field].length === 0) return '-';

  const value = (row as any)[`custom_fields.${field}`];
  if (type === 'invoice_reminder') {
    const parsedValue = JSON.parse(value);
    return [
      parsedValue.invoice_url,
      parsedValue.date_due ? moment(parsedValue.date_due).format('DD MMM YYYY') : '-',
    ];
  }
  if (type === 'date') return value ? moment(value).format('DD MMM YYYY') : '-';
  if (type === 'multiple') return value.toString().split(',').join(', ');
  if (type === 'images' || type === 'files') return value.toString().split(',').join('\n ');
  if (type === 'serial') return value.join('');

  return value;
};

const formatLeadProductDetails = (leadProducts: ListProduct[], detailType: keyof ListProduct) => {
  const details = leadProducts.map((product) => product[detailType]);
  return details.length > 0 ? details.join('\n') : '-';
};

const getStatus = (status: string) => {
  switch (status) {
    case 'hot':
      return '(Hot)';
    case 'cold':
      return '(Cold)';
    default:
      return '';
  }
};

const styleColumns = (
  worksheet: ExcelJS.Worksheet,
  source: string,
  param: any,
  columns: TableColumn<Merchant>[],
  indexRow: number,
  user?: UserModel,
  isFilterDate?: boolean
) => {
  worksheet.getRow(isFilterDate ? 4 : indexRow).alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true,
  };
  worksheet.getRow(isFilterDate ? 4 : indexRow).eachCell(function (cell) {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'F6991D',
      },
    };
  });
  worksheet.getRow(isFilterDate ? 4 : indexRow).font = {
    name: 'Arial Black',
    size: 10,
    bold: true,
  };

  var endRow = worksheet.actualRowCount;
  if (source === 'Pipeline') {
    var index = (user?.data.company_name === "Heiszco") ? 12 : (user?.data.company_id === param.companyId.enterpriseSales || user?.data.company_id === param.companyId.enterpriseService) ? 10 : 9
    if (!columns[index].omit) {
      worksheet.getRow(endRow).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
      };
    }
  }

  if (source === 'Report Logs Pipeline') {
    worksheet.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getRow(1).font = {
      name: 'Arial Black',
      size: 12,
      bold: true,
    };
    worksheet.getRow(2).alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
    worksheet.getRow(2).font = {
      name: 'Arial Black',
      size: 12,
      bold: true,
    };
  }
};

const exportWorkbook = async (workbook: ExcelJS.Workbook, source: string, fileName: string) => {
  if (source === 'Report Logs Pipeline') {
    const report = await workbook.xlsx.writeBuffer().then((buffer) => {
      // Konversi buffer ke blob
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const excelFile = new File([blob], `${fileName}.xlsx`, { type: blob.type, lastModified: Date.now() });
      return excelFile;
    });
    return report;
  } else {
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
  }
};

export const exportExcelMerchant = async (
  nameFile: string,
  source: string,
  nameHeader: {
    [key: string]: string[];
  },
  param: any,
  selectMultiMerchant: any,
  data?: any[],
  columns?: TableColumn<Merchant>[],
  user?: UserModel,
  dateStart?: any,
  dateEnd?: string,
  isFilterDate?: boolean,
) => {
  let exportData: any[] = [];

  var arrayCustomFieldMerchants = Object.keys(selectMultiMerchant).length !== 0
    ? selectMultiMerchant.customFields
    : [];

  let comapnyCodeIndex = -1;
  let comapnyNameIndex = -1;
  let nameIndex1 = -1;
  let nameIndex2 = -1;

  const workbook = createWorkbook();
  const worksheet = createWorksheet(workbook, source);


  if (source === 'Merchant') {
    if (isFilterDate && dateStart && dateEnd) {
      addFileInformationDateRange(worksheet, dateStart, dateEnd);
    }

    var header = [...nameHeader.merchant, ...Object.keys(arrayCustomFieldMerchants).map((field: any) => arrayCustomFieldMerchants[field].name)]

    if (user!.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1') {
      comapnyCodeIndex = header.map((item) => item.toLowerCase()).indexOf('company code');
      nameIndex1 = header.findIndex(col => col.toLowerCase() === 'name' || col.toLowerCase() === 'nama');
      comapnyCodeIndex !== -1 && sortColumnDatatable(header, comapnyCodeIndex, nameIndex1);
      
      comapnyNameIndex = header.map((item) => item.toLowerCase()).indexOf('company name');
      nameIndex2 = header.findIndex(col => col.toLowerCase() === 'name' || col.toLowerCase() === 'nama');
      comapnyNameIndex !== -1 && sortColumnDatatable(header, comapnyNameIndex, nameIndex2);
    }

    worksheet.getRow(isFilterDate ? 4 : 1).values = header

    Array.from(data!).map((row, index) => {
      var temp_excel = [];
      temp_excel.push(
        row.name,
        row.ownerName,
        generateLabel(row),
        row.cityName,
        row.phone,
        row.isWhatsappPhone ? 'Valid' : 'Tidak Valid',
        row.email,
        row.lastMeetEnd,
        row.lastLeadCreatedTime,
        row.leadsCount,
        row.meetingsCount,
        row.creatorName,
        moment(row.dateCreated).format('DD MMM YYYY HH:mm')
      );

      if (selectMultiMerchant.customFields) {
        Object.keys(arrayCustomFieldMerchants).forEach((field) => {
          const type = arrayCustomFieldMerchants[field].type;
          const formattedField = formatCustomField(source, row, field, type);

          if (type === 'invoice_reminder') {
            temp_excel.push(...formattedField);
          } else {
            temp_excel.push(formattedField);
          }
        });
      }

      if (user!.data.company_id === '7763fccc-af52-43e1-8907-4678ab2423d1' && comapnyCodeIndex !== -1 && comapnyNameIndex !== -1) {
        sortColumnDatatable(temp_excel, comapnyCodeIndex, nameIndex1);
        sortColumnDatatable(temp_excel, comapnyNameIndex, nameIndex2);
      }

      exportData.push(temp_excel);
    });

    addDataRows(worksheet, exportData, source, param, header, columns!);
    setColumnWidths(worksheet, source, arrayCustomFieldMerchants, exportData);
    styleColumns(worksheet, source, param, columns!, 1, undefined, isFilterDate);
  }


  exportWorkbook(workbook, source, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Looyal';
  workbook.lastModifiedBy = 'Looyal';
  workbook.created = new Date();
  workbook.modified = new Date();
  return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, source: string, sheetName: string = 'Sheet1') => {
  let worksheet;
  worksheet = workbook.addWorksheet(sheetName);
  return worksheet;
};

const addDataRows = (
  worksheet: ExcelJS.Worksheet,
  data: any[],
  source: string,
  param: any,
  header: string[],
  columns: TableColumn<Merchant>[],
  user?: UserModel,
  startRow?: number
) => {
  if (source === 'Merchant') {
    // Set Data Body
    data.forEach((rowData, index) => {
      const row = worksheet.addRow(rowData);
      // Adjust cell styling if needed
      row.eachCell((cell, colIndex) => {
        cell.alignment = {
          vertical: 'top',
          wrapText: true,
          shrinkToFit: true,
        };
      });
    });

    // Dynamic column
    const columnIndicesToRemove: number[] = [];
    if (user?.data.company_id === "7763fccc-af52-43e1-8907-4678ab2423d1") columnIndicesToRemove.push(3)

    // Cari semua indeks kolom yang perlu dihapus
    header.forEach((item, headerIndex) => {
      columns.forEach((column) => {
        if (column.omit && item === column.name) {
          columnIndicesToRemove.push(headerIndex + 1); // +1 untuk 1-based index
        }
      });
    });

    // Hapus kolom dari worksheet berdasarkan indeks yang ditemukan
    columnIndicesToRemove.sort((a, b) => b - a).forEach((index) => {
      worksheet.spliceColumns(index, 1);
    });
  } else {
    // Set Data Body
    data.forEach((rowData, index) => {
      worksheet.addRow(rowData);
    });
  }
};
const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: any[], data: any) => {
  if (source === 'Pipeline' || source === 'Merchant') {
    const maxColumnWidths: number[] = [];
    data.forEach((row: any) => {
      row.forEach((cell: any, columnIndex: number) => {
        const cellLength = String(cell).length;
        if (!maxColumnWidths[columnIndex] || cellLength > maxColumnWidths[columnIndex]) {
          maxColumnWidths[columnIndex] = cellLength;
        }
      });
    });

    // Atur lebar kolom berdasarkan panjang teks terpanjang
    maxColumnWidths.forEach((width, columnIndex) => {
      worksheet.getColumn(columnIndex + 1).width = width < 20 ? 20 : width + 2 > 40 ? 40 : width + 2; // Tambahkan sedikit padding
    });

    const colWidths = [20, 30, 30, 20, 20, 30, 30, 20, 20, 20, 20, 20, 20, 20, 20, 20, ...Object.keys(fields).map((field) => 25)];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'Merchant') {
    const colWidths = [30, 30, 20, 20, 20, 30, 30, 30, 30, ...Object.keys(fields).map((field) => 25)];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'Meeting') {
    const colWidths = [30, 30, 20, 20, 20, 30, 30, 30, 30, 30, ...Object.keys(fields).map((field) => 25)];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'Report Logs Pipeline') {
    const colWidths = [20, 20, 20, 20, 20, 20];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'success-validation-phone') {
    const colWidths = [30, 20, 30, 20, 50];
    worksheet.columns = colWidths.map((width) => ({ width }));
  } else if (source === 'template-validation-phone') {
    const colWidths = [
      { width: 30, style: '@' },
      { width: 30, style: '@' },
    ];
    worksheet.columns = colWidths.map((data) => ({ width: data.width, style: { numFmt: data.style } }));
  } else if (source === 'Attendance') {
    const colWidths = [25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25];
    worksheet.columns = colWidths.map((width) => ({ width }));
  }
};






const addFileInformationDateRange = (worksheet: ExcelJS.Worksheet, dateStart: string, dateEnd: string) => {
  worksheet.getCell('A1').value = `Filter Tanggal dibuat: ${dateStart} - ${dateEnd}`;
  worksheet.mergeCells('A1:Q1'); // Merge cells to make header span across columns

  worksheet.getCell('A1').alignment = {
    vertical: 'middle',
    horizontal: 'left',
    wrapText: true,
  };
  worksheet.getCell('A1').font = {
    name: 'Arial Black',
    size: 12,
  };

  // Set row height
  worksheet.getRow(1).height = 30;
}

