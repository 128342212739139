import { create } from "zustand";
import { LeadTemplate } from "../components/AccountReceivable/AccountReceivable";


interface ReceivableStateProps {
    selectTemplateIdPipeline: string
    setSelectTemplateIdPipeline: (selectTemplateIdPipeline: string) => void
    selectMultiPipelineCustomField: LeadTemplate | null
    setSelectMultiPipelineCustomField: (selectMultiPipelineCustomField: LeadTemplate | null) => void
}

export const useReceivableStore = create<ReceivableStateProps>((set, getState) => ({
    selectTemplateIdPipeline: '',
    setSelectTemplateIdPipeline: (selectTemplateIdPipeline) => {
        set({ selectTemplateIdPipeline });
    },
    selectMultiPipelineCustomField: null,
    setSelectMultiPipelineCustomField: (selectMultiPipelineCustomField) => {
        set({ selectMultiPipelineCustomField });
    },
}))