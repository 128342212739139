import 'moment/locale/id';
import * as ExcelJS from 'exceljs';
import { TableColumn } from 'react-data-table-component';
import moment from 'moment';
import { style } from '@mui/system';
import 'moment-duration-format';
import addDays from 'date-fns/addDays';
import { DetailMonthlyDashboardWidget, DetailStaticDashboardWidget } from '../../interfaces/Dashboard';

interface Props {
    nameFile: string
    data: any[]
    columns: TableColumn<DetailStaticDashboardWidget>[] | TableColumn<DetailMonthlyDashboardWidget>[]
    dateStart: string
    dateEnd: string
}

export const exportExcelWidgetDashboard = async ({ nameFile, data, columns, dateStart, dateEnd }: Props) => {
    const workbook = createWorkbook();

    const worksheet = createWorksheet(workbook, 'Sheet1');
    worksheet.getCell('A1').value = nameFile;
    worksheet.getCell('A2').value = `${moment(dateStart).format('DD MMM YYYY')} - ${moment(dateEnd).format('DD MMM YYYY')}`;

    if(columns.length === 2) {
        worksheet.mergeCells('A1', 'B1');
        worksheet.mergeCells('A2', 'B2');
    } else if(columns.length === 5) {
        worksheet.mergeCells('A1', 'E1');
        worksheet.mergeCells('A2', 'E2');
    } else if(columns.length === 3) {
        worksheet.mergeCells('A1', 'C1');
        worksheet.mergeCells('A2', 'C2');
    } else {
        worksheet.mergeCells('A1', 'D1');
        worksheet.mergeCells('A2', 'D2');
    }

    worksheet.getRow(4).values = [...columns.map((col: TableColumn<any>) => `${col.name}`)];

    addDataRows(worksheet, data);
    setColumnWidths(worksheet, columns);
    styleColumns(worksheet);
    exportWorkbook(workbook, `${nameFile}.xlsx`);
};

const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
    let worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

const addDataRows = (worksheet: ExcelJS.Worksheet, data: any[],) => {
    data.forEach((rowData, index) => {
        worksheet.addRow(rowData);

        worksheet.getCell(`B${index + 5}`).numFmt = '#,##0;[Red]-#,##0';
        worksheet.getCell(`C${index + 5}`).numFmt = '#,##0;[Red]-#,##0';
        worksheet.getCell(`D${index + 5}`).numFmt = '#,##0;[Red]-#,##0';
    });

};

const setColumnWidths = (worksheet: ExcelJS.Worksheet, columns: TableColumn<DetailStaticDashboardWidget>[] | TableColumn<DetailMonthlyDashboardWidget>[]) => {
    const colWidths: number[] = [];
    columns.map((col: TableColumn<any>) => colWidths.push(25));
    worksheet.columns = colWidths.map((width) => ({ width }));
};

const styleColumns = (worksheet: ExcelJS.Worksheet) => {
    worksheet.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(2).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(4).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheet.getRow(4).eachCell(function (cell) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
                argb: 'F6991D',
            },
        };
    });

    worksheet.getRow(1).font = {
        name: 'Arial Black',
        size: 12,
        bold: true,
    };
    worksheet.getRow(2).font = {
        name: 'Arial Black',
        size: 12,
        bold: true,
    };
    worksheet.getRow(4).font = {
        name: 'Arial Black',
        size: 10,
        bold: true,
    };
};

const exportWorkbook = async (workbook: ExcelJS.Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    });
};
