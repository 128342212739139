import {
    ColumnDef,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    RowData,
    ExpandedState,
    SortingState,
    createColumnHelper,
    ColumnHelper
} from "@tanstack/react-table";
import { FC, ReactNode, useEffect, useState } from "react";
import { DashboardLeadsReportTable } from "../../interfaces/Dashboard";
import useSWR from "swr";
import useAccessToken from "../../hooks/AccessToken";
import axios from "axios";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useIntl } from "react-intl";
import { customNumberFormat } from "../../functions/general";
import moment from 'moment-timezone';
import useUser from "../../hooks/User";

interface Props {
    tableKey: string
    tableColumns: any
    autoReload: boolean
    search?: boolean
    apiURL?: string
    dateStart?: string
    dateEnd?: string
    actions?: ReactNode[]
    customParams?: any
}

const MasterDataTableTanstack: FC<Props> = ({ tableKey, tableColumns, apiURL, autoReload, search = true, dateStart, dateEnd, actions, customParams }) => {
    const BASE_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const intl = useIntl()
    const user = useUser()
    const { tablesData, setTablesData, filteredTablesData, setFilteredTablesData } = useDataTableStore();
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [expandedRows, setExpandedRows] = useState<ExpandedState>({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const fetcher = (url: string) => {
        let params = {}

        if (tableKey === "dashboard-leads-report") {
            params = {
                dateStartedFrom: dateStart,
                dateStartedTo: dateEnd
            }
        }

        return axios.get(url, {
            headers: {
                'X-Auth-token': token
            },
            params
        }).then(res => res.data.data);
    }

    const calculateTotal = (rows: any[], columnId: string) => {
        if(!window.location.pathname.includes('pipeline')) return 0
        return rows?.reduce((total, row) => {
            const value = row.getValue(columnId);
            return total + (value || 0);
        }, 0);
    };

    const { data = [], error, isLoading, isValidating } = useSWR(apiURL ? BASE_URL + apiURL : null, fetcher,
        {
            revalidateOnFocus: autoReload,
            revalidateOnReconnect: autoReload
        }
    )

    useEffect(() => {
        if (!isLoading) {
            if (tableKey === 'dashboard-leads-report') {
                const result: DashboardLeadsReportTable[] = [];

                const calculatePerformance = (obj: any, desc: string, prospectName: string, targetPaid: number, targetProspect: number) => {
                    let total = obj.dropLeadsCount + obj.coldLeadsCount + obj.hotLeadsCount + obj.paidLeadsCount;
                    let lostPercent = (obj.dropLeadsCount / total) * 100;
                    let coldPercent = (obj.coldLeadsCount / total) * 100;
                    let hotPercent = (obj.hotLeadsCount / total) * 100;
                    let paidPercent = (obj.paidLeadsCount / total) * 100;
                    lostPercent = isNaN(lostPercent) ? 0 : lostPercent
                    coldPercent = isNaN(coldPercent) ? 0 : coldPercent
                    hotPercent = isNaN(hotPercent) ? 0 : hotPercent
                    paidPercent = isNaN(paidPercent) ? 0 : paidPercent
                    let totalPercent = lostPercent + coldPercent + hotPercent + paidPercent;

                    let prospectAchievementPercent = (obj.leadsCount / targetProspect) * 100;
                    let paidAchievementPercent = (obj.paidLeadsCount / targetPaid) * 100;

                    return {
                        desc,
                        prospect: prospectName,
                        prospectTarget: targetProspect,
                        prospectAchievementQ: obj.leadsCount,
                        prospectAchievementPercent: isNaN(prospectAchievementPercent) ? 0 : Math.round(prospectAchievementPercent * 100) / 100,
                        paidTarget: targetPaid,
                        paidAchievementQ: obj.paidLeadsCount,
                        paidAchievementPercent: isNaN(paidAchievementPercent) ? 0 : Math.round(paidAchievementPercent * 100) / 100,
                        lostQ: obj.dropLeadsCount,
                        lostPercent: Math.round(lostPercent * 100) / 100,
                        coldQ: obj.coldLeadsCount,
                        coldPercent: Math.round(coldPercent * 100) / 100,
                        hotQ: obj.hotLeadsCount,
                        hotPercent: Math.round(hotPercent * 100) / 100,
                        paidQ: obj.paidLeadsCount,
                        paidPercent: Math.round(paidPercent * 100) / 100,
                        totalQ: total,
                        totalPercent: Math.round(totalPercent * 100) / 100
                    };
                };

                result.push(
                    ...Object.values(data.usersPerformance).map((obj: any) =>
                        calculatePerformance(obj, user.data.is_superadmin ? "Total Cabang" : "Total Sales", obj.ownerName, (obj.ownerName).includes('DUIN-TU') ? 15 : 5, 75)
                    ),
                );

                setTablesData(tableKey, result);
                setFilteredTablesData(tableKey, result);
            } else if (tableKey === 'paid-schedule-termin') {
                let detail = Object.keys(data.dataTermin).map((key: string, index: number) => ({
                    no: index + 1,
                    dpp: Math.ceil(data.dataTermin[key].dpp),
                    keterangan: data.dataTermin[key].keterangan,
                    ppn: Math.ceil(data.dataTermin[key].ppn),
                    tanggalJatuhTempo: moment(data.dataTermin[key].tanggalJatuhTempo).format("DD MMMM YYYY"),
                    tanggalPembayaran: data.dataTermin[key].tanggalPembayaran ? moment(data.dataTermin[key].tanggalPembayaran).format("DD MMMM YYYY") : "-",
                    total: Math.ceil(data.dataTermin[key].total)
                }))

                customParams.setDataPaidScheduleTermin({ ...data, detail })
                setTablesData(tableKey, detail);
                setFilteredTablesData(tableKey, detail);
            }
        }
    }, [data])

    const table = useReactTable({
        data: filteredTablesData[tableKey] || [],
        columns: tableColumns,
        state: {
            sorting,
            globalFilter,
            pagination,
            expanded: expandedRows,
        },
        onSortingChange: setSorting,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onExpandedChange: setExpandedRows,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const totalDpp = calculateTotal(table.getRowModel().rows, 'dpp');
    const totalPPN = calculateTotal(table.getRowModel().rows, 'ppn');
    const totalJumlah = calculateTotal(table.getRowModel().rows, 'total');

    return (
        <div className="card rounded">
            {
                (isLoading || isValidating) &&
                <div className="loading-overlay">
                    <div className="loader"></div>
                </div>
            }
            <div className="card-header border-0">
                {
                    search &&
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <span className="svg-icon svg-icon-1 position-absolute ms-6">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                </svg>
                            </span>
                            <input
                                type="search"
                                className="form-control form-control-solid w-250px ps-14"
                                placeholder={intl.formatMessage({ id: "MASTERS.DATATABLE.SEARCH.PLACEHOLDER" })}
                                value={globalFilter}
                                onChange={(e) => setGlobalFilter(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div className="card-toolbar align-self-center">
                    {
                        actions && actions.map((action, index) => (
                            <div key={index} className="me-5 me-md-3">
                                {action}
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table table-striped align-middle">
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="fw-bolder">
                                    {headerGroup.headers.map((header) => {
                                        const columnRelativeDepth = header.depth - header.column.depth;
                                        if (columnRelativeDepth > 1) {
                                            return null;
                                        }

                                        let rowSpan = 1;
                                        if (header.isPlaceholder) {
                                            const leafs = header.getLeafHeaders();
                                            rowSpan = leafs[leafs.length - 1].depth - header.depth;
                                        }

                                        return (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                rowSpan={rowSpan}
                                                style={{ padding: "1rem", ...(header.column.columnDef.meta as any)?.styleHeader }}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row, rowIndex) => {
                                let rowSpan = 1;

                                if (tableKey === 'dashboard-leads-report') {
                                    const descValue = row.getValue('desc');
                                    if (rowIndex === 0 || descValue !== table.getRowModel().rows[rowIndex - 1].getValue('desc')) {
                                        for (let i = rowIndex + 1; i < table.getRowModel().rows.length; i++) {
                                            if (table.getRowModel().rows[i].getValue('desc') === descValue) {
                                                rowSpan++;
                                            } else {
                                                break;
                                            }
                                        }
                                    } else {
                                        rowSpan = 0;
                                    }
                                }

                                return (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map((cell) => {
                                            const isDescColumn = cell.column.id === 'desc';

                                            if (isDescColumn && rowSpan === 0) return null;

                                            return (
                                                <td
                                                    key={cell.id}
                                                    rowSpan={isDescColumn ? rowSpan : 1}
                                                    style={{ padding: "1rem", ...(cell.column.columnDef.meta as any)?.styleBody }}
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-end align-items-center flex-wrap pt-3 pb-10">
                    <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-5">
                            <select
                                className="form-select form-select-sm font-weight-bold mr-4 border-0 bg-light"
                                style={{ width: "75px" }}
                                value={table.getState().pagination.pageSize}
                                onChange={(e) => {
                                    table.setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        {pageSize}
                                    </option>
                                ))}
                            </select>
                            <div className="fs-7 text-gray-700">
                                {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1} -{" "}
                                {Math.min(
                                    (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                                    table.getFilteredRowModel().rows.length
                                )}{" "}
                                of {table.getFilteredRowModel().rows.length}
                            </div>
                        </div>
                        <nav>
                            <ul className="pagination mb-0">
                                <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => table.setPageIndex(0)}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <i className="fas fa-angle-double-left"></i>
                                    </button>
                                </li>
                                <li className={`page-item ${!table.getCanPreviousPage() ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => table.previousPage()}
                                        disabled={!table.getCanPreviousPage()}
                                    >
                                        <i className="fas fa-angle-left"></i>
                                    </button>
                                </li>
                                <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => table.nextPage()}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <i className="fas fa-angle-right"></i>
                                    </button>
                                </li>
                                <li className={`page-item ${!table.getCanNextPage() ? "disabled" : ""}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                                        disabled={!table.getCanNextPage()}
                                    >
                                        <i className="fas fa-angle-double-right"></i>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {
                    totalDpp > 0 && (
                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                            <div className="d-flex align-items-center gap-3 fw-bolder fs-5">
                                <span>Total DPP :</span>
                                <span>Rp. {customNumberFormat(totalDpp)}</span>
                            </div>
                        </div>
                    )
                }
                {
                    totalPPN > 0 && (
                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                            <div className="d-flex align-items-center gap-3 fw-bolder fs-5">
                                <span>Total PPN :</span>
                                <span>Rp. {customNumberFormat(totalPPN)}</span>
                            </div>
                        </div>
                    )
                }
                {
                    totalJumlah > 0 && (
                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                            <div className="d-flex align-items-center gap-3 fw-bolder fs-5">
                                <span>Total Jumlah :</span>
                                <span>Rp. {customNumberFormat(totalJumlah)}</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export { MasterDataTableTanstack }