import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { calculateDistanceLocation } from "../../functions/general";
import { useIntl } from "react-intl";
import moment from "moment";
import { useSettingsStore } from "../../stores/SettingsStore";
import useUser from "../../hooks/User";
import { Meeting } from "../../interfaces/Meeting";
import { GeoJson, GeoJsonFeature, Map as MapPigeon, Marker as MarkerPigeon, Overlay, ZoomControl } from "pigeon-maps";

interface DetailMeetingProps {
    data: Meeting
}

const DetailVisit: FC<DetailMeetingProps> = ({ data }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const intl = useIntl()
    const { settings } = useSettingsStore()
    const user = useUser()
    const colorRed = `hsl(${0 % 360}deg 39% 70%)`
    const colorBlue = `hsl(210deg 39% 70%)`;
    const [geoJsonFeatureLine, setGeoJsonFeatureLine] = useState<Object>({})
    const [geoJsonFeatureLocation, setGeoJsonFeatureLocation] = useState<Object>({})
    const [distance, setDistance] = useState(0)
    const [inRadius, setInRadius] = useState(false)


    const [latitudeIn, setLatitudeIn] = useState<number>(0)
    const [longitudeIn, setLongitudeIn] = useState<number>(0)
    const [dateIn, setDateIn] = useState<string>('')
    const [addressIn, setAddressIn] = useState<string>('')
    const [noteIn, setNoteIn] = useState<string>('')
    const [imgIn, setImgIn] = useState<string>('')

    const [latitudeOut, setLatitudeOut] = useState<number>(0)
    const [longitudeOut, setLongitudeOut] = useState<number>(0)
    const [dateOut, setDateOut] = useState<string>('')
    const [addressOut, setAddressOut] = useState<string>('')
    const [noteOut, setNoteOut] = useState<string>('')
    const [imgOut, setImgOut] = useState<string>('')

    useEffect(() => {
        setDateIn(moment(data.date_meet).format('DD MMMM YYYY HH:mm:ss') as string)
        setDateOut(moment(data.date_meet_end).format('DD MMMM YYYY HH:mm:ss') as string)

        data.logs.map((log) => {
            if (log.action === "ONGOING") {
                setLatitudeIn(log.latitude as number)
                setLongitudeIn(log.longitude as number)
                setAddressIn(log.address as string)
                setNoteIn(log.note as string)
                setImgIn(log.attachmentLink as string)
            } else if (log.action === "COMPLETED") {
                setLatitudeOut(log.latitude as number)
                setLongitudeOut(log.longitude as number)
                setAddressOut(log.address as string)
                setNoteOut(log.note as string)
                setImgOut(log.attachmentLink as string)
            }
        })
    }, [])

    useEffect(() => {
        const valDistance = calculateDistanceLocation(longitudeIn, latitudeIn, longitudeOut, latitudeOut);
        setDistance(valDistance);
        setInRadius((valDistance * 1000) <= settings.radius_visit!);

        const radius = settings.radius_visit ?? 0;
        if (radius > 0) {
            const coordinates = [longitudeIn, latitudeIn]; // [lon, lat]
            const numberOfEdges = 32; // optional, defaults to 32

            const circleToPolygon = require("circle-to-polygon");
            const polygon = circleToPolygon(coordinates, radius, numberOfEdges);

            setGeoJsonFeatureLocation({
                type: "Feature",
                geometry: polygon,
                properties: { prop0: "value0" },
            });
        }

        if (latitudeIn && longitudeIn && latitudeOut && longitudeOut) {
            const speedFactor = 300;
            const start = [longitudeIn, latitudeIn];
            const end = [longitudeOut, latitudeOut];
            const diffX = end[0] - start[0];
            const diffY = end[1] - start[1];
            const sfX = diffX / speedFactor;
            const sfY = diffY / speedFactor;

            let currentX = start[0];
            let currentY = start[1];

            const interval = setInterval(() => {
                currentX += sfX;
                currentY += sfY;

                if (Math.abs(currentX - start[0]) > Math.abs(diffX) || Math.abs(currentY - start[1]) > Math.abs(diffY)) {
                    setGeoJsonFeatureLine({
                        type: "Feature",
                        geometry: {
                            type: "LineString",
                            coordinates: [start, end]
                        },
                        properties: { prop0: "value0" }
                    });
                    clearInterval(interval);
                    return;
                }

                setGeoJsonFeatureLine({
                    type: "Feature",
                    geometry: {
                        type: "LineString",
                        coordinates: [start, [currentX, currentY]]
                    },
                    properties: { prop0: "value0" }
                });

            }, 10);

            return () => clearInterval(interval);
        }
    }, [latitudeIn, longitudeIn, latitudeOut, longitudeOut, settings.radius_visit]);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`detail-visit-modal-${data.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.VISIT_DETAIL' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form noValidate>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <div className="fs-6 fw-bolder">
                                        <span className="text-dark me-2">{intl.formatMessage({ id: 'FORM.LABEL.PIC' })} :</span>
                                        <span className="text-muted">{data.owner_name}</span>
                                    </div>
                                    <div className="fs-6 fw-bolder">
                                        <span className="text-dark me-2">{settings.merchant_title} :</span>
                                        <span className="text-muted">{data.merchant_name}</span>
                                    </div>
                                    {
                                        data.logs.length > 1 &&
                                        <>
                                            <div className="fs-6 fw-bolder">
                                                <span className="text-dark me-2">{intl.formatMessage({ id: 'FORM.LABEL.DURATION' })} :</span>
                                                <span className="text-muted">{data.duration}</span>
                                            </div>
                                            <div className="fs-6 fw-bolder">
                                                <span className="text-dark me-2">{intl.formatMessage({ id: 'FORM.LABEL.DISTANCE' })} :</span>
                                                <span className="text-muted me-3">{distance} Km</span>
                                                {
                                                    inRadius ?
                                                        <span className="badge badge-success">{intl.formatMessage({ id: 'FORM.LABEL.IN_AREA' })}</span> :
                                                        <span className="badge badge-danger">{intl.formatMessage({ id: 'FORM.LABEL.OUT_AREA' })}</span>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>

                                <MapPigeon height={200} key={`${latitudeIn}-${longitudeIn}`} defaultCenter={[latitudeIn, longitudeIn]} defaultZoom={18} attribution={false} >
                                    {Object.keys(geoJsonFeatureLocation).length > 0 ?
                                        <GeoJson
                                            svgAttributes={{
                                                fill: "#c9909080",
                                                strokeWidth: "1",
                                                stroke: "white",
                                                r: "20",
                                            }}
                                        >
                                            <GeoJsonFeature feature={geoJsonFeatureLocation} />
                                        </GeoJson> : <></>
                                    }

                                    {Object.keys(geoJsonFeatureLine).length > 0 ?
                                        <GeoJson svgAttributes={{
                                            strokeWidth: "4",
                                            stroke: "#009EF7",
                                            strokeDasharray: "1, 10",
                                            strokeLinecap: "round"
                                        }}>
                                            <GeoJsonFeature feature={geoJsonFeatureLine} />
                                        </GeoJson> : <></>
                                    }

                                    {
                                        data.logs.length > 1 &&
                                        <MarkerPigeon
                                            key={`${latitudeOut}-${longitudeOut}`}
                                            width={50}
                                            height={50}
                                            anchor={[latitudeOut, longitudeOut]}
                                            color={colorBlue}
                                        />
                                    }

                                    <MarkerPigeon
                                        key={`${latitudeIn}-${longitudeIn}`}
                                        width={50}
                                        height={50}
                                        anchor={[latitudeIn, longitudeIn]}
                                        color={colorRed}
                                    />

                                    <ZoomControl />
                                </MapPigeon>

                                <div className="d-flex mt-3" style={{ gap: "10px" }}>
                                    <div className="card-xl-stretch me-md-6" style={{ width: "50%" }}>
                                        <div className="d-flex align-items-center gap-2">
                                            <div style={{ backgroundColor: colorRed, width: "15px", height: "15px", borderRadius: "5px" }}></div>
                                            <span className="fs-4 text-dark fw-bolder lh-base">{intl.formatMessage({ id: 'FORM.LABEL.START_VISIT' })}</span>
                                        </div>
                                        <div className="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5">{addressIn}</div>
                                        {
                                            imgIn && <a href={imgIn} target="_blank" rel="noopener noreferrer" className="fw-bold fs-6 text-primary">{intl.formatMessage({ id: 'FORM.ACTION.VIEW_PICTURE' })}</a>
                                        }
                                        <div className="fs-6 fw-bolder">
                                            <span className="text-gray-700 me-2">{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: "" })} :</span>
                                            <span className="text-muted">{dateIn}</span>
                                        </div>
                                        <div className="fs-6 fw-bolder">
                                            <span className="text-gray-700 me-2">{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })} :</span>
                                            <span className="text-muted">{noteIn ? noteIn : '-'}</span>
                                        </div>
                                    </div>
                                    {
                                        data.logs.length > 1 &&
                                        <div className="card-xl-stretch me-md-6" style={{ width: "50%" }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div style={{ backgroundColor: colorBlue, width: "15px", height: "15px", borderRadius: "5px" }}></div>
                                                <span className="fs-4 text-dark fw-bolder lh-base">{intl.formatMessage({ id: 'FORM.LABEL.END_VISIT' })}</span>
                                            </div>
                                            <div className="fw-bold fs-5 text-gray-600 text-dark mt-3 mb-5">{addressOut}</div>
                                            {
                                                imgOut && <a href={imgOut} target="_blank" rel="noopener noreferrer" className="fw-bold fs-6 text-primary">{intl.formatMessage({ id: 'FORM.ACTION.VIEW_PICTURE' })}</a>
                                            }
                                            <div className="fs-6 fw-bolder">
                                                <span className="text-gray-700 me-2">{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: "" })} :</span>
                                                <span className="text-muted">{dateOut}</span>
                                            </div>
                                            <div className="fs-6 fw-bolder">
                                                <span className="text-gray-700 me-2">{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })} :</span>
                                                <span className="text-muted">{noteOut ? noteOut : '-'}</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { DetailVisit }