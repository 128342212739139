import { FC, ReactNode, useEffect, useRef } from "react";
import { TableColumn } from "react-data-table-component";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../functions/general";
import { useSettingsStore } from "../../stores/SettingsStore";
import { MasterDataTableNoUrl } from "../DataTable/MasterDataTableNoUrl";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import { DetailStaticDashboardWidget, StaticDashboardWidget } from "../../interfaces/Dashboard";
import { useIntl } from "react-intl";
import useNameWidget from "../../hooks/NameWidget";
import { DataTableExport } from "../DataTable/DataTableExport";

interface WidgetStaticModalProps {
    data: StaticDashboardWidget
    dateStart: string
    dateEnd: string
    setShowWidgetStaticModal: React.Dispatch<React.SetStateAction<boolean>>
    setShowWidgetDetailMerchantModal: React.Dispatch<React.SetStateAction<boolean>>
    setDataWidgetDetailMerchantModal: React.Dispatch<React.SetStateAction<DetailStaticDashboardWidget>>
}

const WidgetStaticModal: FC<WidgetStaticModalProps> = ({ data, dateStart, dateEnd, setShowWidgetStaticModal, setShowWidgetDetailMerchantModal, setDataWidgetDetailMerchantModal }) => {
    const { settings } = useSettingsStore()
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl()
    const nameWidget = data.alias;
    // const nameWidget = useNameWidget(data.title);

    const handleClickDetailMerchant = (row: DetailStaticDashboardWidget) => {
        setDataWidgetDetailMerchantModal(row)
        setShowWidgetDetailMerchantModal(true)
        setShowWidgetStaticModal(false)
        closeModalRef.current?.click();
    }

    var tableColumns: TableColumn<DetailStaticDashboardWidget>[] = [];

    if (data.title === 'Static All Pipelines Average Age') {
        tableColumns = [
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PROGRESS" }),
                // center: true,
                style: { width: "auto" },
                grow: 2,
                selector: row => row.leadLogProgressName,
                cell(row) {
                    if (row.leadLogProgressColor) return <span className="badge" style={{ backgroundColor: row.leadLogProgressColor, color: isColorDarkFromHex(row.leadLogProgressColor) ? "white" : "black" }}>{row.leadLogProgressName}</span>
                    else return <span className="badge badge-light-info" >{row.leadLogProgressName}</span>
                },
                sortable: true,
                reorder: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.AGE" }),
                width: 'auto',
                selector: row => Number(row.leadAge),
                sortable: true,
                cell(row) {
                    return getDateSummary(Math.round(Number(row.leadAge)))
                }
            },
            {
                name: `Total ${settings.merchant_title}`,
                width: 'auto',
                selector: row => row.leadCount ? customNumberFormat(row.leadCount) : '-',
                sortable: true,
                right: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.ACTION" }),
                width: 'auto',
                selector: row => customNumberFormat(row.leadCount),
                sortable: true,
                center: true,
                cell(row) {
                    return <button className="btn btn-sm btn-light-primary" onClick={() => handleClickDetailMerchant(row)}>Detail</button>
                }
            }
        ]
    } else {
        tableColumns = [
            {
                name: settings.merchant_title,
                width: '200px',
                selector: row => row.merchant_name + " " + row.hot_warm_cold_status,
                sortable: true,
                cell(row) {
                    switch (row.hot_warm_cold_status) {
                        case 'hot':
                            return <><span>{row.merchant_name}</span><span className="badge badge-light-danger ms-3">Hot</span></>
                        case 'warm':
                            return <><span>{row.merchant_name}</span><span className="badge badge-light-warning ms-3">Warm</span></>
                        case 'cold':
                            return <><span>{row.merchant_name}</span><span className="badge badge-light-primary ms-3">Cold</span></>
                        default:
                            return row.merchant_name
                    }
                }
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.OWNER_NAME" }),
                width: '200px',
                selector: row => row.owner_name,
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PROGRESS" }),
                center: true,
                // width: '30%',
                selector: row => row.lead_progress_name,
                cell(row) {
                    if (row.lead_progress_color) return <span className="badge" style={{ backgroundColor: row.lead_progress_color, color: isColorDarkFromHex(row.lead_progress_color) ? "white" : "black" }}>{row.lead_progress_name}</span>
                    else return <span className="badge badge-light-info" >{row.lead_progress_name}</span>
                },
                sortable: true,
            },
            {
                name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOMINAL" }),
                // width: '30%',
                right: true,
                selector: row => row.nominal,
                cell: (row) => {
                    return customNumberFormat(Math.ceil(row.nominal) ?? 0);
                },
                sortable: true,
            }
        ]
    }

    let actions: ReactNode[] = [<DataTableExport keyString={data.title} source={"WidgetStaticDashboard"} nameFile={`Detail ${nameWidget}`} columns={tableColumns} dateStart={dateStart} dateEnd={dateEnd} />];

    useEffect(() => {
        const modalElement = modalRef.current;

        // Fungsi untuk memanggil callback saat modal ditutup
        const handleModalClose = () => {
            console.log("close modal static")
            closeModalRef.current?.click();
            setShowWidgetStaticModal(false)
        };

        // Tambahkan event listener saat modal ditutup
        if (modalElement) {
            modalElement.addEventListener("hidden.bs.modal", handleModalClose);
        }

        // Cleanup untuk menghapus event listener
        return () => {
            if (modalElement) {
                modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
            }
        };
    }, []);

    return (
        <div className="modal fade" tabIndex={-1} id="widget-static-modal" ref={modalRef}>
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detail {nameWidget}</h5>
                        <div
                            ref={closeModalRef}
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <MasterDataTableNoUrl
                        tableKey={"widget-static"}
                        tableColumns={tableColumns}
                        tableData={data.detail}
                        actions={actions}
                    />
                </div>
            </div>
        </div>
    )
}

export { WidgetStaticModal }