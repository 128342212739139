import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { DetailPaidScheduleTermin, PaidScheduleTermin } from '../../interfaces/Termin/Termin';
import { customNumberFormat } from '../general';

export const exportPDFPaidSchedule = (nameFile: string, data: DetailPaidScheduleTermin[], dataHeader: PaidScheduleTermin) => {
    console.log(nameFile, data, dataHeader)
    let exportData: any[] = [];

    const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'cm',
        format: 'a4'
    });

    // Set Header PDF
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(14);
    var lastYHeader = 2;

    const list_header = [
        ["No. Unit", ":", dataHeader.merchantCustomFieldNoUnit ? dataHeader.merchantCustomFieldNoUnit : "-"],
        ["Nama", ":", dataHeader.merchantName ? dataHeader.merchantName : "-"],
        ["Nama Toko", ":", dataHeader.merchantCustomFieldNamaToko ? dataHeader.merchantCustomFieldNamaToko : "-"],
        ["Luas", ":", dataHeader.leadCustomFieldLuas ? dataHeader.leadCustomFieldLuas : "-"],
        ["Jangka Waktu Sewa", ":", dataHeader.leadCustomFieldJangkaWaktuSewa ? dataHeader.leadCustomFieldJangkaWaktuSewa : "-"],
        ["Periode Sewa", ":", moment(dataHeader.leadCustomFieldPeriodeMulaiSewa).format('DD MMMM YYYY') + " - " + moment(dataHeader.leadCustomFieldPeriodeAkhirSewa).format('DD MMMM YYYY')],
        ["Harga Sewa & SC", ":", dataHeader.totalNominalPipeline ? "Rp. " + customNumberFormat(Math.ceil(dataHeader.totalNominalPipeline)) : "Rp. 0"],
        ["Jaminan Sewa", ":", dataHeader.leadCustomFieldNilaiJaminan ? "Rp. " + customNumberFormat(Math.ceil(Number(dataHeader.leadCustomFieldNilaiJaminan))) : "Rp. 0"],
        ["Termin", ":", dataHeader.totalTermin ? customNumberFormat(dataHeader.totalTermin) : "0"],
    ]

    autoTable(doc, {
        startY: lastYHeader,
        tableWidth: "wrap",
        columnStyles: {
            0: {
                cellWidth: 4,
                fillColor: [255, 255, 255]
            },
            1: {
                cellWidth: 0.2,
                fillColor: [255, 255, 255]
            },
            2: {
                fillColor: [255, 255, 255]
            },
        },
        body: list_header,
        styles: {
            textColor: [0, 0, 0],
            lineWidth: 0,
        },
        didParseCell: function (data) {
            data.cell.styles.cellPadding = [0, 0, 0.1, 0];
        }
    });

    Array.from(data).map((row, index) => {
        var temp_pdf = []
        temp_pdf.push(
            row.no,
            row.tanggalJatuhTempo,
            row.keterangan,
            'Rp. ' + customNumberFormat(row.dpp),
            'Rp. ' + customNumberFormat(row.ppn),
            'Rp. ' + customNumberFormat(row.total),
            row.tanggalPembayaran,
        )

        exportData.push(temp_pdf);
    });

    addData(doc, exportData, data, lastYHeader + 5);

    return exportDoc(doc, nameFile);
};

const addData = (doc: jsPDF, exportData: any[], data: DetailPaidScheduleTermin[], lastYHeader: number) => {
    const header_table = [
        [
            { content: 'No', rowSpan: 2 },
            { content: 'Tanggal Jatuh Tempo', rowSpan: 2 },
            { content: 'Keterangan', rowSpan: 2 },
            { content: 'Pembayaran', colSpan: 3 },
            { content: 'Tanggal Pembayaran', rowSpan: 2 },
        ],
        ['DPP', 'PPN', 'Jumlah'], // Sub-header untuk Pembayaran
    ];

    const footer_table = [
        [
            { content: 'Total', colSpan: 3 },
            'Rp. ' + customNumberFormat(data.reduce((total, item) => total + item.dpp, 0)),
            'Rp. ' + customNumberFormat(data.reduce((total, item) => total + item.ppn, 0)),
            'Rp. ' + customNumberFormat(data.reduce((total, item) => total + item.total, 0)),
            ''
        ],
    ]

    autoTable(doc, {
        startY: lastYHeader,
        headStyles: {
            valign: 'middle',
            halign: 'center',
            fillColor: [255, 255, 255],
            lineWidth: 0.05
        },
        columnStyles: {
            0: { cellWidth: 1, fillColor: [255, 255, 255], halign: 'center' },
            1: { cellWidth: 'auto', fillColor: [255, 255, 255] },
            2: { cellWidth: 'auto', fillColor: [255, 255, 255] },
            3: { cellWidth: 'auto', fillColor: [255, 255, 255], halign: 'right' },
            4: { cellWidth: 'auto', fillColor: [255, 255, 255], halign: 'right' },
            5: { cellWidth: 'auto', fillColor: [255, 255, 255], halign: 'right' },
            6: { cellWidth: 'auto', fillColor: [255, 255, 255] },
        },
        footStyles: {
            valign: 'middle',
            halign: 'right',
            fillColor: [255, 255, 255],
            lineWidth: 0.05
        },
        head: header_table,
        body: exportData,
        foot: footer_table,
        styles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            lineWidth: 0.05
        },
        didDrawPage: (data) => {
            // Simpan nilai terakhir Y dari tabel di halaman terakhir
            if (data.cursor) lastYHeader = data.cursor.y;
        }
    });

    doc.setFont('helvetica', 'normal');
    doc.setFontSize(11);
    doc.text('*Note: PPN dapat berubah sewaktu - waktu mengikuti kebijakan pemerintah', 1.5, lastYHeader + 1);
    doc.text('Menyetujui,', 17, lastYHeader + 2, { align: 'center' });
    doc.text('_____________________', 17, lastYHeader + 5, { align: 'center' });
    doc.text('Penyewa', 17, lastYHeader + 5.5, { align: 'center' });
};

const exportDoc = (doc: jsPDF, nameFile: string) => {
    doc.save(`${nameFile}.pdf`);
};