
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const SECTION = `${API_URL}menu-analytics/section`;



export const insertSection = async (
  name: string, templateId:string,token: string
) => {
  return axios.post(
    SECTION,
    {
      name,
      templateId
      
    },
    {
      headers: {
        'X-Auth-token': token,
      },
    }
  );
};

export const updateSection = async (
    name: string,sectionId: string, token: string, position:number
  ) => {
    return axios.put(
      SECTION,
      {
        name,
        sectionId,
        position
      },
      {
        headers: {
          'X-Auth-token': token,
        },
      }
    );
  };

export const deleteSection = async (id: string, token: string) => {
    return axios.delete(
        SECTION+"/"+id, 
        {
      headers: {
        'X-Auth-token': token,
      },
    });
  };

