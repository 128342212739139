import {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import {FormikProps, useFormik} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../_metronic/helpers'
import clsx from 'clsx'
import {handleCaps, randomColor} from '../../../functions/general'
import useAccessToken from '../../../hooks/AccessToken'
import Select from 'react-select'
import {SketchPicker} from 'react-color'
import VirtualizedSelect, {VirtualizedSelectOption} from '../../Select/SelectVirtualized'
import axios from 'axios'
import useSWR from 'swr'
import {Product} from '../../../interfaces/Product'
import {Label} from '../../../interfaces/Label'
import {useSettingsStore} from '../../../stores/SettingsStore'

interface IProps {
  formik: FormikProps<any>
  stepForm: string
  setStepForm: any
  setShowAddCoreModal: any
}

type RuleMappingKey =
  | 'labelPipeline'
  | 'labelMerchant'
  | 'labelProduct'
  | 'labelMeeting'
  | 'progressType'
  | 'source'
  | 'responsible'
  | 'progress'
  | 'products'

export default function AddCore({formik, stepForm, setStepForm, setShowAddCoreModal}: IProps) {
  const intl = useIntl()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const [modalBtnLoading, setModalBtnLoading] = useState(false)

  const fetcher = (url: string) =>
    axios
      .get(url, {
        headers: {
          'X-Auth-token': token,
        },
      })
      .then((res) => res.data.data)
      .catch((error) => {
        throw error.response ? error.response.data : error
      })
  const {settings} = useSettingsStore()

  const API_URL = process.env.REACT_APP_API_URL
  const [productOptions, setProductOptions] = useState<VirtualizedSelectOption[]>([])
  const [progressOptions, setProgressOptions] = useState<VirtualizedSelectOption[]>([])
  const [responsibleOptions, setResponsibleOptions] = useState<VirtualizedSelectOption[]>([])
  const [sourceOptions, setSourceOptions] = useState<VirtualizedSelectOption[]>([])
  const [labelProductOption, setLabelProductOption] = useState<VirtualizedSelectOption[]>([])
  const [labelMerchantOption, setLabelMerchantOption] = useState<VirtualizedSelectOption[]>([])
  const [labelMeetingOption, setLabelMeetingOption] = useState<VirtualizedSelectOption[]>([])
  const [labelPipelineOption, setLabelPipelineOption] = useState<VirtualizedSelectOption[]>([])
  const progressTypesOption: VirtualizedSelectOption[] = [
    {
      value: 'ONGOING',
      label: intl.formatMessage({id: 'PROGRESS.TYPE.ONGOING'}),
    },
    {
      value: 'SUCCESS',
      label: intl.formatMessage({id: 'PROGRESS.TYPE.SUCCESS'}),
    },
    {
      value: 'FAIL',
      label: intl.formatMessage({id: 'PROGRESS.TYPE.FAIL'}),
    },
  ]

  const {
    data: productsData = [],
    error: productsError,
    isLoading: productsLoading,
  } = useSWR(`${API_URL}products`, fetcher)
  const {
    data: progressData = [],
    error: progressError,
    isLoading: progressLoading,
  } = useSWR(`${API_URL}lead_progresses`, fetcher)
  const {
    data: responsibleData = [],
    error: responsibleError,
    isLoading: responsibleLoading,
  } = useSWR(`${API_URL}users`, fetcher)
  const {
    data: sourceData = [],
    error: sourceError,
    isLoading: sourceLoading,
  } = useSWR(`${API_URL}lead_sources`, fetcher)
  const {
    data: labelProductData = [],
    error: labelProdictError,
    isLoading: labelProductLoading,
  } = useSWR(`${API_URL}labels?usableBy[]=product`, fetcher)
  const {
    data: labelMeetingData = [],
    error: labelMeetingError,
    isLoading: labelMeetingLoading,
  } = useSWR(`${API_URL}labels?usableBy[]=meeting`, fetcher)
  const {
    data: labelPipelineData = [],
    error: labelPipelineError,
    isLoading: labelPipelineLoading,
  } = useSWR(`${API_URL}labels?usableBy[]=pipeline`, fetcher)
  const {
    data: labelMerchantData = [],
    error: labelMerchantError,
    isLoading: labelMerchantLoading,
  } = useSWR(`${API_URL}labels?usableBy[]=merchant`, fetcher)

  const validationSchemaCore = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage(
        {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
        {title: intl.formatMessage({id: 'FORM.LABEL.NAME'})}
      )
    ),
    coreType: Yup.string().required(
      intl.formatMessage(
        {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
        {title: intl.formatMessage({id: 'FORM.LABEL.DATA_STATUS'})}
      )
    ),
    tableName: Yup.string().required(
      intl.formatMessage(
        {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
        {title: intl.formatMessage({id: 'FORM.LABEL.SOURCE_DATA'})}
      )
    ),
    colorCode: Yup.string().required(
      intl.formatMessage(
        {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
        {title: intl.formatMessage({id: 'FORM.LABEL.COLOR_CODE'})}
      )
    ),
  })

  const formikCore = useFormik({
    initialValues: {
      name: '',
      tableName: '',
      relationLead: false,
      defaultValue: '',
      colorCode: '',
      coreType: '',
      products: [] as string[],
      progress: [] as string[],
      responsible: [] as string[],
      source: [] as string[],
      progressType: [] as string[],
      labelProduct: [] as string[],
      labelMeeting: [] as string[],
      labelMerchant: [] as string[],
      labelPipeline: [] as string[],
    },
    validationSchema: validationSchemaCore,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (stepForm === '1') setStepForm('2')
        if (stepForm === '2') {
          setSubmitting(true)

          const ruleMapping: RuleMappingKey[] = [
            'labelPipeline',
            'labelMerchant',
            'labelProduct',
            'labelMeeting',
            'progressType',
            'source',
            'responsible',
            'progress',
            'products',
          ]

          const newDataCore = {
            index: formik.values.dataCore.length + 1,
            name: values.name,
            tableName: values.tableName,
            relationLead: values.relationLead,
            defaultValue: values.defaultValue,
            coreType: values.coreType,
            colorCode: values.colorCode,
            status: 'ACTIVE',
            rules: [] as {typeFilter: string; valueFilter: any}[],
          }

          // Iterate over values and process rules
          ;(Object.keys(values) as (keyof typeof values)[]).forEach((key) => {
            const value = values[key]

            if (
              ruleMapping.includes(key as RuleMappingKey) &&
              Array.isArray(value) &&
              value.length > 0
            ) {
              // Ensure valueFilter is string[] when it's an array
              newDataCore.rules.push({
                typeFilter: key,
                valueFilter: value as string[], // Explicitly cast as string[]
              })
            } else if (key === 'coreType' && typeof value === 'string') {
              // Ensure valueFilter is string when it's a single string
              newDataCore.rules.push({
                typeFilter: key,
                valueFilter: value as string, // Explicitly cast as string
              })
            }
          })

          // Update formik values

          formik.setFieldValue('dataCore', [...formik.values.dataCore, newDataCore])
          closeModalRef.current?.click()
          setSubmitting(false)
          setShowAddCoreModal(false)
          setStepForm('1')
        }
      } catch (error) {
        setSubmitting(false)
        setStatus({error: 'Something went wrong. Please try again later.'})
      }
    },
  })

  useEffect(() => {
    formikCore.setFieldValue('colorCode', randomColor())
  }, [])

  useEffect(() => {
    const productOpt = productsData.map((product: Product) => {
      return {
        value: product.id,
        label: product.name,
      }
    })
    const allProduct = { value: 'all', label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PRODUCT" }) }) }
    setProductOptions([allProduct,...productOpt])
  }, [productsData])

  useEffect(() => {
    const progressOpt = progressData.map((product: Product) => {
      return {
        value: product.id,
        label: product.name,
      }
    })
    setProgressOptions(progressOpt)
  }, [progressData])

  useEffect(() => {
    const responsibleOpt = responsibleData.map((product: Product) => {
      return {
        value: product.id,
        label: product.name,
      }
    })
    setResponsibleOptions(responsibleOpt)
  }, [responsibleData])

  useEffect(() => {
    const sourceOpt = sourceData.map((product: Product) => {
      return {
        value: product.id,
        label: product.name,
      }
    })
    setSourceOptions(sourceOpt)
  }, [sourceData])

  useEffect(() => {
    const newOption = labelProductData.map((product: Product) => {
      return {
        value: product.id,
        label: product.name,
      }
    })
    setLabelProductOption(newOption)
  }, [labelProductData])

  useEffect(() => {
    const newOption = labelProductData.map((label: Label) => {
      return {
        value: label.id,
        label: label.name,
      }
    })
    setLabelProductOption(newOption)
  }, [labelProductData])

  useEffect(() => {
    const newOption = labelMeetingData.map((label: Label) => {
      return {
        value: label.id,
        label: label.name,
      }
    })
    setLabelMeetingOption(newOption)
  }, [labelMeetingData])

  useEffect(() => {
    const newOption = labelMerchantData.map((label: Label) => {
      return {
        value: label.id,
        label: label.name,
      }
    })
    setLabelMerchantOption(newOption)
  }, [labelMerchantData])

  useEffect(() => {
    const newOption = labelPipelineData.map((label: Label) => {
      return {
        value: label.id,
        label: label.name,
      }
    })
    setLabelPipelineOption(newOption)
  }, [labelPipelineData])

  const token = useAccessToken()

  const handleBack = () => {
    setStepForm('1')
  }

  const {handleCloseModal} = useHandleCloseModal(modalRef, closeModalRef, formikCore)
  
  const typeChartOptions = [
    {label: settings.pipeline_title, value: 'PIPELINE'},
    {label: settings.meeting_title, value: 'MEETING'},
    {label: intl.formatMessage({id:"MENU.PRODUCTS"}), value: 'PRODUCT'},
    {label: settings.merchant_title, value: 'MERCHANT'},
  ]
  const typeCoreOptions = [
    {label: intl.formatMessage({id: 'FORM.OPTION.LABEL.CREATED'}), value: 'created'},
    ...(formikCore.values.tableName === 'PIPELINE' || formikCore.values.tableName === 'MEETING'
      ? [{label: intl.formatMessage({id: 'FORM.OPTION.LABEL.FINISHED'}), value: 'finished'}]
      : []),
  ]

  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
        formik.resetForm()      
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  return (
    <>
      <div className={`modal fade`} tabIndex={-1} id={`add-core-modal`} ref={modalRef}>
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{intl.formatMessage({id: 'FORM.ACTION.ADD'})} Core</h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleCloseModal}
                ref={closeModalRef}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            <form onSubmit={formikCore.handleSubmit} noValidate>
              <div className='modal-body'>
                <div
                  className='stepper stepper-links d-flex flex-column first'
                  id='kt_create_account_stepper'
                  data-kt-stepper='true'
                >
                  <div className='stepper-nav py-5'>
                    <div
                      className={`stepper-item ${stepForm === '1' ? 'current' : 'pending'}`}
                      data-kt-stepper-element='nav'
                    >
                      <h3 className='stepper-title'>{'Core'}</h3>
                    </div>
                    <div
                      className={`stepper-item ${stepForm === '2' ? 'current' : 'pending'}`}
                      data-kt-stepper-element='nav'
                    >
                      <h3 className='stepper-title'>Rule</h3>
                    </div>
                  </div>
                  <form
                    className='mx-auto w-100 p-10 fv-plugins-bootstrap5 fv-plugins-framework'
                    onSubmit={formikCore.handleSubmit}
                    noValidate
                  >
                    <div
                      className={`${stepForm === '1' ? 'current' : 'pending'}`}
                      data-kt-stepper-element='content'
                    >
                      <div className='w-100'>
                        {/* name */}
                        <>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'FORM.LABEL.NAME'})}
                          </label>
                          <div className='input-group mb-3'>
                            <input
                              placeholder={intl.formatMessage({id: 'FORM.LABEL.NAME'})}
                              {...formikCore.getFieldProps('name')}
                              className={clsx('form-control form-control-lg', {
                                'is-invalid': formikCore.touched.name && formikCore.errors.name,
                              })}
                              type='text'
                              name='name'
                              autoComplete='off'
                              onInput={(e) => handleCaps(e)}
                            />
                          </div>
                          {formikCore.touched.name && formikCore.errors.name && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className='text-danger'>
                                {formikCore.errors.name}
                              </span>
                            </div>
                          )}
                        </>
                        {/* tablename  */}
                        <>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'FORM.LABEL.SOURCE_DATA'})}
                          </label>
                          <div className='input-group mb-3'>
                            <Select
                              closeMenuOnSelect={true}
                              openMenuOnClick
                              options={typeChartOptions}
                              placeholder={`${intl.formatMessage({
                                id: 'FORM.ACTION.CHOOSE',
                              })} ${intl.formatMessage({id: 'FORM.LABEL.SOURCE_DATA'})}`}
                              value={typeChartOptions.filter(
                                (option: any) => formikCore.values.tableName === option.value
                              )}
                              onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions
                                formikCore.setFieldValue('tableName', selectedValues?.value)
                                formikCore.setFieldValue('coreType', '')
                                formikCore.setFieldValue('product', [])
                                formikCore.setFieldValue('progress', [])
                                formikCore.setFieldValue('responsible', [])
                                formikCore.setFieldValue('source', [])
                                formikCore.setFieldValue('labelProduct', [])
                                formikCore.setFieldValue('progressType', [])
                              }}
                              className='w-100 '
                              onBlur={() =>
                                formikCore.values.tableName === '' &&
                                formikCore.setFieldTouched('chartType', true)
                              }
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({...provided, zIndex: 9999}),
                              }}
                            />
                          </div>
                          {formikCore.touched.tableName && formikCore.errors.tableName && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className='text-danger'>
                                {formikCore.errors.tableName}
                              </span>
                            </div>
                          )}
                        </>
                        {/* data yang ingin di lihat */}
                        <>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'FORM.LABEL.DATA_STATUS'})}
                          </label>
                          <div className='input-group mb-3'>
                            <Select
                              closeMenuOnSelect={true}
                              openMenuOnClick
                              isDisabled={
                                formikCore.values.tableName === '' ||
                                formikCore.values.tableName === undefined
                              }
                              options={typeCoreOptions}
                              value={typeCoreOptions.filter(
                                (option: any) => formikCore.values.coreType === option.value
                              )}
                              onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions
                                formikCore.setFieldValue('coreType', selectedValues?.value)
                              }}
                              className='w-100 '
                              onBlur={() =>
                                formikCore.values.coreType === '' &&
                                formikCore.setFieldTouched('coreType', true)
                              }
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: (provided) => ({...provided, zIndex: 9999}),
                              }}
                            />
                          </div>
                          {formikCore.touched.coreType && formikCore.errors.coreType && (
                            <div className='fv-plugins-message-container text-danger'>
                              <span role='alert' className='text-danger'>
                                {formikCore.errors.coreType}
                              </span>
                            </div>
                          )}
                        </>
                        {/* Hubungkan dengan pipeline */}
                        <>
                          <div
                            style={{
                              display: 'flex',
                              padding: '3px',
                              overflowWrap: 'anywhere',
                              gap: '4px',
                            }}
                          >
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='relationLead'
                              name='relationLead'
                              checked={formikCore.values.relationLead}
                              onChange={() => {
                                formikCore.setFieldValue(
                                  'relationLead',
                                  !formikCore.values.relationLead
                                )
                              }}
                              value={'relationLead'}
                            />
                            <label htmlFor='relationLead' className='form-check-label me-2 ml-2'>
                              {' Hubungkan dengan Pipeline'}
                            </label>
                          </div>
                        </>
                        {/* color */}
                        <>
                          <label className='form-label fs-6 fw-bolder text-dark required'>
                            {intl.formatMessage({id: 'FORM.LABEL.COLOR'})}
                          </label>
                          <div className='input-group mb-3'>
                            <SketchPicker
                              color={formikCore.values.colorCode}
                              onChange={(color) => {
                                formikCore.handleChange({
                                  target: {
                                    name: 'colorCode',
                                    value: color.hex,
                                  },
                                })
                              }}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                    <div
                      className={`${stepForm === '2' ? 'current' : 'pending'}`}
                      data-kt-stepper-element='content'
                    >
                      <div className='w-100'>
                        <>
                          {/* filter product */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FORM.LABEL.PRODUCTS'})}
                              </label>
                              <VirtualizedSelect
                              closeMenuOnSelect={false}
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'products',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }

                                options={productOptions}
                                value={productOptions.filter((product) =>
                                  formikCore.values.products.includes(product.value)
                                )}
                                isMulti={true}
                                placeholder={`${intl.formatMessage({id:"FORM.LABEL.ALL"},{ title:intl.formatMessage({id:"FORM.OPTION.TYPE.PRODUCT"})})}`}
                              />
                            </>
                          )}

                          {/* filter progress */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FORM.LABEL.PROGRESS'})}
                              </label>
                              <VirtualizedSelect
                              placeholder={`${intl.formatMessage({id:"FORM.LABEL.ALL"},{ title:intl.formatMessage({id:"MENU.PROGRESSES"})})}`}
                              closeMenuOnSelect={false}
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'progress',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                options={progressOptions}
                                value={progressOptions.filter((progress) =>
                                  formikCore.values.progress.includes(progress.value)
                                )}
                                isMulti={true}
                              />
                            </>
                          )}

                          {/* filter PIC */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.tableName === 'MEETING' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FORM.LABEL.PIC'})}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'responsible',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                options={responsibleOptions}
                                value={responsibleOptions.filter((responsible) =>
                                  formikCore.values.responsible.includes(responsible.value)
                                )}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PIC" }) })}`}
                                                              
                              />
                            </>
                          )}

                          {/* filter sources */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FILTER.LABEL.SOURCES'})}
                              </label>
                              <VirtualizedSelect
                                closeMenuOnSelect={false}
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'source',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                options={sourceOptions}
                                value={sourceOptions.filter((source) =>
                                  formikCore.values.source.includes(source.value)
                                )}
                                isMulti={true}
                              />
                            </>
                          )}

                          {/* filter progresstype */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FORM.LABEL.PROGRESS_TYPE'})}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'progressType',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                closeMenuOnSelect={false}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS_TYPE" }) })}`} 
                                options={progressTypesOption}
                                value={progressTypesOption.filter((progressType) =>
                                  formikCore.values.progressType.includes(progressType.value)
                                )}
                                isMulti={true}
                              />
                            </>
                          )}

                          {/* filter product label */}
                          {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.tableName === 'PRODUCT' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage({id: 'FILTER.LABEL.PRODUCT_LABEL'})}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'labelProduct',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                closeMenuOnSelect={false}
                                options={labelProductOption}
                                value={labelProductOption.filter((labelProduct) =>
                                  formikCore.values.labelProduct.includes(labelProduct.value)
                                )}
                                isMulti={true}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FILTER.LABEL.LABEL" },{title:intl.formatMessage({id:"FORM.MENU.PRODUCTS"})}) })}`} 
                                
                              />
                            </>
                          )}

                          {/* filter label meeting */}
                          {formikCore.values.tableName === 'MEETING' && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage(
                                  {id: 'FILTER.LABEL.LABEL'},
                                  {title: settings.meeting_title}
                                )}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'labelMeeting',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                closeMenuOnSelect={false}
                                options={labelMeetingOption}
                                value={labelMeetingOption.filter((labelMeeting) =>
                                  formikCore.values.labelMeeting.includes(labelMeeting.value)
                                )}
                                isMulti={true}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FILTER.LABEL.LABEL" },{title:intl.formatMessage({id:"FORM.OPTION.TYPE.MEETING"})}) })}`} 
                              />
                            </>
                          )}

                          {/* filter label Pipeline */}
                          {/* {(formikCore.values.tableName === 'PIPELINE' ||
                            formikCore.values.relationLead) && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage(
                                  {id: 'FILTER.LABEL.LABEL'},
                                  {title: settings.pipeline_title}
                                )}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'labelPipeline',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                closeMenuOnSelect={false}
                                options={labelPipelineOption}
                                value={labelPipelineOption.filter((labelPipeline) =>
                                  formikCore.values.labelPipeline.includes(labelPipeline.value)
                                )}
                                isMulti={true}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FILTER.LABEL.LABEL" },{title:settings.pipeline_title}) })}`} 
                              />
                            </>
                          )} */}
                          {/* filter label Merchant */}
                          {formikCore.values.tableName === 'MERCHANT' && (
                            <>
                              <label className='form-label fs-6 fw-bolder text-dark '>
                                {intl.formatMessage(
                                  {id: 'FILTER.LABEL.LABEL'},
                                  {title: settings.merchant_title}
                                )}
                              </label>
                              <VirtualizedSelect
                                onChange={(selectedOptions) =>
                                  formikCore.setFieldValue(
                                    'labelMerchant',
                                    selectedOptions
                                      ? selectedOptions.map((option: VirtualizedSelectOption) => option.value)
                                      : []
                                  )
                                }
                                options={labelMerchantOption}
                                closeMenuOnSelect={false}
                                value={labelMerchantOption.filter((labelMerchant) =>
                                  formikCore.values.labelMerchant.includes(labelMerchant.value)
                                )}
                                isMulti={true}
                                placeholder={`${intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FILTER.LABEL.LABEL" },{title:settings.merchant_title}) })}`} 
                              />
                            </>
                          )}
                        </>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light d-none'
                  data-bs-dismiss='modal'
                  ref={closeModalRef}
                >
                  {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})}
                </button>
                {stepForm === '2' && (
                  <div className='d-flex flex-stack mt-10'>
                    <button type='button' className='btn btn-light' onClick={handleBack}>
                      <span className='svg-icon svg-icon-4 me-1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='11'
                            width='13'
                            height='2'
                            rx='1'
                            fill='black'
                          ></rect>
                          <path
                            d='M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z'
                            fill='black'
                          ></path>
                        </svg>
                      </span>
                      {intl.formatMessage({id: 'FORM.ACTION.BACK'})}
                    </button>
                    <button
                      type='submit'
                      className={`btn btn-primary ${modalBtnLoading ? 'disabled' : ''}`}
                      data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                    >
                      <span className='indicator-label'>
                        {intl.formatMessage({id: 'FORM.ACTION.ADD'})}
                      </span>
                      <span className='indicator-progress'>
                        Loading...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  </div>
                )}

                {stepForm === '1' && (
                  <div className='d-flex flex-stack justify-content-end mt-10'>
                    <button type='submit' className='btn btn-primary'>
                      {intl.formatMessage({id: 'FORM.ACTION.CONTINUE'})}
                      <span className='svg-icon svg-icon-4 ms-1 me-0'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <rect
                            opacity='0.5'
                            x='18'
                            y='13'
                            width='13'
                            height='2'
                            rx='1'
                            transform='rotate(-180 18 13)'
                            fill='black'
                          ></rect>
                          <path
                            d='M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z'
                            fill='black'
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                )}
              </div>
            </form>
            {/* form */}
          </div>
        </div>
      </div>
    </>
  )
}
