import axios from "axios"


const API_URL = process.env.REACT_APP_API_URL
const GET_CITY = `${API_URL}cities`
const GET_ROLE = `${API_URL}roles`
const GET_LEADER = `${API_URL}user/leaders`
const GET_ATTENDANCE_TEMPLATE = `${API_URL}attendance-templates`


export const fetchWithToken = async ([url, token]: [string , string]) => {
    const response = await axios.get(url, {
        headers: {
            'X-Auth-token': token,
        },
    });
    return response.data.data;
  };

export const getCities = async (token: string) => {
    return axios.get(GET_CITY,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const getRoles = async (token: string) => {
    return axios.get(GET_ROLE,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const getLeaders = async (token: string) => {
    return axios.get(GET_LEADER,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const getAttendanceTemplates = async (token: string) => {
    return axios.get(GET_ATTENDANCE_TEMPLATE,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}
