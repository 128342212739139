import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../_metronic/helpers'
import {ISection} from '../../../interfaces/Analytics/Analytics'
import clsx from 'clsx'
import {handleCaps} from '../../../functions/general'
import {insertSection, updateSection} from '../../../api/Analitik/SectionCRUD'
import useAccessToken from '../../../hooks/AccessToken'
import {useAnalyticsStore} from '../../../stores/Analytics/AnalyticStore'

interface IProps {
  section: ISection
}
export default function UpdateSection({section}: IProps) {
  const intl = useIntl()
  const modalRef = useRef<HTMLDivElement | null>(null)
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const [modalBtnLoading, setModalBtnLoading] = useState(false)
  const {sections, setSections} = useAnalyticsStore()
  const token = useAccessToken()

  const formik = useFormik({
    initialValues: {
      name: section.name,
      position: section.position
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        intl.formatMessage(
          {id: 'FORM.VALIDATION.FIELD_REQUIRED'},
          {title: intl.formatMessage({id: 'FORM.LABEL.NAME'})}
        )
      ),
    }),
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setSubmitting(true)
      setModalBtnLoading(true)
      try {
        const response = await updateSection(values.name, section.sectionId, token, values.position)
        if (response.data.success) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: intl.formatMessage({id: 'FORM.VALIDATION.ADD_SUCCESS'}),
            timer: 2000,
            timerProgressBar: true,
          })
          closeModalRef.current?.click()
          setSections(
            sections.map((s) => {
              if (s.sectionId === section.sectionId) {
                return response.data.data
              }
              return s
            })
          )
          // setTablesData('products', ([...tablesData.products, response.data.data]))
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          confirmButtonText: 'Ok',
        })
      } finally {
        setSubmitting(false)
        setModalBtnLoading(false)
      }
    },
  })

  const {handleCloseModal} = useHandleCloseModal(modalRef, closeModalRef, formik)
  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
      formik.resetForm()
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  return (
    <>
      <div
        className={`modal fade`}
        tabIndex={-1}
        id={`update-section-modal-${section.sectionId}`}
        ref={modalRef}
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})} {section.name}
              </h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleCloseModal}
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            {/* form */}

            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='modal-body'>
                <label className='form-label fs-6 fw-bolder text-dark required'>
                  {intl.formatMessage({id: 'FORM.LABEL.NAME'})}
                </label>
                <div className='input-group mb-3'>
                  <input
                    placeholder={intl.formatMessage({id: 'FORM.PLACEHOLDER.SECTION'})}
                    {...formik.getFieldProps('name')}
                    className={clsx('form-control form-control-lg', {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    })}
                    type='text'
                    name='name'
                    autoComplete='off'
                    onInput={(e) => handleCaps(e)}
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className='text-danger'>
                      {formik.errors.name}
                    </span>
                  </div>
                )}
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light d-none'
                  data-bs-dismiss='modal'
                  ref={closeModalRef}
                >
                  {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})} tes
                </button>
                <button type='button' className='btn btn-light' onClick={handleCloseModal}>
                  {intl.formatMessage({id: 'FORM.ACTION.CANCEL'})}
                </button>
                <button
                  type='submit'
                  className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`}
                  data-kt-indicator={modalBtnLoading ? 'on' : 'off'}
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})}
                  </span>
                  <span className='indicator-progress'>
                    Loading...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              </div>
            </form>
            {/* form */}
          </div>
        </div>
      </div>
    </>
  )
}
