import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import moment from "moment";
import { DatePicker } from "rsuite";
import { KTSVG } from "../../_metronic/helpers";
import TimeIcon from '@rsuite/icons/Time';
import { AttendanceReport, MyAttendance } from "../../interfaces/Attendance";
import { approveClockIn, approveClockOut } from "../../api/AttendanceCRUD";
import { mutate } from "swr";
import { useIntl } from "react-intl";

interface UpdateModalProps {
    attendance: MyAttendance;
    updateType: string;
}

const ApproveAttendance: FC<UpdateModalProps> = ({ attendance, updateType }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()

    const API_URL = process.env.REACT_APP_API_URL
    const intl = useIntl();

    const validationSchema = Yup.object().shape({});

    const initialValues = {}

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                if (updateType === "clockIn"){
                    const response = await approveClockIn(
                        attendance.id,
                        attendance,
                        true,
                        token
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.APPROVE_SUCCESS" }),
                            heightAuto: false,
                        })
                        mutate(`${API_URL}user-attendances`);
                        closeModalRef.current?.click();
                    }
                }
                else if (updateType === "clockOut"){
                    const response = await approveClockOut(
                        attendance.id,
                        attendance,
                        true,
                        token
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.APPROVE_SUCCESS" }),
                            heightAuto: false,
                        })
                        mutate(`${API_URL}user-attendances`);
                        closeModalRef.current?.click();
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    text: error.response.data.text?? "",
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={"approve-attendance-modal-" + attendance.userId} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.LABEL.APPROVE_ATTENDANCE" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* name */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
                                <div className="input-group mb-3">
                                    {attendance.userName}
                                </div>
                                {/* date */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.DATE" }, {title: ''})}</label>
                                <div className="input-group mb-3">
                                    {moment(updateType === "clockIn" ? attendance.dateClockIn : attendance.dateClockOut).format('DD MMMM YYYY')}
                                </div>
                                {/* time */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{updateType === "clockIn" ? intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.START_HOUR" }) : intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.END_HOUR" })}</label>
                                <div className="input-group mb-3">
                                    {moment(updateType === "clockIn" ? attendance.dateClockIn : attendance.dateClockOut).format('HH:mm')}
                                </div>
                                {/* description */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.NOTE" })}</label>
                                <div className="input-group mb-3">
                                    {updateType === "clockIn" ? (attendance.descriptionClockIn ?? "-") : (attendance.descriptionClockOut ?? "-")}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.APPROVE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ApproveAttendance }