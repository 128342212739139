import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { exportTemplateExcelEmail } from './exportTemplateExcelEmail'
import useNameColumnExcelEmail from '../hooks/useNameColumnExcelEmail'
import useUser from '../../../hooks/User'
import { useEmailStore } from '../../../stores/EmailStore'
import { useCustomFieldStore } from '../../../stores/FieldEmailStore'
import useSWR from 'swr'
import { useDataTableStore } from '../../../stores/DataTableStore'
import { useUserStore } from '../../../hooks/UserEmail'

interface DataExportEmailProps {
    source: string
    typeAction: 'insert' | 'update'
    type?: string
    param?: any
}

const DataExportTemplateEmail = ({ source, type, typeAction, param }: DataExportEmailProps) => {
    const intl = useIntl()
    const [isLoading, setIsLoading] = useState(false)
    const nameHeader = useNameColumnExcelEmail()
    const { userEmail } = useUserStore()
    const { customFieldStore } = useCustomFieldStore()
    const { filteredTablesData } = useDataTableStore()

    const handleButton = (typeBtn: string) => {
        setIsLoading(true)
        if (source === 'template-detail-group-email') {
            if (typeAction === 'insert') {
                if (typeBtn === 'error') {
                    setTimeout(() => {
                        exportTemplateExcelEmail(source, typeAction, 'Template Error Insert Email', nameHeader.import, setIsLoading, customFieldStore, userEmail!, param, type) // insert error
                    }, 100)
                } else {
                    setTimeout(() => {
                        exportTemplateExcelEmail(source, typeAction, 'Template Insert Email', nameHeader.import, setIsLoading, customFieldStore, userEmail!, param, type)
                    }, 100)
                }
            } else if (typeAction === 'update') {
                if (typeBtn === 'error') {
                    setTimeout(() => {
                        exportTemplateExcelEmail(source, typeAction, 'Template Error Update Email', nameHeader.import, setIsLoading, customFieldStore, userEmail!, param, type) // update error
                    }, 100)
                } else {
                    console.log('berjalan update')
                    setTimeout(() => {
                        exportTemplateExcelEmail(source, typeAction, 'Template Update Email', nameHeader.import, setIsLoading, customFieldStore, userEmail!, { ...param, dataTables: filteredTablesData['detailGroupEmail'] }, type)
                    }, 100)
                }
            }
        }
    }

    if (type === 'error') {
        return (
            <button
                type="button"
                className={`btn btn-light-danger skeleton skeleton-button mb-3 w-100 ${isLoading ? 'disabled' : ""}`}
                data-kt-indicator={isLoading ? 'on' : 'off'}
                id="optionImport"
                onClick={() => handleButton('error')}
            >
                <i className="fas fa-file-download fs-2 me-2"></i>
                <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.DOWNLOAD_TEMPLATE_ERROR' })}
                </span>
                <span className="indicator-progress">
                    Loading... &nbsp;
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        )
    } else {
        return (
            <button
                type="button"
                className={`btn btn-success skeleton skeleton-button mb-3 w-100 ${isLoading ? 'disabled' : ""}`}
                data-kt-indicator={isLoading ? 'on' : 'off'}
                id="optionImport"
                onClick={() => handleButton('success')}
            >
                <i className="fas fa-file-download fs-2 me-2"></i>
                <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.DOWNLOAD_TEMPLATE' })}
                </span>
                <span className="indicator-progress">
                    Loading... &nbsp;
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        )
    }
}

export default DataExportTemplateEmail