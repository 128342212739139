import { create } from 'zustand'
import { Pipeline } from '../interfaces/Pipeline'

interface DNDPipelineStoreStateInterface {
    currentDraggedPipelineCard: Pipeline | undefined
    setCurrentDraggedPipelineCard: (activePipeline: Pipeline|undefined) => void
    currentProgressOver: string | undefined
    setCurrentProgressOver: (selectProgressOver: string|undefined) => void
    allowedContainerToDrop: string[]
    setAllowedContainerToDrop: (selectAllowedContainerToDrop: string[]) => void
    isCardDraggingg: boolean
    setIsCardDragging: (cardDragging: boolean) => void
}

export const useDNDPipelineStore = create<DNDPipelineStoreStateInterface>((set) => ({
  currentDraggedPipelineCard: undefined,
  setCurrentDraggedPipelineCard: (newActivePipeline: Pipeline|undefined) => set(() => ({ currentDraggedPipelineCard:newActivePipeline })),

  currentProgressOver: undefined,
  setCurrentProgressOver: (newCurrentProgressOver: string|undefined) => set(() => ({ currentProgressOver:newCurrentProgressOver })),

  allowedContainerToDrop: [],
  setAllowedContainerToDrop: (selectAllowedContainerToDrop: string[])=> set(()=>({
    allowedContainerToDrop: selectAllowedContainerToDrop
  })),

  isCardDraggingg:false,
  setIsCardDragging:(cardDragging : boolean)=>set(()=>({
    isCardDraggingg: cardDragging
  })),

}))
