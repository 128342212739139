import { FC, useRef, useEffect, useState, useMemo } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { MapContainer, Popup, TileLayer, useMap, Marker } from 'react-leaflet'
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select"
import { nanoid } from "@reduxjs/toolkit";
import useSWR, { mutate } from "swr";
import axios from "axios";
import { Merchant } from "../../interfaces/Merchant";
import moment from "moment";
import { insertMeetingAttachment, insertMeetingFieldAttachment, insertMeetingFieldMultiAttachment } from "../../api/ResourceAPI";
import { insertMeeting, insertVisitMeeting } from "../../api/MeetingsCRUD";
import { MeetingAttachment, VisitPermision } from "../../interfaces/Meeting";
import useUser from "../../hooks/User";
import { useSettingsStore } from "../../stores/SettingsStore";
import { User } from "../../interfaces/User";
import { Modal } from "bootstrap";
import { DatePicker, Dropdown } from "rsuite";
import { Map as MapPigeon, Marker as MarkerPigeon } from "pigeon-maps";
import { CustomField, CustomFieldSerialProps } from "../../interfaces/Settings";
import { AddMerchant } from "../Merchants/AddMerchant";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { dataURLtoFile, getRandomFileName, handleCaps, isValidUrl } from "../../functions/general";
import { useIntl } from "react-intl";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; // Import Leaflet library
import useTodayRange from "../../hooks/TodayRange";
import { UserCompany } from "../../interfaces/UserCompany";
import { isBefore, isAfter, subDays, startOfToday } from 'date-fns';
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { generateSerialApi } from "../../api/Generals";
import { tailspin } from 'ldrs'
import { Buffer } from 'buffer';
import { CapturePhoto } from "../Attendance/CapturePhoto";
import useCamera from "../../hooks/Camera";
import InputPhone from "../Input/InputPhone";
import { AddressLatlong } from "../../interfaces/Gmaps";
import { SelectOption, SelectOptionGroup } from "../../interfaces/SelectOption";
import { useCustomeStore } from "../../stores/CustomeStore";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useChatStore } from "../../stores/ChatStore";
import { useMeetingStore } from "../../stores/MeetingsStore";
import { defaultScope } from "../DataTable/UserScopeModal";

interface SelectAttendees {
    label: string
    value: string
}

interface Props {
    codeUrl: string
    startDate: Date;
    endDate: Date;
    setEndDate: (date: Date) => void;
    setStartDate: (date: Date) => void;
    setVisit: (data: VisitPermision) => void;
}

const customNumberFormat = (valueString: string) => {
    const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
    const parts = onlyNumbers.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
}


const AddMeeting: FC<Props> = ({ codeUrl, startDate, endDate, setStartDate, setEndDate, setVisit }) => {
    const {
        meetings,
        setMeetings,
        meetingLoading,
        setMeetingLoading,
        multiMeetings,
        setMultiMeetings,
        selectMultiMeeting,
        setSelectMultiMeeting,
    } = useMeetingStore();

    const { companyId } = useCustomeStore()
    const todayRange = useTodayRange();
    const intl = useIntl()
    const camera = useCamera()

    const renderButton = (props: any, ref: any) => {
        return (
            <button {...props} ref={ref} className="btn btn-primary mt-3">
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {selectMultiMeeting.name}
            </button>
        );
    };
    tailspin.register()
    const { phoneNumber } = useChatStore()
    const { settings } = useSettingsStore()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [typeMeeting, setTypeMeeting] = useState("")
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [merchantData, setMerchantData] = useState<SelectOptionGroup[]>([]);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [userCompanyData, setUserCompanyData] = useState<SelectOption[]>([]);
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});

    const [capturePhoto, setCapturePhoto] = useState(null);
    const [latitudeMap, setLatitudeMap] = useState<number>(0);
    const [longitudeMap, setLongitudeMap] = useState<number>(0);
    const user = useUser()
    const color = `hsl(${0 % 360}deg 39% 70%)`
    const [showAddMerchantModal, setShowAddMerchantModal] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState<CustomField>({})
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const [optionsLocation, setOptionsLocation] = useState<SelectOption[]>([]);
    const [isLoadingLocation, setIsLoadingLocation] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAllDay, setIsAllDay] = useState(true);
    const { multiMerchants, setMerchants, setMerchantLoading } = useMerchantStore()

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token,
            },
        });
        return response.data.data;
    };
    const API_URL = process.env.REACT_APP_API_URL


    const handleReminderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedValue = customNumberFormat(e.target.value);
        formik.setFieldValue('reminder', formattedValue);
    };

    const handleCoordinate = async () => {
        try {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitudeMap(position.coords.latitude);
                        setLongitudeMap(position.coords.longitude);
                        handleReversLatLongToAddress(position.coords.latitude, position.coords.longitude);
                    },
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            var message1 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.TUTORIAL" })
                            alertErrorCoordinate(message1)
                        }
                    }
                );
            } else {
                var message2 = intl.formatMessage({ id: "BROWSER.SUPPORT.GEOLOCATION" })
                alertErrorCoordinate(message2);
            }
        } catch (error) {
            var message3 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.PERMISSION_DENIED" })
            alertErrorCoordinate(message3)
        }
    };

    const handleReversLatLongToAddress = (lat: number, long: number) => {
        fetch(`https://tool.looyal.id/general/gmaps/convert-latlong?lat=${lat}&long=${long}`)
            .then(response => response.json())
            .then(data => {
                formik.setFieldValue("location", data.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const alertErrorCoordinate = (message: string) => {
        Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: "ALERT.TITLE.ERROR" }),
            html: message,
            confirmButtonText: 'Ok',
            target: document.getElementById('add-meeting-modal'),
            heightAuto: false,
        });
        setLatitudeMap(0);
        setLongitudeMap(0);
        formik.setFieldValue("location", "");
    }

    const getUserURL = () => {
        if (user.data.permission_meeting_create === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data: merchants = [], error: merchantsError, isLoading: merchantsLoading } = useSWR(user.data.permission_merchant_read !== 'NONE' ? `${API_URL}merchants` : null, fetcher)
    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)
    const { data: userCompany = [], error: userCompanyError, isLoading: usersCompanyLoading } = useSWR(`${API_URL}calendars/company?includeSelf=true`, fetcher)
    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=meeting`, fetcher)


    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])


    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (typeMeeting === "schedule" || typeMeeting === "visit") {
            obj[item] = Yup.string().transform(value => Array.isArray(value) ? value.join(', ') : value).notRequired().nullable();
        } 
        else {
            if (field.type === 'multiple' || field.type === 'images' || field.type === 'files') {
                obj[item] = field.required
                    ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                    : Yup.array().notRequired().nullable();
            } else if (field.type === 'date') {
                obj[item] = field.required
                    ? Yup.string().required(isRequiredMessage).nullable()
                    : Yup.string().notRequired().nullable();
            } else if (field.type === 'link') {
                obj[item] = field.required
                    ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                        return !value || isValidUrl(value);
                    })
                    : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                        return !value || isValidUrl(value);
                    });
            } else if (field.type === 'serial') {
                obj[item] = Yup.array().notRequired().nullable();
            } else {
                obj[item] = field.required
                    ? Yup.string().required(isRequiredMessage).nullable()
                    : Yup.string().notRequired().nullable();
            }
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        merchant: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: settings.merchant_title })),
        labels: Yup.array().notRequired(),
        dateStart: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE" }, { title: settings.meeting_title + ' ' + selectMultiMeeting.name }) })).nullable(),
        dateEnd: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE" }, { title: settings.meeting_title + ' ' + selectMultiMeeting.name }) })).nullable(),
        ownerId: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PIC" }) })),
        attachment: Yup.string().nullable().test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PHOTO" }, { title: selectMultiMeeting.name }) }), function (value) {
            if (typeMeeting === 'report' && !settings.meeting_done_disable_capture_camera) {
                return !!value;
            } else if (typeMeeting === 'visit' && !settings.meeting_visit_disable_capture_camera) {
                return !!value;
            }
            return true;
        }),
        location: Yup.mixed().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LOCATION" }) })),
        reminder: Yup.string().notRequired().nullable(),
        note: Yup.string().notRequired().nullable(),
        userAttendees: user?.data.company_id === companyId.maxy ? Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" },
            { title: intl.formatMessage({ id: "FORM.LABEL.PARTICIPANT" }) })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" },
                { title: intl.formatMessage({ id: "FORM.LABEL.PARTICIPANT" }) })).nullable() :
            Yup.array().notRequired().nullable(),
        ...formSchema
    });

    const initialValues = {
        merchant: '',
        labels: '',
        dateStart: '',
        dateEnd: '',
        ownerId: '',
        attachment: '',
        location: '',
        status: '',
        reminder: '',
        note: '',
        userAttendees: [] as SelectOption[],
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key, null])),
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            var dateStart = moment(values.dateStart).format('YYYY-MM-DD');
            var dateStartTime = moment(values.dateStart).format('YYYY-MM-DD HH:mm');
            var nowDate = moment().format('YYYY-MM-DD');
            var nowDateTime = moment().format('YYYY-MM-DD HH:mm');

            if (typeMeeting === "report") {
                if (isAllDay && moment(dateStart).isAfter(nowDate)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.EXECEED_TODAY' })}`,
                        heightAuto: false,
                        target: document.getElementById('add-meeting-modal'),
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (!isAllDay && moment(dateStartTime).isAfter(nowDateTime)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.EXECEED_HOUR' })}`,
                        heightAuto: false,
                        target: document.getElementById('add-meeting-modal'),
                        confirmButtonText: 'Ok'
                    })

                    return false
                }
            }

            if (typeMeeting === "schedule") {
                if (isAllDay && moment(dateStart).isBefore(nowDate)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.EARLIER_TODAY' })}`,
                        heightAuto: false,
                        target: document.getElementById('add-meeting-modal'),
                        confirmButtonText: 'Ok'
                    })

                    return false
                } else if (!isAllDay && moment(dateStartTime).isBefore(nowDateTime)) {
                    Swal.fire({
                        icon: "error",
                        title: `${intl.formatMessage({ id: 'FORM.VALIDATION.EARLIER_HOUR' })}`,
                        heightAuto: false,
                        target: document.getElementById('add-meeting-modal'),
                        confirmButtonText: 'Ok'
                    })

                    return false
                }
            }

            if (!isAllDay && new Date(values.dateEnd).getTime() < new Date(values.dateStart).getTime()) {
                Swal.fire({
                    icon: "error",
                    title: intl.formatMessage({ id: "FORM.VALIDATION.END_DATE_OR_HOUR" }),
                    heightAuto: false,
                    target: document.getElementById('add-meeting-modal'),
                    confirmButtonText: 'Ok'
                })

                return false
            }

            var pic_name = user.data.permission_meeting_create === 'TEAM' || user.data.permission_meeting_create === 'ALL' ? usersData.find(option => option.value === values.ownerId)?.label : user.data.name

            if (typeMeeting === "schedule") {
                Swal.fire({
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.NOTIFICATION' }),
                    html: intl.formatMessage({ id: 'FORM.VALIDATION.SEND_NOTIFICATION' }),
                    icon: "question",
                    showCancelButton: true,
                    showDenyButton: true,
                    buttonsStyling: false,
                    confirmButtonText: intl.formatMessage({ id: 'FORM.ACTION.SEND_NOTIFICATION' }),
                    denyButtonText: intl.formatMessage({ id: 'FORM.ACTION.DONT_SEND_NOTIFICATION' }),
                    cancelButtonText: intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
                    customClass: {
                        confirmButton: "btn btn-active-color-primary",
                        denyButton: "btn btn-active-color-gray-400",
                        cancelButton: "btn btn-active-color-gray-400",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    target: document.getElementById('add-meeting-modal'),
                }).then(async (result) => {
                    var notification = false

                    if (result.isConfirmed) {
                        notification = true
                    } else if (result.isDenied) {
                        notification = false
                    }

                    if (!result.isDismissed) {
                        setModalBtnLoading(true)
                        setSubmitting(true)

                        // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                        const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                            (obj, item) => ({
                                ...obj,
                                [item]: (values as Record<string, string | string[] | SelectOption[] | null>)[item],
                            }),
                            {}
                        );

                        // Use Promise.all to await all async operations
                        var countResourceError = 0
                        await Promise.all(
                            Object.keys(customFields).map(async (key) => {
                                if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                                    try {
                                        const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as any);
                                        if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                        else countResourceError++
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                                    try {
                                        if (values[key as keyof typeof values]) {
                                            const resourceResponse = await insertMeetingFieldMultiAttachment(values[key as keyof typeof values] as any);
                                            if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                                            else countResourceError++
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'multiple') {
                                    var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                                    customFieldsValues[key] = multipleValues as string
                                } else if (customFields[key].type === 'serial') {
                                    try {
                                        if (dataCustomFieldSerial[key].value.length === 0) {
                                            const serialResponse = await generateSerialApi(key, "Meeting", token);
                                            customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                        } else {
                                            customFieldsValues[key] = dataCustomFieldSerial[key].value
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                }
                            })
                        );

                        values.status = "PLANNED"

                        if (countResourceError > 0) {
                            Swal.fire({
                                icon: "error",
                                text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" })}.`,
                                heightAuto: false,
                                target: document.getElementById('add-meeting-modal'),
                                confirmButtonText: 'Ok'
                            })
                            setModalBtnLoading(false);
                            setSubmitting(false);
                        } else {
                            try {
                                let attachments: MeetingAttachment[] = []
                                if (values.attachment) {
                                    const resourceResponse = await insertMeetingAttachment(values.attachment)
                                    const parts = resourceResponse.data.data[0].split('/');
                                    const fileName = parts[parts.length - 1];
                                    attachments = [
                                        {
                                            name: fileName,
                                            url: resourceResponse.data.data[0]
                                        }
                                    ]
                                }
                                const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []
                                const response = await insertMeeting({
                                    meetingTemplateId: codeUrl,
                                    merchantId: values.merchant,
                                    start: {
                                        date: isAllDay ? moment(`${values.dateStart}`).format("YYYY-MM-DD") : null,
                                        dateTime: !isAllDay ? moment(`${values.dateStart}`).toISOString(true) : null
                                    },
                                    end: {
                                        date: isAllDay ? moment(`${values.dateEnd}`).add(isAllDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                                        dateTime: !isAllDay ? moment(`${values.dateEnd}`).add(isAllDay ? 1 : 0, 'days').toISOString(true) : null,
                                    },
                                    ownerId: user.data.permission_meeting_create === 'TEAM' || user.data.permission_meeting_create === 'ALL' ? values.ownerId : user.data.id,
                                    attachments: attachments,
                                    token: token,
                                    status: values.status,
                                    note: values.note,
                                    reminderMinutes: values.reminder === "" ? null : values.reminder.split('.').join(''),
                                    userAttendees: values.userAttendees.reduce((acc: { id: string }[], e: { label: string, value: string }) => {
                                        if (e.label !== pic_name) {
                                            acc.push({ id: e.value });
                                        }
                                        return acc;
                                    }, []),
                                    sendNotification: notification,
                                    latitude: String(latitudeMap),
                                    longitude: String(longitudeMap),
                                    location: values.location,
                                    customFields: customFieldsValues,
                                    meetingLabels
                                })
                                if (response.data.success) {
                                    Swal.fire({
                                        icon: "success",
                                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                                        // target: document.getElementById('add-meeting-modal'),
                                        heightAuto: false,
                                    })


                                    if (moment(values.dateStart).isBefore(moment(startDate))) {
                                        setStartDate(new Date(values.dateStart))
                                    } else if (moment(values.dateEnd).isAfter(moment(endDate))) {
                                        setEndDate(new Date(values.dateEnd))
                                    } else {
                                        setTablesData('meetings', ([
                                            ...tablesData.meetings,
                                            {
                                                ...response.data.data,
                                                date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                                date_meet_end: response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                                status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                            },
                                        ]))
                                    }

                                    closeModalRef.current?.click();
                                }
                            } catch (error: any) {
                                Swal.fire({
                                    icon: 'error',
                                    title: error.response.data.message,
                                    confirmButtonText: 'Ok',
                                    target: document.getElementById('add-meeting-modal'),
                                    heightAuto: false,
                                })
                            } finally {
                                setModalBtnLoading(false)
                                setSubmitting(false)
                            }
                        }
                    }
                })
            } else if (typeMeeting === "report") {
                Swal.fire({
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.CONFIRMATION' }),
                    html: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CONFIRMATION_DATA' }),
                    icon: "question",
                    showCancelButton: true,
                    buttonsStyling: false,
                    confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }),
                    cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
                    customClass: {
                        confirmButton: "btn btn-color-primary",
                        cancelButton: "btn btn-active-color-gray-400",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    target: document.getElementById('add-meeting-modal'),
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setModalBtnLoading(true)
                        setSubmitting(true)

                        // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                        const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                            (obj, item) => ({
                                ...obj,
                                [item]: (values as Record<string, string | string[] | SelectOption[] | null>)[item],
                            }),
                            {}
                        );

                        // Use Promise.all to await all async operations
                        var countResourceError = 0
                        await Promise.all(
                            Object.keys(customFields).map(async (key) => {
                                if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                                    try {
                                        const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as any);
                                        if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                        else countResourceError++
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                                    try {
                                        if (values[key as keyof typeof values]) {
                                            const resourceResponse = await insertMeetingFieldMultiAttachment(values[key as keyof typeof values] as any);
                                            if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                                            else countResourceError++
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                } else if (customFields[key].type === 'multiple') {
                                    var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                                    customFieldsValues[key] = multipleValues as string
                                } else if (customFields[key].type === 'serial') {
                                    try {
                                        if (dataCustomFieldSerial[key].value.length === 0) {
                                            const serialResponse = await generateSerialApi(key, "Meeting", token);
                                            customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                        } else {
                                            customFieldsValues[key] = dataCustomFieldSerial[key].value
                                        }
                                    } catch (error) {
                                        countResourceError++
                                    }
                                }
                            })
                        );

                        values.status = "COMPLETED"

                        if (countResourceError > 0) {
                            Swal.fire({
                                icon: "error",
                                text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_FAILED" })}.`,
                                heightAuto: false,
                                target: document.getElementById('add-meeting-modal'),
                                confirmButtonText: 'Ok'
                            })
                            setModalBtnLoading(false);
                            setSubmitting(false);
                        } else {
                            try {
                                let attachments: MeetingAttachment[] = []
                                if (values.attachment) {
                                    const resourceResponse = await insertMeetingAttachment(values.attachment)
                                    const parts = resourceResponse.data.data[0].split('/');
                                    const fileName = parts[parts.length - 1];
                                    attachments = [
                                        {
                                            name: fileName,
                                            url: resourceResponse.data.data[0]
                                        }
                                    ]
                                }
                                const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []

                                const response = await insertMeeting({
                                    meetingTemplateId: codeUrl,
                                    meetingLabels: meetingLabels,
                                    merchantId: values.merchant,
                                    start: {
                                        date: isAllDay ? moment(`${values.dateStart}`).format("YYYY-MM-DD") : null,
                                        dateTime: !isAllDay ? moment(`${values.dateStart}`).toISOString(true) : null
                                    },
                                    end: {
                                        date: isAllDay ? moment(`${values.dateEnd}`).add(isAllDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                                        dateTime: !isAllDay ? moment(`${values.dateEnd}`).add(isAllDay ? 1 : 0, 'days').toISOString(true) : null,
                                    },
                                    ownerId: user.data.permission_meeting_create === 'TEAM' || user.data.permission_meeting_create === 'ALL' ? values.ownerId : user.data.id,
                                    attachments: attachments,
                                    token: token,
                                    status: values.status,
                                    note: values.note,
                                    reminderMinutes: values.reminder === "" ? null : values.reminder.split('.').join(''),
                                    userAttendees: values.userAttendees.reduce((acc: { id: string }[], e: { value: string, label: string }) => {
                                        if (e.label !== pic_name) {
                                            acc.push({ id: e.value });
                                        }
                                        return acc;
                                    }, []),
                                    sendNotification: false,
                                    latitude: String(latitudeMap),
                                    longitude: String(longitudeMap),
                                    location: values.location,
                                    customFields: customFieldsValues
                                })
                                if (response.data.success) {
                                    Swal.fire({
                                        icon: "success",
                                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                        text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                                        // target: document.getElementById('add-meeting-modal'),
                                        heightAuto: false,
                                    })

                                    if (moment(values.dateStart).isBefore(moment(startDate))) {
                                        setStartDate(new Date(values.dateStart))
                                    } else if (moment(values.dateEnd).isAfter(moment(endDate))) {
                                        setEndDate(new Date(values.dateEnd))
                                    } else {
                                        setTablesData('meetings', ([
                                            ...tablesData.meetings,
                                            {
                                                ...response.data.data,
                                                date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                                date_meet_end: response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                                status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                            },
                                        ]))
                                    }

                                    closeModalRef.current?.click();
                                }
                            } catch (error: any) {
                                Swal.fire({
                                    icon: 'error',
                                    title: error.response.data.message,
                                    confirmButtonText: 'Ok',
                                    target: document.getElementById('add-meeting-modal'),
                                    heightAuto: false,
                                })
                            } finally {
                                setModalBtnLoading(false)
                                setSubmitting(false)
                            }
                        }
                    }
                })
            } else if (typeMeeting === "visit") {
                Swal.fire({
                    title: intl.formatMessage({ id: 'FORM.VALIDATION.CONFIRMATION' }),
                    html: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CONFIRMATION_DATA' }),
                    icon: "question",
                    showCancelButton: true,
                    buttonsStyling: false,
                    confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }),
                    cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
                    customClass: {
                        confirmButton: "btn btn-color-primary",
                        cancelButton: "btn btn-active-color-gray-400",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    target: document.getElementById('add-meeting-modal'),
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setModalBtnLoading(true)
                        setSubmitting(true)

                        // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
                        const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                            (obj, item) => ({
                                ...obj,
                                [item]: (values as Record<string, string | string[] | SelectOption[] | null>)[item],
                            }),
                            {}
                        );

                        // Use Promise.all to await all async operations
                        await Promise.all(
                            Object.keys(customFields).map(async (key) => {
                                if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                                    try {
                                        const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as any);
                                        customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                    } catch (error) {
                                        customFieldsValues[key] = null
                                    }
                                } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                                    try {
                                        if (values[key as keyof typeof values]) {
                                            const resourceResponse = await insertMeetingFieldMultiAttachment(values[key as keyof typeof values] as any);
                                            customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data : null;
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                } else if (customFields[key].type === 'multiple') {
                                    var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                                    customFieldsValues[key] = multipleValues as string
                                } else if (customFields[key].type === 'serial') {
                                    try {
                                        if (dataCustomFieldSerial[key].value.length === 0) {
                                            const serialResponse = await generateSerialApi(key, "Meeting", token);
                                            customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                                        } else {
                                            customFieldsValues[key] = dataCustomFieldSerial[key].value
                                        }
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }
                            })
                        );

                        try {
                            let attachments: MeetingAttachment[] = []
                            if (values.attachment) {
                                const resourceResponse = await insertMeetingAttachment(values.attachment)
                                const parts = resourceResponse.data.data[0].split('/');
                                const fileName = parts[parts.length - 1];
                                attachments = [
                                    {
                                        name: fileName,
                                        url: resourceResponse.data.data[0]
                                    }
                                ]
                            }
                            const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label } }) : []
                            const response = await insertVisitMeeting({
                                meetingTemplateId: codeUrl,
                                meetingLabels: meetingLabels,
                                merchantId: values.merchant,
                                ownerId: user.data.id,
                                attachmentLink: attachments.length > 0 ? attachments[0].url : null,
                                token: token,
                                note: values.note,
                                latitude: latitudeMap,
                                longitude: longitudeMap,
                                location: values.location,
                                customFields: customFieldsValues
                            })
                            if (response.data.success) {
                                Swal.fire({
                                    icon: "success",
                                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}.`,
                                    // target: document.getElementById('add-meeting-modal'),
                                    heightAuto: false,
                                })

                                if (moment(values.dateStart).isBefore(moment(startDate))) {
                                    setStartDate(new Date(values.dateStart))
                                } else if (moment(values.dateEnd).isAfter(moment(endDate))) {
                                    setEndDate(new Date(values.dateEnd))
                                } else {
                                    // const meetingLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label)=>{return{id:label}}):[]
                                    setTablesData('meetings', ([
                                        ...tablesData.meetings,
                                        {
                                            ...response.data.data,
                                            date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                            date_meet_end: null,
                                            status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                        },
                                    ]))
                                }
                                
                                closeModalRef.current?.click();
                                setVisit({
                                    permission: false,
                                    id: response.data.data.id,
                                    customFields: response.data.data.custom_fields
                                })
                            }
                        } catch (error: any) {
                            Swal.fire({
                                icon: 'error',
                                title: error.response.data.message,
                                confirmButtonText: 'Ok',
                                target: document.getElementById('add-meeting-modal'),
                                heightAuto: false,
                            })
                        } finally {
                            setModalBtnLoading(false)
                            setSubmitting(false)
                        }
                    }
                })
            }
        }
    });

    useEffect(() => {
        if ((user.data.permission_meeting_create !== 'TEAM' && user.data.permission_meeting_create !== 'ALL') || typeMeeting === 'visit') {
            formik.setFieldValue("ownerId", user.data.name)
            formik.setFieldValue("dateStart", moment().format('YYYY-MM-DD HH:mm'))
            formik.setFieldValue("dateEnd", moment().format('YYYY-MM-DD HH:mm'))
        }
    }, [user, typeMeeting])



    const loadOptions = async (inputValue: any) => {
        if (inputValue) {
            try {
                const res = await fetch('https://tool.looyal.id/general/gmaps/address-latlong?search=' + inputValue);
                const data = await res.json();
                const results = data.data;

                if (results.length === 0) {
                    setOptionsLocation([])
                } else {
                    const optionData = results.map((option: AddressLatlong) => ({
                        value: option.lat + ',' + option.lon,
                        label: option.address
                    }));

                    setOptionsLocation(optionData);
                }
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoadingLocation(false)
            }
        }
    }

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            if (!showAddMerchantModal) {
                setTypeMeeting('')
                formik.resetForm()
                fileInputRef.current?.value && (fileInputRef.current.value = "")
                formik.setFieldValue('dateStart', '')
                formik.setFieldValue('dateEnd', '')
                formik.setFieldValue('hourStart', '')
                formik.setFieldValue('hourEnd', '')
                formik.setFieldValue('location', '')
                setLatitudeMap(0)
                setLongitudeMap(0)
                setOptionsLocation([])
                // setSelectedAttendees([])

                Object.keys(customFields).map((key, index) => {
                    // Reset 
                    const ref = refArray.current[index];
                    if (ref && ref.value) {
                        ref.value = "";
                    }

                    if (customFields[key].type === "multiple") {
                        var checkboxes = document.getElementsByName(key)
                        checkboxes.forEach((checkbox: any) => {
                            checkbox.checked = false
                        })
                    }

                    if (customFields[key].type === "date") {
                        formik.setFieldValue(key, null)
                    }
                });
            }
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik, showAddMerchantModal, setShowAddMerchantModal]);

    useEffect(() => {
        const groupMerchant: { label: string, id: string, options: SelectOption[] }[] = multiMerchants.map((group) => {
            return {
                label: group.name,
                id: group.id,
                options: [] as SelectOption[]
            }
        })

        setMerchantLoading(merchantsLoading)

        if (!merchantsLoading && merchants.length > 0) {
            if(selectMultiMeeting.merchantTemplates.length === 0){
                merchants.forEach((merchant: any) => {
                    const index = groupMerchant.findIndex((group) => group.id === merchant.merchantTemplateId)
                    index !== -1 && groupMerchant[index].options.push({
                        value: merchant.id,
                        label: merchant.name + " / " + merchant.phone
                    })
                })
            } else {
                const filteredMerchants = merchants.filter((merchant:any) => selectMultiMeeting.merchantTemplates.some((merchantTemplate) => merchantTemplate.id === merchant.merchantTemplateId))
                filteredMerchants.forEach((merchant: any) => {
                    const index = groupMerchant.findIndex((group) => group.id === merchant.merchantTemplateId)
                    index !== -1 && groupMerchant[index].options.push({
                        value: merchant.id,
                        label: merchant.name + " / " + merchant.phone
                    })
                })
            }

            setMerchantData(groupMerchant)
        }
    }, [codeUrl, selectMultiMeeting, multiMerchants, merchants])

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));

            if (user.data.permission_meeting_create !== "ALL") {
                usersData.unshift({ value: user.data.id, label: user.data.name })
            }

            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [users]);

    useEffect(() => {
        if (!usersCompanyLoading) {
            const userCompanyData = userCompany.map((user: UserCompany) => ({
                value: user.id,
                label: user.ownerName,
            }));
            setUserCompanyData(userCompanyData)
        }
    }, [userCompany]);

    useEffect(() => {
        if (showAddMerchantModal) {
            const modalElement = document.getElementById(`add-merchant-modal`);
            const modalElementAddMeeting = document.getElementById(`add-meeting-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                const modalInstanceAddMeeting = new Modal(modalElementAddMeeting!);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAddMerchantModal(false);
                    modalInstanceAddMeeting.show()
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAddMerchantModal, setShowAddMerchantModal]);

    const addMerchant = (merchant: Merchant) => {
        // setMerchantData([...merchantData, {
        //     value: merchant.id,
        //     label: merchant.name
        // }]);

        formik.setFieldValue("merchant", merchant.id)
    }

    useEffect(() => {
        if (typeMeeting === 'report' || typeMeeting === 'visit') {
            handleCoordinate()
        }
    }, [typeMeeting])

    useEffect(() => {
        if (Object.keys(selectMultiMeeting).length !== 0) {
            const data = selectMultiMeeting.customFields
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: []
                };
                return acc;
            }, {});            

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [selectMultiMeeting, codeUrl])

    // useEffect(() => {
    //     if (formik.values.merchantTemplateId) {
    //         setCustomFields(multiMerchants.find((pipeline: SettingMultiMerchants) => pipeline.id === formik.values.merchantTemplateId)!.customFields)
    //     }
    // }, [formik.values.merchantTemplateId])

    // useEffect(() => {
    //     if (settings.meeting_custom_fields) {
    //         var data = JSON.parse(settings.meeting_custom_fields)
    //         const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
    //             acc[key] = {
    //                 loading: false,
    //                 value: []
    //             };
    //             return acc;
    //         }, {});

    //         setDataCustomFieldSerial(transformedData)
    //         console.log('datadatadata',data)
    //         setCustomFields(data)
    //     }
    // }, [settings, typeMeeting])

    useEffect(() => {
        const typingTimer = setTimeout(() => {
            loadOptions(searchTerm);
        }, 1000);

        return () => clearTimeout(typingTimer);
    }, [searchTerm]);

    const handleAttendeesChange = (selectedOptions: any) => {
        // setSelectedAttendees(selectedOptions)
        formik.setFieldValue('userAttendees', selectedOptions)
    };

    const handleSearchLocation = (value: string) => {
        if (value) setIsLoadingLocation(true)
        setSearchTerm(value);
    }

    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Meeting", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('add-meeting-modal'),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }

    const customMarker = new L.Icon({
        iconUrl: toAbsoluteUrl('/media/logos/marker.png'), // Tentukan URL gambar ikon
        iconSize: [38, 38], // Tentukan ukuran ikon [lebar, tinggi]
        iconAnchor: [19, 38], // Tentukan titik anchor ikon [posisi x, posisi y]
        popupAnchor: [0, -38] // Tentukan titik anchor popup [posisi x, posisi y]
    });

    const handleTakePhoto = async () => {
        if (capturePhoto) {
            var file = dataURLtoFile(capturePhoto, getRandomFileName() + '.jpeg', Buffer);
            formik.setFieldValue('attachment', file)
        } else {
            formik.setFieldValue('attachment', null)
        }
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);
    const labelsOptionMock = [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
        { label: "Option 3", value: "option3" },
    ];

    return (
        <div>
            {
                settings.feature_meeting_mode === "0" ?
                    <Dropdown renderToggle={renderButton} title={`${intl.formatMessage({ id: 'FORM.ACTION.ADD' })} ${selectMultiMeeting.name}`} noCaret placement="bottomEnd">
                        <Dropdown.Item data-bs-toggle="modal" data-bs-target="#add-meeting-modal" onClick={() => setTypeMeeting("schedule")}>{intl.formatMessage({ id: 'MEETING.ACTION.SCHEDULED' })}</Dropdown.Item>
                        <Dropdown.Item data-bs-toggle="modal" data-bs-target="#add-meeting-modal" onClick={() => setTypeMeeting("report")}>{intl.formatMessage({ id: 'MEETING.ACTION.FINISHED' })}</Dropdown.Item>
                        <Dropdown.Item data-bs-toggle="modal" data-bs-target="#add-meeting-modal" onClick={() => setTypeMeeting("visit")}>{intl.formatMessage({ id: 'MEETING.ACTION.VISIT' })}</Dropdown.Item>
                    </Dropdown>
                    :
                    <button type="button"
                        className="btn btn-primary mt-3"
                        data-bs-toggle="modal"
                        data-bs-target="#add-meeting-modal"
                        onClick={() => setTypeMeeting(settings.feature_meeting_mode === "1" ? "report" : "schedule")}
                    >
                        <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {selectMultiMeeting.name}
                    </button>
            }


            <div className="modal fade" tabIndex={-1} id="add-meeting-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            {
                                typeMeeting === "visit" ?
                                    <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'MEETING.STATUS.VISIT' })}</h5>
                                    :
                                    <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {selectMultiMeeting.name}</h5>
                            }
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* PIC */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.PIC' })}</label>
                                <div className="input-group mb-3">
                                    {
                                        user.data.permission_meeting_create === 'TEAM' || user.data.permission_meeting_create === 'ALL' ?
                                            typeMeeting !== 'visit' ?
                                                <Select
                                                    styles={{
                                                        menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999
                                                        })
                                                    }}
                                                    // key={nanoid()}
                                                    options={usersData}
                                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PIC' })}`}
                                                    value={usersData.find(option => option.value === formik.values.ownerId) || null}
                                                    onChange={
                                                        option => formik.handleChange({
                                                            target: {
                                                                name: "ownerId",
                                                                value: option?.value || ""
                                                            }
                                                        })
                                                    }
                                                    onBlur={
                                                        () => formik.values.ownerId === "" && formik.setFieldTouched("ownerId", true)
                                                    }
                                                    className="w-100 z-index-3"
                                                    isDisabled={usersLoading}
                                                />
                                                :
                                                <input
                                                    {...formik.getFieldProps('ownerId')}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': formik.touched.ownerId && formik.errors.ownerId },
                                                    )}
                                                    type='text'
                                                    name='ownerId'
                                                    autoComplete='off'
                                                    onInput={(e) => handleCaps(e)}
                                                    disabled
                                                />
                                            :
                                            <input
                                                {...formik.getFieldProps('ownerId')}
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                    { 'is-invalid': formik.touched.ownerId && formik.errors.ownerId },
                                                )}
                                                type='text'
                                                name='ownerId'
                                                autoComplete='off'
                                                onInput={(e) => handleCaps(e)}
                                                disabled
                                            />
                                    }
                                </div>
                                {formik.touched.ownerId && formik.errors.ownerId && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.ownerId}</span>
                                    </div>
                                )}
                                {/* Merchants */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{settings.merchant_title}</label>
                                <div className="row mb-3">
                                    <Select
                                        styles={{
                                            menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999
                                            })
                                        }}
                                        // key={nanoid()}
                                        options={merchantData}
                                        placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${settings.merchant_title}`}
                                        value={merchantData.map(merchanttemplate => merchanttemplate.options).flat().find((option) => option.value === formik.values.merchant) || null}
                                        onChange={
                                            option => formik.handleChange({
                                                target: {
                                                    name: "merchant",
                                                    value: option?.value || ""
                                                }
                                            })
                                        }
                                        onBlur={
                                            () => formik.values.merchant === "" && formik.setFieldTouched("merchant", true)
                                        }
                                        className="col-10 z-index-2"
                                        isDisabled={merchantsLoading}
                                    />
                                    <button type="button" className="btn btn-secondary col-2 btn-sm" data-bs-dismiss="modal" onClick={() => {
                                        setShowAddMerchantModal(true);
                                    }}>
                                        <i className="fas fa-plus"></i>
                                    </button>
                                </div>
                                {formik.touched.merchant && formik.errors.merchant && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.merchant}</span>
                                    </div>
                                )}
                                {/* label */}
                                {
                                    user.data.company_id !== companyId.esb &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                        <div className="input-group mb-3">
                                            <Select
                                                styles={{
                                                    menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999
                                                    })
                                                }}
                                                closeMenuOnSelect={false}
                                                openMenuOnClick
                                                isMulti
                                                options={labelsOption}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                                value={labelsOption && Array.isArray(labelsOption)
                                                    ? labelsOption.filter((option: any) =>
                                                        formik.values.labels.includes(option.value)
                                                    )
                                                    : []}
                                                onChange={(selectedOptions) => {
                                                    const selectedValues = selectedOptions
                                                        ? selectedOptions.map((option: any) => option.value)
                                                        : []
                                                    formik.setFieldValue('labels', selectedValues)
                                                }}
                                                className='w-100'
                                                onBlur={() =>
                                                    formik.values.labels === '' &&
                                                    formik.setFieldTouched('labels', true)
                                                }
                                            />
                                        </div>
                                        {formik.touched.labels && formik.errors.labels && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                            </div>
                                        )}
                                    </>
                                }
                                {/* Date */}
                                {
                                    typeMeeting !== 'visit' &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.DATE' }, { title: selectMultiMeeting.name })}</label>
                                        <div className="row mb-2">
                                            {/* StartDate */}
                                            <div className="input-group">
                                                <DatePicker
                                                    oneTap
                                                    style={{ width: "100%", zIndex: "0" }}
                                                    cleanable={false}
                                                    editable={false}
                                                    format="dd/MM/yyyy"
                                                    container={document.querySelector('#add-meeting-modal') as HTMLElement}
                                                    value={formik.values.dateStart ? new Date(formik.values.dateStart) : null}
                                                    onChange={(value: any) => {
                                                        var date = moment(value).format('YYYY-MM-DD');

                                                        if (typeMeeting === "schedule") {
                                                            if (value && !isBefore(new Date(date), startOfToday())) {
                                                                formik.setFieldValue('dateStart', value)
                                                                formik.setFieldValue('dateEnd', value)
                                                            } else {
                                                                formik.setFieldValue('dateStart', null)
                                                                formik.setFieldValue('dateEnd', null)
                                                            }
                                                        } else {
                                                            if (value && !isAfter(new Date(date), new Date())) {
                                                                formik.setFieldValue('dateStart', value)
                                                                formik.setFieldValue('dateEnd', value)
                                                            } else {
                                                                formik.setFieldValue('dateStart', null)
                                                                formik.setFieldValue('dateEnd', null)
                                                            }
                                                        }
                                                    }}
                                                    shouldDisableDate={date => {
                                                        if (typeMeeting === "schedule") return isBefore(date, startOfToday());
                                                        else return isAfter(date, new Date());
                                                    }}
                                                />
                                            </div>
                                            {formik.touched.dateStart && formik.errors.dateStart && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{formik.errors.dateStart}</span>
                                                </div>
                                            )}
                                        </div>
                                        {
                                            !isAllDay &&
                                            <>
                                                <div className="row mb-2">
                                                    <div className="col-6">
                                                        {/* StartHour */}
                                                        <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.HOUR_START" }, { title: "" })}</label>
                                                        <div className="input-group">
                                                            <DatePicker
                                                                style={{ width: "100%", zIndex: "0" }}
                                                                cleanable={false}
                                                                editable={false}
                                                                format="HH:mm"
                                                                container={document.querySelector('#add-meeting-modal') as HTMLElement}
                                                                value={formik.values.dateStart ? new Date(formik.values.dateStart) : null}
                                                                onChange={(value: any) => {
                                                                    if (value) {
                                                                        var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                                        var now = moment().format('YYYY-MM-DD HH:mm');
                                                                        var isBeforeDate = moment(val).isBefore(now)
                                                                        var isAfterDate = moment(val).isAfter(now)

                                                                        if (typeMeeting === "schedule" && isBeforeDate) formik.setFieldValue('dateStart', new Date())
                                                                        else if (typeMeeting === "report" && isAfterDate) formik.setFieldValue('dateStart', new Date())
                                                                        else formik.setFieldValue('dateStart', value)
                                                                    } else formik.setFieldValue('dateStart', null)
                                                                }}
                                                                shouldDisableHour={(hour: number, date: Date) => {
                                                                    const selectedDate = moment(date);
                                                                    const now = moment();

                                                                    if (selectedDate.isSame(now, 'day')) {
                                                                        return typeMeeting === "schedule" ? hour < now.hour() : hour > now.hour();
                                                                    }
                                                                    return false;
                                                                }}
                                                                shouldDisableMinute={(minute: number, date: Date) => {
                                                                    const selectedDate = moment(date);
                                                                    const now = moment();

                                                                    if (selectedDate.isSame(now, 'hour')) {
                                                                        return typeMeeting === "schedule" ? minute < now.minute() : minute > now.minute();
                                                                    }
                                                                    return false;
                                                                }}
                                                            />
                                                        </div>
                                                        {formik.touched.dateStart && formik.errors.dateStart && (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors.dateStart}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-6">
                                                        {/* EndHour */}
                                                        <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.HOUR_END" }, { title: "" })}</label>
                                                        <div className="input-group">
                                                            <DatePicker
                                                                style={{ width: "100%", zIndex: "0" }}
                                                                cleanable={false}
                                                                editable={false}
                                                                placement="bottomEnd"
                                                                format="HH:mm"
                                                                container={document.querySelector('#add-meeting-modal') as HTMLElement}
                                                                value={formik.values.dateEnd ? new Date(formik.values.dateEnd) : null}
                                                                onChange={(value: any) => {
                                                                    if (value) {
                                                                        var val = moment(value).format('YYYY-MM-DD HH:mm');
                                                                        var now = moment().format('YYYY-MM-DD HH:mm');
                                                                        var isBeforeDate = moment(val).isBefore(now)
                                                                        var isAfterDate = moment(val).isAfter(now)

                                                                        if (typeMeeting === "schedule" && isBeforeDate) formik.setFieldValue('dateEnd', new Date())
                                                                        else if (typeMeeting === "report" && isAfterDate) formik.setFieldValue('dateEnd', new Date())
                                                                        else formik.setFieldValue('dateEnd', value)
                                                                    } else formik.setFieldValue('dateEnd', null)
                                                                }}
                                                                shouldDisableHour={(hour: number, date: Date) => {
                                                                    const selectedDate = moment(date);
                                                                    const now = moment();

                                                                    if (selectedDate.isSame(now, 'day')) {
                                                                        return typeMeeting === "schedule" ? hour < now.hour() : hour > now.hour();
                                                                    }
                                                                    return false;
                                                                }}
                                                                shouldDisableMinute={(minute: number, date: Date) => {
                                                                    const selectedDate = moment(date);
                                                                    const now = moment();

                                                                    if (selectedDate.isSame(now, 'hour')) {
                                                                        return typeMeeting === "schedule" ? minute < now.minute() : minute > now.minute();
                                                                    }
                                                                    return false;
                                                                }}
                                                            />
                                                        </div>
                                                        {formik.touched.dateEnd && formik.errors.dateEnd && (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors.dateEnd}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="form-check mb-3">
                                            <label className="form-check-label" htmlFor="isAllDay">
                                                Sepanjang hari
                                            </label>
                                            <input className="form-check-input" type="checkbox" id="isAllDay"
                                                checked={isAllDay}
                                                onChange={() => {
                                                    setIsAllDay(!isAllDay)

                                                    if (isAllDay) {
                                                        formik.handleChange({
                                                            target: {
                                                                name: "dateEnd",
                                                                value: formik.values.dateStart ? new Date(formik.values.dateStart) : null
                                                            }
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                }
                                {/* Attachments */}
                                {
                                    (typeMeeting === 'report' || typeMeeting === 'visit') &&
                                    <>
                                        {
                                            typeMeeting === 'report' && !settings.meeting_done_disable_capture_camera ?
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: 'FORM.LABEL.PHOTO' }, { title: selectMultiMeeting.name })}</label>
                                                    <InputAttachment keyString={'attachment'} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                </>
                                                :
                                                <>
                                                    {!settings.meeting_visit_disable_capture_camera && (
                                                        <>
                                                            <label className="form-label fs-6 fw-bolder text-dark required">
                                                                {intl.formatMessage({ id: 'FORM.LABEL.PHOTO' }, { title: selectMultiMeeting.name })}
                                                            </label>

                                                            {camera.isCamera ? (
                                                                <CapturePhoto
                                                                    keyString="visit"
                                                                    img={capturePhoto!}
                                                                    setImg={setCapturePhoto!}
                                                                    func={handleTakePhoto}
                                                                    modalBtnLoading={modalBtnLoading!}
                                                                />
                                                            ) : (
                                                                <div className="mb-5 alert alert-danger">
                                                                    <div className="alert-text font-weight-bold">
                                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT1' }, { title: intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.CAMERA' }) })}<br /><br />
                                                                        <b>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT2' }, { title: intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.CAMERA' }) })}</b><br />
                                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT3' })}<br />
                                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT4' }, { title: intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.CAMERA' }) })}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </>

                                        }
                                        {formik.touched.attachment && formik.errors.attachment && (
                                            <div className='fv-plugins-message-container text-danger'>
                                                <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                            </div>
                                        )}
                                    </>
                                }
                                {/* Coordinat Location */}
                                <span className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LOCATION' })}</span><br />
                                {
                                    typeMeeting === 'report' || typeMeeting === 'visit' ?
                                        <>
                                            <small>{intl.formatMessage({ id: 'FORM.GUIDE.ACTIVE_GPS' })}</small>
                                            <div className="d-flex">
                                                <div className="input-group mb-3">
                                                    <input
                                                        {...formik.getFieldProps('location')}
                                                        className={clsx(
                                                            'form-control form-control-lg me-3',
                                                            { 'is-invalid': formik.touched.location && formik.errors.location },
                                                        )}
                                                        type='text'
                                                        name='location'
                                                        autoComplete='off'
                                                        disabled={true}
                                                    />
                                                </div>
                                                <button type="button" className="btn btn-secondary mb-3" onClick={handleCoordinate}>
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <div className="input-group mb-3">
                                            <Select
                                                styles={{
                                                    menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999
                                                    })
                                                }}
                                                isLoading={isLoadingLocation}
                                                key={"key_coordinate"}
                                                options={optionsLocation}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.PLACEHOLDER.TYPE_LOCATION' })}`}
                                                value={optionsLocation.find((option) => option.label === formik.values.location) || null}
                                                onChange={
                                                    option => {
                                                        try {
                                                            formik.handleChange({
                                                                target: {
                                                                    name: "location",
                                                                    value: option?.label || ""
                                                                }
                                                            })
                                                            setLatitudeMap(Number(option?.value.split(',')[0]) || 0)
                                                            setLongitudeMap(Number(option?.value.split(',')[1]) || 0)
                                                        } catch {

                                                        }
                                                    }
                                                }
                                                className="w-100 "
                                                onBlur={
                                                    () => formik.values.location === "" && formik.setFieldTouched("location", true)
                                                }
                                                isOptionDisabled={() => isLoadingLocation}
                                                onInputChange={(e) => handleSearchLocation(e)}
                                            />
                                        </div>
                                }
                                {formik.touched.location && formik.errors.location && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.location}</span>
                                    </div>
                                )}

                                {
                                    (latitudeMap !== 0 && longitudeMap !== 0) &&
                                    (
                                        typeMeeting === 'schedule' ?
                                            <MapContainer key={`${latitudeMap}-${longitudeMap}`} center={[latitudeMap, longitudeMap]} zoom={22} scrollWheelZoom={true} style={{ height: '300px', width: '100%', zIndex: "0" }} attributionControl={false} preferCanvas={false}>
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={[latitudeMap, longitudeMap]} icon={customMarker} autoPanOnFocus>
                                                </Marker>
                                            </MapContainer>
                                            :
                                            <MapPigeon key={`${latitudeMap}-${longitudeMap}`} height={200} defaultCenter={[latitudeMap, longitudeMap]} defaultZoom={16} attribution={false} >
                                                <MarkerPigeon
                                                    key={`${latitudeMap}-${longitudeMap}`}
                                                    width={50}
                                                    height={50}
                                                    anchor={[latitudeMap, longitudeMap]}
                                                    color={color}
                                                />
                                            </MapPigeon>
                                    )
                                }

                                {/* Participant */}
                                {
                                    typeMeeting !== 'visit' &&
                                    <>
                                        <label className={`form-label fs-6 fw-bolder text-dark ${user?.data.company_id === companyId.maxy && 'required'}`}>{intl.formatMessage({ id: 'FORM.LABEL.PARTICIPANT' })}</label>
                                        <div className="input-group mb-3">
                                            <Select
                                                styles={{
                                                    menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 9999
                                                    })
                                                }}
                                                openMenuOnClick
                                                closeMenuOnSelect={false}
                                                options={userCompanyData}
                                                placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PARTICIPANT' })}`}
                                                isMulti
                                                value={formik.values.userAttendees}
                                                className="w-100"
                                                isDisabled={usersLoading}
                                                onChange={handleAttendeesChange}
                                            />
                                        </div>
                                    </>

                                }
                                {/* Menambahkan error di FORM.LABEL.PARTICIPANT */}
                                {formik.touched.userAttendees && formik.errors.userAttendees && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.userAttendees}</span>
                                    </div>
                                )}
                                {/* Reminder */}
                                {
                                    typeMeeting === "schedule" &&
                                    <>
                                        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.REMINDER' })}</label>
                                        <div className="input-group mb-5">
                                            <input {...formik.getFieldProps('reminder')}
                                                className={clsx(
                                                    'form-control form-control-lg',
                                                    { 'is-invalid': formik.touched.reminder && formik.errors.reminder },
                                                )}
                                                type='text'
                                                name='reminder'
                                                autoComplete='off'
                                                onChange={(e) => handleReminderChange(e)}
                                                onInput={(e) => handleCaps(e)}
                                                placeholder={intl.formatMessage({ id: 'FORM.LABEL.REMINDER' })}
                                                aria-describedby="insert-reminder" />
                                            <span className="input-group-text" id="insert-reminder">{intl.formatMessage({ id: 'MINUTES' })}</span>
                                        </div>
                                    </>
                                }
                                {/* Note */}
                                {
                                    user?.data.company_id === companyId.maxy ? (
                                        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.DISCUSSION_NOTES' })}</label>
                                    ) : (
                                        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}</label>
                                    )
                                }
                                <div className="input-group mb-3">
                                    <textarea
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}
                                        id="floatingTextarea"
                                        {...formik.getFieldProps('note')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.note && formik.errors.note },
                                        )}
                                        style={{ zIndex: 0 }}
                                        name="note"
                                        onInput={(e) => handleCaps(e)}
                                    >
                                    </textarea>
                                </div>
                                {formik.touched.note && formik.errors.note && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.note}</span>
                                    </div>
                                )}
                                {/* 
                                {
                                    customFields &&
                                    <RenderCustomFieldGeneralForm
                                        customFieldGeneral={customFields}
                                        formik={formik}
                                        errors={formik.errors}
                                        containerRef={document.querySelector('#add-meeting-modal') as HTMLElement}
                                        dataCustomFieldSerial={dataCustomFieldSerial}
                                        handleGenerateSerial={handleGenerateSerial}
                                        inputRefCustomField={inputCustomFieldRef}
                                    />
                                } */}
{
                                    customFields && Object.keys(customFields).map((key, index) => {
                                        if (customFields[key].type === 'text') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            onInput={(e) => handleCaps(e)}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'serial') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                    {
                                                        dataCustomFieldSerial[key].loading ?
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                                <l-tailspin
                                                                    size="15"
                                                                    stroke="3"
                                                                    speed="0.9"
                                                                    color="#ffffff"
                                                                ></l-tailspin>
                                                            </span>
                                                            :
                                                            <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                    }
                                                    <div className="d-flex mb-3 gap-3">
                                                        <input
                                                            disabled
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                            className={clsx('form-control form-control-lg')}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'link') {
                                            return (
                                                <div key={index}>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            placeholder={customFields[key].name}
                                                            {...formik.getFieldProps(key)}
                                                            className={clsx(
                                                                'form-control form-control-lg txt-link',
                                                                { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                            )}
                                                            type='text'
                                                            name={key}
                                                            autoComplete='off'
                                                            style={{ zIndex: 0 }}
                                                            required={true}
                                                            ref={ref => {
                                                                refArray.current[index] = ref; // took this from your guide's example.
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'phone') {
                                            return (
                                                <div>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</label>
                                                    <InputPhone keyString={key} formik={formik} type="input" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        } else if (customFields[key].type === 'image') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format JPG, JPEG, PNG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'images') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format JPG, JPEG, PNG</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="image/png, image/jpeg, image/jpg" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'file') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={false} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'files') {
                                            return (
                                                <>
                                                    {/* Attachments */}
                                                    <span className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</span><br />
                                                    <small><i>*Format PDF, XLS, XLSX, DOCX, PPT</i></small>
                                                    <InputAttachment keyString={key} formik={formik} isMulti={true} type="input" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'date') {
                                            return (
                                                <>
                                                    {/* Date */}
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <DatePicker
                                                            ranges={todayRange}
                                                            oneTap
                                                            editable={false}
                                                            format="dd MMM yyyy"
                                                            value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values] as any) : null}
                                                            defaultValue={null}
                                                            style={{ width: "100%", zIndex: 0 }}
                                                            container={document.querySelector('#add-meeting-modal') as HTMLElement}
                                                            onChange={(value: any) => {
                                                                if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                                else formik.setFieldValue(key, null)
                                                            }}
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'option') {

                                            const options = customFields[key].value.map((option, index) => ({
                                                value: option,
                                                label: option
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${(customFields[key].required && typeMeeting === 'report') && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            key={nanoid()}
                                                            isClearable
                                                            options={options}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                            value={options.find(option => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: key,
                                                                        value: option?.value || ""
                                                                    }
                                                                })
                                                            }
                                                            onBlur={
                                                                () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                            }
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        } else if (customFields[key].type === 'multiple') {
                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && typeMeeting === 'report' && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <div className="form-check form-check-custom form-check-solid" style={{ display: "contents" }}>
                                                            {
                                                                customFields[key].value.map((option, index) => (
                                                                    <div style={{ display: "flex", padding: "3px", overflowWrap: "anywhere" }} key={index}>
                                                                        <input
                                                                            key={index}
                                                                            {...formik.getFieldProps(key)}
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={option as string}
                                                                            name={key}
                                                                        />
                                                                        <label className="form-check-label me-2">{option as string}</label>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                        return null
                                    })
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >

            {
                showAddMerchantModal && <AddMerchant addMerchant={addMerchant} />
            }
        </div >
    )
}

export { AddMeeting }