import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationMerchnatProps = ({intl, settings}:IVarDocumentationProps )=>{    
    // ===================== GET CUSTOM FIELD MERCHANT =====================
    const getSettingExample = {
        data: {
            key: "merchant_custom_fields",
            value: "{\"d1c15a71-a8c1-4c88-b76e-09dd347f1394\":{\"name\":\"Gambar byk\",\"type\":\"images\",\"required\":false,\"value\":[],\"score\":\"\"},\"e39f9j38-022e-45a4-96b8-c001aad1dcc0\":{\"name\":\"File byk\",\"type\":\"files\",\"required\":false,\"value\":[],\"score\":\"\"}}"
        },
        success: true,
        message: "Berhasil mendapatkan kolom kustom"
    }
    const python_get_setting = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/setting/merchant_custom_fields"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }
    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `
    const javascript_get_setting = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/setting/merchant_custom_fields";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_setting = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/setting/merchant_custom_fields";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_setting = `
    curl  -X GET
    'https://crm-api.wooblazz.com/setting/merchant_custom_fields'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `


    // ===================== GET MERCHANT =====================
    const getMerchantsExample = {
        data: [
            {
                cityId: 2,
                cityName: "Kota Surabaya",
                creatorId: "454fd8c5-87cd-4617-8cba-ced92ad48faa",
                creatorName: "Superadmin",
                customFields: {
                    "454fd8c5-87cd-4617-8cba-ced92ad48faa": "Surabaya",
                    "454fd8c5-87cd-4617-8cba-ced92ad48fab": null
                },
                dateCreated: "2024-01-20 00:54:10",
                id: "454fd8c5-87cd-4617-8cba-ced92ad48faa",
                isWhatsappPhone: false,
                lastLeadCreatedTime: "2024-10-14 11:24:32",
                lastMeetEnd: "2024-09-17T01:42:42.000Z",
                leadsCount: 2,
                meetingsCount: 4,
                name: "a",
                ownerId: "454fd8c5-87cd-4617-8cba-ced92ad48faa",
                ownerName: "Manager",
                phone: "6281234567890",
            }
        ],
        success: true,
        message: `Berhasil mendapatkan daftar ${settings.merchant_title}`
    }

    const getMerchantsParams: QueryParameter[] = [
        {
            field: 'phone',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_PHONE" }, { title: settings.merchant_title })
        },
    ]

    const python_get_merchant = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/merchants"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_get_merchant = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/merchants";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_get_merchant = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchants";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_get_merchant = `
    curl  -X GET
    'https://crm-api.wooblazz.com/merchants'
    --header 'Accept: */*'
    --header 'Content-Type: application/json'
    --header 'X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}'
    `

   

    // ===================== ADD MERCHANT =====================
    const addMerchantResponse = {
        data: {
            id: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            name: "Test Merchant",
            phone: "628221234567",
            email: "tes@gmail.com",
            creatorId: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            creatorName: "Test",
            dateCreated: "2024-10-14 15:51:45",
            cityId: 131,
            cityName: "Kota Surabaya",
            customFields: {
                "882a4990-526e-4e2f-b4fa-88ac575ff5ec": "Test Custom Field",
                "882a4990-526e-4e2f-b4fa-88ac575ff5ed": "Test Custom Field",
            },
            ownerId: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            ownerName: "Test Owner",
            isWhatsappPhone: true,
            lastMeetEnd: "2024-10-04 11:24:44",
            lastLeadCreatedTime: "2024-10-04T04:15:26.000Z"
        },
        success: true,
        message: `Berhasil menambahkan ${settings.merchant_title}`
    }

    const addMerchantBody: RequestBody[] = [
        {
            field: 'ownerId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_OWNER_ID" }, { title: settings.merchant_title }),
            target: 'getUsers',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })
        },
        {
            field: 'name',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_NAME" }, { title: settings.merchant_title })
        },
        {
            field: 'cityId',
            type: `Number (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_CITY_ID" }),
            target: 'getCities',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })
        },
        {
            field: 'phone',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_PHONE" }, { title: settings.merchant_title })
        },
        // {
        //     field: 'isWhatsappPhone',
        //     type: `boolean (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
        //     description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_VALID_WHATSAPP" }, { title: settings.merchant_title })
        // },
        {
            field: 'customFields',
            type: `Object (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_CUSTOM_FIELD" }),
            target: 'getSetting',
            target_name: intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title })
        },
    ]

    const python_add_merchant = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/merchant"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8"
    name = "Test Merchant"
    cityId = 131
    phone = "628221234567"
    customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    }

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    payload = json.dumps({
    "ownerId": ownerId,
    "name": name,
    "cityId": cityId,
    "phone": phone,
    "customFields": customFields,
    })

    response = requests.request("POST", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_add_merchant = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/merchant";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    const ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    const name = "Test Merchant";
    const cityId = 131;
    const phone = "628221234567";
    const customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    const payload = {
    "ownerId": ownerId,
    "name": name,
    "cityId": cityId,
    "phone": phone,
    "customFields": customFields,
    };

    axios.post(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_add_merchant = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchant";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    $ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    $name = "Test Merchant";
    $cityId = 131;
    $phone = "628221234567";
    $customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $data = [
        "ownerId" => $ownerId,
        "name" => $name,
        "cityId" => $cityId,
        "phone" => $phone,
        "customFields" => $customFields,
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_add_merchant = `
    ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8"
    name = "Test Merchant"
    cityId = 131
    phone = "628221234567"
    customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    }

    curl -X POST "https://crm-api.wooblazz.com/merchant" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}"
    -d '{
    "ownerId": "'"$ownerId"'",
    "name": "'"$name"'",
    "cityId": "'"$cityId"'",
    "phone": "'"$phone"'",
    "customFields": "'"$customFields"'",
    }'
    `

    
    // ===================== UPDATE MERCHANT =====================
    const updateMerchantResponse = {
        data: {
            id: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            name: "Test Merchant",
            phone: "628221234567",
            email: "tes@gmail.com",
            creatorId: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            creatorName: "Test",
            dateCreated: "2024-10-14 15:51:45",
            cityId: 131,
            cityName: "Kota Surabaya",
            customFields: {
                "882a4990-526e-4e2f-b4fa-88ac575ff5ec": "Test Custom Field",
                "882a4990-526e-4e2f-b4fa-88ac575ff5ed": "Test Custom Field",
            },
            ownerId: "882a4990-526e-4e2f-b4fa-88ac575ff5ec",
            ownerName: "Test Owner",
            isWhatsappPhone: true,
        },
        success: true,
        message: `Berhasil mengubah ${settings.merchant_title}`
    }

    const updateMerchantBody: RequestBody[] = [
        {
            field: 'ownerId',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_OWNER_ID" }, { title: settings.merchant_title }),
            target: 'getUsers',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })
        },
        {
            field: 'name',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_NAME" }, { title: settings.merchant_title })
        },
        {
            field: 'cityId',
            type: `Number (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_CITY_ID" }),
            target: 'getCities',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })
        },
        // {
        //     field: 'isWhatsappPhone',
        //     type: `boolean (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
        //     description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_VALID_WHATSAPP" }, { title: settings.merchant_title })
        // },
        {
            field: 'customFields',
            type: `String[] (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_CUSTOM_FIELD" }),
            target: 'getSetting',
            target_name: intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title })
        },
    ]

    const updateMerchantParams: QueryParameter[] = [
        {
            field: 'id',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_ID" }, { title: settings.merchant_title }),
            target: 'getMerchants',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title })
        },
    ]

    const python_update_merchant = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8"
    name = "Test Merchant"
    cityId = 131
    customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    }

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    payload = json.dumps({
    "ownerId": ownerId,
    "name": name,
    "cityId": cityId,
    "customFields": customFields,
    })

    response = requests.request("PATCH", reqUrl, data=payload, headers=headersList)

    print(response.text)
    `

    const javascript_update_merchant = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    const ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    const name = "Test Merchant";
    const cityId = 131;
    const customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    const payload = {
    "ownerId": ownerId,
    "name": name,
    "cityId": cityId,
    "customFields": customFields,
    };

    axios.patch(reqUrl, payload, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_update_merchant = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}
    $ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8";
    $name = "Test Merchant";
    $cityId = 131;
    $customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    };

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $data = [
        "ownerId" => $ownerId,
        "name" => $name,
        "cityId" => $cityId,
        "customFields" => $customFields,
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "PATCH");
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_update_merchant = `
    ownerId = "b4ab8ad2-d2b4-45d9-9a48-7086c59ed0k8"
    name = "Test Merchant"
    cityId = 131
    phone = "628221234567"
    customFields: {
        "12143-42424-csd232dsdsds-dfdfdq1": ["Opsi 1", "Opsi 2", "Opsi 3"],
        "lkh14-b108f-h10903hbxa10-zdw133": "Tes",
        "12dsq-jhh14-00913bb712s8-na9ll1": null,
    }

    curl -X PATCH "https://crm-api.wooblazz.com/merchant/<<id>>" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}"
    -d '{
    "ownerId": "'"$ownerId"'",
    "name": "'"$name"'",
    "cityId": "'"$cityId"'",
    "customFields": "'"$customFields"'",
    }'
    `
   

    // ===================== DELETE MERCHANT =====================
    const deleteMerchantResponse = {
        success: true,
        message: `Berhasil menghapus ${settings.merchant_title}`
    }

    const deleteMerchantParameters: RequestBody[] = [
        {
            field: 'id',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.REQUIRED" })})`,
            description: intl.formatMessage({ id: "OPEN_API.PARAMS.DESC_DELETE_ID" }, { title: settings.merchant_title }),
            target: 'getMerchants',
            target_name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title })
        },
    ]

    const python_delete_merchant = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("DELETE", reqUrl, headers=headersList)

    print(response.text)
    `

    const javascript_delete_merchant = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    const headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json",
    "X-Auth-token": token
    };

    axios.delete(reqUrl, { headers: headersList })
    .then((response) => {
        console.log(response.data);
    })
    .catch((error) => {
        console.error(error);
    });
    `

    const php_delete_merchant = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/merchant/<<id>>";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init($reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);
    curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "DELETE")

    $response = curl_exec($ch);

    if ($response === false) {
        echo "Error: " . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);
    ?>
    `

    const bash_delete_merchant = `
    curl -X DELETE "https://crm-api.wooblazz.com/merchant/<<id>>" 
    -H "Accept: */*" 
    -H "Content-Type: application/json" 
    -H "X-Auth-token: //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}"
    `
  
    return   {getSettingExample,python_get_setting,javascript_get_setting,php_get_setting,bash_get_setting, getMerchantsExample, getMerchantsParams, python_get_merchant, javascript_get_merchant, php_get_merchant, bash_get_merchant, addMerchantResponse, addMerchantBody, python_add_merchant, javascript_add_merchant, php_add_merchant, bash_add_merchant , updateMerchantResponse, updateMerchantBody, updateMerchantParams, python_update_merchant, javascript_update_merchant, php_update_merchant, bash_update_merchant, deleteMerchantResponse, deleteMerchantParameters, python_delete_merchant, javascript_delete_merchant, php_delete_merchant, bash_delete_merchant}
}