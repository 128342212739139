import { create } from 'zustand';
import { IComponent, ISection, ITemplateAnalytic } from '../../interfaces/Analytics/Analytics';

type ProgressState = {
  sections: ISection[]; // Change the type to Record<string, Setting>
  components: IComponent[];
  iscomponentsLoading: boolean;
  component?: IComponent;
  section?: ISection;
  showUpdateComponentModal: boolean
  showUpdateTemplateModal: boolean
  showDeleteComponentModal: boolean
  showDeleteTemplateModal: boolean
  templatesAnalytic: ITemplateAnalytic[]
  templateAnalytic?: ITemplateAnalytic
  setComponentsLoading: (iscomponentsLoading:boolean)=>void
  setShowDeleteComponentModal: (show:boolean)=>void
  setShowDeleteTemplateModal: (show:boolean)=>void
  setShowUpdateComponentModal: (show:boolean)=>void
  setShowUpdateTemplateModal: (show:boolean)=>void
  setComponents: (components: IComponent[])=>void;
  setSections: (sections: ISection[]) => void;
  setComponent: (component: IComponent) => void;
  setSection: (section: ISection) => void;
  setTemplatesAnalytic: (templatesAnalytic : ITemplateAnalytic[] )=> void
  setTemplateAnalytic: (templatesAnalytic : ITemplateAnalytic )=> void

};

export const useAnalyticsStore = create<ProgressState>((set) => ({
  sections: [],
  components: [],
  templatesAnalytic: [],
  iscomponentsLoading: false,
  showDeleteComponentModal: false,
  showDeleteTemplateModal: false,
  showUpdateComponentModal: false,
  showUpdateTemplateModal: false,
  setComponentsLoading: (iscomponentsLoading)=>{
    set({iscomponentsLoading})
  },
  setShowUpdateComponentModal: (showUpdateComponentModal)=>{
    set({showUpdateComponentModal})
  },
  setShowUpdateTemplateModal: (showUpdateTemplateModal)=>{
    set({showUpdateTemplateModal})
  },
  setTemplatesAnalytic: (templatesAnalytic)=>{
    set({templatesAnalytic})
  },
  setTemplateAnalytic: (templateAnalytic)=>{
    set({templateAnalytic})
  },
  component:undefined,
  section:undefined,
  setSections: (sections) => {
    set({ sections });
  },
  setComponents: (components) => {
    set({ components });
  },
  setComponent: (component) => {
    set({ component });
  },
  setSection: (section) => {
    set({ section });
  },
  setShowDeleteComponentModal: (showDeleteComponentModal:boolean)=>{
    set({showDeleteComponentModal})
  },
  setShowDeleteTemplateModal: (showDeleteTemplateModal:boolean)=>{
    set({showDeleteTemplateModal})
  }
}));