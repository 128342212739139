import { FC, RefObject, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { EventData } from "../../interfaces/EventData";
import { updateEvent } from "../../api/EventCRUD";
import FullCalendar from "@fullcalendar/react";

interface eventProps {
    id: string,
    events: []
}

interface Props {
    eventData: EventData
    calendarRef: RefObject<FullCalendar>
    closeModalRefDetail: RefObject<HTMLDivElement>
    listEvent: eventProps[]
    setListEvent: React.Dispatch<React.SetStateAction<eventProps[]>>
}

const DeleteEvent: FC<Props> = ({ eventData, calendarRef, closeModalRefDetail, listEvent, setListEvent }) => {

    const closeModalRef = useRef<HTMLButtonElement>(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const [sendNotification, setSendNotification] = useState(false)
    const token = useAccessToken()

    const handleDeleteProduct = async () => {
        setModalBtnLoading(true)
        try {
            const response = await updateEvent({
                name: eventData.title,
                description: eventData.description,
                type: eventData.type,
                timezone: eventData.timezone,
                status: 'CANCELLED',
                isAllDay: eventData.allDay,
                start: {
                    date: eventData.start,
                    dateTime: eventData.start
                },
                end: {
                    date: eventData.end,
                    dateTime: eventData.end
                },
                color: eventData.color,
                reminderMinutes: eventData.reminderMinutes===0 ? 1 : eventData.reminderMinutes,
                location: eventData.location,
                idCalendar: eventData.calendar.id,
                idEvent: eventData.id,
                sendNotification: sendNotification,
                onlineMeetLink: eventData.onlineMeetLink ?? null,
                token: token,
            })
            if (response.data.success || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Berhasil!",
                    text: `${eventData.title} berhasil dihapus.`,
                    heightAuto: false,
                })
                // setTablesData('users', (tablesData.users.filter((data) => data.id !== userData.id)))
                const updatedListEvent = listEvent.map((eventItem: eventProps) => {
                    const hasSameId = eventItem.events.some((e: any) => e.id === eventData.id);
                
                    if (hasSameId) {
                        return {
                            ...eventItem,
                            events: eventItem.events.map((e: any) => {
                                if (e.id === eventData.id) {
                                    return response.data.data;
                                }
                                return e;
                            })
                        };
                    }
                    return eventItem;
                });
                
                setListEvent(updatedListEvent as eventProps[])
                calendarRef.current?.getApi().getEventById(eventData.id)?.remove()
                closeModalRef.current?.click();
                closeModalRefDetail.current?.click();
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={'delete-event-modal'}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Hapus Event</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>Apakah anda ingin menghapus event {eventData.title}?</p>

                            {/* Notification */}
                            <div className="input-group mb-3">
                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" onChange={(e) => { setSendNotification(e.target.checked) }} />
                                    <label className="form-check-label">
                                        Kirim Notifikasi Whatsapp
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                Batal
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleDeleteProduct}>
                                <span className="indicator-label">
                                    Hapus
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteEvent }