export const ALWAYS_REQUIRED_FIELDS = [
    '39c22156-3629-4c73-af6d-2fd203b783e3', // KTP_SUAMI_ISTRI_KEY
    'bdb19002-9016-47b9-9434-343bc20a0571', // KK_KEY
    'c8d8fc19-5857-4ab8-a60c-68af6578c7db', // NPWP_KEY
    'cdb8fa9e-817a-47a5-aa65-bd66352cb7e6', // BI_CHECKING_KEY
    'aa0bb7e3-a999-4226-8607-6062aa8ab603', // CREDIT_ANALYST_KEY
    // ===== SINERTEK =====
    '9d4cf984-e537-4ee1-b4ed-aece446a4889', // FILE RESI PENGIRIMAN
    '1bb37d9a-f404-48d3-bb4c-65e1a3e8b6d3', // FILE POD
];

export const ALWAYS_REQUIRED_FIELDS_PROOF_OF_PAYMENT = [
    'c4acf50e-71bb-44ce-b73c-85c626903cb0', // PROGRESS_UTJ_ID
    // '9db61b86-5475-4d9d-a194-3b4b582e7b76'
]

export const USER_MANAGER_HAPPY_PLAY = [
    '1ae20195-91b3-46ad-9aaf-d0db2b29608f', // AZHAR
    '85b6cdf0-0042-4cd1-ba32-0754779cbbe9', // HENNY
    'db367c5d-665a-4308-9ea7-32dfef86f3b5', // TRI
    'fd561450-ee2e-48a2-aa20-7fccc8afe242' // ATTA
]

export const CUSTOM_FIELD_NAME_MERCHANT_MASPION_SQUARE = [
    'Nama Toko',
    'No Unit',
    'Alamat'
]

export const HIDE_MENU_CHAT_CUSTOM_COMPANY = [
    '98bdd14b-b723-4ee8-bfb8-4a4c4f828013', // company Rainbow Edu rei-mentari
    // '5dc4e6fa-7d13-45d7-8d1d-0c4227689099', // lit-artha
    // '2efab7d6-052c-4323-86c8-c2402d258d72' // lit-arthasales
]