import moment from "moment-timezone";
import { CSSProperties, RefObject } from "react";
import { Column, flexRender } from '@tanstack/react-table'
import { PaymentTermin } from "../interfaces/Termin/Termin";
import { TempPaymentTermin } from "../components/Pipelines/Logs/AddLog";
import { IntlShape } from "react-intl";

export function customNumberFormat(valueNumber: number, thousandSeparator: string = '.', decimalSeparator: string = ','): string {
  const [integerPart, decimalPart] = valueNumber.toString().split('.'); // Pisahkan bilangan bulat dan desimal
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator); // Format ribuan

  // Jika ada desimal, tambahkan ke hasil, jika tidak cukup bilangan bulat saja
  return decimalPart ? `${formattedInteger}${decimalSeparator}${decimalPart}` : formattedInteger;
}


export function inputNumberFormat(valueString: string): string {
  const parts = valueString.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
}

export function secondsToTimeFormat(totalSeconds: number) {
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed in JavaScript
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const formatDateDataTable = (dateString: Date) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  // JavaScript's getMonth() returns 0 for January, 1 for February, etc.
  // So, we add 1 to get the actual month number.
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getDateDifference = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let differenceInYears = end.getFullYear() - start.getFullYear();
  let differenceInMonths = end.getMonth() - start.getMonth();
  let differenceInDays = end.getDate() - start.getDate();

  if (differenceInDays < 0) {
    differenceInDays += new Date(end.getFullYear(), end.getMonth(), 0).getDate(); // Days in last month
    differenceInMonths--;
  }

  if (differenceInMonths < 0) {
    differenceInMonths += 12;
    differenceInYears--;
  }

  let displayDifference = '';
  if (differenceInYears > 0) {
    return `> 1 Tahun `;
  }

  if (differenceInMonths > 0) {
    displayDifference += `${differenceInMonths} Bulan `;
  }

  if (differenceInDays > 0) {
    displayDifference += `${differenceInDays} Hari`;
  }

  if (differenceInYears === 0 && differenceInMonths === 0 && differenceInDays === 0) {
    displayDifference = '0 Hari';
  }

  return displayDifference;
};

export const getDateRangeDuration = (days: string, nextDays: string): string => {
  let displayDuration = '';

  const date1 = new Date(days);
  const date2 = new Date(nextDays);

  // Menghitung perbedaan dalam tahun, bulan, dan hari
  let years = date2.getFullYear() - date1.getFullYear();
  let months = date2.getMonth() - date1.getMonth();
  let daysDiff = date2.getDate() - date1.getDate();
  let hours = date2.getHours() - date1.getHours();
  let minutes = date2.getMinutes() - date1.getMinutes();
  let seconds = date2.getSeconds() - date1.getSeconds();

  // Adjust for negative values in months and days
  if (seconds < 0) {
    seconds += 60;
    minutes--;
  }
  if (minutes < 0) {
    minutes += 60;
    hours--;
  }
  if (hours < 0) {
    hours += 24;
    daysDiff--;
  }
  if (daysDiff < 0) {
    // Handle different month lengths
    const daysInPreviousMonth = new Date(date2.getFullYear(), date2.getMonth(), 0).getDate();
    daysDiff += daysInPreviousMonth;
    months--;
  }
  if (months < 0) {
    months += 12;
    years--;
  }

  if (years > 0) displayDuration += `${years} tahun, `;
  if (months > 0) displayDuration += `${months} bulan, `;
  if (daysDiff > 0) displayDuration += `${daysDiff} hari, `;
  if (hours > 0) displayDuration += `${hours} jam, `;
  if (minutes > 0) displayDuration += `${minutes} menit, `;
  if (seconds > 0) displayDuration += `${seconds} detik`;

  // Menghapus koma dan spasi tambahan di akhir string
  if (displayDuration.endsWith(', ')) displayDuration = displayDuration.slice(0, -2);

  return displayDuration;
};

export const getDateSummary = (days: number): string => {
  const differenceInYears = Math.floor(days / 365);
  const remainingDays = days % 365;
  const differenceInMonths = Math.floor(remainingDays / 30);
  const remainingDaysInMonth = remainingDays % 30;

  let displayDifference = '';

  if (differenceInYears > 0) {
    return '> 1 Tahun';
  }

  if (differenceInMonths > 0) {
    displayDifference += `${differenceInMonths} Bulan `;
  }

  if (remainingDaysInMonth > 0) {
    displayDifference += `${remainingDaysInMonth} Hari`;
  }

  if (displayDifference === '') {
    displayDifference = '0 Hari';
  }

  return displayDifference;
};

export const getDurationDay = (date: string) => {
  const data = new Date(date);
  const currentDate = new Date();
  data.setHours(0);
  data.setMinutes(0);
  data.setSeconds(0);
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);

  // Ubah kedua tanggal tersebut menjadi milidetik sejak epoch (1 Januari 1970)
  const dateInMilliseconds = data.getTime();
  const currentDateInMilliseconds = currentDate.getTime();

  // Hitung selisih waktu dalam milidetik
  const timeDifferenceInMilliseconds = currentDateInMilliseconds - dateInMilliseconds;

  // Konversi selisih waktu dari milidetik ke hari
  const timeDifferenceInDays = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));

  if (timeDifferenceInDays === 0) return 'Hari ini';
  else if (timeDifferenceInDays === 1) return 'Kemarin';
  else if (timeDifferenceInDays < 0) return '-';
  else return timeDifferenceInDays + ' Hari lalu';
};

// export const getProgressPhrase = (progressBar: number) => {
//   if (progressBar <= 25) {
//     return 'Target Masih Jauh, Terus Berjuang!';
//   } else if (progressBar <= 50) {
//     return 'Sudah Setengah Perjalanan, Tetap Semangat!';
//   } else if (progressBar <= 75 || progressBar <= 99) {
//     return 'Hampir Mencapai Target, Sedikit Lagi!';
//   } else if (progressBar >= 100) {
//     return 'Telah Mencapai Target, Kerja Bagus!';
//   }
// };

export const getProgressColor = (progressBar: number) => {
  if (progressBar <= 25) {
    return 'bg-danger';
  } else if (progressBar <= 50) {
    return 'bg-warning';
  } else if (progressBar <= 75 || progressBar <= 99) {
    return 'bg-info';
  } else if (progressBar === 100) {
    return 'bg-primary';
  }
};

export const formatCountdown = (countDown: number) => {
  const minutes = Math.floor(countDown / 60);
  const seconds = countDown % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const fetchBlob = async (imageUrl: string): Promise<{ blob: Blob; fileName: string }> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  // Generate a random filename using the current timestamp
  const timestamp = new Date().getTime();
  const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string

  // the extension of the file according to type either image, video or file
  if (blob.type.includes('image')) {
    const extension = blob.type.split('/')[1];
    const fileName = `image_${timestamp}_${randomString}.${extension}`;
    return { blob, fileName };
  } else if (blob.type.includes('video')) {
    const extension = blob.type.split('/')[1];
    const fileName = `video_${timestamp}_${randomString}.${extension}`;
    return { blob, fileName };
  }
  return { blob, fileName: '' };
};

export const isUrl = (text: string): boolean => {
  // Updated URL regex for non-protocol URLs with subdomain.domain.extension and domain.extension patterns
  const urlRegex =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})? /;
  return urlRegex.test(text);
};

export const isValidUrl = (url: string) => {
  // Membuat pola regex dengan menyertakan TLD yang valid
  const urlRegex = /^(https:\/\/|http:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(\/.*)?$/;
  return urlRegex.test(url);
}

export const formatText = (input: string, urlChecking: boolean = false) => {
  let output = '';
  let bold = false;
  let italic = false;
  let strikethrough = false;

  for (let char of input) {
    if (char === '*') {
      bold = !bold;
      output += bold ? '<b>' : '</b>';
    } else if (char === '_') {
      italic = !italic;
      output += italic ? '<i>' : '</i>';
    } else if (char === '~') {
      strikethrough = !strikethrough;
      output += strikethrough ? '<s>' : '</s>';
    } else {
      output += char;
    }
  }

  // Replace newline characters with <br>
  output = output.replace(/\n/g, '<br>');

  // Replace escaped double quotes with regular double quotes
  output = output.replace(/\\"/g, '"');

  output = output.replace(/\\'/g, "'");

  if (urlChecking) {
    // split the string into an array of words
    const wordsWithBrackets = output.split(/(<br\s*\/?>)|\s/).filter(Boolean);
    // Loop through the array of words and check if the word is a URL
    for (let i = 0; i < wordsWithBrackets.length; i++) {
      const word = wordsWithBrackets[i];
      if (word.includes('<br') || word === '(' || word === ')' || word === '[' || word === ']') {
        // If the word is a <br> tag, (, ), [, or ], leave it as is
      } else if (isUrl(word)) {
        // If the word is a URL, wrap it in an anchor tag with rel attribute

        if (word.startsWith('http')) {
          wordsWithBrackets[i] = `<a href="${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
        } else if (!word.startsWith('https://') || word.startsWith('www')) {
          wordsWithBrackets[
            i
          ] = `<a href="https://${word}" target="_blank" rel="noopener noreferrer">${word}</a>`;
        }

        const nonUrlCharsRegex = /^([^a-zA-Z0-9]+)(.+)([^a-zA-Z0-9]+)$/;

        const match = word.match(nonUrlCharsRegex);
        if (match) {
          const [, startChars, urlPart, endChars] = match;
          const fullUrl = `${startChars}<a href="${urlPart}" target="_blank" rel="noopener noreferrer">${urlPart}</a>${endChars}`;
          wordsWithBrackets[i] = fullUrl;
        }
      }
    }

    output = wordsWithBrackets.join(' ');
    // Join the array of words back into a string
  }

  return output;
};

export const getFilterScopePhrase = (scope: string, title: string, user?: string) => {
  var prefix: string;
  var suffix: string;

  if (scope.toUpperCase() === 'ALL') {
    prefix = `semua ${title}`;
  } else if (user !== undefined) {
    prefix = `${title} milik ${user}`;
  } else if (title === 'Target' && scope.toUpperCase() !== 'SELF') {
    prefix = `${title} milik`;
  } else {
    prefix = `${title} milik anda`;
  }

  switch (scope.toUpperCase()) {
    case 'TEAM':
      suffix = ' dan tim anda';
      break;
    case 'WHOLE-TEAM':
      suffix = ' dan seluruh tim di bawah anda';
      break;
    default:
      suffix = '';
      break;
  }

  if (title === 'Target' && user === undefined) suffix = suffix.replace(' dan', '');
  return `${prefix} ${suffix}`;
};

export const handleCaps = (event: any) => {
  var caps = JSON.parse(localStorage.getItem('settings-store')!).state.settings.caps_lock_inputs as boolean;
  if (caps) {
    event.target.value = event.target.value.toUpperCase();
  }
};

export const handleCapsSelect = (value: string) => {
  var caps = JSON.parse(localStorage.getItem('settings-store')!).state.settings.caps_lock_inputs as boolean;
  if (caps) {
    return (value = value.toUpperCase());
  }
};

export const randomColor = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert the values to hexadecimal format
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  // Concatenate the values to form the color code
  const colorCode = `#${redHex}${greenHex}${blueHex}`;
  return colorCode;
};

export function customDistanceFormat(valueString: number) {
  if (valueString >= 1000) {
    return Math.round(valueString / 1000.0) + ' km';
  } else if (valueString >= 100) {
    return Math.round(valueString) + ' m';
  } else {
    return valueString.toFixed(1) + ' m';
  }
}

export function isColorDarkFromHex(hexColor: string): boolean {
  const luminance: number =
    0.299 * parseInt(hexColor.slice(1, 3), 16) +
    0.587 * parseInt(hexColor.slice(3, 5), 16) +
    0.114 * parseInt(hexColor.slice(5, 7), 16);
  return luminance < 128;
}

export const calculateHaversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  function toRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  const R = 6371000; // Earth's radius in meters

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;

  return distance;
};

export const customSort = (rows: any, selector: any, direction: any) => {
  return rows.sort((rowA: any, rowB: any) => {
    const aField = selector(rowA);
    const bField = selector(rowB);

    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }

    return direction === 'desc' ? comparison * -1 : comparison;
  });
};

export const isUUID = (str: string) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return uuidRegex.test(str);
};

export const isImageUrl = (url: string) => {
  if (!url) {
    // Jika URL kosong atau undefined, kembalikan false
    return false;
  }

  // Daftar protokol yang valid
  const validProtocols = ['http://', 'https://'];

  // Daftar ekstensi file gambar yang valid
  const imageExtensions = ['.jpg', '.jpeg', '.png'];

  // Memeriksa protokol yang valid
  const protocolRegex = /^(https?|ftp):\/\//;
  const protocolMatch = url.match(protocolRegex);
  if (!protocolMatch) {
    return false;
  }
  const protocol = protocolMatch[0];

  // Memeriksa ekstensi file di URL
  const pathnameRegex = /\/[^/?#]+(\.[^/?#]+)($|[?#])/;
  const pathnameMatch = url.match(pathnameRegex);
  if (!pathnameMatch) {
    // Jika tidak ada ekstensi dalam path name, maka bukan URL gambar
    return false;
  }
  const extension = pathnameMatch[1].toLowerCase(); // Mendapatkan ekstensi dalam huruf kecil untuk memastikan kecocokan
  return validProtocols.includes(protocol) && imageExtensions.includes(extension);
};

export const isFileUrl = (url: string) => {
  if (!url) {
    // Jika URL kosong atau undefined, kembalikan false
    return false;
  }

  // Daftar protokol yang valid
  const validProtocols = ['http://', 'https://'];

  // Daftar ekstensi file gambar yang valid
  const imageExtensions = ['.pdf', '.xls', '.xlsx', '.doc', '.docx', '.ppt', '.pptx'];

  // Memeriksa protokol yang valid
  const protocolRegex = /^(https?|ftp):\/\//;
  const protocolMatch = url?.match(protocolRegex);
  if (!protocolMatch) {
    return false;
  }
  const protocol = protocolMatch[0];

  // Memeriksa ekstensi file di URL
  const pathnameRegex = /\/[^/?#]+(\.[^/?#]+)($|[?#])/;
  const pathnameMatch = url?.match(pathnameRegex);
  if (!pathnameMatch) {
    // Jika tidak ada ekstensi dalam path name, maka bukan URL gambar
    return false;
  }
  const extension = pathnameMatch[1].toLowerCase(); // Mendapatkan ekstensi dalam huruf kecil untuk memastikan kecocokan
  return validProtocols.includes(protocol) && imageExtensions.includes(extension);
};

export const truncateText = (text: string, textLimit: number) => {
  // const words = text.split(' ');
  if (text.length > textLimit) {
    return text.slice(0, textLimit) + '...';
  }
  return text;
}

export const sortColumnDatatable = (arr: any, fromIndex: number, toIndex: number) => {
  const item = arr.splice(fromIndex, 1)[0];
  arr.splice(toIndex, 0, item);
};

export const getFileType = (url: string) => {
  // Ekstrak ekstensi file dari URL
  const fileExtension = url.split('.').pop()?.toLowerCase();

  // Daftar tipe file dan ekstensinya
  const fileTypes = {
    ppt: ['ppt', 'pptx'],
    pdf: ['pdf'],
    doc: ['doc', 'docx'],
    xlsx: ['xlsx', 'xls']
  };

  // Cari jenis file berdasarkan ekstensi
  for (const [type, extensions] of Object.entries(fileTypes)) {
    if (extensions.includes(fileExtension!)) {
      return type;
    }
  }

  // Jika ekstensi tidak ditemukan, kembalikan 'unknown'
  return 'unknown';
}

export const hexWithOpacity = (hex: string, opacity: number) => {
  hex = hex.replace('#', '');
  if (hex.length !== 6) {
    throw new Error('Hex color code must be 6 characters long.');
  }

  // Memastikan opacity dalam rentang yang benar
  if (opacity < 0 || opacity > 100) {
    throw new Error('Opacity must be a value between 0 and 100.');
  }

  // Mengonversi opacity dari persentase ke nilai hex (00 hingga FF)
  let alpha = Math.round((opacity / 100) * 255).toString(16).padStart(2, '0').toUpperCase();

  // Menggabungkan hex color dengan nilai alpha
  return `#${hex}${alpha}`;
}

export const getNameUrl = (url: string) => {
  const name = url.split('/').pop();
  return name;
}

export const isValidTypeAttachment = (fileName: string, validExtensions: string[]) => {
  // Ambil ekstensi file dari fileName
  const fileExtension = fileName?.split('.').pop()?.toLowerCase();

  // Cek apakah fileExtension ada di dalam daftar validExtensions
  return validExtensions.includes(fileExtension!);
}

export const calculateDistanceLocation = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371; // Jari-jari bumi dalam kilometer
  const dLat = (lat2 - lat1) * (Math.PI / 180); // Konversi selisih lintang ke radian
  const dLon = (lon2 - lon1) * (Math.PI / 180); // Konversi selisih bujur ke radian

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Jarak dalam kilometer

  // Menghilangkan pembulatan dengan membatasi 2 digit tanpa pembulatan
  return Math.round(distance * 100) / 100; // Jarak dengan maksimal 2 digit tanpa pembulatan
}

export const convertToMinutes = (data: { day: number, hour: number, minute: number }): number => {
  const minutesFromDays = data.day * 24 * 60; // 1 hari = 24 jam, 1 jam = 60 menit
  const minutesFromHours = data.hour * 60; // 1 jam = 60 menit
  const totalMinutes = minutesFromDays + minutesFromHours + data.minute;

  return totalMinutes;
}

export const convertFromMinutes = (totalMinutes: number) => {
  const day = Math.floor(totalMinutes / (24 * 60)); // 1 hari = 24 jam * 60 menit
  const remainingMinutesAfterDays = totalMinutes % (24 * 60);

  const hour = Math.floor(remainingMinutesAfterDays / 60); // 1 jam = 60 menit
  const minute = remainingMinutesAfterDays % 60; // sisa menit

  return {
    day: day,
    hour: hour,
    minute: minute
  };
}

export const toTitleCase = (str: string): string => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const customNumberFormatString = (valueString: string) => {
  const onlyNumbers = valueString.replace(/\D+/g, '');  // remove non-digits
  const parts = onlyNumbers.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join('.');
}

export const isBeforeToday = (date: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date < today;
};

export const toRupiah = (amount: number) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0
  }).format(amount)
};


export const dataURLtoFile = (dataUrl: string, filename: string, Buffer: any): File | undefined => {
  const arr = dataUrl.split(',');
  if (arr.length < 2) { return undefined; }
  const mimeArr = arr[0].match(/:(.*?);/);
  if (!mimeArr || mimeArr.length < 2) { return undefined; }
  const mime = mimeArr[1];
  const buff = Buffer.from(arr[1], 'base64');
  return new File([buff], filename, { type: mime });
}

export const getRandomFileName = () => {
  var timestamp = new Date().toISOString().replace(/[-:.]/g, "");
  var random = ("" + Math.random()).substring(2, 8);
  var random_number = timestamp + random;
  return random_number;
}

export const isEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const isImage = (name: string) => /\.(jpg|jpeg|png|gif)$/i.test(name);
export const isVideo = (name: string) => /\.(mp4|mkv|webm|mov)$/i.test(name);
export const isFile = (name: string) => /\.(pdf|xls|xlsx|doc|docx|ppt|pptx|txt|text)$/i.test(name);

export const countTopUserPercent = (value: number, target: number) => {
  if (target) {
    let result = (value / target) * 100;

    if (isNaN(result)) {
      result = 0;
    } else if (result === Infinity && value >= target) {
      result = 100;
    }

    if (value === 0 && value === target) {
      result = 0;
    }

    return Math.round(result)
  } else return 0
}

export const getDataById = (id: string | string[], data: any[]) => {
  if (Array.isArray(id)) {
    return data.filter((item: any) => id.includes(item.id))
  } else {
    return data.find((item: any) => item.id === id)
  }
}

export const removeDotNominal = (value: string) => {
  const newValue = value.replace(/\./g, '');
  return Number(newValue)
}

export const getPinningStyles = (column: Column<PaymentTermin | TempPaymentTermin>, isHeader: boolean = false): CSSProperties => {
  if (column.id === 'actions') {
    return {
      position: 'sticky',
      left: 0,
      backgroundColor: isHeader ? '#D4D4D4' : 'white',
      zIndex: 2,
      boxShadow: '2px 0 4px -2px rgba(0,0,0,0.2)',
      width: column.getSize(),
    }
  }
  return {}
}

export const isAfterToday = (date: Date) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  return date >= tomorrow;
};


export const normalizeDate = (dateString: string): string => {
  if (!dateString) return '';

  const parts = dateString.split('-');
  if (parts.length !== 3) return dateString;

  const [year, month, day] = parts;

  const normalizedMonth = month.padStart(2, '0');
  const normalizedDay = day.padStart(2, '0');

  return `${year}-${normalizedMonth}-${normalizedDay}`;
};

export function roundNominal(data: string | number) {
  if (typeof data === 'string') {
    let checkNominal = data.includes('Rp')
    if (checkNominal) {
      const numericString = data.replace(/Rp|\./g, "").replace(",", ".");
      const numeric = parseFloat(numericString)
      const roundedUp = Math.ceil(numeric);
      return 'Rp ' + customNumberFormat(roundedUp)
    } else {
      return data
    }
  } else {
    return Math.ceil(data)
  }
}


export function formatTimeByFrame(time: any, timeFrame: string): string {
  switch (timeFrame) {
    case 'DAILY':
      return moment(time).format('DD MMMM YYYY'); // Contoh: "01 January 2024"
    case 'MONTHLY':
      return moment(time).format('MMMM YYYY'); // Contoh: "January 2024"
    case 'WEEKLY': {
      const startDate = moment(time); // Tanggal awal
      const endDate = moment(time).add(6, 'days'); // Tanggal akhir (6 hari setelah tanggal awal)
      return `${startDate.format('DD MMMM YYYY')} - ${endDate.format('DD MMMM YYYY')}`; // Contoh: "01 January 2024 - 07 January 2024"
    }
    default:
      return time;
  }
}

export const renderWithFlexRender = (cell: any) => {
  return flexRender(cell.column.columnDef.cell, cell.getContext());
};

export const renderWithouFlexRender = (cell: any) => {
  return typeof cell.column.columnDef.cell === "function"
    ? cell.column.columnDef.cell(cell.getContext())
    : cell.column.columnDef.cell;
};

export const customNumberFormatNew = (number: number | string | undefined | null): string => {
  if (number === undefined || number === null) return '0';
  
  const num = typeof number === 'string' ? parseFloat(number) : number;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
