import React, { useState, useEffect } from 'react'
import ModalWrapper from '../ModalGeneral/ModalWrapper'
import { SelectOption } from '../../interfaces/SelectOption'
import { CustomField } from '../../interfaces/Settings'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

interface CustomFieldSettingDynamicProgressProps {
  isOpen: boolean
  onClose: () => void
  dataProgress: SelectOption[]
  setCustomField: (data: CustomField) => void
  currentField?: CustomField
  selectedFieldKey?: string
  nameField?: string
}

interface ProgressSetting {
  required: boolean
}

interface ProgressState {
  [key: string]: ProgressSetting
}

const CustomFieldSettingDynamicProgress = ({
  isOpen,
  onClose,
  dataProgress,
  setCustomField,
  currentField,
  selectedFieldKey,
  nameField
}: CustomFieldSettingDynamicProgressProps) => {
  const intl = useIntl()
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProgress, setSelectedProgress] = useState<ProgressState>({})

  useEffect(() => {
    if (currentField && selectedFieldKey) {
      const field = currentField[selectedFieldKey]
      if (field && field.progress) {
        const initialProgress: ProgressState = {}
        if (Array.isArray(field.progress)) {
          field.progress.forEach((prog: any) => {
            initialProgress[prog.id] = {
              required: prog.required || false
            }
          })
        } else {
          setSelectedProgress(field.progress)
          return
        }
        setSelectedProgress(initialProgress)
      }
    }
  }, [currentField, selectedFieldKey])

  const isAllProgressSelected = () => {
    const nonAllProgress = dataProgress.filter(item => item.value !== 'all')
    return nonAllProgress.every(item => !!selectedProgress[item.value])
  }

  const isAllProgressNotRequired = () => {
    const nonAllProgress = dataProgress.filter(item => item.value !== 'all')
    return nonAllProgress.every(item =>
      selectedProgress[item.value] && !selectedProgress[item.value].required
    )
  }

  const filteredProgress = dataProgress.filter(item =>
    item.value !== 'all' &&
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const getAllProgress = dataProgress.filter(item => item.value !== 'all')

  const handleProgressChange = (progressId: string, checked: boolean) => {
    if (progressId === 'all') {
      if (checked) {
        const newState: ProgressState = {}
        getAllProgress.forEach(item => {
          newState[item.value] = { required: true }
        })
        setSelectedProgress(newState)
      } else {
        setSelectedProgress({})
      }
    } else {
      setSelectedProgress(prev => {
        if (checked) {
          return {
            ...prev,
            [progressId]: {
              required: true
            }
          }
        } else {
          const { [progressId]: removed, ...rest } = prev
          return rest
        }
      })
    }
  }

  const handleRequiredChange = (progressId: string, value: string) => {
    if (progressId === 'all') {
      const newState: ProgressState = {}
      Object.keys(selectedProgress).forEach(key => {
        newState[key] = {
          required: value === 'wajib'
        }
      })
      setSelectedProgress(newState)
    } else {
      setSelectedProgress(prev => ({
        ...prev,
        [progressId]: {
          required: value === 'wajib'
        }
      }))
    }
  }



  const handleSave = () => {
    if (currentField && selectedFieldKey) {
      const updatedField = {
        ...currentField,
        [selectedFieldKey]: {
          ...currentField[selectedFieldKey],
          progress: {
            ...selectedProgress
          }
        }
      }
      setCustomField(updatedField as CustomField)
      onClose()
    }
  }

  const handleClose = () => {
    if (Object.keys(selectedProgress).length > 0) {
      Swal.fire({
        title: intl.formatMessage({ id: 'FORM.VALIDATION.CANCEL' }),
        text: intl.formatMessage({ id: 'FORM.VALIDATION.DESC_CANCEL' }),
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.YES' }) + ", " + intl.formatMessage({ id: 'FORM.ACTION.CANCEL' }),
        cancelButtonText: intl.formatMessage({ id: 'FORM.CONFIRMATION.NO' }),
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-active-light",
        },
        reverseButtons: true,
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onClose()
        }
      })
    } else {
      onClose()
    }
  }

  return (
    <ModalWrapper isOpen={isOpen} onClose={handleClose} modalSize='md'>
      <div className='modal-header'>
        <h5 className='modal-title'>Pengaturan Progres Kolom Kustom {nameField}</h5>
        <button
          type='button'
          className='btn-close'
          onClick={handleClose}
          aria-label="Close"
        />
      </div>
      <div className='modal-body'>
        <p>{intl.formatMessage({ id: 'FORM.LABEL.DESC_DYNAMIC_PROGRESS' })}</p>

        <div className="mb-3">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Cari progress..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div style={{ maxHeight: '150px', overflowY: 'scroll' }}>
          {filteredProgress.length > 0 ? (
            <>
              <div className='d-flex align-items-center justify-content-between gap-2 my-2'>
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={isAllProgressSelected()}
                    onChange={(e) => handleProgressChange('all', e.target.checked)}
                    id='all'
                  />
                  <label className='form-check-label' htmlFor='all'>
                    {intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) })}
                  </label>
                </div>
                <select
                  className='form-select form-select-sm'
                  style={{ width: 'auto', minWidth: '120px' }}
                  value={isAllProgressNotRequired() ? 'tidak_wajib' : 'wajib'}
                  onChange={(e) => handleRequiredChange('all', e.target.value)}
                  disabled={!isAllProgressSelected()}
                >
                  <option value="wajib">{intl.formatMessage({ id: 'FORM.VALIDATION.REQUIRED' })}</option>
                  <option value="tidak_wajib">{intl.formatMessage({ id: 'FORM.VALIDATION.NOT_REQUIRED' })}</option>
                </select>
              </div>
              {filteredProgress.map((item) => (
                <div className='d-flex align-items-center justify-content-between gap-2 my-2' key={item.value}>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={!!selectedProgress[item.value]}
                      onChange={(e) => handleProgressChange(item.value, e.target.checked)}
                      id={item.value}
                    />
                    <label className='form-check-label' htmlFor={item.value}>
                      {item.label}
                    </label>
                  </div>
                  <select
                    className='form-select form-select-sm'
                    style={{ width: 'auto', minWidth: '120px' }}
                    value={selectedProgress[item.value]?.required ? 'wajib' : 'tidak_wajib'}
                    onChange={(e) => handleRequiredChange(item.value, e.target.value)}
                    disabled={!selectedProgress[item.value]}
                  >
                    <option value="wajib">{intl.formatMessage({ id: 'FORM.VALIDATION.REQUIRED' })}</option>
                    <option value="tidak_wajib">{intl.formatMessage({ id: 'FORM.VALIDATION.NOT_REQUIRED' })}</option>
                  </select>
                </div>
              ))}
            </>
          ) : (
            <div className="text-center text-muted">
              {intl.formatMessage({ id: 'FORM.VALIDATION.NO_DATA_MATCHING_SEARCH' })}
            </div>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={handleClose}>
          {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}
        </button>
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
        </button>
      </div>
    </ModalWrapper>
  )
}

export default CustomFieldSettingDynamicProgress