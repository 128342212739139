import { useIntl } from 'react-intl';
import { useCustomFieldStore } from '../../../stores/FieldEmailStore';

const useNameColumnExcelEmail = () => {
    const intl = useIntl();
    const { customFieldStore } = useCustomFieldStore()

    const getCustomFieldNames = () => {
        if (!customFieldStore) return [];
        return Object.keys(customFieldStore).map((key: any) => customFieldStore[key].name);
    };

    const getCustomFieldSubHeaders = () => {
        if (!customFieldStore) return [];
        return Object.keys(customFieldStore).map((key: any) => {
            const isOptional = customFieldStore[key].required === false;
            return `${intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: customFieldStore[key].name })} ${isOptional ? '(Optional)' : ''}`;
        });
    };

    var nameHeader = {
        export: {
            detailGroupEmail: [
                intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
                'Email',
                ...getCustomFieldNames()
            ],
            detailBroadcastEmail: [
                'Message',
                'Status Pengiriman',
                'Waktu Terkirim',
                'Status Terbaca',
                'Email Penerima',
                'Status Email',
                'Url'
                // ...getCustomFieldNames()
            ],
        },
        import: {
            insert: {
                email: {
                    header: [
                        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
                        'Email',
                        ...getCustomFieldNames()
                    ],
                    subHeader: [
                        intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }),
                        intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `Email` }),
                        ...getCustomFieldSubHeaders()
                    ],
                }
            },
            update: {
                email: {
                    header: [
                        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CODE' }),
                        intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
                        'Email',
                        ...getCustomFieldNames()
                    ],
                    subHeader: [
                        intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' }),
                        intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }) }),
                        intl.formatMessage({ id: 'EXCEL.VALIDATION.ENTER' }, { title: `Email` }),
                        ...getCustomFieldSubHeaders()
                    ],
                }
            },
            info: {
                fileInformation: intl.formatMessage({ id: 'EXCEL.LABEL.FILE_INFORMATION' }),
                downloader: intl.formatMessage({ id: 'EXCEL.LABEL.DOWNLOADER' }),
                downloadTime: intl.formatMessage({ id: 'EXCEL.LABEL.DOWNLOAD_TIME' }),
                type: intl.formatMessage({ id: 'EXCEL.LABEL.TYPE' }),
            },
            error: intl.formatMessage({ id: 'EXCEL.VALIDATION.IGNORE_COLUMN' })
        }
    }

    return nameHeader;
}

export default useNameColumnExcelEmail;