import React, { FC, memo, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { Pipeline, ListProduct, TemplatePipelineProps } from "../../../interfaces/Pipeline";
import { TableColumn } from "react-data-table-component";
import { customNumberFormat, getDateSummary, isColorDarkFromHex, sortColumnDatatable, handleCaps } from "../../../functions/general";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UpdatePipeline } from "../../../components/Pipelines/UpdatePipeline";
import { DeletePipeline } from "../../../components/Pipelines/DeletePipeline";
import { AddPipeline } from "../../../components/Pipelines/AddPipeline";
import { Modal } from "bootstrap";
import axios from "axios";
import useAccessToken from "../../../hooks/AccessToken";
import useSWR, { mutate } from "swr";
import { AddLog } from "../../../components/Pipelines/Logs/AddLog"
import { DateRangePicker } from 'rsuite';
import moment from "moment";
import { endOfYear, startOfMonth, startOfYear, subDays } from "date-fns";
import { SelectOption } from "../../../interfaces/SelectOption";
import useUser from "../../../hooks/User";
import { UserScopeModal, defaultScope } from "../../../components/DataTable/UserScopeModal";
import { UserScope } from "../../../interfaces/UserScope";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField } from "../../../interfaces/Settings";
import { PipelineAttachments } from "../../../components/Pipelines/PipelineAttachments";
import { FilterPipeline } from "../../../components/Pipelines/FilterPipeline";
import { DataTableExport } from "../../../components/DataTable/DataTableExport";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { checkWhatsapp, updateWhatsapp } from "../../../api/Whatsapp";
import Swal from "sweetalert2";
import { useChatStore } from "../../../stores/ChatStore";
import Select from 'react-select'
import { Subordinate } from "../../../interfaces/Subordinate";
import { DetailProductPipeline } from "../../../components/Pipelines/DetailProductPipeline";
import { AbandonedPipeline } from "../../../components/Pipelines/AbandonedPipeline";
import { useIntl } from "react-intl";
import usePredefinedRanges from "../../../hooks/PredefinedRanges";
import useCalendarLocale from "../../../hooks/CalendarLocale";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { MasterBoardPipelinesContent } from "./MasterBoardPipelinesContent";
import { LeadProgress, Progress } from "../../../interfaces/Progress";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";
import { DetailPipeline } from "../../../components/Pipelines/DetailPipeline";
import { useCustomeStore } from "../../../stores/CustomeStore";
import { ActivityPipeline } from "../../../components/Pipelines/ActivityPipeline";
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, horizontalListSortingStrategy, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useProgressStore } from "../../../stores/ProgressStore";
import { isActionActivitiyPipeline, isActionAddLogPipeline, isActionDeletePipeline, isActionDetailProductPipeline, isActionDetailTerminPipeline, isActionLogPipeline, isActionUpdatePipeline } from "../../../functions/customCase";
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { useTemplatePipeline } from "../../../stores/TemplatePipeline";
import useSearch from "../../../hooks/Search";
import InputSearch from "../../../components/Input/InputSearch";
import { ExpandableElement } from "../../../components/General/ExpandableElement";
import DetailTerminPipeline from "../../../components/Pipelines/DetailTermin/DetailTerminPipeline";
import { usePipelineStore } from "../../../stores/PipelineStore";
import { useMerchantStore } from "../../../stores/MerchantStore";
import { useUserStore } from "../../../stores/UserStore";
import { useDNDPipelineStore } from "../../../stores/DNDPipelineStore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Product } from "../../../interfaces/Product";
import { Label } from "../../../interfaces/Label";
import { useVirtualizer } from "@tanstack/react-virtual";
import useModalEffect from "../../../hooks/useModalEffect";
import { useRouteStore } from "../../../stores/RouteStore";
import { getChatMessagesData } from "../../../api/ChatCRUD";
import { Message } from "../../../interfaces/Chat/Message";
import { sortListChat } from "../../../functions/chat";
import BadgeTooltip from "../../../components/General/BadgeTooltip";

interface MasterPipelinesContentProps {
    merchantId?: string
}

interface IProducLabelMerge {
    productName: string
    productLabelName: string
    productLabelColor: string
}
interface IProductLabelAcc {
    [key: string]: {
        productLabelColor: string;
        productLabelName: string;
        productSName: string[];
    };
}
const MasterPipelinesContent: FC<MasterPipelinesContentProps> = ({ merchantId }) => {
    const { prevRoute } = useRouteStore()
    const user = useUser()
    const { templatePipelineId } = useParams()
    const API_URL = process.env.REACT_APP_API_URL
    const today = new Date()
    const lastYear = subDays(startOfYear(today), 365); // Awal tahun lalu
    const { pathname } = useLocation()
    const { companyId, roleId, keyCustomfield } = useCustomeStore()
    const { setMerchants, setSimpleMerchants, setSimpleMerchantLoading, setMerchantLoading } = useMerchantStore()
    const { multiPipelines, selectMultiPipeline, setPipelines, setPipelineLoading, setSelectMultiPipeline } = usePipelineStore()
    const { setUsers, setUserLoading } = useUserStore()
    const { userTemplatePipeline, companyTemplatePipeline, setUserTemplatePipeline, setCompanyTemplatePipeline } = useTemplatePipeline()
    const { tablesData, filteredTablesData, setFilteredTablesData, filterTextTable, setSelectedFilter, setFilterTextTable, isValidatingTable, isFullyLoaded, setFetchCounter, fetchCounter } = useDataTableStore()
    const { progress, setProgress } = useProgressStore()
    const [startDate, setStartDate] = useState<Date>(user.data.company_id === companyId.hom ? subDays(today, 1) : user.data.company_id === companyId.happyPlay ? startOfMonth(today) : user.data.company_id === companyId.tkd ? startOfYear(lastYear) : startOfYear(today));
    const [endDate, setEndDate] = useState<Date>(user.data.company_id === companyId.tkd ? endOfYear(today) : today);
    const [typeDate, setTypeDate] = useState("1");
    const [codeUrl, setCodeUrl] = useState((pathname).split('/').pop());
    const [loading, setLoading] = useState<boolean>(true);
    const [user_id, setUser_id] = useState<string>(user.data.id);
    const [user_name, setUser_name] = useState<string>(user.data.name);
    const [scope, setScope] = useState<UserScope>(user.data.permission_pipeline_read === 'TEAM' ? UserScope.WholeTeam : defaultScope(user.data.permission_pipeline_read));
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [showAddLogModal, setShowAddLogModal] = useState<boolean>(false);
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [showDetailTermin, setShowDetailTermin] = useState<boolean>(false);
    const [showDetailProductModal, setShowDetailProductModal] = useState<boolean>(false);
    const [showActivityModal, setShowActivityModal] = useState<boolean>(false);
    const [pipeline, setPipeline] = useState<Pipeline>();
    const token = useAccessToken()
    const { settings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [key, setKey] = useState<string>('');
    const [url, setUrl] = useState<string | string[]>('');
    const [manageColumns, setManageColumns] = useState<TableColumn<Pipeline>[]>([]);
    const [selectAllColumn, setSelectAllColumn] = useState<boolean>(true);
    const navigate = useNavigate()
    const {
        setPhoneNumber, persistedMessages, phoneNumber, incomingMessageNotifications, setIncomingMessageNotifications,
        setRecentChatHistories, setFilteredRecentChatHistories, setMessagesIsSyncing, setMessages, setPersistedMessages
    } = useChatStore()
    const webView = localStorage.getItem("web-view") === "true" ? true : false
    const [displayMode, setDisplayMode] = useState<string>("table");
    const [leadProgress, setLeadProgress] = useState<LeadProgress[]>([]);
    const [isMounted, setIsMounted] = useState(false);
    const actionRef = useRef<Menu>(null);
    const parentRef = React.useRef<HTMLElement>(null);
    const { setCurrentDraggedPipelineCard, currentProgressOver, setCurrentProgressOver, setAllowedContainerToDrop, setIsCardDragging } = useDNDPipelineStore()

    const columnVirtualizer = useVirtualizer({
        count: leadProgress.length,
        getScrollElement: () => parentRef.current ?? null,
        estimateSize: () => 350, // Sesuaikan dengan tinggi setiap item
        overscan: 1,
    });
    const items = columnVirtualizer.getVirtualItems()
    const { templateMerchantId } = useParams()
    const [hasMutated, setHasMutated] = useState(false);

    const intl = useIntl()

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();
    const optionsTypeDate = [
        { value: '1', label: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_CREATED" }) },
        { value: '2', label: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_STARTED" }) },
        { value: '3', label: intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_ENDED" }) },
    ]

    const handleClickAction = (event: React.MouseEvent<HTMLButtonElement>, row: Pipeline) => {
        setPipeline(row)
        actionRef.current?.toggle(event);

    }

    const returnProductName = (productsName: string[]) => {
        return productsName.map((name, index) => index !== 0 ? `, ${name}` : name)
    }

    var tableColumns: TableColumn<Pipeline>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            allowOverflow: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        <Button
                            type="button"
                            icon="pi pi-ellipsis-v"
                            rounded
                            text
                            className="p-button-plain"
                            onClick={(event: any) => handleClickAction(event, row)}
                        />
                    </>
                )
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }))?.omit
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED' }),
            id: 'dateCreated',
            style: { cursor: "pointer" },
            width: '120px',
            selector: row => row.date_created,
            cell: (row) => {
                return (
                    moment(row.date_created).format("DD MMM YYYY")
                )
            },
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED' }))?.omit
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }),
            id: 'merchantName',
            style: { cursor: "pointer", paddingTop: "10px", paddingBottom: "10px" },
            width: '250px',
            selector: row => row.merchant_name,
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }))?.omit,
            cell: (row) => {
                return (
                    <div className="d-flex align-items-center gap-3">
                        {(() => {
                            switch (row.hot_warm_cold_status) {
                                case 'hot':
                                    return (
                                        <>
                                            <div className="d-flex flex-column">
                                                {(Array.isArray(row?.paymentTermins) && row.paymentTermins.length > 0 && row.paymentTermins[0]?.nominal! > 0) && <span className="badge badge-light-dark" style={{ borderRadius: "100px", width: "fit-content" }}>{intl.formatMessage({ id: "FORM.LABEL.TERMIN" })}</span>}
                                                <span>{row.merchant_name}</span>
                                                {row.merchant_link && <p style={{ color: "#a1a5b7", marginBottom: '0px', fontStyle: 'italic' }}>{row.merchant_link.merchantName}</p>}
                                            </div>
                                            <span className="badge badge-light-danger ms-3">Hot</span>
                                        </>
                                    );
                                case 'warm':
                                    return (
                                        <>
                                            <div className="d-flex flex-column">
                                                {(Array.isArray(row?.paymentTermins) && row.paymentTermins.length > 0 && row.paymentTermins[0]?.nominal! > 0) && <span className="badge badge-light-dark" style={{ borderRadius: "100px", width: "fit-content" }}>{intl.formatMessage({ id: "FORM.LABEL.TERMIN" })}</span>}
                                                <span>{row.merchant_name}</span>
                                                {row.merchant_link && <p style={{ color: "#a1a5b7", marginBottom: '0px', fontStyle: 'italic' }}>{row.merchant_link.merchantName}</p>}
                                            </div>
                                            <span className="badge badge-light-warning ms-3">Warm</span>
                                        </>
                                    );
                                case 'cold':
                                    return (
                                        <>
                                            <div className="d-flex flex-column">
                                                {(Array.isArray(row?.paymentTermins) && row.paymentTermins.length > 0 && row.paymentTermins[0]?.nominal! > 0) && <span className="badge badge-light-dark" style={{ borderRadius: "100px", width: "fit-content" }}>{intl.formatMessage({ id: "FORM.LABEL.TERMIN" })}</span>}
                                                <span>{row.merchant_name}</span>
                                                {row.merchant_link && <p style={{ color: "#a1a5b7", marginBottom: '0px', fontStyle: 'italic' }}>{row.merchant_link.merchantName}</p>}
                                            </div>
                                            <span className="badge badge-light-primary ms-3">Cold</span>
                                        </>
                                    );
                                default:
                                    return (
                                        <>
                                            <div className="d-flex flex-column">
                                                {(Array.isArray(row?.paymentTermins) && row.paymentTermins.length > 0 && row.paymentTermins[0]?.nominal! > 0) && <span className="badge badge-light-dark" style={{ borderRadius: "100px", width: "fit-content" }}>{intl.formatMessage({ id: "FORM.LABEL.TERMIN" })}</span>}
                                                <span>{row.merchant_name}</span>
                                                {row.merchant_link && <p style={{ color: "#a1a5b7", marginBottom: '0px', fontStyle: 'italic' }}>{row.merchant_link.merchantName}</p>}
                                            </div>
                                        </>
                                    )
                            }
                        })()}
                        <span>
                            <div>
                                <OverlayTrigger
                                    placement='right'
                                    overlay={<Tooltip id='tooltip-labels'>
                                        {row?.labels?.map((label, index) => (
                                            <div key={index} style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color || "black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                </svg>
                                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {label.name}
                                                </span>
                                            </div>
                                        ))
                                        }
                                    </Tooltip>}
                                >
                                    <span
                                        id={`labels-${row.id}`}
                                        className='cursor-pointer'
                                        data-toggle='tooltip'
                                        data-placement='right'
                                        title=''
                                    >
                                        {(row.labels?.length && row.labels.length > 0) ? (
                                            row.labels.length === 1 ? (
                                                <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: row.labels[0].color || "black" }} >
                                                    <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                                </svg>
                                            ) : (
                                                <div className="ps-1" style={{ color: row.labels[1].color || "black" }}>
                                                    <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                        <path fill={`${row.labels[0].color || "black"}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                        <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                    </svg>
                                                </div>

                                            )
                                        ) : <></>}
                                    </span>
                                </OverlayTrigger>
                            </div>
                        </span>
                    </div>
                );
            }

        },
        {
            name: user.data.company_id === companyId.elgisa ? "Opportunity" : intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }),
            id: 'note',
            style: { cursor: "pointer" },
            width: '250px',
            selector: row => row.note,
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOTES' }))?.omit,
            cell: (row) => {
                if (row.note) return <div dangerouslySetInnerHTML={{ __html: (row.note).replaceAll('\n', '<br/>') }}></div>
                else return "-"
            },
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }),
            id: 'ownerName',
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.owner_name?.toLowerCase(),
            sortable: true,
            cell: row => row.owner_name,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PIC' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }),
            id: 'totalLogs',
            style: { cursor: "pointer", justifyContent: 'right' },
            width: '110px',
            selector: row => row.lead_logs_count,
            sortable: true,
            reorder: true,
            cell(row) {
                return (
                    <span>{row.lead_logs_count && customNumberFormat(row.lead_logs_count)}</span>
                )
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.TOTAL_LOGS' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }),
            id: 'progressName',
            style: { cursor: "pointer", width: "auto" },
            // width: 'auto',
            grow: 3,
            width: '150px',
            center: true,
            selector: row => row.lead_progress_name,
            cell(row) {
                const progressName = row.lead_progress_name;
                const subProgressName = row.lead_subprogress_name;
                const progressColor = row.lead_progress_color;

                // Determine the badge style based on progress color
                const badgeStyle = progressColor
                    ? { backgroundColor: progressColor, color: isColorDarkFromHex(progressColor) ? "white" : "black" }
                    : { backgroundColor: "transparent", }; // Default when no color

                // Generate badge content
                const badgeContent = subProgressName
                    ? `${progressName} (${subProgressName})`
                    : progressName;

                // Return the badge component
                return (
                <BadgeTooltip tooltipText={badgeContent} badgeClass={progressColor ? '' : 'badge-light-info'} style={badgeStyle}>
                        {badgeContent}
                </BadgeTooltip>
                );
            },
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PROGRESS' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
            id: 'merchantPhone',
            style: { cursor: "pointer" },
            width: '150px',
            allowOverflow: true,
            selector: row => Number(row.merchant_phone),
            cell(row) {
                return row.merchant_phone;
            },
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }),
            id: 'merchantIsWhatsapp',
            style: { cursor: "pointer" },
            width: '150px',
            selector: row => row.merchant_is_whatsapp_phone,
            sortable: true,
            center: true,
            reorder: true,
            cell: row => {
                if (row.merchant_is_whatsapp_phone) return <img alt='Phone' style={{ padding: "15px" }} onClick={() => redirectWhatsappValid(row.merchant_id, row.merchant_phone)} className='h-50px cursor-pointer' src={toAbsoluteUrl('/media/assets/checked.png')} />
                else return <img alt='Phone' style={{ padding: "15px" }} onClick={() => redirectWhatsappNotValid(row.merchant_id, row.merchant_phone)} className='h-50px cursor-pointer' src={toAbsoluteUrl('/media/assets/remove.png')} />
            },
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.WHATSAPP' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }),
            id: 'age',
            style: { cursor: "pointer" },
            width: '100px',
            allowOverflow: true,
            selector: row => getDateSummary(row.lead_age),
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.AGE' }))?.omit,
        },

        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
            id: 'nominal',
            style: { cursor: "pointer", justifyContent: 'space-between' },
            width: '170px',

            selector: (row: any) => Number(row.nominal),
            cell: (row: any) => {
                return (
                    <>
                        <span>Rp</span><span>{row.nominal && customNumberFormat(Math.round(row.nominal))}</span>
                    </>
                )
            },
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }))?.omit,
        },
        ...(user?.data.company_name === "Maspion Square" ? [
            {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL_BEFORE_VAT' }),
                id: 'nominal_without_vat',
                style: { cursor: "pointer", justifyContent: 'space-between' },
                width: '170px',
                selector: (row: any) => Number(row.nominal_without_vat),
                cell: (row: any) => {
                    return (
                        <>
                            <span>Rp</span><span>{row.nominal_without_vat && customNumberFormat(Math.round(row.nominal_without_vat))}</span>
                        </>
                    )
                },
                sortable: true,
                reorder: true,
                omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL_BEFORE_VAT' }))?.omit,
            }
        ] : []),

        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }),
            id: 'products',
            style: { cursor: "pointer" },
            width: '170px',
            selector: row => row.leadProducts.map((data: ListProduct) => data.productName).join(', '),
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PRODUCT' }))?.omit,
            cell: row => {
                const labels = Object.values(
                    row.leadProducts
                        .flatMap((leadProduct) =>
                            leadProduct.productLabels?.map((productLabel): IProducLabelMerge => {
                                return {
                                    productName: leadProduct.productName,
                                    productLabelName: productLabel.name,
                                    productLabelColor: productLabel.color,
                                };
                            })
                        )
                        .reduce((acc: IProductLabelAcc, item) => {
                            const { productLabelColor, productLabelName, productName } = item as IProducLabelMerge;
                            if (!acc[productLabelName]) {
                                acc[productLabelName] = {
                                    productLabelColor,
                                    productLabelName,
                                    productSName: [],
                                };
                            }
                            acc[productLabelName].productSName.push(productName);
                            return acc;
                        }, {} as IProductLabelAcc)
                )
                return (
                    <div className="d-flex gap-4" >
                        <div dangerouslySetInnerHTML={{ __html: row.leadProducts.map((data: ListProduct) => data.productName).join('<br>') }} />
                        <div>
                            <OverlayTrigger
                                placement='right'
                                overlay={<Tooltip id='tooltip-labels'>
                                    {labels?.map((label, index) => (
                                        <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }} key={index}>
                                            <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.productLabelColor || "black", width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                            </svg>
                                            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                                                {label.productLabelName}
                                                <div style={{ fontSize: '8px' }}>{returnProductName(label.productSName)}</div>
                                            </span>
                                        </div>
                                    ))
                                    }
                                </Tooltip>}
                            >
                                <span
                                    className='cursor-pointer'
                                    data-toggle='tooltip'
                                    data-placement='right'
                                    title=''
                                >
                                    {(labels?.length && labels.length > 0) ? (
                                        labels.length === 1 ? (
                                            <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: labels[0].productLabelColor || "black" }} >
                                                <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                            </svg>
                                        ) : (
                                            <div className="ps-1" style={{ color: labels[1].productLabelColor || "black" }}>
                                                <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                    <path fill={`${labels[0].productLabelColor}` || "black"} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                    <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                </svg>
                                            </div>

                                        )
                                    ) : <></>}
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                )
            }
        },
        // {
        //     name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }),
        //     id: 'label',
        //     style: { cursor: "pointer" },
        //     width: '170px',
        //     selector: row => {
        //         const uniqueLabels = [...new Set(row.leadProducts.flatMap(data => data.productLabels))];

        //         return uniqueLabels.length > 0 ? uniqueLabels.join(', ') : "-";
        //     },
        //     sortable: true,
        //     reorder: true,
        //     omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LABEL' }))?.omit,
        //     cell: row => {
        //         const uniqueLabels = [...new Set(row.leadProducts.flatMap(data => data.productLabels))];

        //         return uniqueLabels.length > 0 ? uniqueLabels.join(', ') : "-";
        //     },
        // },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }),
            id: 'sumProducts',
            style: { cursor: "pointer", justifyContent: 'right' },
            width: '150px',
            selector: row => Number(row.leadProducts.reduce((total, data) => total + Number(data.leadProductQty), 0)),
            cell: row => (
                <span>{customNumberFormat(row.leadProducts.reduce((total, data) => total + Number(data.leadProductQty), 0))}</span>
            ),
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SUM_PRODUCTS' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }),
            id: 'source',
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.lead_source_name,
            cell(row) {
                const leadSourceName = row.lead_source_name;
                let badgeClass = "badge-light-info"; // Default badge style

                switch (leadSourceName) {
                    case 'INBOUND':
                        badgeClass = "badge-light-primary";
                        break;
                    case 'OUTBOUND':
                        badgeClass = "badge-light-success";
                        break;
                    default:
                        break;
                }

                // Return the badge component wrapped in OverlayTrigger
                return (
                    <BadgeTooltip children={leadSourceName} tooltipText={leadSourceName} badgeClass={badgeClass}/>
                );
            },
            sortable: true,
            reorder: true,
            center: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.SOURCE' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            id: 'createdBy',
            style: { cursor: "pointer" },
            width: '200px',
            selector: row => row.creator_name,
            sortable: true,
            allowOverflow: true,
            reorder: true,
            wrap: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
            id: 'dateStart',
            style: { cursor: "pointer" },
            width: '120px',
            selector: row => row.date_start,
            cell: (row) => {
                return (
                    moment(row.date_start).format("DD MMM YYYY")
                )
            },
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }))?.omit,
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
            id: 'dateEnd',
            style: { cursor: "pointer" },
            width: '120px',
            selector: row => row.date_end ? row.date_end : "-",
            cell: (row) => {
                return (
                    row.date_end ? moment(row.date_end).format("DD MMM YYYY") : "-"
                )
            },
            sortable: true,
            allowOverflow: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }))?.omit,
        },
        ...(user?.data.company_id === companyId.esb ? [
            {
                name: 'Company Code',
                id: 'companyCode',
                width: "200px",
                selector: (row: Pipeline) => row.merchant_company_code ? row.merchant_company_code : '-',
                sortable: true,
                allowOverflow: true,
                reorder: true,
                omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.INFORMATION' }))?.omit
            }
        ] : []),
        ...(user?.data.company_name === "Juragan Material" ? [
            {
                name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.INFORMATION' }),
                id: 'information',
                width: '200px',
                selector: (row: Pipeline) => row.from_excel,
                cell: (row: Pipeline) => row.from_excel ? intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DESC_INFORMATION' }) : "-",
                sortable: true,
                allowOverflow: true,
                reorder: true,
                omit: manageColumns.find(item => item.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.INFORMATION' }))?.omit
            }
        ] : []),
    ];

    if (user.data.company_id !== companyId.elgisa) {
        tableColumns = tableColumns.filter(column => column.id !== "note");
    }
    const tableColumnsWithCustomFields: TableColumn<Pipeline>[] = [
        ...tableColumns,
        ...Object.keys(customFields).map((key, index) => {
            const customField = customFields[key];
            let columns: TableColumn<Pipeline>[] = [];

            if (customField.type === 'invoice_reminder') {
                const fileColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key + " (File)",
                    width: "150px",
                    name: customField.name + " (File)",
                    center: true,
                    sortable:true,
                    sortFunction: (rowa: Pipeline, rowb: Pipeline) => {
                        const invoiceUrla: string | null = rowa.custom_fields && rowa.custom_fields[key] ? JSON.parse(rowa.custom_fields[key] as string).invoice_url : null;
                        const invoiceUrlb: string | null = rowb.custom_fields && rowb.custom_fields[key] ? JSON.parse(rowb.custom_fields[key] as string).invoice_url : null;
                    
                        // Handle cases where one or both invoice_urls might be null
                        if (invoiceUrla && !invoiceUrlb) {
                            return 1; // rowa comes after rowb
                        }
                        if (!invoiceUrla && invoiceUrlb) {
                            return -1; // rowa comes before rowb
                        }
                    
                        // If both invoice_urls exist, compare them
                        if (invoiceUrla && invoiceUrlb) {
                            if (invoiceUrla > invoiceUrlb) {
                                return 1;
                            }
                            if (invoiceUrla < invoiceUrlb) {
                                return -1;
                            }
                        }
                    
                        return 0; // If they are equal or both are null
                    },
                    
                    cell: (row: Pipeline) => {
                        const invoiceUrl = row.custom_fields && row.custom_fields[key] && JSON.parse(row.custom_fields[key] as string).invoice_url;
                        return invoiceUrl ? (
                            <a
                                href={invoiceUrl}
                                target="_blank"
                                rel="noreferrer"
                                className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                            >
                                <i className="fas fa-file-invoice fs-3 float-end"></i>
                            </a>
                        ) : '-';
                    },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name + " (File)")?.omit
                };
                columns.push(fileColumn);

                const tglExpiredColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name + ` (${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.EXPIRED' }) })})`,
                    selector: (row: Pipeline) => {
                        const dateDue = row.custom_fields && row.custom_fields[key] && JSON.parse(row.custom_fields[key] as string).date_due;
                        return dateDue ? moment(dateDue).format('DD MMM YYYY') : '-';
                    },
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name + ` (${intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.EXPIRED' }) })})`)?.omit
                };
                columns.push(tglExpiredColumn);
            } else if (customField.type === 'date') {
                const dateColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    selector: (row: Pipeline) => {
                        const dateValue = row.custom_fields && row.custom_fields[key];
                        return dateValue ? moment(dateValue).format('DD MMM YYYY') : '-';
                    },
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(dateColumn);
            } else if (customField.type === 'image' || customField.type === 'images') {
                const imageColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "70px",
                    name: customField.name,
                    center: true,
                    cell: (row: Pipeline) => {
                        const imageUrl = row.custom_fields && row.custom_fields[key];
                        return imageUrl ? (
                            <span role="button" onClick={() => {
                                setShowAttachmentModal(true);
                                setPipeline(row);
                                setKey(key);
                                setUrl(imageUrl as any);
                            }}>
                                <i className="fas fs-2 fa-eye"></i>
                            </span>
                        ) : '-';
                    },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit,
                    sortable: true,
                    sortFunction: (rowA: Pipeline, rowB: Pipeline) => {
                        let imageUrlA = rowA.custom_fields?.[key];
                        let imageUrlB = rowB.custom_fields?.[key];
                    
                        // Handle string or array cases
                        imageUrlA = Array.isArray(imageUrlA) ? imageUrlA.join(', ') : imageUrlA || '';
                        imageUrlB = Array.isArray(imageUrlB) ? imageUrlB.join(', ') : imageUrlB || '';
                    
                        return imageUrlA.localeCompare(imageUrlB);
                      },
                };
                columns.push(imageColumn);
            } else if (customField.type === 'file' || customField.type === 'files') {
                const fileColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "70px",
                    name: customField.name,
                    center: true,
                    cell: (row: Pipeline) => {
                        const fileUrl = row.custom_fields && row.custom_fields[key];
                        if (typeof fileUrl === "string") {
                            return fileUrl ? (
                                <a
                                    href={fileUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                >
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </a>
                            ) : '-';
                        } else {
                            return fileUrl ? (
                                <span role="button" onClick={() => {
                                    setShowAttachmentModal(true);
                                    setPipeline(row);
                                    setKey(key);
                                    setUrl(fileUrl as string[]);
                                }}>
                                    <i className="fas fa-file-invoice fs-3 float-end"></i>
                                </span>
                            ) : '-';
                        }
                    },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit,
                    sortable:true,
                    sortFunction: (rowA: Pipeline, rowB: Pipeline) => {
                        let fileUrlA = rowA.custom_fields?.[key];
                        let fileUrlB = rowB.custom_fields?.[key];
                  
                        // Handle both string and array cases
                        fileUrlA = Array.isArray(fileUrlA) ? fileUrlA.join(', ') : fileUrlA || '';
                        fileUrlB = Array.isArray(fileUrlB) ? fileUrlB.join(', ') : fileUrlB || '';
                  
                        return fileUrlA.localeCompare(fileUrlB);
                      }
                };
                columns.push(fileColumn);
            } else if (customField.type === 'multiple' || customField.type === 'option' || customField.type === 'text' || customField.type === 'pipeline_invoice' || customField.type === 'phone') {
                const textColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    selector: (row: Pipeline) => {
                        const fieldValue = row.custom_fields && (row.custom_fields[key] as string);
                        const displayValue = fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
                            ? '-'
                            : fieldValue;

                        return displayValue || '-';
                    },
                    cell: (row: Pipeline) => {
                        const values = row.custom_fields && row.custom_fields[key] && row.custom_fields[key].toString();
                        return values ? values.split(',').join(', ') : '-';
                    },
                    allowOverflow: true,
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(textColumn);
            } else if (customField.type === 'serial') {
                const textColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    cell: (row: Pipeline) => {
                        const values = row.custom_fields && row.custom_fields[key];
                        return typeof values === 'string' ? values : (values as string[])?.length > 0 ? (values as string[]).join('') : '-';
                    },
                    allowOverflow: true,
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(textColumn);
            } else if (customField.type === 'pipeline') {
                const textColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    cell: (row: Pipeline) => {
                        const values = row.info_custom_fields && row.info_custom_fields[key];
                        if (Array.isArray(values) && values.length > 0) {
                            return <span><b>{(values[0] as any).name}</b> / Rp.{customNumberFormat((values[0] as any).nominal)} / {moment((values[0] as any).dateCreated).format("DD MMM YYYY")}</span>;
                        }
                        return '-';
                    },
                    allowOverflow: true,
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(textColumn);
            } else if (customField.type === 'merchant') {
                const textColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    cell: (row: Pipeline) => {
                        const values = row.info_custom_fields && row.info_custom_fields[key];
                        if (Array.isArray(values) && values.length > 0) {
                            return values.map((item: any) => item.merchant_name).join(', ');
                        }
                        return '-';
                    },
                    allowOverflow: true,
                    sortable: true,
                    sortFunction: (rowA: Pipeline, rowB: Pipeline) => {
                        const valueA = (rowA as any)[`custom_fields.${key}`] ?? "";
                        const valueB = (rowB as any)[`custom_fields.${key}`] ?? "";
                        
                        // Pastikan hanya membandingkan string
                        if (typeof valueA === 'string' && typeof valueB === 'string') {
                          return valueA.localeCompare(valueB);
                        }
                        return 0; // Tidak ada perbandingan jika bukan string
                      },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(textColumn);
            }
            else if (customField.type === 'user') {
                const textColumn: TableColumn<Pipeline> = {
                    style: { cursor: "pointer" },
                    id: key,
                    width: "150px",
                    name: customField.name,
                    cell: (row: Pipeline) => {
                        const values = row.info_custom_fields && row.info_custom_fields[key];
                        if (Array.isArray(values) && values.length > 0) {
                            return values.map((item: any) => item.name).join(', ');
                        }
                        return '-';
                    },
                    allowOverflow: true,
                    sortable: true,
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                };
                columns.push(textColumn);
            }
            else if (customFields[key].type === 'link') {
                const linkColumn: TableColumn<Pipeline> = {
                    name: customField.name,
                    id: key,
                    grow: 2,
                    cell: (row: Pipeline) => {
                        if (row.custom_fields && row.custom_fields[key]) {
                            return (
                                <a
                                    href={row.custom_fields[key] as string}
                                    rel="noreferrer"
                                    className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600"
                                    target="_blank"
                                >
                                    Lihat
                                    <i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i>
                                </a>
                            );
                        } else {
                            return "-";
                        }
                    },
                    sortable: true,
                    sortFunction: (rowA: Pipeline, rowB: Pipeline) => {
                        const linkA = Array.isArray(rowA.custom_fields?.[key])
                          ? rowA.custom_fields?.[key][0] // Ambil nilai pertama jika array
                          : rowA.custom_fields?.[key] || "";
                  
                        const linkB = Array.isArray(rowB.custom_fields?.[key])
                          ? rowB.custom_fields?.[key][0]
                          : rowB.custom_fields?.[key] || "";
                  
                        // Bandingkan hanya jika kedua nilai adalah string
                        if (typeof linkA === "string" && typeof linkB === "string") {
                          return linkA.localeCompare(linkB);
                        }
                        return 0;
                      },
                    reorder: true,
                    omit: manageColumns.find(item => item.name === customField.name)?.omit
                    
                }
                columns.push(linkColumn);
            }

            return columns;
        }).flat(),
    ];



    // SORT COLUMN DATATABLE
    if (user.data.company_id === companyId.enterpriseSales || user.data.company_id === companyId.enterpriseService) {
        tableColumnsWithCustomFields.push({
            style: { cursor: "pointer" },
            width: "150px",
            name: 'Invoice',
            selector: (row: Pipeline) => {
                if (row.custom_fields[keyCustomfield.serial.enterpriseSales]) {
                    return (row.custom_fields[keyCustomfield.serial.enterpriseSales] as string[]).join('')
                } else if (row.custom_fields[keyCustomfield.serial.enterpriseService]) {
                    return (row.custom_fields[keyCustomfield.serial.enterpriseService] as string[]).join('')
                } else {
                    return "-"
                }
            },
            cell: (row: Pipeline) => {
                if (row.custom_fields[keyCustomfield.serial.enterpriseSales]) {
                    return (row.custom_fields[keyCustomfield.serial.enterpriseSales] as string[]).join('')
                } else if (row.custom_fields[keyCustomfield.serial.enterpriseService]) {
                    return (row.custom_fields[keyCustomfield.serial.enterpriseService] as string[]).join('')
                } else {
                    return "-"
                }
            },
            allowOverflow: true,
            sortable: true,
            reorder: true,
            omit: manageColumns.find(item => item.name === 'Invoice')?.omit
        })

        const invoiceIndex = tableColumnsWithCustomFields.findIndex(col => col.name === 'Invoice');
        const merchantIndex = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }));
        sortColumnDatatable(tableColumnsWithCustomFields, invoiceIndex, merchantIndex + 1);
    }

    if (user.data.company_name === "Heiszco") {
        const productsIndex = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PRODUCT" }));
        const subSectorIndex = tableColumnsWithCustomFields.findIndex(col => (col.name as string)?.toLowerCase() === "sub sector/nama project");
        const picIndex = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PIC" }));
        sortColumnDatatable(tableColumnsWithCustomFields, productsIndex, picIndex + 1);
        sortColumnDatatable(tableColumnsWithCustomFields, subSectorIndex, picIndex + 2);
    }

    if (user.data.company_id === companyId.esb) {
        const companyCodeIndex = tableColumnsWithCustomFields.findIndex(col => (col.name as string)?.toLowerCase() === "company code");
        const nameIndex = tableColumnsWithCustomFields.findIndex(col => col.name === intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: settings.merchant_title }));
        companyCodeIndex !== -1 && sortColumnDatatable(tableColumnsWithCustomFields, companyCodeIndex, nameIndex);
    }

    const updateWhatsappValid = async (id: string, phone: string, res: boolean) => {
        try {
            const response = await updateWhatsapp(id, res, token)
            if (response.data.success) {
                if (res) {
                    if (user.data.is_superadmin) {
                        window.open(`https://api.whatsapp.com/send?phone=${phone.replace("+", "")}`)
                    } 
                    // else {
                    //     navigate('/chat')
                    //     setPhoneNumber(phone.replace("+", ""))
                    // }
                }
            }
        } catch (error) {

        }
    }

    const redirectWhatsappValid = async (id: string, phone: string) => {
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            if (!response.data.result.exists) {
                updateWhatsappValid(id, phone, response.data.result.exists)
            } else {
                if (user.data.is_superadmin) {
                    window.open(`https://api.whatsapp.com/send?phone=${phone.replace("+", "")}`)
                } 
                // else {
                //     navigate('/chat')
                //     setPhoneNumber(phone.replace("+", ""))
                //     getChatMessages(phone.replace("+", ""), '')
                // }
            }
        } catch (error) {

        }
    }

    const redirectWhatsappNotValid = async (id: string, phone: string) => {
        try {
            const response = await checkWhatsapp(phone.replace("+", ""))
            if (response.data.result.exists) {
                updateWhatsappValid(id, phone, response.data.result.exists)
            } else {
                Swal.fire({
                    icon: "error",
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.INVALID_NUMBER" })}.`,
                    heightAuto: false,
                })
            }
        } catch (error) {

        }
    }

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 5, // untuk menghindari drag yang tidak disengaja
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        })
    );



    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.data.current.type === 'card') {
            if (over.id === "-1") {
                setCurrentProgressOver(undefined)
                setCurrentDraggedPipelineCard(undefined)
                setIsCardDragging(false)
                // Swal.fire(
                //     intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
                //     intl.formatMessage({ id: 'FORM.LABEL.NOT_ALLOWED_PROGRESS' }),
                //     'error'
                // )
                return;
            }

            const pipeline: Pipeline = filteredTablesData['pipelines'].filter((pipeline: Pipeline) => pipeline.id === active.id)[0]

            setPipeline(pipeline)

            if (progresses.some((progres: any) => progres.id === currentProgressOver)) {
                setShowAddLogModal(true);

            } else {
                setCurrentProgressOver(undefined)
                setCurrentDraggedPipelineCard(undefined)
                setIsCardDragging(false)
                // Swal.fire(
                //     intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
                //     intl.formatMessage({ id: 'FORM.LABEL.NOT_ALLOWED_PROGRESS' }),
                //     'error'
                // )
            }
        }

        if (active.id !== over.id && active.data.current.type !== 'card') {
            const updateProgress = (leadProgress: LeadProgress[]) => {
                const oldIndex = leadProgress.findIndex(item => item.id === active.id);
                const newIndex = leadProgress.findIndex(item => item.id === over.id);
                return arrayMove(leadProgress, oldIndex, newIndex);
            };

            const newLeadProgress = updateProgress([...leadProgress]);
            setLeadProgress(newLeadProgress);
            setProgress(newLeadProgress);
        }

        setCurrentDraggedPipelineCard(undefined)

    };





    const handleDragOver = (event: any) => {
        if (event.active.data.current.type === 'card') {
            setCurrentProgressOver(event.over.id)
        }
        return;
    }

    const onRowClicked = (row: Pipeline) => {
        setPipeline(row)
        setShowDetailModal(true);
    };

    const sortDataById = (data: TableColumn<Pipeline>[], sort: TableColumn<Pipeline>[]) => {
        const sortOrder = sort.reduce((acc: Record<string, number>, item: any, index: number) => {
            acc[item.id] = index;
            return acc;
        }, {});

        return data.sort((a: any, b: any) => sortOrder[a.id] - sortOrder[b.id]);
    };

    const handleResetFilter = () => {
        setSelectedFilter('pipelines', 'leadProducts', [])
        setSelectedFilter('pipelines', 'lead_progress_progress_type', ['ONGOING', 'SUCCESS'])
        setSelectedFilter('pipelines', 'lead_progress_name', [])
        setSelectedFilter('pipelines', 'owner_name', [])
        setSelectedFilter('pipelines', 'labels', [])
        setSelectedFilter('pipelines', 'leadProductLabels', [])
        setSelectedFilter('pipelines', 'lead_source_name', [])

        sortDataById(manageColumns, tableColumnsWithCustomFields)
        setManageColumns(manageColumns.map((data: TableColumn<Pipeline>) => ({ ...data, omit: false })));
        setSelectAllColumn(true)
        setUserTemplatePipeline(
            userTemplatePipeline.map((data: TemplatePipelineProps) => ({
                ...data,
                content: {
                    ...data.content,
                    use: false,
                }
            }))
        );
        setCompanyTemplatePipeline(
            companyTemplatePipeline.map((data: TemplatePipelineProps) => ({
                ...data,
                content: {
                    ...data.content,
                    use: false,
                }
            }))
        );

    }

    const convertOldData = (oldData: any) => {
        const contentParsed = JSON.parse(oldData.content);
        const hide = contentParsed.column
            .filter((col: any) => col.omit) // Filter column yang di-omit
            .map((col: any) => {
                const matchedColumn = tableColumnsWithCustomFields.find((item) => item.name === col.name);
                return matchedColumn ? matchedColumn.id : null; // Mengambil id jika ditemukan
            })
            .filter((id: string | null): id is string => id !== null);
        const sort = tableColumnsWithCustomFields.map((col: any) => col.id);

        const newContent = {
            hide: hide,
            sort: sort,
            use: false,
            filter: contentParsed.filter
        };

        return {
            ...oldData,
            content: newContent
        };
    };

    const fetcher = async (url: string) => {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token,
            },
        });
        return response.data.data;
    };

    const { data: simpleMerchantsData = [], error: simpleMerchantsError, isLoading: simpleMerchantsLoading } = useSWR(`${API_URL}simple-merchants`, fetcher)
    const { data: userData = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: leads = [], error: leadsError, isLoading: leadsLoading } = useSWR(`${API_URL}simple-leads`, fetcher)
    const { data: progresses = [], error: progressesError, isLoading: progressesLoading } = useSWR(`${API_URL}lead_progresses?onlyShowAllowedForCreateLeadLog=true&leadTemplateId=${templatePipelineId}`,
        fetcher
    );
    const { data: merchantsData = [], error: merchantsError, isLoading: merchantsLoading } = useSWR(user.data.permission_merchant_read !== 'NONE' ? `${API_URL}merchants?leadTemplateId=${templatePipelineId}` : null, fetcher)
    const { data: userTemplate = [], error: userTemplateError, isLoading: userTemplateLoading } = useSWR(templatePipelineId ? `${API_URL}user-view-templates?leadTemplateId=${templatePipelineId}` : null, fetcher)
    const { data: companyTemplate = [], error: companyTemplateError, isLoading: companyTemplateLoading } = useSWR(templatePipelineId ? `${API_URL}company-view-templates?leadTemplateId=${templatePipelineId}` : null, fetcher)
    const { data: products, error: productError, isLoading: productLoading } = useSWR(`${API_URL}products`, fetcher)

    useEffect(() => {
        const progressesID: string[] = progresses.map((progress: Progress) => progress.id)
        setAllowedContainerToDrop(progressesID)
    }, [progresses])

    useEffect(() => {
        setPipelineLoading(leadsLoading)
        if (!leadsLoading) {
            setPipelines(leads)
        }
    }, [leads]);

    useEffect(() => {
        setSimpleMerchantLoading(simpleMerchantsLoading)
        if (!simpleMerchantsLoading) {
            setSimpleMerchants(simpleMerchantsData)
        }
    }, [simpleMerchantsData]);

    useEffect(() => {
        setMerchantLoading(merchantsLoading)
        if (!merchantsLoading) {
            setMerchants(merchantsData)
        }
    }, [merchantsLoading]);

    useEffect(() => {
        setUserLoading(usersLoading)
        if (!usersLoading) {
            setUsers(userData)
        }
    }, [userData]);

    useEffect(() => {
        if (!userTemplateLoading) {
            var data = userTemplate.map((template: any) => {
                let parsedContent;

                try {
                    parsedContent = JSON.parse(template.content);
                } catch (e) {
                    return template;
                }

                if (parsedContent.column) {
                    return convertOldData(template);
                } else {
                    return {
                        ...template,
                        content: parsedContent
                    };
                }
            });

            setUserTemplatePipeline(data);
        }
    }, [userTemplate]);

    useEffect(() => {
        if (!companyTemplateLoading) {
            var data = companyTemplate.map((template: any) => {
                let parsedContent;

                try {
                    parsedContent = JSON.parse(template.content);
                } catch (e) {
                    return template;
                }

                if (parsedContent.column) {
                    return convertOldData(template);
                } else {
                    return {
                        ...template,
                        content: parsedContent
                    };
                }
            });

            setCompanyTemplatePipeline(data);
        }
    }, [companyTemplate]);

    useEffect(() => {
        setManageColumns(tableColumnsWithCustomFields);
    }, [customFields])

    let actions: ReactNode[] = []
    // let searchComponent: ReactNode = <InputSearch tableKey={"pipelines"} tableColumns={tableColumns} />
    let actionsModeBoard: ReactNode[] = []
    if (user.data.is_allowed_export_excel && !webView) {
        actions.push(<DataTableExport source={"Pipeline"} columns={manageColumns} user={user!} />)
        actionsModeBoard.push(<DataTableExport source={"Pipeline"} columns={manageColumns} user={user!} />)
    }

    if (user.data.permission_pipeline_create !== 'NONE' && !merchantId) {
        if (settings.feature_import_excel_pipeline) {
            if (user?.data.company_id === companyId.juraganMaterial) {
                if (user?.data.role_id === roleId.juraganMaterial.superadmin || user?.data.role_id === roleId.juraganMaterial.salesHead) {
                    actions.push(
                        <DataTableImport source={"template-pipeline"} columns={manageColumns} />,
                    )
                }
            } else {
                actions.push(
                    <DataTableImport source={"template-pipeline"} columns={manageColumns} />,
                )
            }
        }
        // if (user?.data.company_id === companyId.looyalIT || user?.data.company_id === companyId.juraganMaterial || user?.data.company_id === companyId.elgisa || user?.data.company_id === companyId.tkd) {
        //     if (user?.data.company_id === companyId.elgisa || user?.data.company_id === companyId.tkd) {
        //         actions.push(
        //             <DataTableImport source={"template-pipeline"} columns={manageColumns} />,
        //         )
        //     } else if (user?.data.role_id === roleId.juraganMaterial.superadmin || user?.data.role_id === roleId.juraganMaterial.salesHead) {
        //         actions.push(
        //             <DataTableImport source={"template-pipeline"} columns={manageColumns} />,
        //         )
        //     }
        // }

        actions.push(
            <AddPipeline manageColumns={manageColumns} setManageColumns={setManageColumns} setScope={setScope} handleResetFilter={handleResetFilter} />
        );
        actionsModeBoard.push(<AddPipeline manageColumns={manageColumns} setManageColumns={setManageColumns} setScope={setScope} handleResetFilter={handleResetFilter} />);
        setTimeout(() => {
        }, 0)
    }

    actions.push(<FilterPipeline manageColumns={manageColumns} setManageColumns={setManageColumns} selectAllColumn={selectAllColumn} setSelectAllColumn={setSelectAllColumn} handleResetFilter={handleResetFilter} />);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (codeUrl !== (pathname).split('/').pop()) {
            setCodeUrl((pathname).split('/').pop()!)
        }
    }, [pathname])

    useModalEffect({
        showModal: showAddLogModal,
        setShowModal: setShowAddLogModal,
        modalId: 'tambah-log-modal'
    })

    useModalEffect({
        showModal: showUpdateModal,
        setShowModal: setShowUpdateModal,
        modalId: `update-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showAttachmentModal,
        setShowModal: setShowAttachmentModal,
        modalId: `attachments-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showDeleteModal,
        setShowModal: setShowDeleteModal,
        modalId: `delete-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showDetailProductModal,
        setShowModal: setShowDetailProductModal,
        modalId: `detail-product-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showActivityModal,
        setShowModal: setShowActivityModal,
        modalId: `activity-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showDetailModal,
        setShowModal: setShowDetailModal,
        modalId: `detail-pipeline-modal-${pipeline?.id}`
    })

    useModalEffect({
        showModal: showDetailTermin,
        setShowModal: setShowDetailTermin,
        modalId: `detail-termin-pipeline-modal-${pipeline?.id}`
    })

    useEffect(() => {
        if (isMounted) {
            if (startDate && endDate && typeDate) {
                mutate(`${API_URL}v2/leads`);
            }
        } else {
            setIsMounted(true);
        }
    }, [startDate, endDate, typeDate]);

    useEffect(() => {
        if (codeUrl && multiPipelines.length > 0) {
            const data = multiPipelines.find(item => item.id === codeUrl)
            setSelectMultiPipeline(data!)
            setCustomFields(data?.customFields!);
            mutate(`${API_URL}user-view-templates`);
            mutate(`${API_URL}company-view-templates`);
            if (hasMutated) {
                mutate(`${API_URL}v2/leads`);
            } else {
                setHasMutated(true);
            }
        }
    }, [multiPipelines, codeUrl, prevRoute])

    useEffect(() => {
        if (filteredTablesData["pipelines"] && progress) {
            if (progress.length !== 0) {
                var leadProgressName = [...new Map(filteredTablesData["pipelines"].map((item) => [item.lead_progress_name, { id: item.lead_progress_id === null ? "-1" : item.lead_progress_id, name: item.lead_progress_name, color: item.lead_progress_color, sort: "asc" }])).values()];
                setLeadProgress([...progress.filter(item => leadProgressName.some(progres => progres.name === item.name)), ...leadProgressName.filter(item => !progress.some(progres => progres.name === item.name))]);
            }
        }
    }, [filteredTablesData["pipelines"], progress])

    useEffect(() => {
        if (filteredTablesData["pipelines"] && progress.length === 0) {
            const orderType = ["ONGOING", "SUCCESS", "FAIL"]
            filteredTablesData["pipelines"].sort((a, b) => {
                if ((a.lead_progress_name).includes("NEW")) return -1;
                if ((b.lead_progress_name).includes("NEW")) return 1;

                const indexA = orderType.indexOf(a.lead_progress_progress_type);
                const indexB = orderType.indexOf(b.lead_progress_progress_type);
                return indexA - indexB;
            });

            var leadProgressName = [...new Map(filteredTablesData["pipelines"].map((item) => [item.lead_progress_name, { id: item.lead_progress_id === null ? "-1" : item.lead_progress_id, name: item.lead_progress_name, color: item.lead_progress_color, sort: "asc" }])).values()];
            setProgress(leadProgressName)
        }
    }, [filteredTablesData["pipelines"]])

    useEffect(() => {
        setDisplayMode('table')
    }, [templatePipelineId, scope, startDate, endDate])

    const { filterText, handleSearch } = useSearch("pipelines", tableColumnsWithCustomFields, "asc");

    const searchComponent = () => {
        return (
            <>
                <span className="svg-icon svg-icon-1 position-absolute ms-6 mt-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                    </svg>
                </span>
                <input
                    type="search"
                    data-kt-lists-table-filter="search"
                    className={`${displayMode === "table" ? "form-control-solid skeleton mt-3" : "border-body bg-body"} form-control w-210px ps-15`}
                    placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })}
                    id="inputSearch"
                    value={filterText["pipelines"]}
                    onChange={e => { handleSearch(e.target.value) }}
                    onInput={(e) => handleCaps(e)}
                />
            </>
        )
    }

    return (
        <>
            {
                tablesData.pipelinesAbandoned && tablesData.pipelinesAbandoned.length > 0 &&
                <div className="alert alert-danger">
                    <div className="d-flex align-items-center">
                        <KTSVG path="/media/icons/duotune/general/gen044.svg" className='svg-icon-2 svg-icon-danger me-2' />
                        <span>{intl.formatMessage({ id: 'WARNING.PIPELINE.INACTIVE_PIPELINE.NEED_UPDATE' }, { title: settings.pipeline_title, title2: settings.pipeline_title })}
                            <b className="cursor-pointer" data-bs-toggle="modal" data-bs-target={`#abandoned-pipeline-modal`}> <u>{intl.formatMessage({ id: 'WARNING.PIPELINE.INACTIVE_PIPELINE.VIEW_LIST' }, { title: settings.pipeline_title })}</u></b></span>
                    </div>
                </div>
            }

            <div className="row justify-content-between mb-3">
                <div className="col-sm-3 col-lg-4 col-xl-5" style={{ alignSelf: "center" }}>
                    <ol className="breadcrumb text-muted fs-6 fw-bold">
                        <li className="breadcrumb-item text-muted">{settings.pipeline_title}</li>
                        <li className="breadcrumb-item text-dark">{selectMultiPipeline.name}</li>
                    </ol>
                </div>
                {
                    !merchantId &&
                    <div className="col-sm-9 col-lg-8 col-xl-7">
                        <div className="d-flex px-3 float-end">
                            <Select
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        border: "none", // Menghilangkan simbol garis
                                        backgroundColor: "none",
                                        maxWidth: "200px"
                                    }),
                                    option: (baseStyles, { isSelected, isFocused }) => ({
                                        ...baseStyles,
                                        backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                        cursor: 'pointer'
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: 'none',
                                    },
                                })}
                                onChange={(data: any) => { setTypeDate(data.value) }}
                                value={optionsTypeDate.find(option => option.value === typeDate)}
                                className='react-select-styled z-index-3'
                                classNamePrefix='react-select'
                                options={optionsTypeDate}
                                placeholder='Select an option'
                                isSearchable={false}
                            />

                            <DateRangePicker
                                cleanable={false}
                                character=" - "
                                format="dd MMM yyyy"
                                locale={calendarLocale}
                                value={[startDate, endDate]}
                                ranges={predefinedRanges}
                                placement="bottomEnd"
                                onChange={(value) => {
                                    if (value && value.length === 2) {
                                        setStartDate(value[0]);
                                        setEndDate(value[1]);
                                    }
                                }}
                                showOneCalendar={isMobile}
                                disabled={loading}
                                isoWeek={true}
                            />

                            {
                                isFullyLoaded ?
                                    <div onClick={() => {
                                        mutate(`${API_URL}v2/leads`)
                                        setFetchCounter(fetchCounter + 1)
                                    }} className="btn btn-sm btn-light ms-2">
                                        <i className="fas fa-sync-alt p-0"></i>
                                    </div> :
                                    <div className="spinner-border spinner-border-sm ms-2 my-auto" role="status">
                                    </div>
                            }
                        </div>
                    </div>
                }
            </div>

            <div className={`d-flex ${(user.data.permission_pipeline_read !== 'OWNED' && user.data.permission_pipeline_read !== 'NONE') && !merchantId ? 'justify-content-between' : 'justify-content-end'}`}>
                {
                    (user.data.permission_pipeline_read !== 'OWNED' && user.data.permission_pipeline_read !== 'NONE') && !merchantId &&
                    <UserScopeModal
                        user_id={user_id}
                        setUser_id={setUser_id}
                        user_name={user_name}
                        setUser_name={setUser_name}
                        scope={scope}
                        setScope={setScope}
                        permission={user.data.permission_pipeline_read}
                        apiURL="v2/leads"
                    />
                }

                <div className="d-flex align-items-center">
                    {
                        merchantId &&
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "0.475rem",
                                    width: "fit-content",
                                    minWidth: "150px",
                                    boxShadow: 'none',
                                }),
                                option: (baseStyles, { isSelected, isFocused }) => ({
                                    ...baseStyles,
                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                    cursor: 'pointer',
                                }),
                                indicatorSeparator: (baseStyles, state) => ({
                                    ...baseStyles,
                                    display: 'none',
                                }),
                                menuList: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "fit-content",
                                    minWidth: "150px",
                                }),
                                menu: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: "fit-content",
                                    minWidth: "150px",
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'none',
                                },
                            })}
                            onChange={(data: any) => {
                                navigate('/merchants/' + templateMerchantId + "/" + merchantId + '/pipelines/' + data.value)
                            }}
                            closeMenuOnSelect={true}
                            placeholder={`Template ${settings.pipeline_title}`}
                            options={multiPipelines}
                            value={multiPipelines.find(option => option.value === templatePipelineId)}
                            className="react-select-styled z-index-3 mb-2 me-3"
                        />
                    }

                    <ul className="nav nav-pills mb-2 align-items-center" style={{ flexWrap: "unset" }}>
                        <li className="nav-item m-0" onClick={() => { setDisplayMode("table"); }}>
                            <a className={`btn btn-sm btn-icon btn-white me-3 ${displayMode === "table" ? "btn-primary" : "btn-active-light-primary"}`} data-bs-toggle="tab" href="#tab_table_pipelines" >
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black"></path>
                                        <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black"></path>
                                    </svg>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item m-0" onClick={() => { setDisplayMode("board"); }}>
                            <a className={`btn btn-sm btn-icon btn-white ${displayMode === "board" ? "btn-primary me-5" : "btn-active-light-primary"}`} data-bs-toggle="tab" href="#tab_board_pipelines" >
                                <span className="svg-icon svg-icon-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"></rect>
                                            <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                            <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                            <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {
                displayMode === "board" &&
                <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", alignItems: "stretch", flexWrap: "wrap" }}>
                    <div>
                        {searchComponent()}
                    </div>
                    <div className="card-toolbar align-self-center d-flex">
                        {
                            actionsModeBoard && actionsModeBoard.map((action, index) => (
                                <div key={index} className="ms-5 ms-md-3">
                                    {action}
                                </div>
                            ))
                        }
                    </div>
                </div>
            }

            {
                displayMode === 'table' ? (
                    <MasterDataTable
                        tableKey="pipelines"
                        tableColumns={manageColumns}
                        actions={actions}
                        apiURL={`v2/leads`}
                        dateStart={moment(startDate).format('YYYY-MM-DD')}
                        dateEnd={moment(endDate).format('YYYY-MM-DD')}
                        customParams={typeDate}
                        user_id={user_id}
                        merchantId={merchantId}
                        scope={scope}
                        setLoading={setLoading}
                        autoReload={false}
                        onRowClicked={onRowClicked}
                        searchComponent={searchComponent}
                    />
                ) : (
                    <>
                        {
                            isValidatingTable['pipelines'] &&
                            <div className="loading-overlay">
                                <div className="loader"></div>
                            </div>
                        }

                        <DndContext onDragEnd={handleDragEnd} onDragOver={handleDragOver} sensors={sensors} collisionDetection={closestCenter}>
                            <SortableContext items={leadProgress} strategy={horizontalListSortingStrategy}>
                                <section ref={parentRef}>
                                    <div
                                        style={{
                                            height: columnVirtualizer.getTotalSize(),
                                            width: '100%',
                                            position: 'relative',
                                        }}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                transform: `translateX(${items[0]?.start ?? 0}px)`,
                                            }}
                                            className="lists-container position-relative">
                                            {columnVirtualizer.getVirtualItems().map((virtualRow) => {
                                                const progress = leadProgress[virtualRow.index];
                                                return (
                                                    <MasterBoardPipelinesContent
                                                        key={progress.id}
                                                        id={progress.id}
                                                        dataProgress={progress}
                                                        data={filteredTablesData["pipelines"].filter((pipeline) => pipeline.lead_progress_name === progress.name)}
                                                        setShowUpdateModal={setShowUpdateModal}
                                                        setShowDeleteModal={setShowDeleteModal}
                                                        setShowAddLogModal={setShowAddLogModal}
                                                        setShowDetailModal={setShowDetailModal}
                                                        setShowDetailProductModal={setShowDetailProductModal}
                                                        setPipeline={setPipeline}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </section>
                            </SortableContext>
                        </DndContext>
                    </>
                )
            }

            <Menu
                popup
                ref={actionRef}
                model={[
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.UPDATE' }),
                        icon: 'pi pi-pencil',
                        command: () => {
                            setShowUpdateModal(true);
                        },
                        visible: isActionUpdatePipeline(user, merchantId!)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.DELETE' }),
                        icon: 'pi pi-trash',
                        command: () => {
                            setShowDeleteModal(true);
                        },
                        visible: isActionDeletePipeline(user, merchantId!)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.LOGS' }),
                        icon: 'pi pi-eye',
                        command: () => {
                            window.open(`/pipelines/${templatePipelineId}/log/${pipeline?.id}`, '_blank');
                        },
                        visible: isActionLogPipeline(user)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.ADD_LOG' }),
                        icon: 'pi pi-plus-circle',
                        command: () => {
                            setShowAddLogModal(true);
                        },
                        visible: isActionAddLogPipeline(user, pipeline!, merchantId!, settings, companyId)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.PRODUCTS_DETAIL' }),
                        icon: 'pi pi-info-circle',
                        command: () => {
                            setShowDetailProductModal(true);
                        },
                        visible: isActionDetailProductPipeline(merchantId!)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.ACTIVITIES' }),
                        icon: 'pi pi-eye',
                        command: () => {
                            setShowActivityModal(true);
                        },
                        visible: isActionActivitiyPipeline(user, companyId, roleId, merchantId!)
                    },
                    {
                        label: intl.formatMessage({ id: 'FORM.ACTION.DETAIL_TERMIN' }),
                        icon: 'pi pi-eye',
                        command: () => {
                            setShowDetailTermin(true);
                        },
                        visible: isActionDetailTerminPipeline(pipeline!)
                    }
                ].filter(Boolean)} // Filter out any `false` or `undefined` values from the array
            />

            {
                showDetailModal && <DetailPipeline data={pipeline!} />
            }
            {
                showUpdateModal && <UpdatePipeline pipeline={pipeline!} />
            }
            {
                showDeleteModal && <DeletePipeline pipeline={pipeline!} />
            }
            {
                showAddLogModal && <AddLog lead={pipeline!} showAddLogButton progress={currentProgressOver} setProgress={setCurrentProgressOver} progresses={progresses} progressesLoading={progressesLoading} />
            }
            {
                showAttachmentModal && <PipelineAttachments pipeline={pipeline!} title={customFields[key].name!} url={url} />
            }
            {
                showDetailProductModal && <DetailProductPipeline pipeline={pipeline!} />
            }
            {
                showActivityModal && <ActivityPipeline pipeline={pipeline!} startDate={moment(startDate).format('YYYY-MM-DD')} endDate={moment(endDate).format('YYYY-MM-DD')} />
            }
            {
                settings.pipeline_abandoned_lock && <AbandonedPipeline />
            }
            {
                showDetailTermin && <DetailTerminPipeline pipeline={pipeline!} />
            }
        </>
    )
}

export { MasterPipelinesContent }
