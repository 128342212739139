import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG } from "../../_metronic/helpers";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { UpdateEmailType, updateEmail } from "../../api/MasterEmailCRUD";
import { Email } from "../../interfaces/Email";
import { insertEmailAttachment } from "../../api/ResourceAPI";
import { handleCaps } from "../../functions/general";

const API_URL = process.env.REACT_APP_API_URL

interface UpdateEmailProps {
    email: Email
}

const UpdateEmail: FC<UpdateEmailProps> = ({ email }) => {
    const intl = useIntl();
    const token = useAccessToken();
    const { tablesData, setTablesData } = useDataTableStore();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: email.firstName,
            lastName: email.lastName,
            avatar: email.photoUrl,
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string(),
            lastName: Yup.string(),
            avatar: Yup.mixed().nullable(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
                let photoUrl = values.avatar ? email.photoUrl : '';

                if (values.avatar) {
                    const attachmentResponse = await insertEmailAttachment(values.avatar);
                    photoUrl = attachmentResponse.data.data[0];
                }
                const updateEmailData: UpdateEmailType = {
                    id: email.id,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    photoUrl: photoUrl === "" ? '-' : photoUrl,
                };
                const updateEmailFunction = updateEmail(token, updateEmailData);
                const response = await updateEmailFunction(`${API_URL}email/update-email`);
                if (response) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_EMAIL_SUCCESS" })}`,
                        timer: 1500,
                        timerProgressBar: true
                    });
                    setTablesData('emails', tablesData.emails.map((item: any) =>
                        item.id === email.id ? response.data : item
                    ));
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response?.data?.message || 'An error occurred',
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                });
            } finally {
                setSubmitting(false);
            }
        }
    });

    const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.currentTarget.files?.[0];
        if (file) {
            formik.setFieldValue('avatar', file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAvatarPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        } else {
            formik.setFieldValue('avatar', null);
            setAvatarPreview(null);
        }
    };

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`update-email-modal-${email.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })} Email</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}

                                {/* First Name */}
                                <label className='form-label fs-6 fw-bolder text-dark'>First Name</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='First Name'
                                        {...formik.getFieldProps('firstName')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.firstName && formik.errors.firstName },
                                        )}
                                        type='text'
                                        name='firstName'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.firstName}</span>
                                    </div>
                                )}

                                {/* Last Name */}
                                <label className='form-label fs-6 fw-bolder text-dark'>Last Name</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder='Last Name'
                                        {...formik.getFieldProps('lastName')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.lastName && formik.errors.lastName },
                                        )}
                                        type='text'
                                        name='lastName'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.lastName && formik.errors.lastName && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.lastName}</span>
                                    </div>
                                )}

                                {/* Avatar */}
                                <label className='form-label fs-6 fw-bolder text-dark'>Avatar (Optional)</label>
                                <div className="mb-5 position-relative">
                                    {formik.values.avatar && formik.values.avatar !== '-' ?
                                        <img
                                            className="mb-3"

                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            src={avatarPreview || formik.values.avatar}
                                            alt='user-icon'
                                        />
                                        :
                                        <img
                                            className="mb-3"

                                            style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                                            src={avatarPreview || '/media/icons/duotune/communication/com013.svg'}
                                            alt='user-icon'
                                        />
                                    }
                                    {formik.values.avatar && formik.values.avatar !== '-' && (
                                        <button
                                            type="button"
                                            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                            style={{
                                                position: 'absolute',
                                                top: '1px',
                                                left: '-7px',
                                            }}
                                            onClick={() => {
                                                formik.setFieldValue('avatar', null);
                                                setAvatarPreview(null);
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = '';
                                                }
                                            }}
                                        >
                                            <i className="bi bi-x fs-2"></i>
                                        </button>
                                    )}
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept="image/*"
                                        onChange={handleAvatarChange}
                                        className="form-control form-control-lg"
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? 'disabled' : ''}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { UpdateEmail }