import React from 'react'
import {IComponent} from '../../../interfaces/Analytics/Analytics'
import {ColumnDef, createColumnHelper, getCoreRowModel, useReactTable} from '@tanstack/react-table'
import {useIntl} from 'react-intl'
import TableLite from '../../DataTable/TableLite'
import { formatTimeByFrame, toRupiah } from '../../../functions/general'
import clsx from 'clsx'
interface IProps {
  component: IComponent
  usePagination?: boolean
}
interface RowData {
  time: string
}
export default function List({component,usePagination}: IProps) {
  const intl = useIntl()
  const columnHelper = createColumnHelper<RowData>()
  const mergedData: any[] = []
  component.dataCore.forEach((core) => {
    core.data.forEach((entry) => {
      let existing = mergedData.find((item) => item.time === entry.time)
      if (!existing) {
        existing = {time: entry.time}
        mergedData.push(existing)
      }
      existing[core.name] = entry.value
    })
  })
  const columns: ColumnDef<RowData, any>[] = [
    columnHelper.accessor('time', {
      header: intl.formatMessage({id: 'FORM.ACTION.DATE'}),
      size: 10,
      cell: (info) => (
        <span className='d-flex justify-content-between align-items-center gap-3'>
          <span>{formatTimeByFrame( info.getValue(), component.timeFrame)}</span>
        </span>
      ),
    }),
  ]

  component.dataCore.forEach((core) => {
    columns.push({
      header: core.name,
      accessorKey: core.name,
      size: 10,
      cell: (info) => (
        <span className={clsx('d-flex  align-items-center gap-3 w-100 px-2', component.dataType==="NOMINAL"?"justify-content-end ":"justify-content-center")}>
          {component.dataType==="NOMINAL"?  toRupiah(info.getValue()) :info.getValue()}
        </span>
      ),
    })
  })

  return ( <div className='px-4'>
     <TableLite usePagination={usePagination} columns={columns} data={mergedData} />
  </div>
  )
  }
