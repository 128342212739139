import { lazy, FC, Suspense, useEffect, useRef } from 'react'
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { Dashboard } from '../pages/dashboard/Dashboard'
import { PipelinesWrapper } from '../pages/pipeline/PipelinesWrapper'
import { Logs } from '../pages/pipeline/logs/Logs'
import { MasterPipelines } from '../pages/pipeline/MasterPipelines'
import { MasterProducts } from '../pages/product/MasterProducts'
import { ProductsWrapper } from '../pages/product/ProductsWrapper'
import { MasterProgresses } from '../pages/progress/MasterProgresses'
import { MasterMerchant } from '../pages/merchant/MasterMerchants'
import { MasterSources } from '../pages/source/MasterSources'
import { UserModel } from '../modules/auth/models/UserModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'
import { MasterMeetings } from '../pages/meetings/MasterMeetings'
import { UsersWrapper } from '../pages/users/UsersWrapper'
import { MasterUsers } from '../pages/users/MasterUsers'
import { MasterActivities } from '../pages/activity/MasterActivities'
import { MasterRoles } from '../pages/roles/MasterRoles'
import { MasterSettings } from '../pages/settings/MasterSettings'
import { MasterTargets } from '../pages/targets/MasterTargets'
import { Chat } from '../pages/chat/Chat'
import { Emails } from '../pages/email/Emails'
import { Attendance } from '../pages/attendance/Attendance'
import { AttendanceWrapper } from '../pages/attendance/AttendanceWrapper'
import { Capture } from '../pages/attendance/capture/Capture'
import { ReportDailyAttendance } from '../pages/report/ReportDailyAttendance'
import { ReportAttendanceRecap } from '../pages/report/ReportAttendanceRecap'
import { MasterLabels } from '../pages/label/MasterLabels'
import { MasterSchedule } from '../pages/schedule/MasterSchedule'
import { MasterValidationPhone } from '../pages/validation-phone/MasterValidationPhone'
import { useSettingsStore } from '../../stores/SettingsStore'
import { WebView } from '../pages/WebView'
import { Reimbursement } from '../pages/reimbursement/Reimbursement'
import { ReimbursementCategories } from '../pages/reimbursement-category/ReimbursementCategories'
import { MasterConferences } from '../pages/conference/MasterConferences'
import { MasterUnits } from '../pages/unit/MasterUnits'
import { MerchantPipelines } from '../pages/merchant/MerchantPipelines'
import MasterEmail from '../pages/email/MasterEmail'
import { MerchantMeetings } from '../pages/merchant/MerchantMeetings'
import { ReimbursementLimits } from '../pages/reimbursement-limit/ReimbursementLimits'
import { MasterProgressSettings } from '../pages/roles/MasterProgressSettings'
import { usePipelineStore } from '../../stores/PipelineStore'
import { useRouteStore } from '../../stores/RouteStore'
import { MerchantWrapper } from '../pages/merchant/MerchantWrapper'
import { Analytics } from '../pages/analytics/Analytics'
import AccountReceivable from '../../components/AccountReceivable/AccountReceivable'
import { useCustomeStore } from '../../stores/CustomeStore'
import { MeetingsWrapper } from '../pages/meetings/MeetingsWrapper'
import { useMerchantStore } from '../../stores/MerchantStore'
import { useMeetingStore } from '../../stores/MeetingsStore'
import { AnalyticsTemplate } from '../pages/analytics/AnalyticsTemplate'
import { AnalyticsWrapper } from '../pages/analytics/AnalyticsWrapper'

const PrivateRoutes = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const { settings } = useSettingsStore()
  const { multiMerchants } = useMerchantStore()
  const { multiMeetings } = useMeetingStore()
  const { multiPipelines } = usePipelineStore()
  const { setPrevRoute } = useRouteStore()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { companyId } = useCustomeStore()


  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  useEffect(() => {
    if (multiPipelines.length > 0) {
      if (pathname === '/pipelines') navigate(`/pipelines/${multiPipelines[0].id}`)
    }
  }, [multiPipelines])
  useEffect(() => {
    if (multiMerchants.length > 0) {
      if (pathname === '/merchants') navigate(`/merchants/${multiMerchants[0].id}`)
    }
  }, [multiMerchants])
  useEffect(() => {
    if (multiMeetings.length > 0) {
      if (pathname === '/meetings') navigate(`/meetings/${multiMeetings[0].id}`)
    }
  }, [multiMeetings])

  const location = useLocation();
  const prevLocation = useRef(location.pathname);

  useEffect(() => {
    prevLocation.current = location.pathname;
  }, [location]);

  useEffect(() => {
    setPrevRoute(prevLocation.current);
  }, [prevLocation]);

  return (
    <Routes>
      <Route path='t/:token/:page' element={<WebView />} />
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route element={<DashboardWrapper />} />
        <Route path='dashboard' element={<Dashboard />} />
        {        
        (user.data.company_id === companyId.tkd || user.data.company_id === companyId.looyalIT|| user.data.company_id === companyId.QA|| user.data.company_id === companyId.QAProd)&& user.data.is_superadmin
        && <Route path='analytics' element={<AnalyticsWrapper />}>
                        <Route index element={<AnalyticsTemplate />} />
                        <Route path=':templateAnalyticId' element={<Analytics />} />
                    </Route>
                }
        {
          (settings.feature_pipeline && (user.data.permission_pipeline_read !== "NONE" || document.referrer.includes('merchants'))) &&
          <Route path='pipelines' element={<PipelinesWrapper />}>
            <Route path=':templatePipelineId' element={<MasterPipelines />} />
            <Route path=':templatePipelineId/log/:code' element={<Logs />} />
          </Route>
        }

        {/* {
          settings.feature_meeting && user.data.permission_meeting_read !== "NONE" &&
          <Route path='meetings' element={<MasterMeetings />} />
        } */}

        {
          settings.feature_meeting && user.data.permission_meeting_read !== "NONE" &&
          <>
            <Route path='meetings' element={<MeetingsWrapper />}  >
              {/* <Route path=':templateMerchantId/meetings/:id' element={<MerchantMeetings />} /> */}
              <Route path=':templateMeetingsId' element={<MasterMeetings />} />
              {/* <Route path=':templateMerchantId/:merchantId/pipelines/:templatePipelineId' element={<MerchantPipelines />} /> */}
            </Route>
          </>
        }

        {
          settings.feature_merchant && user.data.permission_merchant_read !== "NONE" &&
          <>
            <Route path='merchants' element={<MerchantWrapper />}  >
              <Route path=':templateMerchantId/meetings/:id' element={<MerchantMeetings />} />
              <Route path=':templateMerchantId' element={<MasterMerchant />} />
              <Route path=':templateMerchantId/:merchantId/pipelines/:templatePipelineId' element={<MerchantPipelines />} />
            </Route>
          </>
        }

        {
          user.data.permission_user_read !== "NONE" && user.data.is_superadmin &&
          <Route path='users' element={<UsersWrapper />}>
            <Route index element={<MasterUsers />} />
          </Route>
        }

        {
          settings.feature_activities &&
          <Route path='activities' element={<MasterActivities />} />
        }

        {
          settings.feature_product && user.data.permission_product_read !== "NONE" && user.data.is_superadmin &&
          <Route path='products' element={<ProductsWrapper />}>
            <Route index element={<MasterProducts />} />
          </Route>
        }

        {
          settings.feature_label &&
          <Route path='labels' element={<MasterLabels />} />
        }

        {
          settings.feature_unit &&
          <Route path='units' element={<MasterUnits />} />
        }

        {
          settings.feature_progress && user.data.is_superadmin &&
          <Route path='progresses' element={<MasterProgresses />} />
        }

        {
          settings.feature_source && user.data.is_superadmin &&
          <Route path='sources' element={<MasterSources />} />
        }

        {
          settings.feature_conference && user.data.companyLimitMasterRoom > 0 &&
          <Route path='conferences' element={<MasterConferences />} />
        }

        {
          user.data.companyLimitMasterEmail > 0 &&
          <Route path='emails' element={<MasterEmail />} />
        }

        {
          user?.data.is_superadmin &&
          <Route path='settings' element={<MasterSettings />} />
        }

        {
          settings.feature_check_number &&
          <Route path='validation-phone' element={<MasterValidationPhone />} />
        }

        {
          // user.data.permission_user_target_set !== "NONE" && !((user.data.permission_user_target_set === "WHOLE TEAM EXCEPT SELF" || user.data.permission_user_target_set === "TEAM EXCEPT SELF") && subordinates.length === 0) &&
          settings.feature_target && user.data.permission_user_target_set !== "NONE" &&
          <Route path='targets' element={<MasterTargets />} />
        }

        {
          user?.data.is_superadmin &&
          <>
            <Route path='roles' element={<MasterRoles />} />
            <Route path='roles/lead-progress-access/:id' element={<MasterProgressSettings />} />
          </>
        }

        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />

        {
          settings.feature_attendance && !user.data.is_superadmin &&
          <Route path='attendance' element={<AttendanceWrapper />}>
            <Route index element={<Attendance />} />
            {
              <>
                <Route path='clock-in' element={<Capture />} />
                <Route path='clock-out' element={<Capture />} />
              </>
            }
          </Route>
        }

        <Route path='calendar' element={<MasterSchedule />} />

        {
          settings.feature_attendance && user?.data.is_superadmin &&
          <Route path='report-daily-attendance' element={<ReportDailyAttendance />} />
        }

        {
          (user.data.company_id === companyId.maspionSquare || user.data.company_id === companyId.giaGroup) &&
          <Route path='report-account-receivable' element={<AccountReceivable />} />
        }

        {
          settings.feature_attendance && user?.data.is_superadmin &&
          <Route path='report-attendance-recap' element={<ReportAttendanceRecap />} />
        }

        {
          settings.feature_reimbursement &&
          <Route path='reimbursement' element={<Reimbursement />} />
        }

        {
          settings.feature_reimbursement &&
          <Route path='limits' element={<ReimbursementLimits />} />
        }

        {
          settings.feature_reimbursement && user?.data.is_superadmin &&
          <Route path='categories' element={<ReimbursementCategories />} />
        }

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
