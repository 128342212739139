import { FC } from "react";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { Link, useParams } from "react-router-dom";
import { MasterPipelinesContent } from "../pipeline/MasterPipelinesContent";

const MerchantPipelines: FC = () => {

    const { settings } = useSettingsStore()

    const { merchantId } = useParams()

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">
                        <Link to={'/merchants'}>
                            {settings.merchant_title}
                        </Link>
                    </li>
                    <li className="breadcrumb-item text-dark">{settings.pipeline_title}</li>
                </ol>
            </div>
            <MasterPipelinesContent merchantId={merchantId} />
        </>
    )
}

export { MerchantPipelines }