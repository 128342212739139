import { FC, useEffect, useState } from 'react'
import { checkSession } from '../../../api/ChatHelper'
import useUser from '../../../hooks/User'
import { useChatStore } from '../../../stores/ChatStore'
import { ToC } from '../../../components/Chat/ToC'
import { PageNoPermisson } from '../general/PageNoPermission'
import { useSettingsStore } from '../../../stores/SettingsStore'
import useAccessToken from '../../../hooks/AccessToken'
import { ChatSidebar } from '../../../components/Chat/ChatSidebar'
import { ChatWindow } from '../../../components/Chat/ChatWindow'
import moment from 'moment';
import { Socket, io } from 'socket.io-client';
import { Message } from '../../../interfaces/Chat/Message'
import { Onboarding } from '../../../components/Chat/Onboarding/Onboarding'
import Swal from 'sweetalert2'
import axios from 'axios'
import useSWR from 'swr'
import { MerchantLeads, RecentChatHistory } from '../../../interfaces/Chat/RecentChatHistory'
import { clearDB } from '../../../db'
import { ChatAside } from '../../../components/Chat/ChatAside'
import { ChatSetting } from '../../../components/Chat/Settings/ChatSetting'
import { ChatLabelSetting } from '../../../components/Chat/Settings/ChatLabelSetting'
import { ChatDetailLabelSetting } from '../../../components/Chat/Settings/ChatDetailLabelSetting'
import { ChatQuickReplySetting } from '../../../components/Chat/Settings/ChatQuickReplySetting'
import { SettingMultiPipelines } from '../../../interfaces/Settings'
import { usePipelineStore } from '../../../stores/PipelineStore'
import { useUserStore } from '../../../stores/UserStore'
import { useCustomeStore } from '../../../stores/CustomeStore'
import { useDispatch } from 'react-redux'
import * as auth from '../../../app/modules/auth/redux/AuthRedux'
import ModalForwardChat from '../../../components/Chat/Modal/ForwardChat/ModalForwardChat'
import { USER_MANAGER_HAPPY_PLAY } from '../../../constant/General'
import useShowCompanyById from '../../../hooks/useShowCompanyById'
import { useTokenCustomCompany } from '../../../stores/useTokenCustomCompany'
import { getChatMessagesData, getChatRoomsData, getInitSyncData, getTotalChatData } from '../../../api/ChatCRUD'
import { calculateTotalChats, sortListChat } from '../../../functions/chat'
import SelectUser from '../../../components/Chat/Onboarding/SelectUser'
import { ListChatPhone } from '../../../interfaces/Chat/ListChatPhone'
import { SettingMultiMeetings } from '../../../interfaces/Meeting'
import { useMeetingStore } from '../../../stores/MeetingsStore'

const Chat: FC = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const token = useAccessToken()
    const {
        setConnected, agreedTNC, recentChatHistories, setFilteredRecentChatHistories, selectedChatPhone,
        setRecentChatHistories, persistedMessages, socket, setLastSynced, setListChatPhone, setLoadingListChatPhone,
        setPersistedMessages, isFirstSyncing, setIsFirstSyncing, setMessages, listChatPhone,
        connected, lastSynced, setRecentChatIsSyncing, setIsWaitingForNetwork, tokenChat, setSocket,
        setMessagesIsSyncing, menuActive, setChatLabels, quickReplies, setQuickReplies,
        isOpenForwardChat, setIsOpenForwardChat, incomingMessageNotifications, setIncomingMessageNotifications
    } = useChatStore()
    const { settings } = useSettingsStore()
    const { setPipelines, setPipelineLoading, setSelectMultiPipeline, setMultiPipelines } = usePipelineStore()
    const { setMeetings, setSelectMultiMeeting, setMultiMeetings } = useMeetingStore()
    const { setUsers, setUserLoading } = useUserStore()

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [totalRecentChats, setTotalRecentChats] = useState(0)
    const [totalChats, setTotalChats] = useState(0)
    const [offset, setOffset] = useState(0)
    const [offsetChatRoom, setOffsetChatRoom] = useState(2500)
    const user = useUser()
    const { companyId } = useCustomeStore()
    const { tokenCustomCompany, numberCustomCompany } = useTokenCustomCompany()
    const [isTabActive, setIsTabActive] = useState(true)
    const dispatch = useDispatch()
    const { company } = useShowCompanyById(user.data.company_id)
    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const { data: version = [], error: versionError, isLoading: versionLoading, isValidating: versionValidating } = useSWR(`${API_URL}version`, fetcher)
    const { data: chatPhone = [], error: chatPhoneError, isLoading: chatPhoneLoading, isValidating: chatPhoneValidating } = useSWR(`${API_URL}v2/chat/auth/phones`, fetcher)
    const { data: labels = [], error: labelsError, isLoading: labelsIsLoading } = useSWR(`${API_URL}/chat/labels`, fetcher)
    const { data: quickRepliesData = [], error: quickRepliesError, isLoading: quickRepliesIsLoading } = useSWR(`${API_URL}/chat/quick-replies`, fetcher)
    const { data: leadTemplate = [], error: leadTemplateError, isLoading: leadTemplateLoading } = useSWR(`${API_URL}lead-templates`, fetcher)
    const { data: meetingTemplate = [], error: meetingTemplateError, isLoading: meetingTemplateLoading } = useSWR(`${API_URL}meeting-templates`, fetcher)
    const { data: userData = [], error: usersError, isLoading: usersLoading } = useSWR(`${API_URL}users`, fetcher)
    const { data: leads = [], error: leadsError, isLoading: leadsLoading } = useSWR(`${API_URL}simple-leads`, fetcher)

    const getTotalChat = async (phone: string) => {
        const { recentChatHistories, selectedChatPhone } = useChatStore.getState();
        setRecentChatIsSyncing(true);

        try {
            const response = await getTotalChatData(phone, tokenChat, token);
            if (response.data.success) {
                const totalData = calculateTotalChats(response.data.data);
                const totalRecentChatsData = totalData.totalRecentChats;
                const totalChatsData = totalData.totalChats;

                setIsWaitingForNetwork(false);
                setTotalRecentChats(totalRecentChatsData);
                setTotalChats(totalChatsData);

                if (lastSynced || recentChatHistories.length >= totalRecentChatsData) {
                    await Promise.all(
                        selectedChatPhone.map(async (item) => {
                            await getChatRooms(lastSynced, item.phone, undefined, totalRecentChatsData);
                        })
                    );
                } else {
                    setRecentChatHistories(() => []);
                    setIsFirstSyncing(true);

                    await Promise.all(
                        selectedChatPhone.map(async (item) => {
                            await getChatRooms('', item.phone, { offset: 0, limit: 500 }, totalRecentChatsData);
                        })
                    );
                }
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response?.data?.message || 'Error fetching chat data',
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    };

    const getInitSync = async (currentOffset: number, phone: string) => {
        try {
            const response = await getInitSyncData(10000, currentOffset, phone, tokenChat, token);
            if (response.data.success) {
                const { persistedMessages } = useChatStore.getState();

                let newPersistedMessages = { ...persistedMessages };

                response.data.data.forEach((chatMessage: any) => {
                    const newMessage: Message = {
                        as: chatMessage.as,
                        color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                        id: chatMessage.chat_id,
                        id_reference: chatMessage.chat_id_reference,
                        text: chatMessage.chat_message,
                        time: chatMessage.chat_date,
                        is_from_me: chatMessage.chat_from_me,
                        attachment: chatMessage.chat_attachment,
                        attachment_name: chatMessage.chat_attachment_name,
                        name: chatMessage.chat_name,
                        phone: chatMessage.chat_phone,
                        type: chatMessage.chat_from_me === 1 ? 'out' : 'in',
                        is_editing: false,
                        is_replying: false,
                        sender: chatMessage.sender,
                        chat_j_message: chatMessage.chat_j_message,
                        isForwarded: chatMessage.isForwarded,
                    };

                    // Jika belum ada key client_phone, inisialisasi dengan array kosong
                    if (!newPersistedMessages[`${chatMessage.client_phone}-${phone}`]) {
                        newPersistedMessages[`${chatMessage.client_phone}-${phone}`] = { messages: [] };
                    }

                    // Merge data dengan menjaga urutan dan menghindari duplikasi
                    const existingMessages = newPersistedMessages[`${chatMessage.client_phone}-${phone}`].messages;
                    const isDuplicate = existingMessages.some(msg => msg.id === newMessage.id);

                    if (!isDuplicate) {
                        newPersistedMessages[`${chatMessage.client_phone}-${phone}`].messages = [
                            newMessage,
                            ...existingMessages
                        ];
                    }
                });

                // Update state dengan cara merge yang benar
                setPersistedMessages(prev => {
                    const mergedMessages = { ...prev };

                    Object.keys(newPersistedMessages).forEach(phone => {
                        if (!mergedMessages[phone]) {
                            mergedMessages[phone] = { messages: [] };
                        }

                        // Gabungkan pesan baru dengan yang lama
                        mergedMessages[phone].messages = [
                            ...newPersistedMessages[phone].messages,
                            ...mergedMessages[phone].messages
                        ];
                    });

                    return mergedMessages;
                });

                // Lanjutkan proses sinkronisasi jika masih ada data
                const newOffset = currentOffset + 10000;
                if (newOffset < totalChats) {
                    setOffset(newOffset);
                    setTimeout(() => getInitSync(newOffset, phone), 0);
                } else {
                    setMessagesIsSyncing(false);
                    setIsFirstSyncing(false);
                }
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response?.data?.message || "Error fetching messages",
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    };

    const getChatRooms = async (lastSynced: string, phone: string, params?: { offset: number, limit: number }, totalRecentChatsData?: number) => {
        try {
            const response = await getChatRoomsData(lastSynced, phone, tokenChat, token, params);
            if (!response.data.success) return;

            let chatHistory = response.data.data;
            Swal.close();

            if (!lastSynced) {
                const recentChats: RecentChatHistory[] = chatHistory.map((chat: any) => ({
                    as: chat.as,
                    color: selectedChatPhone.find((item) => item.phone === chat.as)?.color,
                    profile_picture_url: chat.profile_picture_url,
                    chat_id: chat.chat_id,
                    chat_name: chat.chat_name,
                    chat_message_sender: chat.chat_message_sender,
                    chat_phone: chat.chat_phone,
                    chat_message: chat.chat_message,
                    chat_date: chat.chat_date,
                    chat_attachment: chat.chat_attachment,
                    chat_attachment_name: chat.chat_attachment_name,
                    chat_list_count: chat.chat_list_count,
                    pipelines: chat.pipelines,
                    isFirstLoaded: true,
                    merchant_id: chat.merchant_id,
                    merchant_name: chat.merchant_name,
                    merchant_name_active: chat.merchant_name_active,
                    merchant_name_PIC: chat.merchant_name_PIC,
                    selected: false,
                    chat_j_message: chat.chat_j_message,
                    isForwarded: chat.isForwarded,
                }));

                const { recentChatHistories, filteredRecentChatHistories } = useChatStore.getState();
                let dataFinalRechentChat = sortListChat([...recentChatHistories, ...recentChats])
                let dataFinalFilteredRecentChat = sortListChat([...filteredRecentChatHistories, ...recentChats]);
                setRecentChatHistories(() => dataFinalRechentChat);
                setFilteredRecentChatHistories(() => dataFinalFilteredRecentChat);

                if (params?.offset !== undefined && totalRecentChatsData && params.offset + 500 <= totalRecentChatsData) {
                    const newOffset = params.offset + 500;
                    setOffsetChatRoom(newOffset);
                    await getChatRooms('', phone, { offset: newOffset, limit: 500 }, totalRecentChatsData);
                } else {
                    setLastSynced(moment().format('YYYY-MM-DD HH:mm:ss').toString());
                    setRecentChatIsSyncing(false);
                }
            } else if (chatHistory.length > 0) {
                setMessagesIsSyncing(true)
                
                const { recentChatHistories } = useChatStore.getState();

                let newRecentChatHistories = recentChatHistories;
                for (const chat of chatHistory.reverse()) {
                    const recentChatHistory = { ...chat, isFirstLoaded: true, color: selectedChatPhone.find((item) => item.phone === chat.as)?.color };
                    newRecentChatHistories = newRecentChatHistories.filter(rch => `${rch.chat_phone}-${rch.as}` !== `${chat.chat_phone}-${chat.as}`);
                    newRecentChatHistories = [recentChatHistory, ...newRecentChatHistories];

                    await getChatMessages(chat.chat_phone, phone, newRecentChatHistories.find(rch => rch.chat_phone === chat.chat_phone)?.chat_id ?? '');
                }

                const sortedListChat = sortListChat(newRecentChatHistories);
                setRecentChatHistories(() => sortedListChat);
                setFilteredRecentChatHistories(() => sortedListChat);

                setRecentChatIsSyncing(false);
                setMessagesIsSyncing(false);
            } else {
                setRecentChatIsSyncing(false);
                setMessagesIsSyncing(false);
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response?.data?.message || 'Error fetching chat rooms',
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    };

    const getChatMessages = async (roomPhone: string, phone: string, lastChatId: string) => {
        try {
            const response = await getChatMessagesData(roomPhone, phone, token, tokenChat, lastChatId);
            if (response.data.success) {
                let client_phone = response.data.data.client_phone
                let chatMessages = response.data.data.chatMessages
                let chatMessagesOrder = response.data.data.chatMessagesOrder
                if (Object.keys(chatMessages).length > 0 && chatMessagesOrder.length > 0) {
                    const { persistedMessages } = useChatStore.getState();

                    const bufferMessages: Message[] = [];
                    chatMessagesOrder.forEach((chatMessageId: string) => {
                        const chatMessage = chatMessages[chatMessageId];
                        if (chatMessage) {
                            const duplicateMessage = persistedMessages[`${client_phone}-${phone}`]?.messages.find(message => message.id === chatMessageId) ?? null;

                            if (duplicateMessage) return;
                            const newMessage: Message = {
                                as: chatMessage.as,
                                color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                                id: chatMessageId,
                                id_reference: chatMessage.chat_id_reference,
                                phone: chatMessage.chat_phone,
                                name: chatMessage.chat_name ?? chatMessage.chat_phone,
                                type: chatMessage.chat_phone !== client_phone ? 'out' : 'in',
                                text: chatMessage.chat_message,
                                time: chatMessage.chat_date,
                                attachment: chatMessage.chat_attachment,
                                attachment_name: chatMessage.chat_attachment_name,
                                is_editing: false,
                                is_replying: false,
                                is_from_me: chatMessage.chat_from_me,
                                sender: chatMessage.sender,
                            };
                            bufferMessages.push(newMessage);
                        }
                    });

                    if (bufferMessages.length === 0) {
                        return
                    }
                    const updatedMessages = [...persistedMessages[`${client_phone}-${phone}`]?.messages ?? [], ...bufferMessages];

                    setPersistedMessages((prevMessages) => ({
                        ...prevMessages,
                        [`${client_phone}-${phone}`]: {
                            messages: updatedMessages
                        }
                    }));

                    if (`${client_phone}-${phone}` === phoneNumber) setMessages(() => updatedMessages);

                    const { filteredRecentChatHistories } = useChatStore.getState();
                    const newFilteredRecentChatHistories = filteredRecentChatHistories.map(recentChat => {
                        if (recentChat.chat_phone === client_phone) {
                            return {
                                ...recentChat,
                                chat_list_count: updatedMessages.length
                            };
                        }
                        return recentChat;
                    })

                    if (bufferMessages.length > 0) {
                        bufferMessages.forEach(message => {
                            if (incomingMessageNotifications[`${message.phone}-${phone}`]) {
                                const newUnreadCount = user.data.chat_phone === message.phone ? incomingMessageNotifications[`${message.phone}-${phone}`].unreadCount : incomingMessageNotifications[`${message.phone}-${phone}`].unreadCount + 1;
                                const newLatestMessage = message.text;
                                setIncomingMessageNotifications((prevState) => ({
                                    ...prevState,
                                    [`${message.phone}-${phone}`]: {
                                        latestMessage: newLatestMessage,
                                        unreadCount: newUnreadCount,
                                    },
                                }));
                            } else {
                                setIncomingMessageNotifications((prevState) => ({
                                    ...prevState,
                                    [`${message.phone}-${phone}`]: {
                                        latestMessage: message.text,
                                        unreadCount: 1,
                                    },
                                }));
                            }
                        })
                    }

                    const sortedListChat = sortListChat(newFilteredRecentChatHistories);
                    setRecentChatHistories(() => sortedListChat);
                    setFilteredRecentChatHistories(() => sortedListChat)
                    setMessagesIsSyncing(false)
                }
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    }

    useEffect(() => {
        if (token && tokenChat) {
            const socket = io(`${API_URL}whatsapp-chat`, {
                reconnection: true,
                reconnectionDelayMax: 10000,
                auth: { CRMToken: token, token: tokenChat }
            });
            setSocket(() => socket)
        }

        const checkConnection = async () => {
            try {
                let customToken = ''
                if (company) {
                    customToken = tokenCustomCompany;
                }

                const response = await checkSession(token, customToken, company)

                if (response.status === 200) {
                    // setConnected(response.data.data.isActive)
                }
            } catch (error) {
                console.log(error)
            }
        }

        setRecentChatHistories((prevState) => prevState.map(recentChatHistory => {
            return {
                ...recentChatHistory,
                isFirstLoaded: true
            }
        }))

        checkConnection()
    }, [])

    useEffect(() => {
        if (!versionLoading) {
            if (!localStorage.getItem('version-crm')) {
                localStorage.setItem('version-crm', version)
            } else if (version !== localStorage.getItem("version-crm")) {
                localStorage.setItem('version-crm', version)
                clearDB()
                window.location.reload();
            }
        }
    }, [version])

    useEffect(() => {
        if (!leadTemplateLoading) {
            const result = leadTemplate.map((lead: SettingMultiPipelines) => ({
                ...lead,
                label: lead.name,
                value: lead.id,
            }));

            let dataLocal = localStorage.getItem("selectMultiPipeline")
            if (dataLocal) setSelectMultiPipeline(JSON.parse(dataLocal))
            else setSelectMultiPipeline(result[0])
            setMultiPipelines(result);
        }
    }, [leadTemplate]);

    useEffect(() => {
        if (!meetingTemplateLoading) {
            const result = meetingTemplate.map((lead: SettingMultiMeetings) => ({
                ...lead,
                label: lead.name,
                value: lead.id,
            }));

            let dataLocal = localStorage.getItem("selectMultiMeeting")
            if (dataLocal) setSelectMultiMeeting(JSON.parse(dataLocal))
            else setSelectMultiMeeting(result[0])
            setMultiMeetings(result);
        }
    }, [meetingTemplate]);

    useEffect(() => {
        setLoadingListChatPhone(chatPhoneValidating)
        if (!chatPhoneValidating) {
            setListChatPhone(chatPhone)
        }
    }, [chatPhone, chatPhoneValidating])

    useEffect(() => {
        if (!leadsLoading) {
            setPipelines(leads)
        }
    }, [leads]);

    useEffect(() => {
        if (!usersLoading) {
            setUsers(userData)
        }
    }, [userData]);

    useEffect(() => {
        if (!labelsIsLoading) {
            setChatLabels(labels)
        }
    }, [labelsIsLoading])

    useEffect(() => {
        if (!quickRepliesIsLoading && quickRepliesData) {
            setQuickReplies(quickRepliesData)
        }
    }, [quickRepliesIsLoading])

    useEffect(() => {
        if (connected) {
            if (company) {
                if (tokenCustomCompany) {
                    socket.emit('setOption', { token: token, [`${company}Token`]: tokenCustomCompany });
                } else {
                    socket.emit('setOption', { token: token });
                }
            } else {
                socket.emit('setOption', { token: token });
            }
            // swal connecting
            setIsWaitingForNetwork(false)
        }

        // if(user.data.company_id === companyId.happyPlay) {
        //     if(connected) {
        //         if (tokenHappyPlay) {
        //             socket.emit('setOption', { token: token, happyPlayToken: tokenHappyPlay });
        //         }
        //     }
        //     setIsWaitingForNetwork(true)
        // } else {
        //     if (connected) {
        //         socket.emit('setOption', { token: token });
        //     }
        //     setIsWaitingForNetwork(true)
        // }

        return () => {
            socket?.off("setOption");
        };
    }, [socket, token, connected, tokenCustomCompany])

    // Server memberikan tanda ketika ada yang salah pada option / konfigurasi
    socket.on('setOptionError', (message) => {
        console.log('setOptionError')
        Swal.fire({
            title: 'Error',
            text: message.error,
            icon: 'error',
            confirmButtonText: 'Refresh',
            didClose: () => {
                clearDB()
                window.location.reload()
            }
        })
    });

    useEffect(() => {
        if (numberCustomCompany) {
            const newUserData = {
                ...user,
                data: {
                    ...user.data,
                    chat_phone: numberCustomCompany
                }
            }
            dispatch(auth.actions.fulfillUser(newUserData))
        }
    }, [numberCustomCompany])

    useEffect(() => {
        socket.on('disconnect', () => {
            console.log('disconnect')
            setIsWaitingForNetwork(true)
        })

        socket.on('sessionDisconnected', () => {
            Swal.fire({
                title: 'Session Disconnected',
                icon: 'warning'
            })
            // setConnected(false)
        })

        return () => {
            socket.off('sessionDisconnected')
        }
    }, [offset, persistedMessages, lastSynced])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 1200) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const { selectedChatPhone } = useChatStore.getState();

        if (selectedChatPhone.length > 0 && tokenChat) {
            getTotalChat(selectedChatPhone[0].phone)
        }
    }, [tokenChat])

    useEffect(() => {
        const { selectedChatPhone } = useChatStore.getState();

        let totalMessages = 0
        Object.keys(persistedMessages).forEach(phone => {
            totalMessages += persistedMessages[phone].messages.length
        })

        if (totalMessages < totalChats && totalChats !== 0 && isFirstSyncing && selectedChatPhone.length > 0 && tokenChat) {
            setPersistedMessages(() => ({}))
            setMessagesIsSyncing(true)

            selectedChatPhone.forEach(async (data) => {
                await getInitSync(offset, data.phone)
            })

            setOffset(10000)
        } else if (totalMessages >= totalChats) {
            setIsFirstSyncing(false)
        }
    }, [totalChats, isFirstSyncing, tokenChat])

    const { phoneNumber } = useChatStore()
    let menu = <ChatSidebar totalChats={totalChats} getChatRooms={getChatRooms} />
    if (menuActive === "chat") menu = <ChatSidebar totalChats={totalChats} getChatRooms={getChatRooms} />
    else if (menuActive === "settings") menu = <ChatSetting />
    else if (menuActive === "settings-label") menu = <ChatLabelSetting />
    else if (menuActive === "settings-detail-label") menu = <ChatDetailLabelSetting />
    else if (menuActive === "settings-quick-reply") menu = <ChatQuickReplySetting />

    document.addEventListener('contextmenu', event => event.preventDefault());

    useEffect(() => {
        const channel = new BroadcastChannel('whatsapp_tab_channel');
        channel.postMessage('tab_opened');
        channel.onmessage = (event) => {
            if (event.data === 'tab_opened') {
                setIsTabActive(false);
                Swal.fire({
                    title: 'Wooblazz Chat sedang terbuka di jendela lain',
                    text: 'Klik "Gunakan di Sini" untuk menggunakan Wooblazz Chat di jendela ini.',
                    showCancelButton: true,
                    confirmButtonText: 'Gunakan di Sini',
                    cancelButtonText: 'Tutup',
                    confirmButtonColor: '#00a884',
                    cancelButtonColor: '#8696a0',
                }).then((result) => {
                    if (result.isConfirmed) {
                        channel.postMessage('take_control');
                        setIsTabActive(true);
                        window.location.reload();
                    } else {
                        window.close();
                    }
                });
            } else if (event.data === 'take_control') {
                setIsTabActive(false);
                window.close();
            }
        };

        return () => {
            channel.close();
        };
    }, []);

    return (
        <>
            {isTabActive ? (
                <>
                    {
                        !agreedTNC && <ToC />
                    }
                    {
                        !settings.feature_chat ?
                            <PageNoPermisson title='Chat' />
                            :
                            agreedTNC &&
                            <>
                                {
                                    (!tokenChat || !connected) && <SelectUser />
                                        // : (listChatPhone?.length === 1 && !connected) && <Onboarding totalRecentChats={totalRecentChats} totalChats={totalChats} />
                                }
                                <div className={`row me-0 mt-2 ${(!connected) && 'd-none'}`}>
                                    <div className={`col-12 col-xl-4 d-${phoneNumber ? 'none' : 'flex'} d-lg-flex`}>
                                        <ChatAside />
                                        {menu}
                                    </div>
                                    <div className={`col-xl-8 d-${phoneNumber ? 'block' : 'none'} d-lg-block`}>
                                        <ChatWindow />
                                    </div>
                                </div>
                                <ModalForwardChat isOpen={isOpenForwardChat} onClose={() => setIsOpenForwardChat(false)} userChats={recentChatHistories} user={user} />
                            </>
                    }
                </>
            ) : null}
        </>
    )
}

export { Chat } 