const pipeline = ['/pipelines'];
const merchant = ['/merchants']
const meeting = ['/meetings']
const master = ['/products', '/labels', '/progresses', '/sources', '/conferences', '/units', '/emails'];

const userRoles = ['/activities', '/targets', '/users', '/roles'];
const reimbursement = ['/reimbursement', 'limits', '/categories'];
const report = ['/report-daily-attendance', '/report-attendance-recap'];

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0];
}

// mengcek jika sebuah value yang ada pada example master maka tetap hidup
export function checkIsSubMenuActive(pathname: string) {
  const newPathname = `/${pathname.split('/')[1]}`
  if (pipeline.includes(newPathname)) {
    return { pipeline: true };
  } else if (merchant.includes(newPathname)) {
    return { merchant: true };
  } else if (meeting.includes(newPathname)) {
    return { meetings: true };
  } else if (master.includes(pathname)) {
    return { master: true };
  } else if (userRoles.includes(pathname)) {
    return { userRole: true };
  } else if (reimbursement.includes(pathname)) {
    return { reimbursement: true };
  } else if (report.includes(pathname)) {
    return { report: true };
  } else {
    return false;
  }
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname);
  checkIsSubMenuActive(current);
  if (!current || !url) {
    return false;
  }

  if (current === url) {
    return true;
  }

  if (current.indexOf(url) > -1) {
    return true;
  }

  return false;
}
