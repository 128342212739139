import React, { useEffect, useRef, useState } from 'react'
import clsx from "clsx";
import { useIntl } from "react-intl";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import { useDataTableStore } from '../../../../stores/DataTableStore';
import { createDetailGroupContactEmail, getColumnCustomFields } from '../../../../api/EmailCRUD';
import useHandleCloseModal from '../../../../hooks/HandleCloseModal';
import { KTSVG } from '../../../../_metronic/helpers';
import { handleCaps } from '../../../../functions/general';
import useSWR from 'swr';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import { CustomFieldType } from '../../../../interfaces/Email/BroadCastEmailType';
import { IDetailGroupEmail } from '../../../../interfaces/Email/InputEmailTypes';

interface AddDetailGroupEmailProps {
  idGroupContact: string
}

const API_URL = process.env.REACT_APP_API_URL_EMAIL

interface FormValues {
  name: string;
  email: string;
  dataCustomField: {
    [key: string]: null;
  };
}

const AddDetailGroupEmail = ({ idGroupContact }: AddDetailGroupEmailProps) => {
  const intl = useIntl();
  const { tablesData, setTablesData } = useDataTableStore();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const [customFields, setCustomFields] = useState<CustomFieldType[] | null>();

  const { data = [], isLoading } = useSWR(`${API_URL}groupcontact/colloum-group-contact?idGroupContact=${idGroupContact}`, getColumnCustomFields)

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedColumns = Object.entries(data[0]).map(([id, columnData]: [string, any]) => ({
        id,
        name: columnData.name,
        type: columnData.type,
        required: columnData.required,
      }))
      setCustomFields(formattedColumns)
    }
  }, [data])

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      dataCustomField: customFields ? Object.fromEntries(customFields.map(field => [field.id, null])) : {}
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'User' })),
      email: Yup.string().email('Invalid email').required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Email' })),
      dataCustomField: Yup.object().shape(
        customFields?.reduce((shape: any, field) => {
          if (field.required) {
            shape[field.id] = Yup.string().nullable().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name }))
          }
          return shape;
        }, {})
      )
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const dataCustomField = Object.entries(values.dataCustomField).map(([key, value]) => ({ [key]: value }));
        const data: IDetailGroupEmail = {
          name: values.name,
          email: values.email,
          dataCustomField,
          idGroupContact: idGroupContact
        }
        const response = await createDetailGroupContactEmail(data);
        if (response) {
          Swal.fire({
            icon: 'success',
            heightAuto: false,
            title: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_EMAIL_SUCCESS" })}`,
            timer: 1500,
            timerProgressBar: true
          });
          setTablesData('detailGroupEmail', ([response.data, ...tablesData.detailGroupEmail]));

          closeModalRef.current?.click();
        }
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error.response?.data?.message || 'An error occurred',
          confirmButtonText: 'Ok',
          heightAuto: false,
        });
      } finally {
        setSubmitting(false);
      }
    }
  });

  const handleCustomFieldChange = (fieldId: string, value: string | null) => {
    formik.setFieldValue(`dataCustomField.${fieldId}`, value);
  };

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleModalHidden = () => {
      formik.resetForm();
    };

    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
  }, [formik]);

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  return (
    <div>
      <button type="button" className="btn btn-primary mt-3" data-bs-toggle="modal" data-bs-target="#add-detail-group-email">
        <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} Email
      </button>
      <div className="modal fade" tabIndex={-1} id="add-detail-group-email" ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} Contact Email</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                onClick={handleCloseModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className="modal-body">
                {/* Name */}
                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                <div className="input-group mb-3">
                  <input
                    placeholder={`${intl.formatMessage({ id: 'FORM.LABEL.NAME' })}`}
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-lg',
                      { 'is-invalid': formik.touched.name && formik.errors.name },
                    )}
                    type='text'
                    name='name'
                    autoComplete='off'
                    onInput={(e) => handleCaps(e)}
                  />
                </div>
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                  </div>
                )}
                {/* Email */}
                <label className='form-label fs-6 fw-bolder text-dark required'> Email</label>
                <div className="input-group mb-3">
                  <input
                    placeholder={`Email`}
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg txt-email',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                    )}
                    type='text'
                    name='email'
                    autoComplete='off'
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container text-danger'>
                    <span role='alert' className="text-danger">{formik.errors.email}</span>
                  </div>
                )}
                {/* Custom Fields */}
                {customFields?.map((field) => {
                  return (
                    <div key={field.id}>
                      <label className={`${field.required ? 'required' : ''} form-label fs-6 fw-bolder text-dark`}>{field.name}</label>
                      <div className='input-group mb-3'>
                        {field.type === 'text' && (
                          <input
                            type="text"
                            className={clsx(
                              'form-control form-control-lg',
                              { 'is-invalid': formik.touched.dataCustomField?.[field.id] && formik.errors.dataCustomField?.[field.id] },
                            )}
                            placeholder={field.name}
                            name={`dataCustomField.${field.id}`}
                            value={formik.values.dataCustomField[field.id] || ''}
                            onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                            autoComplete='off'
                          />
                        )}
                        {field.type === 'number' && (
                          <input
                            type="number"
                            className={clsx(
                              'form-control form-control-lg',
                              { 'is-invalid': formik.touched.dataCustomField?.[field.id] && formik.errors.dataCustomField?.[field.id] },
                            )}
                            placeholder={field.name}
                            name={`dataCustomField.${field.id}`}
                            value={formik.values.dataCustomField[field.id] || ''}
                            onChange={(e) => handleCustomFieldChange(field.id, e.target.value)}
                            onBlur={formik.handleBlur}
                            autoComplete='off'
                          />
                        )}
                        {field.type === 'date' && (
                          <DatePicker
                            format="dd MMM yyyy"
                            style={{ width: "100%", zIndex: 0 }}
                            container={document.querySelector('#add-detail-group-email') as HTMLElement}
                            onChange={(value: Date | null, event: React.SyntheticEvent<Element, Event>) => {
                              if (value) {
                                handleCustomFieldChange(field.id, moment(value).format('YYYY-MM-DD'));
                              } else {
                                handleCustomFieldChange(field.id, null);
                              }
                            }}
                            onBlur={() => formik.setFieldTouched(`dataCustomField.${field.id}`, true)}
                          />
                        )}
                      </div>
                      {formik.touched.dataCustomField?.[field.id] && formik.errors.dataCustomField?.[field.id] && (
                        <div className='fv-plugins-message-container text-danger'>
                          <span role='alert' className="text-danger">{formik.errors.dataCustomField[field.id]}</span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light d-none"
                  data-bs-dismiss="modal"
                  ref={closeModalRef}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={handleCloseModal}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button type="submit" className={`btn btn-primary me-10 ${formik.isSubmitting ? 'disabled' : ''}`} data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}>
                  <span className="indicator-label">
                    {intl.formatMessage({ id: 'FORM.ACTION.CREATE' })}
                  </span>
                  <span className="indicator-progress">
                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddDetailGroupEmail