import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useDataTableStore } from "../../stores/DataTableStore";
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { DetailLimit } from "../../interfaces/ReimbursementLimit";
import { deleteLimit, getLimitDetail } from "../../api/ReimbursementLimitCRUD";
import moment from "moment";

interface Props {
    limit: DetailLimit
}

const DeleteReimbursementLimit: FC<Props> = ({ limit }) => {
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()
    const [detailLoading, setDetailLoading] = useState(true);
    const [checkHasReimbursement, setCheckHasReimbursement] = useState(true);

    async function handleDeleteLimit() {
        try {
            const response = await deleteLimit(limit.id, token)
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}.`,
                    heightAuto: false,
                })
                closeModalRef.current?.click();
                setTablesData('reimbursement-limits', (tablesData['reimbursement-limits'].filter((data) => data.id !== limit.id)))
            }
        } catch (error: any) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok'
            })
        } finally {
            setModalBtnLoading(false)
        }
    }
    const handleDelete = async () => {
        setModalBtnLoading(true)
            
        if (checkHasReimbursement) {
            Swal.fire({
                title: intl.formatMessage({ id: "ALERT.TITLE.WARNING" }),
                text: intl.formatMessage({ id: "REIMBURSEMENT_LIMIT.WARNING.DELETE" }),
                icon: "warning",
                showCancelButton: !0,
                buttonsStyling: !1,
                confirmButtonText: `${intl.formatMessage({ id: "FORM.CONFIRMATION.YES" })}, ${intl.formatMessage({ id: "FORM.ACTION.DELETE" })}!`,
                cancelButtonText: intl.formatMessage({ id: "FORM.CONFIRMATION.NO" }),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary me-2",
                },
                reverseButtons: true,
                heightAuto: false,
            }).then(async (result) => {
                if (result.isConfirmed) await handleDeleteLimit()
                else setModalBtnLoading(false)
            });
        }
        else await handleDeleteLimit()
    }

    useEffect(() => {
        if (detailLoading) {
            console.log("fetch")
            const fetchDetailData = async () => {
                try {
                    const response = await getLimitDetail(
                        limit.id,
                        token
                    )
                    if (response.data.success) {
                        setCheckHasReimbursement(response.data.data.hasReimbursement)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        text: error.response.data.text?? "",
                        confirmButtonText: 'Ok',
                        heightAuto: false,
                    })
                } finally {
                    setDetailLoading(false)
                }
            }

            fetchDetailData()
        }
    }, [])

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`delete-limit-modal-${limit.id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })} {limit.userName} {intl.formatMessage({ id: 'FORM.LABEL.PERIOD' })} {moment(limit.period.start, 'YYYY-MM-DD').format('DD MMM YYYY')} - {moment(limit.period.end, 'YYYY-MM-DD').subtract(1, "days").format('DD MMM YYYY')}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>{intl.formatMessage({ id: 'FORM.CONFIRMATION' })} {intl.formatMessage({ id: 'FORM.ACTION.DELETE' }).toLowerCase()} {limit.userName} {intl.formatMessage({ id: 'FORM.LABEL.PERIOD' }).toLowerCase()} {moment(limit.period.start, 'YYYY-MM-DD').format('DD MMM YYYY')} - {moment(limit.period.end, 'YYYY-MM-DD').subtract(1, "days").format('DD MMM YYYY')} ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: 'FORM.ACTION.CANCEL' })}</button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading || detailLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading || detailLoading ? 'on' : 'off'} onClick={handleDelete}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { DeleteReimbursementLimit }