import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { SelectOption } from "../../interfaces/SelectOption";
import useAccessToken from "../../hooks/AccessToken";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { Subordinate } from "../../interfaces/Subordinate";
import Select from "react-select";
import { UserScope } from "../../interfaces/UserScope";
import useUser from "../../hooks/User";
import { useSettingsStore } from "../../stores/SettingsStore";
import useFilterScopePhrase from "../../hooks/FilterScopePhrase";
import { useIntl } from "react-intl";
// import { getFilterScopePhrase } from "../../functions/general";

interface UserScopeModalProps {
    user_id: string
    setUser_id: (user_id: string) => void
    user_name: string
    setUser_name: (user_name: string) => void
    scope: UserScope
    setScope: (scope: UserScope) => void
    permission: string
    apiURL?: string
    funct?: any
}

export const defaultScope = (permission: string): UserScope => {
    switch (permission) {
        case 'SELF':
            return UserScope.Self
        case 'TEAM':
            return UserScope.WholeTeam
        case 'ALL':
            return UserScope.All
        default:
            return UserScope.Self
    }
}

const UserScopeModal: FC<UserScopeModalProps> = ({ user_id, setUser_id, user_name, setUser_name, scope, setScope, permission, apiURL, funct }) => {
    const { settings } = useSettingsStore()
    const closeButtonRef = useRef<HTMLButtonElement | null>(null);
    const saveButtonRef = useRef<HTMLButtonElement | null>(null);
    const [isMounted, setIsMounted] = useState(false);

    const intl = useIntl()

    const teamScopeOptions: SelectOption[] = [
        { value: UserScope.Self, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.SELF' }) },
        { value: UserScope.Team, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.TEAM' }) }
    ]

    const wholeTeamScopeOptions: SelectOption[] = [
        { value: UserScope.Self, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.SELF' }) },
        { value: UserScope.Team, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.TEAM' }) },
        { value: UserScope.WholeTeam, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.WHOLE_TEAM' }) }
    ]

    const allScopeOptions: SelectOption[] = [
        { value: UserScope.Self, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.SELF' }) },
        { value: UserScope.Team, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.TEAM' }) },
        { value: UserScope.WholeTeam, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.WHOLE_TEAM' }) },
        { value: UserScope.All, label: intl.formatMessage({ id: 'USER.SCOPE.OPTION.ALL' }) }
    ]

    const [selectedUser_id, setSelectedUser_id] = useState<string>(user_id)
    const [selectedScope, setSelectedScope] = useState<UserScope>(scope)
    const [subordinatesData, setSubordinatesData] = useState<SelectOption[]>([]);

    const user = useUser()
    const token = useAccessToken()

    const filterAPIURL = apiURL === 'v2/leads' ? settings.pipeline_title : (/^meetings\b/.test(apiURL!)) ? settings.meeting_title : 'Data'

    const getFilterScopePhrase = useFilterScopePhrase(scope, filterAPIURL, user_name === user.data.name ? undefined : user_name)

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getUserURL = () => {
        if (apiURL === 'user-targets') {
            if (user.data.permission_user_target_set === "ALL") {
                return `${API_URL}users`
            } else if (user.data.permission_user_target_set === "TEAM" || user.data.permission_user_target_set === "TEAM EXCEPT SELF") {
                return `${API_URL}user/one-level-subordinates`
            } else {
                return `${API_URL}user/subordinates`
            }
        }
        else {
            if (user.data.permission_pipeline_read === "ALL") {
                return `${API_URL}users`
            } else {
                return `${API_URL}user/subordinates`
            }
        }
    }

    const { data: subordinates = [], error: subordinatesError, isLoading: subordinatesLoading } = useSWR(getUserURL(), fetcher)

    useEffect(() => {
        if (!subordinatesLoading) {
            const data = subordinates.map((subordinate: Subordinate) => ({
                value: subordinate.id,
                label: subordinate.name
            }));

            if (apiURL === 'user-targets') {
                // if (user.data.permission_user_target_set === "WHOLE TEAM" || user.data.permission_user_target_set === "TEAM") {
                if (user.data.permission_user_target_set !== "ALL") {
                    data.unshift({ value: user.data.id, label: user.data.name })
                }
            }
            else {
                if (user.data.permission_pipeline_read !== "ALL") {
                    data.unshift({ value: user.data.id, label: user.data.name })
                }
            }
            setSubordinatesData(data);
        }
    }, [subordinates]);

    useEffect(() => {
        setSelectedScope(scope)
    }, [scope])

    useEffect(() => {
        if(isMounted) {
            if(apiURL) mutate(`${API_URL}${apiURL}`)
            else funct()
        }else{
            setIsMounted(true)
        }
    }, [selectedScope, selectedUser_id])

    return (
        <div className="fs-5 mb-3">
            {
                (user.data.permission_pipeline_read !== 'OWNED' || apiURL === 'user-targets') &&
                <div className="d-flex mb-2">
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "10px",
                                // borderColor: "black",
                                backgroundColor: "white",
                                minWidth: "100px",
                                maxWidth: "200px",
                            }),
                            option: (baseStyles, { isSelected, isFocused }) => ({
                                ...baseStyles,
                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                cursor: 'pointer'
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'none',
                            },
                        })}
                        onChange={(value) => {
                            setSelectedScope(value!.value as UserScope)
                            setScope(value!.value as UserScope)
                                
                            if (apiURL === 'user-targets' && value!.value === UserScope.Self && (user.data.permission_user_target_set === 'TEAM EXCEPT SELF' || user.data.permission_user_target_set === 'WHOLE TEAM EXCEPT SELF')){
                                setSelectedUser_id(subordinatesData[1]!.value)
                                setUser_id(subordinatesData[1]!.value)
                                setUser_name(subordinatesData[1]!.label)
                                if (subordinatesData.some(el => el.value === user.data.id)) subordinatesData.splice(subordinatesData.findIndex(item => item.value === user.data.id), 1)
                            } else if (apiURL === 'user-targets' && (value!.value === UserScope.Team || value!.value === UserScope.WholeTeam) && (user.data.permission_user_target_set === 'TEAM EXCEPT SELF' || user.data.permission_user_target_set === 'WHOLE TEAM EXCEPT SELF')){
                                if (!subordinatesData.some(el => el.value === user.data.id)) subordinatesData.unshift({ value: user.data.id, label: user.data.name })
                            }
                        }}
                        value={allScopeOptions.find((scope) => scope.value === selectedScope)}
                        className='react-select-styled me-3 z-index-2'
                        classNamePrefix='react-select'
                        options={apiURL === 'user-targets' && user.data.permission_user_target_set === 'ALL' ? 
                                    allScopeOptions 
                                    : 
                                    apiURL === 'user-targets' && (user.data.permission_user_target_set === 'TEAM' || user.data.permission_user_target_set === 'TEAM EXCEPT SELF') ? 
                                        teamScopeOptions 
                                        : 
                                        apiURL === 'user-targets' && (user.data.permission_user_target_set === 'WHOLE TEAM' || user.data.permission_user_target_set === 'WHOLE TEAM EXCEPT SELF') ? 
                                            wholeTeamScopeOptions 
                                            : 
                                            user.data.permission_pipeline_read === 'ALL' ? 
                                                allScopeOptions 
                                                : 
                                                wholeTeamScopeOptions}
                        placeholder='Pilih Scope'
                        isSearchable={true}
                    />

                    {
                        selectedScope !== UserScope.All &&
                        <Select
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "10px",
                                    backgroundColor: "white",
                                    maxWidth: "200px"
                                }),
                                option: (baseStyles, { isSelected, isFocused }) => ({
                                    ...baseStyles,
                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                    cursor: 'pointer'
                                }),
                            }}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary: 'none',
                                },
                            })}
                            onChange={(value) => {
                                setSelectedUser_id(value!.value)
                                setUser_id(value!.value)
                                setUser_name(value!.label)
                            }}
                            value={subordinatesData.find((subordinate) => subordinate.value === selectedUser_id)}
                            className='react-select-styled z-index-2'
                            classNamePrefix='react-select'
                            options={subordinatesData}
                            placeholder={`${intl.formatMessage({id: "FORM.ACTION.CHOOSE"})} ${intl.formatMessage({id: "FORM.MENU.USERS"})}`}
                            isSearchable={true}
                        />
                    }
                </div>
            }

            <span className="fw-bolder">
                {
                    intl.formatMessage({ id: 'USER.SCOPE.MODAL.SHOWING' })
                }
            </span>
            <span className="text-primary"> {getFilterScopePhrase}</span>
            {/* {
                apiURL === "leads" ?
                    <span className="text-primary"> {getFilterScopePhrase}</span>
                    :
                    apiURL === "meetings" ?
                        <span className="text-primary"> {getFilterScopePhrase(scope, "Meeting", user_name === user.data.name ? undefined : user_name)}</span>
                        :
                        apiURL === 'user-targets' ?
                            <span className="text-primary"> {getFilterScopePhrase(scope, "Target", user_name === user.data.name ? undefined : user_name)}</span>
                            :
                            <span className="text-primary"> {getFilterScopePhrase(scope, "Data", user_name === user.data.name ? undefined : user_name)}</span>
                // <span className="text-primary">  {user_name}, {scope !== UserScope.Self ? scope.toUpperCase() : 'PERSONAL'}</span>
            } */}
        </div>


        // <>
        //     <button type="button"
        //         className={`btn btn-light-info ${window.location.pathname !== '/dashboard' && 'mt-3'}`}
        //         data-bs-toggle="modal"
        //         data-bs-target="#user-scope-modal"
        //     >
        //         <i className="fas fa-cog"></i>
        //         Jangkauan {apiURL === 'meetings' ? "Meeting" : settings.pipeline_title}
        //     </button>
        //     <div className="modal fade" tabIndex={-1} id="user-scope-modal">
        //         <div className="modal-dialog modal-dialog-centered">
        //             <div className="modal-content">
        //                 <div className="modal-header">
        //                     <h5 className="modal-title">Jangkauan {apiURL === 'meetings' ? "Meeting" : settings.pipeline_title}</h5>
        //                     <div
        //                         className="btn btn-icon btn-sm btn-active-light-primary ms-2"
        //                         data-bs-dismiss="modal"
        //                         aria-label="Close"
        //                     >
        //                         <KTSVG
        //                             path="/media/icons/duotune/arrows/arr061.svg"
        //                             className="svg-icon svg-icon-2x"
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className="modal-body">
        //                     {
        //                         selectedScope !== UserScope.All &&
        //                         <>
        //                             <div className="required">Bawahan</div>
        //                             <div className="mb-5">
        //                                 <Select
        //                                     placeholder="Pilih Bawahan"
        //                                     value={subordinatesData.find((subordinate) => subordinate.value === selectedUser_id)}
        //                                     options={subordinatesData}
        //                                     onChange={(value) => {
        //                                         setSelectedUser_id(value!.value)
        //                                         setUser_id(value!.value)
        //                                         setUser_name(value!.label)
        //                                     }}
        //                                     required
        //                                 />
        //                             </div>
        //                         </>
        //                     }
        //                     <div>
        //                         <Select
        //                             placeholder="Pilih Scope"
        //                             value={allScopeOptions.find((scope) => scope.value === selectedScope)}
        //                             options={permission === 'ALL' ? allScopeOptions : teamScopeOptions}
        //                             onChange={(value) => {
        //                                 setSelectedScope(value!.value as UserScope)
        //                                 setScope(value!.value as UserScope)
        //                             }}
        //                         />
        //                     </div>
        //                 </div>
        //                 <div className="modal-footer">
        //                     <button
        //                         type="button"
        //                         className="btn btn-light"
        //                         data-bs-dismiss="modal"
        //                         ref={closeButtonRef}
        //                     >
        //                         Close
        //                     </button>
        //                     <button
        //                         type="button"
        //                         className="btn btn-danger"
        //                         onClick={() => {
        //                             setSelectedUser_id(user.data.id)
        //                             setSelectedScope(defaultScope(permission))
        //                             setUser_id(user.data.id)
        //                             setUser_name(user.data.name)
        //                             setScope(defaultScope(permission))
        //                             saveButtonRef.current?.click()
        //                         }}
        //                     >
        //                         Reset
        //                     </button>
        //                     <button type="button" className="btn btn-primary" ref={saveButtonRef}
        //                         onClick={() => {
        //                             mutate(`${API_URL}${apiURL}`)
        //                             closeButtonRef.current?.click()
        //                         }}>
        //                         Simpan
        //                     </button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
}

export { UserScopeModal }