/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useEffect } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage } from '../modules/auth'
import { RootState } from '../../setup'
import { App } from '../App'
import { UserModel } from '../modules/auth/models/UserModel'
import { Emails } from '../pages/email/Emails'
import useUser from '../../hooks/User'
import { Chat } from '../pages/chat/Chat'
import { WebView } from '../pages/WebView'
import { ChatMessageList } from '../../components/Chat/ChatMessage/ChatMessageList'
import { settings } from 'cluster';
import { useSettingsStore } from '../../stores/SettingsStore'
import { OpenAPI } from '../pages/documentation/OpenAPI'
import RedirectBlankPage from '../pages/email/redirect/RedirectBlankPage'
import { HIDE_MENU_CHAT_CUSTOM_COMPANY } from '../../constant/General'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const isAuthorized: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const user = useUser()
  const { settings } = useSettingsStore()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {
            isAuthorized?.success || isAuthorized?.message === 'Token verified successfully' ? (
              <>
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
                <Route path='email' element={<Emails />} />
                <Route path='open-api' element={<OpenAPI />} />
                <Route path='redirect/email' element={<RedirectBlankPage />} />
                {
                  ((!user.data.is_superadmin && !HIDE_MENU_CHAT_CUSTOM_COMPANY.includes(user.data.id)) || (user.data.is_superadmin && settings.feature_chat_super_admin)) &&
                  < Route path='chat' element={<Chat />} />
                }
                {/* <Route path='test' element={<ChatMessageList />} /> */}
              </>
            ) : (
              <>
                <Route path='email' element={<Emails />} />
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
                <Route path='t/:token/:page' element={<WebView />} />
                <Route path='redirect/email' element={<RedirectBlankPage />} />
              </>
            )
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }