import { create } from 'zustand';
import { Pipeline } from '../interfaces/Pipeline';
import { SettingMultiPipelines } from '../interfaces/Settings';
import { createJSONStorage, persist } from 'zustand/middleware';

type RouteState = {
    prevRoute: string
    setPrevRoute: (prevRoute: string) => void;
}

export const useRouteStore = create<RouteState>()(
    persist(
        (set) => ({
            prevRoute: '',

            setPrevRoute: (prevRoute) => {
                set({ prevRoute });
            },
        }),
        {
            name: 'route-sote',
            storage: createJSONStorage(() => localStorage),
        }
    )
)