import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { Meeting, MeetingAttachment, VisitPermision } from "../../interfaces/Meeting";
import { updateVisitOutMeeting } from "../../api/MeetingsCRUD";
import moment from "moment";
import useUser from "../../hooks/User";
import { Buffer } from 'buffer';
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG } from "../../_metronic/helpers";
import { dataURLtoFile, getDateRangeDuration, getRandomFileName, handleCaps } from "../../functions/general";
import { useIntl } from "react-intl";
import { insertMeetingAttachment, insertMeetingFieldAttachment, insertMeetingFieldMultiAttachment } from "../../api/ResourceAPI";
import Select from "react-select";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { CapturePhoto } from "../Attendance/CapturePhoto";
import useCamera from "../../hooks/Camera";
import { CustomField } from "../../interfaces/Settings";
import { useMeetingStore } from "../../stores/MeetingsStore";

interface Props {
    codeUrl: string
    idMeeting: string
    visit: VisitPermision
    setVisit: (data: VisitPermision) => void;
}

const UpdateVisitMeeting: FC<Props> = ({ codeUrl, idMeeting, visit, setVisit }) => {
    const {
        meetings,
        setMeetings,
        meetingLoading,
        setMeetingLoading,
        multiMeetings,
        setMultiMeetings,
        selectMultiMeeting,
        setSelectMultiMeeting,
    } = useMeetingStore();
    const user = useUser()
    const camera = useCamera()
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const intl = useIntl()
    const { settings } = useSettingsStore()
    const [capturePhoto, setCapturePhoto] = useState(null);
    const [isShowCamera, setIsShowCamera] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState<CustomField>({})

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });

        if (item === '4af76875-862a-4533-b980-635f57853799') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        location: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.LOCATION" }) })),
        latitude: Yup.number().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Latitude' })),
        longitude: Yup.number().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Longitude' })),
        attachment: Yup.string().nullable().test('is-required', intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PHOTO" }, { title: settings.meeting_title }) }), function (value) {
            if (!settings.meeting_visit_disable_capture_camera) {
                return !!value;
            }
            return true;
        }), ...formSchema
    });

    const initialValues = {
        location: "",
        latitude: 0,
        longitude: 0,
        attachment: "",
        note: "",
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key,
            visit.customFields && visit.customFields[key] ?
                customFields[key].type === 'date' ?
                    moment(visit.customFields[key], 'YYYY-MM-DD', true).isValid() ?
                        visit.customFields[key]
                        : null
                    : visit.customFields[key]
                : ""]
        )),
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
            const customFieldsValues: Record<string, string | string[] | null | number> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [item]: (values as Record<string, string | string[] | null | number>)[item],
                }),
                {}
            );

            // Use Promise.all to await all async operations
            await Promise.all(
                Object.keys(customFields).map(async (key) => {
                    if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                        try {
                            if (typeof values[key as keyof typeof values] === 'object') {
                                const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as any);
                                customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : "";
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                        try {
                            if (values[key as keyof typeof values]) {
                                var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                const resourceResponse = await insertMeetingFieldMultiAttachment(dataMultiAttachment);
                                (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                })
            );

            try {
                let attachments: MeetingAttachment[] = []
                if (values.attachment) {
                    const resourceResponse = await insertMeetingAttachment(values.attachment)
                    if (resourceResponse.data.data.length > 0) {
                        const parts = resourceResponse.data.data[0].split('/');
                        const fileName = parts[parts.length - 1];
                        attachments = [
                            {
                                name: fileName,
                                url: resourceResponse.data.data[0]
                            }
                        ]
                    }
                }

                const response = await updateVisitOutMeeting({
                    meetingTemplateId: codeUrl,
                    idMeeting: idMeeting,
                    token: token,
                    note: values.note,
                    location: values.location,
                    latitude: values.latitude,
                    longitude: values.longitude,
                    attachmentLink: attachments.length > 0 ? attachments[0].url : null,
                    customFields: customFieldsValues
                })
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "MEETING.ACTION.VISIT_OUT" })}.`,
                        timer: 2000,
                        timerProgressBar: true
                    })

                    setTablesData('meetings', (tablesData.meetings.map(item => {
                        if (item.id === idMeeting) {
                            return {
                                ...item,
                                status: response.data.data.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : response.data.data.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : response.data.data.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
                                attachments: response.data.data.attachments,
                                note: response.data.data.note,
                                date_meet: moment(response.data.data.date_meet).format("YYYY-MM-DD HH:mm:ss"),
                                date_meet_end: response.data.data.allDay ? moment(response.data.data.date_meet_end).subtract(1, 'days').local().format("YYYY-MM-DD HH:mm:ss") : moment(response.data.data.date_meet_end).format("YYYY-MM-DD HH:mm:ss"),
                                logs: response.data.data.logs,
                                duration: getDateRangeDuration(response.data.data.date_meet, response.data.data.date_meet_end),
                                custom_fields: response.data.data.custom_fields
                            }
                        } else {
                            return item
                        }
                    })));

                    closeModalRef.current?.click();
                    setVisit({
                        permission: true,
                        id: '',
                        customFields: {}
                    })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    const handleCoordinate = async () => {
        try {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        formik.setFieldValue("latitude", position.coords.latitude);
                        formik.setFieldValue("longitude", position.coords.longitude);

                        handleReversLatLongToAddress(position.coords.latitude, position.coords.longitude);
                    },
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            var message1 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.TUTORIAL" })
                            alertErrorCoordinate(message1)
                        }
                    }
                );
            } else {
                var message2 = intl.formatMessage({ id: "BROWSER.SUPPORT.GEOLOCATION" })
                alertErrorCoordinate(message2);
            }
        } catch (error) {
            var message3 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.PERMISSION_DENIED" })
            alertErrorCoordinate(message3)
        }
    };

    const handleReversLatLongToAddress = (lat: number, long: number) => {
        fetch(`https://tool.looyal.id/general/gmaps/convert-latlong?lat=${lat}&long=${long}`)
            .then(response => response.json())
            .then(data => {
                formik.setFieldValue("location", data.data);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const alertErrorCoordinate = (message: string) => {
        Swal.fire({
            icon: 'error',
            title: intl.formatMessage({ id: "ALERT.TITLE.ERROR" }),
            html: message,
            confirmButtonText: 'Ok',
            target: document.getElementById(`update-visit-meeting-modal-${idMeeting}`),
            heightAuto: false,
        });
        formik.setFieldValue("location", "");
    }

    const handleTakePhoto = async () => {
        if (capturePhoto) {
            var file = dataURLtoFile(capturePhoto, getRandomFileName() + '.jpeg', Buffer);
            formik.setFieldValue('attachment', file)
        } else {
            formik.setFieldValue('attachment', null)
        }
    };

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.setFieldValue("attachment", "");
            formik.setFieldValue("note", '');
            setIsShowCamera(false)
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        handleCoordinate()
    }, [])

    // useEffect(() => {
    //     if (settings.meeting_custom_fields) {
    //         var data = JSON.parse(settings.meeting_custom_fields)
    //         setCustomFields(data)
    //     }
    // }, [settings])

    useEffect(() => {
        if (Object.keys(selectMultiMeeting).length !== 0) {
            setCustomFields(selectMultiMeeting.customFields)
        }
    }, [selectMultiMeeting])

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target={`#update-visit-meeting-modal-${idMeeting}`}
                onClick={() => setIsShowCamera(true)}
            >
                <i className="fas fa-sign-out-alt"></i> {intl.formatMessage({ id: 'MEETING.ACTION.VISIT_OUT' })}
            </button>

            <div className="modal fade" tabIndex={-1} id={`update-visit-meeting-modal-${idMeeting}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ overflowWrap: "anywhere" }}>{intl.formatMessage({ id: 'MEETING.ACTION.VISIT_OUT' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {/* Coordinat Location */}
                                <span className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LOCATION' })}</span><br />
                                <small>{intl.formatMessage({ id: 'FORM.GUIDE.ACTIVE_GPS' })}</small>
                                <div className="d-flex">
                                    <div className="input-group mb-3">
                                        <input
                                            {...formik.getFieldProps('location')}
                                            className={clsx(
                                                'form-control form-control-lg me-3',
                                                { 'is-invalid': formik.touched.location && formik.errors.location },
                                            )}
                                            type='text'
                                            name='location'
                                            autoComplete='off'
                                            disabled={true}
                                        />
                                    </div>
                                    <button type="button" className="btn btn-secondary mb-3" onClick={handleCoordinate}>
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                                {formik.touched.location && formik.errors.location && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.location}</span>
                                    </div>
                                )}

                                {/* Attachments */}
                                {
                                    !settings.meeting_visit_disable_capture_camera &&
                                    <>
                                        <label className={`form-label fs-6 fw-bolder text-dark required`}>{intl.formatMessage({ id: 'FORM.LABEL.PHOTO' }, { title: settings.meeting_title })}</label>
                                        {
                                            camera.isCamera && isShowCamera ?
                                                <CapturePhoto keyString='visit' img={capturePhoto!} setImg={setCapturePhoto!} func={handleTakePhoto} modalBtnLoading={modalBtnLoading!} />
                                                :
                                                <div className='mb-5 alert alert-danger'>
                                                    <div className='alert-text font-weight-bold'>
                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT1' }, { title: intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) })}<br /><br />
                                                        <b>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT2' }, { title: intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) })}</b><br />
                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT3' })}<br />
                                                        {intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT4' }, { title: intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) })}
                                                    </div>
                                                </div>
                                        }
                                    </>
                                }
                                {formik.touched.attachment && formik.errors.attachment && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                    </div>
                                )}

                                {/* Note */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}</label>
                                <div className="input-group mb-3">
                                    <textarea
                                        placeholder={intl.formatMessage({ id: 'FORM.LABEL.NOTE' })}
                                        id="floatingTextarea"
                                        {...formik.getFieldProps('note')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.note && formik.errors.note },
                                        )}
                                        style={{ zIndex: 0 }}
                                        name="note"
                                        onInput={(e) => handleCaps(e)}
                                    >
                                    </textarea>
                                </div>
                                {formik.touched.note && formik.errors.note && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.note}</span>
                                    </div>
                                )}
                                {
                                    customFields && Object.keys(customFields).map((key, index) => {
                                        if (key === '4af76875-862a-4533-b980-635f57853799') {

                                            const options = customFields[key].value.map((option, index) => ({
                                                value: option,
                                                label: option
                                            }))

                                            return (
                                                <>
                                                    <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                    <div className="input-group mb-3">
                                                        <Select
                                                            isClearable
                                                            options={options}
                                                            placeholder={`Pilih ${customFields[key].name}`}
                                                            value={options.find(option => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                            onChange={
                                                                option => formik.handleChange({
                                                                    target: {
                                                                        name: key,
                                                                        value: option?.value || ""
                                                                    }
                                                                })
                                                            }
                                                            onBlur={
                                                                () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                            }
                                                            className="w-100"
                                                        />
                                                    </div>
                                                    {
                                                        formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                        (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }

                                        return null
                                    })
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div >
            </div >
        </>
    )
}

export { UpdateVisitMeeting }