import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Banner } from '../../interfaces/Banner';
import { toAbsoluteUrl } from '../../_metronic/helpers';
import { CompanyInformations } from '../../interfaces/Company';
import { useIntl } from 'react-intl';

interface CarouselBannerProps {
  dataBanner: CompanyInformations[]
}

const CarouselBanner: React.FC<CarouselBannerProps> = ({ dataBanner }) => {
  const intl = useIntl()

  const settings = {
    dots: true,
    infinite: true,
    // slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 50000,
    focusOnSelect: false,
    cssEase: "linear",
    adaptiveHeight: true,
    swipeToSlide: true,
  };

  const checkNewBanner = (date: string) => {
    const dateCreatedString = date;
    const dateCreated = new Date(dateCreatedString);
    const currentDate = new Date();

    dateCreated.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const timeDifference = currentDate.getTime() - dateCreated.getTime();

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference
  }

  return (
    <div>
      <h1>{intl.formatMessage({ id: "FORM.LABEL.INFORMATION" })}</h1>
      <Slider {...settings}>
        {
          dataBanner.map((banner, index) => {
            return (
              <div onClick={() => banner.banner && window.open(banner.banner, "_blank")} key={index}>
                <div className="card-body ribbon ribbon-top" style={{ padding: "0px" }}>
                  {
                    checkNewBanner(banner.dateCreated) <= 3 &&
                    <div className="ribbon-label bg-primary">
                      {intl.formatMessage({ id: "FORM.LABEL.NEW" })}
                    </div>
                  }
                  {
                    banner.banner &&
                    <img
                      src={banner.banner ? banner.banner : toAbsoluteUrl('/media/assets/no-image.png')}
                      alt={banner.name}
                      // height="400"
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        height: "auto",
                        padding: "10px",
                        borderRadius: "20px",
                        aspectRatio: "16/9",
                        // objectFit: "cover",
                        // objectPosition: "center"
                      }}
                    />
                  }
                  <span className='d-block text-center fs-3' dangerouslySetInnerHTML={{ __html: banner.description }}></span>
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
};

export default CarouselBanner;
