import React, {useState, useRef, useCallback, useEffect, createContext, useContext, useMemo, forwardRef} from 'react'
import {useVirtualizer} from '@tanstack/react-virtual'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

export const VirtualizedSelectContext = createContext<any | undefined>(undefined)

export interface Option {
  id?: string
  label: string
  value: string
  color?: string
  nameCustomField?: string
  nameTemplate?: string
  nameMerchant?: string
  idCustomField?: string
  idTemplate?: string
  idMerchant?: string
  customFieldCompanyCode?: string
  idMerchantLink?: string
}
export interface VirtualizedSelectOption {
  id?: string
  label: string
  value: string
  color?: string
}

export interface VirtualizedSelectOptionsType {
  id?: string
  value?: string
  label: string
  group?: string
  options?: Option[]
  nameCustomField?: string
  nameTemplate?: string
  nameMerchant?: string
  idCustomField?: string
  idTemplate?: string
  idMerchant?: string
  customFieldCompanyCode?: string
  idMerchantLink?: string
}


export interface VirtualizedSelectProps<TOption extends VirtualizedSelectOption = VirtualizedSelectOption> {
  id?: string
  title?: string
  options: VirtualizedSelectOptionsType[]
  value?: VirtualizedSelectOption | VirtualizedSelectOption[] | null
  // onChange: (option: Option | Option[]) => void
  onChange: (option: any) => void
  onInputChange?: (value: string) => void
  onBlur?: () => void
  defaultValue?: VirtualizedSelectOption
  placeholder?: string
  name?: string
  className?: string
  required?: boolean
  openMenuOnClick?: boolean
  closeMenuOnSelect?: boolean
  isMenuOpen?: boolean
  isLoading?: boolean
  isShowGroup?: boolean
  isSelectGroup?: boolean
  isDisabled?: boolean
  isMulti?: boolean
  isClearable?: boolean
  isReselectable?: boolean
  isSearchable? : boolean
  isSort?: boolean
  sortType?: 'ASC' | 'DESC'
  dialogPosition?: 'top' | 'bottom'
  estimateSize?: number
  overscan?: number
  components?: {
    ClearIndicator?: React.ElementType<any>
    Control?: React.ElementType<any>
    DropdownIndicator?: React.ElementType<any>
    DownChevron?: React.ElementType<any>
    CrossIcon?: React.ElementType<any>
    Group?: React.ElementType<any>
    GroupHeading?: React.ElementType<any>
    IndicatorsContainer?: React.ElementType<any>
    IndicatorSeparator?: React.ElementType<any>
    Input?: React.ElementType<any>
    LoadingIndicator?: React.ElementType<any>
    Menu?: React.ElementType<any>
    MenuList?: React.ElementType<any>
    MenuPortal?: React.ElementType<any>
    LoadingMessage?: React.ElementType<any>
    NoOptionsMessage?: React.ElementType<any>
    MultiValue?: React.ElementType<any>
    MultiValueContainer?: React.ElementType<any>
    MultiValueLabel?: React.ElementType<any>
    MultiValueRemove?: React.ElementType<any>
    Option?: React.ComponentType<{ option: VirtualizedSelectOptionsType; onClick?: () => void }>
    Placeholder?: React.ElementType<any>
    SelectContainer?: React.ElementType<any>
    SingleValue?: React.ComponentType<{value: VirtualizedSelectOption}>
    ValueContainer?: React.ElementType<any>
  }
}


const VirtualizedSelect = forwardRef<HTMLDivElement, VirtualizedSelectProps>(function VirtualizedSelect(
  {
    id,
    title = '',
    options,
    value,
    onChange,
    onInputChange,
    onBlur,
    defaultValue,
    placeholder = 'Select an option',
    name,
    className = '',
    required = false,
    openMenuOnClick = true,
    closeMenuOnSelect = true,  // only support group options
    isMenuOpen = false,
    isLoading = false,
    isShowGroup = true, // only support group options
    isSelectGroup = false, // only support group options
    isDisabled = false,
    isMulti = false,
    isClearable = true,
    isReselectable = false, // only support single value not multiple
    isSearchable = true,
    isSort = true,
    sortType = 'ASC',
    dialogPosition = 'bottom',
    estimateSize = 35,
    overscan = 5,
    components,
  },
  ref
) {
  const intl = useIntl()

  const [isOpen, setIsOpen] = useState<boolean|null>(isMenuOpen?isMenuOpen:false)
  const [isGroup, setIsGroup] = useState(false)
  const [groupList, setGroupList] = useState<{name:string,color:string}[]>([])
  const [hover, setHover] = useState<string>()
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [filteredGroup, setFilteredGroup] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<VirtualizedSelectOptionsType[]>([])
  const [selectedOption, setSelectedOption] = useState<VirtualizedSelectOption | VirtualizedSelectOption[] | null>(null)
  const [searchTerm, setSearchTerm] = useState('')

  const containerRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const getOptions = useMemo(() => {
    if (!options) return [];
  
    const filteredOptions = options.flatMap((option) => {
      if (option.options) {
        return option.options
          .filter((opt) => {
            const matchesSearchTerm = opt.label.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFilteredGroup = filteredGroup !== '' ? option.label.toLowerCase().includes(filteredGroup.toLowerCase()) : true;
            return matchesSearchTerm && matchesFilteredGroup;
          })
          .map((opt) => ({ ...opt, group: option.label }));
      }
      
      const matchesSearchTerm = option.label.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFilteredGroup = filteredGroup ? option.label.toLowerCase().includes(filteredGroup.toLowerCase()) : true;
      return matchesSearchTerm && matchesFilteredGroup ? [option] : [];
    });
  
    return isSort && sortType === "ASC" ? filteredOptions.sort((a, b) => a.label.localeCompare(b.label)) : filteredOptions;
  }, [options, searchTerm, filteredGroup, isSort]);

  useEffect(() => {
    groupList.length > 0 ? setIsGroup(true) : setIsGroup(false)
  }, [groupList])

  useEffect(() => {
    if (options) {
      const newGroupList = Array.from(
        new Set(options.flatMap((option) => (option.options ? [option.label] : [])))
      ).filter((label): label is string => label !== undefined)
      setGroupList((prev) => [
        ...prev,
        ...newGroupList.map((label, index) => ({
          name: label,
          color: groupColors[index]?.hex,
        })),
      ])
    } else {

    }
    setFilteredOptions(getOptions)
  }, [options])


  const memorizedValue = useMemo(
    () =>
      Array.isArray(value)
        ? value.filter((val) =>
            options.some((option) =>
              option.options
                ? option.options.some((opt) => opt.value === val.value)
                : option.value === val.value
            )
          )
        : value &&
            options.some((option) =>
            option.options
              ? option.options.some((opt) => opt.value === value.value)
              : option.value === value.value
          )
        ? value
        : defaultValue ? defaultValue : null,
    [value, options, defaultValue]
  )

  useEffect(() => {
    setSelectedOption(memorizedValue)
  }, [memorizedValue])

  useEffect(() => {
    if (searchTerm.length === 0 || searchTerm === '') {
      onInputChange?.(searchTerm)
      if (Array.isArray(selectedOption) && selectedOption.length > 0 && isMulti) {
        setFilteredOptions(
          getOptions.filter((opt) => {
            return !selectedOption.find((so) => {
              return opt.value === so.value && opt.label === so.label
            })
          })
        )
      } else {
        !Array.isArray(selectedOption) ?
          setFilteredOptions(
            getOptions.filter((opt) => {
              return selectedOption?.value !== opt.value && selectedOption?.label !== opt.label
            })
          ) : console.log('no reset', selectedOption)
      }
    } else {
      setFilteredOptions(getOptions)
    }
  }, [searchTerm])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        // console.log('handleOutsideClick', isOpen)
        setIsOpen(false)
        setSearchTerm('')
        setFilteredGroup('')
        if (isOpen) {
          onBlur?.()
        }
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!isOpen || filteredOptions.length === 0) return;

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === null || prev === filteredOptions.length - 1 ? 0 : prev + 1
        );
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex((prev) =>
          prev === null || prev === 0 ? filteredOptions.length - 1 : prev - 1
        );
      }

      if (e.key === 'Enter' && activeIndex !== null) {
        e.preventDefault();
        handleOptionClick(filteredOptions[activeIndex] as VirtualizedSelectOption);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, filteredOptions, activeIndex]);


  const rowVirtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: useCallback(() => estimateSize, []),
    overscan
  })

  useEffect(() => {
    if (activeIndex !== null) {
      rowVirtualizer.scrollToIndex(activeIndex);
    }
  }, [activeIndex, rowVirtualizer]);

  useEffect(() => {
    // console.log('VSELECT isOpen', placeholder, isOpen)
    if (!isOpen) setActiveIndex(null);
  }, [isOpen]);

  useEffect(() => {
    setActiveIndex(null);
  }, [filteredOptions]);


  // const toggleDropdown = () => {
  //   console.log('toggleDropDown', isOpen)
  //   if (isOpen) {
  //     onBlur?.()
  //   }
  //   setIsOpen((prev) => !prev)
  // }

  const handleOptionClick = (option: VirtualizedSelectOption) => {
    if (isMulti) {
      setSelectedOption((prev) => {
        const options = Array.isArray(prev) ? prev : [] // Pastikan prev selalu array
        const exists = options.find((opt) => opt.value === option.value)

        return exists ? options.filter((opt) => opt.value !== option.value) : [...options, option]
      })
      onChange(Array.isArray(selectedOption) ? [...selectedOption, option] : ([option] as VirtualizedSelectOption[]))
    } else {
      // Jika single-select
      setSelectedOption(option as VirtualizedSelectOption)
      onChange(option as VirtualizedSelectOption)
    }

    if (closeMenuOnSelect) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    
    if(!isReselectable) setFilteredOptions(getOptions.filter((opt) => opt.value !== option.value && opt.label !== option.label)) 
    setFilteredGroup('')
    setSearchTerm('')
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  const handleClear = () => {
    setSelectedOption(isMulti ? [] : null)
    setFilteredOptions(getOptions)
    setSearchTerm('')
    setFilteredGroup('')
    onChange(isMulti ? [] : null)
  }

  // useEffect(() => {
  //   if (containerRef.current) {
  //     console.log('containerRef.current.offsetWidth', containerRef.current.offsetWidth)
  //   }
  // }, [containerRef])

  const OptionComponent = components?.Option || DefaultOption
  const SingleValueComponent = components?.SingleValue || DefaultSingleValue

  useEffect(() => {
    // console.log('selectedOption', title, selectedOption)
    if (Array.isArray(selectedOption) && selectedOption.length > 0 && isMulti) {
      // console.log('getOptions', getOptions)
      setFilteredOptions(
        getOptions.filter((opt) => {
          return !selectedOption.find((so) => {
            return opt.value === so.value && opt.label === so.label
          })
        })
      )
    }
    // else {
    //   setFilteredOptions(options)
    // }
  }, [selectedOption])

  // useEffect(() => {
  //   if (isMulti) {
  //     setSelectedOption([])
  //   } else {
  //     console.log('isMulti tidaaak', placeholder, value)
  //     setSelectedOption(null)
  //   }
  // }, [isMulti])

  useEffect(() => {
    // console.log('options',options)
    if(isGroup && groupList.length > 0 && filteredGroup !== '') {
      // console.log('filteredGroup',filteredGroup)
      setFilteredOptions(getOptions.filter((opt) => opt && opt.group === filteredGroup))
    } else if (isGroup && groupList.length > 0 && filteredGroup === ''){
      setFilteredOptions(getOptions)
    }
  }, [groupList, filteredGroup]);

  return (
    <div 
      id={id} 
      ref={ref}
      className={clsx('position-relative', className)} 
    >
      {name && (
        <input
          type="hidden"
          name={name}
          value={
            Array.isArray(value)
              ? value.map((v) => v.value).join(',') // Join multiple values for isMulti
              : value?.value || '' // Single value or empty string
          }
          required={required}
        />
      )}
      <div 
        ref={containerRef} 
      >
        {isOpen ? (
          <>
            <div
              className='d-flex flex-row border border-secondary border-2 rounded justify-content-between'
            >
              <div className='d-flex flex-row flex-wrap w-100 gap-2 p-2 align-items-between justify-content-start'>
                {isMulti && Array.isArray(selectedOption) && selectedOption.length > 0 ? (
                  <>
                    <ShowMultiValue
                      selectedOption={selectedOption as VirtualizedSelectOption[]}
                      handleClose={(v: VirtualizedSelectOption) => {
                        setSelectedOption?.((prev) => {
                          const updatedOptions = Array.isArray(prev) ? prev.filter((opt) => opt.value !== v.value) : [];
                          setFilteredOptions(
                            getOptions.filter(
                              (opt) => !updatedOptions.some((so) => so.value === opt.value)
                            )
                          );
                          onChange(updatedOptions);
                          return updatedOptions; 
                        });
                      }}
                    />
                  </>
                ) : (
                  <div className='d-flex justify-content-between p-2 w-100 text-truncate'>
                    {typeof selectedOption === 'object' ? (
                      <SingleValueComponent
                        value={selectedOption as VirtualizedSelectOption}
                        placeholder={placeholder}
                      />
                    ) : (
                      <span>{placeholder}</span>
                    )}
                  </div>
                )}
                <div className="d-flex  w-100 justify-content-between">
                {groupList.length > 0 && isSelectGroup && isGroup && isShowGroup && (() => {
                    const groupListOptions = groupList.map((group) => ({
                      label: group.name,
                      value: group.name,
                    }));

                    return (
                      <div className='d-flex justify-content-between gap-2 mx-2 rounded' style={{ outline: '2px solid rgb(165, 165, 165)' }}>
                        <select id="groupList" name="groupList" className='border border-0 px-2 bg-transparent' value={filteredGroup} onChange={(e) => setFilteredGroup(e.target.value)} style={{ outline:'none' }}>
                          <option value="" disabled selected>
                            {intl.formatMessage({id:'FORM.LABEL.CHOOSE'}) + ' Option Group'}
                          </option>
                          {groupListOptions.map((option) => (
                            <option key={option.value} value={option.value} className='text-break'>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {filteredGroup !=='' && (
                          <div className='cursor-pointer d-flex align-items-center justify-content-center w-100 h-100 ps-2 pe-3' 
                            onClick={() => {
                              setFilteredGroup('')
                            }}
                            >
                              <i className="bi bi-x-lg " 
                                style={{ fontSize: '1rem', fontWeight: 'bold' }}
                              />
                          </div>
                        )}
                      </div>
                    );
                  })()}

                  {isSearchable?
                  <input
                    type='text'
                    className='border border-0 form-control w-100'
                    placeholder='Search...'
                    value={searchTerm}
                    onChange={handleSearch}
                    autoFocus
                  />
                  :<></>
                  }
                </div>
              </div>

              <div className='p-2 d-flex position-relative align-content-center'>
                {isClearable && selectedOption && (
                  <div className='d-flex align-items-center cursor-pointer px-2 h-100 gap-4'>
                    <i onClick={handleClear} className='bi bi-x-lg align-middle p-2 fw-bold' />
                    <i onClick={()=>{setIsOpen(false)}} className='bi bi-chevron-up fw-bold p-4' />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`d-flex align-items-center justify-content-between gap-1 px-3 py-2 border border-2 rounded bg-white `}
              onClick={isDisabled ? undefined : () => {
                  if(openMenuOnClick) {
                    setIsOpen(true)
                  } else {
                    setIsOpen(false)
                  }
                }
              }
              style={{cursor: isDisabled ? 'not-allowed' : 'pointer', minWidth: '150px', backgroundColor:
                isDisabled ? '#f0f0f0 !important' : 'transparent',
              }}
            >
              {Array.isArray(selectedOption) ? (
                <div className=' d-flex flex-wrap gap-2 align-items-center justify-content-start align-items-stretch '>
                  {selectedOption.length > 0 ? (
                    <div className='d-flex flex-row flex-wrap w-100 gap-2 align-items-between justify-content-start'>
                      <ShowMultiValue
                        selectedOption={selectedOption}
                        handleClose={(v: VirtualizedSelectOption) => {
                          setSelectedOption?.((prev) => {
                            const updatedOptions = Array.isArray(prev) ? prev.filter((opt) => opt.value !== v.value) : [];
                         
                            setFilteredOptions(
                              getOptions.filter(
                                (opt) => !updatedOptions.some((so) => so.value === opt.value)
                              )
                            );
                            onChange(updatedOptions);
                            return updatedOptions;
                          });
                        }}
                        
                      />
                      {/* <span className='pl-3 w-100 text-dark text-truncate py-4'>{placeholder}</span> */}
                    </div>
                  ) : (
                    <span className='pl-3 w-100 text-truncate' style={{color: '#8c8c8b'}}>
                      {placeholder}
                    </span>
                  )}
                </div>
              ) : (
                <>
                  {typeof selectedOption === 'object' ? (
                    <SingleValueComponent
                      value={selectedOption as VirtualizedSelectOption}
                      placeholder={placeholder}
                    />
                  ) : (
                    <span>{placeholder}</span>
                  )}
                </>
                // <span className='pl-3 w-100 bg-warning d-flex justify-content-start align-items-center text-truncate'>
                //   {selectedOption?.label || placeholder}
                // </span>
              )}
              {isLoading ?
                <>
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                </>
              :
                <div className='d-flex align-items-center cursor-pointer px-2 h-100 gap-4'>
                  {isClearable && selectedOption && (
                    <i onClick={handleClear} className='bi bi-x-lg align-middle p-2 fw-bold' />
                  )}
                  <div
                    onClick={isDisabled ? undefined : () => {
                        if(openMenuOnClick) {
                          setIsOpen(true)
                        } else {
                          setIsOpen(false)
                        }
                      }
                    }
                    // className="bg-danger"
                    style={{ position: 'relative' }}
                  >
                  <i
                    className="bi bi-chevron-down fw-bold"
                    style={{
                      pointerEvents: 'none',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              </div>
              }
            </div>
          </>
        )}

        {isOpen && (
          <div
            className={`position-absolute mt-1 bg-white border rounded-1 shadow`}
            style={{
              zIndex: 1000, 
              width: containerRef.current?.offsetWidth
                ? containerRef.current.offsetWidth > 300
                  ? '100%'
                  : '600px'
                : '100%',
              bottom: dialogPosition==='top'?'100px':undefined,
            }}
          >
            <div
              ref={parentRef}
              // className='overflow-auto'
              style={{
                maxHeight: '300px',
                width: containerRef.current?.offsetWidth
                  ? containerRef.current.offsetWidth > 300
                    ? 'auto'
                    : '600px'
                  : 'auto',
                overflowX: containerRef.current?.offsetWidth
                  ? containerRef.current.offsetWidth > 300
                    ? 'hidden'
                    : 'scroll'
                  : 'hidden',
                overflowY: 'auto',
              }}
            >
              {isLoading ?
                <div className='d-flex p-4 gap-2 justify-content-center align-items-end' style={{ height:'35px' }}>
                  <span className='align-text-bottom' style={{ lineHeight:'14px' }}>Loading</span>
                  {[...Array(3)].map((_, i) => (
                    <div key={i} className="spinner-grow spinner-grow-sm" role="status" style={{ height: '4px', width: '4px' }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ))}
                </div>
              :
                <>
              <div
                style={{
                  minHeight: `${rowVirtualizer.getTotalSize()}px`,
                  position: 'relative',
                }}
              >
                    {filteredOptions.length === 0 ? (
                      <div className='d-flex gap-4 align-items-center justify-content-center w-100 h-100 p-8'>
                        <i className='bi bi-emoji-frown' style={{fontSize: '2rem'}} />{' '}
                        <span style={{fontSize: '1.5rem'}}>
                          {intl.formatMessage({id: 'FORM.CONFIRM.DATA_NOT_FOUND'}, {title})}
                        </span>
                      </div>
                    ) : (
                      rowVirtualizer.getVirtualItems().map((virtualItem, index) => {
                        // console.log('rowVirtualizer.getVirtualItems()',rowVirtualizer.getVirtualItems().length)
                        const option = filteredOptions[virtualItem.index]
                        if (!option) return null

                        // if(rowVirtualizer.getVirtualItems().length>9){
                          return (
                            <div
                              key={virtualItem.index}
                              className={`position-absolute 
                              w-100`}
                              style={{
                                top: `${virtualItem.start}px`,
                                height: `${virtualItem.size}px`,
                                width: containerRef.current?.offsetWidth,
                                background: 
                                          activeIndex === virtualItem.index
                                            ? '#d9f7be'
                                            // : hover === virtualItem.index.toString() &&
                                            //   (!Array.isArray(selectedOption) ||
                                            //     !selectedOption.some((opt) => opt.value === option.value))
                                            // ? '#b1d9fa'
                                            : Array.isArray(selectedOption) &&
                                              selectedOption.some((opt) => opt.value === option.value)
                                            ? '#c9c9c9'
                                            : 'transparent',
                              }}
                              onClick={() => {
                                if (Array.isArray(selectedOption)) {
                                  if (!selectedOption.some((opt) => opt.value === option.value)) {
                                    handleOptionClick(option as VirtualizedSelectOption)
                                  }
                                } else {
                                  handleOptionClick(option as VirtualizedSelectOption)
                                }
                              }}
                            >
                              <div
                                onMouseEnter={() => setHover(index.toString())}
                                onMouseLeave={() => setHover('')}
                                className={`d-flex align-items-center w-100 h-100 `}
                                style={{
                                  background:
                                    hover === index.toString() &&
                                    (!Array.isArray(selectedOption) ||
                                      !selectedOption.some((opt) => opt.value === option.value))
                                      ? '#b1d9fa'
                                      : Array.isArray(selectedOption) &&
                                        selectedOption.some((opt) => opt.value === option.value)
                                      ? '#c9c9c9'
                                      : 'transparent',
                                }}
                              >
                                <div
                                  className={`d-flex align-items-center w-100 h-100 ${
                                    isGroup ? 'px-3' : 'p-0'
                                  }`}
                                >
                                  {isShowGroup && isGroup && (
                                    <>
                                      <div
                                        className='rounded-1 text-white p-2 text-nowrap text-truncate w-auto'
                                        style={{
                                          overflowX: 'hidden',
                                          display: 'block',
                                          maxWidth: '300px',
                                          fontSize: '11px',
                                          background: groupList.find((group) => group.name === option.group)?.color,
                                        }}
                                      >
                                        {option.group}
                                      </div>
                                    </>
                                  )}
                                  <OptionComponent
                                    key={option.value}
                                    option={option}
                                    onClick={() => {}}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        // }
                      })
                    )}

              </div>
                  </>
                }
            </div>
          </div>
        )}
      </div>
    </div>
  )
});

export default VirtualizedSelect;

const ShowMultiValue = ({
  selectedOption,
  handleClose,
}: {
  selectedOption: VirtualizedSelectOption[]
  handleClose: (v: VirtualizedSelectOption) => void
}) => {
  return (
    <>
      {Array.isArray(selectedOption) &&
        selectedOption.map((v) => (
          <div
            key={v.value}
            className='d-flex m-0 align-items-center rounded'
            style={{ background: '#e5e5e5', maxWidth: '300px' }}
          >
            <div
              className='border-start border-secondary px-2 text-black'
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                maxWidth: '300px',
                fontSize: '11px',
              }}
            >
              {v.label}
            </div>
            <div
              className='d-flex align-items-center cursor-pointer p-2 h-100'
              onClick={(e) => {
                e.stopPropagation() // Prevent parent onClick event
                handleClose(v)
              }}
            >
              <i className='bi bi-x-lg align-middle' />
            </div>
          </div>
        ))}
    </>
  )
}

const DefaultOption = ({
  children,
  option,
  onClick,
}: {
  children?: React.ReactNode
  option: VirtualizedSelectOptionsType
  onClick: () => void
}) => (
  <div
    onClick={onClick}
    className='px-3 py-2 '
    style={{
      cursor: 'pointer',
      // display: '-webkit-box',
      // WebkitLineClamp: 2,
      // WebkitBoxOrient: 'vertical',
      // overflow: 'hidden',
      // maxWidth: '800px',
    }}
  >
    {children || option.label}
  </div>
)

const DefaultSingleValue = ({value, placeholder}: {value: VirtualizedSelectOption; placeholder: string}) => {
  return (
    <span className='pl-3 w-100 d-flex justify-content-start align-items-center text-truncate'>
      {value?.label || placeholder}
    </span>
  )
}

const groupColors = [
  { hex: "#969696", name: "Primary" },
  { hex: "#1E40AF", name: "Royal Blue" },
  { hex: "#7C3AED", name: "Violet" },
  { hex: "#BE185D", name: "Ruby" },
  { hex: "#059669", name: "Emerald" },
  { hex: "#4338CA", name: "Indigo" },
  { hex: "#9D174D", name: "Crimson" },
  { hex: "#0369A1", name: "Ocean Blue" },
  { hex: "#4C1D95", name: "Deep Purple" },
  { hex: "#B91C1C", name: "Cherry Red" },
  { hex: "#15803D", name: "Forest Green" },
  { hex: "#6D28D9", name: "Amethyst" },
  { hex: "#0E7490", name: "Teal" },
  { hex: "#86198F", name: "Magenta" },
  { hex: "#1F2937", name: "Charcoal" },
  { hex: "#5B21B6", name: "Royal Purple" },
]

export const dummyGroupOptions = Array.from({length: 10000}, (_, i) => ({
  label: `Group ${i + 1}`,
  options: [
    {
      value: `${i + 1}-eed2be4b-5fad-48ee-a484-f6465f9804e7`,
      label: `dummy1 / ${i + 1}`,
    },
    {
      value: `${i + 1}-832499fa-ddba-4243-a3eb-5d3afc55a9c2`,
      label: `dummy2 / ${i + 1}`,
    },
    {
      value: `${i + 1}-d1a4975f-182e-403f-b82a-c2c595628a79`,
      label: `dummy3 / ${i + 1}`,
    },
    {
      value: `${i + 1}-96b78def-99c3-433b-9fc9-435a85da01b5`,
      label: `dummy4 / ${i + 1}`,
    },
    {
      value: `${i + 1}-4f509ec7-995b-40ca-a441-c3d2247e68ca`,
      label: `dummy5 / ${i + 1}`,
    },
  ],
}))

export const dummySingleOptions = Array.from({length: 10000}, (_, i) => ({
  value: `${i + 1}-4f509ec7-995b-40ca-a441-c3d2247e68ca`,
  label: `dummy ${i + 1}`,
}))