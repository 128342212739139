import ExcelJS from 'exceljs';

const styleHeader = (worksheet: ExcelJS.Worksheet, rowIndex: number, color: string = 'F6991D', isSubHeader: boolean = false) => {

    worksheet.getRow(rowIndex).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };

    worksheet.getRow(rowIndex).height = 35;

    worksheet.getRow(rowIndex).eachCell(function (cell) {
        cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: color }
        };
    });
    worksheet.getRow(rowIndex).font = {
        name: 'Arial',
        size: isSubHeader ? 8 : 12,  // Ukuran font lebih kecil untuk subHeader
        bold: !isSubHeader,  // Bold hanya untuk header utama
    };
};

const listShowHeader = ["merchantName", "terminNominal", "leadName", "leadTemplateName", "Nomor Resi", "tanggalJatuhTempo"]

const downloadExcel = async (workbook: ExcelJS.Workbook, nameFile: string) => {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${nameFile}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
};

// Tambahkan mapping untuk header names
const headerMapping: { [key: string]: string } = {
    "merchantName": "Merchant Name",
    "terminNominal": "Nominal Termin",
    "leadName": "Nama Produk",
    "leadTemplateName": "Nama Template",
    "tanggalJatuhTempo": "Tanggal Jatuh Tempo"
};

const generateWorksheetData = (data: any[], listShowHeader: string[]) => {
    // Map headers menggunakan headerMapping
    console.log(data, 'data')
    const headers = listShowHeader.map(header => headerMapping[header] || header);

    // Filter dan map data sesuai header
    const rows = data.map(item => {
        return listShowHeader.map(header => {
            if (header === 'terminNominal') {
                const value = Math.ceil(Number(item[header]) || 0);
                return value;
            }
            return item[header] || '-';
        });
    });

    // Hitung total untuk Nominal Termin
    const totalTermin = data.reduce((sum, item) =>
        sum + Math.ceil(Number(item.terminNominal) || 0), 0
    );

    // Tambahkan row total
    const totalRow = listShowHeader.map(header => {
        if (header === 'merchantName') return 'Total';
        if (header === 'terminNominal') return totalTermin;
        return '';
    });

    return {
        headers,
        rows,
        totalRow
    };
};

export const exportExcelAccountReceivableGeneral = async (
    nameFile: string,
    data: any[],
) => {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Account Receivable');
        console.log(data, 'data')
        const { headers, rows, totalRow } = generateWorksheetData(data, listShowHeader);

        worksheet.addRow(headers);
        styleHeader(worksheet, 1, 'F6991D', false);

        rows.forEach(row => {
            const rowAdded = worksheet.addRow(row);
            const terminCell = rowAdded.getCell(listShowHeader.indexOf('terminNominal') + 1);
            terminCell.numFmt = '#,##0';
        });

        // Tambahkan total row dengan style bold
        const totalRowAdded = worksheet.addRow(totalRow);

        // Style khusus untuk cell Total dan Nominal Total
        totalRowAdded.eachCell((cell, colNumber) => {
            if (cell.value) {
                if (colNumber === listShowHeader.indexOf('merchantName') + 1 ||
                    colNumber === listShowHeader.indexOf('terminNominal') + 1) {
                    cell.font = { bold: true };
                    if (colNumber === listShowHeader.indexOf('terminNominal') + 1) {
                        cell.numFmt = '#,##0';
                    }
                }
            }
        });

        worksheet.columns.forEach((column) => {
            column.width = 30;
        });

        await downloadExcel(workbook, nameFile);

    } catch (error) {
        console.error('Error in exportExcelAccountReceivableGeneral:', error);
        throw error;
    }
};
