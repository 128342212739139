import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationProductProps = ({intl, settings}:IVarDocumentationProps )=>{    
 

    // ===================== GET PRODUCTS =====================
    const getProductsExample =  {
        "success": true,
        "message": "Berhasil mendapatkan Produk.",
        "data": [
          {
            "id": "d9b22bc5-cfef-42da-9819-883e0dcf8455",
            "name": "Jambu air",
            "sku": "jambuari 123",
            "note": "ya kan ya",
            "color": "#ce5c1f",
            "fixedQty": null,
            "productLabels": [
              {
                "label": {
                  "id": "104390e5-275f-4cb2-9e7f-af1b824e5914",
                  "name": "AIR",
                  "color": "#7ed321"
                }
              },
              {
                "label": {
                  "id": "d4e28a1e-4393-4d44-b8db-47ff3a3af9a5",
                  "name": "ANYAR",
                  "color": null
                }
              }
            ],
            "productUnits": [
              {
                "unit": {
                  "id": "5774b293-d731-49ee-ba32-60c8a500842a",
                  "name": "GRAMS",
                  "alias": "GR"
                },
                "convertionToPieces": 2
              },
              {
                "unit": {
                  "id": "18992f19-b703-40fe-ba34-7e4dbf2e4194",
                  "name": "CENTI METER",
                  "alias": "CM"
                },
                "convertionToPieces": 5
              }
            ]
          }
        ]
      };
      

    

    const python_get_products = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/products"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)
    print(response.text)
    `

    const javascript_get_products = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/products";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error(error);
        });

    `

    const php_get_products = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/products";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init();

    curl_setopt($ch, CURLOPT_URL, $reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if (curl_errno($ch)) {
        echo 'Error: ' . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);

    ?>

    `

    const bash_get_products = `

    #!/bin/bash

    REQ_URL="https://crm-api.wooblazz.com/products"
    TOKEN="//${intl.formatMessage({ id: 'OPEN_API.DESC_TOKEN' })}"

    curl -X GET "$REQ_URL" \
    --header "Accept: */*" \
    --header "Content-Type: application/json" \
    --header "X-Auth-token: $TOKEN"

    `

    return   {bash_get_products, python_get_products, php_get_products, javascript_get_products,getProductsExample }
}