import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { TableColumn } from 'react-data-table-component';
import { Pipeline } from '../../interfaces/Pipeline';
import { useSortable } from '@dnd-kit/sortable';

interface Props {
    id: string;
    manageColumns: TableColumn<Pipeline>[];
    index: number;
    setManageColumns: (data: TableColumn<Pipeline>[]) => void;
    setSelectAllColumn: (data: boolean) => void;
}

const InputCheckbox: FC<Props> = React.memo(({ id, manageColumns, index, setManageColumns, setSelectAllColumn }) => {
    const [isHovered, setIsHovered] = useState(false);
    const intl = useIntl();
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
        transition: {
            duration: 300, // Longer duration for a smoother transition
            easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)', // Natural easing
        },
    });

    const style = transform
        ? {
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            boxShadow: isDragging ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
            padding: '5px',
        }
        : {
            padding: '5px'
        };

    const handleClickColumn = () => {
        const newManageColumns = [...manageColumns];
        newManageColumns[index].omit = !newManageColumns[index].omit;
        setManageColumns(newManageColumns);

        if (newManageColumns.filter(column => column.omit !== true).length === newManageColumns.length) {
            setSelectAllColumn(true)
        } else {
            setSelectAllColumn(false)
        }
    }

    return (
        <div
            ref={setNodeRef}
            className="d-flex align-items-center bg-hover-light-primary rounded-2"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={style}
        >
            <div className={`${isHovered ? 'd-block cursor-move' : 'd-none'} me-3 ms-2`} {...attributes} {...listeners}>
                <i className="fas fa-grip-vertical"></i>
            </div>
            <div className="d-flex align-items-center w-100" onClick={handleClickColumn} >
                <div className="form-check form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" checked={!manageColumns[index].omit} />
                </div>
                <span className="form-check-label ms-2 w-100">{manageColumns[index].name}</span>
            </div>
        </div>
    );
});

export { InputCheckbox };
