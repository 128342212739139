import { FC, useRef, useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import AsyncSelect from 'react-select/async';
import clsx from "clsx";
import { ListProduct, Pipeline, PipelineAttachment } from "../../interfaces/Pipeline";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useSWR, { mutate } from "swr";
import axios from "axios";
import useAccessToken from "../../hooks/AccessToken";
import { updateLead } from "../../api/PipelineCRUD";
import { nanoid } from "@reduxjs/toolkit";
import { Product, ProductUnits } from "../../interfaces/Product";
import moment from "moment";
import { insertLeadAttachment, insertLeadFieldMultiAttachment, insertLeadFieldAttachment } from "../../api/ResourceAPI";
import useUser from "../../hooks/User";
import { User } from "../../interfaces/User";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, CustomFieldSerialProps } from "../../interfaces/Settings";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { DatePicker } from "rsuite";
import { addDays, isAfter, isBefore, startOfToday, subDays } from "date-fns";
import { customNumberFormat, customNumberFormatString, getDataById, handleCaps, isValidUrl } from "../../functions/general";
import { useIntl } from "react-intl";
import useTodayRange from "../../hooks/TodayRange";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useCustomeStore } from "../../stores/CustomeStore";
import { tailspin } from 'ldrs'
import { generateSerialApi } from "../../api/Generals";
import { debounce } from 'lodash';
import { VariableSizeList as List } from 'react-window';
import InputPhone from "../Input/InputPhone";
import { usePipelineStore } from "../../stores/PipelineStore";
import { Merchant } from "../../interfaces/Merchant";
import { useMerchantStore } from "../../stores/MerchantStore";
import { useUserStore } from "../../stores/UserStore";
import VirtualizedSelect, { Option } from "../Select/SelectVirtualized";
import { SelectOption } from "../../interfaces/SelectOption";
import { CUSTOM_FIELD_NAME_MERCHANT_MASPION_SQUARE } from "../../constant/General";
import { useChatStore } from "../../stores/ChatStore";
import { defaultScope } from "../DataTable/UserScopeModal";

interface Props {
    pipeline: Pipeline
}

interface SelectOptionProduct extends SelectOption {
    sku?: string;
    note?: string;
    productUnits?: ProductUnits[];
    alias?: string;
    price?: number;
    vatPercentage?: number
}

interface SelectProducUnit extends SelectOption {
    alias?: string;
    note?: string;
}

interface DataProduct {
    productId: string
    productName: string
    productSku: string
    productNote: string
    productUnits: ProductUnits[]
    productPrice: number
    productVatPercentage: number
}

const UpdatePipeline: FC<Props> = ({ pipeline }) => {
    tailspin.register()
    const todayRange = useTodayRange();
    const { phoneNumber } = useChatStore()
    const { settings } = useSettingsStore()
    const { pipelines, pipelineLoading, selectMultiPipeline } = usePipelineStore()
    const { simpleMerchants, simpleMerchantLoading } = useMerchantStore()
    const { users, userLoading } = useUserStore()
    const [customFields, setCustomFields] = useState<CustomField>({})
    const [dataCustomFieldSerial, setDataCustomFieldSerial] = useState<CustomFieldSerialProps>({});
    const user = useUser()
    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const { tablesData, setTablesData, setFetchCounter, fetchCounter } = useDataTableStore()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [productData, setProductData] = useState<SelectOptionProduct[]>([]);
    const [unitData, setUnitData] = useState<SelectProducUnit[]>([]);
    const [vatProduct, setVatProduct] = useState<string>("");
    const [isVatProduct, setIsVatProduct] = useState<boolean>(false);
    const [file, setFile] = useState<string | null>(null);
    const token = useAccessToken()
    const [listProducts, setListProducts] = useState<ListProduct[]>(
        pipeline.leadProducts.map(product => ({
            ...product,
            leadProductTotal: product.leadProductQty * product.leadProductPrice
        }))
    );

    const [dataProduct, setDataProduct] = useState<DataProduct>({
        productId: "",
        productName: "",
        productSku: "",
        productNote: "",
        productUnits: [],
        productPrice: 0,
        productVatPercentage: 0,
    });

    const [isDataProduct, setIsDataProduct] = useState<boolean>(false);
    const [qtyProduct, setQtyProduct] = useState<string>("");
    const [isQtyProduct, setIsQtyProduct] = useState<boolean>(false);
    const [priceProduct, setPriceProduct] = useState<string>("");
    const [isPriceProduct, setIsPriceProduct] = useState<boolean>(false);
    const [totalProduct, setTotalProduct] = useState<string>("");
    const [isTotalProduct, setIsTotalProduct] = useState<boolean>(false);
    const [hppProduct, setHppProduct] = useState<string>("");
    const [unitProduct, setUnitProduct] = useState<SelectProducUnit>({ value: "", label: "", alias: "" });
    const [isUnitProduct, setIsUnitProduct] = useState<boolean>(false);
    const { companyId } = useCustomeStore()
    const [hideFormProductBtn, setHideFormProductBtn] = useState(false);
    const [isLoadingSearchProduct, setIsLoadingSearchProduct] = useState<boolean>(false);
    const [valSearchProduct, setValSearchProduct] = useState<string>("");

    const intl = useIntl();

    const MenuList = (props: any) => {
        const { options, children, maxHeight, getValue } = props;
        const [value] = getValue();
        const listRef = useRef<any>(null);

        const sizeMap = useRef<{ [index: number]: number }>({});

        // Memoized function to measure the height of each item
        const getItemSize = useCallback((index: number) => {
            if (sizeMap.current[index]) {
                return sizeMap.current[index];
            }
            const item = children[index];
            const height = item ? item.props.label.length <= 20 ? 40 : 35 + item.props.label.length : 40; // Example logic for dynamic height
            sizeMap.current[index] = height;
            return height;
        }, [children, valSearchProduct]);

        useEffect(() => {
            if (listRef.current) {
                listRef.current.resetAfterIndex(0, true); // Reset the list after height measurement
            }
        }, [children, getItemSize, valSearchProduct]);

        return (
            <div style={{ width: '100%' }}>
                <List
                    ref={listRef}
                    width="100%"
                    height={maxHeight}
                    itemCount={children.length}
                    itemSize={getItemSize}
                    initialScrollOffset={options.indexOf(value) * getItemSize(options.indexOf(value))}
                >
                    {({ index, style }) => (
                        <div style={{ ...style }}>
                            {children[index]}
                        </div>
                    )}
                </List>
            </div>
        );
    };

    function getFileTypeFromUrl(url: any) {
        if (url && Object.keys(url).length !== 0) {
            var splitUrl = typeof url === 'object' ? url.name.split(".") : url.split(".");
            var fileExtension = splitUrl[splitUrl.length - 1];

            // Menentukan tipe file berdasarkan ekstensi
            switch (fileExtension.toLowerCase()) {
                case "xlsx":
                    return "xlsx";
                case "xlx":
                    return "xlsx";
                case "doc":
                    return "doc";
                case "ppt":
                    return "ppt";
                case "pdf":
                    return "pdf";
                default:
                    return "";
            }
        } else return ""
    }

    const checkIsCompanyElgisa = () => {
        if (user?.data?.company_id === companyId.elgisa) {
            return true
        }
    }

    const checkIsCompanyEsb = () => {
        if (user?.data?.company_id === companyId.esb) {
            return true
        }
    }

    const doubleCheckIsCompanyElgisa = () => {
        if (checkIsCompanyElgisa() && hideFormProductBtn === true) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => { // untuk menghilangkan btn product
        if (checkIsCompanyElgisa()) {
            if (listProducts.length > 0) {
                setHideFormProductBtn(true)
            } else {
                setHideFormProductBtn(false)
            }
        }

        doubleCheckIsCompanyElgisa()
    }, [listProducts, setListProducts])

    const handleVatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        if (!isNaN(Number(value)) && Number(value) > 100) {
            return;
        }
        const formattedValue = customNumberFormatString(value);

        setVatProduct(formattedValue);

    };

    const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value)
        setQtyProduct(formattedValue);
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setPriceProduct(formattedValue);

    };

    const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setTotalProduct(formattedValue);
    };

    const handleHppChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value ?? 0;
        const formattedValue = customNumberFormatString(value);
        setHppProduct(formattedValue);
    };

    useEffect(() => {
        const total = Number(qtyProduct.toString().replaceAll(".", "")) * Number(priceProduct.toString().replaceAll(".", ""));
        const totalAfterVAT = Math.round(total + Math.round((Number(vatProduct) / 100) * total));
        if (isNaN(totalAfterVAT)) {
            setTotalProduct("")
        } else if (totalAfterVAT === 0) {
            setTotalProduct("0")
        }
        else {
            const formattedTotal = customNumberFormat(totalAfterVAT)
            setTotalProduct(formattedTotal)
        }
    }, [qtyProduct, priceProduct, vatProduct])

    useEffect(() => {
        const totalProductNumber = Number(totalProduct.toString().replaceAll(".", ""))
        const qtyProductNumber = Number(qtyProduct.toString().replaceAll(".", ""))
        const vatProductNumber = Number(vatProduct)
        const priceProductNumber = Number(priceProduct.toString().replaceAll(".", ""))

        // formula : totalbeforevat = totalaftervat / (100 + vat) % 
        //                          = 100 * totalaftervat / 100+vat
        // formula : product        = totalbeforevat / qty product 
        const totalBeforeVat = Math.round(100 * totalProductNumber / (100 + vatProductNumber))
        const productAfterTotalChange = totalBeforeVat / qtyProductNumber
        if (isNaN(productAfterTotalChange) || isNaN(priceProductNumber) || isNaN(qtyProductNumber) || qtyProductNumber === 0) {
            if (priceProduct) {
                setPriceProduct(priceProduct)
            } else {
                setPriceProduct("")
            }
        } else {
            const productAfterTotalChangeFormatted = customNumberFormat(productAfterTotalChange)
            setPriceProduct(productAfterTotalChangeFormatted)
        }



    }, [totalProduct])

    function handleAddProduct() {

        if (dataProduct.productId === "") setIsDataProduct(true)
        if (qtyProduct === "") setIsQtyProduct(true)
        if (priceProduct === "") setIsPriceProduct(true)
        // if (vatProduct=== "") setIsVatProduct(true)
        if (totalProduct === "") setIsTotalProduct(true)
        if (dataProduct.productUnits.length > 0 && unitProduct.value === "") setIsUnitProduct(true)

        if (dataProduct.productId && qtyProduct && priceProduct &&
            // vatProduct && 
            totalProduct && ((dataProduct.productUnits.length > 0 && unitProduct.value) || dataProduct.productUnits.length == 0)) {
            setListProducts([
                ...listProducts,
                {
                    productId: dataProduct.productId,
                    leadProductVatPercentage: Number(vatProduct),
                    leadProductName: dataProduct.productName.indexOf(" (SKU:") !== -1 ? dataProduct.productName.substring(0, dataProduct.productName.indexOf(" (SKU:")) : dataProduct.productName,
                    productName: dataProduct.productName.indexOf(" (SKU:") !== -1 ? dataProduct.productName.substring(0, dataProduct.productName.indexOf(" (SKU:")) : dataProduct.productName,
                    leadProductQty: parseInt(qtyProduct.split('.').join('')),
                    leadProductNotes: "",
                    leadProductPrice: parseInt(priceProduct.split('.').join('')),
                    leadProductTotal: parseInt(totalProduct.split('.').join('')),
                    leadProductDiscount: null,
                    edit: false,
                    leadProductHpp: hppProduct ? parseInt(hppProduct.split('.').join('')) : null,
                    productSku: dataProduct.productSku,
                    productNote: dataProduct.productNote,
                    unitId: unitProduct.value && unitProduct.value !== 'pcs-id' ? unitProduct.value : null,
                    unitName: unitProduct.label && unitProduct.value !== 'pcs-id' ? unitProduct.label : null,
                    unitAlias: unitProduct.alias && unitProduct.value !== 'pcs-id' ? unitProduct.alias : null,
                    productUnits: dataProduct.productUnits
                }
            ])

            setDataProduct({ productId: "", productName: "", productSku: "", productNote: "", productUnits: [], productPrice: 0, productVatPercentage: 0 })
            setQtyProduct("")
            setPriceProduct("")
            setTotalProduct("")
            setHppProduct("")
            setVatProduct("")
            setUnitProduct({ value: "", label: "", alias: "" })

        }
    }

    function handleUnitChange(option: SelectProducUnit, index: number) {
        listProducts[index].unitId = option.value;
        listProducts[index].unitName = option.label;
        listProducts[index].unitAlias = option.alias;

        setListProducts([...listProducts])
    }

    function handleInputProduct(type: string, index: number, e: React.ChangeEvent<HTMLInputElement>) {
        var value = e.target.value ?? 0
        value = customNumberFormatString(value)

        if (type === "qty") {
            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)

            const qty = Number(value.replaceAll(".", ""))
            const price = Number(listProducts[index].leadProductPrice)

            const total = Number(qty * price)
            const totalAfterVAT = total + vatPercentage / 100 * total - (listProducts[index].leadProductVatNominal ?? 0)

            listProducts[index].leadProductQty = qty
            listProducts[index].leadProductTotal = Math.round(totalAfterVAT)
        } else if (type === "price") {
            const price = Number(value.replaceAll(".", ""))

            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)
            const qty = Number(listProducts[index].leadProductQty)

            const total = Number(qty * price)
            const totalAfterVAT = Math.round(total + vatPercentage / 100 * total - (listProducts[index].leadProductVatNominal ?? 0))


            listProducts[index].leadProductPrice = price
            listProducts[index].leadProductTotal = Math.round(totalAfterVAT)
        } else if (type === "vatPercentage") {
            const value = e.target.value ?? 0;
            if (!isNaN(Number(value)) && Number(value) > 100) {
                return;
            }
            const formattedValue = customNumberFormatString(value);
            const vatPercentage = Number(formattedValue)

            const price = Number(listProducts[index].leadProductPrice)
            const qty = Number(listProducts[index].leadProductQty)

            const total = Number(qty * price)
            const totalAfterVAT = total + vatPercentage / 100 * total


            listProducts[index].leadProductVatPercentage = vatPercentage
            listProducts[index].leadProductTotal = Math.round(totalAfterVAT - (listProducts[index].leadProductVatNominal ?? 0))
        } else if (type === "hpp") {
            listProducts[index].leadProductHpp = (value ? Number(value.replaceAll(".", "")) : null)
        } else {
            const totalAfterVAT = Number(value.replaceAll(".", ""))
            const qty = Number(listProducts[index].leadProductQty)
            const price = Number(listProducts[index].leadProductPrice)
            const vatPercentage = Number(listProducts[index].leadProductVatPercentage)

            // formula : totalbeforevat = totalaftervat / (100 + vat) % 
            //                          = 100 * totalaftervat / 100+vat
            // formula : product        = totalbeforevat / qty product 

            const totalBeforeVat = 100 * totalAfterVAT / 100 + vatPercentage
            const newProductVAT = totalBeforeVat / qty


            if (totalAfterVAT === 0) {
                listProducts[index].leadProductPrice = 0
            } else {
                listProducts[index].leadProductPrice = Math.round(Number(newProductVAT)) ?? 0

            }

            listProducts[index].leadProductTotal = Math.round(Number(totalAfterVAT))
        }

        setListProducts([...listProducts])
    }

    function handleUpdateProduct(index: number) {
        listProducts[index].edit = true
        setListProducts([...listProducts])
    }

    function handleSaveUpdateProduct(index: number) {
        listProducts[index].edit = false
        setListProducts([...listProducts])
    }

    function handleDeleteProduct(index: number) {
        setListProducts(listProducts.filter((_, i) => i !== index))
    }

    const filterProduct = (inputValue: string) => {
        return productData.filter((i) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const handleSearchProduct = (
        inputValue: string,
        callback: (options: SelectOption[]) => void
    ) => {
        setValSearchProduct(inputValue)
        setTimeout(() => {
            callback(filterProduct(inputValue));
        }, 1000);
    };

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getUserURL = () => {
        if (user.data.permission_pipeline_update === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    // const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)
    const { data: product = [], error: productError, isLoading: productLoading } = useSWR(selectMultiPipeline.id ? `${API_URL}lead-template-product-accesses/${selectMultiPipeline.id}` : null, fetcher)

    const initialValues = {
        startDate: pipeline.date_start,
        pic: pipeline.owner_id,
        labels: pipeline.labels.map((label) => { return label.id }),
        note: pipeline.note,
        invoice: '',
        invoice_name: '',
        invoice_date: pipeline.date_invoice ? moment(pipeline.date_invoice).format('YYYY-MM-DD') : undefined,
        due_date: pipeline.date_invoice_due ? moment(pipeline.date_invoice_due).format('YYYY-MM-DD') : undefined,
        ...Object.fromEntries(Object.keys(customFields).flatMap((key) => {
            if (customFields[key].type === 'invoice_reminder') {
                return [
                    [`${key}_invoice_url`, pipeline.custom_fields[key] && JSON.parse(pipeline.custom_fields[key] as string).invoice_url ? JSON.parse(pipeline.custom_fields[key] as string).invoice_url : null],
                    [`${key}_date_due`, pipeline.custom_fields[key] && JSON.parse(pipeline.custom_fields[key] as string).date_due ? JSON.parse(pipeline.custom_fields[key] as string).date_due : null]
                ];
            } else {
                return [
                    [key,
                        pipeline.custom_fields && pipeline.custom_fields[key] ?
                            customFields[key].type === 'date' ?
                                moment(pipeline.custom_fields[key], 'YYYY-MM-DD', true).isValid() ?
                                    pipeline.custom_fields[key]
                                    : null
                                : pipeline.custom_fields[key]
                            : null]
                ];
            }
        })),
    }

    const formSchema = Object.keys(customFields).reduce((obj: any, item: string) => {
        const field = customFields[item];
        const isRequiredMessage = intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: field.name });
        const isValidUrlMessage = intl.formatMessage({ id: "FORM.VALIDATION.MUST_LINK" });

        if (field.type === 'multiple' || field.type === 'images' || field.type === 'files' || field.type === 'merchant' || field.type === 'user') {
            obj[item] = field.required
                ? Yup.array().min(1, isRequiredMessage).required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else if (field.type === 'date') {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        } else if (field.type === 'link') {
            obj[item] = field.required
                ? Yup.string().nullable().required(isRequiredMessage).test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                })
                : Yup.string().nullable().test('is-required', isValidUrlMessage, function (value) {
                    return !value || isValidUrl(value);
                });
        } else if (field.type === 'serial') {
            obj[item] = Yup.array().notRequired().nullable();
        } else if (field.type === 'custom-serial') {
            obj[item] = field.required
                ? Yup.array().required(isRequiredMessage).nullable()
                : Yup.array().notRequired().nullable();
        } else {
            obj[item] = field.required
                ? Yup.string().required(isRequiredMessage).nullable()
                : Yup.string().notRequired().nullable();
        }

        return obj;
    }, {});

    const validationSchema = Yup.object().shape({
        startDate: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" }) })).nullable(),
        pic: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }) })).nullable(),
        note: user.data.company_id === companyId.elgisa ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: "Opportunity" })).nullable() : Yup.string().notRequired().nullable(),
        labels: Yup.array().notRequired(),
        invoice_name: Yup.string().notRequired().nullable(),
        invoice_date: Yup.string().when('invoice_name', {
            is: (val: string) => val !== undefined,
            then: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.INVOICE" }) })),
            otherwise: Yup.string().notRequired().nullable()
        }),
        due_date: Yup.string().when('invoice_name', {
            is: (val: string) => val !== undefined,
            then: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.INVOICE_DATE" }) })),
            otherwise: Yup.string().notRequired().nullable()
        }),
        ...formSchema
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (listProducts.length === 0) {
                Swal.fire({
                    icon: "error",
                    title: intl.formatMessage({ id: "FORM.VALIDATION.ADD_FIRST" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }) }),
                    heightAuto: false,
                    target: document.getElementById(`update-pipeline-modal-${pipeline.id}`),
                    confirmButtonText: 'Ok'
                })

                return false
            }

            setSubmitting(true)
            setModalBtnLoading(true)

            // Assuming values is of type Record<string, string> and customFields is of type fieldCustom
            const customFieldsValues: Record<string, string | string[] | null> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [item]: (values as Record<string, string | string[] | null>)[item],
                }),
                {}
            );

            const convertCustomFields: Record<string, string> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [`custom_fields.${item}`]: (values as Record<string, string | string[] | null>)[`custom_fields.${item}`] ?? "",
                }),
                {}
            );

            // Use Promise.all to await all async operations
            var countResourceError = 0
            await Promise.all(
                Object.keys(customFields).map(async (key) => {
                    if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                        try {
                            if (values[key as keyof typeof values] && typeof values[key as keyof typeof values] === 'object') {
                                const resourceResponse = await insertLeadFieldAttachment(values[key as keyof typeof values]!! as string | File);
                                if (resourceResponse.data.success) customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                        try {
                            if (values[key as keyof typeof values]) {
                                var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                const resourceResponse = await insertLeadFieldMultiAttachment(dataMultiAttachment);
                                if (resourceResponse.data.success) (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                                else countResourceError++
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'invoice_reminder') {
                        try {
                            if (values[key + "_invoice_url" as keyof typeof values] && typeof values[key + "_invoice_url" as keyof typeof values] === 'object') {
                                const resourceResponse = await insertLeadFieldAttachment(values[key + "_invoice_url" as keyof typeof values]! as string);
                                if (resourceResponse.data.success) customFieldsValues[key] = JSON.stringify({ date_due: values[key + "_date_due" as keyof typeof values], invoice_url: resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : null })
                                else countResourceError++
                            } else {
                                customFieldsValues[key] = JSON.stringify({ date_due: values[key + "_date_due" as keyof typeof values], invoice_url: values[key + "_invoice_url" as keyof typeof values] })
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'multiple') {
                        var multipleValues = values[key as keyof typeof values]?.length! > 0 ? values[key as keyof typeof values] : null;
                        customFieldsValues[key] = multipleValues as string
                    } else if (customFields[key].type === 'serial') {
                        try {
                            if (dataCustomFieldSerial[key].value.length === 0) {
                                const serialResponse = await generateSerialApi(key, "Pipeline", token);
                                customFieldsValues[key] = serialResponse.data.success ? serialResponse.data.data : null;
                            } else {
                                customFieldsValues[key] = dataCustomFieldSerial[key].value
                            }
                        } catch (error) {
                            countResourceError++
                        }
                    } else if (customFields[key].type === 'merchant') {
                        convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, simpleMerchants)?.map((item: any) => item.name).join(", ");
                    } else if (customFields[key].type === 'user') {
                        convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as any, users)?.map((item: any) => item.name).join(", ");
                    } else if (customFields[key].type === 'pipeline') {
                        if (values[key as keyof typeof values]) convertCustomFields[`custom_fields.${key}`] = getDataById(values[key as keyof typeof values] as string, pipelines)?.name + " / Rp." + customNumberFormat(getDataById(values[key as keyof typeof values] as string, pipelines)?.nominal) + " / " + moment(getDataById(values[key as keyof typeof values] as string, pipelines)?.dateCreated).format('DD MMM YYYY');
                        else convertCustomFields[`custom_fields.${key}`] = '-'
                    }
                })
            );





            if (countResourceError > 0) {
                Swal.fire({
                    icon: "error",
                    text: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_FAILED" })}.`,
                    heightAuto: false,
                    target: document.getElementById(`update-pipeline-modal-${pipeline.id}`),
                    confirmButtonText: 'Ok'
                })
                setModalBtnLoading(false);
                setSubmitting(false);
            } else {
                try {
                    const leadLabels = values.labels.length > 0 && Array.isArray(values.labels) ? values.labels.map((label) => { return { id: label, name: labelsOption.filter((option: any) => option.value === label)[0]['label'] } }) : []
                    let attachments: PipelineAttachment[] = []
                    const startDate = moment(values.startDate).toISOString();
                    const response = await updateLead(
                        leadLabels,
                        selectMultiPipeline.id,
                        pipeline.id,
                        startDate,
                        values.pic,
                        values.note,
                        listProducts,
                        token,
                        values.invoice_date,
                        values.due_date,
                        attachments,
                        customFieldsValues
                    )

                    // if (pipeline.paymentTermins[0].nominal !== null) {
                    //     const totalNominalLeadproduct = listProducts.reduce((acc, curr) => acc + curr.leadProductTotal, 0)
                    //     if (totalNominalLeadproduct < pipeline.nominal) {
                    //         Swal.fire({
                    //             icon: 'error',
                    //             title: 'Nominal tidak bisa kurang dari current nominal',
                    //         })
                    //         return;
                    //     }
                    // }
                    if (response.data.success) {
                        Swal.fire({
                            icon: 'success',
                            heightAuto: false,
                            title: `${intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" })}.`,
                            timer: 2000,
                            timerProgressBar: true
                        })
                            .then(() => {
                                setTablesData('pipelines', (tablesData.pipelines.map(item => {
                                    if (item.id === pipeline.id) {
                                        return {
                                            ...item,
                                            ...convertCustomFields,
                                            note: response.data.data.note,
                                            nominal: response.data.data.nominal,
                                            nominal_without_vat: response.data.data.nominal_without_vat,
                                            // handle ketidaksusain round antar BE dan FE saat search Contoh : 3.99 -> 4//
                                            nominalWithoutVatForSearch: response.data.data.nominal_without_vat ? Math.round(response.data.data.nominal_without_vat as number).toString() : null,
                                            nominalForSearch: response.data.data.nominal ? Math.round(response.data.data.nominal as number).toString() : null,

                                            date_invoice: response.data.data.date_invoice,
                                            hot_warm_cold_status: response.data.data.hot_warm_cold_status,
                                            date_invoice_due: response.data.data.date_invoice_due,
                                            attachments: response.data.data.attachments,
                                            custom_fields: response.data.data.custom_fields,
                                            date_start: response.data.data.date_start,
                                            labels: response.data.data.labels,
                                            owner_name: response.data.data.owner_name,
                                            leadProducts: response.data.data.leadProducts?.map((product: ListProduct) => { //nambahin data yg gaada di response
                                                const objProduct = { ...product }
                                                const dataProductById = listProducts.filter((p) => p.productId === product.productId);
                                                const dataProductByUnit = listProducts.filter((p) => product.leadProductUnitId && p.unitId === product.leadProductUnitId);
                                                if (dataProductById.length > 0) {
                                                    objProduct.productSku = dataProductById[0]?.productSku
                                                    objProduct.productNote = dataProductById[0]?.productNote
                                                }
                                                if (dataProductByUnit.length > 0) {
                                                    objProduct.unitId = dataProductByUnit[0]?.unitId
                                                    objProduct.unitName = dataProductByUnit[0]?.unitName
                                                    objProduct.productUnits = dataProductByUnit[0]?.productUnits
                                                }
                                                return objProduct
                                            })
                                        }
                                    } else {
                                        return item
                                    }
                                })));
                                setTablesData('pipelinesAbandoned', (tablesData.pipelinesAbandoned?.map(item => {
                                    if (item.id === pipeline.id) {
                                        return {
                                            ...item,
                                            leadProducts: response.data.data.leadProducts,
                                            note: response.data.data.note,
                                            nominal: response.data.data.nominal,
                                            date_invoice: response.data.data.date_invoice,
                                            hot_warm_cold_status: response.data.data.hot_warm_cold_status,
                                            date_invoice_due: response.data.data.date_invoice_due,
                                            attachments: response.data.data.attachments,
                                            custom_fields: response.data.data.custom_fields,
                                            owner_name: response.data.data.owner_name,
                                        }
                                    } else {
                                        return item
                                    }
                                })));
                            })
                        closeModalRef.current?.click();
                        setFetchCounter(fetchCounter + 1)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        target: document.getElementById(`update-pipeline-modal-${pipeline.id}`),
                        confirmButtonText: 'Ok'
                    })
                } finally {
                    setSubmitting(false)
                    setModalBtnLoading(false)
                }
            }
        }
    });
    const { data: labels = [], error: labelsError, isLoading: labelsLoading } = useSWR(`${API_URL}labels?usableBy[]=pipeline`, fetcher)

    const [labelsOption, setLabelOption] = useState([])

    useEffect(() => {
        const newLabelsOption = labels.map((label: any) => { return { value: label.id, label: label.name } })
        setLabelOption(newLabelsOption)
    }, [labels])
    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    function mapUnitSelect(data: any) {
        const mappedData = data.map((x: ProductUnits) => ({
            value: x.unit.id,
            label: x.unit.name,
            alias: x.unit.alias
        }));
        return mappedData;
    }

    const handleGenerateSerial = async (key: string) => {
        setDataCustomFieldSerial(prevState => ({
            ...prevState,
            [key]: { ...prevState[key], loading: true }
        }));

        try {
            const response = await generateSerialApi(key, "Pipeline", token);
            if (response.data.success) {
                formik.setFieldValue(key, response.data.data)
                setDataCustomFieldSerial(prevState => ({
                    ...prevState,
                    [key]: { ...prevState[key], value: response.data.data }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                target: document.getElementById('update-pipeline-modal' + pipeline.id),
                heightAuto: false,
            })
        } finally {
            setDataCustomFieldSerial(prevState => ({
                ...prevState,
                [key]: { ...prevState[key], loading: false }
            }));
        }
    }

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.Option>
        );
    };

    const CustomSingleValue = (props: any) => {
        return (
            <components.SingleValue {...props}>
                <span><b>{props.data.name}</b> / Rp.{customNumberFormat(props.data.nominal)} / {moment(props.data.dateCreated).format("DD MMM YYYY")}</span>
            </components.SingleValue>
        );
    };

    useEffect(() => {
        setUnitProduct({ value: "", label: "", alias: "" })
        setIsUnitProduct(false)
        if (dataProduct.productUnits.length > 0) {
            // const unitSelectData = dataProduct.productUnits.map((x: ProductUnits) => ({
            //     value: x.unit.id,
            //     label: x.unit.name,
            //     alias: x.unit.alias
            // }));
            const unitSelectData = mapUnitSelect(dataProduct.productUnits)
            setUnitData([...unitSelectData, { value: 'pcs-id', label: 'PCS', alias: 'PCS' }])
        }
    }, [dataProduct])

    useEffect(() => {
        if (!productLoading) {
            const productData = product.map((product: Product) => ({
                value: product.id,
                label: product.name + (product.sku ? (' (SKU: ' + product.sku + ')') : ''),
                sku: product.sku,
                note: product.note,
                productUnits: product.productUnits,
                vatPercentage: product.vatPercentage,
                price: product.price
            }));
            setProductData(productData)
        }
    }, [product])

    useEffect(() => {
        if (!userLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }))

            if (user.data.permission_pipeline_update !== "ALL") {
                usersData.unshift({ value: user.data.id, label: user.data.name })
            }

            setUsersData(usersData)
        }
    }, [users])

    useEffect(() => {
        if (Object.keys(selectMultiPipeline).length !== 0) {
            var data = selectMultiPipeline.customFields
            const transformedData = Object.keys(data).reduce((acc: any, key: string) => {
                acc[key] = {
                    loading: false,
                    value: pipeline.custom_fields[key] ? pipeline.custom_fields[key] : []
                };
                return acc;
            }, {});

            setCustomFields(data)
            setDataCustomFieldSerial(transformedData)
        }
    }, [selectMultiPipeline])


    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);
    const labelsOptionMock = [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
        { label: "Option 3", value: "option3" },
    ];

    return (
        <div className="modal fade" tabIndex={-1} id={`update-pipeline-modal-${pipeline.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" style={{ overflowWrap: "anywhere" }}>{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })} {pipeline.merchant_name}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            onClick={handleCloseModal}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            {/* startDate */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.DATE_STARTED" })}</label>
                            <div className="input-group mb-3">
                                {
                                    settings.pipeline_date_min && settings.pipeline_date_min === "1" ?
                                        <DatePicker
                                            ranges={todayRange}
                                            editable={false}
                                            format="dd MMM yyyy HH:mm"
                                            value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                            defaultValue={null}
                                            style={{ width: "100%", zIndex: 0 }}
                                            container={document.querySelector('#update-pipeline-modal-' + pipeline.id) as HTMLElement}
                                            onChange={(value: any) => {
                                                if (value) formik.setFieldValue('startDate', moment(value).format('YYYY-MM-DD HH:mm'))
                                                else formik.setFieldValue('startDate', null)
                                            }}
                                            shouldDisableDate={date => isBefore(date, subDays(startOfToday(), 1)) || isAfter(date, new Date())}
                                        />
                                        :
                                        <DatePicker
                                            ranges={todayRange}
                                            editable={false}
                                            format="dd MMM yyyy HH:mm"
                                            value={formik.values.startDate ? new Date(formik.values.startDate) : null}
                                            defaultValue={null}
                                            style={{ width: "100%", zIndex: 0 }}
                                            container={document.querySelector('#update-pipeline-modal-' + pipeline.id) as HTMLElement}
                                            onChange={(value: any) => {
                                                if (value) formik.setFieldValue('startDate', moment(value).format('YYYY-MM-DD HH:mm'))
                                                else formik.setFieldValue('startDate', null)
                                            }}
                                        />
                                }
                            </div>
                            {formik.touched.startDate && formik.errors.startDate && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.startDate}</span>
                                </div>
                            )}
                            {
                                (user.data.permission_pipeline_update === 'TEAM' || user.data.permission_pipeline_update === 'ALL') &&
                                // PIC (Sales)
                                <>
                                    <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.LABEL.PIC" })}</label>
                                    <div className="input-group mb-3">
                                        <VirtualizedSelect
                                            key={nanoid()}
                                            options={usersData}
                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PIC" })}`}
                                            value={usersData.find(option => option.value === formik.values.pic) as Option}
                                            onChange={
                                                option => formik.handleChange({
                                                    target: {
                                                        name: "pic",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                            onBlur={
                                                () => formik.values.pic === "" && formik.setFieldTouched("pic", true)
                                            }
                                            className="w-100"
                                            isDisabled={userLoading}
                                        />
                                        {/* <Select
                                            key={nanoid()}
                                            options={usersData}
                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.PIC" })}`}
                                            value={usersData.find(option => option.value === formik.values.pic)}
                                            onChange={
                                                option => formik.handleChange({
                                                    target: {
                                                        name: "pic",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                            onBlur={
                                                () => formik.values.pic === "" && formik.setFieldTouched("pic", true)
                                            }
                                            className="w-100"
                                            isDisabled={userLoading}
                                        /> */}
                                    </div>
                                    {formik.touched.pic && formik.errors.pic && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.pic}</span>
                                        </div>
                                    )}
                                </>
                            }
                            {/* label */}
                            {
                                user.data.company_id !== companyId.esb &&
                                <>
                                    <label className='form-label fs-6 fw-bolder text-dark '>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                    <div className="input-group mb-3">
                                        <Select
                                            closeMenuOnSelect={false}
                                            openMenuOnClick
                                            isMulti
                                            options={labelsOption}
                                            placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                            value={labelsOption.filter((option: any) =>
                                                formik.values.labels.includes(option.value)
                                            )
                                            }
                                            onChange={(selectedOptions) => {
                                                const selectedValues = selectedOptions
                                                    ? selectedOptions.map((option: any) => option.value)
                                                    : []
                                                formik.setFieldValue('labels', selectedValues)
                                            }}
                                            className='w-100'
                                            onBlur={() =>
                                                formik.values.labels.length == 0 &&
                                                formik.setFieldTouched('labels', true)
                                            }
                                        />
                                    </div>
                                    {formik.touched.labels && formik.errors.labels && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                        </div>
                                    )}
                                </>
                            }
                            <div className="separator separator-dashed my-10"></div>

                            {
                                doubleCheckIsCompanyElgisa() ? <></> : (
                                    <div className="row">
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Products */}
                                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</label>
                                            <div className="input-group mb-3">
                                                <VirtualizedSelect
                                                    title={intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    options={productData}
                                                    // options={dummySingleOptions}
                                                    // options={dummyGroupOptions}
                                                    // isDisabled={merchantLoading}
                                                    isClearable={false}
                                                    className="w-100"
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}`}
                                                    value={productData.find(option => option.value === dataProduct.productId) || null}
                                                    onChange={option => {
                                                        if (checkIsCompanyElgisa()) {
                                                            if (option) {
                                                                setQtyProduct('1')
                                                            }
                                                        } else if (checkIsCompanyEsb()) {
                                                            setQtyProduct('1')
                                                            setHppProduct('0')
                                                        }
                                                        setDataProduct(
                                                            {
                                                                productId: option?.value || "",
                                                                productName: option?.label || "",
                                                                productSku: option?.sku || "",
                                                                productNote: option?.note || "",
                                                                productUnits: option?.productUnits || [],
                                                                productPrice: 0,
                                                                productVatPercentage: 0
                                                            }
                                                        )
                                                        if (option?.price) {
                                                            setPriceProduct(customNumberFormat(option?.price))
                                                        } else {
                                                            setPriceProduct("")
                                                        }
                                                        if (option?.vatPercentage) {
                                                            setVatProduct(option?.vatPercentage.toString())
                                                        } else {
                                                            setVatProduct("")
                                                        }
                                                    }}
                                                    onBlur={
                                                        () => dataProduct ? setIsDataProduct(false) : setIsDataProduct(true)
                                                    }
                                                    components={{ MenuList }}
                                                />
                                                {/* <AsyncSelect
                                                    key={
                                                        window.location.pathname === '/chat' ? nanoid() : ''
                                                    }
                                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}`}
                                                    value={productData.find(option => option.value === dataProduct.productId) || null}
                                                    onChange={option => {
                                                        if (checkIsCompanyElgisa() || checkIsCompanyEsb()) {
                                                            if (option) {
                                                                setQtyProduct('1')
                                                                setHppProduct('0')
                                                            }
                                                        }
                                                        setDataProduct(
                                                            {
                                                                productId: option?.value || "",
                                                                productName: option?.label || "",
                                                                productSku: option?.sku || "",
                                                                productPrice: option?.price || 0,
                                                                productVatPercentage: option?.price || 0,
                                                                productNote: option?.note || "",
                                                                productUnits: option?.productUnits || []
                                                            }
                                                        )
                                                        if (option?.price) {
                                                            setPriceProduct(customNumberFormat(option?.price))
                                                        }
                                                        if (option?.vatPercentage) {
                                                            setVatProduct(option?.vatPercentage.toString())
                                                        }
                                                    }}
                                                    onBlur={
                                                        () => dataProduct ? setIsDataProduct(false) : setIsDataProduct(true)
                                                    }
                                                    className="w-100"
                                                    cacheOptions
                                                    loadOptions={handleSearchProduct}
                                                    defaultOptions={productData}
                                                    components={{ MenuList }}
                                                /> */}
                                            </div>
                                            {isDataProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Units */}
                                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.UNITS" })}</label>
                                            <div className="input-group mb-3">
                                                {dataProduct.productUnits.length > 0 ?
                                                    <>
                                                        <Select
                                                            key={
                                                                window.location.pathname === '/chat' ? nanoid() : ''
                                                            }
                                                            options={unitData}
                                                            placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.UNITS" })}`}
                                                            value={unitData.find(option => option.value === unitProduct.value) || null}
                                                            onChange={option => {
                                                                setUnitProduct({ value: option?.value || "", label: option?.label || "", alias: option?.alias || "" })
                                                            }
                                                            }
                                                            onBlur={() => {
                                                                unitProduct.value ? setIsUnitProduct(false) : setIsUnitProduct(true);
                                                            }}
                                                            className="w-100"
                                                        // isDisabled={productLoading}
                                                        />

                                                        {isUnitProduct && (
                                                            <div className='fv-plugins-message-container text-danger'>
                                                                <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.UNITS" }) })}</span>
                                                            </div>
                                                        )}
                                                    </>
                                                    : <span className="mt-3">PCS</span>}
                                            </div>
                                        </div>
                                        {
                                            user.data.is_allowed_access_hpp &&
                                            <div className="col-12 col-lg-1 col-xl-1 pe-0">
                                                {/* Hpp Product */}
                                                <label className='form-label fs-6 fw-bolder text-dark' style={{ float: "right" }}>HPP</label>
                                                <div className="input-group input-group-sm mb-3">
                                                    <input
                                                        disabled={checkIsCompanyEsb()}
                                                        value={hppProduct}
                                                        onChange={handleHppChange}
                                                        // onBlur={() => {
                                                        //     qtyProduct ? setIsQtyProduct(false) : setIsQtyProduct(true);
                                                        //     totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                        // }}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            // { 'is-invalid': isQtyProduct },
                                                        )}
                                                        type='text'
                                                        autoComplete='off'
                                                        style={{ zIndex: 0, textAlign: "right" }}

                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 col-lg-1 col-xl-1 pe-0">
                                            {/* Qty Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    disabled={checkIsCompanyElgisa() || checkIsCompanyEsb()}
                                                    value={qtyProduct}
                                                    onChange={handleQtyChange}
                                                    onBlur={() => {
                                                        qtyProduct ? setIsQtyProduct(false) : setIsQtyProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isQtyProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}

                                                />
                                            </div>
                                            {isQtyProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.QUANTITY" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Price Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={priceProduct}
                                                    onChange={handlePriceChange}
                                                    onBlur={() => {
                                                        priceProduct ? setIsPriceProduct(false) : setIsPriceProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isPriceProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {isPriceProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* VAT Product */}
                                            <label className='form-label fs-6 fw-bolder text-dark ' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.VAT" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={vatProduct}
                                                    onChange={handleVatChange}
                                                    onBlur={() => {
                                                        vatProduct ? setIsVatProduct(false) : setIsVatProduct(true);
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        // { 'is-invalid': isVatProduct },
                                                    )}
                                                    type='text'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {/* {isVatProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.VAT" }) })}</span>
                                                </div>
                                            )} */}
                                        </div>
                                        <div className="col-12 col-lg-2 col-xl-2 pe-0">
                                            {/* Total */}
                                            <label className='form-label fs-6 fw-bolder text-dark required' style={{ float: "right" }}>{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</label>
                                            <div className="input-group input-group-sm mb-3">
                                                <input
                                                    value={totalProduct}
                                                    onChange={handleNominalChange}
                                                    onBlur={() => {
                                                        totalProduct ? setIsTotalProduct(false) : setIsTotalProduct(true);
                                                        priceProduct ? setIsPriceProduct(false) : setIsPriceProduct(true)
                                                    }}
                                                    className={clsx(
                                                        'form-control form-control-lg',
                                                        { 'is-invalid': isTotalProduct },
                                                    )}
                                                    type='text'
                                                    name='nominal'
                                                    autoComplete='off'
                                                    style={{ zIndex: 0, textAlign: "right" }}
                                                />
                                            </div>
                                            {isTotalProduct && (
                                                <div className='fv-plugins-message-container text-danger'>
                                                    <span role='alert' className="text-danger">{intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" }) })}</span>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-3 offset-lg-9 col-xl-3 offset-xl-9">
                                            <label className='form-label fs-6 fw-bolder text-white'>t</label>
                                            <button type="button" className="btn btn-light-primary w-100" onClick={handleAddProduct}>
                                                {intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="table-responsive">
                                <table className="table table-striped gs-7">
                                    <thead>
                                        <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                                            <th>{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</th>
                                            <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.UNITS" })}</th>
                                            {
                                                user.data.is_allowed_access_hpp &&
                                                <th className="text-end">HPP</th>
                                            }
                                            <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}</th>
                                            <th className="text-end">{checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</th>
                                            <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.VAT" })}</th>
                                            <th className="text-end">{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</th>
                                            <th className="text-center"></th>
                                        </tr>
                                    </thead>
                                    {
                                        listProducts.length > 0 ?
                                            <tbody>
                                                {
                                                    listProducts.map((product, index) => (
                                                        <tr style={{ verticalAlign: "middle" }}>
                                                            <td style={{ minWidth: "200px" }}>
                                                                {product.productSku && product.productName.indexOf(" (SKU:") !== -1 ? product.productName.substring(0, product.productName.indexOf(" (SKU:")) : product.productName}
                                                                {product.productSku &&
                                                                    <>
                                                                        <br />
                                                                        <small className="text-muted fst-italic">SKU: {product.productSku}</small>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                {
                                                                    // product.edit ? 
                                                                    //     product.productUnits && product.productUnits.length > 0 ?
                                                                    //         <Select
                                                                    //             key={
                                                                    //                 window.location.pathname === '/chat' ? nanoid() : ''
                                                                    //             }
                                                                    //             options={mapUnitSelect(product.productUnits)}
                                                                    //             placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.UNITS" })}`}
                                                                    //             value={mapUnitSelect(product.productUnits).find((option: any) => option.value === product.unitId) || null}
                                                                    //             onChange={option => handleUnitChange(option, index)}
                                                                    //             className="w-100"
                                                                    //             menuPortalTarget={modalRef.current}
                                                                    //             styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    //         />
                                                                    //         : 
                                                                    //         <span className="mt-3">-</span>
                                                                    //     :
                                                                    product.unitName ? product.unitName : "PCS"
                                                                }
                                                            </td>
                                                            {
                                                                user.data.is_allowed_access_hpp &&
                                                                <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                    {
                                                                        product.edit ?
                                                                            <input disabled={checkIsCompanyEsb()} type="text" className='form-control form-control-sm float-end' value={product.leadProductHpp || product.leadProductHpp == 0 ? customNumberFormat(product.leadProductHpp) : ""} onChange={(e) => handleInputProduct("hpp", index, e)} style={{ textAlign: "right" }} />
                                                                            :
                                                                            product.leadProductHpp || product.leadProductHpp == 0 ? customNumberFormat(product.leadProductHpp) : ""
                                                                    }
                                                                </td>
                                                            }
                                                            <td className="text-end ps-0" style={{ minWidth: "70px" }}>
                                                                {
                                                                    product.edit ?
                                                                        <input disabled={checkIsCompanyElgisa() || checkIsCompanyEsb()} type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductQty)} onChange={(e) => handleInputProduct("qty", index, e)} style={{ textAlign: "right" }} />
                                                                        :
                                                                        customNumberFormat(product.leadProductQty)
                                                                }
                                                            </td>
                                                            <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                {
                                                                    product.edit ?
                                                                        <input type="text" className='form-control form-control-sm float-end' value={customNumberFormat(product.leadProductPrice)} onChange={(e) => handleInputProduct("price", index, e)} style={{ textAlign: "right" }} />
                                                                        :
                                                                        customNumberFormat(product.leadProductPrice)
                                                                }
                                                            </td>
                                                            <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                {
                                                                    product.edit ?
                                                                        <input type="text" className='form-control form-control-sm float-end'
                                                                            value={product.leadProductVatPercentage ? customNumberFormat(product.leadProductVatPercentage) : "0"}
                                                                            onChange={(e) => handleInputProduct("vatPercentage", index, e)}
                                                                            style={{ textAlign: "right" }} />
                                                                        :
                                                                        product.leadProductVatPercentage ? customNumberFormat(product.leadProductVatPercentage) : ""
                                                                }
                                                            </td>
                                                            <td className="text-end ps-0" style={{ minWidth: "100px" }}>
                                                                {
                                                                    product.edit ?
                                                                        <input type="text" className='form-control form-control-sm float-end' value={customNumberFormat(Math.round(product.leadProductTotal + (product.leadProductVatNominal ?? 0)))} onChange={(e) => handleInputProduct("total", index, e)} style={{ textAlign: "right" }} />
                                                                        :
                                                                        customNumberFormat(Math.round(product.leadProductTotal + (product.leadProductVatNominal ?? 0)))
                                                                }
                                                            </td>
                                                            <td className="text-center" style={{ minWidth: "70px" }}>
                                                                {
                                                                    product.edit ?
                                                                        <i className="fas fa-check text-primary cursor-pointer me-3" onClick={() => handleSaveUpdateProduct(index)}></i>
                                                                        :
                                                                        <>
                                                                            <i className="fas fa-edit text-primary cursor-pointer me-3" onClick={() => handleUpdateProduct(index)}></i>
                                                                            <i className="fas fa-trash text-danger cursor-pointer" onClick={() => handleDeleteProduct(index)}></i>
                                                                        </>

                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            :
                                            <tbody>
                                                <tr>
                                                    <td colSpan={7} className="text-center">
                                                        {intl.formatMessage({ id: "FORM.VALIDATION.NO_CHOSEN" }, { title: intl.formatMessage({ id: "FORM.MENU.PRODUCTS" }).toLowerCase() })}
                                                    </td>
                                                </tr>
                                            </tbody>
                                    }
                                </table>
                            </div>
                            <div className="separator separator-dashed my-10"></div>
                            {/* Note */}
                            {
                                user.data.company_id === companyId.elgisa ?
                                    <label className='form-label fs-6 fw-bolder text-dark required'>Opportunity</label>
                                    :
                                    <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.NOTE" })}</label>
                            }
                            <div className="input-group mb-3">
                                <textarea
                                    id="floatingTextarea"
                                    {...formik.getFieldProps('note')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.note && formik.errors.note },
                                    )}
                                    style={{ zIndex: 0 }}
                                    name="note"
                                    onInput={(e) => handleCaps(e)}
                                >
                                </textarea>
                            </div>
                            {formik.touched.note && formik.errors.note && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.note}</span>
                                </div>
                            )}

                            {
                                customFields && Object.keys(customFields).map((key, index) => {
                                    if (customFields[key].type === 'text') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        onInput={(e) => handleCaps(e)}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'serial') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark`}>{customFields[key].name}</label>
                                                {
                                                    dataCustomFieldSerial[key].loading ?
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" style={{ float: "inline-end" }}>
                                                            <l-tailspin
                                                                size="15"
                                                                stroke="3"
                                                                speed="0.9"
                                                                color="#ffffff"
                                                            ></l-tailspin>
                                                        </span>
                                                        :
                                                        <span className="text-white bg-active-success active py-1 px-2 fs-8 border-md rounded" onClick={() => handleGenerateSerial(key)} style={{ float: "inline-end", cursor: "pointer" }}>{intl.formatMessage({ id: 'FORM.ACTION.GENERATE_SERIAL' })}</span>
                                                }
                                                <div className="d-flex mb-3 gap-3">
                                                    <input
                                                        disabled
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx('form-control form-control-lg')}
                                                        value={typeof formik.values[key as keyof typeof formik.values] === 'string' ? formik.values[key as keyof typeof formik.values] : (formik.values[key as keyof typeof formik.values] as any)?.join("")}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'link') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg txt-link',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'phone') {
                                        return (
                                            <div>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <InputPhone keyString={key} formik={formik} type="update" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'image') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'images') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PNG, JPG, JPEG</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="image/png, image/jpeg, image/jpg" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'file') {
                                        var fileType = getFileTypeFromUrl(formik.values[key as keyof typeof formik.values])

                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={false} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'files') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                <InputAttachment keyString={key} formik={formik} isMulti={true} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'date') {
                                        var min: number | null = null
                                        var max: number | null = null

                                        if (customFields[key].min === undefined || customFields[key].min === null) min = null //Tidak ada batasan
                                        else if (customFields[key].min) min = Number(customFields[key].min)
                                        else min = 0 //Batasan hari ini

                                        if (customFields[key].max === undefined || customFields[key].max === null) max = null //Tidak ada batasan
                                        else if (customFields[key].max) max = Number(customFields[key].max)
                                        else max = 0 //Batasan hari ini

                                        return (
                                            <>
                                                {/* Date */}
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        ranges={todayRange}
                                                        oneTap
                                                        editable={false}
                                                        format="dd MMM yyyy"
                                                        value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values]! as string | number | Date) : null}
                                                        defaultValue={null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#update-pipeline-modal-' + pipeline.id) as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                            else formik.setFieldValue(key, null)
                                                        }}
                                                        shouldDisableDate={date => {
                                                            var startDate = min === null ? null : min > 0 ? addDays(new Date(), min) : subDays(new Date(), min * -1);
                                                            var endDate = max === null ? null : max > 0 ? addDays(new Date(), max) : subDays(new Date(), max * -1);

                                                            date.setHours(0, 0, 0, 0);
                                                            if (startDate !== null && endDate !== null) {
                                                                startDate.setHours(0, 0, 0, 0);
                                                                endDate.setHours(0, 0, 0, 0);
                                                                return date > endDate || date < startDate
                                                            } else if (startDate !== null && endDate === null) {
                                                                startDate.setHours(0, 0, 0, 0);
                                                                return date < startDate
                                                            } else if (startDate === null && endDate !== null) {
                                                                endDate.setHours(0, 0, 0, 0);
                                                                return date > endDate
                                                            } else return false
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'option') {

                                        const options = customFields[key].value.map((option, index) => ({
                                            value: option,
                                            label: option
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        // key={nanoid()}
                                                        isClearable
                                                        options={options}
                                                        placeholder={`Pilih ${customFields[key].name}`}
                                                        value={options.find(option => option.value === formik.values[key as keyof typeof formik.values])}
                                                        onChange={
                                                            option => formik.handleChange({
                                                                target: {
                                                                    name: key,
                                                                    value: option?.value
                                                                }
                                                            })
                                                        }
                                                        onBlur={
                                                            () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                        }
                                                        className="w-100"
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'merchant') {
                                        var filteredMerchants = simpleMerchants
                                        if ((customFields[key].value as string[]).length > 0) {
                                            filteredMerchants = simpleMerchants.filter((merchant: Merchant) => (customFields[key].value as string[]).includes(merchant.merchantTemplateId))
                                        }

                                        const options = filteredMerchants.map((option: Merchant) => ({
                                            value: option.id,
                                            label: option.name
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <VirtualizedSelect
                                                        key={
                                                            window.location.pathname === '/chat' ? nanoid() : ''
                                                        }
                                                        options={options}
                                                        // options={dummySingleOptions}
                                                        // options={dummyGroupOptions}
                                                        isLoading={simpleMerchantLoading}
                                                        isDisabled={simpleMerchantLoading}
                                                        className="w-100"
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} Merchant`}
                                                        value={
                                                            options.filter(option =>
                                                                formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                            )
                                                        }
                                                        onChange={(selectedOptions) =>
                                                            formik.setFieldValue(
                                                                key,
                                                                selectedOptions ? selectedOptions.map((option: Option) => option.value) : []
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (formik.values[key as keyof typeof formik.values] === "") {
                                                                formik.setFieldTouched(key, true);
                                                            }
                                                        }}
                                                        components={{ MenuList }}
                                                        isMulti
                                                    />
                                                    {/* <Select 
                                                        isMulti
                                                        isClearable
                                                        options={options}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={
                                                            options.filter(option =>
                                                                formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                            )
                                                        }
                                                        onChange={(selectedOptions) =>
                                                            formik.setFieldValue(
                                                                key,
                                                                selectedOptions ? selectedOptions.map((option) => option.value) : []
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (formik.values[key as keyof typeof formik.values] === "") {
                                                                formik.setFieldTouched(key, true);
                                                            }
                                                        }}
                                                        className="w-100"
                                                        isLoading={simpleMerchantLoading}
                                                        isDisabled={simpleMerchantLoading}
                                                    /> */}
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'user') {
                                        const options = users.map((option: User) => ({
                                            value: option.id,
                                            label: option.name
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        isMulti
                                                        isClearable
                                                        options={options}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={
                                                            options.filter(option =>
                                                                formik.values[key as keyof typeof formik.values]?.includes(option.value)
                                                            )
                                                        }
                                                        onChange={(selectedOptions) =>
                                                            formik.setFieldValue(
                                                                key,
                                                                selectedOptions ? selectedOptions.map((option) => option.value) : []
                                                            )
                                                        }
                                                        onBlur={() => {
                                                            if (formik.values[key as keyof typeof formik.values] === "") {
                                                                formik.setFieldTouched(key, true);
                                                            }
                                                        }}
                                                        className="w-100"
                                                        isLoading={userLoading}
                                                        isDisabled={userLoading}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'pipeline') {

                                        const options = pipelines.map((option: any) => ({
                                            ...option,
                                            value: option.id,
                                            label: option.name
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        isClearable
                                                        options={options}
                                                        placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${customFields[key].name}`}
                                                        value={options.find((option: SelectOption) => option.value === formik.values[key as keyof typeof formik.values]) || null}
                                                        onChange={
                                                            option => formik.handleChange({
                                                                target: {
                                                                    name: key,
                                                                    value: option?.value || ""
                                                                }
                                                            })
                                                        }
                                                        onBlur={
                                                            () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                        }
                                                        components={{
                                                            Option: CustomOption,
                                                            SingleValue: CustomSingleValue,
                                                        }}
                                                        className="w-100"
                                                        isLoading={pipelineLoading}
                                                        isDisabled={pipelineLoading}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'multiple') {
                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <div className="form-check form-check-custom form-check-solid" style={{ display: "contents" }}>
                                                        {
                                                            customFields[key].value.map((option, index) => (
                                                                <div style={{ display: "flex", padding: "3px" }}>
                                                                    <input
                                                                        key={index}
                                                                        {...formik.getFieldProps(key)}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={option as string}
                                                                        name={key}
                                                                        checked={formik.values[key as keyof typeof formik.values]?.includes(option as string)}
                                                                    />
                                                                    <label className="form-check-label me-2">{option}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'invoice_reminder') {
                                        var fileType = getFileTypeFromUrl(formik.values[key + "_invoice_url" as keyof typeof formik.values])

                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name + " (File)"}</span><br />
                                                <small><i>*Format PDF, XLX, XLSX, DOCS, PPT</i></small>
                                                <InputAttachment keyString={key + "_invoice_url"} formik={formik} isMulti={false} type="update" accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation" />

                                                {/* Date Invoice */}
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name + " (Tgl Expired)"}</label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        ranges={todayRange}
                                                        oneTap
                                                        editable={false}
                                                        format="dd MMM yyyy"
                                                        value={formik.values[key + "_date_due" as keyof typeof formik.values] ? new Date(formik.values[key + "_date_due" as keyof typeof formik.values] as string) : null}
                                                        defaultValue={null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#update-pipeline-modal-' + pipeline.id) as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) {
                                                                formik.setFieldValue(`${key}_date_due`, moment(value).format('YYYY-MM-DD'))
                                                            } else {
                                                                formik.setFieldValue(`${key}_date_due`, null)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }

                                    return null
                                })
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export { UpdatePipeline }
