import { FC, ReactNode, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import useUser from "../../../hooks/User";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from "react-intl";
import { DetailLimit } from "../../../interfaces/ReimbursementLimit";
import moment from "moment";
import { customNumberFormat } from "../../../functions/general";
import { DateRangePicker } from 'rsuite';
import usePredefinedRanges from '../../../hooks/PredefinedRanges';
import useCalendarLocale from '../../../hooks/CalendarLocale';
import { endOfMonth, startOfMonth, endOfYear, startOfYear } from 'date-fns';
import { mutate } from 'swr';
import { UpdateReimbursementLimit } from "../../../components/ReimbursementLimit/UpdateReimbursementLimit";
import { DeleteReimbursementLimit } from "../../../components/ReimbursementLimit/DeleteReimbursementLimit";
import { AddReimbursementLimit } from "../../../components/ReimbursementLimit/AddReimbursementLimit";
import { FilterReimbursementLimit } from "../../../components/ReimbursementLimit/FilterReimbursementLimit";

const ReimbursementLimits: FC = () => {

    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const user = useUser();
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const intl = useIntl()
    const [limit, setLimit] = useState<DetailLimit>();

    const predefinedRanges = usePredefinedRanges();
    const calendarLocale = useCalendarLocale();
    const today = new Date()
    const [startDate, setStartDate] = useState<Date>(startOfYear(today));
    const [endDate, setEndDate] = useState<Date>(endOfYear(today));
    const [loading, setLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const API_URL = process.env.REACT_APP_API_URL

    const handleClick = (menuId: string) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<DetailLimit>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_STARTED' }),
            // width: '125px',
            selector: row => row.period.start,
            sortable: true,
            cell: row => moment(row.period.start, 'YYYY-MM-DD').format('DD MMM YYYY')
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_ENDED' }),
            // width: '125px',
            selector: row => row.period.end,
            sortable: true,
            cell: row => moment(row.period.end, 'YYYY-MM-DD').subtract(1, "days").format('DD MMM YYYY')
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NOMINAL' }),
            width: '170px',
            style: {
                justifyContent: 'space-between',
                '&:hover': {
                    cursor: 'pointer'
                }
            },
            selector: row => row.amount,
            sortable: true,
            cell(row) {
                return (
                    <>
                        <span>{row.currency.symbol??''}</span><span>{row.amount && customNumberFormat(row.amount)}</span>
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_CREATED' }),
            selector: row => row.createdTime,
            sortable: true,
            cell: row => moment(row.createdTime).format('DD MMM YYYY'),
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CREATED_BY' }),
            selector: row => row.creator.name?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.creator.name ?? "-"
        }
    ];

    if (user.data.is_superadmin) tableColumns.unshift(
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            center: true,
            width: '70px',
            cell: (row) => {
                return (
                    <>
                        {
                            <>
                                <IconButton
                                    aria-label={`more-${row.id}`}
                                    id={`menu-anchor-${row.id}`}
                                    onClick={() => handleClick(row.id)}
                                >
                                    <MoreVertIcon />
                                </IconButton>

                                <Menu
                                    id={`menu-${row.id}`}
                                    anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                                    open={openMenuId === row.id}
                                    onClose={handleClose}
                                    disableScrollLock={true}
                                >
                                    {
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowUpdateModal(true);
                                            setLimit(row);
                                        }}>
                                            <i className="fas fa-edit fs-4 me-4"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                                        </MenuItem>
                                    }

                                    {
                                        <MenuItem onClick={() => {
                                            handleClose();
                                            setShowDeleteModal(true);
                                            setLimit(row);
                                        }}>
                                            <i className="fas fa-trash fs-3 me-5"></i>
                                            <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                                        </MenuItem>
                                    }
                                </Menu>
                            </>
                        }
                    </>
                )
            }
        },
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME_TITLE' }, { title: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.USER' }) }),
            selector: row => row.userName?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.userName ?? "-"
        }
    )

    let actions: ReactNode[] = []
    if (user.data.is_superadmin) actions.push(<AddReimbursementLimit startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />, <FilterReimbursementLimit />)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 666) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (showUpdateModal && limit) {
            const modalElement = document.getElementById(`update-limit-modal-${limit.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, limit, setShowUpdateModal]);

    useEffect(() => {
        if (showDeleteModal && limit) {
            const modalElement = document.getElementById(`delete-limit-modal-${limit.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, limit, setShowDeleteModal]);

    useEffect(() => {
        if (startDate && endDate) {
            mutate(`${API_URL}reimbursement-limits`);
        }
    }, [startDate, endDate]);

    return (
        <>
            <div className="d-flex justify-content-between mb-5">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'FORM.MENU.LIMITS' })}</li>
                </ol>
                <div>
                    <span className="me-5">{intl.formatMessage({ id: "MASTERS.DATATABLE.TYPE.DATE_STARTED" })}</span>
                    <DateRangePicker
                        cleanable={false}
                        character=" - "
                        format="dd MMM yyyy"
                        locale={calendarLocale}
                        value={[startDate, endDate]}
                        ranges={predefinedRanges}
                        placement="bottomEnd"
                        onChange={(value) => {
                            if (value && value.length === 2) {
                                setStartDate(value[0]);
                                setEndDate(value[1]);
                            }
                        }}
                        showOneCalendar={isMobile}
                        disabled={loading}
                        isoWeek={true}
                    />
                </div>
            </div>
            <MasterDataTable
                tableKey="reimbursement-limits"
                tableColumns={tableColumns}
                actions={actions}
                apiURL="reimbursement-limits"
                dateStart={moment(startDate).format('YYYY-MM-DD')}
                dateEnd={moment(endDate).format('YYYY-MM-DD')}
                setLoading={setLoading}
                orderBy={user.data.is_superadmin ? 3 : 1}
                order="asc"
            />
            {
                showUpdateModal && <UpdateReimbursementLimit limit={limit!} />
            }
            {
                showDeleteModal && <DeleteReimbursementLimit limit={limit!} />
            }

        </>
    )
}

export { ReimbursementLimits }