import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import { insertMerchant } from "../../api/MerchantsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import Select from "react-select";
import useSWR, { mutate } from "swr";
import axios from "axios";
import { City } from "../../interfaces/City";
import { nanoid } from "@reduxjs/toolkit";
import { useSettingsStore } from "../../stores/SettingsStore";
import { CustomField, CustomFieldSerialProps, SettingMultiPipelines } from "../../interfaces/Settings";
import moment from "moment";
import { insertMerchantFieldMultitAttachment, insertMerchantFieldtAttachment } from "../../api/ResourceAPI";
import { DatePicker } from "rsuite";
import { ChatWindowData } from "../../interfaces/Chat/ChatWindowData";
import { Merchant } from "../../interfaces/Merchant";
import useUser from "../../hooks/User";
import { User } from "../../interfaces/User";
import { SelectOption } from "../../interfaces/SelectOption";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { checkWhatsapp } from "../../api/Whatsapp";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, isValidUrl } from "../../functions/general";
import { resolve } from "dns";
import { useIntl } from "react-intl";
import useTodayRange from "../../hooks/TodayRange";
import InputAttachment from "../Input/InputAttachment";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useCustomeStore } from "../../stores/CustomeStore";
import { tailspin } from 'ldrs'
import { generateSerialApi } from "../../api/Generals";
import InputPhone from "../Input/InputPhone";
import { title } from "process";
import { addTemplateSettingPipeline } from "../../api/SettingCRUD";
import { usePipelineStore } from "../../stores/PipelineStore";

const AddTemplateSettingPipeline: FC = () => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const { settings } = useSettingsStore()
    const { multiPipelines, setMultiPipelines } = usePipelineStore()
    tailspin.register()
    const intl = useIntl()

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true);
            setSubmitting(true);

            try {
                const response = await addTemplateSettingPipeline(values.name, {}, token)
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    setMultiPipelines([...multiPipelines, {...response.data.data, label: response.data.data.name, value: response.data.data.id}])
                    closeModalRef.current?.click();
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    heightAuto: false,
                    // target: document.getElementById(`add-merchant-modal`),
                    confirmButtonText: 'Ok'
                });
            } finally {
                setModalBtnLoading(false);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const modalElement = modalRef.current;

        const handleModalHidden = () => {
            formik.resetForm()
        };

        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div className="modal fade" tabIndex={-1} id="add-template-setting-pipeline-modal" ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD_TEMPLATE' }, { title: settings.pipeline_title })}</h5>
                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleCloseModal} >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                            <div className="input-group mb-3">
                                <input
                                    placeholder={intl.formatMessage({ id: 'FORM.LABEL.NAME' })}
                                    {...formik.getFieldProps('name')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.name && formik.errors.name },
                                    )}
                                    type='text'
                                    name='name'
                                    autoComplete='off'
                                    onInput={(e) => handleCaps(e)}
                                />
                            </div>
                            {formik.touched.name && formik.errors.name && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.name}</span>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light d-none"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={handleCloseModal}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export { AddTemplateSettingPipeline }