import { FC } from "react";
import moment from "moment";
import { Log, LogAttachment } from "../../../interfaces/Log";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { getFileType, isFileUrl } from "../../../functions/general";

interface LogAttachmentProps {
    log: Log,
}

const LogAttachments: FC<LogAttachmentProps> = ({ log }) => {
    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text;
        return text.substr(0, maxLength) + '...';
    };

    const renderAttachment = (attachment: LogAttachment, index: number) => {
        return (
            <div key={index} className="d-flex w-3/4 align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                {
                    getFileType(attachment.url) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                        : getFileType(attachment.url) === "doc" || getFileType(attachment.url) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                            : getFileType(attachment.url) === "ppt" || getFileType(attachment.url) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                                : getFileType(attachment.url) === "xlsx" || getFileType(attachment.url) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                                    : isFileUrl(attachment.url) ? <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                                        : <img src={toAbsoluteUrl('/media/logos/image.png')} alt="" style={{ width: "20px" }} />
                }
                <a href={attachment.url} className="m-3" style={{ width: "45%" }} target="_blank" rel="noopener noreferrer">
                    {truncateText(attachment.name, 57)}
                </a>
            </div>
        );
    };

    return (
        <div className="modal fade" tabIndex={-1} id={`attachments-log-modal-${log.id}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {moment(log.date_created).format('DD MMMM YYYY HH:mm')}
                        </h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body align-self-center">
                        {log.attachments && log.attachments.length > 0 ? (
                            log.attachments.map((attachment, index) => renderAttachment(attachment, index))
                        ) : (
                            <p>No attachments available</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            Tutup
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { LogAttachments }