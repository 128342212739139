import { FC, useEffect, useState } from "react";
import { Link, Events, scrollSpy } from 'react-scroll';
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { IDocumentationProps, IMenuDocumentation } from "../../../interfaces/OpenAPI";
import useUser from "../../../hooks/User";
import useAccessToken from "../../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { MenuDocumentation as MenuDocumentationComp } from "../../../components/OpenAPI/Menu";
import { Documentation } from "../../../components/OpenAPI/Documentation";
import { getDocumentationPipelineProps ,getDocumentationAuthProps,getDocumentationCityProps,getDocumentationMerchnatProps,getDocumentationProductProps,getDocumentationTemplatePipelineProps, getDocumentationPipelineSourceProps, getDocumentationLabelProps} from "../../../components/OpenAPI/Constant/index";


const headerInfoStyle = { color: "#235C97", fontWeight: "bold" }

const OpenAPI: FC = () => {
    const user = useUser()
    const token = useAccessToken()
    const intl = useIntl()
    const { settings } = useSettingsStore()

    const [logo, setLogo] = useState("");
    const [lastUpdate, setLastUpdate] = useState(intl.formatMessage({ id: "OPEN_API.VALUE_LAST_UPDATED" }));

    const headDescription = document.querySelector("meta[name='description']");
    const headFavicon = document.querySelector("link[rel='shortcut icon']");

    useEffect(() => {
        document.title = "Wooblazz CRM"
        headDescription?.setAttribute("content", "WooBlazz CRM");
        headFavicon?.setAttribute("href", toAbsoluteUrl("/media/logos/logo-wooblazz.png"));
        setLogo(toAbsoluteUrl("/media/logos/logo-wooblazz-crm-new.png"))

        // Initialize scrollspy
        Events.scrollEvent.register('begin', function (to, element) {
            console.log('begin', arguments);
        });

        Events.scrollEvent.register('end', function (to, element) {
            console.log('end', arguments);
        });

        scrollSpy.update();

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, [])


    const {
        bash_login,
        bash_send_otp,
        javascript_login,
        javascript_send_otp,
        loginBody,
        loginResponse,
        php_login, 
        php_send_otp,
        python_login,
        python_send_otp,
        sendOTPBody,
        sendOTPResponse
    } = getDocumentationAuthProps({intl, settings})

    const {
        bash_get_city,
        bash_get_user,
        getCitiesExample,
        getUsersExample,
        javascript_get_city,
        javascript_get_user,
        php_get_city,
        php_get_user,
        python_get_city,
        python_get_user
    } = getDocumentationCityProps({intl,settings})

    const {
        addMerchantBody,
        addMerchantResponse,
        bash_add_merchant,
        bash_delete_merchant,
        bash_get_merchant,
        bash_get_setting,
        bash_update_merchant,
        deleteMerchantParameters,
        deleteMerchantResponse,
        getMerchantsExample,
        getMerchantsParams,
        getSettingExample,
        javascript_add_merchant,
        javascript_delete_merchant,
        javascript_get_merchant, 
        javascript_get_setting,
        javascript_update_merchant,
        php_add_merchant,
        php_delete_merchant,
        php_get_merchant,
        php_get_setting,
        php_update_merchant,
        python_add_merchant,
        python_delete_merchant,
        python_get_merchant,
        python_get_setting,
        python_update_merchant,
        updateMerchantBody,
        updateMerchantParams,
        updateMerchantResponse
    } = getDocumentationMerchnatProps({intl, settings})

    const {
        getPipelinesParams, 
        bash_get_pipelines, 
        javascript_get_pipelines,
        php_get_pipelines, 
        python_get_pipelines, 
        getPipelinesExample,
        bash_get_pipeline, 
        getPipelineExample, 
        getPipelineParam,
        javascript_get_pipeline,
        php_get_pipeline,
        python_get_pipeline,
        addPipelineBody,
        addPipelineResponse,
        bash_add_pipeline,
        javascript_add_pipeline,
        php_add_pipeline,
        python_add_pipeline
    } = getDocumentationPipelineProps({intl, settings})

    const{
        bash_get_template_pipeline,bash_get_template_pipelines,getTemplatePipelineExample,getTemplatePipelineParam,getTemplatePipelinesExample,getTemplatePipelinesParams,javascript_get_template_pipeline,javascript_get_template_pipelines,php_get__template_pipelines,php_get_template_pipeline,python_get_template_pipeline,python_get_template_pipelines
    } = getDocumentationTemplatePipelineProps({intl, settings})

    const {bash_get_products,javascript_get_products,php_get_products,python_get_products, getProductsExample} = getDocumentationProductProps({intl,settings})

    const {bash_get_labels,getLabelparams,getLabelsExample,javascript_get_labels,php_get_labels,python_get_labels} = getDocumentationLabelProps({intl, settings})

    const {bash_get_pipeline_sources,getPipelineSourcesExample,javascript_get_pipeline_sources,php_get_pipeline_sources,python_get_pipeline_sources} = getDocumentationPipelineSourceProps({intl, settings})

    const MenuDocumentation: IMenuDocumentation[] = [
    {
        name:intl.formatMessage({ id: "OPEN_API.AUTHENTICATION" }),
        subMenus:
        [
            {
                name: intl.formatMessage({ id: "OPEN_API.SEND_OTP_LOGIN" }),
                to : "sendOTP"
            },
            {
                name:  'Login',
                to : "login"
            }
        ]   
    },
    {
        name: intl.formatMessage({ id: "OPEN_API.CITY" }) + intl.formatMessage({ id: "OPEN_API.USER" }),
        subMenus:
        [
            {
                name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) }),
                to : "getCities"
            },
            {
                name:intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) }),  
                to : 'getUsers',
            }
        ]   
    },
    {
        name: settings.merchant_title,
        subMenus:
        [
            {
                name: intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title }),
                to : "getSetting"
            },
            {
                name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title }),
                to : "getMerchants",
            },
            {
                name: intl.formatMessage({ id: "OPEN_API.ADD" }, { title: settings.merchant_title }),
                to : "addMerchant",
            },
            {
                name: intl.formatMessage({ id: "OPEN_API.UPDATE" }, { title: settings.merchant_title }),
                to : "updateMerchant",
            },
            {
                name : intl.formatMessage({ id: "OPEN_API.DELETE" }, { title: settings.merchant_title }),
                to: "deleteMerchant",
            }     
        ]   
    },
    {
        name: intl.formatMessage({ id: "OPEN_API.PIPELINE" }),
        subMenus:[
            {
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({ id: "OPEN_API.PIPELINE" })}),
                to: "getPipelines"
            },
            {
                name:  intl.formatMessage({ id: "OPEN_API.PIPELINE" }),
                to: "getPipeline"
            },
            {
                name: intl.formatMessage({ id: "OPEN_API.ADD" }, {title: intl.formatMessage({ id: "OPEN_API.PIPELINE" })}),
                to : "addPipeline"              
            }
        ]
    },
    {
        name: intl.formatMessage({ id: "OPEN_API.TEMPLATE_PIPELINE" }),
        subMenus:[
            {
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({ id: "OPEN_API.TEMPLATE_PIPELINE" })}),
                to: "getTemplatePipelines"
            },
            {
                name: intl.formatMessage({ id: "OPEN_API.TEMPLATE_PIPELINE" }),
                to: "getTemplatePipeline"
            },
        ]
    },
    {
        name : intl.formatMessage({id : "OPEN_API.PRODUCT"}),
        subMenus:[{
            name: intl.formatMessage({id:"OPEN_API.GET"},{title: intl.formatMessage({id : "OPEN_API.PRODUCT"})}),
            to : "getProducts"
        }]
    },
    {
        name : intl.formatMessage({id : "OPEN_API.PIPELINE_SOURCE"}),
        subMenus:[{
            name: intl.formatMessage({id:"OPEN_API.GET"},{title: intl.formatMessage({id : "OPEN_API.PIPELINE_SOURCE"})}),
            to : "getLeadSources"
        }]
    },
    {
        name : intl.formatMessage({id : "OPEN_API.LABEL"}),
        subMenus:[{
            name: intl.formatMessage({id:"OPEN_API.GET"},{title: intl.formatMessage({id : "OPEN_API.LABEL"})}),  
            to : "getLabels"
        }]
    }

    ]

    const Documentations: IDocumentationProps[] = [
        {
            Description: {
                id: "sendOTP",
                description: intl.formatMessage({ id: "OPEN_API.DESC_SEND_OTP" }),
                endpoint: `https://crm-api.wooblazz.com/user-send-otp`,
                method: "POST",
                name: intl.formatMessage({ id: "OPEN_API.SEND_OTP_LOGIN" }),
                queryParameters: [],
                requestBody: sendOTPBody,
                token: token,
            },
            Code: {
                bash: bash_send_otp,
                javascript: javascript_send_otp,
                php: php_send_otp,
                python: python_send_otp,
                type: "send-otp",
                responseExample: sendOTPResponse,
            }
        },
        {
            Description: {
                id: "login",
                description: intl.formatMessage({ id: "OPEN_API.DESC_LOGIN" }),
                endpoint: `https://crm-api.wooblazz.com/user-login`,
                method: "POST",
                name: "Login",
                queryParameters: [],
                requestBody: loginBody,
                token: token,
            },
            Code: {
                bash: bash_login,
                javascript: javascript_login,
                php: php_login,
                python: python_login,
                type: "login",
                responseExample: loginResponse,
            }
        },
        {
            Description: {
                id: "getCities",
                description: intl.formatMessage({ id: "OPEN_API.DESC_GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) }),
                endpoint: `https://crm-api.wooblazz.com/cities`,
                method: "GET",
                name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) }),
                queryParameters: [],
                requestBody: [],
                token: token,
            },
            Code: {
                bash: bash_get_city,
                javascript: javascript_get_city,
                php: php_get_city,
                python: python_get_city,
                type: "get-cities",
                responseExample: getCitiesExample,
            }
        },
        {
            Description: {
                id: "getUsers",
                description: intl.formatMessage({ id: "OPEN_API.DESC_GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) }),
                endpoint: `https://crm-api.wooblazz.com/users`,
                method: "GET",
                name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) }),
                queryParameters: [],
                requestBody: [],
                token: token,
            },
            Code: {
                bash: bash_get_user,
                javascript: javascript_get_user,
                php: php_get_user,
                python: python_get_user,
                type: "get-users",
                responseExample: getUsersExample,
            }
        },
        {
            Description: {
                id: "getSetting",
                description: intl.formatMessage({ id: "OPEN_API.DESC_GET_CUSTOM_FIELD" }, { title: settings.merchant_title }),
                endpoint: `https://crm-api.wooblazz.com/setting/merchant_custom_fields`,
                method: "GET",
                name: intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title }),
                queryParameters: [],
                requestBody: [],
                token: token,
            },
            Code: {
                bash: bash_get_setting,
                javascript: javascript_get_setting,
                php: php_get_setting,
                python: python_get_setting,
                type: "get-setting",
                responseExample: getSettingExample,
            }
        },
        {
            Description: {
                id: "getMerchants",
                description: intl.formatMessage({ id: "OPEN_API.DESC_GET" }, { title: settings.merchant_title }),
                endpoint: `https://crm-api.wooblazz.com/merchants`,
                method: "GET",
                name: intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title }),
                queryParameters: getMerchantsParams,
                requestBody: [],
                token: token,
            },
            Code: {
                bash: bash_get_merchant,
                javascript: javascript_get_merchant,
                php: php_get_merchant,
                python: python_get_merchant,
                type: "get-merchants",
                responseExample: getMerchantsExample,
            }
        },
        {
            Description: {
                id: "addMerchant",
                description: intl.formatMessage({ id: "OPEN_API.DESC_ADD" }, { title: settings.merchant_title }),
                endpoint: `https://crm-api.wooblazz.com/merchant`,
                method: "POST",
                name: intl.formatMessage({ id: "OPEN_API.ADD" }, { title: settings.merchant_title }),
                queryParameters: [],
                requestBody: addMerchantBody,
                token: token,
            },
            Code: {
                bash: bash_add_merchant,
                javascript: javascript_add_merchant,
                php: php_add_merchant,
                python: python_add_merchant,
                type: "add-merchant",
                responseExample: addMerchantResponse,
            }
        },
        {
            Description: {
                id: "updateMerchant",
                description: intl.formatMessage({ id: "OPEN_API.DESC_UPDATE" }, { title: settings.merchant_title }),
                endpoint: `https://crm-api.wooblazz.com/merchant/<<id>>`,
                method: "PATCH",
                name: intl.formatMessage({ id: "OPEN_API.UPDATE" }, { title: settings.merchant_title }),
                queryParameters: updateMerchantParams,
                requestBody: updateMerchantBody,
                token: token,
            },
            Code: {
                bash: bash_update_merchant,
                javascript: javascript_update_merchant,
                php: php_update_merchant,
                python: python_update_merchant,
                type: "update-merchant",
                responseExample: updateMerchantResponse,
            }
        },
        {
            Description: {
                id: "deleteMerchant",
                description: intl.formatMessage({ id: "OPEN_API.DESC_DELETE" }, { title: settings.merchant_title }),
                endpoint: `https://crm-api.wooblazz.com/merchant/<<id>>`,
                method: "DELETE",
                name: intl.formatMessage({ id: "OPEN_API.DELETE" }, { title: settings.merchant_title }),
                queryParameters: deleteMerchantParameters,
                requestBody: [],
                token: token,
            },
            Code: {
                bash: bash_delete_merchant,
                javascript: javascript_delete_merchant,
                php: php_delete_merchant,
                python: python_delete_merchant,
                type: "delete-merchant",
                responseExample: deleteMerchantResponse,
            }
        },
        {
            Description:{
                id : "getPipelines",
                description : intl.formatMessage({ id: "OPEN_API.DESC_GET"}, {title: intl.formatMessage({id: "OPEN_API.PIPELINE"})}),
                endpoint:"https://crm-api.wooblazz.com/v2/leads",
                method:"GET",
                name: intl.formatMessage({ id: "OPEN_API.GET"}, {title: intl.formatMessage({id: "OPEN_API.PIPELINE"})}),
                queryParameters: getPipelinesParams,
                requestBody: [],
                token: token
            },
            Code:{
                bash: bash_get_pipelines,
                javascript : javascript_get_pipelines,
                php: php_get_pipelines,
                python: python_get_pipelines,
                type: "",
                responseExample: getPipelinesExample
            }
        },
        {
            Description:{ 
                id: "getPipeline",
                description: intl.formatMessage({id:"OPEN_API.DESC_GET_ONE"}, {title : intl.formatMessage({id : "OPEN_API.PIPELINE"})}),
                endpoint:"https://crm-api.wooblazz.com/v2/lead/<<id>>",
                method:"GET",
                name : intl.formatMessage({id:"OPEN_API.GET_ONE"}, {title: intl.formatMessage({id:"OPEN_API.PIPELINE"})}),
                queryParameters:getPipelineParam,
                requestBody:[],
                token: token
            },
            Code : {
                bash: bash_get_pipeline,
                javascript: javascript_get_pipeline,
                php: php_get_pipeline,
                python: python_get_pipeline,
                type:"",
                responseExample:getPipelineExample
            }
            
        },
        {
            Description:{
                id: "addPipeline",
                description : intl.formatMessage({id : "OPEN_API.ADD"}, {title: intl.formatMessage({id : "OPEN_API.PIPELINE"})}),
                endpoint: "https://crm-api.wooblazz.com/v2/lead",
                method: "POST",
                name: intl.formatMessage({id:"OPEN_API.ADD"}, {title : intl.formatMessage({id :"OPEN_API.PIPELINE"})}),
                queryParameters:[],
                requestBody:addPipelineBody,
                token: token,
            },
            Code:{
                bash:bash_add_pipeline,
                javascript: javascript_add_pipeline,
                php: php_add_pipeline,
                python:python_add_pipeline,
                type: "",
                responseExample:addPipelineResponse
            }
        },
        {
            Description: {
                id : "getTemplatePipelines",
                description: intl.formatMessage({id : "OPEN_API.DESC_GET"}, {title : intl.formatMessage({id:"OPEN_API.TEMPLATE_PIPELINE"})}),
                endpoint:"https://crm-api.wooblazz.com/lead-templates",
                method:"GET",
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title:intl.formatMessage({id:"OPEN_API.TEMPLATE_PIPELINE"})}),
                queryParameters:getTemplatePipelinesParams,
                requestBody:[],
                token: token
            },
            Code:{
                bash:bash_get_template_pipelines,
                javascript: javascript_get_template_pipelines,
                php: php_get__template_pipelines,
                python: python_get_template_pipelines,
                type:'get-templates',
                responseExample:getTemplatePipelinesExample,
            }
        },
        {
            Description: {
                description: intl.formatMessage({id : "OPEN_API.DESC_GET_ONE"}, {title : intl.formatMessage({id:"OPEN_API.TEMPLATE_PIPELINE"})}),
                id: "getTemplatePipeline",
                endpoint:"https://crm-api.wooblazz.com/lead-templates/<<id>>",
                method:"GET",
                name:intl.formatMessage({id : "OPEN_API.GET_ONE"}, {title:intl.formatMessage({id:"OPEN_API.TEMPLATE_PIPELINE"})}),
                queryParameters:getTemplatePipelineParam,
                requestBody:[],
                token:token
            },
            Code:{
                bash: bash_get_template_pipeline,
                javascript: javascript_get_template_pipeline,
                python: python_get_template_pipeline,
                php: php_get_template_pipeline,
                type:"get-template",
                responseExample: getTemplatePipelineExample
            }
        },
        {
            Description:{
                id: "getProducts",
                description: intl.formatMessage({id : "OPEN_API.DESC_GET"}, {title: intl.formatMessage({id : "OPEN_API.PRODUCT"})}),
                endpoint:"https://crm-api.wooblazz.com/products",
                method:"GET",
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id : "OPEN_API.PRODUCT"})}),
                queryParameters:[],
                requestBody: [],
                token: token
            },
            Code: {
                bash: bash_get_products,
                javascript: javascript_get_products,
                php: php_get_products,
                python: python_get_products,
                type:"",
                responseExample:getProductsExample
            }
        },
        {
            Description:{
                id: "getLeadSources",
                description: intl.formatMessage({id : "OPEN_API.DESC_GET"}, {title: intl.formatMessage({id : "OPEN_API.PIPELINE_SOURCE"})}),
                endpoint:"https://crm-api.wooblazz.com/lead_sources",
                method:'GET',
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id : "OPEN_API.PIPELINE_SOURCE"})}),
                queryParameters:[],
                requestBody:[],
                token:token
            },
            Code:{
                bash: bash_get_pipeline_sources,
                javascript: javascript_get_pipeline_sources,
                python: python_get_pipeline_sources,
                php: php_get_pipeline_sources,
                type:'',
                responseExample: getPipelineSourcesExample,
            }
        },
        {
            Code:{
                bash: bash_get_labels,
                javascript : javascript_get_labels,
                php: php_get_labels,
                python:python_get_labels,
                type:"",
                responseExample: getLabelsExample
            },
            Description:{
                id: "getLabels",
                description: intl.formatMessage({id : "OPEN_API.DESC_GET"}, {title: intl.formatMessage({id : "OPEN_API.LABEL"})}),
                endpoint:"https://crm-api.wooblazz.com/labels",
                method:'GET',
                name: intl.formatMessage({id : "OPEN_API.GET"}, {title: intl.formatMessage({id : "OPEN_API.LABEL"})}),
                queryParameters:getLabelparams,
                requestBody:[],
                token:token
            }
        }
    ];
    

    return (
        <div style={{ overflowX: "hidden" }}>
            <div className="row" style={{ minHeight: "100vh", color: "black" }}>
                <div className="col-12 col-lg-0" id="navbar-open-api" style={{ backgroundColor: "whitesmoke" }}>
                    <nav className="navbar navbar-expand-lg bg-body-tertiary" style={{ position: "fixed", zIndex: "9999", backgroundColor: 'white' }}>
                        <div className="container-fluid">
                            <img src={logo} className="img-fluid" alt="logo" style={{ width: "15%", margin: "10px 0px" }} />
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
                                <i className="fas fa-solid fa-bars fs-1"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul style={{ listStyleType: "none" }} className="px-2 mt-10 fs-2">
                                    <div style={{ color: "black", fontWeight: "300", fontSize: "x-small", textTransform: "uppercase" }}>{intl.formatMessage({ id: "OPEN_API.AUTHENTICATION" })}</div>
                                    <div className="separator separator-dashed mt-2"></div>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="sendOTP" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.SEND_OTP_LOGIN" })}
                                        </Link>
                                    </li>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="login" spy smooth={true} duration={500}>
                                            Login
                                        </Link>
                                    </li>
                                    <div className="mt-7" style={{ color: "black", fontWeight: "300", fontSize: "x-small", textTransform: "uppercase" }}>{intl.formatMessage({ id: "OPEN_API.CITY" })} & {intl.formatMessage({ id: "OPEN_API.USER" })}</div>
                                    <div className="separator separator-dashed mt-2"></div>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="getCities" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.CITY" }) })}
                                        </Link>
                                    </li>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="getUsers" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.GET" }, { title: intl.formatMessage({ id: "FORM.LABEL.USER" }) })}
                                        </Link>
                                    </li>
                                    <div className="mt-7" style={{ color: "black", fontWeight: "300", fontSize: "x-small", textTransform: "uppercase" }}>{settings.merchant_title}</div>
                                    <div className="separator separator-dashed mt-2"></div>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="getSetting" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.CUSTOM_FIELD" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="getMerchants" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.GET" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li>
                                    {/* <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="getMerchantsByPhone" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.GET_PHONE" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li> */}
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="addMerchant" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.ADD" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="updateMerchant" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.UPDATE" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li>
                                    <li className="text-hover-primary pt-1 fw-bold" style={{ cursor: "pointer" }}>
                                        <Link to="deleteMerchant" spy smooth={true} duration={500}>
                                            {intl.formatMessage({ id: "OPEN_API.DELETE" }, { title: settings.merchant_title })}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className="px-7 pb-3" style={{ backgroundColor: 'white', paddingTop: "5rem" }}>
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.VERSION" })}:&nbsp;
                            </span>
                            1.1.0
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.LAST_UPDATED" })}:&nbsp;
                            </span>
                            {lastUpdate}
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.USER" })}:&nbsp;
                            </span>
                            {user.data.name}
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                WhatsApp:&nbsp;
                            </span>
                            {user.data.phone}
                        </span><br />
                    </div>
                </div>
                <div className="col-12 col-lg-3 p-10 pt-0" id="sidebar-open-api" style={{
                    backgroundColor: "#F4F5F8",
                    position: "fixed",
                    height: "100%",
                    overflowY: "auto",
                    display: "none", 
                }}
                >
                    <img src={logo} className="img-fluid w-50" alt="logo" style={{ margin: "20px 0px" }} />
                    <h5>{intl.formatMessage({ id: "OPEN_API.TITLE" })}</h5>

                    <div className="mt-10">
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.VERSION" })}:&nbsp;
                            </span>
                            1.1.0
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.LAST_UPDATED" })}:&nbsp;
                            </span>
                            {lastUpdate}
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                {intl.formatMessage({ id: "OPEN_API.USER" })}:&nbsp;
                            </span>
                            {user.data.name}
                        </span><br />
                        <span>
                            <span style={headerInfoStyle}>
                                WhatsApp:&nbsp;
                            </span>
                            {user.data.phone}
                        </span><br />
                    </div>

                    <ul style={{ listStyleType: "none" }} className="p-0 mt-10 fs-2">
                        {MenuDocumentation.map((menu, index)=>{
                            return <MenuDocumentationComp key={index} name={menu.name} subMenus={menu.subMenus} />
                        })}
                    </ul>
                </div>
                <div className="col-12 col-lg-9" id="documentation" style={{ marginLeft: "auto" }}>
                    {Documentations.map((documentation, index)=>{
                        return <Documentation key={index} Code={documentation.Code} Description={documentation.Description}/>
                    })}
                </div>
            </div>
        </div>
    )
}

export { OpenAPI }