import { useIntl } from "react-intl";

const useProgressPhrase = (progressBar: number) => {
    const intl = useIntl();

    if (progressBar <= 25) {
        return intl.formatMessage({ id: "FORM.LABEL.TARGET_STILL_FAR_AWAY" });
    } else if (progressBar <= 50) {
        return intl.formatMessage({ id: "FORM.LABEL.ALREADY_HALFWAY" })
    } else if (progressBar <= 75 || progressBar <= 99) {
        return intl.formatMessage({ id: "FORM.LABEL.ALMOST_REACHING_TARGET" });
    } else if (progressBar >= 100) {
        return intl.formatMessage({ id: "FORM.LABEL.TARGET_ACHIEVED" });
    }

}

export default useProgressPhrase;