import { FC, useRef, useState } from "react";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import useAccessToken from "../../../hooks/AccessToken";
import { useChatStore } from "../../../stores/ChatStore";
import { assignContactChatLabel, deleteChatLabel } from "../../../api/ChatLabelsCRUD";
import { KTSVG } from "../../../_metronic/helpers";

interface Props {
    dataSelect: {
        [phone: string]: boolean;
    }
    setSelected: (value: any) => void
}

const RemoveContactLabel: FC<Props> = ({ dataSelect, setSelected }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const token = useAccessToken()
    const intl = useIntl()
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const { selectChatLabel, setChatLabels, setSelectChatLabel } = useChatStore()

    const handleRemove = async () => {
        const dataRemove = selectChatLabel.roomList.filter((phone) => {
            return !dataSelect[phone];
        });
        
        const result = {
            [selectChatLabel.id]: dataRemove
        };
        
        setModalBtnLoading(true)
        try {
            const response = await assignContactChatLabel(result, token)
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    heightAuto: false,
                    title: `${intl.formatMessage({ id: "FORM.VALIDATION.DELETE_SUCCESS" })}`,
                    timer: 2000,
                    timerProgressBar: true
                })
                closeModalRef.current?.click();
                setSelected({})
                setSelectChatLabel({ ...selectChatLabel, roomList: dataRemove })
                setChatLabels(response.data.data)
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Gagal',
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        } finally {
            setModalBtnLoading(false)
        }
    }

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`remove-contact-label-modal`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.DELETE" })} Data</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <p>
                                {intl.formatMessage({ id: "FORM.VALIDATION.DESC_REMOVE_LABEL" }, {title: selectChatLabel.name, title2: Object.keys(dataSelect).length})}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                            </button>
                            <button type="button" className={`btn btn-danger me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'} onClick={handleRemove}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.DELETE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { RemoveContactLabel }