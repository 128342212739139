import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import { insertLabel } from "../../api/LabelsCRUD";
import useAccessToken from "../../hooks/AccessToken";
import { useDataTableStore } from "../../stores/DataTableStore";
import { mutate } from "swr";
import { SelectOption } from "../../interfaces/SelectOption";
import { handleCaps, randomColor } from "../../functions/general";
import { useIntl } from "react-intl";
import { SketchPicker } from "react-color";
import { insertUnit } from "../../api/UnitsCRUD";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

interface UnitSelect {
    id: string
    name: string
    qty: number
}

interface AddLabelProps {
    selectedUnits?: UnitSelect[]
    setSelectedUnits?: (value: any) => void
}

const AddUnit: FC<AddLabelProps> = ({ selectedUnits, setSelectedUnits }) => {

    const BASE_URL = process.env.REACT_APP_API_URL

    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const [color, setColor] = useState('')
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const [showModal, setShowModal] = useState(false)
    const intl = useIntl()

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            alias: '',
            color: color,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            alias: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: 'Alias' })),
            color: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.COLOR" }) })),
        }),
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)
            try {
                const response = await insertUnit(values.name, values.alias, values.color, token)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" })}`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    if (selectedUnits && setSelectedUnits) {
                        setSelectedUnits([...selectedUnits, {
                            name: response.data.data.name,
                            id: response.data.data.id,
                            qty: 0
                        }])
                        // mutate(BASE_URL + 'labels')
                    }
                    let slicedData = [...tablesData.units].slice(0, -1) // remove PCS (default) data
                    if (tablesData.units) setTablesData('units', ([...slicedData, response.data.data, {
                        name: "PIECES",
                        alias: "PCS"
                    }]))
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                if (window.location.pathname === '/products') mutate(BASE_URL + 'units')
                setSubmitting(false)
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            setShowModal(false)
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        setColor(randomColor())
    }, [showModal, setShowModal])
    
    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            {
                window.location.pathname !== '/products' &&
                <button type="button"
                    className="btn btn-primary mt-3"
                    data-bs-toggle="modal"
                    data-bs-target="#add-unit-modal"
                    onClick={() => setShowModal(true)}
                >
                    <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'MENU.UNITS' })}
                </button>

            }
            <div className="modal fade" tabIndex={-1} id="add-unit-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'MENU.UNITS' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}

                                <label className='form-label fs-6 fw-bolder text-dark required'>Alias</label>
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('alias')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.alias && formik.errors.alias },
                                        )}
                                        type='text'
                                        name='alias'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.alias && formik.errors.alias && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.alias}</span>
                                    </div>
                                )}

                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                                <div className="input-group mb-3">
                                    <SketchPicker
                                        color={formik.values.color}
                                        onChange={color => {
                                            formik.handleChange({
                                                target: {
                                                    name: "color",
                                                    value: color.hex
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                            <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? 'disabled' : ''}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export { AddUnit }