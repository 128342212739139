import { FC } from "react";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { PageNoPermisson } from "../general/PageNoPermission";
import { MasterMeetingsContent } from "./MasterMeetingsContent";

const MasterMeetings: FC = () => {
    const { settings } = useSettingsStore()

    return (
        <>
            {
                settings.feature_meeting ?
                    <MasterMeetingsContent />
                    :
                    <PageNoPermisson title={settings.meeting_title} />
            }
        </>
    )
}

export { MasterMeetings }