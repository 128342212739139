import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import useAccessToken from "../../hooks/AccessToken";
import moment from "moment";
import { DatePicker } from "rsuite";
import { KTSVG } from "../../_metronic/helpers";
import TimeIcon from '@rsuite/icons/Time';
import { AttendanceReport, MyAttendance } from "../../interfaces/Attendance";
import { getLogAttendance, updateClockIn, updateClockOut } from "../../api/AttendanceCRUD";
import { mutate } from "swr";
import useHandleCloseModal from "../../hooks/HandleCloseModal";
import { useIntl } from "react-intl";

interface UpdateModalProps {
    attendance: MyAttendance;
    updateType: string;
}

const UpdateAttendance: FC<UpdateModalProps> = ({ attendance, updateType }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const [logLoading, setLogLoading] = useState(true);
    const [logData, setLogData] = useState<any[]>([])
    const intl = useIntl()

    const API_URL = process.env.REACT_APP_API_URL

    const validationSchema = Yup.object().shape({
        time: Yup.string().required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'HOURS' }) })).nullable()
        .test('is-same-before', intl.formatMessage({ id: 'FORM.LABEL.NO_CHANGES' }), value => {
            return !(moment(value).format('HH:mm') === moment(updateType === "clockIn" ? attendance.dateClockIn : attendance.dateClockOut).format('HH:mm'));  // If value is present, then check format
        }),
    });

    const initialValues = {
        time: updateType === "clockIn" ? attendance.dateClockIn : attendance.dateClockOut,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            try {
                if (updateType === "clockIn") {
                    // if (moment(values.time).isAfter(moment(attendance.userAttendances[0].dateClockOut))) {
                    //     throw {
                    //         response: {
                    //             data: {
                    //                 message: 'Jam masuk tidak bisa lebih akhir dari jam keluar',
                    //                 text: 'Jika perlu melakukan koreksi, harap sesuaikan dengan jam masuk dan keluar user'
                    //             }
                    //         },
                    //         error: new Error()
                    //     };
                    // }
                    const response = await updateClockIn(
                        attendance.id,
                        attendance,
                        moment(values.time).format("YYYY-MM-DD HH:mm:ss"),
                        token
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                            heightAuto: false,
                        })
                        mutate(`${API_URL}user-attendances`);
                        closeModalRef.current?.click();
                    }
                }
                else if (updateType === "clockOut") {
                    // if (moment(values.time).isBefore(moment(attendance.userAttendances[0].dateClockIn))) {
                    //     throw {
                    //         response: {
                    //             data: {
                    //                 message: 'Jam keluar tidak bisa lebih awal dari jam masuk',
                    //                 text: 'Jika perlu melakukan koreksi, harap sesuaikan dengan jam masuk dan keluar user'
                    //             }
                    //         },
                    //         error: new Error()
                    //     };
                    // }
                    const response = await updateClockOut(
                        attendance.id,
                        attendance,
                        moment(values.time).format("YYYY-MM-DD HH:mm:ss"),
                        token
                    )
                    if (response.data.success) {
                        Swal.fire({
                            icon: "success",
                            title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                            text: intl.formatMessage({ id: "FORM.VALIDATION.UPDATE_SUCCESS" }),
                            heightAuto: false,
                        })
                        mutate(`${API_URL}user-attendances`);
                        closeModalRef.current?.click();
                    }
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    text: error.response.data.text ?? "",
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        if ((updateType == "clockIn" && attendance.isClockInChanged) || (updateType == "clockOut" && attendance.isClockOutChanged)) {
            const fetchLogData = async () => {
                try {
                    const response = await getLogAttendance(
                        attendance.id,
                        token
                    )
                    if (response.data.success) {
                        // setLogData(response.data.data)
                        const responseData = response.data.data
                        let logUpdate: any[] = []
                        responseData.map((data: any) => {
                            if (updateType == "clockIn" && data.dateClockIn[0] !== data.dateClockIn[1]) logUpdate.push({ old: data.dateClockIn[0], new: data.dateClockIn[1], dateCreated: data.dateCreated })
                            else if (updateType == "clockOut" && data.dateClockOut[0] !== data.dateClockOut[1]) logUpdate.push({ old: data.dateClockOut[0], new: data.dateClockOut[1], dateCreated: data.dateCreated })
                        })
                        setLogData(logUpdate)
                    }
                } catch (error: any) {
                    Swal.fire({
                        icon: 'error',
                        title: error.response.data.message,
                        text: error.response.data.text ?? "",
                        confirmButtonText: 'Ok',
                        heightAuto: false,
                    })
                } finally {
                    setLogLoading(false)
                }
            }

            fetchLogData()
        }
    }, [])

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={"update-attendance-modal-" + attendance.userId} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.ACTION.UPDATE_ATTENDANCE" })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                {/* name */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.NAME" })}</label>
                                <div className="input-group mb-3">
                                    {attendance.userName}
                                </div>
                                {/* date */}
                                <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.DATE" }, {title: ''})}</label>
                                <div className="input-group mb-3">
                                    {moment(updateType === "clockIn" ? attendance.dateClockIn : attendance.dateClockOut).locale('id').format('DD MMMM YYYY')}
                                </div>
                                {/* time */}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{updateType === "clockIn" ? intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.START_HOUR" }) : intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.END_HOUR" })}</label>
                                <div className="input-group mb-3">
                                    <DatePicker
                                        editable={false}
                                        format="HH:mm"
                                        value={formik.values.time ? new Date(formik.values.time) : null}
                                        style={{ width: "100%", zIndex: 0 }}
                                        container={document.querySelector('#update-attendance-modal-' + attendance.userId) as HTMLElement}
                                        onChange={(value: any) => {
                                            if (value) formik.setFieldValue('time', value)
                                            else formik.setFieldValue('time', null)
                                        }}
                                        caretAs={TimeIcon}
                                        onSelect={(value: any) => {
                                            if (value) formik.setFieldValue('time', value)
                                            else formik.setFieldValue('time', null)
                                        }}
                                    />
                                    {formik.touched.time && formik.errors.time && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.time}</span>
                                        </div>
                                    )}
                                </div>
                                {((updateType == "clockIn" && attendance.isClockInChanged) || (updateType == "clockOut" && attendance.isClockOutChanged)) &&
                                    <div className="pt-5">
                                        <h5 className="mb-3">{intl.formatMessage({ id: "FORM.LABEL.LOG" })}</h5>
                                        {logLoading ? (
                                            <div className="text-center w-100">
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Loading...{' '}
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            </div>
                                        ) : logData.length > 0 ? (
                                            <div>
                                                {logData.map(data => (
                                                    <div className="row mb-2">
                                                        <div className="col-2">{moment(data.old).format('HH:mm')}</div>
                                                        <div className="col-1">{'=>'}</div>
                                                        <div className="col-2">{moment(data.new).format('HH:mm')}</div>
                                                        <div className="col-7 fst-italic">{intl.formatMessage({ id: "FORM.LABEL.CHANGED_ON" }, {title: moment(data.dateCreated).locale('id').format('D MMM YY, HH:mm')})}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>{intl.formatMessage({ id: "FORM.LABEL.NO_CHANGES" })}</p>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export { UpdateAttendance }