import { FC, useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { useChatStore } from "../../stores/ChatStore";
import useAccessToken from "../../hooks/AccessToken";
import { ChatListItem } from "./ChatListItem";
import { Message } from "../../interfaces/Chat/Message";
import { downloadMedia, generateQR } from "../../api/ChatHelper";
import addNotification from "react-push-notification";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "../../hooks/User";
import { IncomingMessageNotification } from "../../interfaces/Chat/IncomingMessageNotification";
import { MerchantLeads, RecentChatHistory, RecentChatHistoryPipeline } from "../../interfaces/Chat/RecentChatHistory";
import { useSettingsStore } from "../../stores/SettingsStore";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { SelectOption } from "../../interfaces/SelectOption";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { Progress } from "rsuite/esm/Progress/Progress";
import { base58Encode, rot8Encode } from "../../functions/encryption";
import moment from 'moment';
import { PersistedMessage } from "../../interfaces/Chat/PersistedMessage";
import { useCustomeStore } from "../../stores/CustomeStore";
import Swal from "sweetalert2";
import { ChatList } from "./ChatList";
import { useIntl } from "react-intl";
import { Modal } from "bootstrap";
import { AssignContactLabel } from "./Modal/AssignContactLabel";
import { LabelChatProps } from "../../interfaces/Chat/LabelChat";
import { icon } from "leaflet";
import { USER_MANAGER_HAPPY_PLAY } from "../../constant/General";
import { merge } from "chart.js/dist/helpers/helpers.core";
import { useTokenCustomCompany } from "../../stores/useTokenCustomCompany";
import useShowCompanyById from "../../hooks/useShowCompanyById";
import { sortListChat } from "../../functions/chat";
import { getChatRoomsData } from "../../api/ChatCRUD";
import { customNumberFormat } from "../../functions/general";

interface ChatSidebarProps {
    totalChats: number
    getChatRooms: (lastSynced: string, phone: string, params?: { offset: number, limit: number }) => void
}

const ChatSidebar = ({ totalChats, getChatRooms }: ChatSidebarProps) => {
    const user = useUser()
    const intl = useIntl()
    const token = useAccessToken()
    const { companyId } = useCustomeStore()

    const navigate = useNavigate()
    const { pathname } = useLocation()
    const {
        socket, phoneNumber, connected,
        recentChatHistories, setRecentChatHistories, setFilteredRecentChatHistories,
        selectedChatPhone, setMediaDownloads, incomingMessageNotifications,
        setIncomingMessageNotifications, setPhoneNumber, setShowChat,
        interacted, messages, setMessages,
        filteredRecentChatHistories, setPersistedMessages,
        persistedMessages, setLastSynced, lastSynced,
        recentChatIsSyncing, setRecentChatIsSyncing, replacedChatIds,
        setReplacedChatIds, replacementChat, setReplacementChat,
        persistedMessagesCount, setRecentChatSelectedPipeline, setPersistedMessagesCount,
        setIsWaitingForNetwork, isWaitingForNetwork, isFirstSyncing, messagesIsSyncing,
        selectChatWindow, setSelectChatWindow, chatLabels
    } = useChatStore()
    const { settings } = useSettingsStore()
    const { tokenCustomCompany } = useTokenCustomCompany()

    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [statusData, setStatusData] = useState<SelectOption[]>([])
    const [progressesData, setProgressesData] = useState<SelectOption[]>([])
    const [labelsData, setLabelsData] = useState<SelectOption[]>([])
    const [selectedFilterProgress, setSelectedFilterProgress] = useState<string[]>([]);
    const [selectedFilterLabel, setSelectedFilterLabel] = useState<string>("");
    const [selectedFilterStatus, setSelectedFilterStatus] = useState<string>("");
    const [selected, setSelected] = useState<{ [phone: string]: boolean }>({});
    const [showAssignContactLabelModal, setShowAssignContactLabelModal] = useState<boolean>(false);
    const [offset, setOffset] = useState(2500)
    const { company } = useShowCompanyById(user.data.company_id)

    useEffect(() => {
        if (recentChatHistories.length > 0 && search.trim() === '') {
            const sortedListChat = sortListChat(recentChatHistories);
            if (settings.chat_show_pic_only && !user.data.is_superadmin) {
                setFilteredRecentChatHistories(() => sortedListChat.filter(recentChatHistory => recentChatHistory.merchant_id))
            } else {
                setFilteredRecentChatHistories(() => sortedListChat)
            }
        }
    }, [offset, totalChats, lastSynced]);

    useEffect(() => {
        let persistedMessagesCount = 0
        Object.keys(persistedMessages).forEach((key) => {
            persistedMessagesCount += persistedMessages[key].messages.length
        })

        setPersistedMessagesCount(persistedMessagesCount)
    }, [persistedMessages, totalChats])

    useEffect(() => {
        if (!connected) setIsLoading(false)
    }, [connected])

    const handleNotification = async (incomingMessage: Message) => {
        const { incomingMessageNotifications, recentChatHistories } = useChatStore.getState();

        try {
            addNotification({
                title: incomingMessage.name ?? incomingMessage.phone,
                message: incomingMessage.text,
                native: true, // when using native, your OS will handle theming.
                // icon: response.data.data ? response.data.data : toAbsoluteUrl(`/media/logos/logo-wooblazz.png`),
                onClick: () => {
                    navigate('/chat')
                    // if (persistedMessages[incomingMessage.phone] && persistedMessages[incomingMessage.phone].messages) {
                    //     setMessages(() => persistedMessages[incomingMessage.phone].messages)
                    //     // setMessages(persistedMessages[incomingMessage.phone].messages)
                    // }
                    setShowChat(true)
                    setPhoneNumber(`${incomingMessage.phone}-${incomingMessage.as}`)

                    // find recentChatHistory with incomingMessage.phone
                    const recentChatHistory = recentChatHistories.find(recentChatHistory => recentChatHistory.chat_phone === incomingMessage.phone)

                    if (recentChatHistory) setRecentChatSelectedPipeline(recentChatHistory.pipelines![0])
                    const { [`${incomingMessage.phone}-${incomingMessage.as}`]: removedItem, ...rest } = incomingMessageNotifications;

                    setIncomingMessageNotifications(() => rest);
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        socket.on("chatMessage", async (chatMessage) => {
            await mutate(`${API_URL}chat/merchants-leads`)
            const incomingChat: any = Object.values(chatMessage.chatMessage)[0]
            const { recentChatHistories } = useChatStore.getState();
            const recentChatHistory = recentChatHistories.find((recentChatHistory) => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === `${chatMessage.client_phone}-${chatMessage.as}`) ?? chatMessage.chatMessage

            const incomingMessage: Message = {
                as: chatMessage.as,
                color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                id: incomingChat.chat_id,
                id_reference: incomingChat.chat_id_reference,
                phone: chatMessage.client_phone,
                name: incomingChat.chat_from_me === 1 ? recentChatHistory.chat_name : incomingChat.chat_name,
                type: incomingChat.chat_from_me === 1 ? 'out' : 'in',
                text: incomingChat.chat_message,
                time: incomingChat.chat_date,
                attachment: incomingChat.chat_attachment,
                attachment_name: incomingChat.chat_attachment_name,
                is_editing: false,
                is_replying: false,
                is_from_me: incomingChat.chat_from_me,
                sender: incomingChat.sender,
                chat_j_message: incomingChat.chat_j_message,
                isForwarded: incomingChat.isForwarded
            }

            // if (chatId !== incomingMessage.id && incomingMessage.attachment_name) handleIncomingMessageMedia(incomingMessage)
            setFilteredRecentChatHistories((prevState) => {

                const existingChat = prevState.find(chat => chat.chat_id === incomingChat.chat_id);

                const newChat: RecentChatHistory = {
                    as: chatMessage.as,
                    color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                    profile_picture_url: incomingChat.profile_picture_url,
                    chat_id: incomingChat.chat_id,
                    chat_name: incomingChat.chat_from_me === 1 ? recentChatHistory.chat_name : incomingChat.chat_name,
                    chat_message_sender: incomingChat.chat_message_sender,
                    chat_phone: chatMessage.client_phone,
                    chat_message: incomingChat.chat_message,
                    chat_date: incomingChat.chat_date,
                    chat_attachment: incomingChat.chat_attachment,
                    chat_attachment_name: incomingChat.chat_attachment_name,
                    chat_list_count: incomingChat.chat_list_count,
                    pipelines: incomingChat.pipelines,
                    isFirstLoaded: true,
                    merchant_id: recentChatHistory.merchant_id,
                    merchant_name: recentChatHistory.merchant_name,
                    merchant_name_active: recentChatHistory.merchant_name_active,
                    merchant_name_PIC: recentChatHistory.merchant_name_PIC,
                    selected: false,
                    chat_j_message: incomingChat.chat_j_message,
                    isForwarded: incomingChat.isForwarded
                }
                if (!existingChat && selectedChatPhone.find((item) => item.phone !== incomingChat.chat_phone)) {
                    return [newChat, ...prevState]
                }

                return prevState.map(chat => {
                    return chat;
                });
            })

            setRecentChatHistories((prevState) => {
                const existingChat = prevState.find(chat => chat.chat_id === incomingChat.chat_id);

                const newChat = {
                    as: chatMessage.as,
                    color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                    profile_picture_url: incomingChat.profile_picture_url,
                    chat_id: incomingChat.chat_id,
                    chat_name: incomingChat.chat_from_me === 1 ? recentChatHistory.chat_name : incomingChat.chat_name,
                    chat_message_sender: incomingChat.chat_message_sender,
                    chat_phone: chatMessage.client_phone,
                    chat_message: incomingChat.chat_message,
                    chat_date: incomingChat.chat_date,
                    chat_attachment: incomingChat.chat_attachment,
                    chat_attachment_name: incomingChat.chat_attachment_name,
                    chat_list_count: incomingChat.chat_list_count,
                    pipelines: incomingChat.pipelines,
                    isFirstLoaded: true,
                    merchant_id: incomingChat.merchant_id,
                    merchant_name: incomingChat.merchant_name,
                    merchant_name_active: incomingChat.merchant_name_active,
                    merchant_name_PIC: incomingChat.merchant_name_PIC,
                    selected: false,
                    chat_j_message: incomingChat.chat_j_message,
                    isForwarded: incomingChat.isForwarded
                }

                if (!existingChat && selectedChatPhone.find((item) => item.phone !== incomingChat.chat_phone)) {
                    return [newChat, ...prevState]
                }
                return prevState.map(chat => {
                    return chat
                })
            })

            if (incomingMessage.type === 'in') {
                if (settings.chat_show_pic_only && !user.data.is_superadmin) {
                    chatMerchants.forEach((merchant: MerchantLeads) => {
                        if (merchant.phone === incomingMessage.phone) {
                            return handleNotification(incomingMessage)
                        }
                    })
                } else {
                    handleNotification(incomingMessage)
                }
            }

            if (
                ((
                    (!replacedChatIds.includes(incomingMessage.id!) && selectedChatPhone.find((item) => item.phone === incomingMessage.phone)) // Handle chat from CRM sendMessage
                    ||
                    (selectedChatPhone.find((item) => item.phone === incomingMessage.phone) && !replacedChatIds.includes(incomingMessage.id!))) // Handle incoming chat from WA
                    &&
                    (`${chatMessage.client_phone}-${chatMessage.as}` === phoneNumber)) // Handle if the chat is not the current chat
                || (`${incomingMessage.phone}-${incomingMessage.as}` === phoneNumber && selectedChatPhone.find((item) => item.phone !== incomingMessage.phone)) // Handle if the chat is the current chat and the sender is the user but to other phone number
            ) {
                if (!messages.filter(message => message.id === incomingMessage.id) || messages.filter(message => message.id === incomingMessage.id).length === 0) {
                    setMessages((prev) => [...prev, incomingMessage])
                }
            } else {
                const temporaryId = replacementChat[incomingMessage.id!]

                if (temporaryId) {
                    // remove the temporaryId from the replacementChat
                    const { [incomingMessage.id!]: removedItem, ...rest } = replacementChat;

                    setReplacementChat(() => rest)

                    // remove the temporaryId from the replacedChatIds
                    const newReplacedChatIds = replacedChatIds.filter((chatId) => chatId !== temporaryId)

                    setReplacedChatIds(() => newReplacedChatIds)
                }
            }

            const incomingPhone = selectedChatPhone.find((item) => item.phone === incomingMessage.phone) ? chatMessage.client_phone : incomingMessage.phone

            // if (!incomingMessageNotifications[incomingPhone] && incomingPhone !== user.data.chat_phone && incomingMessage.phone !== user.data.chat_phone && phoneNumber !== incomingPhone) {
            //     const newIncomingMessageNotification: IncomingMessageNotification = {
            //         [incomingPhone]: {
            //             latestMessage: incomingMessage.text,
            //             unreadCount: 1,
            //         },
            //     };
            //     setIncomingMessageNotifications((prevNotifications) => ({ ...prevNotifications, ...newIncomingMessageNotification }));
            if (incomingMessage.type === 'in' && phoneNumber.split('-')[0] !== incomingPhone) {
                setIncomingMessageNotifications((prevNotifications) => ({
                    ...prevNotifications,
                    [`${incomingPhone}-${chatMessage.as}`]: {
                        ...prevNotifications[`${incomingPhone}-${chatMessage.as}`],
                        latestMessage: incomingMessage.text,
                        unreadCount: (prevNotifications[`${incomingPhone}-${chatMessage.as}`]?.unreadCount || 0) + 1,
                    },
                }));

            }
            // else {
            //     setIncomingMessageNotifications({
            //         ...incomingMessageNotifications,
            //         [incomingPhone]: {
            //             ...incomingMessageNotifications[incomingPhone],
            //             latestMessage: incomingMessage.text,
            //             unreadCount: 0
            //         }
            //     })
            // }

            // get the data with the incomingPhone
            if (recentChatHistory) {
                const newRecentChatHistories = recentChatHistories.filter((recentChatHistory) => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` !== `${chatMessage.client_phone}-${chatMessage.as}`)

                const newRecentChatHistory: RecentChatHistory = {
                    as: chatMessage.as,
                    color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                    profile_picture_url: recentChatHistory?.profile_picture_url!,
                    chat_id: incomingChat.chat_id,
                    chat_name: incomingChat.chat_from_me === 1 ? recentChatHistory.chat_name : incomingChat.chat_name,
                    chat_message_sender: incomingChat.chat_message_sender,
                    chat_phone: incomingPhone,
                    chat_message: incomingChat.chat_message,
                    chat_date: incomingChat.chat_date,
                    chat_attachment: incomingChat.chat_attachment,
                    chat_attachment_name: incomingChat.chat_attachment_name,
                    chat_list_count: (recentChatHistory!.chat_list_count ?? 0) + 1,
                    isFirstLoaded: recentChatHistories.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === `${chatMessage.client_phone}-${chatMessage.as}`)?.isFirstLoaded ?? true,
                    merchant_id: recentChatHistory?.merchant_id,
                    merchant_ids: recentChatHistory?.merchant_ids,
                    merchant_name: recentChatHistory?.merchant_name,
                    merchant_name_active: recentChatHistory?.merchant_name_active,
                    merchant_name_PIC: recentChatHistory?.merchant_name_PIC,
                    pipelines: recentChatHistory?.pipelines,
                    labels: recentChatHistory?.labels,
                    selected: `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phoneNumber,
                    chat_j_message: incomingChat.chat_j_message,
                    isForwarded: incomingChat.isForwarded
                }

                const mergeData = [newRecentChatHistory, ...newRecentChatHistories]
                const sortedListChat = sortListChat(mergeData);
                setRecentChatHistories(() => sortedListChat)
                if (settings.chat_show_pic_only && !user.data.is_superadmin) {
                    setFilteredRecentChatHistories(() => sortedListChat.filter(chat => chat.merchant_id))
                } else {
                    setFilteredRecentChatHistories(() => sortedListChat)
                }
            } else {
                const newRecentChatHistory: RecentChatHistory = {
                    as: chatMessage.as,
                    color: selectedChatPhone.find((item) => item.phone === chatMessage.as)?.color!,
                    profile_picture_url: incomingChat.profile_picture_url,
                    chat_id: incomingChat.chat_id,
                    chat_name: incomingChat.chat_from_me === 1 ? recentChatHistory.chat_name : incomingChat.chat_name,
                    chat_message_sender: incomingChat.chat_message_sender,
                    chat_phone: incomingPhone,
                    chat_message: incomingChat.chat_message,
                    chat_date: incomingChat.chat_date,
                    chat_attachment: incomingChat.chat_attachment,
                    chat_attachment_name: incomingChat.chat_attachment_name,
                    chat_list_count: 1,
                    isFirstLoaded: true,
                    merchant_id: '',
                    merchant_ids: [],
                    merchant_name: '',
                    merchant_name_active: '',
                    merchant_name_PIC: '',
                    pipelines: [],
                    selected: false,
                    chat_j_message: incomingChat.chat_j_message,
                    isForwarded: incomingChat.isForwarded
                }

                const mergeData = [newRecentChatHistory, ...recentChatHistories]
                const sortedListChat = sortListChat(mergeData);
                setRecentChatHistories(() => sortedListChat)
                if (settings.chat_show_pic_only && !user.data.is_superadmin) {
                    setFilteredRecentChatHistories(() => sortedListChat.filter(chat => chat.merchant_id))
                } else {
                    setFilteredRecentChatHistories(() => sortedListChat)
                }
            }

            if (persistedMessages[`${incomingPhone}-${chatMessage.as}`]) {
                setPersistedMessages((prevMessages) => ({
                    ...prevMessages,
                    [`${incomingPhone}-${chatMessage.as}`]: {
                        messages: [...(prevMessages[`${incomingPhone}-${chatMessage.as}`]?.messages || []), incomingMessage]
                    }
                }));
            } else {
                setPersistedMessages((prevMessages) => ({
                    ...prevMessages,
                    [`${incomingPhone}-${chatMessage.as}`]: {
                        messages: [incomingMessage]
                    }
                }));
            }
        })

        return () => {
            socket.off("chatMessage")
        };
    }, [phoneNumber, messages, incomingMessageNotifications, persistedMessages, recentChatHistories, replacedChatIds, replacementChat])

    const handleFilterChange = (selectedOptions: SelectOption[] | SelectOption, type: string) => {
        if (type === "progress") {
            const selectedValues = (selectedOptions as SelectOption[]).map(option => option.value);
            setSelectedFilterProgress(selectedValues);
        } else if (type === "labels") {
            setSelectedFilterLabel((selectedOptions as SelectOption)?.value);
        } else if (type === "status") {
            setSelectedFilterStatus((selectedOptions as SelectOption)?.value);
        }
    };

    useEffect(() => {
        const applyFilters = (histories: RecentChatHistory[], filters: string[], selectedFilterLabel: string, selectedFilterStatus: string) => {
            // Jika tidak ada filter yang dipilih, kembalikan semua data
            if (filters.length === 0 && selectedFilterLabel === "" && selectedFilterStatus === "") {
                return histories;
            }

            return histories?.filter(history => {
                // Filter berdasarkan lead_progress_name
                const matchesFilters = filters.length === 0 || history.pipelines?.some(pipeline =>
                    pipeline.lead_progress_name && filters?.includes(pipeline.lead_progress_name)
                );

                // Filter berdasarkan label
                const matchesLabel = !selectedFilterLabel || history.labels?.some(label => label.id === selectedFilterLabel);

                // Filter berdasarkan status read/unread
                const isUnread = incomingMessageNotifications[`${history.chat_phone}-${history.as}`];
                const matchesStatus = !selectedFilterStatus || (selectedFilterStatus === "unread" && isUnread) || (selectedFilterStatus === "read" && !isUnread);

                // Return true hanya jika semua kondisi terpenuhi
                return matchesFilters && matchesLabel && matchesStatus;
            });
        };


        const normalizeText = (text: string) => {
            return text
                ?.toLowerCase()
                .replace(/[\u2018\u2019\u201A\u201B\u2039\u203A]/g, "'")  // replace various single quotes with a standard single quote
                .replace(/[\u201C\u201D\u201E\u201F\u2033\u2036]/g, '"') // replace various double quotes with a standard double quote
                .replace(/\\'/g, "'"); // replace escaped single quote
        };

        const performSearch = (histories: RecentChatHistory[], query: string) => {
            if (query?.trim() === '') {
                return histories;
            }

            if (query.length >= 2) {
                if (query.substring(0, 2) === '08') {
                    query = '628' + query.substring(2);
                }
            }

            const searchedChatHistories: RecentChatHistory[] = [];
            const searchedContacts: RecentChatHistory[] = [];

            const searchedPhone: RecentChatHistory[] = recentChatHistories?.filter(recentChatHistory => recentChatHistory.chat_phone.includes(query));
            const searchedMerchant: RecentChatHistory[] = recentChatHistories?.filter(recentChatHistory => normalizeText(recentChatHistory?.merchant_name_active!)?.includes(normalizeText(query)));
            const searchedName: RecentChatHistory[] = recentChatHistories?.filter(recentChatHistory => normalizeText(recentChatHistory?.chat_name)?.includes(normalizeText(query)));

            if (searchedPhone) {
                searchedPhone.forEach((recentChat, index) => {
                    if (recentChat.chat_phone.includes(searchedName[(index + 1) - 1]?.chat_phone)) {
                        searchedContacts.push({
                            ...recentChat,
                            profile_picture_url: 'contact',
                        });
                    }
                })
            }

            if (searchedMerchant) {
                searchedMerchant.forEach((recentChat, index) => {
                    if (normalizeText(recentChat.merchant_name_active!)?.includes(normalizeText(searchedName[(index + 1) - 1]?.merchant_name_active!))) {
                        searchedContacts.push({
                            ...recentChat,
                            profile_picture_url: 'contact',
                        });
                    }
                })
            }

            if (searchedName) {
                searchedName.forEach((recentChat, index) => {
                    if (normalizeText(recentChat?.chat_name)?.includes(normalizeText(searchedName[(index + 1) - 1]?.chat_name))) {
                        searchedContacts.push({
                            ...recentChat,
                            profile_picture_url: 'contact',
                        });
                    }
                })
            }

            if (searchedPhone.length > 0) {
                searchedPhone.forEach((contact) => {
                    searchedChatHistories.push(contact);
                });
            }

            if (searchedMerchant.length > 0) {
                searchedMerchant.forEach((contact) => {
                    searchedChatHistories.push(contact);
                });
            }

            if (searchedContacts.length > 0) {
                searchedContacts.forEach((contact) => {
                    searchedChatHistories.push(contact);
                });
            }

            const { persistedMessages } = useChatStore.getState();
            Object.keys(persistedMessages).forEach((phone) => {
                const chatMessages = persistedMessages[phone].messages;

                const searchedTextMessages = chatMessages?.filter((message) =>
                    normalizeText(message?.text)?.includes(normalizeText(query))
                );


                if (searchedTextMessages.length > 0) {
                    const recentChatHistory = recentChatHistories?.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phone);
                    searchedTextMessages.forEach((message) => {
                        searchedChatHistories.push({
                            as: recentChatHistory?.as!,
                            color: selectedChatPhone.find((item) => item.phone === recentChatHistory?.as)?.color!,
                            profile_picture_url: 'search',
                            chat_id: message.id!,
                            chat_name: recentChatHistory?.chat_name ?? phone.split('-')[0],
                            chat_message_sender: recentChatHistory?.chat_message_sender!,
                            chat_phone: phone.split('-')[0],
                            chat_message: (message.phone === user.data.chat_phone ? 'You: ' : '') + message.text,
                            chat_date: message.time,
                            chat_attachment: message.attachment,
                            chat_attachment_name: message.attachment_name,
                            chat_list_count: recentChatHistory?.chat_list_count ?? 0,
                            isFirstLoaded: false,
                            merchant_id: recentChatHistory?.merchant_id!,
                            merchant_ids: recentChatHistory?.merchant_ids,
                            merchant_name: recentChatHistory?.merchant_name,
                            merchant_name_active: recentChatHistory?.merchant_name_active,
                            merchant_name_PIC: recentChatHistory?.merchant_name_PIC,
                            pipelines: recentChatHistory?.pipelines,
                            labels: recentChatHistory?.labels,
                            selected: false
                        });
                    });
                }
            });

            const chatMap = new Map(searchedChatHistories.map(chat => [chat.chat_id, chat]));
            const filteredChats = Array.from(chatMap.values());
            return filteredChats;
        };

        const { recentChatHistories } = useChatStore.getState();
        let updatedHistories = recentChatHistories;

        // Apply search logic
        updatedHistories = performSearch(updatedHistories, search);

        // Apply filter logic
        updatedHistories = applyFilters(updatedHistories, selectedFilterProgress, selectedFilterLabel, selectedFilterStatus);

        // Sort and set state
        if (updatedHistories.length > 0) {
            const shouldFilterByPic = settings.chat_show_pic_only && !user.data.is_superadmin;
            const sortedListChat = sortListChat(updatedHistories);

            const filteredHistories = shouldFilterByPic
                ? sortedListChat?.filter(history => history.merchant_id)
                : sortedListChat;

            setFilteredRecentChatHistories(() => filteredHistories);
        } else {
            setFilteredRecentChatHistories(() => []);
        }
    }, [search, selectedFilterProgress, selectedFilterLabel, selectedFilterStatus, incomingMessageNotifications]);

    // Function to debounce the setSearch
    const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return function (this: any, ...args: Parameters<F>) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    // Debounced setSearch function with a delay of 500 milliseconds (0.5 second)
    const debouncedSetSearch = debounce(setSearch, 500);

    const DropdownIndicator = (
        props: DropdownIndicatorProps<SelectOption, true>
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <i className="fas fa-filter"></i>
            </components.DropdownIndicator>
        );
    };

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const { data: progresses = [], error: progressesError, isLoading: progressesLoading } = useSWR(`${API_URL}lead_progresses?onlyShowAllowedForCreateLeadLog=true`, fetcher)

    useEffect(() => {
        if (!progressesLoading) {
            const progressesData = progresses
                .filter((progress: Progress) => progress.name !== "Paid" && progress.name !== "Drop")
                .map((progress: Progress) => ({
                    value: progress.name,
                    label: progress.name
                }));

            // append the "New" option
            if (user.data.company_id !== companyId.elgisa) {
                progressesData.push({
                    value: 'NEW',
                    label: 'New'
                })
            }

            setProgressesData(progressesData)
        }
    }, [progresses])

    const { data: chatMerchants, error, isLoading: chatMerchantsIsLoading } = useSWR(`${API_URL}chat/merchants-leads`, fetcher)

    useEffect(() => {
        const { recentChatHistories } = useChatStore.getState();
        if (!chatMerchantsIsLoading && chatMerchants && recentChatHistories) {
            let recentChatHistoriesTemp: RecentChatHistory[] = [];

            recentChatHistoriesTemp = recentChatHistories.map(recentChatHistory => {
                let merchantData = chatMerchants.find((merchant: any) => merchant.phone === recentChatHistory.chat_phone);
                if (merchantData) {
                    let merchantPipelines: RecentChatHistoryPipeline[] = [];
                    let merchantIds: string[] = [];

                    merchantData.data.forEach((merchant: any) => {
                        if (merchant.pipelines) {
                            merchantIds.push(merchant.id);
                            merchantPipelines.push(...merchant.pipelines);
                        }
                    });

                    return {
                        ...recentChatHistory,
                        merchant_name_active: merchantData.name,
                        merchant_name_PIC: merchantData.namePIC,
                        merchant_ids: merchantIds,
                        pipelines: merchantPipelines
                    };
                }
                return {
                    ...recentChatHistory,
                    merchant_ids: [],
                    merchant_name_active: '',
                    merchant_name_PIC: '',
                    pipelines: []
                }
            });

            const sortedListChat = recentChatHistoriesTemp;
            setRecentChatHistories(() => sortedListChat);
        }
    }, [chatMerchants]);

    useEffect(() => {
        const { selectedChatPhone } = useChatStore.getState();

        if (lastSynced) {
            setLastSynced(moment().format('YYYY-MM-DD HH:mm:ss').toString())
            setIsWaitingForNetwork(false); // Reset waiting state once emitted
            selectedChatPhone.forEach((item) => {
                getChatRooms(lastSynced, item.phone)
            })
        } else {
            setRecentChatIsSyncing(true)
        }

        setRecentChatHistories((prevState) => prevState.map(chat => ({ ...chat, selected: false })))
        setStatusData([{ value: 'unread', label: 'Belum dibaca' }, { value: 'read', label: 'Dibaca' }])

        const updateNetworkStatus = () => {
            if (!navigator.onLine) {
                console.log('reconnecting...');
                setIsWaitingForNetwork(true);
                setLastSynced(moment().format('YYYY-MM-DD HH:mm:ss').toString())
                if (company) {
                    socket.emit('setOption', { token: token, [`${company}Token`]: tokenCustomCompany });
                } else {
                    socket.emit('setOption', { token: token });
                }
            } else {
                console.log('connected!');
                setLastSynced(moment().format('YYYY-MM-DD HH:mm:ss').toString())
                setIsWaitingForNetwork(false); // Reset waiting state once emitted
                selectedChatPhone.forEach((item) => {
                    getChatRooms(lastSynced, item.phone)
                })
            }
        };

        // Event listeners for online/offline status
        window.addEventListener('load', updateNetworkStatus);
        window.addEventListener('online', updateNetworkStatus);
        window.addEventListener('offline', updateNetworkStatus);

        // Set an interval to retry emitting every 10 seconds if offline
        const interval = setInterval(() => {
            if (!navigator.onLine) {
                if (company) {
                    socket.emit('setOption', { token: token, [`${company}Token`]: tokenCustomCompany });
                } else {
                    socket.emit('setOption', { token: token });
                }
            }
        }, 2000); // 10 seconds

        // Clean up the event listeners and interval when component unmounts
        return () => {
            window.removeEventListener('load', updateNetworkStatus);
            window.removeEventListener('online', updateNetworkStatus);
            window.removeEventListener('offline', updateNetworkStatus);
            clearInterval(interval); // Clear interval on cleanup
            socket.off("setOption");
        };
    }, [lastSynced]);

    useEffect(() => {
        const labelsData = chatLabels.map((label: LabelChatProps) => ({
            value: label.id,
            label: label.name,
            color: label.color
        }));

        const { recentChatHistories } = useChatStore.getState();
        const updatedChatHistories = recentChatHistories.map(chat => {
            const matchingLabels = chatLabels.filter(label =>
                label.roomList.includes(chat.chat_phone)
            );

            return {
                ...chat,
                labels: matchingLabels
            };
        });

        setRecentChatHistories(() => updatedChatHistories);
        if (updatedChatHistories.length > 0 && search.trim() === '') {
            const sortedListChat = sortListChat(updatedChatHistories);
            if (settings.chat_show_pic_only && !user.data.is_superadmin) {
                setFilteredRecentChatHistories(() => sortedListChat.filter(recentChatHistory => recentChatHistory.merchant_id))
            } else {
                setFilteredRecentChatHistories(() => sortedListChat)
            }
        }
        setLabelsData(labelsData);
    }, [chatLabels, messagesIsSyncing]);

    useEffect(() => {
        const result = selectChatWindow.reduce((acc, item) => {
            acc[item.phone] = true; // Set boolean value, bisa juga false sesuai kebutuhan
            return acc;
        }, {} as { [phone: string]: boolean });

        setSelected(result)
    }, [showAssignContactLabelModal])

    useEffect(() => {
        if (showAssignContactLabelModal) {
            const modalElement = document.getElementById(`assign-contact-label-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAssignContactLabelModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAssignContactLabelModal, setShowAssignContactLabelModal]);

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: props.data.color, width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                    </svg>
                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {props.data.label}
                    </span>
                </div>
            </components.Option>
        );
    };

    const CustomSingleValue = (props: any) => {
        return (
            <components.SingleValue {...props}>
                <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: props.data.color }}>
                    <path fill="currentColor" fillRule="evenodd" clip-rule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                </svg>
                {props.data.label}
            </components.SingleValue>
        );
    };

    return (
        <div className='card card-flush w-100' style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
            <div className="d-flex align-items-center justify-content-between p-3 pb-0">
                <h1 className="mb-0 text-dark fw-bolder fs-1">Chat</h1>
                {/* <i className="p-3 fas fa-ellipsis-v fs-6 cursor-pointer"></i> */}
            </div>
            {
                connected &&
                <>
                    <div className="px-3">
                        {
                            (settings.chat_show_pic_only && !user.data.is_superadmin) &&
                            <span className="badge badge-primary">
                                Merchants only
                            </span>
                        }
                    </div>
                    {
                        selectChatWindow.length === 0 ?
                            <div className="card-header p-3" style={{ background: "white" }}>
                                <form className='w-100 position-relative' autoComplete='off'>
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                                    />

                                    <input
                                        type='search'
                                        className='form-control form-control-solid ps-15'
                                        name='search'
                                        placeholder='Search by text...'
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') e.preventDefault()
                                        }}
                                        style={{ borderRadius: "50px" }}
                                        onChange={(e) => debouncedSetSearch(e.target.value)}
                                        disabled={recentChatHistories.length === 0 && search === ''}
                                    />
                                </form>

                                <div className="d-flex w-100 gap-2 mb-10">
                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: "#F5F8FA",
                                                backgroundColor: "#F5F8FA",
                                                borderRadius: "0.475rem",
                                                width: "fit-content",
                                                minWidth: "100px",
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    borderColor: "#F5F8FA",
                                                    backgroundColor: "#F5F8FA",
                                                },
                                            }),
                                            option: (baseStyles, { isSelected, isFocused }) => ({
                                                ...baseStyles,
                                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                cursor: 'pointer',
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: 'none',
                                            }),
                                            menuList: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "150px",
                                            }),
                                            menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "150px",
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'none',
                                            },
                                        })}
                                        isClearable={true}
                                        closeMenuOnSelect={true}
                                        placeholder={`Status`}
                                        onChange={(option) => handleFilterChange(option as SelectOption, "status")}
                                        options={statusData}
                                        className="my-2 h-40px"
                                    />

                                    <Select
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: "#F5F8FA",
                                                backgroundColor: "#F5F8FA",
                                                borderRadius: "0.475rem",
                                                width: "fit-content",
                                                minWidth: "120px",
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    borderColor: "#F5F8FA",
                                                    backgroundColor: "#F5F8FA",
                                                },
                                            }),
                                            option: (baseStyles, { isSelected, isFocused }) => ({
                                                ...baseStyles,
                                                backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                cursor: 'pointer',
                                            }),
                                            indicatorSeparator: (baseStyles, state) => ({
                                                ...baseStyles,
                                                display: 'none',
                                            }),
                                            menuList: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "150px",
                                            }),
                                            menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: "150px",
                                            }),
                                            valueContainer: (baseStyles, state) => ({
                                                ...baseStyles,
                                                // display: 'flex',
                                                // flexWrap: 'nowrap', // Mencegah data dipilih berbaris
                                                overflowY: 'auto',  // Mengaktifkan scroll horizontal
                                                // whiteSpace: 'nowrap', // Mencegah teks terputus menjadi beberapa baris
                                                maxHeight: '80px', // Atur lebar maksimal agar tidak melebihi batas tertentu
                                                scrollbarWidth: "thin", // Untuk Firefox
                                                scrollbarColor: "#d1d1d1 transparent", // Untuk Firefox
                                                "&::-webkit-scrollbar": {
                                                    height: "4px", // Tinggi scrollbar
                                                },
                                                "&::-webkit-scrollbar-thumb": {
                                                    backgroundColor: "#d1d1d1", // Warna scroll thumb
                                                },
                                            }),
                                            multiValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                minWidth: 'fit-content', // Tambahkan jarak antar elemen jika diperlukan
                                            }),
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            colors: {
                                                ...theme.colors,
                                                primary: 'none',
                                            },
                                        })}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        placeholder={`Progress ${settings.pipeline_title}`}
                                        // components={{ DropdownIndicator }}
                                        onChange={(option) => handleFilterChange(option as SelectOption[], "progress")}
                                        options={progressesData}
                                        className="my-2"
                                        isDisabled={progressesLoading || recentChatHistories.length === 0}
                                    />

                                    {
                                        labelsData.length > 0 &&
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    borderColor: "#F5F8FA",
                                                    backgroundColor: "#F5F8FA",
                                                    borderRadius: "0.475rem",
                                                    width: "fit-content",
                                                    minWidth: "120px",
                                                    boxShadow: 'none',
                                                    '&:hover': {
                                                        borderColor: "#F5F8FA",
                                                        backgroundColor: "#F5F8FA",
                                                    },
                                                }),
                                                option: (baseStyles, { isSelected, isFocused }) => ({
                                                    ...baseStyles,
                                                    backgroundColor: isSelected ? '#2684FF' : isFocused ? '#dcf2ff' : 'none',
                                                    cursor: 'pointer',
                                                }),
                                                indicatorSeparator: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    display: 'none',
                                                }),
                                                menuList: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "fit-content",
                                                    minWidth: "150px",
                                                }),
                                                menu: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: "fit-content",
                                                    minWidth: "150px",
                                                }),
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary: 'none',
                                                },
                                            })}
                                            isClearable={true}
                                            closeMenuOnSelect={true}
                                            placeholder={intl.formatMessage({ id: "FORM.LABEL.LABELS" })}
                                            onChange={(option) => handleFilterChange(option as SelectOption, "labels")}
                                            options={labelsData}
                                            components={{
                                                Option: CustomOption,
                                                SingleValue: CustomSingleValue,
                                            }}
                                            className="my-2 z-index-3 h-40px"
                                        />
                                    }
                                </div>
                            </div>
                            :
                            <div className="card-header p-3" style={{ background: "white" }}>
                                <div className="px-2 d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center gap-5">
                                        <i className="p-3 cursor-pointer fas fa-times fs-3" onClick={() => setSelectChatWindow([])}></i>
                                        <div className="mb-0 text-dark fs-3">{selectChatWindow.length} {intl.formatMessage({ id: "FORM.LABEL.SELECTED_DATA" })}</div>
                                    </div>
                                    <svg onClick={() => setShowAssignContactLabelModal(true)} viewBox="0 0 24 24" height="24" width="24" className="cursor-pointer me-3" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: "#a1a5b7" }}>
                                        <path fill="currentColor" fillRule="evenodd" clip-rule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                    </svg>
                                </div>
                            </div>
                    }
                    <div className="card-body px-3 pt-0" style={isLoading ? { height: '100vh' } : { height: '100%' }}>
                        {
                            isLoading &&
                            <div className="loading-overlay">
                                <div className="loader"></div>
                            </div>
                        }
                        {
                            (recentChatIsSyncing || messagesIsSyncing || persistedMessagesCount < totalChats) &&
                            <div id="sync-label" className="text-center">
                                <span className="badge badge-light-primary fs-7 fw-bold">
                                    Synchronizing &nbsp;
                                    {
                                        persistedMessagesCount < totalChats &&
                                        `${customNumberFormat(persistedMessagesCount)} / ${customNumberFormat(totalChats)}`
                                    }
                                </span>
                            </div>
                        }
                        {
                            isWaitingForNetwork &&
                            <div id="network-status" className="alert alert-warning text-center mt-2">
                                Waiting for network...
                            </div>
                        }
                        {
                            filteredRecentChatHistories.length === 0 && search.trim() !== '' ?
                                <div className="d-flex align-items-center justify-content-center">
                                    <span className="fs-1 fw-bold my-2">No results</span>
                                </div>
                                :
                                <ChatList chatMerchants={chatMerchants} />
                        }
                    </div>
                </>
            }

            {
                showAssignContactLabelModal && <AssignContactLabel dataSelect={selected} setSelected={setSelected} />
            }
        </div>
    )
}

export { ChatSidebar }