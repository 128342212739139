import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { insertProgress } from "../../api/ProgressesCRUD";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import { SelectOption } from "../../interfaces/SelectOption";
import { CompactPicker, SketchPicker } from "react-color";
import { KTSVG } from "../../_metronic/helpers";
import { handleCaps, randomColor } from "../../functions/general";
import { SubProgress } from "../../interfaces/Progress";
import { useIntl } from "react-intl";
import { useSettingsStore } from "../../stores/SettingsStore";
import { Switch } from "@mui/material";
import useHandleCloseModal from "../../hooks/HandleCloseModal";

const AddProgress: FC = () => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [color, setColor] = useState('')
    const [subProgress, setSubProgress] = useState<SubProgress[]>([])
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const { tablesData, setTablesData } = useDataTableStore()
    const token = useAccessToken()
    const [checkSubProgressInput, setCheckSubProgressInput] = useState(false)
    const [allowedLog, setAllowedLog] = useState(false);

    const intl = useIntl()
    const { settings } = useSettingsStore()

    const handleSwitchLogChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('update_log_hpp_progress', event.target.checked);
    };

    const handleSwitchAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('isRequiredAttachment', event.target.checked);
    };

    const handleSwitchSplittable = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('isSplittable', event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            progressType: '',
            color: color,
            update_log_hpp_progress: false,
            isRequiredAttachment: false,
            isSplittable: false
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            progressType: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.PROGRESS_TYPE" }) })),
            color: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.COLOR" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)
            setSubmitting(true)

            const newDataSubProgress = subProgress.map(data => {
                const { id, ...rest } = data;
                return rest;
            });

            const checkEmptyName = newDataSubProgress.some(data => data.name === "");
            const hasDuplicateName = newDataSubProgress.some((data, index, array) => array.findIndex(i => i.name === data.name) !== index);

            if (checkEmptyName) {
                Swal.fire({
                    icon: 'error',
                    text: intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.SUBPROGRESS' }) }),
                    heightAuto: false,
                    confirmButtonText: 'Ok'
                })

                setModalBtnLoading(false)
                setSubmitting(false)
                return false
            }

            if (hasDuplicateName) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: intl.formatMessage({ id: 'FORM.VALIDATION.DUPLICATE' }, { title: intl.formatMessage({ id: 'FORM.LABEL.SUBPROGRESS' }) }),
                    heightAuto: false,
                    confirmButtonText: 'Ok'
                })

                setModalBtnLoading(false)
                setSubmitting(false)
                return false
            }

            try {
                const response = await insertProgress(values.name, values.progressType, values.color, newDataSubProgress, token, values.isRequiredAttachment, values.update_log_hpp_progress, values.isSplittable)
                if (response.data.success) {
                    Swal.fire({
                        icon: "success",
                        title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                        text: intl.formatMessage({ id: "FORM.VALIDATION.ADD_SUCCESS" }),
                        heightAuto: false,
                    })
                    closeModalRef.current?.click();
                    setTablesData('lead_progresses', ([...tablesData.lead_progresses, response.data.data]))
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
                setSubmitting(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            formik.resetForm()
            setShowModal(false)
            setSubProgress([])
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        setColor(randomColor())
    }, [showModal, setShowModal])

    const progressTypes: SelectOption[] = [
        {
            value: "ONGOING",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.ONGOING" })
        },
        {
            value: "SUCCESS",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.SUCCESS" })
        },
        {
            value: "FAIL",
            label: intl.formatMessage({ id: "PROGRESS.TYPE.FAIL" })
        }
    ]

    const handleAddSubProgress = () => {
        setSubProgress([...subProgress, { id: '', name: '' }])
    }

    const handleChangeSubProgress = (value: string, key: number) => {
        subProgress.map((data: SubProgress, index: number) => {
            if (index === key) subProgress[index].name = value
        })

        setSubProgress([...subProgress]);
    }

    const handleDeleteSubProgress = (key: number) => {
        subProgress.splice(key, 1);
        setSubProgress([...subProgress]);
    }

    const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

    return (
        <div>
            <button type="button"
                className="btn btn-primary mt-3"
                data-bs-toggle="modal"
                data-bs-target="#tambah-progress-modal"
                onClick={() => setShowModal(true)}
            >
                <i className="fas fa-plus"></i> {intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.PROGRESSES' })}
            </button>
            <div className="modal fade" tabIndex={-1} id="tambah-progress-modal" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: 'FORM.ACTION.ADD' })} {intl.formatMessage({ id: 'FORM.MENU.PROGRESSES' })}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={handleCloseModal}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body">
                                {formik.status && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.NAME' })}</label>
                                <div className="input-group mb-3">
                                    <input
                                        placeholder={intl.formatMessage({ id: 'FORM.MENU.PROGRESSES' })}
                                        {...formik.getFieldProps('name')}
                                        className={clsx(
                                            'form-control form-control-lg',
                                            { 'is-invalid': formik.touched.name && formik.errors.name },
                                        )}
                                        type='text'
                                        name='name'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.name}</span>
                                    </div>
                                )}
                                <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.PROGRESS_TYPE' })}</label>
                                <Select
                                    key={nanoid()}
                                    options={progressTypes}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.PROGRESS_TYPE' })}`}
                                    value={progressTypes.find(option => option.value === formik.values.progressType)}
                                    onChange={
                                        option => formik.handleChange({
                                            target: {
                                                name: "progressType",
                                                value: option?.value
                                            }
                                        })
                                    }
                                    onBlur={
                                        () => formik.values.progressType === "" && formik.setFieldTouched("progressType", true)
                                    }
                                    className="w-100 mb-3"
                                />
                                {formik.touched.progressType && formik.errors.progressType && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.progressType}</span>
                                    </div>
                                )}
                                <div className="m-0">
                                    <div className="d-flex align-items-center collapsible py-3 toggle mb-0 collapsed" data-bs-toggle="collapse" data-bs-target="#kt_color" aria-expanded="true">
                                        <div className="me-1">
                                            <span className="svg-icon toggle-on svg-icon-primary svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black"></path>
                                                </svg>
                                            </span>
                                            <span className="svg-icon toggle-off svg-icon-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black"></path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className="d-flex align-items-center flex-wrap">
                                            <label className='form-label m-0 fs-6 fw-bolder text-dak required'>{intl.formatMessage({ id: 'FORM.LABEL.COLOR' })}</label>
                                            <div style={{ backgroundColor: formik.values.color, width: '20px', height: '20px', borderRadius: '6px', marginLeft: "10px" }}></div>
                                        </div>
                                    </div>
                                    <div id="kt_color" className="fs-6 ms-10 collapse">
                                        <div className="input-group mb-3">
                                            <SketchPicker
                                                color={formik.values.color}
                                                onChange={color => {
                                                    formik.handleChange({
                                                        target: {
                                                            name: "color",
                                                            value: color.hex
                                                        }
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.ATTACHMENT' })}</label>
                                    <div>
                                        <Switch
                                            checked={formik.values.isRequiredAttachment}
                                            onChange={handleSwitchAttachment}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        <label className='form-label fs-6 text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.REQUIRED' })}</label>
                                    </div>
                                </div>
                                {
                                    formik.values.progressType === "SUCCESS" && (
                                        <div className="d-flex justify-content-between mb-3">
                                            <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.SPLITTABLE_TERMIN' })}</label>
                                            <div>
                                                <Switch
                                                    checked={formik.values.isSplittable}
                                                    onChange={handleSwitchSplittable}
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                {settings.update_log_hpp_progress && (
                                    <div className="d-flex justify-content-between mb-3">
                                        <label className='form-label fs-6 fw-bolder text-dark align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.UPDATE_PRODUCT_HPP' })}</label>
                                        <div>
                                            <Switch
                                                checked={formik.values.update_log_hpp_progress}
                                                onChange={handleSwitchLogChange}
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="d-flex justify-content-between mb-3">
                                    <label className='form-label fs-6 fw-bolder text-dark mb-0 align-self-center'>{intl.formatMessage({ id: 'FORM.LABEL.SUBPROGRESS' })}</label>
                                    <button type="button" onClick={() => handleAddSubProgress()} className="btn btn-light-dark btn-rounded" style={{ padding: "5px 20px" }}>
                                        <i className="fas fa-plus pe-3"></i>
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </button>
                                </div>
                                {
                                    subProgress.map((data: SubProgress, index: number) => (
                                        <div className="mb-3 d-flex">
                                            <input
                                                className='form-control form-control-sm me-3'
                                                type='text'
                                                autoComplete='off'
                                                value={data.name}
                                                onChange={(e) => handleChangeSubProgress(e.target.value, index)}
                                                onInput={(e) => handleCaps(e)}
                                            />
                                            <button type="button" className="btn btn-icon btn-light-youtube" onClick={() => handleDeleteSubProgress(index)}>
                                                <i className="fas fa-trash fs-4"></i>
                                            </button>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light d-none"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={handleCloseModal}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        {intl.formatMessage({ id: 'FORM.ACTION.ADD' })}
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AddProgress }