import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type LanguageState = {
    locale: 'id' | 'en';
    setLocale: (locale: 'id' | 'en') => void;
}

export const useLanguageStore = create<LanguageState>()(
    persist(
        (set) => ({
            locale: 'id',
            setLocale: (locale: 'id' | 'en') => set({ locale }),
        }),
        {
            name: 'language-store',
            storage: createJSONStorage(() => localStorage),
        }
    )
)