import React, { useEffect } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { customNumberFormat } from '../../../functions/general'
import { useEmailStore } from '../../../stores/EmailStore'
import { useUserStore } from '../../../hooks/UserEmail'

interface SideBarMobileProps {
  activeSidebar: {
    inbox: string
    sent: string
    draft: string
    trash: string
    archive: string
    spam: string
    groupEmail: string
    broadcastEmail: string
  }
  qtySidebar: {
    inbox: number
    draft: number
    trash: number
    archive: number
    spam: number
  }
  btnLogoutEmail: () => void
}

const SideBarMobile: React.FC<SideBarMobileProps> = ({
  activeSidebar,
  qtySidebar,
  btnLogoutEmail,
}) => {
  const { showSideBar, setShowSideBar, setTypeEmail, setTypeMenu, setActiveSidebar } = useEmailStore()
  const { userEmail } = useUserStore()

  const handleShowSideBar = () => {
    setShowSideBar((prevState) => !prevState)
  }

  return (
    <>
      {showSideBar && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            zIndex: 999,
          }}
          onClick={handleShowSideBar}
        />
      )}
      <div
        className={`bg-white flex-row-auto w-260px w-lg-275px ${showSideBar ? 'show-bar' : 'hide-bar'
          }`}
      >
        <div className='card card-flush' style={{ height: '100dvh', borderRadius: 0, overflowY: 'auto' }}>
          <span
            className='d-flex justify-content-center align-items-center cursor-pointer m-3'
            onClick={() => {
              setTypeEmail('listEmail')
              setTypeMenu('inbox')
              setActiveSidebar({
                inbox: 'active',
                sent: '',
                draft: '',
                trash: '',
                archive: '',
                spam: '',
                groupEmail: '',
                broadcastEmail: '',
              })
            }}
          >
            <img
              src='/media/logos/wooblazz-email.png'
              alt='Wooblazz Mail Logo'
              style={{
                maxWidth: '250px',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
            {showSideBar && (
              <span onClick={handleShowSideBar}>
                <img width={25} src='/media/icons/duotune/arrows/arr079.svg' alt='hide' />
              </span>
            )}
          </span>
          <div className='card-body'>
            <span
              className='btn btn-primary d-flex justify-content-center align-content-center gap-3 text-uppercase w-100 mb-10'
              onClick={() => {
                setTypeEmail('sendEmail')
                setTypeMenu('sendMail')
                setActiveSidebar({
                  inbox: '',
                  sent: '',
                  draft: '',
                  trash: '',
                  archive: '',
                  spam: '',
                  groupEmail: '',
                  broadcastEmail: '',
                })
              }}
            >
              <img width={20} src='/media/svg/general/pen.svg' alt='icon-pen' />
              <span className='my-auto'>Pesan Baru</span>
            </span>
            <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10'>
              <div className='menu-item mb-3'>
                <span
                  className={`menu-link ${activeSidebar.inbox}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('inbox')
                    setActiveSidebar({
                      inbox: 'active',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com010.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Kotak Masuk</span>
                  {qtySidebar.inbox > 0 && (
                    <span className='badge badge-light-success'>
                      {customNumberFormat(qtySidebar.inbox)}
                    </span>
                  )}
                </span>
              </div>
              <div className='menu-item mb-3'>
                <span
                  className={`menu-link ${activeSidebar.sent}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('sent')
                    setActiveSidebar({
                      inbox: '',
                      sent: 'active',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen016.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Terkirim</span>
                  {/* {qtySidebar.sent > 0 && (
                    <span className='badge badge-light-primary'>
                      {customNumberFormat(qtySidebar.sent)}
                    </span>
                  )} */}
                </span>
              </div>
              <div className='menu-item mb-3'>
                <span
                  className={`menu-link ${activeSidebar.draft}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('draft')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: 'active',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil003.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Draf</span>
                  {qtySidebar.draft > 0 && (
                    <span className='badge badge-light-info'>
                      {customNumberFormat(qtySidebar.draft)}
                    </span>
                  )}
                </span>
              </div>
              <div className='menu-item mb-3'>
                <span
                  className={`menu-link ${activeSidebar.spam}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('spam')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: 'active',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Spam</span>
                  {qtySidebar.spam > 0 && (
                    <span className='badge badge-light-warning'>
                      {customNumberFormat(qtySidebar.spam)}
                    </span>
                  )}
                </span>
              </div>
              <div className='menu-item mb-3'>
                <span
                  className={`menu-link ${activeSidebar.archive}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('archive')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: 'active',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen056.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Arsip</span>
                  {qtySidebar.archive > 0 && (
                    <span className='badge badge-light-dark'>
                      {customNumberFormat(qtySidebar.archive)}
                    </span>
                  )}
                </span>
              </div>
              <div className='menu-item'>
                <span
                  className={`menu-link ${activeSidebar.trash}`}
                  onClick={() => {
                    setTypeEmail('listEmail')
                    setTypeMenu('trash')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: '',
                      trash: 'active',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: '',
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Sampah</span>
                  {qtySidebar.trash > 0 && (
                    <span className='badge badge-light-danger'>
                      {customNumberFormat(qtySidebar.trash)}
                    </span>
                  )}
                </span>
              </div>
            </div>
            <div
              style={{ borderTop: '0.8px solid #E4E4E4', borderBottom: '0.8px solid #E4E4E4' }}
              className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary py-3 gap-3'
            >
              <div className='menu-item'>
                <span
                  className={`menu-link ${activeSidebar.broadcastEmail}`}
                  onClick={() => {
                    setTypeEmail('broadcastEmail')
                    setTypeMenu('broadcastEmail')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: '',
                      broadcastEmail: 'active'
                    })
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com002.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Broadcast Email</span>
                </span>
              </div>
              <div className='menu-item'>
                <span
                  className={`menu-link ${activeSidebar.groupEmail}`}
                  onClick={() => {
                    setTypeEmail('groupEmail')
                    setTypeMenu('groupEmail')
                    setActiveSidebar({
                      inbox: '',
                      sent: '',
                      draft: '',
                      trash: '',
                      archive: '',
                      spam: '',
                      groupEmail: 'active',
                      broadcastEmail: ''
                    })
                    // navigate('/email/group-contact')
                  }}
                >
                  <span className='menu-icon'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com014.svg'
                      className='svg-icon svg-icon-2 me-3'
                    />
                  </span>
                  <span className='menu-title fw-bolder'>Group Email</span>
                </span>
              </div>
            </div>
            <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary py-3 gap-3'>
              <div className='px-3 mt-5 d-block d-md-none'>
                <span className='svg-icon svg-icon-2 me-5'>
                  <KTSVG
                    path='/media/icons/duotune/communication/com013.svg'
                    className='svg-icon-2'
                  />
                </span>
                <span className='menu-title fw-bold opacity-50'>{userEmail?.email}</span>
              </div>
              <div className='menu-item mt-0 mt-md-5' onClick={() => btnLogoutEmail()}>
                <div className='menu-link'>
                  <span className='menu-icon'>
                    <span className='svg-icon svg-icon-2 me-3'>
                      <KTSVG path='/media/icons/duotune/arrows/arr077.svg' className='svg-icon-2' />
                    </span>
                  </span>
                  <span className='menu-title fw-bold'>Keluar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SideBarMobile
