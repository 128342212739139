import React, { useEffect } from 'react';
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import { getFileType } from '../../../functions/general';

interface PreviewAttachmentProps {
  files: File | File[];
  pipelineId: string;
}

export const isImageUrl = (filename: string): boolean => {
  // Daftar ekstensi file gambar yang umum
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];

  // Ambil ekstensi dari filename
  const extension = filename.split('.').pop()?.toLowerCase();

  // Cek apakah ekstensi termasuk dalam daftar ekstensi gambar
  return extension ? imageExtensions.includes(extension) : false;
};

interface PreviewAttachmentProps {
  files: File | File[];
  pipelineId: string;
  onClose: () => void; // Add new prop
}

const PreviewAttachmentTermin: React.FC<PreviewAttachmentProps> = ({ files, pipelineId, onClose }) => {
  const intl = useIntl();
  const isMultipleFiles = Array.isArray(files);

  return (
    <div
      className="modal-custom"
      onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1060
      }}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        onClick={e => e.stopPropagation()}
        style={{
          width: '90%',
          maxWidth: '400px',
        }}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">
              {isMultipleFiles ? 'Attachments' : (files as File).name}
            </h3>
            <button
              type="button"
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              onClick={onClose}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr061.svg"
                className="svg-icon svg-icon-2x"
              />
            </button>
          </div>

          <div className="modal-body text-center w-100">
            {!isMultipleFiles ? (
              // Single file preview
              isImageUrl(files.name) ? (
                <img src={URL.createObjectURL(files)} alt={files.name} className="img-fluid" />
              ) : (
                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2 mb-3">
                  {getFileType(files.name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                    : getFileType(files.name) === "doc" || getFileType(files.name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                      : getFileType(files.name) === "ppt" || getFileType(files.name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                        : getFileType(files.name) === "xlsx" || getFileType(files.name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                          : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                  }
                  <a href={URL.createObjectURL(files)} className="m-3" target="_blank" rel="noopener noreferrer">
                    {files.name}
                  </a>
                </div>
              )
            ) : (
              // Multiple files preview
              <div className="d-flex flex-column gap-3">
                {files.map((file, index) => (
                  <div key={index} className="d-flex align-items-center border border-dashed border-gray-300 rounded ps-2">
                    {getFileType(file.name) === "pdf" ? <img src={toAbsoluteUrl('/media/logos/pdf.png')} alt="" style={{ width: "20px" }} />
                      : getFileType(file.name) === "doc" || getFileType(file.name) === "docx" ? <img src={toAbsoluteUrl('/media/logos/doc.png')} alt="" style={{ width: "20px" }} />
                        : getFileType(file.name) === "ppt" || getFileType(file.name) === "pptx" ? <img src={toAbsoluteUrl('/media/logos/ppt.png')} alt="" style={{ width: "20px" }} />
                          : getFileType(file.name) === "xlsx" || getFileType(file.name) === "xls" ? <img src={toAbsoluteUrl('/media/logos/xls.png')} alt="" style={{ width: "20px" }} />
                            : <img src={toAbsoluteUrl('/media/logos/file.png')} alt="" style={{ width: "20px" }} />
                    }
                    <a href={URL.createObjectURL(file)} className="m-3" target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              onClick={onClose}
            >
              {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewAttachmentTermin;