import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  const [isPageReady, setIsPageReady] = useState(false)

  // Fungsi untuk menangani navigasi dan memuat data
  const handleNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: string) => {
    event.preventDefault()
    setTimeout(() => {
      setIsPageReady(false) // Set halaman belum siap
      navigate(link, { replace: true }) // Navigasi ke halaman baru
    }, 10)
  }

  useEffect(() => {
    // Set halaman siap setelah rute berubah
    setIsPageReady(true)
  }, [pathname])

  return (
    <div className='menu-item'>
      <Link
        replace
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        target={(to === '/chat' || to === "/email" || to === "/open-api") ? '_blank' : '_self'}
        onClick={(event) => handleNavigation(event, to)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}

      {/* Indikator Loading Halaman */}
      {/* {!isPageReady && <div className="loading-indicator">Loading...</div>} */}
    </div>
  )
}

export { AsideMenuItem }
