import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const INSERT_BANNER = `${API_URL}company-information`
const UPDATE_BANNER = `${API_URL}company-information`
const DELETE_BANNER = `${API_URL}company-information`
const SORT_BANNER = `${API_URL}company-update-informations-display`

export const insertBanner = async (name: string, banner: string, description: string, token: string) => {
    return axios.post(INSERT_BANNER, {
        name,
        banner,
        description
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const updateBanner = async (id: string, name: string, banner: string, description: string, token: string) => {
    return axios.patch(UPDATE_BANNER + "/" + id, {
        name,
        banner,
        description
    }, {
        headers: {
            'X-Auth-token': token
        }
    })
}

export const deleteBanner = async (id: string, token: string) => {
    return axios.delete(DELETE_BANNER + "/" + id,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}

export const sortBanner = async (companyInformationsId: Array<string>, token: string) => {
    return axios.patch(SORT_BANNER,
        {
            companyInformationsId
        },
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}