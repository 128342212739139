import { FC, useState, useEffect } from "react";
import useGeoLocation from "../../../../hooks/GeoLocation";
import useCamera from "../../../../hooks/Camera";
import { Link } from 'react-router-dom'
import { insertClockInPicture, insertClockOutPicture } from "../../../../api/ResourceAPI";
import { Buffer } from 'buffer';
import { insertClockIn, insertClockOut, insertPhotoAttendance } from "../../../../api/AttendanceCRUD";
import useAccessToken from "../../../../hooks/AccessToken";
import Swal from "sweetalert2";
import { AttendanceDescription } from "../../../../components/Attendance/AttendanceDescription";
import { Modal } from "bootstrap";
import { useNavigate } from 'react-router-dom'
import { CapturePhoto } from "../../../../components/Attendance/CapturePhoto";
import useUser from '../../../../hooks/User'
import { useIntl } from "react-intl";
import { dataURLtoFile, getRandomFileName } from "../../../../functions/general";

const Capture: FC = () => {
    const geolocation = useGeoLocation()
    const camera = useCamera()

    const [img, setImg] = useState(null);

    const [modalBtnLoading, setModalBtnLoading] = useState(false);
    const token = useAccessToken()
    const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);
    const [isClockInLate, setIsClockInLate] = useState<boolean>(false);
    const [isClockOutEarly, setIsClockOutEarly] = useState<boolean>(false);
    const [isOutOfRange, setIsOutOfRange] = useState<boolean>(false);
    const [isUnrecognizedFace, setIsUnrecognizedFace] = useState<boolean>(false);
    const [attendanceId, setAttendanceId] = useState<string>('');
    const navigate = useNavigate()
    const user = useUser()
    const [tries, setTries] = useState<number>(2);
    const intl = useIntl()

    const saveAttendance = async () => {
        setModalBtnLoading(true)
        const file = img ? dataURLtoFile(img, getRandomFileName() + '.jpeg', Buffer) : ""

        try {
            if (file) {
                const photoResponse = await insertPhotoAttendance(
                    user.data.username,
                    file,
                );
                if (photoResponse.data.valid) {
                    if (window.location.pathname === '/attendance/clock-in') {
                        const resourceResponse = await insertClockInPicture(file)

                        const response = await insertClockIn(
                            resourceResponse.data.data[0],
                            '',
                            token,
                            geolocation.coords?.longitude,
                            geolocation.coords?.latitude,
                        );
                        if (response.data.success) {
                            if (response.data.info.isClockInLate || response.data.info.isOutOfRange || response.data.info.isUnrecognizedFace) {
                                setIsClockInLate(response.data.info.isClockInLate)
                                setIsOutOfRange(response.data.info.isOutOfRange)
                                setIsUnrecognizedFace(response.data.info.isUnrecognizedFace)
                                setIsClockOutEarly(false);
                                setAttendanceId(response.data.data.id)
                                setShowDescriptionModal(true)
                            }
                            else {
                                Swal.fire({
                                    icon: 'success',
                                    heightAuto: false,
                                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                    timer: 2000,
                                    timerProgressBar: true
                                })
                                .then(() => {
                                    navigate('/attendance')
                                })
                            }
                        }
                    }
                    else if (window.location.pathname === '/attendance/clock-out') {
                        const resourceResponse = await insertClockOutPicture(file)

                        const response = await insertClockOut(
                            resourceResponse.data.data[0],
                            '',
                            token,
                            geolocation.coords?.longitude,
                            geolocation.coords?.latitude,
                        );
                        if (response.data.success) {
                            if (response.data.info.isClockOutEarly || response.data.info.isOutOfRange || response.data.info.isUnrecognizedFace) {
                                setIsClockOutEarly(response.data.info.isClockOutEarly)
                                setIsOutOfRange(response.data.info.isOutOfRange)
                                setIsUnrecognizedFace(response.data.info.isUnrecognizedFace)
                                setIsClockInLate(false);
                                setAttendanceId(response.data.data.id)
                                setShowDescriptionModal(true)
                            }
                            else {
                                Swal.fire({
                                    icon: 'success',
                                    heightAuto: false,
                                    title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                    timer: 2000,
                                    timerProgressBar: true
                                })
                                .then(() => {
                                    navigate('/attendance')
                                })
                            }
                        }
                    }
                }
                else {
                    throw {
                        response: {
                            data: {
                                message: intl.formatMessage({ id: "ATTENDANCE.ALERT.INVALID_PHOTO" }) + ' ' + intl.formatMessage({ id: "ATTENDANCE.ALERT.RETAKE_PHOTO" })
                            }
                        },
                        error: new Error()
                      };
                }
            }
        } catch (error: any) {
            if (tries > 0) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok'
                })
                .then(() => {
                    setTries(tries - 1);
                    setImg(null)
                })
            } else {
                Swal.fire({
                    title: intl.formatMessage({ id: "ATTENDANCE.ALERT.INVALID_PHOTO" }),
                    text: intl.formatMessage({ id: "ATTENDANCE.ALERT.VALIDATION_PHOTO" }),
                    icon: "error",
                    showCancelButton: !0,
                    buttonsStyling: !1,
                    confirmButtonText: intl.formatMessage({ id: "FORM.ACTION.SAVE_ATTENDANCE" }),
                    cancelButtonText: intl.formatMessage({ id: "FORM.ACTION.RETAKE_PHOTO" }),
                    customClass: {
                        confirmButton: "btn btn-danger",
                        cancelButton: "btn btn-secondary me-2",
                    },
                    reverseButtons: true,
                    heightAuto: false,
                    preConfirm: async () => {
                        try {
                            if (file) {
                                if (window.location.pathname === '/attendance/clock-in') {
                                    const resourceResponse = await insertClockInPicture(file)
            
                                    const response = await insertClockIn(
                                        resourceResponse.data.data[0],
                                        '',
                                        token,
                                        geolocation.coords?.longitude,
                                        geolocation.coords?.latitude,
                                    );
                                    if (response.data.success) {
                                        if (response.data.info.isClockInLate || response.data.info.isOutOfRange || response.data.info.isUnrecognizedFace) {
                                            setIsClockInLate(response.data.info.isClockInLate)
                                            setIsOutOfRange(response.data.info.isOutOfRange)
                                            setIsUnrecognizedFace(response.data.info.isUnrecognizedFace)
                                            setIsClockOutEarly(false);
                                            setAttendanceId(response.data.data.id)
                                            setShowDescriptionModal(true)
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'success',
                                                heightAuto: false,
                                                title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                                timer: 2000,
                                                timerProgressBar: true
                                            })
                                            .then(() => {
                                                navigate('/attendance')
                                            })
                                        }
                                    }
                                }
                                else if (window.location.pathname === '/attendance/clock-out') {
                                    const resourceResponse = await insertClockOutPicture(file)
            
                                    const response = await insertClockOut(
                                        resourceResponse.data.data[0],
                                        '',
                                        token,
                                        geolocation.coords?.longitude,
                                        geolocation.coords?.latitude,
                                    );
                                    if (response.data.success) {
                                        if (response.data.info.isClockOutEarly || response.data.info.isOutOfRange || response.data.info.isUnrecognizedFace) {
                                            setIsClockOutEarly(response.data.info.isClockOutEarly)
                                            setIsOutOfRange(response.data.info.isOutOfRange)
                                            setIsUnrecognizedFace(response.data.info.isUnrecognizedFace)
                                            setIsClockInLate(false);
                                            setAttendanceId(response.data.data.id)
                                            setShowDescriptionModal(true)
                                        }
                                        else {
                                            Swal.fire({
                                                icon: 'success',
                                                heightAuto: false,
                                                title: intl.formatMessage({ id: "ALERT.TITLE.SUCCESS" }),
                                                timer: 2000,
                                                timerProgressBar: true
                                            })
                                            .then(() => {
                                                navigate('/attendance')
                                            })
                                        }
                                    }
                                }
                            }
                        } catch (error: any) {
                            Swal.fire({
                                icon: 'error',
                                title: error.response.data.message,
                                text: intl.formatMessage({ id: "ATTENDANCE.ALERT.TRY_AGAIN" }),
                                confirmButtonText: 'Ok'
                            })
                            .then(() => {
                                setTries(tries - 1);
                                setImg(null)
                                navigate('/attendance')
                            })
                        }
                    }
                }).then((result) => {
                    if (!result.isConfirmed) {
                        setTries(tries - 1);
                        setImg(null)
                    }
                })
            }
        } finally {
            setModalBtnLoading(false)
        }
    };

    useEffect(() => {
        if (showDescriptionModal && (isOutOfRange || isClockInLate || isClockOutEarly || isUnrecognizedFace)) {
        const modalElement = document.getElementById(`description-attendance-modal`);

        if (modalElement) {
            const modalInstance = new Modal(modalElement);
            modalInstance.show();

            // This listener sets showChat to false when the modal is closed
            const handleModalHide = () => {
            setShowDescriptionModal(false);
            };

            // Attach the event listener
            modalElement.addEventListener('hidden.bs.modal', handleModalHide);

            // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
            return () => {
            modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
            };
        }
        }
    }, [showDescriptionModal, isOutOfRange, isClockInLate, isClockOutEarly, isUnrecognizedFace, setShowDescriptionModal]);

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <ol className="breadcrumb text-muted fs-6 fw-bold">
                    <li className="breadcrumb-item pe-3">
                        <Link to={`/attendance`} className="pe-3">
                            {intl.formatMessage({ id: "MENU.ATTENDANCE" })}
                        </Link>
                    </li>
                    <li className="breadcrumb-item text-dark">{window.location.pathname === '/attendance/clock-in' ? intl.formatMessage({ id: "FORM.ACTION.CLOCK_IN" }) : intl.formatMessage({ id: "FORM.ACTION.CLOCK_OUT" })}</li>
                </ol>
            </div>
            {((!geolocation.coords && geolocation.error) || !camera.isCamera)  && (
                <div className='mb-5 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT1' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}<br /><br /><b>{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT2' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}</b><br />{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT3' } )}<br />{intl.formatMessage({ id: 'ATTENDANCE.PERMISSIONS.ALERT4' }, {title: ( !geolocation.coords && geolocation.error && camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION_CAMERA" }) : camera.error ? intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.CAMERA" }) : intl.formatMessage({ id: "ATTENDANCE.PERMISSIONS.LOCATION" }) )})}</div>
                </div>
            )}
            
            {geolocation.coords && camera.isCamera && (
                <CapturePhoto keyString='attendance' img={img!} setImg={setImg!} func={saveAttendance} modalBtnLoading={modalBtnLoading!}></CapturePhoto>
            )}
            {
                showDescriptionModal && <AttendanceDescription id={attendanceId!} isClockInLate={isClockInLate!} isClockOutEarly={isClockOutEarly!} isOutOfRange={isOutOfRange!} isUnrecognizedFace={isUnrecognizedFace!} />
            }
        </>
    );
}

export { Capture }