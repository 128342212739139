import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import useHandleCloseModal from '../../../hooks/HandleCloseModal'
import {FormikProps, useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {KTSVG} from '../../../_metronic/helpers'
import {IComponent, ISection} from '../../../interfaces/Analytics/Analytics'
import clsx from 'clsx'
import {formatTimeByFrame, handleCaps, randomColor} from '../../../functions/general'
import {insertSection} from '../../../api/Analitik/SectionCRUD'
import useAccessToken from '../../../hooks/AccessToken'
import Select from 'react-select'
import {SketchPicker} from 'react-color'
import axios from 'axios'
import useSWR from 'swr'
import {Product} from '../../../interfaces/Product'
import {Label} from '../../../interfaces/Label'
import {useSettingsStore} from '../../../stores/SettingsStore'
import {title} from 'process'
import {ChartConfiguration, ChartOptions} from 'chart.js'
import {Bar, Pie} from 'react-chartjs-2'
import List from './List'
import html2canvas from 'html2canvas'
import {Chart} from 'chart.js'

interface FormValues {
  name: string
  tableName: string
  relationLead: boolean
  defaultValue: string
  colorCode: string
  product: string[]
  progress: string[]
  responsible: string[]
  source: string[]
  progressType: string[]
  coreType: string
  labelProduct: string[]
  labelMeeting: string[]
  labelMerchant: string[]
  labelPipeline: string[]
}

interface IProps {
  component: IComponent
}

type RuleMappingKey =
  | 'labelPipeline'
  | 'labelMerchant'
  | 'labelProduct'
  | 'labelMeeting'
  | 'progressType'
  | 'source'
  | 'responsible'
  | 'progress'
  | 'product'

export default function PopupComponent({component}: IProps) {
  const chartRef = React.useRef(null)

  const modalRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()
  const closeModalRef = useRef<HTMLButtonElement>(null)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
    },
    onSubmit: (values) => {
    },
  })

  const {handleCloseModal} = useHandleCloseModal(modalRef, closeModalRef, formik)

  useEffect(() => {
    // Get the modal element
    const modalElement = modalRef.current

    // Define the event listener
    const handleModalHidden = () => {
      formik.resetForm()
      // setShowModal(false)
    }

    // Attach the event listener
    modalElement?.addEventListener('hidden.bs.modal', handleModalHidden)

    // Cleanup
    return () => {
      modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden)
    }
  }, [formik])

  let labels: any
  let datasets: any
  let options: ChartOptions<'bar'> | ChartOptions<'pie'> | any
  if (component.chartType === 'PIE') {
    labels = component?.dataCore[0]?.data?.map((d: any) => {
      return formatTimeByFrame(d.time, component.timeFrame)
    })

    datasets = component.dataCore.map((core, index) => {
      const randomColors = core.data.map(() => {
        return randomColor()
      })
      return {
        label: core.name,
        data: core.data.map((d) => d.value),
        backgroundColor: randomColors,
      }
    })
    options = {
      plugins: {
        legend: {
          display: true,
          position: 'bottom'
        },
      },
    }
  }

  if (component.chartType === 'BAR') {
    labels = component?.dataCore[0]?.data?.map((d: any) => {
      return formatTimeByFrame(d.time, component.timeFrame)
    })
    datasets = component.dataCore.map((core) => {
      return {
        label: core.name,
        backgroundColor: core.colorCode,
        data: core.data.map((d) => d.value),
      }
    })

    options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom' as const,
        },
        title: {
          display: true,
          text: component.nameComponent,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
    }
  }
  let data = {
    labels,
    datasets,
  }

  const componentExportRef = useRef(null)

  const handleExportImage = () => {
    const SCALE = 5
    const element = componentExportRef.current // Get the current value of the ref
    // var w = 1000
    // var h = 1000
    var canvas = document.createElement('canvas')
    // canvas.width = w * SCALE
    // canvas.height = h * SCALE
    // canvas.style.width = w + 'px'
    // canvas.style.height = h + 'px'
    // const context = canvas.getContext('2d')
    // context?.scale(SCALE, SCALE)

    html2canvas(element!).then((canvas) => {
      // Convert the canvas to an image (data URL)
      const imgData = canvas.toDataURL('image/png')

      // Create an anchor element to download the image
      const link = document.createElement('a')
      link.href = imgData
      link.download = component.nameComponent // Set the file name
      link.click() // Trigger the download
    })
  }
  return (
    <>
      <div
        className={`modal fade`}
        tabIndex={-1}
        id={`popup-component-${component.componentId}`}
        ref={modalRef}
      >
        <div className='modal-dialog modal-dialog-centered modal-xl'>
          <div className='modal-content p-4'>
            <div className='modal-header'>
              <h5 className='modal-title'>{component.nameComponent}</h5>
              <button
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                onClick={handleCloseModal}
                ref={closeModalRef}
                data-bs-dismiss='modal'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </button>
            </div>
            <div>
              {component.chartType === 'PIE' && (
                <div ref={componentExportRef}>
                  <Pie options={options} data={data} style={{maxHeight: '600px'}} />
                  <div className='my-5'>
                      <List component={component} usePagination={false}/>
                    </div>
                </div>
              )}
              {component.chartType === 'BAR' && (
                <>
                  <div className=' d-flex justify-content-end mt-4'>
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        if (chartRef && chartRef.current) {
                          ;(chartRef.current as any).resetZoom()
                        }
                      }}
                      className="btn btn-sm btn-icon btn-light"
                    >
                      <i className="bi bi-zoom-out"></i>           
                    </button>
                  </div>
                  <div ref={componentExportRef}>
                    <Bar
                      ref={chartRef}
                      options={options}
                      data={data}
                      style={{maxHeight: '600px'}}
                    />
                    <div className='my-4'>
                      <List component={component} usePagination={false}/>
                    </div>
                  </div>
                </>
              )}
              {component.chartType === 'LIST' && (
                <div ref={componentExportRef}>
                  <List component={component} />
                </div>
              )}
            </div>
            <div className=' my-5 d-flex justify-content-center'>
              <button
                onClick={(e) => {
                  handleExportImage()
                }}
                className={`btn btn-primary me-10 w-25 px-9  `}
              >
                {intl.formatMessage({id: 'FORM.ACTION.DOWNLOAD'})}{' '}
                {intl.formatMessage({id: 'FORM.OPTION.TYPE.IMAGE'})}
              </button>
            </div>

            {/* form */}
          </div>
        </div>
      </div>
    </>
  )
}
