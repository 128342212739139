import { useEffect, useRef, useState } from "react";
import { useChatStore } from "../../stores/ChatStore";
import useAccessToken from "../../hooks/AccessToken";
import { useIntl } from "react-intl";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import useSWR, { mutate } from "swr";
import { Drawer } from 'rsuite'
import 'moment/locale/id';
import useUser from "../../hooks/User";
import { useSettingsStore } from "../../stores/SettingsStore";
import axios from "axios";
import { Pipeline } from "../../interfaces/Pipeline";
import { customNumberFormat, getDateSummary, isColorDarkFromHex } from "../../functions/general";
import { Meeting } from "../../interfaces/Meeting";
import moment from "moment";
import { Tooltip } from 'react-tippy';
import { Modal } from "bootstrap";
import { OverlayTrigger, Tooltip as TooltipBs } from "react-bootstrap";
import { usePipelineStore } from "../../stores/PipelineStore";
import { DetailMeeting } from "../Meetings/DetailMeeting";
import { ChatDetailPipeline } from "./Detail/ChatDetailPipeline";
import { ChatDetailMeeting } from "./Detail/ChatDetailMeeting";
import { useMeetingStore } from "../../stores/MeetingsStore";
import { defaultScope } from "../DataTable/UserScopeModal";

interface Props {
}

const DetailContactChat = ({ }: Props) => {
    const API_URL = process.env.REACT_APP_API_URL
    const { phoneNumber, recentChatHistories, isOpenDetailContact, setIsOpenDetailContact, selectedChatPhone } = useChatStore()
    const token = useAccessToken();
    const intl = useIntl();
    const user = useUser()
    const { multiPipelines, setSelectMultiPipeline } = usePipelineStore()
    const { multiMeetings, setSelectMultiMeeting } = useMeetingStore()
    const { settings } = useSettingsStore()
    const [listPipeliens, setListPipelines] = useState<Pipeline[]>([])
    const [listMeetings, setListMeetings] = useState<Meeting[]>([])
    const [originalListPipelines, setOriginalListPipelines] = useState<Pipeline[]>([]);
    const [originalListMeetings, setOriginalListMeetings] = useState<Meeting[]>([]);
    const [searchPipeline, setSearchPipeline] = useState("");
    const [searchMeeting, setSearchMeeting] = useState("");
    const [pipeline, setPipeline] = useState<Pipeline>();
    const [meeting, setMeeting] = useState<Meeting>();
    const [showDetailPipelineModal, setShowDetailPipelineModal] = useState<boolean>(false);
    const [showDetailMeetingModal, setShowDetailMeetingModal] = useState<boolean>(false);

    const recentChat = recentChatHistories.find(recentChat => `${recentChat.chat_phone}-${recentChat.as}` === phoneNumber)

    let name = ''
    if (recentChat) {
        if (recentChat?.chat_phone !== user.data.phone) {
            if (recentChat?.chat_name) name = recentChat?.chat_name
            else name = recentChat?.chat_phone
        } else {
            name = user.data.name + ' (You)'
        }
    }

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data).catch(error => {
        throw error.response ? error.response.data : error;
    })

    const getPipelineURL = () => {
        if (user.data.is_superadmin) {
            return `${API_URL}leads?scope=all&merchant_phone=${phoneNumber.split('-')[0]}`
        } else {
            return `${API_URL}leads?scope=${defaultScope(user.data.permission_pipeline_read)}&user_id=${user.data.id}&merchant_phone=${phoneNumber.split('-')[0]}`
        }
    }

    const getMeetingURL = () => {
        if (user.data.is_superadmin) {
            return `${API_URL}meetings?scope=all&merchant_phone=${phoneNumber.split('-')[0]}`
        } else {
            return `${API_URL}meetings?scope=${defaultScope(user.data.permission_meeting_read)}&user_id=${user.data.id}&merchant_phone=${phoneNumber.split('-')[0]}`
        }
    }

    const { data: dataPipelines = [], error: dataPipelinesError, isValidating: isValidatingDataPipelines } = useSWR(isOpenDetailContact ? getPipelineURL() : null, fetcher)
    const { data: dataMeetings = [], error: dataMeetingsError, isValidating: isValidatingDataMeetings } = useSWR(isOpenDetailContact ? getMeetingURL() : null, fetcher)

    const getInitialsOrPhonePrefix = (recentChatHistory: { chat_name: string; chat_phone: string }): string => {
        if (recentChatHistory.chat_name) {
            const words = recentChatHistory.chat_name.split(' ');
            const initials = words.length > 1
                ? words.map((word: string) => word[0]).join('')
                : words[0][0];

            return recentChatHistory.chat_name !== recentChatHistory.chat_phone
                ? initials.substring(0, 2).toUpperCase()
                : recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
        }
        return recentChatHistory.chat_phone.substring(0, 2).toUpperCase();
    };

    const returnStatus = (meeting: Meeting) => {
        if (meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })) {
            return <span className="badge badge-info" >{intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' })}</span>
        } else if (meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })) {
            return <span className="badge badge-success" >{intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' })}</span>
        } else if (meeting.status === intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })) {
            return <span className="badge badge-warning" >{intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' })}</span>
        } else {
            return (
                <>
                    {
                        (meeting.meetingStatusNote)?.includes("sistem") ?
                            <Tooltip
                                title={`${intl.formatMessage({ id: 'TOOLTIP.AUTO_CANCEL_SYSTEM' })}`}
                                theme="light"
                                arrow={true}
                                className="btn btn-icon btn-flush w-auto"
                                style={{ cursor: "default", display: "flex" }}
                            >
                                <i className='fas fa-info-circle me-2 fs-7'></i>
                                <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                            </Tooltip>
                            :
                            <span className="badge badge-danger" >{intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' })}</span>
                    }
                </>
            )
        }
    }

    const handleSearchPipeline = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchPipeline(value);

        const filtered = originalListPipelines.filter(
            (item) =>
                String(item.lead_age).toLowerCase().includes(value) ||
                String(item.nominal).toLowerCase().includes(value) ||
                item.merchant_name.toLowerCase().includes(value) ||
                item.owner_name.toLowerCase().includes(value) ||
                item.lead_template_name.toLowerCase().includes(value)
        );

        setListPipelines(filtered);
    };

    const handleSearchMeeting = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchMeeting(value);

        const filtered = originalListMeetings.filter(
            (item) =>
                item.status.toLowerCase().includes(value) ||
                item.owner_name.toLowerCase().includes(value) ||
                item.date_meet.toLowerCase().includes(value)
        );

        setListMeetings(filtered);
    };

    const handleClickDetailPipeline = (data: Pipeline) => {
        const dataTemplate = multiPipelines.find(item => item.id === data.lead_template_id)
        setSelectMultiPipeline(dataTemplate!)
        setPipeline(data)
        setShowDetailPipelineModal(true)
    }

    const handleClickDetailMeeting = (data: Meeting) => {
        const dataTemplate = multiMeetings.find(item => item.id === data.meetingTemplateId)
        setSelectMultiMeeting(dataTemplate!)
        setMeeting(data)
        setShowDetailMeetingModal(true)
    }

    useEffect(() => {
        if (!isValidatingDataPipelines) {
            const pipelienTemps = dataPipelines.map((pipeline: Pipeline) => ({
                ...pipeline,
                lead_age: getDateSummary(pipeline.lead_age),
                nominal: `Rp. ${customNumberFormat(pipeline.nominal)}`
            }));

            setOriginalListPipelines(pipelienTemps)
            setListPipelines(pipelienTemps)

            if (pipeline) {
                const updatePipeline = pipelienTemps.find((item: Pipeline) => item.id === pipeline.id)
                setPipeline(updatePipeline)
            }
        }
    }, [dataPipelines])

    useEffect(() => {
        if (!isValidatingDataMeetings) {
            const meetingTemps = dataMeetings.map((meeting: Meeting) => ({
                ...meeting,
                status: meeting.status === "PLANNED" ? intl.formatMessage({ id: 'MEETING.STATUS.SCHEDULED' }) : meeting.status === "COMPLETED" ? intl.formatMessage({ id: 'MEETING.STATUS.FINISHED' }) : meeting.status === "ONGOING" ? intl.formatMessage({ id: 'MEETING.STATUS.ONGOING' }) : intl.formatMessage({ id: 'MEETING.STATUS.CANCELLED' }),
            }));

            setOriginalListMeetings(meetingTemps)
            setListMeetings(meetingTemps)

            if (meeting) {
                const updateMeeting = meetingTemps.find((item: Meeting) => item.id === meeting.id)
                setMeeting(updateMeeting)
            }
        }
    }, [dataMeetings])

    useEffect(() => {
        if (showDetailPipelineModal && pipeline) {
            const modalElement = document.getElementById(`detail-pipeline-modal-${pipeline.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailPipelineModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailPipelineModal, pipeline, setShowDetailPipelineModal]);

    useEffect(() => {
        if (showDetailMeetingModal && meeting) {
            const modalElement = document.getElementById(`detail-meeting-modal-${meeting.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowDetailMeetingModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDetailMeetingModal, meeting, setShowDetailMeetingModal]);

    return (
        <div className="col-12 col-md-6 col-lg-6 col-xl-5" style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)", overflow: "auto", backgroundColor: "#f5f8fa" }}>
            {
                showDetailPipelineModal ?
                    <ChatDetailPipeline dataPipeline={pipeline!} setShowDetailPipelineModal={setShowDetailPipelineModal} />
                    :
                    showDetailMeetingModal ?
                        <ChatDetailMeeting data={meeting!} setShowDetailMeetingModal={setShowDetailMeetingModal} />
                        :
                        <>
                            <div className="card mb-5">
                                <h3 className="py-3 px-5 d-flex align-items-center gap-3">
                                    <i className="fas fa-times fs-3 text-hover-primary cursor-pointer" onClick={() => setIsOpenDetailContact(false)}></i>
                                    Detail Kontak
                                </h3>
                                <div className="card-body d-flex flex-center flex-column pt-5 px-5 pb-9">
                                    <div className='symbol symbol-45px symbol-circle'>
                                        <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                            <img src={toAbsoluteUrl('/media/logos/logo-wooblazz.png')} className='symbol symbol-45px symbol-circle img-fluid' style={{ padding: "10px" }} alt='profile' />
                                        </span>
                                    </div>
                                    <div className="fs-4 text-gray-800 fw-bolder d-flex gap-2">
                                        {
                                            recentChat && selectedChatPhone.length > 1 &&
                                            <OverlayTrigger
                                                key={`bullet-${recentChat.chat_id}`}
                                                placement='top'
                                                overlay={
                                                    <TooltipBs id='tooltip-labels'>
                                                        <p><b>{recentChat.as}</b></p>
                                                        <span>{selectedChatPhone.find(item => item.phone === recentChat.as)?.ownersName.join(', ')}</span>
                                                    </TooltipBs>}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <span className="bullet bullet-dot h-10px w-10px" style={{ backgroundColor: recentChat.color }}></span>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                        {name}
                                    </div>
                                    <div className="fw-bold text-gray-400 mb-6">{phoneNumber.split('-')[0]}</div>
                                    {
                                        recentChat?.merchant_name && <i className="text-center">{recentChat?.merchant_name}</i>
                                    }

                                    <div className="d-flex gap-4 flex-wrap justify-content-center">
                                        {
                                            recentChat?.labels?.map((label: any, index: number) => (
                                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                    <svg viewBox="0 0 24 24" height="20" width="20" className="me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color, width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                    </svg>
                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>
                                                        {label.name}
                                                    </span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="card-body" style={{ padding: "2rem" }}>
                                    <div className="d-flex align-items-center fs-4 text-gray-800 fw-bolder mb-3 gap-3">
                                        {settings.pipeline_title}
                                        {
                                            listPipeliens.length > 0 && <span className="badge badge-light-success">{listPipeliens.length}</span>
                                        }
                                    </div>
                                    <span className="svg-icon svg-icon-1 position-absolute ms-3 mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                        </svg>
                                    </span>
                                    <input type="search" onChange={handleSearchPipeline} value={searchPipeline} className="form-control form-control-sm form-control-solid ps-12" placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })} />
                                    {
                                        isValidatingDataPipelines ?
                                            <div className="loading-overlay">
                                                <div className="loader"></div>
                                            </div>
                                            :
                                            listPipeliens.length > 0 ?
                                                <div className="mt-3" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                                    {
                                                        listPipeliens.map((pipeline: Pipeline, index: number) => (
                                                            <div key={index} className="d-flex align-items-center flex-wrap flex-grow-1 mb-5 cursor-pointer text-hover-primary" onClick={() => handleClickDetailPipeline(pipeline)}>
                                                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3">
                                                                    <span className="fs-6 fw-bolder">{pipeline.merchant_name}</span>
                                                                    <span className="text-gray-400 fw-bold fs-7 my-1">{pipeline.owner_name}</span>
                                                                    <span className="text-gray-400 fw-bold fs-7">Template: <span className="text-gray-800 fw-bold">{pipeline.lead_template_name}</span></span>
                                                                </div>
                                                                <div className="text-end py-lg-0 py-2">
                                                                    <span className="text-gray-800 fw-bolder fs-5">{pipeline.nominal}</span>
                                                                    <span className="text-gray-400 fs-7 fw-bold d-block">{pipeline.lead_age}</span>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                <h4 style={{ padding: "2rem", textAlign: "center" }}>
                                                    Tidak ada data {settings.pipeline_title}
                                                </h4>
                                    }
                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="card-body" style={{ padding: "2rem" }}>
                                    <div className=" d-flex align-items-center fs-4 text-gray-800 fw-bolder mb-2 gap-3">
                                        {settings.meeting_title}
                                        {
                                            listMeetings.length > 0 && <span className="badge badge-light-success">{listMeetings.length}</span>
                                        }
                                    </div>
                                    <span className="svg-icon svg-icon-1 position-absolute ms-3 mt-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                                        </svg>
                                    </span>
                                    <input type="search" onChange={handleSearchMeeting} value={searchMeeting} className="form-control form-control-sm form-control-solid ps-12" placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })} />
                                    {
                                        isValidatingDataMeetings ?
                                            <div className="loading-overlay">
                                                <div className="loader"></div>
                                            </div>
                                            :
                                            listMeetings.length > 0 ?
                                                <div className="mt-3" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                                    {
                                                        listMeetings.map((meeting: Meeting, index: number) => (
                                                            <div className="d-flex align-items-sm-center mb-5 pe-2 cursor-pointer text-hover-primary" onClick={() => handleClickDetailMeeting(meeting)}>
                                                                <div className="d-flex align-items-center justify-content-between flex-row-fluid flex-wrap">
                                                                    <div className="d-flex flex-column me-2">
                                                                        <span className="text-gray-800 text-hover-primary fs-6 fw-bolder d-flex gap-1">
                                                                            {meeting.owner_name}
                                                                            {meeting.type === 'VISIT' && <span className="badge badge-light-primary" >{intl.formatMessage({ id: 'MEETING.STATUS.VISIT' })}</span>}
                                                                        </span>
                                                                        <span className="text-muted fw-bold d-block fs-7">{moment(meeting.date_meet).format('DD MMMM YYYY HH:mm')}</span>
                                                                        <span className="text-muted fw-bold d-block fs-7">Template: {meeting.meetingTemplateName}</span>
                                                                    </div>
                                                                    {returnStatus(meeting)}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                :
                                                <h4 style={{ padding: "2rem", textAlign: "center" }}>
                                                    Tidak ada data {settings.meeting_title}
                                                </h4>
                                    }
                                </div>
                            </div>
                        </>
            }

            {/* {
                showDetailPipelineModal && <DetailPipeline data={pipeline!} />
            }

            {
                showDetailMeetingModal && <DetailMeeting data={meeting!} />
            } */}
        </div>
    );
};

export { DetailContactChat };
