import { nanoid } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import Select from 'react-select';

interface Props {
    defaultTimezone?: string;
    onChange: (timezone: string) => void;
}

const TimezoneSelect: FC<Props> = ({ defaultTimezone='', onChange }) => {
    const [selectedTimezone, setSelectedTimezone] = useState<string>(defaultTimezone);
    const timezones = moment.tz.names();

    useEffect(() => {
        if (defaultTimezone === '') {
            const userTimezone = moment.tz.guess();
            setSelectedTimezone(userTimezone);
            onChange(userTimezone);
        }
    }, []);

    const options = timezones.map((timezone) => ({
        value: timezone,
        label: timezone,
    }));

    const handleChange = (selectedOption: { value: string; label: string } | null) => {
        if (selectedOption) {
            setSelectedTimezone(selectedOption.value);
            onChange(selectedOption.value);
        }
    };

    return (
        <Select
            key={nanoid()}
            value={{ value: selectedTimezone, label: selectedTimezone }}
            options={options}
            onChange={handleChange}
            placeholder="Pilih Zona Waktu"
            isSearchable
            className='w-100'
        />
    )
}

export default TimezoneSelect;