import { FC, useRef, useEffect, useState } from "react";
import { useDataTableStore } from "../../stores/DataTableStore";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import clsx from "clsx";
import useAccessToken from "../../hooks/AccessToken";
import { insertProgress } from "../../api/ProgressesCRUD";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import { SelectOption } from "../../interfaces/SelectOption";
import moment from "moment";
import { Target } from "../../interfaces/Target";
import { insertTarget } from "../../api/TargetCRUD";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { User } from "../../interfaces/User";
import { UserModel } from "../../app/modules/auth/models/UserModel";
import { RootState } from "../../setup";
import { shallowEqual, useSelector } from "react-redux";
import { DatePicker } from "rsuite";
import { useSettingsStore } from "../../stores/SettingsStore";
import { startOfMonth } from "date-fns";
import { KTSVG } from "../../_metronic/helpers";
import * as ExcelJS from 'exceljs';
import { FileUploader } from "react-drag-drop-files";
import { checkWhatsapp, getListSessions, validationPhoneByGetContact } from "../../api/Whatsapp";
import { ListValidationPhone, ResponseErrorValidationPhoneProp, ResponseSuccessValidationPhoneProp } from "../../interfaces/ValidationPhone";
import { DataTableExport } from "../DataTable/DataTableExport";

interface ImportValidationPhoneProps {
    dataSuccess: ResponseSuccessValidationPhoneProp[]
    dataError: ResponseErrorValidationPhoneProp[]
    setDataSuccess: (data: ResponseSuccessValidationPhoneProp[]) => void
    setDataError: (data: ResponseErrorValidationPhoneProp[]) => void
}


const ImportExcelValidationPhone: FC<ImportValidationPhoneProps> = ({ dataSuccess, dataError, setDataSuccess, setDataError }) => {
    const [modalBtnLoading, setModalBtnLoading] = useState(false)
    const [listData, setListData] = useState<ListValidationPhone[]>([])

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [listSessions, setListSessions] = useState<string[]>([]);
    const [indexUpload, setIndexUpload] = useState(0);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const formik = useFormik({
        initialValues: {
            data: '',
        },
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setSubmitting(true)
            setModalBtnLoading(true)

            setDataSuccess([])
            setDataError([])
            var data1: ResponseSuccessValidationPhoneProp[] = []
            var data2: ResponseErrorValidationPhoneProp[] = []
            for (let i = 0; i < listData.length; i++) {
                setIndexUpload(i);

                const responses = await Promise.all([checkWhatsapp(listData[i].phone)]);
                const response1 = responses[0].data.result.exists;

                if (response1) {
                    for(var j=0; j<listSessions.length; j++){
                        const response2 = await validationPhoneByGetContact(listData[i].phone, listSessions[j]);
    
                        if (response2.data.status) {
                            // if(typeof response2.data.result.tags !== "string") {
                                data1.push({ ...response2.data.result, merchant: listData[i].merchant });
                                break
                            // }
                        } else {
                            data2.push({
                                phone: listData[i].phone,
                                merchant: listData[i].merchant,
                                message: response2.data.message,
                            });
                        }
                    }
                } else {
                    data2.push({ phone: listData[i].phone, merchant: listData[i].merchant, message: "Nomor tidak valid" });
                }
            }

            setDataSuccess([...dataSuccess, ...data1])
            setDataError([...dataError, ...data2])
            localStorage.setItem("data_validation_success", JSON.stringify([...dataSuccess, ...data1]))
            localStorage.setItem("data_validation_error", JSON.stringify([...dataError, ...data2]))
            closeModalRef.current?.click();
            setModalBtnLoading(false);
            setSubmitting(false);
        }
    });

    const handleChange = (e: any) => {
        setListData([]); // Mengosongkan listData saat memulai proses upload
        const file = e;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer: any = reader.result;
            wb.xlsx.load(buffer).then((workbook) => {
                const newData: ListValidationPhone[] = [];
                workbook.eachSheet((sheet, id) => {
                    sheet.eachRow((row: any, rowIndex: number) => {
                        if (rowIndex > 1) {
                            if(!dataSuccess.some((data) => data.phoneNumber === row.values[1])) {
                                newData.push({
                                    phone: row.values[1],
                                    merchant: typeof row.values[2] === "string" ? row.values[2] : row.values[2].text
                                });
                            }
                            // console.log(row.values, rowIndex);
                        }
                    });
                });
                // Setelah selesai membaca semua sheet, update state
                setListData((prevListData) => [...prevListData, ...newData]);
            });
        };
    };

    const handleDrop = (files: any) => {
        // Lakukan sesuatu dengan file, misalnya menyimpannya ke state uploadedFiles
        setUploadedFiles(files);
    };

    const getSession = async () => {
        try {
            const response = await getListSessions();
            if (response.data.status) {
                setListSessions(response.data.sessions)
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            })
        }
    };

    useEffect(() => {
        getSession()

        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listener
        const handleModalHidden = () => {
            setListData([]);
        };

        // Attach the event listener
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);


    return (
        <div>
            <button
                type="button"
                className="btn btn-light-success mt-3"
                data-bs-toggle="modal"
                data-bs-target="#import-excel-modal"
            >
                <i className="fas fa-file-upload fs-2 me-2"></i>Impor Excel
            </button>

            <div className="modal fade" tabIndex={-1} id="import-excel-modal" ref={modalRef} >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Upload Excel</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit} noValidate>
                            <div className="modal-body" style={{ height: "300px" }}>
                                <DataTableExport source={"template-validation-phone"} columns={[]}/>

                                <FileUploader
                                    fileOrFiles={listData.length === 0 ? null : listData}
                                    classes="h-75"
                                    handleChange={handleChange}
                                    onDrop={(files: any) => handleDrop(files)}
                                    name="file"
                                    types={["XLSX", "XLS", "CSV"]}
                                />

                                {
                                    listData.length > 0 &&
                                    <div style={{ padding: "10px" }}>{listData.length} Data akan di upload</div>
                                }
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    Batal
                                </button>
                                <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                    <span className="indicator-label">
                                        Upload
                                    </span>
                                    <span className="indicator-progress">
                                        Loading... &nbsp;
                                        {indexUpload + " / " + listData.length}
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ImportExcelValidationPhone }