import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { customNumberFormat } from "../../functions/general";
import { ListProduct, Pipeline } from "../../interfaces/Pipeline";
import { useIntl } from "react-intl";
import { Modal } from "bootstrap";
import { LeadProductLog } from "./Logs/LeadProductLog";
import { useDataTableStore } from "../../stores/DataTableStore";
import useUser from "../../hooks/User";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCustomeStore } from "../../stores/CustomeStore";
import { title } from "process";

interface DetailProductPipelineProps {
    pipeline: Pipeline
}

const DetailProductPipeline: FC<DetailProductPipelineProps> = ({ pipeline }) => {
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);

    const intl = useIntl()
    const [showLogHppModal, setShowLogHppModal] = useState<boolean>(false);
    const [product, setProduct] = useState<ListProduct>();
    const { setTablesData, setFilteredTablesData } = useDataTableStore();
    const [idBefore, setIdBefore] = useState<string>("");
    const user = useUser()
    const { companyId } = useCustomeStore()

    const checkIsCompanyEsb = () => {
        if (user?.data?.company_id === companyId.esb) {
            return true
        }
    }

    useEffect(() => {
        if (showLogHppModal && product) {
            const modalElement = document.getElementById(`log-hpp-modal-${product.leadProductId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
                if (idBefore !== product.leadProductId) {
                    setTablesData(`lead-product-logs`, [])
                    setIdBefore(product.leadProductId ?? "")
                }

                const handleModalHide = () => {
                    setShowLogHppModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showLogHppModal, product, setShowLogHppModal]);

    return (
        <div>
            <div className="modal fade" tabIndex={-1} id={`detail-product-pipeline-modal-${pipeline.id}`} ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{intl.formatMessage({ id: "FORM.MENU.PRODUCTS_DETAIL" })} {pipeline.merchant_name}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <form noValidate>
                            <div className="modal-body">
                                <div className="table-responsive">
                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                        <thead>
                                            <tr className="fw-bolder">
                                                <th className="min-w-150px">{intl.formatMessage({ id: "FORM.MENU.PRODUCTS" })}</th>
                                                <th className="min-w-140px text-end">{intl.formatMessage({ id: "FORM.LABEL.UNITS" })}</th>
                                                {
                                                    user.data.is_allowed_access_hpp &&
                                                    <th className="min-w-140px text-end">HPP</th>
                                                }
                                                <th className="min-w-140px text-end">{intl.formatMessage({ id: "FORM.LABEL.QUANTITY" })}</th>
                                                <th className="min-w-120px text-end">{checkIsCompanyEsb() ? "Projected revenue" : intl.formatMessage({ id: "FORM.LABEL.PRICE" })}</th>
                                                <th className="min-w-120px text-end">{intl.formatMessage({ id: "FORM.LABEL.VAT" })}</th>
                                                {
                                                    user.data.company_name === "Maspion Square" &&
                                                    <th className="min-w-120px text-end text-nowrap">{intl.formatMessage({ id: "FORM.LABEL.TOTAL_BEFORE_VAT" }, { title: "" })}</th>
                                                }
                                                <th className="min-w-120px text-end">{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</th>
                                                {
                                                    user.data.is_allowed_access_hpp &&
                                                    <th className="text-center"></th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                pipeline.leadProducts.map((product, index) => (
                                                    <tr>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex justify-content-start flex-column">
                                                                    <div className=" d-flex">

                                                                    <span className="text-dark fw-bolder fs-6">{product.productName}</span>
                                                                    <OverlayTrigger
                                                                    // key={`labels-${row.id}`}
                                                                    placement='right'
                                                                    overlay={<Tooltip id='tooltip-labels'>                            
                                                                        {    product?.productLabels?.map((label, index) => (
                                                                                <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'wrap' }}>
                                                                                    <svg viewBox="0 0 24 24" height="20" width="20" className="cursor-pointer me-2" preserveAspectRatio="xMidYMid meet" fill="none" style={{ color: label.color, width: '20px', flexShrink: 0, marginBottom: "auto" }}>
                                                                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z"></path>
                                                                                    </svg>
                                                                                    <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                                        {label.name}
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                        </Tooltip>}
                                                                    >
                                                                        <span
                                                                            // id={`labels-${row.id}`}
                                                                            className='cursor-pointer'
                                                                            data-toggle='tooltip'
                                                                            data-placement='right'
                                                                            title=''
                                                                        >
                                                                            {(product.productLabels?.length && product.productLabels.length > 0) ? (
                                                                                product.productLabels.length === 1 ? (
                                                                                    <svg viewBox="0 0 24 24" height="19" width="20" fill="none" style={{ color: product.productLabels[0].color }} >
                                                                                        <path fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M15.393 5C16.314 5 17.167 5.447 17.685 6.182L21.812 12L21.346 12.657L17.686 17.816C17.166 18.553 16.314 19 15.393 19L5.81 18.992C4.262 18.992 3 17.738 3 16.19V7.81C3 6.261 4.262 5.008 5.809 5.008L15.393 5Z" />
                                                                                    </svg>
                                                                                ) : (
                                                                                    <div className="ps-1" style={{ color: product.productLabels[1].color }}>
                                                                                        <svg width="18px" height="15px" viewBox="0 0 18 15">
                                                                                            <path fill={`${product.productLabels[0].color}`} d="M11.208,3.925H2.236C1.556,3.925,1,4.565,1,5.357v7.213C1,13.362,1.556,14,2.236,14h8.972 c0.414,0,0.785-0.237,1.007-0.604l2.701-4.433L12.215,4.53C11.993,4.162,11.622,3.925,11.208,3.925z" />
                                                                                            <path fill="currentColor" d="M15.541,7.832L13.164,3.93 c-0.423-0.698-1.15-1.142-1.956-1.142H2.595V2.432C2.595,1.64,3.151,1,3.831,1h9.1c0.414,0,0.782,0.237,1.005,0.605l2.696,4.433 L15.541,7.832z" />
                                                                                        </svg>
                                                                                    </div>

                                                                                )
                                                                            ) : <></>}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                    </div>                                                                    
                                                                    {product.productSku &&
                                                                        <span className="text-muted fw-bold text-muted d-block fs-7">SKU : {product.productSku}</span>
                                                                    }
                                                                    {product.productNote &&
                                                                        <span className="text-muted fw-bold text-muted d-block fs-7">{intl.formatMessage({ id: "FORM.LABEL.NOTE" })} : {product.productNote}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-end">
                                                            <span className="fw-bold fs-7">{product.unitName ? product.unitName : "PCS"}</span>
                                                        </td>
                                                        {
                                                            user.data.is_allowed_access_hpp &&
                                                            <td className="text-end">
                                                                <span className="fw-bold fs-7">{product.leadProductHpp || product.leadProductHpp == 0 ? customNumberFormat(product.leadProductHpp) : ""}</span>
                                                            </td>
                                                        }
                                                        <td className="text-end">
                                                            <span className="fw-bold fs-7">{product.leadProductQty === null ? "-" : customNumberFormat(product.leadProductQty)}</span>
                                                        </td>
                                                        <td className="text-end">
                                                            <span className="fw-bold fs-7">{product.leadProductPrice === null ? "-" : customNumberFormat(Math.round(product.leadProductPrice))}</span>
                                                        </td>
                                                        <td className="text-end">
                                                            <span className="fw-bold fs-7">{product.leadProductVatPercentage === null ? "-" : customNumberFormat(product.leadProductVatPercentage)}</span>
                                                        </td>
                                                        {
                                                            user.data.company_name === "Maspion Square"  &&
                                                            <td className="text-end">
                                                                <span className="fw-bold fs-7">
                                                                    {(() => {
                                                                        const isEmptyValue =
                                                                            product.leadProductQty === null || 
                                                                            product.leadProductPrice === null 

                                                                        if (isEmptyValue) {
                                                                            return "-";
                                                                        }

                                                                        const calculatedValue = 
                                                                            Math.round(
                                                                                (product.leadProductQty * product.leadProductPrice) 
                                                                            );

                                                                        return customNumberFormat(calculatedValue);
                                                                    })()}
                                                                </span>
                                                                </td>
                                                        }  
                                                        <td className="text-end">
                                                        <span className="fw-bold fs-7">
                                                            {(() => {
                                                                const isEmptyValue =
                                                                    product.leadProductQty === null || 
                                                                    product.leadProductPrice === null || 
                                                                    product.leadProductVatNominal === null;

                                                                if (isEmptyValue) {
                                                                    return "-";
                                                                }

                                                                const calculatedValue = 
                                                                    Math.round(
                                                                        (product.leadProductQty * product.leadProductPrice) + 
                                                                        (product.leadProductVatNominal ?? 0)
                                                                    );

                                                                return customNumberFormat(calculatedValue);
                                                            })()}
                                                        </span>
                                                        </td>
                                                        {
                                                            user.data.is_allowed_access_hpp &&
                                                            <td className="text-end">
                                                                <button type="button" className="btn btn-sm btn-light-primary text-nowrap" onClick={() => { setShowLogHppModal(true); setProduct(product); }}>{intl.formatMessage({ id: "FORM.ACTION.VIEW_HPP_LOG" })}</button>
                                                            </td>
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                        {user?.data.company_name === "Maspion Square"  &&
                                            <tr>
                                            <td colSpan={5} className="text-end fw-bolder">{intl.formatMessage({ id: "FORM.LABEL.TOTAL_BEFORE_VAT" },{title:""})}</td>
                                            <td className="text-end">
                                            {
                                                customNumberFormat(
                                                    pipeline.leadProducts.reduce((total, data) => {
                                                        const quantity = Number(data.leadProductQty ?? 0);
                                                        const price = Math.round(Number(data.leadProductPrice ?? 0));

                                                        return Math.round(total + (quantity * price ));
                                                    }, 0)
                                                )
                                            }
                                            </td>
                                            </tr>
                                            }
                                            <tr>
                                                <td colSpan={5} className="text-end fw-bolder">{intl.formatMessage({ id: "FORM.LABEL.TOTAL" }, { title: "" })}</td>
                                                <td className="text-end">
                                                {
                                                    customNumberFormat(
                                                        pipeline.leadProducts.reduce((total, data) => {
                                                            const quantity = Number(data.leadProductQty ?? 0);
                                                            const price = Number(data.leadProductPrice ?? 0);
                                                            const vat = Number(data.leadProductVatNominal ?? 0);

                                                            return Math.round(total + (quantity * Math.round(price) + Math.round(vat)));
                                                        }, 0)
                                                    )
                                                }
                                                </td>
                                            </tr>
                                           
                                            
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    data-bs-dismiss="modal"
                                    ref={closeModalRef}
                                >
                                    {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                showLogHppModal && <LeadProductLog product={product!} />
            }
        </div>
    )
}

export { DetailProductPipeline }