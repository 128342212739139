import React, {useState} from 'react'
import {Bar, Pie} from 'react-chartjs-2'
import {ChartOptions} from 'chart.js'
import {IComponent, ISection} from '../../../interfaces/Analytics/Analytics'
import moment from 'moment'
import {Dropdown} from 'rsuite'
import {useIntl} from 'react-intl'
import {useAnalyticsStore} from '../../../stores/Analytics/AnalyticStore'
import List from './List'
import {formatTimeByFrame, randomColor} from '../../../functions/general'
import clsx from 'clsx'
import useModalEffect from '../../../hooks/useModalEffect'
import PopupComponent from './PopupComponent'

interface IProps {
  component: IComponent
  section: ISection
}

export default function ComponentAnalytics({component, section}: IProps) {
  const intl = useIntl()
  const {
    setComponent,
    setShowUpdateComponentModal,
    setSection,
    setShowDeleteComponentModal,
    component: componentFromStore,
  } = useAnalyticsStore()

  let labels: any
  let datasets: any
  let options: ChartOptions<'bar'> | ChartOptions<'pie'> | any
  if (component.chartType === 'PIE') {
    labels = component?.dataCore[0]?.data?.map((d: any) => {
      return formatTimeByFrame(d.time, component.timeFrame)
    })

    datasets = component.dataCore.map((core, index) => {
      const randomColors = core.data.map(() => {
        return randomColor()
      })
      return {
        label: core.name,
        data: core.data.map((d) => d.value),
        backgroundColor: randomColors,
      }
    })
    options = {
      plugins: {
        legend: {
          display: true,
        },
      },
    }
  }

  if (component.chartType === 'BAR') {
    labels = component?.dataCore[0]?.data?.map((d: any) => {
      return formatTimeByFrame(d.time, component.timeFrame)
    })
    datasets = component.dataCore.map((core) => {
      return {
        label: core.name,
        backgroundColor: core.colorCode,
        data: core.data.map((d) => d.value),
      }
    })

    options = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: true,
          text: component.nameComponent,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
    }
  }
  let data = {
    labels,
    datasets,
  }

  const chartRef = React.useRef(null)

  const renderIconButtonSection = (props: any, ref: any) => {
    return (
      <button {...props} ref={ref} className='btn btn-sm btn-icon btn-light'>
        <i className={`fas fs-8 fa-ellipsis-v`}></i>
      </button>
    )
  }

  const [showPopupComponent, setShowPopupComponent] = useState(false)

  useModalEffect({
    showModal: showPopupComponent,
    setShowModal: setShowPopupComponent,
    modalId: `popup-component-${component.componentId}`,
  })

  return (
    <div className={clsx('card position-relative ')}>
      <div className='card-body p-4 d-flex justify-content-between flex-column '>
        <div className='position-absolute top-0 end-0 m-3 d-flex gap-2 justify-content-center align-items-center'>
        {component.chartType === 'BAR'&& 
        (
          <>
          <button
             onClick={(e) => {
               e.stopPropagation()
               if (chartRef && chartRef.current) {
                 ;(chartRef.current as any).resetZoom()
               }
             }}
             className="btn btn-sm btn-icon btn-light"
           >
            <i className="bi bi-zoom-out"></i>           
          </button>
        </>
        )
        }
          <Dropdown renderToggle={renderIconButtonSection} placement='bottomEnd'>
            <Dropdown.Item
              onClick={() => {
                component.dataCore.forEach((core) => {
                  const coreTypeRule = core.rules.find((r) => r.typeFilter === 'coreType')
                  if (coreTypeRule) {
                    core.coreType = coreTypeRule.valueFilter as string
                  }
                })
                setComponent(component)
                setSection(section)
                setShowUpdateComponentModal(true)
              }}
            >
              {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                setComponent(component)
                setShowDeleteComponentModal(true)
              }}
            >
              {intl.formatMessage({id: 'FORM.ACTION.DELETE'})}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e) => {
                setShowPopupComponent(true)
                setComponent(component)
              }}
            >
              {intl.formatMessage({id: 'FORM.ACTION.DETAIL'})}
            </Dropdown.Item>
          </Dropdown>
        </div>

        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{component.nameComponent}</span>
            <span className='text-gray-400 fw-bold'>
              {moment(component.startDate).format('DD MMMM YYYY ')} -{' '}
              {moment(component.endDate).format('DD MMMM YYYY')}
            </span>
          </div>
        </div>

        {component.chartType === 'PIE' && (
          <Pie options={options} data={data} style={{maxHeight: '400px'}} />
        )}
        {component.chartType === 'BAR' && (
         <>
         <Bar ref={chartRef} options={options} data={data} style={{ maxHeight: "400px" }} />
       </>
        )}

        {component.chartType === 'LIST' && <List component={component} usePagination={true} />}
      </div>

      {showPopupComponent && <PopupComponent component={componentFromStore!} />}
    </div>
  )
}
