import { FC, useEffect, useRef, useState } from "react";
import { ChatWindowTitle } from "./ChatWindowTitle";
import { useChatStore } from "../../stores/ChatStore";
import { Message } from "../../interfaces/Chat/Message";
import { ChatFooter } from "./ChatFooter/ChatFooter";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import { LogWidget } from "../Pipelines/Logs/LogWidget";
import { AddPipeline } from "../Pipelines/AddPipeline";
import Swal from "sweetalert2";
import { AddMerchant } from "../Merchants/AddMerchant";
import { ChatMessageList } from '../../components/Chat/ChatMessage/ChatMessageList'
import { ChatAttachmentModal } from "./ChatAttachmentModal";
import storage from "../../db";
import { AddLog } from "../Pipelines/Logs/AddLog";
import useUser from "../../hooks/User";
import { MasterLog } from "../Pipelines/Logs/MasterLog";
import { DetailContactChat } from "./DetailContact";
import ForwardFooter from "./ChatFooter/ForwardFooter";
import { ChatInput } from "./ChatFooter/ChatInput";
import { useSettingsStore } from "../../stores/SettingsStore";
import { sortListChat } from "../../functions/chat";

const ChatWindow: FC = () => {

    const {
        socket, showChat, setShowChat,
        phoneNumber, messages, setMessages,
        isLoading, recentChatSelectedPipeline, editMessage,
        setReplyMessage, setMessage, setPhoneNumber,
        setPersistedMessages, persistedMessages,
        filteredRecentChatHistories,
        setRecentChatHistories, setFilteredRecentChatHistories,
        incomingMessageNotifications, setIncomingMessageNotifications, recentChatHistories,
        attachmentMessageId, attachmentUrl, setMessagesIsSyncing,
        isOpenDetailContact, setIsOpenDetailContact, isOpenCheckBoxForward, setIsOpenCheckBoxForward
    } = useChatStore()
    const user = useUser()
    const { settings } = useSettingsStore()
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const checkData = (settings.chat_show_pic_only && !user.data.is_superadmin) ? recentChatHistories.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phoneNumber)?.merchant_id : recentChatHistories.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phoneNumber)?.merchant_name_active

    // useEffect(() => {
    //     socket.on("chatMessages", ({ client_phone,  chatMessages, chatMessagesOrder }: any) => {
    //         if (Object.keys(chatMessages).length > 0 && chatMessagesOrder.length > 0) {
    //             // if(chatMessages?.map((chat: any) => chat.chat_message).includes("")) return

    //             const bufferMessages: Message[] = [];
    //             chatMessagesOrder.forEach((chatMessageId: string) => {
    //                 const chatMessage = chatMessages[chatMessageId];
    //                 if (chatMessage) {

    //                     const duplicateMessage = persistedMessages[client_phone]?.messages.find(message => message.id === chatMessageId) ?? null;

    //                     if (duplicateMessage) return;
    //                     const newMessage: Message = {
    //                         as: 
    //                         id: chatMessageId,
    //                         id_reference: chatMessage.chat_id_reference,
    //                         phone: chatMessage.chat_phone,
    //                         name: chatMessage.chat_name ?? chatMessage.chat_phone,
    //                         type: chatMessage.chat_phone !== client_phone ? 'out' : 'in',
    //                         text: chatMessage.chat_message,
    //                         time: chatMessage.chat_date,
    //                         attachment: chatMessage.chat_attachment,
    //                         attachment_name: chatMessage.chat_attachment_name,
    //                         is_editing: false,
    //                         is_replying: false,
    //                         is_from_me: chatMessage.chat_from_me,
    //                         sender: chatMessage.sender,
    //                     };
    //                     bufferMessages.push(newMessage);
    //                 }
    //             });
    //             if (bufferMessages.length === 0) {
    //                 return
    //             }
    //             const updatedMessages = [...persistedMessages[client_phone]?.messages ?? [], ...bufferMessages];

    //             setPersistedMessages((prevMessages) => ({
    //                 ...prevMessages,
    //                 [client_phone]: {
    //                     messages: updatedMessages
    //                 }
    //             }));

    //             if (client_phone === phoneNumber) setMessages(() => updatedMessages);

    //             const { filteredRecentChatHistories } = useChatStore.getState();
    //             const newFilteredRecentChatHistories = filteredRecentChatHistories.map(recentChat => {
    //                 if (recentChat.chat_phone === client_phone) {
    //                     return {
    //                         ...recentChat,
    //                         chat_list_count: updatedMessages.length
    //                     };
    //                 }
    //                 return recentChat;
    //             })

    //             if (bufferMessages.length > 0) {
    //                 bufferMessages.forEach(message => {
    //                     if (incomingMessageNotifications[message.phone]) {
    //                         const newUnreadCount = user.data.chat_phone === message.phone ? incomingMessageNotifications[message.phone].unreadCount : incomingMessageNotifications[message.phone].unreadCount + 1;
    //                         const newLatestMessage = message.text;
    //                         setIncomingMessageNotifications((prevState) => ({
    //                             ...prevState,
    //                             [message.phone]: {
    //                                 latestMessage: newLatestMessage,
    //                                 unreadCount: newUnreadCount,
    //                             },
    //                         }));
    //                     } else {
    //                         setIncomingMessageNotifications((prevState) => ({
    //                             ...prevState,
    //                             [message.phone]: {
    //                                 latestMessage: message.text,
    //                                 unreadCount: 1,
    //                             },
    //                         }));
    //                     }
    //                 })
    //             }

    //             const sortedListChat = sortListChat(newFilteredRecentChatHistories);
    //             setRecentChatHistories(() => sortedListChat);
    //             setFilteredRecentChatHistories(() => sortedListChat)
    //             setMessagesIsSyncing(false)
    //         }
    //     })

    //     return () => {
    //         socket.off("chatMessages")
    //     }
    // }, [filteredRecentChatHistories, persistedMessages, phoneNumber, incomingMessageNotifications])

    // useEffect(() => {
    //     socket.on("editChat", (message_id: string) => {
    //         Swal.fire({
    //             icon: 'success',
    //             title: 'Pesan berhasil diubah',
    //         })
    //         const filteredMessages = messages.map((message) => {
    //             if (message.id === message_id) {
    //                 return {
    //                     ...message,
    //                     is_editing: false,
    //                     text: editMessage,
    //                 }
    //             }
    //             return message
    //         })
    //         setMessages(() => filteredMessages)
    //         const newPersistedMessages = persistedMessages[phoneNumber]?.messages.map((message) => {
    //             if (message.id === message_id) {
    //                 return {
    //                     ...message,
    //                     is_editing: false,
    //                     text: editMessage,
    //                 }
    //             }
    //             return message
    //         })

    //         setPersistedMessages((prevMessages) => ({
    //             ...prevMessages,
    //             [phoneNumber]: {
    //                 messages: newPersistedMessages
    //             }
    //         }));
    //     })

    //     return () => {
    //         socket.off("editChat");
    //     };
    // }, [messages, editMessage])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 765) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        // Initial check
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleBackButton = () => {
        setPhoneNumber('')
        setShowChat(false)
    }

    return (
        <div className="row">
            <div className={`card card-custom ${isMobile && isOpenDetailContact ? "d-none" : isOpenDetailContact ? "col-md-6 col-lg-6 col-xl-7" : "col-12"}`} style={{ maxHeight: "calc(100vh - 1rem)", height: "calc(100vh - 1rem)" }}>
                <div className="card-header min-h-auto p-2">
                    <div className="row w-100 m-0">
                        <div className="col-10 col-xl-11 d-flex">
                            <i className="fas fa-arrow-circle-left d-xl-none mt-3 me-3" onClick={handleBackButton} role="button" style={{ fontSize: "25px" }}></i>
                            <ChatWindowTitle setOpenDetailContact={setIsOpenDetailContact} />
                        </div>
                        <div className="col-2 col-xl-1 d-flex justify-content-end mt-1">
                            {
                                phoneNumber &&
                                <div className="cursor-pointer p-2" style={{ height: "fit-content" }} data-bs-toggle="modal" data-bs-target={`${checkData?.length !== 0 ? '#add-pipeline-modal' : '#add-merchant-modal'}`} >
                                    <i className="fas fa-plus fs-3 text-hover-primary"></i>
                                </div>
                            }
                            {/* {
                                phoneNumber && recentChatSelectedPipeline &&
                                <div className="cursor-pointer p-2" style={{ height: "fit-content" }} data-bs-toggle="modal" data-bs-target="#logs-modal" >
                                    <i className="fas fa-book fs-3 text-hover-primary"></i>
                                </div>
                            } */}
                        </div>
                    </div>
                </div>
                {
                    isLoading &&
                    <div className="card-body p-0" style={{ zIndex: 1000 }}>
                        <div className="loading-overlay">
                            <div className="loader"></div>
                        </div>
                    </div>
                }

                {
                    showChat && !isLoading ?
                        <>
                            {/* {
                        (messagesIsSyncing || isFirstSyncing) &&
                        <span className="text-primary fs-7 fw-bold text-center mx-auto">
                            Syncing...
                        </span>
                    } */}
                            <ChatMessageList />
                            {/* {isOpenForwardChat ? <ForwardFooter selectedMessages={selectedMessages} onCancel={() => setIsOpenForwardChat(false)} onForward={() => {}} /> : <ChatFooter />} */}
                            {/* {isOpenForwardChat ? <ForwardFooter onCancel={() => setIsOpenForwardChat(false)} onForward={() => {}} /> : <ChatFooter />} */}
                            <div className="card-footer">
                                {isOpenCheckBoxForward ? <ForwardFooter onCancel={() => setIsOpenCheckBoxForward(false)} onForward={() => { }} /> : <ChatInput />}
                            </div>
                        </>
                        :
                        <div className="card-body">
                            <div className="center-overlay">
                                <img src={toAbsoluteUrl('/media/logos/wooblazz-chat.png')} style={{ height: "20%" }} alt="" />
                            </div>
                        </div>
                }

                {
                    recentChatSelectedPipeline && recentChatSelectedPipeline.id &&
                    <div className="modal fade" tabIndex={-1} id="logs-modal">
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <MasterLog lead={recentChatSelectedPipeline} />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* {
                    recentChatSelectedPipeline && recentChatSelectedPipeline.id &&
                    <AddLog lead={recentChatSelectedPipeline} showAddLogButton={false} />
                } */}

                {
                    phoneNumber &&
                    <AddPipeline merchantId={recentChatHistories.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phoneNumber)?.merchant_ids} />
                }
                {
                    phoneNumber &&
                    <AddMerchant chatWindowData={{ name: recentChatHistories.find(recentChatHistory => `${recentChatHistory.chat_phone}-${recentChatHistory.as}` === phoneNumber)?.chat_name!, phone: phoneNumber.split('-')[0], as: phoneNumber.split('-')[1] }} />
                }
                {
                    attachmentMessageId &&
                    <ChatAttachmentModal messageId={attachmentMessageId} url={attachmentUrl} />
                }
            </div>
            {
                isOpenDetailContact && <DetailContactChat />
            }
        </div>
    )
}

export { ChatWindow }