import { FC } from "react";
// import { Link } from "react-router-dom";
import { Link, Element, Events, scrollSpy } from 'react-scroll';
import { DescriptionProps, QueryParameter, RequestBody } from "../../interfaces/OpenAPI";
import { useIntl } from "react-intl";



const Description: FC<DescriptionProps> = ({ id, name, endpoint, description, method, token, queryParameters, requestBody }) => {
    const intl = useIntl()

    return (
        <div>
            <h1 className="fs-2 text-primary" style={{ paddingTop: "5rem" }}>{name}</h1>
            <div id="api-endpoint-info">
                <p><strong>URL:</strong> {endpoint}</p>
                <p><strong>{intl.formatMessage({ id: "OPEN_API.DESCRIPTION" })}:</strong> {description}</p>
                <p><strong>Method:</strong> {method}</p>
                {
                    id !== "login" && id !== "sendOTP" &&
                    <>
                        <p><strong>Headers:</strong></p>
                        <div className="ms-10">
                            <p><strong>Accept:</strong> */*</p>
                            <p><strong>Content-Type:</strong> application/json</p>
                            <p><strong>X-Auth-token:</strong> {`<<${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}>>`}</p>
                        </div>
                    </>
                }
            </div>
            {
                queryParameters.length > 0 &&
                <div id="api-endpoint-parameters">
                    <h1 className="fs-2 text-primary mt-10 mb-0">Query Parameters</h1>
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-7 text-gray-800">
                                <th>{intl.formatMessage({ id: "OPEN_API.FIELD" })}</th>
                                <th style={{ width: "150px" }}>{intl.formatMessage({ id: "OPEN_API.TYPE" })}</th>
                                <th>{intl.formatMessage({ id: "OPEN_API.DESCRIPTION" })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                queryParameters.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.field}</td>
                                        <td>{data.type}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>
                                            {data.description + " "}
                                            {data.target && <Link to={data.target} spy smooth={true} duration={500} className="text-primary cursor-pointer">{data.target_name}</Link>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }

            {
                requestBody.length > 0 &&
                <div id="api-endpoint-parameters">
                    <h1 className="fs-2 text-primary mt-10 mb-0">Request Body</h1>
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-7 text-gray-800">
                                <th>{intl.formatMessage({ id: "OPEN_API.FIELD" })}</th>
                                <th style={{ width: "150px" }}>{intl.formatMessage({ id: "OPEN_API.TYPE" })}</th>
                                <th>{intl.formatMessage({ id: "OPEN_API.DESCRIPTION" })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                requestBody.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.field}</td>
                                        <td>{data.type}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>
                                            {data.description + " "}
                                            {data.target && <Link to={data.target} spy smooth={true} duration={500} className="text-primary cursor-pointer">{data.target_name}</Link>}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div >
    )
}

export { Description }