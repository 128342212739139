import { FC, useEffect, useRef, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import { SelectOption } from "../../interfaces/SelectOption";
import useAccessToken from "../../hooks/AccessToken";
import axios from "axios";
import useSWR, { mutate } from "swr";
import Select from "react-select";
import useUser from "../../hooks/User";
import { TopUserFilter } from "../../interfaces/TopUserFilter";
import { Role } from "../../interfaces/Role";
import { useIntl } from "react-intl";
import { useSettingsStore } from "../../stores/SettingsStore";


interface TopUserFilterModalProps {
    role_id: string
    setRole_id: (role_id?: string) => void
    filter: 'nominal' | 'progress' | 'product' | 'meeting'
    setFilter: (filter: TopUserFilter) => void
}

const TopUserFilterModal: FC<TopUserFilterModalProps> = ({ role_id, setRole_id, filter, setFilter }) => {
    const user = useUser()
    const token = useAccessToken()
    const { settings } = useSettingsStore()

    const closeButtonRef = useRef<HTMLButtonElement | null>(null);
    const saveButtonRef = useRef<HTMLButtonElement | null>(null);

    const [selectedRole_id, setSelectedRole_id] = useState<string | undefined>('all')
    const [selectedFilter, setSelectedFilter] = useState<'nominal' | 'progress' | 'product' | 'meeting'>(filter)
    const [rolesData, setRolesData] = useState<SelectOption[]>([])
    const intl = useIntl()

    const topUserFilterOptions: SelectOption[] = [
        { value: TopUserFilter.Nominal, label: intl.formatMessage({ id: "FORM.LABEL.TOTAL_REVENUE" }) },
        { value: TopUserFilter.Product, label: intl.formatMessage({ id: "FORM.LABEL.PRODUCT_SOLD" }) },
        { value: TopUserFilter.Meeting, label: intl.formatMessage({ id: "FORM.LABEL.COUNT_MEETING" }, { title: settings.meeting_title }) },
        { value: TopUserFilter.Progress, label: intl.formatMessage({ id: "FORM.LABEL.PROGRESS" }) },
    ]

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getRoleURL = () => {
        return `${API_URL}roles`
    }

    const { data: roles = [], error: rolesError, isLoading: rolesLoading } = useSWR(getRoleURL(), fetcher)

    useEffect(() => {
        if (!rolesLoading) {
            const data = roles.map((role: Role) => ({
                value: role.id,
                label: role.name
            }));

            // add all role option
            data.unshift({
                value: 'all',
                label: intl.formatMessage({ id: "FILTER.SCOPE.PHRASE.ALL" }, { title: intl.formatMessage({ id: "FORM.LABEL.ROLE" }) })
            })

            setRolesData(data);
        }
    }, [roles]);

    return (
        <>
            <button type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#top-user-filter-modal"
            >
                <i className="fas fa-filter"></i>
                Filter
            </button>
            <div className="modal fade" tabIndex={-1} id="top-user-filter-modal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header justify-content-end">
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            <label className='form-label fs-6 fw-bolder text-dark'>Filter</label>
                            <div className="mb-10">
                                <Select
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}`}
                                    defaultValue={{ value: 'all', label: `${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} ${intl.formatMessage({ id: "FORM.LABEL.ROLE" })}` }}
                                    value={rolesData.find((role) => role.value === selectedRole_id)}
                                    options={rolesData}
                                    onChange={(value) => {
                                        setSelectedRole_id(value?.value)
                                    }}
                                    required
                                />
                            </div>
                            <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "FORM.LABEL.TOP_USER_RANKINGS" })}</label>
                            <div>
                                <Select
                                    placeholder={`${intl.formatMessage({ id: "FORM.ACTION.CHOOSE" })} Filter`}
                                    value={topUserFilterOptions.find((filter) => filter.value === selectedFilter)}
                                    options={topUserFilterOptions}
                                    onChange={(value) => {
                                        setSelectedFilter(value!.value as TopUserFilter)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeButtonRef}
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => {
                                    setRole_id(user.data.is_superadmin ? rolesData[0].value : user.data.role_id)
                                    setSelectedRole_id(user.data.is_superadmin ? rolesData[0].value : user.data.role_id)
                                    setSelectedFilter(TopUserFilter.Nominal)
                                    setFilter(TopUserFilter.Nominal)
                                    closeButtonRef.current?.click()
                                }}
                            >
                                Reset
                            </button>
                            <button type="button" className="btn btn-primary" ref={saveButtonRef}
                                onClick={() => {
                                    setRole_id(selectedRole_id)
                                    setFilter(selectedFilter as TopUserFilter)
                                    closeButtonRef.current?.click()
                                }}>
                                {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { TopUserFilterModal }