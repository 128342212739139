import { FC, useEffect, useState } from "react";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { Modal } from "bootstrap";
import { UpdateAttendance } from "../../../components/Attendance/UpdateAttendance";
import { AttendanceReport, MyAttendance } from "../../../interfaces/Attendance";
import moment from "moment";
import { AttendanceNote } from "../../../components/Attendance/AttendanceNote";
import { DatePicker } from "rsuite";
import { mutate } from "swr";
import { AttendanceAttachments } from "../../../components/Attendance/AttendanceAttachments";
import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy';
import 'moment-duration-format';
import { ApproveAttendance } from "../../../components/Attendance/ApproveAttendance";
import { calculateHaversineDistance, customDistanceFormat } from "../../../functions/general";
import { MapDistanceAttendance } from "../../../components/Attendance/MapDistanceAttendance";
import { AttendanceInfo } from "../../../components/Attendance/AttendanceInfo";
import { useIntl } from "react-intl";
import { ExportAttendance } from "../../../components/Attendance/ExportAttendance";
import useUser from "../../../hooks/User";
import useCalendarLocale from "../../../hooks/CalendarLocale";

const ReportDailyAttendance: FC = () => {

    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [attendance, setAttendance] = useState<MyAttendance>();
    const [date, setDate] = useState<Date>(new Date());

    const [showNoteModal, setShowNoteModal] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [updateType, setUpdateType] = useState<string>("");
    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false);
    const [fileAttachment, setFileAttachment] = useState<string>('');

    const [showMapModal, setShowMapModal] = useState<boolean>(false);
    const [latitudeMap, setLatitudeMap] = useState<number>();
    const [longitudeMap, setLongitudeMap] = useState<number>();
    const [difference, setDifference] = useState<number>();
    
    const [showExportModal, setShowExportModal] = useState<boolean>(false);

    const intl = useIntl();

    const API_URL = process.env.REACT_APP_API_URL
    const user = useUser()
    const calendarLocale = useCalendarLocale();

    const tableColumns: TableColumn<AttendanceReport>[] = [
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NAME" }),
            width: '150px',
            selector: row => row.userName?.toLowerCase() ?? "-",
            sortable: true,
            cell: row => row.userName
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.START_HOUR" }),
            width: '125px',
            id: 'clockIn',
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => row.userAttendances?.length > 0 && row.userAttendances[0].dateClockIn ?
                <span className="" onClick={() => {
                    setAttendance(row.userAttendances[0])
                    setUpdateType("clockIn")
                    setShowUpdateModal(true);
                }}>{moment(row.userAttendances[0].dateClockIn).format('HH:mm')}{row.userAttendances[0].isClockInChanged && <i className="fas fa-info-circle fs-4 ms-5 text-warning"></i>}</span>
            :
            '-',
            conditionalCellStyles: [
                {
                    when: row => row.userAttendances?.length > 0 && row.userAttendances[0].dateClockIn?.length > 0,
                    classNames: ['bg-hover-secondary'],
                }
            ]
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.END_HOUR" }),
            width: '125px',
            id: 'clockOut',
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => row.userAttendances?.length > 0 && row.userAttendances[0].dateClockOut?
                <span className="" onClick={() => {
                    setAttendance(row.userAttendances[0])
                    setUpdateType("clockOut")
                    setShowUpdateModal(true);
                }}>{moment(row.userAttendances[0].dateClockOut).format('HH:mm')}{row.userAttendances[0].isClockOutChanged && <i className="fas fa-info-circle fs-4 ms-5 text-warning"></i>}</span>
            :
            '-',
            conditionalCellStyles: [
                {
                    when: row => row.userAttendances?.length > 0 && row.userAttendances[0].dateClockOut?.length > 0,
                    classNames: ['bg-hover-secondary'],
                }
            ]
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN_NOTES" }),
            center: true,
            width: '200px',
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => (
                <>
                    {row.userAttendances?.length > 0 && row.userAttendances[0].dateClockInDifference && row.userAttendances[0].dateClockInDifference < 0 ?
                        <Tooltip
                            title={`${intl.formatMessage({ id: "TOOLTIP.LATE" })} ` + (moment.duration(moment(row.userAttendances[0].dateClockIn).diff(moment(row.userAttendances[0].dateTemplateClockIn), 'minutes'), 'minutes').as('minutes') !== 0 ? moment.duration(moment(row.userAttendances[0].dateClockIn).startOf('minute').diff(moment(row.userAttendances[0].dateTemplateClockIn), 'minutes'), 'minutes').format(`h [${intl.formatMessage({ id: "HOURS" })}] m [${intl.formatMessage({ id: "MINUTES" })}]`) : moment.duration(moment(row.userAttendances[0].dateClockIn).diff(moment(row.userAttendances[0].dateTemplateClockIn), 'seconds'), 'seconds').format(`h [${intl.formatMessage({ id: "HOURS" })}] m [${intl.formatMessage({ id: "MINUTES" })}] s [${intl.formatMessage({ id: "SECONDS" })}]`))}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                            style={{ cursor: "default" }}
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-danger w-auto"} style={{ cursor: "default" }}><i className="fas fa-clock fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush " + (row.userAttendances[0]?.dateClockIn ? "btn-icon-primary" : "btn-icon-muted")} style={{ cursor: "default" }}><i className="fas fa-clock fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].latitudeClockIn && row.userAttendances[0].longitudeClockIn ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.LOCATION" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush " + (row.userAttendances[0].positionClockInDifference && row.userAttendances[0].positionClockInDifference > (row.userAttendances[0].templateRadiusClockIn ?? 0) || (!row.userAttendances[0].positionClockInDifference && (row.userAttendances[0].templateLatitudeClockIn || row.userAttendances[0].templateLatitudeClockOut) && (row.userAttendances[0].templateLongitudeClockIn || row.userAttendances[0].templateLongitudeClockOut) && (calculateHaversineDistance(row.userAttendances[0].latitudeClockIn, row.userAttendances[0].longitudeClockIn, row.userAttendances[0].templateLatitudeClockIn ?? row.userAttendances[0].templateLatitudeClockOut, row.userAttendances[0].templateLongitudeClockIn ?? row.userAttendances[0].templateLongitudeClockOut) > (row.userAttendances[0].templateRadiusClockIn ?? 0))) ? "btn-icon-danger" : "btn-icon-primary")}
                                onClick={() => {
                                    setShowMapModal(true);
                                    setLatitudeMap(row.userAttendances[0].latitudeClockIn);
                                    setLongitudeMap(row.userAttendances[0].longitudeClockIn);
                                    setDifference(!row.userAttendances[0].positionClockInDifference && (row.userAttendances[0].templateLatitudeClockIn || row.userAttendances[0].templateLatitudeClockOut) && (row.userAttendances[0].templateLongitudeClockIn || row.userAttendances[0].templateLongitudeClockOut) ? calculateHaversineDistance(row.userAttendances[0].latitudeClockIn, row.userAttendances[0].longitudeClockIn, row.userAttendances[0].templateLatitudeClockIn ?? row.userAttendances[0].templateLatitudeClockOut, row.userAttendances[0].templateLongitudeClockIn ?? row.userAttendances[0].templateLongitudeClockOut) : row.userAttendances[0].positionClockInDifference);
                                    setAttendance(row.userAttendances[0]);
                                }} style={{ cursor: "pointer" }}><i className="fas fa-map-marker-alt fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-map-marker-alt fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].imageClockIn ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PHOTO" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush w-auto " + (row.userAttendances[0].imageClockIn && row.userAttendances[0].isUnrecognizedFaceClockIn ? 'btn-icon-danger' : 'btn-icon-primary')} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (row.userAttendances?.length > 0 && row.userAttendances[0].imageClockIn) {
                                        setShowAttachmentModal(true);
                                        setFileAttachment(row.userAttendances[0].imageClockIn);
                                        setAttendance(row.userAttendances[0]);
                                        setUpdateType("clockIn")
                                    }
                                }}><i className="fas fa-eye fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-eye fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].descriptionClockIn ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-primary w-auto"} style={{ cursor: "pointer" }} onClick={() => {
                                if (row.userAttendances?.length > 0 && row.userAttendances[0].descriptionClockIn) {
                                    setAttendance(row.userAttendances[0]);
                                    setNote(row.userAttendances[0].descriptionClockIn);
                                    setShowNoteModal(true);
                                    setUpdateType("clockIn")
                                }
                            }}><i className="fas fa-sticky-note fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-sticky-note fs-2"></i></div>
                    }
                </>
            )
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT_NOTES" }),
            center: true,
            width: '200px',
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => (
                <>
                    {row.userAttendances?.length > 0 && row.userAttendances[0].dateClockOutDifference && row.userAttendances[0].dateClockOutDifference < 0 ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.EARLY" }, { title: (moment.duration(moment(row.userAttendances[0].dateTemplateClockOut).diff(moment(row.userAttendances[0].dateClockOut), 'minutes'), 'minutes').as('minutes') !== 0 ? moment.duration(moment(row.userAttendances[0].dateTemplateClockOut).diff(moment(row.userAttendances[0].dateClockOut).startOf('minute'), 'minutes'), 'minutes').format(`h [${intl.formatMessage({ id: "HOURS" })}] m [${intl.formatMessage({ id: "MINUTES" })}]`) : moment.duration(moment(row.userAttendances[0].dateTemplateClockOut).diff(moment(row.userAttendances[0].dateClockOut), 'seconds'), 'seconds').format(`h [${intl.formatMessage({ id: "HOURS" })}] m [${intl.formatMessage({ id: "MINUTES" })}] s [${intl.formatMessage({ id: "SECONDS" })}]`)) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                            style={{ cursor: "default" }}
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-danger w-auto"} style={{ cursor: "default" }}><i className="fas fa-clock fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush " + (row.userAttendances[0]?.dateClockOut ? "btn-icon-primary" : "btn-icon-muted")} style={{ cursor: "default" }}><i className="fas fa-clock fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].latitudeClockOut && row.userAttendances[0].longitudeClockOut ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.LOCATION" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush " + (row.userAttendances[0].positionClockOutDifference && row.userAttendances[0].positionClockOutDifference > (row.userAttendances[0].templateRadiusClockOut ?? 0) || (!row.userAttendances[0].positionClockOutDifference && (row.userAttendances[0].templateLatitudeClockOut || row.userAttendances[0].templateLatitudeClockIn) && (row.userAttendances[0].templateLongitudeClockOut || row.userAttendances[0].templateLongitudeClockIn) && (calculateHaversineDistance(row.userAttendances[0].latitudeClockOut, row.userAttendances[0].longitudeClockOut, row.userAttendances[0].templateLatitudeClockOut ?? row.userAttendances[0].templateLatitudeClockIn, row.userAttendances[0].templateLongitudeClockOut ?? row.userAttendances[0].templateLongitudeClockIn) > (row.userAttendances[0].templateRadiusClockOut ?? 0))) ? "btn-icon-danger" : "btn-icon-primary")}
                                onClick={() => {
                                    setShowMapModal(true);
                                    setLatitudeMap(row.userAttendances[0].latitudeClockOut);
                                    setLongitudeMap(row.userAttendances[0].longitudeClockOut);
                                    setDifference(!row.userAttendances[0].positionClockOutDifference && (row.userAttendances[0].templateLatitudeClockOut || row.userAttendances[0].templateLatitudeClockIn) && (row.userAttendances[0].templateLongitudeClockOut || row.userAttendances[0].templateLongitudeClockIn) ? calculateHaversineDistance(row.userAttendances[0].latitudeClockOut, row.userAttendances[0].longitudeClockOut, row.userAttendances[0].templateLatitudeClockOut ?? row.userAttendances[0].templateLatitudeClockIn, row.userAttendances[0].templateLongitudeClockOut ?? row.userAttendances[0].templateLongitudeClockIn) : row.userAttendances[0].positionClockOutDifference);
                                    setAttendance(row.userAttendances[0]);
                                }} style={{ cursor: "pointer" }}><i className="fas fa-map-marker-alt fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-map-marker-alt fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].imageClockOut ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.PHOTO" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush w-auto " + (row.userAttendances[0].imageClockOut && row.userAttendances[0].isUnrecognizedFaceClockOut ? 'btn-icon-danger' : 'btn-icon-primary')} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    if (row.userAttendances?.length > 0 && row.userAttendances[0].imageClockOut) {
                                        setShowAttachmentModal(true);
                                        setFileAttachment(row.userAttendances[0].imageClockOut);
                                        setAttendance(row.userAttendances[0]);
                                        setUpdateType("clockOut")
                                    }
                                }}><i className="fas fa-eye fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-eye fs-2"></i></div>
                    }

                    {row.userAttendances?.length > 0 && row.userAttendances[0].descriptionClockOut ?
                        <Tooltip
                            title={intl.formatMessage({ id: "TOOLTIP.SHOW" }, { title: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.NOTES" }) })}
                            theme="light"
                            arrow={true}
                            className="btn btn-icon btn-flush"
                        >
                            <div className={"btn btn-icon btn-flush btn-icon-primary w-auto"} style={{ cursor: "pointer" }} onClick={() => {
                                if (row.userAttendances?.length > 0 && row.userAttendances[0].descriptionClockOut) {
                                    setAttendance(row.userAttendances[0]);
                                    setNote(row.userAttendances[0].descriptionClockOut);
                                    setShowNoteModal(true);
                                    setUpdateType("clockOut")
                                }
                            }}><i className="fas fa-sticky-note fs-2"></i></div>
                        </Tooltip>
                        :
                        <div className={"btn btn-icon btn-flush btn-icon-muted"} style={{ cursor: "default" }}><i className="fas fa-sticky-note fs-2"></i></div>
                    }
                </>
            )
        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_IN_STATUS" }),
            width: '150px',
            center: true,
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => (row.userAttendances?.length > 0 && row.userAttendances[0].isPermittedClockIn ?
                <span className="badge badge-success">Valid</span>
                : row.userAttendances?.length > 0 && row.userAttendances[0].isPermittedClockIn == false ?
                    <>
                        <span className="badge badge-danger">Invalid</span>
                        <div className={"btn btn-icon btn-flush"}
                            onClick={() => {
                                setAttendance(row.userAttendances[0])
                                setUpdateType("clockIn")
                                setShowApproveModal(true);
                            }}
                        ><i className="fas fa-check fs-4"></i></div>
                    </>
                    :
                    '-')

        },
        {
            name: intl.formatMessage({ id: "MASTERS.DATATABLE.COLUMNS.CLOCK_OUT_STATUS" }),
            width: '150px',
            center: true,
            selector: row => row.userAttendances?.length > 0 ?? "-",
            cell: row => (row.userAttendances?.length > 0 && row.userAttendances[0].isPermittedClockOut ?
                <span className="badge badge-success">Valid</span>
                : row.userAttendances?.length > 0 && row.userAttendances[0].isPermittedClockOut == false ?
                    <>
                        <span className="badge badge-danger">Invalid</span>
                        <div className={"btn btn-icon btn-flush"}
                            onClick={() => {
                                setAttendance(row.userAttendances[0])
                                setUpdateType("clockOut")
                                setShowApproveModal(true);
                            }}
                        ><i className="fas fa-check fs-4"></i></div>
                    </>
                    :
                    '-')
        },
    ];

    const onRowClicked = (row: any, event: any) => {
        if (event.target.dataset.columnId && (event.target.dataset.columnId === "clockIn" || event.target.dataset.columnId === "clockOut") && event.target.textContent !== '-') {
            setAttendance(row.userAttendances[0])
            setUpdateType(event.target.dataset.columnId)
            setShowUpdateModal(true);
        }
    };

    useEffect(() => {
        if (showNoteModal && attendance && note && updateType) {
            const modalElement = document.getElementById(`note-attendance-modal`);
            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowNoteModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showNoteModal, attendance, note, updateType, setShowNoteModal]);

    useEffect(() => {
        if (showUpdateModal && attendance && updateType) {
            const modalElement = document.getElementById(`update-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowUpdateModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateModal, attendance, updateType, setShowUpdateModal]);

    useEffect(() => {
        if (showExportModal) {
            const modalElement = document.getElementById(`export-attendance-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowExportModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showExportModal, setShowExportModal]);

    useEffect(() => {
        if (date) {
            mutate(`${API_URL}user-attendances`);
        }
    }, [date]);

    useEffect(() => {
        if (showAttachmentModal && fileAttachment && attendance && updateType) {
            const modalElement = document.getElementById(`attachments-attendance-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowAttachmentModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showAttachmentModal, fileAttachment, attendance, updateType, setShowAttachmentModal]);

    useEffect(() => {
        if (showApproveModal && attendance && updateType) {
            const modalElement = document.getElementById(`approve-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowApproveModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showApproveModal, attendance, updateType, setShowApproveModal]);

    useEffect(() => {
        if (showMapModal && attendance) {
            const modalElement = document.getElementById(`map-attendance-modal-${attendance.userId}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                // This listener sets showChat to false when the modal is closed
                const handleModalHide = () => {
                    setShowMapModal(false);
                };

                // Attach the event listener
                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showMapModal, attendance, setShowMapModal]);

    let actions = [
        <AttendanceInfo />
    ];

    return (
        <>
            <div className="d-flex justify-content-between mb-3">
                <div className="text-gray-900 fs-2x fw-bold">
                    <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
                        <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.DAILY_ATTENDANCE' })}</li>
                    </ol>
                </div>
                <div>
                    {
                        user.data.is_allowed_export_excel &&
                        <button
                            type="button"
                            className={`btn btn-success btn-sm skeleton skeleton-button me-3`}
                            onClick={() => setShowExportModal(true)}
                        >
                            <i className="fas fa-file-download fs-2 me-2"></i>{intl.formatMessage({ id: "FORM.ACTION.DOWNLOAD_SUMMARY" })}
                        </button>
                    }
                    <DatePicker
                        format="dd MMM yyyy"
                        ranges={[]}
                        oneTap
                        value={date}
                        onChange={(value) => {
                            if (value) {
                                setDate(value)
                            }
                        }}
                        style={{ width: 200, backgroundColor: "white" }}
                        shouldDisableDate={(date) => {
                            return date > new Date();
                        }}
                        locale={calendarLocale}
                        placement="bottomEnd"
                        editable={false}
                    />
                </div>
            </div>
            <MasterDataTable
                tableKey="user-attendances"
                tableColumns={tableColumns}
                apiURL="user-attendances"
                dateStart={moment(date).format('YYYY-MM-DD')}
                onRowClicked={onRowClicked}
                actions={actions}
            />

            {
                showNoteModal && <AttendanceNote attendance={attendance!} note={note!} updateType={updateType!} />
            }
            {
                showUpdateModal && <UpdateAttendance attendance={attendance!} updateType={updateType!} />
            }
            {
                showApproveModal && <ApproveAttendance attendance={attendance!} updateType={updateType!} />
            }
            {
                showAttachmentModal && <AttendanceAttachments image={fileAttachment!} attendance={attendance!} updateType={updateType!} />
            }
            {
                showMapModal && <MapDistanceAttendance latitude={latitudeMap!} longitude={longitudeMap!} attendance={attendance!} difference={difference!} />
            }
            {
                showExportModal && <ExportAttendance />
            }

        </>
    )
}

export { ReportDailyAttendance }