import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { Pipeline } from '../../../interfaces/Pipeline'
import { KTSVG } from '../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import { DatePicker } from 'rsuite';
import { IconButton, Menu, MenuItem, Popover, Switch } from "@mui/material";
import useTodayRange from '../../../hooks/TodayRange';
import useSWR from 'swr';
import useAccessToken from '../../../hooks/AccessToken';
import axios from 'axios';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { insertPaymentTerminAttachment, insertPaymentTerminAttachmentCustomField, insertPaymentTerminMultiAttachmentCustomField } from '../../../api/ResourceAPI';
import { deletePaymentTermin, createPaymentTermin, ICreatePaymentTermin } from '../../../api/PipelineCRUD';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import { customNumberFormatString, getPinningStyles, isBeforeToday, isValidUrl, toRupiah } from '../../../functions/general';
import {
  Column,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useSettingsStore } from '../../../stores/SettingsStore';
import { CustomField } from '../../../interfaces/Settings';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import RenderTerminCustomFieldDetail from '../RenderCustomFieldTermin/RenderTerminCustomFieldDetail';
import useHandleCloseModal from '../../../hooks/HandleCloseModal';
import UpdateDetailTermin from './UpdateDetailTermin';
import { PaymentTermin } from '../../../interfaces/Termin/Termin';
import TerminAttachment from './TerminAttachment';
import ModalPaymentTermin from './ModalPaymentTermin';
import ModalDeleteTermin from './ModalDeleteTermin';
import useModalEffect from '../../../hooks/useModalEffect';
import { TempPaymentTermin } from '../Logs/AddLog';
import InputAttachmentTermin from "../DetailTermin/InputAttachmentTermin";
import PreviewPaidSchedule from './PreviewPaidSchedule';
import useUser from '../../../hooks/User';
import { useCustomeStore } from '../../../stores/CustomeStore';

const API_URL = process.env.REACT_APP_API_URL

interface DetailTerminPipelineProps {
  pipeline: Pipeline
}

const DetailTerminPipeline = ({ pipeline }: DetailTerminPipelineProps) => {
  const { settings } = useSettingsStore()
  const token = useAccessToken()
  const intl = useIntl()
  const user = useUser()
  const { companyId } = useCustomeStore()
  const todayRange = useTodayRange();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const modalRef2 = useRef<HTMLDivElement | null>(null); // digunakan untuk ketika bug pada handleCloseModal karena formik value masih ada
  const closeModalRef = useRef<HTMLButtonElement>(null);
  const [unpaymentNominal, setUnpaymentNominal] = useState(0)
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [customFieldTermin, setCustomFieldTermin] = useState<CustomField>({})
  const columnHelper = createColumnHelper<PaymentTermin>()
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [switchPaidSchedule, setSwitchPaidSchedule] = useState<boolean>(false)
  const [selectedTermin, setSelectedTermin] = useState<PaymentTermin | null>(null);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false)
  const [terminKey, setTerminKey] = useState<string>('')
  const [terminUrl, setTerminUrl] = useState<string>('')
  const inputRef = useRef<(HTMLInputElement | null)[]>([])
  const [selectedTerminNumber, setSelectedTerminNumber] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (settings.termins_custom_fields) {
      setCustomFieldTermin(JSON.parse(settings.termins_custom_fields))
    }
  }, [settings])

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuId(null);
  };

  const fetcher = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          'x-auth-token': token
        }
      })
      return response.data.data
    } catch (error) {
      console.log(error)
    }
  }

  const { data = [], isLoading, mutate } = useSWR<PaymentTermin[]>(`${API_URL}payment-termins?leadId=${pipeline.id}`, fetcher);

  useEffect(() => {
    if (pipeline.nominal) {
      setUnpaymentNominal(Math.round(pipeline.nominal - data.reduce((acc, item) => acc + item.nominalBeforeVat, 0)))
    }
  }, [pipeline.nominal, unpaymentNominal, data])

  const columnCustomField = Object.keys(customFieldTermin).map(key => {
    const fieldType = customFieldTermin[key].type

    if (fieldType === 'text' || fieldType === 'option' || fieldType === 'multiple') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 25,
        cell: (info) => {
          const row = info.row.original;
          const values = row.customFields && row.customFields[key] && row.customFields[key].toString();
          return values ? values.split(',').join(', ') : '-';
        },
      })
    } else if (fieldType === 'date') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 30,
        cell: (info) => {
          const value = info.getValue()
          return value ? moment(value).format("DD MMM YYYY") : '-'
        },
      })
    } else if (fieldType === 'image' || fieldType === 'images') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 25,
        cell: (info) => {
          const row = info.row.original;
          const imageUrl = row.customFields && row.customFields[key];
          return imageUrl ? (
            <span role="button" onClick={() => {
              setShowAttachmentModal(true);
              setTerminKey(key);
              setTerminUrl(imageUrl as any);
            }}>
              <i className="fas fs-2 fa-eye"></i>
            </span>
          ) : '-';
        },
      })
    } else if (fieldType === 'file' || fieldType === 'files') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 25,
        cell: (info) => {
          const row = info.row.original;
          const fileUrl = row.customFields && row.customFields[key];
          if (typeof fileUrl === "string") {
            return fileUrl ? (
              <a
                href={fileUrl}
                target="_blank"
                rel="noreferrer"
                className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
              >
                <i className="fas fa-file-invoice fs-3"></i>
              </a>
            ) : '-';
          } else {
            return fileUrl ? (
              <span role="button" onClick={() => {
                setShowAttachmentModal(true);
                setTerminKey(key);
                setTerminUrl(fileUrl as any);
              }}>
                <i className="fas fa-file-invoice fs-3"></i>
              </span>
            ) : '-';
          }
        },
      })
    } else if (fieldType === 'serial') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 25,
        cell: (info) => {
          const row = info.row.original;
          const values = row.customFields && row.customFields[key];
          return typeof values === 'string' ? values : (values as string[])?.length > 0 ? (values as string[]).join('') : '-';
        },
      })
    } else if (fieldType === 'link') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 25,
        cell: (info) => {
          const row = info.row.original;
          const values = row.customFields && row.customFields[key];
          return values ? (
            <a href={values} target="_blank" rel="noreferrer"
              className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600">
              <i className="fas fa-external-link-alt fs-6 ms-2 p-0 me-2"></i>
              {intl.formatMessage({ id: 'FORM.LABEL.LOOK' })}
            </a>
          ) : '-';
        },
      })
    } else if (fieldType === 'phone') {
      return columnHelper.accessor(row => row.customFields?.[key], {
        id: key,
        header: () => customFieldTermin[key].name,
        size: 30,
        cell: (info) => {
          const row = info.row.original;
          const values = row.customFields && row.customFields[key];
          return values ? values : '-';
        },
      })
    }
    return null;
  }).filter((column): column is NonNullable<typeof column> => column !== null);

  const columns = [
    columnHelper.accessor(row => row.id, {
      id: 'actions',
      header: () => intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
      size: 25,
      cell: (info) => {
        const row = info.row.original;
        if (row.progress === 'PENDING') {
          return (
            <>
              <IconButton
                aria-label={`more-${row.id}`}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                  setOpenMenuId(row.id);
                }}
              >
                <MoreVertIcon />
              </IconButton>

              <Menu
                id={`menu-${row.id}`}
                open={openMenuId === row.id}
                anchorEl={anchorEl}
                onClose={handleClose}
              >
                <div>
                  <MenuItem onClick={() => {
                    handleClose();
                    setSelectedTermin(row);
                    setShowUpdateModal(true);
                    setSelectedTerminNumber(info.row.index + 1);
                  }}>
                    <i className="fas fa-edit fs-4 me-4"></i>
                    <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE' })}</span>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    setSelectedTermin(row);
                    setShowPaymentModal(true);
                  }}>
                    <i className="fas fa-money-check-alt fs-3 me-5"></i>
                    <span>{intl.formatMessage({ id: 'FORM.ACTION.PAYMENT' })}</span>
                  </MenuItem>
                  <MenuItem onClick={() => {
                    handleClose();
                    setShowDeleteModal(true);
                    setSelectedTermin(row);
                    setSelectedTerminNumber(info.row.index + 1);
                  }}>
                    <i className="fas fa-trash fs-3 me-5"></i>
                    <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                  </MenuItem>
                </div>
              </Menu>
            </>
          )
        }
        return <span>-</span>;
      },
    }),
    columnHelper.accessor(row => row.id, {
      id: 'termNo',
      header: () => intl.formatMessage({ id: 'FORM.LABEL.TABLE.TERM_TO' }),
      size: 25,
      cell: (info) => info.row.index + 1,
    }),
    columnHelper.accessor('nominalBeforeVat', {
      header: () => 'Nominal',
      size: 30,
      cell: (info) => {
        return (
          <span className='d-flex justify-content-between align-items-center gap-3'>
            <span>Rp</span>
            <span>{customNumberFormatString(Math.round(info.getValue()).toString())}</span>
          </span>)
      },
    }),
    columnHelper.accessor('vatPercentage', {
      header: () => intl.formatMessage({ id: 'FORM.LABEL.TABLE.VAT' }),
      size: 30,
      cell: (info) => {
        const _vat = info.getValue()
        const vat = _vat ? customNumberFormatString(_vat.toString()) : undefined

        return (
          <span className='d-flex justify-content-between align-items-center gap-3'>
            <span>
              {vat || "-"}
            </span>
            <span>{vat ? "%" : ""}</span>
          </span>)
      },
    }),
    columnHelper.accessor('vatNominal', {
      header: () => 'Total',
      size: 30,
      cell: (info) => {
        const row = info.row.original; // Access the entire row
        const totalNominal = Math.ceil(row.nominal)
        return (
          <span className='d-flex justify-content-between align-items-center gap-3'>
            <span>Rp</span>
            <span>{customNumberFormatString(totalNominal.toString())}</span>
          </span>)
      },
    }),
    columnHelper.accessor('dateDue', {
      header: () => intl.formatMessage({ id: 'FORM.LABEL.DEADLINE_PAYMENT' }),
      size: 25,
      cell: (info) => {
        return moment(info.getValue()).format("DD MMM YYYY")
      },
    }),
    columnHelper.accessor('datePaid', {
      header: () => intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_DATE' }),
      size: 25,
      cell: (info) => {
        const getValue = info.getValue();
        if (getValue) {
          return moment(getValue).format("DD MMM YYYY")
        }
        return <span>-</span>
      },
    }),
    columnHelper.accessor('receiptUrls', {
      header: () => intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_PAYMENT_TERM' }),
      size: 30,
      cell: (info) => {
        if (info.getValue().length > 0) {
          return (
            <a
              href={`${info.getValue()[0]}`}
              className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: 'FORM.LABEL.LOOK' })}
              <i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i>
            </a>
          )
        }

        return <span>{intl.formatMessage({ id: 'FORM.LABEL.NO_PROOF' })}</span>
      },
    }),
    columnHelper.accessor('progress', {
      header: () => intl.formatMessage({ id: 'FORM.LABEL.STATUS_OF_PAYMENT' }),
      size: 25,
      cell: (info) => (
        info.getValue() === 'PENDING' ?
          <span className='badge bg-warning'>{info.getValue()}</span> :
          <span className='badge bg-success'>{info.getValue()}</span>
      ),
    }),
    ...columnCustomField,
  ]

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnPinning: {
        left: ['actions']
      }
    }
  })

  const validationSchema = Yup.object().shape({
    vatPercentage: Yup.string().nullable(),
    nominal: Yup.string()
      .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_AMOUNT' }) })),
    dateDue: Yup.string().when('togglePayment', {
      is: false,
      then: () => Yup.string()
        .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.DEADLINE_PAYMENT' }) })),
      otherwise: () => Yup.string().nullable()
    }),
    datePaid: Yup.string().when('togglePayment', {
      is: true,
      then: () => Yup.string()
        .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_DATE' }) })),
      otherwise: () => Yup.string().nullable()
    }),
    customFields: Yup.object().shape(
      Object.keys(customFieldTermin).reduce((acc: any, key: string) => {
        if (['image', 'images', 'file', 'files'].includes(customFieldTermin[key].type)) {
          acc[key] = customFieldTermin[key].required
            ? Yup.mixed()
              .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
            : Yup.mixed().nullable();
        } else if (customFieldTermin[key].type === 'link') {
          acc[key] = customFieldTermin[key].required
            ? Yup.string()
              .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
              .test('is-url', intl.formatMessage({ id: 'FORM.VALIDATION.MUST_LINK' }),
                (value) => !value || isValidUrl(value))
            : Yup.string()
              .nullable()
              .test('is-url', intl.formatMessage({ id: 'FORM.VALIDATION.MUST_LINK' }),
                (value) => !value || isValidUrl(value));
        } else if (customFieldTermin[key].type === 'option' || customFieldTermin[key].type === 'multiple') {
          acc[key] = customFieldTermin[key].required
            ? Yup.mixed()
              .required(intl.formatMessage({ id: 'FORM.VALIDATION.FIELD_REQUIRED' }, { title: customFieldTermin[key].name }))
            : Yup.mixed().nullable();
        } else if (customFieldTermin[key].required) {
          acc[key] = Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFieldTermin[key].name })).nullable()
        }
        return acc;
      }, {})
    ),
  });

  const formik = useFormik({
    initialValues: {
      togglePayment: false,
      inputType: 'nominal',
      nominal: '',
      vatPercentage: '',
      dateDue: '',
      datePaid: null as string | null,
      receiptUrls: null as File | null | string[] | string,
      customFields: Object.keys(customFieldTermin).reduce((acc: any, key: string) => {
        acc[key] = '';
        return acc;
      }, {} as Record<string, string>),
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let currentPaymentAmount: number = 0;
        if (values.inputType === 'nominal') {
          currentPaymentAmount = Number(values.nominal.replaceAll(".", ""));
        } else {
          const percentageValue = parseFloat(values.nominal);
          currentPaymentAmount = Math.round((percentageValue / 100) * pipeline.nominal);
        }

        if (currentPaymentAmount === 0) {
          Swal.fire(
            intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
            intl.formatMessage({ id: 'FORM.LABEL.PAYMENT_AMOUNT_0' }),
            'error'
          )
          return;
        }

        // if (currentPaymentAmount > unpaymentNominal) {
        //   Swal.fire(
        //     intl.formatMessage({ id: 'ALERT.TITLE.ERROR' }),
        //     intl.formatMessage({ id: 'FORM.LABEL.TERMIN_FULLFILLED' }),
        //     'error'
        //   )
        //   return;
        // }

        // Handle file uploads
        if (values.customFields) {
          for (let key in values.customFields) {
            if (values.customFields[key] instanceof File) {
              const response = await insertPaymentTerminAttachmentCustomField(values.customFields[key]);
              if (response.success) {
                values.customFields[key] = response.data[0];
              }
            } else if (Array.isArray(values.customFields[key]) && values.customFields[key].some((item: any) => item instanceof File)) {
              const files = values.customFields[key].filter((item: any) => item instanceof File);
              if (files.length > 0) {
                const response = await insertPaymentTerminMultiAttachmentCustomField(files);
                if (response.success) {
                  values.customFields[key] = response.data;
                }
              }
            }
          }
        }

        if (values.receiptUrls instanceof File) {
          const attachment = await insertPaymentTerminAttachment(values.receiptUrls);
          if (attachment.success) {
            values.receiptUrls = [attachment.data[0]];
          }
        }
        const vatPercentage = isNaN(Number(values.vatPercentage)) ? null : Number(values.vatPercentage)
        const newTermin: ICreatePaymentTermin = {
          leadId: pipeline.id,
          nominal: currentPaymentAmount,
          vatPercentage: vatPercentage,
          dateDue: values.datePaid ? values.datePaid : values.dateDue,
          datePaid: values.datePaid || null,
          customFields: values.customFields,
          progress: values.togglePayment ? 'PAID' : 'PENDING',
          receiptUrls: values.receiptUrls || []
        }

        const response = await createPaymentTermin(newTermin, token);
        if (response.success) {
          mutate();
          Swal.fire(
            intl.formatMessage({ id: 'FORM.CONFIRM_SUCCESS' }),
            intl.formatMessage({ id: 'FORM.CONFIRM.TERMIN_CREATED' }),
            'success'
          );
          resetForm();
        }
      } catch (error: any) {
        console.error('Error creating payment termin:', error);
      }
    },
  });


  // Replace handleNominalPembayaranChange
  const handleNominalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formik.values.inputType === 'percentage') {
      const value = e.target.value ?? '0';
      if (Number(value) > 100) {
        formik.setFieldError('nominal', intl.formatMessage({ id: 'FORM.LABEL.PERCENTAGE_MAX' }));
        return;
      }
      formik.setFieldValue('nominal', value);
    } else {
      const value = e.target.value ?? '0';
      const formattedValue = customNumberFormatString(value);
      formik.setFieldValue('nominal', formattedValue);
    }
  };

  const handleVATTerminChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Hapus semua karakter non-digit (termasuk titik)
    const rawValue = e.target.value.replace(/[^\d]/g, '');


    const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (Number(rawValue) > 100) {
      formik.setFieldError('vatPercentage', intl.formatMessage({ id: 'FORM.LABEL.PERCENTAGE_MAX' }));
      return;
    }
    formik.setFieldValue('vatPercentage', formattedValue);

  };

  // Replace handlePaymentDate
  const handleDateChange = (date: Date | null) => {
    if (date === null) {
      if (formik.values.togglePayment) {
        formik.setFieldValue('datePaid', '');
      } else {
        formik.setFieldValue('dateDue', '');
      }
    } else {
      if (formik.values.togglePayment) {
        formik.setFieldValue('datePaid', moment(date).format('YYYY-MM-DD'));
      } else {
        const filterDate = moment(date).format('YYYY-MM-DD');
        formik.setFieldValue('dateDue', filterDate);
      }
    }
  };

  useEffect(() => { // reset tanggal ketika terjadi perubahan togglePayment
    if (!formik.values.togglePayment) {
      formik.setFieldValue('dateDue', '')
      formik.setFieldValue('datePaid', '')
    }
  }, [formik.values.togglePayment])

  useEffect(() => {
    formik.setFieldValue('nominal', '')
  }, [formik.values.inputType])

  useModalEffect({
    showModal: showDeleteModal,
    setShowModal: setShowDeleteModal,
    modalId: `delete-detail-termin-modal-${pipeline.id}`
  });

  useModalEffect({
    showModal: showUpdateModal,
    setShowModal: setShowUpdateModal,
    modalId: `update-detail-termin-modal-${pipeline.id}`
  });

  useModalEffect({
    showModal: showPaymentModal,
    setShowModal: setShowPaymentModal,
    modalId: `payment-detail-termin-modal-${pipeline.id}`
  });

  useModalEffect({
    showModal: showAttachmentModal,
    setShowModal: setShowAttachmentModal,
    modalId: `attachments-detail-termin-modal-${pipeline.id}`
  });

  const renderWithFlexRender = (cell: any) => {
    return flexRender(cell.column.columnDef.cell, cell.getContext());
  };

  const renderWithouFlexRender = (cell: any) => {
    return typeof cell.column.columnDef.cell === "function"
      ? cell.column.columnDef.cell(cell.getContext())
      : cell.column.columnDef.cell;
  };

  const { handleCloseModal } = useHandleCloseModal(modalRef, closeModalRef, formik);

  return (
    <>
      <div className="modal fade" tabIndex={-1} id={`detail-termin-pipeline-modal-${pipeline.id}`} ref={modalRef}>
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            {isLoading && (
              <div className="loading-overlay">
                <div className="loader"></div>
              </div>
            )}
            <div className="modal-header">
              <h5 className="modal-title w-100">{intl.formatMessage({ id: "FORM.MENU.TERM_DETAILS" })} {pipeline.merchant_name}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                onClick={handleCloseModal}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              {
                user.data.company_id === companyId.maspionSquare &&
                <div className="form-group h-100">
                  <div className="d-flex align-items-center h-100 justify-content-end">
                    <label className='form-label fs-6 fw-bolder text-dark mb-0 me-3'>{intl.formatMessage({ id: 'FORM.ACTION.PAID_SCHEDULE' })}</label>
                    <Switch
                      checked={switchPaidSchedule}
                      onChange={(e) => setSwitchPaidSchedule(e.target.checked)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </div>
                </div>
              }
              {
                switchPaidSchedule ?
                  <PreviewPaidSchedule pipeline={pipeline} />
                  :
                  <>
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-230 align-middle gs-0 gy-4 text-center">
                        <thead>
                          <tr className="fw-bolder text-dark" style={{ backgroundColor: '#D4D4D4' }}>
                            {table.getHeaderGroups().map(headerGroup => (
                              headerGroup.headers.map((header, index) => (
                                <th
                                  key={header.id}
                                  style={{
                                    padding: `12px ${header.column.columnDef.size}px`,
                                    whiteSpace: 'nowrap',
                                    minWidth: 'fit-content',
                                    ...getPinningStyles(header.column as Column<TempPaymentTermin | PaymentTermin>, true)
                                  }}
                                  className="align-middle"
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                </th>
                              ))
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                              {row.getVisibleCells().map((cell, index) => (
                                <td
                                  key={cell.id}
                                  style={{
                                    padding: `12px ${cell.column.columnDef.size}px`,
                                    whiteSpace: 'nowrap',
                                    minWidth: 'fit-content',
                                    ...getPinningStyles(cell.column as Column<TempPaymentTermin | PaymentTermin>, false)
                                  }}
                                  className="align-middle"
                                >
                                  {index === 0 ? renderWithouFlexRender(cell) : renderWithFlexRender(cell)}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-5">
                      <div className='d-flex flex-column gap-3'>
                        <h5>{intl.formatMessage({ id: "FORM.LABEL.CREATE_NEW_TERMIN" })}</h5>
                        <span>
                          <h6>{intl.formatMessage({ id: "FORM.LABEL.REMAINING_UNPAYMENT" })} : {toRupiah(unpaymentNominal)}</h6>
                        </span>
                      </div>
                      <div className="row g-3 mt-3">
                        <div className='col-md-3'>
                          <div className="form-group">
                            <label className="form-label fs-6 fw-bolder text-dark">{intl.formatMessage({ id: "FORM.LABEL.INPUT_TYPE" })}</label>
                            <select
                              className="form-select"
                              value={formik.values.inputType}
                              onChange={(e) => formik.setFieldValue('inputType', e.target.value)}
                            >
                              <option value="nominal">Nominal</option>
                              <option value="percentage">Persentase</option>
                            </select>
                          </div>
                        </div>
                        <div className='col-md-3'>
                          <div className="form-group h-100">
                            <div className="d-flex align-items-center h-100 pt-6">
                              <label className='form-label fs-6 fw-bolder text-dark mb-0 me-3'>{intl.formatMessage({ id: 'FORM.ACTION.ALREADY_PAID' })}</label>
                              <Switch
                                checked={formik.values.togglePayment}
                                onChange={(e) => formik.setFieldValue('togglePayment', e.target.checked)}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'></div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label fs-6 fw-bolder text-dark required">
                              {formik.values.inputType === 'nominal' ? intl.formatMessage({ id: "FORM.LABEL.PAYMENT_AMOUNT" }) : intl.formatMessage({ id: "FORM.LABEL.PAYMENT_PERCENTAGE" })}
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">
                                {formik.values.inputType === 'nominal' ? 'Rp' : '%'}
                              </span>
                              <input
                                type="text"
                                inputMode="numeric"
                                className={clsx('form-control', {
                                  'is-invalid': formik.touched.nominal && formik.errors.nominal,
                                })}
                                placeholder={formik.values.inputType === 'nominal' ? intl.formatMessage({ id: "FORM.LABEL.ENTER_PAYMENT_AMOUNT" }, { title: 'Nominal' }) : intl.formatMessage({ id: "FORM.LABEL.ENTER_PERCENTAGE" })}
                                value={formik.values.nominal}
                                onChange={handleNominalChange}
                              />
                            </div>
                            {formik.touched.nominal && formik.errors.nominal && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.nominal}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                          <div className="form-group">
                            <label className="form-label fs-6 fw-bolder text-dark ">
                              {intl.formatMessage({ id: "FORM.LABEL.VAT" })}
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">%</span>
                              <input
                                type="text"
                                inputMode="numeric"
                                className="form-control"
                                value={formik.values.vatPercentage}
                                onChange={handleVATTerminChange}
                              />
                            </div>
                            {formik.touched.vatPercentage && formik.errors.vatPercentage && (
                              <div className="invalid-feedback d-block">
                                {formik.errors.vatPercentage}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                          <div className="form-group">
                            {
                              formik.values.togglePayment ? (
                                <>
                                  <label className="form-label fs-6 fw-bolder text-dark required">
                                    {intl.formatMessage({ id: "FORM.LABEL.PAYMENT_DATE" })}
                                  </label>
                                  <DatePicker
                                    value={formik.values.datePaid ? new Date(formik.values.datePaid) : null}
                                    onChange={handleDateChange}
                                    ranges={todayRange}
                                    oneTap
                                    editable={false}
                                    format="dd MMM yyyy"
                                    style={{ width: "100%" }}
                                    className={clsx('form-control p-1', {
                                      'is-invalid': formik.touched.datePaid && formik.errors.datePaid,
                                    })}
                                    shouldDisableDate={isBeforeToday}
                                    container={document.querySelector(`#detail-termin-pipeline-modal-${pipeline.id}`) as HTMLElement}
                                  />
                                  {formik.touched.datePaid && formik.errors.datePaid && (
                                    <div className="invalid-feedback d-block">
                                      {formik.errors.datePaid}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <label className="form-label fs-6 fw-bolder text-dark required">
                                    {intl.formatMessage({ id: "FORM.LABEL.DEADLINE_PAYMENT" })}
                                  </label>
                                  <DatePicker
                                    value={formik.values.dateDue ? new Date(formik.values.dateDue) : null}
                                    onChange={handleDateChange}
                                    ranges={todayRange}
                                    oneTap
                                    editable={false}
                                    format="dd MMM yyyy"
                                    style={{ width: "100%" }}
                                    className={clsx('form-control p-1', {
                                      'is-invalid': formik.touched.dateDue && formik.errors.dateDue,
                                    })}
                                    shouldDisableDate={isBeforeToday}
                                    container={document.querySelector(`#detail-termin-pipeline-modal-${pipeline.id}`) as HTMLElement}
                                  />
                                  {formik.touched.dateDue && formik.errors.dateDue && (
                                    <div className="invalid-feedback d-block">
                                      {formik.errors.dateDue}
                                    </div>
                                  )}
                                </>
                              )
                            }
                          </div>
                        </div>
                        <div className='col-md-12'>
                          {formik.values.togglePayment &&
                            <div className="col-md-8">
                              <label className="form-label fs-6 fw-bolder text-dark">{intl.formatMessage({ id: 'FORM.LABEL.PROOF_OF_PAYMENT_TERM' })}</label>
                              <InputAttachmentTermin key={`formik.receiptUrls`} keyString={`formik.receiptUrls`} formik={formik} isMulti={false} type="input" accept="image/png, image/jpeg, image/jpg" typeCustomField='paymentTermin' />
                            </div>
                          }
                        </div>

                        <RenderTerminCustomFieldDetail
                          customFieldTermin={customFieldTermin}
                          formik={formik}
                          errors={formik.errors}
                          pipeline={pipeline}
                          inputRefs={inputRef}
                        />
                      </div>
                    </div>
                  </>
              }
              <div className="col-12 text-end">
                <button
                  type="button"
                  className="btn btn-light d-none"
                  data-bs-dismiss="modal"
                  ref={closeModalRef}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={handleCloseModal}
                >
                  {intl.formatMessage({ id: "FORM.ACTION.CANCEL" })}
                </button>
                {
                  !switchPaidSchedule &&
                  <>
                    <button
                      className={`btn btn-primary ${formik.isSubmitting ? 'disabled' : ''}`}
                      data-kt-indicator={formik.isSubmitting ? 'on' : 'off'}
                      data-kt-in
                      onClick={() => formik.handleSubmit()}
                      disabled={formik.isSubmitting}
                    >
                      <span className='indicator-label'>
                        {intl.formatMessage({ id: "FORM.LABEL.ADD_GENERAL" }, { title: intl.formatMessage({ id: "FORM.LABEL.TERMIN" }) })}
                      </span>
                      <span className="indicator-progress">
                        Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      {showUpdateModal && selectedTermin && (
        <UpdateDetailTermin
          termin={selectedTermin}
          pipeline={pipeline}
          onSuccess={() => {
            setShowUpdateModal(false);
            setSelectedTermin(null);
            mutate();
          }}
          terminNumber={selectedTerminNumber}
          customFieldTermin={customFieldTermin}
        />
      )}

      {showAttachmentModal && terminUrl && (
        <TerminAttachment // pop up attachment
          pipeline={pipeline}
          title={customFieldTermin[terminKey].name!}
          url={terminUrl}
        />
      )}

      {
        showPaymentModal && (
          <ModalPaymentTermin
            termin={selectedTermin!}
            pipeline={pipeline}
            setShowPaymentModal={setShowPaymentModal}
          />
        )
      }

      {
        showDeleteModal && (
          <ModalDeleteTermin
            termin={selectedTermin!}
            pipeline={pipeline}
            terminNumber={selectedTerminNumber}
            onSuccess={() => {
              setShowDeleteModal(false);
              setSelectedTermin(null);
              mutate();
            }}
          />
        )
      }
    </>

  )
}

export default DetailTerminPipeline