import {  IVarDocumentationProps, QueryParameter, RequestBody } from "../../../interfaces/OpenAPI";

export const getDocumentationLabelProps = ({intl, settings}:IVarDocumentationProps )=>{    
 

    // ===================== GET LABEL =====================
    const getLabelparams: QueryParameter[] = [
        {
            field: 'usableBy[]',
            type: `String (${intl.formatMessage({ id: "FORM.LABEL.OPTIONAL" })})`,
            description: intl.formatMessage({ id: "OPEN_API_PARAMS.DESC_LABEL_USABLEBY" }),
        },
    ]
    const getLabelsExample = {
        success: true,
        message: "Berhasil mendapatkan daftart label.",
        data: [{
            id: "cfc9d0a9-b173-4d39-9a77-deac4124b831",
            name: "SDSD",
            color: null,
            dateCreated: "2024-02-15 17:16:24",
            isUsableByProduct: true,
            isUsableByPipeline: true,
            isUsableByMerchant: false,
            isUsableByMeeting: false
          },
          {
            id: "8cebe8d8-f4e3-41ab-b624-dfeb9d34fcff",
            name: "OTOMOTIF",
            color: null,
            dateCreated: "2024-02-15 17:16:50",
            isUsableByProduct: true,
            isUsableByPipeline: true,
            isUsableByMerchant: false,
            isUsableByMeeting: false
          },]
      };
      
    

    const python_get_labels = `
    import requests
    import json

    reqUrl = "https://crm-api.wooblazz.com/labels?usableBy[]=product&usableBy[]=pipeline"
    token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })}

    headersList = {
    "Accept": "*/*",
    "Content-Type": "application/json", 
    "X-Auth-token": token
    }

    response = requests.request("GET", reqUrl, headers=headersList)
    print(response.text)
    `

    const javascript_get_labels = `
    const axios = require('axios');

    const reqUrl = "https://crm-api.wooblazz.com/labels?usableBy[]=product&usableBy[]=pipeline";
    const token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    const headersList = {
        "Accept": "*/*",
        "Content-Type": "application/json",
        "X-Auth-token": token
    };

    axios.get(reqUrl, { headers: headersList })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error(error);
        });

    `

    const php_get_labels = `
    <?php

    $reqUrl = "https://crm-api.wooblazz.com/labels??usableBy[]=product&usableBy[]=pipeline";
    $token = //${intl.formatMessage({ id: "OPEN_API.DESC_TOKEN" })};

    $headersList = [
        "Accept: */*",
        "Content-Type: application/json",
        "X-Auth-token: $token"
    ];

    $ch = curl_init();

    curl_setopt($ch, CURLOPT_URL, $reqUrl);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLOPT_HTTPHEADER, $headersList);

    $response = curl_exec($ch);

    if (curl_errno($ch)) {
        echo 'Error: ' . curl_error($ch);
    } else {
        echo $response;
    }

    curl_close($ch);

    ?>

    `

    const bash_get_labels = `

    #!/bin/bash

    REQ_URL="https://crm-api.wooblazz.com/labels??usableBy[]=product&usableBy[]=pipeline"
    TOKEN="//${intl.formatMessage({ id: 'OPEN_API.DESC_TOKEN' })}"

    curl -X GET "$REQ_URL" \
    --header "Accept: */*" \
    --header "Content-Type: application/json" \
    --header "X-Auth-token: $TOKEN"

    `

    return   {  bash_get_labels, javascript_get_labels, getLabelsExample, php_get_labels, python_get_labels, getLabelparams}
}