import { Dropdown } from "rsuite";
import { SketchPicker } from "react-color";
import useNameWidget from '../../hooks/NameWidget';
import { ArrayWidget, Widget } from "../../interfaces/Widget";
import { useIntl } from "react-intl";

type Props = {
    index: number
    type: string
    data: ArrayWidget
    widget: Widget
    setWidget: (widget: Widget) => void 
    handleWidget: (data: ArrayWidget, type: string, action: string) => void
}

const renderCardWidget = (nameWidget: string, props: any, ref: any) => {
    return (
        <div {...props} ref={ref} className="form-check form-check-custom justify-content-between w-100">
            <div className='my-3 mx-3 d-flex w-100' style={{ alignItems: "center" }}>
                <label className="form-check-label ms-3 fw-bold fs-7 w-100">{nameWidget}</label>
            </div>
        </div>
    );
};

const CustomColumnWidget: React.FC<Props> = ({ index, type, data, widget, setWidget, handleWidget }) => {
    const intl = useIntl()
    const nameWidget = (data.name.includes("Monthly") && type === 'pieChart') ? `${data.alias} (${intl.formatMessage({ id: "FORM.LABEL.MONTHLY" })})` : data.alias;
    const dataWidget = type === 'static' ? widget.static : type === 'monthly' ? widget.monthly : widget.pieChart

    return (
        <div className="col-12 col-lg-3 col-xl-3 cursor-pointer mb-3" key={index}>
            <div className={`card-dashboard`} style={{ position: "relative", backgroundColor: "#dcf5e7", color: "#50cd89" }}>
                <Dropdown renderToggle={(props, ref) => renderCardWidget(nameWidget!, props, ref)} placement="topEnd" style={{ width: "100%" }}>
                    <SketchPicker
                        color={data.color ?? ""}
                        onChange={color => {
                            setWidget({ ...widget, [type]: dataWidget.map(item => item.id === data.id ? { ...item, color: color.hex } : item) })
                        }}
                    />
                    <div className="btn btn-sm btn-primary mt-3 ms-3" onClick={() => handleWidget(data, type, 'show')}>
                        Ok
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}

export { CustomColumnWidget }