import { FC, useRef, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import Swal from "sweetalert2";
import { useDataTableStore } from "../../stores/DataTableStore";
import useAccessToken from "../../hooks/AccessToken";
import { Meeting, MeetingAttachment } from "../../interfaces/Meeting";
import { updateMeeting } from "../../api/MeetingsCRUD";
import moment from "moment";
import { insertMeetingAttachment, insertMeetingFieldAttachment, insertMeetingFieldMultiAttachment } from "../../api/ResourceAPI";
import useUser from "../../hooks/User";
import useSWR from "swr";
import axios from "axios";
import { SelectOption } from "../../interfaces/SelectOption";
import { User } from "../../interfaces/User";
import Select from "react-select"
import { nanoid } from "@reduxjs/toolkit";
import { DatePicker } from "rsuite";
import { Map, Marker } from "pigeon-maps";
import { CustomField } from "../../interfaces/Settings";
import { useSettingsStore } from "../../stores/SettingsStore";
import { KTSVG, toAbsoluteUrl } from "../../_metronic/helpers";
import { handleCaps } from "../../functions/general";
import useTodayRange from "../../hooks/TodayRange";
import { useIntl } from "react-intl";
import { useMeetingStore } from "../../stores/MeetingsStore";

interface Props {
    codeUrl: string
    meeting: Meeting
}

const UpdateMeeting: FC<Props> = ({ codeUrl, meeting }) => {
    const {
        meetings,
        setMeetings,
        meetingLoading,
        setMeetingLoading,
        multiMeetings,
        setMultiMeetings,
        selectMultiMeeting,
        setSelectMultiMeeting,
    } = useMeetingStore();

    const refArray = useRef<(HTMLInputElement | null)[]>([]);
    const todayRange = useTodayRange();
    const intl = useIntl()

    const user = useUser()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [modalBtnLoading, setModalBtnLoading] = useState<boolean>(false);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const closeModalRef = useRef<HTMLButtonElement>(null);
    const token = useAccessToken()
    const { tablesData, setTablesData } = useDataTableStore()
    const [usersData, setUsersData] = useState<SelectOption[]>([]);
    const [file, setFile] = useState<string | null>(null);
    const [latitudeMap, setLatitudeMap] = useState<number>(parseFloat(meeting.latitude));
    const [longitudeMap, setLongitudeMap] = useState<number>(parseFloat(meeting.longitude));
    const color = `hsl(${0 % 360}deg 39% 70%)`
    const { settings } = useSettingsStore()
    const [customFields, setCustomFields] = useState<CustomField>({})

    function getFileTypeFromUrl(url: any) {
        if (url && Object.keys(url).length !== 0) {
            var splitUrl = typeof url === 'object' ? url.name.split(".") : url.split(".");
            var fileExtension = splitUrl[splitUrl.length - 1];

            // Menentukan tipe file berdasarkan ekstensi
            switch (fileExtension.toLowerCase()) {
                case "xlsx":
                    return "xlsx";
                case "xlx":
                    return "xlsx";
                case "doc":
                    return "doc";
                case "ppt":
                    return "ppt";
                case "pdf":
                    return "pdf";
                default:
                    return "";
            }
        } else return ""
    }

    const fetcher = (url: string) => axios.get(url, {
        headers: {
            'X-Auth-token': token
        }
    }).then(res => res.data.data)

    const API_URL = process.env.REACT_APP_API_URL

    const getUserURL = () => {
        if (user.data.permission_meeting_update === "ALL") {
            return `${API_URL}users`
        } else {
            return `${API_URL}user/subordinates`
        }
    }

    const { data: users = [], error: usersError, isLoading: usersLoading } = useSWR(getUserURL(), fetcher)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>, key: string) {
        if (e.target.files && e.target.files[0]) {
            setFile(URL.createObjectURL(e.target.files[0]));
            formik.handleChange({
                target: {
                    name: key,
                    value: e.target.files[0]
                }
            })
        } else {
            setFile(null);
            formik.handleChange({
                target: {
                    name: key,
                    value: ""
                }
            })
        }
    }

    const handleCoordinate = async () => {
        try {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatitudeMap(position.coords.latitude);
                        setLongitudeMap(position.coords.longitude);
                        formik.setFieldValue("coordinate", position.coords.latitude + ", " + position.coords.longitude);
                    },
                    (error) => {
                        if (error.code === error.PERMISSION_DENIED) {
                            var message1 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.TUTORIAL" })
                            alertErrorCoordinate(message1)
                        }
                    }
                );
            } else {
                var message2 = intl.formatMessage({ id: "BROWSER.SUPPORT.GEOLOCATION" })
                alertErrorCoordinate(message2);
            }
        } catch (error) {
            var message3 = intl.formatMessage({ id: "BROWSER.GEOLOCATION.PERMISSION_DENIED" })
            alertErrorCoordinate(message3)
        }
    };

    const alertErrorCoordinate = (message: string) => {
        Swal.fire({
            icon: 'error',
            title: `${intl.formatMessage({ id: "ALERT.TITLE.ERROR" })}`,
            html: message,
            confirmButtonText: 'Ok',
            heightAuto: false,
        });
        setLatitudeMap(0);
        setLongitudeMap(0);
        formik.setFieldValue("coordinate", "0, 0");
    }

    const formSchema = Object.keys(customFields).reduce(
        (obj, item) => ({
            ...obj,
            [item]:
                (customFields[item].type === 'multiple' || customFields[item].type === 'images' || customFields[item].type === 'files')
                    ? customFields[item].required ? Yup.array().min(1, intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable()
                        : Yup.array().notRequired().nullable()
                    : customFields[item].type === 'date'
                        ? customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
                        : customFields[item].required ? Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: customFields[item].name })).nullable() : Yup.string().notRequired().nullable()
        }),
        {}
    )

    const validationSchema = Yup.object().shape({
        dateMeet: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.DATE" }, { title: settings.meeting_title }) })).nullable(),
        dateMeetEnd: Yup.string().required('Tanggal harus diisi').nullable(),
        labels: Yup.array().notRequired(),
        ownerId: Yup.string().required('PIC harus diisi'),
        attachment: Yup.mixed().notRequired().nullable(),
        note: Yup.string().notRequired().nullable(),
        coordinate: Yup.mixed().required('Lokasi harus diisi'),
        ...formSchema
    });

    const initialValues = {
        dateMeet: moment(meeting.date_meet).format("YYYY-MM-DD HH:mm"),
        dateMeetEnd: moment(meeting.date_meet_end).format("YYYY-MM-DD HH:mm"),
        attachment: meeting.attachments ? meeting.attachments[0].url : "",
        labels : meeting.labels.map((label)=>{return label.id}),
        ownerId: meeting.owner_id,
        note: meeting.note,
        coordinate: meeting.latitude === null ? "" : meeting.latitude + ", " + meeting.longitude,
        ...Object.fromEntries(Object.keys(customFields).map((key) => [key,
            meeting.custom_fields && meeting.custom_fields[key] ?
                customFields[key].type === 'date' ?
                    moment(meeting.custom_fields[key], 'YYYY-MM-DD', true).isValid() ?
                        meeting.custom_fields[key]
                        : null
                    : meeting.custom_fields[key]
                : ""]
        )),
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setModalBtnLoading(true)

            const customFieldsValues: Record<string, string|string[]|null> = Object.keys(customFields).reduce(
                (obj, item) => ({
                    ...obj,
                    [item]: (values as Record<string, string|string[]|null>)[item],
                }),
                {}
            );

            // Use Promise.all to await all async operations
            await Promise.all(
                Object.keys(customFields).map(async (key) => {
                    if (customFields[key].type === 'image' || customFields[key].type === 'file') {
                        try {
                            if (typeof values[key as keyof typeof values] === 'object') {
                                const resourceResponse = await insertMeetingFieldAttachment(values[key as keyof typeof values] as string | File);
                                customFieldsValues[key] = resourceResponse.data.data.length > 0 ? resourceResponse.data.data[0] : "";
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    } else if (customFields[key].type === 'images' || customFields[key].type === 'files') {
                        try {
                            if (values[key as keyof typeof values]) {
                                var dataMultiAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item !== 'string')
                                var dataUrlAttachment = (values[key as keyof typeof values] as any).filter((item: any) => typeof item === 'string')

                                const resourceResponse = await insertMeetingFieldMultiAttachment(dataMultiAttachment);
                                (customFieldsValues[key] as any) = resourceResponse.data.data.length > 0 ? [...dataUrlAttachment, ...resourceResponse.data.data] : dataUrlAttachment.length > 0 ? dataUrlAttachment : null;
                            }
                        } catch (error) {
                            console.log(error);
                        }
                    }
                })
            );

            try {
                let attachments: MeetingAttachment[] = []
                if (values.attachment) {
                    const resourceResponse = await insertMeetingAttachment(values.attachment)
                    if (resourceResponse.data.data.length > 0) {
                        const parts = resourceResponse.data.data[0].split('/');
                        const fileName = parts[parts.length - 1];
                        attachments = [
                            {
                                name: fileName,
                                url: resourceResponse.data.data[0]
                            }
                        ]
                    }
                }

                const latitude = values.coordinate.split(", ")[0]
                const longitude = values.coordinate.split(", ")[1]
                const notification = false
                const response = await updateMeeting({
                    meetingTemplateId: codeUrl,
                    id: meeting.id,
                    start: {
                        date: meeting.allDay ? moment(`${meeting.date_meet}`).format("YYYY-MM-DD") : null,
                        dateTime: !meeting.allDay ? moment(`${meeting.date_meet}`).toISOString(true) : null
                    },
                    end: {
                        date: meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').format("YYYY-MM-DD") : null,
                        dateTime: !meeting.allDay ? moment(`${meeting.date_meet_end}`).add(meeting.allDay ? 1 : 0, 'days').toISOString(true) : null,
                    },
                    ownerId: meeting.owner_id,
                    attachments: attachments,
                    status: meeting.status,
                    reminderMinutes: meeting.reminderMinutes,
                    userAttendees: meeting.attendees,
                    token: token,
                    notification: notification,
                    note: meeting.note,
                    latitude: meeting.latitude,
                    longitude: meeting.longitude,
                    customFields: meeting.custom_fields,
                })
                if (response.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: `${meeting.merchant_name} telah diubah`,
                        timer: 2000,
                        timerProgressBar: true
                    })
                    closeModalRef.current?.click();
                    setTablesData('meetings', (tablesData.meetings.map(item => {
                        if (item.id === meeting.id) {
                            return {
                                ...item,
                                date_meet: response.data.data.date_meet,
                                date_meet_end: response.data.data.date_meet_end,
                                owner_id: response.data.data.owner_id,
                                owner_name: response.data.data.owner_name,
                                attachments: response.data.data.attachments,
                                latitude: response.data.data.latitude,
                                longitude: response.data.data.longitude,
                                note: response.data.data.note,
                                custom_fields: response.data.data.custom_fields
                            }
                        } else {
                            return item
                        }
                    })));
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                    confirmButtonText: 'Ok',
                    heightAuto: false,
                })
            } finally {
                setModalBtnLoading(false)
            }
        }
    });

    useEffect(() => {
        // Get the modal element
        const modalElement = modalRef.current;

        // Define the event listeners
        const handleModalHidden = () => {
            formik.resetForm();
        };

        // Attach the event listeners
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);

        // Cleanup
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, [formik]);

    useEffect(() => {
        if (!usersLoading) {
            const usersData = users.map((user: User) => ({
                value: user.id,
                label: user.name
            }));

            if (user.data.permission_meeting_update !== "ALL") {
                usersData.unshift({ value: user.data.id, label: user.data.name })
            }
            // Prepend the two objects to the salesData array
            setUsersData(usersData);
        }
    }, [users]);

    // useEffect(() => {
    //     if (settings.meeting_custom_fields) {
    //         setCustomFields(JSON.parse(settings.meeting_custom_fields))
    //     }
    // }, [settings])

    useEffect(() => {
        if (Object.keys(selectMultiMeeting).length !== 0) {
            setCustomFields(selectMultiMeeting.customFields)
        }
    }, [selectMultiMeeting])


    const {data:labels=[], error:labelsError, isLoading:labelsLoading} = useSWR(`${API_URL}labels?usableBy[]=meeting`, fetcher)

    
    const [labelsOption, setLabelOption]= useState([])

    useEffect(()=>{
        const newLabelsOption = labels.map((label:any)=>{return{value:label.id , label: label.name}})
        setLabelOption(newLabelsOption)
    },[labels])


    return (
        <div className="modal fade" tabIndex={-1} id={`update-meeting-modal-${meeting.id}`} ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" style={{ overflowWrap: "anywhere" }}>Ubah {meeting.merchant_name} <br /> Tanggal {moment(meeting.date_meet).format('DD-MM-YYYY')}</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <div className="modal-body">
                            {formik.status && (
                                <div className='mb-lg-15 alert alert-danger'>
                                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                                </div>
                            )}
                            {
                                (user.data.permission_meeting_update === 'TEAM' || user.data.permission_meeting_update === 'ALL') &&
                                <>
                                    <label className='form-label fs-6 fw-bolder text-dark required'>PIC</label>
                                    <div className="input-group mb-3">
                                        <Select
                                            key={nanoid()}
                                            options={usersData}
                                            placeholder={`Pilih PIC`}
                                            value={usersData.find(option => option.value === formik.values.ownerId)}
                                            onChange={
                                                option => formik.handleChange({
                                                    target: {
                                                        name: "ownerId",
                                                        value: option?.value
                                                    }
                                                })
                                            }
                                            onBlur={
                                                () => formik.values.ownerId === "" && formik.setFieldTouched("ownerId", true)
                                            }
                                            className="w-100"
                                        />
                                    </div>
                                    {formik.touched.ownerId && formik.errors.ownerId && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formik.errors.ownerId}</span>
                                        </div>
                                    )}
                                </>
                            }
                             {/* labelS */}
                             <label className='form-label fs-6 fw-bolder text-dark required'>{intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}</label>
                                <div className="input-group mb-3">
                                <Select
                                    closeMenuOnSelect={false}
                                    openMenuOnClick
                                    isMulti
                                    options={labelsOption}
                                    placeholder={`${intl.formatMessage({ id: 'FORM.ACTION.CHOOSE' })} ${intl.formatMessage({ id: 'FORM.LABEL.LABELS' })}`}
                                    value={ labelsOption.filter((option: any) =>
                                        formik.values.labels.includes(option.value)
                                      )
                                    }
                                    onChange={(selectedOptions) => {
                                        const selectedValues = selectedOptions
                                            ? selectedOptions.map((option:any) => option.value)
                                            : []
                                        formik.setFieldValue('labels', selectedValues)
                                        }}
                                    className='w-100'
                                    onBlur={() =>
                                        formik.values.labels.length ==0 &&
                                        formik.setFieldTouched('labels', true)
                                        }
                                />
                                </div>
                                {formik.touched.labels && formik.errors.labels && (
                                    <div className='fv-plugins-message-container text-danger'>
                                        <span role='alert' className="text-danger">{formik.errors.labels}</span>
                                    </div>
                                )}
                            {/* Date Start */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Tanggal Mulai Meeting</label>
                            <div className="input-group mb-3">
                                <DatePicker
                                    ranges={todayRange}
                                    editable={false}
                                    format="dd MMM yyyy HH:mm"
                                    defaultValue={new Date(formik.values.dateMeet)}
                                    style={{ width: "100%", zIndex: 0 }}
                                    container={document.querySelector('#update-meeting-modal-' + meeting.id) as HTMLElement}
                                    onChange={(value: any) => {
                                        if (value) formik.setFieldValue('dateMeet', moment(value).format('YYYY-MM-DD HH:mm'))
                                        else formik.setFieldValue('dateMeet', null)
                                    }}
                                />
                            </div>
                            {formik.touched.dateMeet && formik.errors.dateMeet && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.dateMeet}</span>
                                </div>
                            )}
                            {/* Date End */}
                            <label className='form-label fs-6 fw-bolder text-dark required'>Tanggal Selesai Meeting</label>
                            <div className="input-group mb-3">
                                <DatePicker
                                    ranges={todayRange}
                                    editable={false}
                                    format="dd MMM yyyy HH:mm"
                                    defaultValue={new Date(formik.values.dateMeetEnd)}
                                    style={{ width: "100%", zIndex: 0 }}
                                    container={document.querySelector('#update-meeting-modal-' + meeting.id) as HTMLElement}
                                    onChange={(value: any) => {
                                        if (value) formik.setFieldValue('dateMeetEnd', moment(value).format('YYYY-MM-DD HH:mm'))
                                        else formik.setFieldValue('dateMeetEnd', null)
                                    }}
                                />
                            </div>
                            {formik.touched.dateMeetEnd && formik.errors.dateMeetEnd && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.dateMeetEnd}</span>
                                </div>
                            )}
                            {/* Attachments */}
                            <label className='form-label fs-6 fw-bolder text-dark'>Foto Meeting</label>
                            {
                                formik.values.attachment && typeof formik.values.attachment === 'string' && (
                                    <img src={formik.values.attachment} alt="" style={{ height: '100px', padding: "10px" }} className="d-block" />
                                )
                            }
                            <div className="d-flex mb-3">
                                <input
                                    style={{ zIndex: 0 }}
                                    ref={fileInputRef}
                                    type="file"
                                    onChange={(e) => handleChange(e, "attachment")}
                                    accept="image/*"
                                    className={clsx('form-control form-control-lg')}
                                />

                                <button type="button" className="btn btn-light-danger ms-3" onClick={() => { formik.setFieldValue("attachment", ''); fileInputRef.current!.value = "" }}>
                                    Hapus
                                </button>
                                {/* {
                                    file ?
                                        <img src={file} alt="meeting" className="img-fluid mt-3" />
                                        :
                                        meeting.attachments && meeting.attachments.length > 0 &&
                                        <img src={meeting.attachments[0].url} alt="meeting" className="img-fluid mt-3" />
                                } */}
                            </div>
                            {formik.touched.attachment && formik.errors.attachment && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.attachment}</span>
                                </div>
                            )}
                            {/* Coordinat Location */}
                            <span className='form-label fs-6 fw-bolder text-dark required'>Lokasi</span><br />
                            <small>*Pastikan GPS anda nyala agar lokasi lebih akurat</small>
                            <div className="d-flex">
                                <div className="input-group mb-3">
                                    <input
                                        {...formik.getFieldProps('coordinate')}
                                        className={clsx(
                                            'form-control form-control-lg me-3',
                                            { 'is-invalid': formik.touched.coordinate && formik.errors.coordinate },
                                        )}
                                        type='text'
                                        name='coordinate'
                                        autoComplete='off'
                                        onInput={(e) => handleCaps(e)}
                                        disabled
                                    />
                                </div>
                                <button type="button" className="btn btn-secondary mb-3" onClick={handleCoordinate}>
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                            {formik.touched.coordinate && formik.errors.coordinate && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.coordinate}</span>
                                </div>
                            )}
                            {
                                latitudeMap && longitudeMap ?
                                    <Map height={200} center={[latitudeMap, longitudeMap]} defaultZoom={18} attribution={false}>
                                        <Marker
                                            width={50}
                                            height={50}
                                            anchor={[latitudeMap, longitudeMap]}
                                            color={color}
                                        />
                                    </Map> : <></>
                            }
                            {/* Note */}
                            <label className='form-label fs-6 fw-bolder text-dark'>Catatan</label>
                            <div className="input-group mb-3">
                                <textarea
                                    placeholder="Catatan"
                                    id="floatingTextarea"
                                    {...formik.getFieldProps('note')}
                                    className={clsx(
                                        'form-control form-control-lg',
                                        { 'is-invalid': formik.touched.note && formik.errors.note },
                                    )}
                                    style={{ zIndex: 0 }}
                                    name="note"
                                    maxLength={255}
                                    onInput={(e) => handleCaps(e)}
                                >
                                </textarea>
                            </div>
                            {formik.touched.note && formik.errors.note && (
                                <div className='fv-plugins-message-container text-danger'>
                                    <span role='alert' className="text-danger">{formik.errors.note}</span>
                                </div>
                            )}

                            {
                                customFields && Object.keys(customFields).map((key, index) => {
                                    if (customFields[key].type === 'text') {
                                        return (
                                            <div key={index}>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        placeholder={customFields[key].name}
                                                        {...formik.getFieldProps(key)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        type='text'
                                                        name={key}
                                                        autoComplete='off'
                                                        style={{ zIndex: 0 }}
                                                        required={true}
                                                        onInput={(e) => handleCaps(e)}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    } else if (customFields[key].type === 'image') {
                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format gambar JPG, JPEG, PNG</i></small>
                                                {
                                                    formik.values[key as keyof typeof formik.values] && typeof formik.values[key as keyof typeof formik.values] === 'string' && (
                                                        <img src={formik.values[key as keyof typeof formik.values] as string|undefined} alt={key} style={{ height: '100px', padding: "10px" }} className="d-block" />
                                                    )
                                                }
                                                <div className="d-flex mb-3">
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        style={{ zIndex: 0 }}
                                                        onChange={(e) => handleChange(e, key)}
                                                        onBlur={() => formik.setFieldTouched(key, true)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />

                                                    <button type="button" className="btn btn-light-danger ms-3" onClick={() => { formik.setFieldValue(key, ''); refArray.current[index]!.value! = "" }}>
                                                        Hapus
                                                    </button>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'file') {
                                        var fileType = getFileTypeFromUrl(formik.values[key as keyof typeof formik.values])

                                        return (
                                            <>
                                                {/* Attachments */}
                                                <span className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</span><br />
                                                <small><i>*Format file PDF, XLS, XLSX, DOCX, TXT</i></small>
                                                {
                                                    formik.values[key as keyof typeof formik.values] && (
                                                        fileType === 'doc' ?
                                                            <img alt="" className='h-80px logo d-block' style={{ padding: "10px" }} src={toAbsoluteUrl('/media/logos/doc.png')} />
                                                            : fileType === 'xlsx' ?
                                                                <img alt="" className='h-80px logo d-block' style={{ padding: "10px" }} src={toAbsoluteUrl('/media/logos/xls.png')} />
                                                                : fileType === 'pdf' ?
                                                                    <img alt="" className='h-80px logo d-block' style={{ padding: "10px" }} src={toAbsoluteUrl('/media/logos/pdf.png')} />
                                                                    : fileType === 'ppt' ?
                                                                        <img alt="" className='h-80px logo d-block' style={{ padding: "10px" }} src={toAbsoluteUrl('/media/logos/ppt.png')} />
                                                                        :
                                                                        <img alt="" className='h-80px logo d-block' style={{ padding: "10px" }} src={toAbsoluteUrl('/media/logos/file.png')} />
                                                    )
                                                }
                                                <div className="d-flex mb-3">
                                                    <input
                                                        type="file"
                                                        accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                                        style={{ zIndex: 0 }}
                                                        onChange={(e) => handleChange(e, key)}
                                                        onBlur={() => formik.setFieldTouched(key, true)}
                                                        className={clsx(
                                                            'form-control form-control-lg',
                                                            { 'is-invalid': formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] },
                                                        )}
                                                        ref={ref => {
                                                            refArray.current[index] = ref; // took this from your guide's example.
                                                        }}
                                                    />

                                                    <button type="button" className="btn btn-light-danger ms-3" onClick={() => { formik.setFieldValue(key, ''); refArray.current[index]!.value! = "" }}>
                                                        Hapus
                                                    </button>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'date') {
                                        return (
                                            <>
                                                {/* Date */}
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <DatePicker
                                                        ranges={todayRange}
                                                        oneTap
                                                        editable={false}
                                                        format="dd MMM yyyy"
                                                        value={formik.values[key as keyof typeof formik.values] ? new Date(formik.values[key as keyof typeof formik.values] as string|number|Date) : null}
                                                        defaultValue={null}
                                                        style={{ width: "100%", zIndex: 0 }}
                                                        container={document.querySelector('#update-meeting-modal-' + meeting.id) as HTMLElement}
                                                        onChange={(value: any) => {
                                                            if (value) formik.setFieldValue(key, moment(value).format('YYYY-MM-DD'))
                                                            else formik.setFieldValue(key, null)
                                                        }}
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'option') {

                                        const options = customFields[key].value.map((option, index) => ({
                                            value: option,
                                            label: option
                                        }))

                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <Select
                                                        key={nanoid()}
                                                        isClearable
                                                        options={options}
                                                        placeholder={`Pilih ${customFields[key].name}`}
                                                        value={options.find(option => option.value === formik.values[key as keyof typeof formik.values])}
                                                        onChange={
                                                            option => formik.handleChange({
                                                                target: {
                                                                    name: key,
                                                                    value: option?.value
                                                                }
                                                            })
                                                        }
                                                        onBlur={
                                                            () => formik.values[key as keyof typeof formik.values] === "" && formik.setFieldTouched(key, true)
                                                        }
                                                        className="w-100"
                                                    />
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    } else if (customFields[key].type === 'multiple') {
                                        return (
                                            <>
                                                <label className={`form-label fs-6 fw-bolder text-dark ${customFields[key].required && 'required'}`}>{customFields[key].name}</label>
                                                <div className="input-group mb-3">
                                                    <div className="form-check form-check-custom form-check-solid" style={{ display: 'contents' }}>
                                                        {
                                                            customFields[key].value.map((option, index) => (
                                                                <div style={{ display: 'flex', padding: "3px" }}>
                                                                    <input
                                                                        key={index}
                                                                        {...formik.getFieldProps(key)}
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        value={option as string}
                                                                        name={key}
                                                                        checked={formik.values[key as keyof typeof formik.values]?.includes(option as string)}
                                                                    />
                                                                    <label className="form-check-label me-2">{option}</label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    formik.touched[key as keyof typeof formik.touched] && formik.errors[key as keyof typeof formik.errors] &&
                                                    (
                                                        <div className='fv-plugins-message-container text-danger'>
                                                            <span role='alert' className="text-danger">{formik.errors[key as keyof typeof formik.errors]}</span>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                    return null
                                })
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                                ref={closeModalRef}
                            >
                                Batal
                            </button>
                            <button type="submit" className={`btn btn-primary me-10 ${modalBtnLoading ? "disabled" : ""}`} data-kt-indicator={modalBtnLoading ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    Ubah
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    )
}

export { UpdateMeeting }