import { useCallback, useState } from 'react';
import { RefObject } from 'react';
import ReactQuill from 'react-quill';
import Swal from 'sweetalert2';
import { getBase64 } from '../../../functions/email';
import { MAX_FILES_SIZE } from '../../../constant/email';

interface UseImagePasteProps {
    quillRef: RefObject<ReactQuill>;
    setTemporaryCopyFile: (files: any) => void;
    setTotalFileSize: React.Dispatch<React.SetStateAction<number>>;
    totalFileSize: number;
    showModal: boolean;
}

export const useImagePaste = ({
    quillRef,
    setTemporaryCopyFile,
    setTotalFileSize,
    totalFileSize,
    showModal
}: UseImagePasteProps) => {
    const [isAlertShown, setIsAlertShown] = useState(false);

    const handlePaste = useCallback(
        async (event: ClipboardEvent) => {
            const items = event.clipboardData?.items;
            if (items) {
                const fileItems = Array.from(items).filter((item) => item.kind === 'file');
                const filePromises = fileItems.map((item) => {
                    return new Promise<File | null>(async (resolve, reject) => {
                        const blob = item.getAsFile();
                        if (blob) {
                            try {
                                if (item.type.startsWith('image/')) {
                                    event.preventDefault();
                                    // ketika ngecopy image, dan ngepaste image maka tidak perlu di jasikan base64
                                    const getImages = await getBase64(blob);
                                    const editor = quillRef.current?.getEditor();
                                    const range = editor?.getSelection();
                                    if (editor && range) {
                                        editor.insertEmbed(range.index, 'image', getImages);
                                    }
                                    resolve(null);
                                } else {
                                    event.preventDefault();
                                    resolve(blob);
                                }
                            } catch (error) {
                                console.error('Error processing file:', error);
                                reject(error);
                            }
                        } else {
                            resolve(null);
                        }
                    });
                });

                try {
                    const newFiles = await Promise.all(filePromises);
                    const validFiles = newFiles.filter((file): file is File => file !== null);
                    setTemporaryCopyFile((prevState: File[]) => [...prevState, ...validFiles]);
                    const newTotalFileSize = validFiles.reduce(
                        (total: number, file: File) => total + file.size,
                        0
                    );
                    setTotalFileSize((prevNumber) => prevNumber + newTotalFileSize);
                } catch (error) {
                    console.error('Error processing files:', error);
                }
            }
        },
        [quillRef, setTemporaryCopyFile, setTotalFileSize]
    );

    const checkFileSizeLimit = useCallback(
        (temporaryCopyFile: File[]) => {
            if (!totalFileSize) return;
            if (totalFileSize > MAX_FILES_SIZE && !isAlertShown) {
                setIsAlertShown(true);
                if (!showModal) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Peringatan!',
                        text: 'Total Size Lebih dari 25mb, Klik OK untuk menghapus file terakhir',
                        heightAuto: false,
                        confirmButtonText: 'OK',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let currentSize = totalFileSize;
                            const updatedFiles = [...temporaryCopyFile];

                            while (currentSize > MAX_FILES_SIZE && updatedFiles.length > 0) {
                                const removedFile = updatedFiles.pop() as File;
                                currentSize -= removedFile.size;
                            }

                            setTemporaryCopyFile(updatedFiles);
                            setTotalFileSize(currentSize);
                        }
                        setIsAlertShown(false);
                    });
                }
            }
        },
        [totalFileSize, isAlertShown, showModal, setTemporaryCopyFile, setTotalFileSize]
    );

    return {
        handlePaste,
        checkFileSizeLimit,
        isAlertShown,
    };
};