import { FC, ReactNode, useEffect, useRef, useState } from "react";
import moment from "moment";
import { MasterDataTable } from "../../../components/DataTable/MasterDataTable";
import { TableColumn } from "react-data-table-component";
import { UserModel } from "../../modules/auth/models/UserModel";
import { RootState } from "../../../setup";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "bootstrap";
import { User } from "../../../interfaces/User";
import { AddUser } from "../../../components/user/AddUser";
import { UpdateUser } from "../../../components/user/UpdateUser";
import { DeleteUser } from "../../../components/user/DeleteUser";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useCompanyStore } from "../../../stores/CompanyStore";
import { useIntl } from "react-intl";
import { customNumberFormat, handleCaps } from "../../../functions/general";
import Pagination from "../../../components/Pagination";
import { Dropdown } from "rsuite";
import { DataTableImport } from "../../../components/DataTable/DataTableImport";
import { MasterCardUsers } from "./MasterCardUser";
import { useSettingsStore } from "../../../stores/SettingsStore";
import { CustomField } from "../../../interfaces/Settings";
import { UserMultiAttachments } from "../../../components/user/UserMultiAttachments";

const renderIconButton = (props: any, ref: any) => {
  return (
    <button {...props} ref={ref} style={{ backgroundColor: "white" }}>
      <i className={`fas fs-8 fa-ellipsis-v`}></i>
    </button>
  );
};

const MasterUsers: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const {
    tablesData,
    setTablesData,
    filteredTablesData,
    setFilteredTablesData,
    filterTextTable,
    setFilterTextTable,
  } = useDataTableStore()
  const { settings } = useSettingsStore()

  const [displayMode, setDisplayMode] = useState<string>("table");
  const [showUpdateUserModal, setShowUpdateUserModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<User>();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const { companies, setCompanies } = useCompanyStore()
  const [filterText, setFilterText] = useState('');
  const [customFields, setCustomFields] = useState<CustomField>({})
  const [hideColumns, setHideColumns] = useState<TableColumn<User>[]>([])
  const [key, setKey] = useState<string>('')
  const [url, setUrl] = useState<string>('')
  const [meeting, setMeeting] = useState<User>()
  const [showAttachmentCustomFieldModal, setShowAttachmentCustomFieldModal] = useState(false)

  // Set Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemPerPage] = useState<number>(10);
  const totalPages = Math.ceil(filteredTablesData["users"]?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentEmails = filteredTablesData["users"]?.slice(startIndex, endIndex);
  const pagination = Array.from({ length: totalPages }, (_, index) => index + 1);

  const intl = useIntl()

  const handleClick = (menuId: string) => {
    setOpenMenuId(menuId);
  };
  const handleClose = () => {
    setOpenMenuId(null);
  };

  useEffect(() => {
    if (showUpdateUserModal && userData) {
      const modalElement = document.getElementById(`update-user-modal-${userData.id}`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        // This listener sets showChat to false when the modal is closed
        const handleModalHide = () => {
          setShowUpdateUserModal(false);
        };

        // Attach the event listener
        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showUpdateUserModal, userData, setShowUpdateUserModal]);

  useEffect(() => {
    if (showDeleteUserModal && userData) {
      const modalElement = document.getElementById(`delete-user-modal-${userData.id}`);

      if (modalElement) {
        const modalInstance = new Modal(modalElement);
        modalInstance.show();

        // This listener sets showChat to false when the modal is closed
        const handleModalHide = () => {
          setShowDeleteUserModal(false);
        };

        // Attach the event listener
        modalElement.addEventListener('hidden.bs.modal', handleModalHide);

        // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
        };
      }
    }
  }, [showDeleteUserModal, userData, setShowDeleteUserModal]);

  useEffect(() => {
    if (showAttachmentCustomFieldModal && user.data) {
      const modalElement = document.getElementById(`attachments-user-modal-${userData?.id}`)

      if (modalElement) {
        const modalInstance = new Modal(modalElement)
        modalInstance.show()

        // This listener sets showChat to false when the modal is closed
        const handleModalHide = () => {
          setShowAttachmentCustomFieldModal(false)
        }

        // Attach the event listener
        modalElement.addEventListener('hidden.bs.modal', handleModalHide)

        // Clean up the listener when the component is unmounted or if showChat/chatHistory changes
        return () => {
          modalElement.removeEventListener('hidden.bs.modal', handleModalHide)
        }
      }
    }
  }, [showAttachmentCustomFieldModal, userData, setShowAttachmentCustomFieldModal])

  const tableColumns: TableColumn<User>[] = [
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
      center: true,
      omit: (user.data.is_superadmin === false),
      width: '70px',
      id: 'fixedLeft',
      cell: (row) => {
        return (
          <>
            {
              (
                (row.id === user.data.id || user.data.is_superadmin) ||
                (user.data.permission_user_update !== 'NONE' || user.data.permission_user_delete !== 'NONE')
              ) &&
              <>
                <IconButton
                  aria-label={`more-${row.id}`}
                  id={`menu-anchor-${row.id}`}
                  onClick={() => handleClick(row.id)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={`menu-${row.id}`}
                  anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                  open={openMenuId === row.id}
                  onClose={handleClose}
                  disableScrollLock={true}
                >
                  {
                    (
                      (row.id === user.data.id || user.data.is_superadmin) ||
                      (user.data.permission_user_update !== 'NONE') && user.data.is_superadmin
                    ) &&
                    <MenuItem onClick={() => {
                      handleClose();
                      setShowUpdateUserModal(true);
                      setUserData(row);
                    }}>
                      <i className="fas fa-edit fs-4 me-4"></i>
                      <span>{intl.formatMessage({ id: "FORM.ACTION.UPDATE" })}</span>
                    </MenuItem>
                  }

                  {
                    (
                      (row.id === user.data.id || user.data.is_superadmin) ||
                      (user.data.permission_user_delete !== 'NONE') && user.data.is_superadmin
                    ) &&
                    <MenuItem onClick={() => {
                      handleClose();
                      setShowDeleteUserModal(true);
                      setUserData(row);
                    }}>
                      <i className="fas fa-trash fs-4 me-4"></i>
                      <span>{intl.formatMessage({ id: "FORM.ACTION.DELETE" })}</span>
                    </MenuItem>
                  }

                </Menu>
              </>
            }
          </>
        )
      }
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.MANAGER' }),
      selector: row => row.leader_name?.toLowerCase() ?? "-",
      cell: row => row.leader_name ?? "-"
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.NAME' }),
      width: '14%',
      selector: row => row.name?.toLowerCase() ?? "-",
      sortable: true,
      cell: row => row.name
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ROLE' }),
      width: '14%',
      selector: row => row.role_name?.toLowerCase() ?? "-",
      sortable: true,
      cell: row => row.role_name ?? "-"
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ATTENDANCE_TEMPLATE' }),
      width: '175px',
      selector: row => row.attendanceTemplateName?.toLowerCase() ?? "-",
      sortable: true,
      cell: row => row.attendanceTemplateName ?? "-",
      omit: (companies.companyFeatureLimitUserAttendance ? false : true)
    },
    {
      name: 'Username',
      width: '200px',
      selector: row => row.username ?? '-',
      cell: (row) => {
        return row.username ?? '-';
      },
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ID_NUMBER' }),
      width: '150px',
      selector: row => row.nik ?? '-',
      cell: (row) => {
        return row.nik ?? '-';
      },
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.PHONE' }),
      width: '150px',
      selector: row => row.phone,
      cell: (row) => {
        return row.phone && row.phone
      },
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CONNECT_CHAT' }),
      width: '160px',
      selector: row => row.isWaSessionActive,
      cell: (row) => {
        const connected = row.isWaSessionActive
        return (
          connected ? (
            <span className="badge badge-light-success fs-8 fw-bolder" >
              <i className="fas fa-link me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CONNECTED' })}
            </span >
          ) : (
            <span className="badge badge-light-danger fs-8 fw-bolder">
              <i className="fas fa-unlink me-2"></i> {intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DISCONNECTED' })}
            </span>
          )
        )
      },
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.CITY' }),
      width: '150px',
      selector: row => row.city_name?.toLowerCase() ?? "-",
      cell: row => row.city_name
    },
    {
      name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.DATE_JOINED' }),
      width: '170px',
      selector: row => row.date_joined,
      sortable: true,
      cell: (row) => {
        return moment(row.date_joined).format('DD MMM YYYY')
      },
    },
  ];

  const tableColumnsWithCustomFields: TableColumn<User>[] = [
    ...tableColumns,
    ...Object.keys(customFields).map((key, index) => {
      if (customFields[key].type === 'date') {
        return {
          name: customFields[key].name,
          selector: (row: User) =>
            row.customFields && row.customFields[key]
              ? moment(row.customFields[key]).format('DD-MM-YYYY')
              : '-',
          sortable: true,
          reorder: true,
          width: '150px',
          omit: hideColumns[index + 11] ? hideColumns[index + 11].omit : false,
          cell: (row: User) => {
            if (row.customFields && row.customFields[key]) {
              return moment(row.customFields[key]).format('DD MMM YYYY')
            } else {
              return '-'
            }
          },
        }
      }
      if (customFields[key].type === 'image' || customFields[key].type === 'images') {
        return {
          width: "70px",
          name: customFields[key].name,
          center: true,
          cell: (row: User) => {
            if (row.customFields && row.customFields[key]) {
              return (
                <span role="button" onClick={
                  () => {
                    setShowAttachmentCustomFieldModal(true);
                    setMeeting(row);
                    setKey(key);
                    setUrl(row.customFields && row.customFields[key] ? row.customFields[key] : '');
                    setUserData(row)
                  }
                }>
                  <i className="fas fs-2 fa-eye"></i>
                </span>
              )
            } else {
              return '-'
            }
          },
          reorder: true,
          omit: hideColumns[index + 12] ? hideColumns[index + 12].omit : false,
        }
      }
      if (customFields[key].type === 'file' || customFields[key].type === 'files') {
        return {
          width: "70px",
          name: customFields[key].name,
          center: true,
          cell(row: User) {
            const fileUrl = row.customFields && row.customFields[key];
            if (typeof fileUrl === "string") {
              return fileUrl ? (
                <a
                  href={fileUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="ms-2 btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                >
                  <i className="fas fa-file-invoice fs-3 float-end"></i>
                </a>
              ) : '-';
            } else {
              return fileUrl ? (
                <span role="button" onClick={() => {
                  setShowAttachmentCustomFieldModal(true);
                  setMeeting(row);
                  setKey(key);
                  setUrl(fileUrl);
                  setUserData(row)
                }}>
                  <i className="fas fa-file-invoice fs-3 float-end"></i>
                </span>
              ) : '-';
            }
          },
          reorder: true,
          omit: hideColumns[index + 12] ? hideColumns[index + 12].omit : false,
        }
      }
      if (customFields[key].type === 'multiple') {
        return {
          name: customFields[key].name,
          cell: (row: User) => {
            const values = row.customFields && row.customFields[key] && row.customFields[key].toString()
            return values ? values.split(',').join(', ') : '-'
          },
          sortable: true,
          reorder: true,
          width: "150px",
          omit: hideColumns[index + 12] ? hideColumns[index + 12].omit : false,
        };
      } if (customFields[key].type === 'link') {
        return {
          name: customFields[key].name,
          cell: (row: User) => {
            if (row.customFields && row.customFields[key]) {
              return (
                <a href={row.customFields[key]} rel="noreferrer" className="btn btn-sm btn-outline btn-outline-default btn-active-light text-gray-600" target="_blank">{intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.LINK' })}<i className="fas fa-external-link-alt fs-6 ms-2 p-0"></i></a>
              )
            } else return "-"
          },
          sortable: true,
          reorder: true,
          width: "150px",
          omit: hideColumns[index + 12] ? hideColumns[index + 12].omit : false,
        };
      }
      return {
        name: customFields[key].name,
        selector: (row: User) => {
          // Check if row.customFields is defined and not null
          const fieldValue = row.customFields && row.customFields[key]

          // Check if fieldValue is an object and not null
          const displayValue =
            fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
              ? '-'
              : fieldValue

          return displayValue?.toLowerCase() || '-'
        },
        allowOverflow: true,
        sortable: true,
        width: '150px',
        omit: hideColumns[index + 11] ? hideColumns[index + 11].omit : false,
        cell: (row: User) => {
          // Check if row.customFields is defined and not null
          const fieldValue = row.customFields && row.customFields[key]

          // Check if fieldValue is an object and not null
          const displayValue =
            fieldValue && typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0
              ? '-'
              : fieldValue

          return displayValue || '-'
        },
        reorder: true,
      }
    }),
  ]

  useEffect(() => {
    setHideColumns(tableColumnsWithCustomFields)
  }, [customFields])

  useEffect(() => {
    if (tablesData['users']) {
      setFilteredTablesData('users', tablesData['users'].filter(item => {
        // Check filterText
        if (filterText) {
          const filterWords = filterText.split(' ');

          const isTextInValues = Object.values(item).some(val => {
            return filterWords.every(word => {
              const regexText = '.*' + word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*';
              const regex = new RegExp(regexText, 'i');
              return regex.test(String(val));
            });
          });

          if (!isTextInValues) {
            return false;
          }
        }

        return true;  // If all filters match (or are empty), and date is in range (if applicable), include this item.
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, tablesData]);

  useEffect(() => {
    setFilterText(filterTextTable['users']);
  }, [filterTextTable])

  let actions: ReactNode[] = [];
  if (user.data.permission_user_create !== 'NONE' && user.data.is_superadmin) {
    actions.push(
      <DataTableImport source={"template-user"} columns={tableColumns} />,
      <AddUser displayMode={displayMode} />
    );
  }

  useEffect(() => {
    if (settings.users_custom_fields) {
      setCustomFields(JSON.parse(settings.users_custom_fields))
    }
  }, [settings])

  return (
    <>
      <ol className="breadcrumb text-muted fs-6 fw-bold mb-5">
        <li className="breadcrumb-item text-dark">{intl.formatMessage({ id: 'MENU.USERS' })}</li>
      </ol>

      <div className="row d-sm-flex justify-content-between">
        <div className="col-xl-5 col-lg-5 col-sm-5 mb-5">
          <div className="d-flex flex-wrap align-items-center">
            <div className="d-flex align-items-center position-relative">
              {
                displayMode === "card" &&
                <>
                  <span className="svg-icon svg-icon-3 position-absolute ms-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="kt_filter_search"
                    className="form-control border-body bg-body w-210px ps-10"
                    placeholder={intl.formatMessage({ id: 'MASTERS.DATATABLE.SEARCH.PLACEHOLDER' })}
                    value={filterText}
                    onChange={e => { setFilterText(e.target.value); setFilterTextTable('users', e.target.value) }}
                    onInput={(e) => handleCaps(e)}
                  />
                </>
              }
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-lg-7 col-sm-7 mb-5">
          <div style={{ float: 'right' }}>
            <ul className="nav nav-pills mb-2 mb-sm-0">
              <li className="nav-item m-0">
                <div className="d-flex align-items-center w-200px flex-column me-5">
                  <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'FORM.LABEL.USER_LIMIT' })}</span>
                    <span className="fw-bolder fs-6">{customNumberFormat(companies.usersCount ?? 0)} / {customNumberFormat(companies.limit_user ?? 0)}</span>
                  </div>
                  <div className="h-5px mx-3 w-100 mb-3" style={{ backgroundColor: "white" }}>
                    <div className="bg-success rounded h-5px" role="progressbar" style={{ width: Math.round(companies.usersCount / companies.limit_user * 100) + "%" }}>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item m-0" onClick={() => { setDisplayMode("table"); }}>
                <a className={`btn btn-sm btn-icon btn-white me-3 ${displayMode === "table" ? "btn-primary" : "btn-active-light-primary"}`} data-bs-toggle="tab" href="#kt_project_users_table_pane" >
                  <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black"></path>
                      <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black"></path>
                    </svg>
                  </span>
                </a>
              </li>
              <li className="nav-item m-0" onClick={() => { setDisplayMode("card"); }}>
                <a className={`btn btn-sm btn-icon btn-white ${displayMode === "card" ? "btn-primary me-5" : "btn-active-light-primary"}`} data-bs-toggle="tab" href="#kt_project_users_card_pane" >
                  <span className="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="5" y="5" width="5" height="5" rx="1" fill="#000000"></rect>
                        <rect x="14" y="5" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                        <rect x="5" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                        <rect x="14" y="14" width="5" height="5" rx="1" fill="#000000" opacity="0.3"></rect>
                      </g>
                    </svg>
                  </span>
                </a>
              </li>
              {
                displayMode === "card" && user.data.permission_user_create !== 'NONE' && user.data.is_superadmin &&
                <>
                  <li className="nav-item m-0 me-3">
                    <DataTableImport source={"template-user"} columns={tableColumns} displayMode={displayMode} />
                  </li>
                  <li className="nav-item m-0">
                    <AddUser displayMode={displayMode} />
                  </li>
                </>
              }
            </ul>
          </div>
        </div>
      </div>

      <div className="tab-content">
        <div id="kt_project_users_table_pane" className="tab-pane fade show active">
          <MasterDataTable
            tableKey="users"
            tableColumns={tableColumnsWithCustomFields}
            actions={actions}
            apiURL="users"
            orderBy={3}
            order="asc"
          />
        </div>

        <div id="kt_project_users_card_pane" className="tab-pane fade">
          <div className="row g-4">
            {
              currentEmails?.map((dataUser, index) => (
                <MasterCardUsers
                  key={index}
                  dataUser={dataUser}
                  setUserData={setUserData}
                  setShowUpdateUserModal={setShowUpdateUserModal}
                  setShowDeleteUserModal={setShowDeleteUserModal}
                />
              ))
            }
          </div>

          {
            currentEmails?.length > 0 &&
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              pagination={pagination}
              itemsPerPage={itemsPerPage}
              totalItems={filteredTablesData["users"]?.length}
              setCurrentPage={setCurrentPage}
              setItemPerPage={setItemPerPage}
            />
          }
        </div>
      </div>

      {showUpdateUserModal && <UpdateUser userData={userData!} />}
      {showDeleteUserModal && <DeleteUser userData={userData!} />}
      {showAttachmentCustomFieldModal && <UserMultiAttachments user={userData!} title={customFields[key].name!} url={url} />}
    </>
  )
}

export { MasterUsers }