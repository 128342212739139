import { FC, useState } from "react";
import { useDataTableStore } from "../../../stores/DataTableStore";
import { useCustomFieldStore } from "../../../stores/FieldEmailStore";
import { useIntl } from "react-intl";
import useNameColumnExcelEmail from "../hooks/useNameColumnExcelEmail";
import { exportTemplateExcelEmailGeneral } from "./exportTemplateExcelEmail";

interface DetailExportGroupEmailProps {
    source: string
}

const ButtonEksporEmail: FC<DetailExportGroupEmailProps> = ({ source }) => {
    const { filteredTablesData } = useDataTableStore()
    const intl = useIntl()
    const nameHeader = useNameColumnExcelEmail()

    const handleButton = () => {
        if (source === 'template-detail-group-email') {
            exportTemplateExcelEmailGeneral(source, 'export', 'Detail Group Email', nameHeader, filteredTablesData['detailGroupEmail'])
        } else if (source === 'export-broadcast-email') {
            exportTemplateExcelEmailGeneral(source, 'export', 'Detail Broadcast Email', nameHeader, filteredTablesData['detailBroadcastEmail'])
        }
    }

    return (
        <button
            type="button"
            className={`btn btn-light-success skeleton skeleton-button mt-3`}
            id="optionImport"
            onClick={() => handleButton()}
        >
            <i className="fas fa-file-download fs-2 me-2"></i>
            {intl.formatMessage({ id: "MASTERS.DATATABLE.ACTIONS.EXPORT_EXCEL" })}
        </button>
    )
}

export { ButtonEksporEmail }