import * as ExcelJS from 'exceljs';
import moment from 'moment';
import { UserModel } from '../../app/modules/auth/models/UserModel';
import { CustomField } from '../../interfaces/Settings';

export const addWorkSheetInfo = async (
    worksheetInfo: ExcelJS.Worksheet,
    source: string,
    nameFile: string,
    nameInfo: string,
    nameHeader: any,
    user: UserModel
) => {
    worksheetInfo.protect('mjioerh3uiqWHEIOPHQWIOPEJHDWBH', {
        formatColumns: true,
        autoFilter: true,
    });
    worksheetInfo.mergeCells('A1', 'B1');
    worksheetInfo.getCell('A1').value = nameHeader.info.fileInformation;
    worksheetInfo.getCell('A2').value = nameHeader.info.downloader;
    worksheetInfo.getCell('B2').value = `${user.data.name}`;
    worksheetInfo.getCell('A3').value = nameHeader.info.downloadTime;
    worksheetInfo.getCell('B3').value = `${moment().format('DD MMM YYYY HH:mm')}`;
    worksheetInfo.getCell('A4').value = nameHeader.info.type;
    worksheetInfo.getCell('B4').value = nameInfo;

    worksheetInfo.getRow(1).font = {
        name: 'Arial Black',
        size: 16,
    };
    worksheetInfo.getRow(1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
    };
    worksheetInfo.getCell('A4').font = {
        color: {
            argb: 'FFFFFFFF',
        },
    };
    worksheetInfo.getCell('B4').font = {
        color: {
            argb: 'FFFFFFFF',
        },
    };

    worksheetInfo.columns = [
        {
            width: 25,
        },
        {
            width: 30,
        },
    ];
};

export const createWorkbook = () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Looyal';
    workbook.lastModifiedBy = 'Looyal';
    workbook.created = new Date();
    workbook.modified = new Date();
    return workbook;
};

export const createWorksheet = (workbook: ExcelJS.Workbook, sheetName: string) => {
    const worksheet = workbook.addWorksheet(sheetName);
    return worksheet;
};

export const setColumnWidths = (worksheet: ExcelJS.Worksheet, source: string, fields: string[], data: any) => {
    const colWidths = Object.keys(fields).map(() => ({ width: 25 }));
    const colStyles = Object.keys(fields).map(() => ({ numFmt: '@' }));

    if (worksheet.name === 'Satuan Produk (Opsional)') {
        colWidths[1] = { width: 43 };
        colWidths[2] = { width: 36 };
    }

    worksheet.columns = Object.keys(fields).map((field, index) => ({
        ...colWidths[index],
        style: colStyles[index],
    }));
};

export const styleColumns = (worksheet: ExcelJS.Worksheet, source: string, indexRow: number) => {
    if (indexRow === 1) {
        worksheet.getRow(indexRow).alignment = {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
        };
        worksheet.getRow(indexRow).font = {
            name: 'Arial Black',
            size: 12,
            bold: true,
        };
        worksheet.getRow(indexRow).eachCell(function (cell) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'F6991D',
                },
            };
        });
    } else if (indexRow === 2) {
        worksheet.getRow(indexRow).eachCell(function (cell) {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'F8B219'
                },
            }
        });
    }
};

export const exportWorkbook = async (
    workbook: ExcelJS.Workbook,
    worksheet: ExcelJS.Worksheet,
    source: string,
    fileName: string,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        setIsLoading(false);
    });
};
