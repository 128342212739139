import React from 'react'
import { Message } from '../../../interfaces/Chat/Message'
import { useChatStore } from '../../../stores/ChatStore'

interface ForwardChatProps {
    // selectedMessages: Message[];
    onCancel: () => void
    onForward: () => void
}

// const ForwardFooter = ({ selectedMessages, onCancel, onForward }: ForwardChatProps) => {
const ForwardFooter = ({ onCancel, onForward }: ForwardChatProps) => {
    const { selectedForwardMessages, setIsOpenForwardChat } = useChatStore();


    return (
        <div className="d-flex justify-content-between align-items-center p-4 slide-up">
            <div className="d-flex align-items-center">
                <button
                    className="btn btn-icon btn-light-primary me-3"
                    onClick={onCancel}
                >
                    <i className="bi bi-x-lg"></i>
                </button>
                <span className="fw-bold">
                    {/* {selectedMessages.length} selected */}
                    {selectedForwardMessages.length} selected
                </span>
            </div>
            <button
                className="btn btn-primary"
                onClick={() => {
                    setIsOpenForwardChat(true)
                }}
                // disabled={selectedMessages.length === 0}
                disabled={selectedForwardMessages.length === 0}
            >
                <i className="fa fa-paper-plane me-2"></i>
                Forward
            </button>
        </div>
    )
}

export default ForwardFooter