import { FC, useEffect, useState } from "react";
import { Message } from "../../../interfaces/Chat/Message";
import moment from "moment";
import { ChatReply } from "./ChatReply";
import { ChatAttachment } from "./ChatAttachment";
import useUser from "../../../hooks/User";
import { ChatAction } from "./ChatAction";
import { useChatStore } from "../../../stores/ChatStore";
import { formatText } from "../../../functions/general";
import Swal from 'sweetalert2';
import { putEditChat } from "../../../api/ChatCRUD";
import useAccessToken from "../../../hooks/AccessToken";
interface ChatMessageProps {
    message: Message
    scrollToChat: (index: number) => void
}

const ChatMessage: FC<ChatMessageProps> = ({ message, scrollToChat }) => {

    const user = useUser()
    const token = useAccessToken()

    // Check if message is from me
    const isFromMe = message.type === 'out'
    // Align message to right if message is from me
    const alignItems = isFromMe ? 'align-items-end' : 'align-items-start'

    const [showMessageActions, setShowMessageActions] = useState<boolean>(false)
    const [chatPhone, setChatPhone] = useState('');
    const [chatAs, setChatAs] = useState('');

    const {
        socket, phoneNumber, temporaryChatIds, setPersistedMessages,
        editMessage, setEditMessage, message: inputMessage,
        setMessage, replacementChat, setReplacementChat, tokenChat,
        messages, setMessages, mediaDownloads, setMediaDownloads, persistedMessages
    } = useChatStore()

    const handleEditChat = async (receiver_phone: string, as_phone: string, messageId: string, new_message: string) => {
        try {
            const response = await putEditChat(messageId, receiver_phone, as_phone, new_message, token, tokenChat);
            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Pesan berhasil diubah',
                })
                const filteredMessages = messages.map((message) => {
                    if (message.id === messageId) {
                        return {
                            ...message,
                            is_editing: false,
                            text: editMessage,
                        }
                    }
                    return message
                })
                setMessages(() => filteredMessages)
                const newPersistedMessages = persistedMessages[phoneNumber]?.messages.map((message) => {
                    if (message.id === messageId) {
                        return {
                            ...message,
                            is_editing: false,
                            text: editMessage,
                        }
                    }
                    return message
                })

                setPersistedMessages((prevMessages) => ({
                    ...prevMessages,
                    [phoneNumber]: {
                        messages: newPersistedMessages
                    }
                }));
            }
        } catch (error: any) {
            Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                confirmButtonText: 'Ok',
                heightAuto: false,
            });
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>, editedMessage?: Message) => {
        // Check if Ctrl key is pressed and the key is 'b'
        if ((e.ctrlKey && e.key.toLowerCase() === 'b') || (e.metaKey && e.key.toLowerCase() === 'b')) {
            // Prevent the default behavior of the browser
            e.preventDefault();

            // Get the selected text
            const selectedText = window.getSelection()?.toString();

            // If there is selected text, replace the selected text with '*'
            if (selectedText) {
                const updatedMessage = inputMessage.replace(selectedText, `*${selectedText}*`);
                setMessage(updatedMessage);
            } else {
                // If no text is selected, insert '*' at the current cursor position
                const cursorPosition = e.currentTarget.selectionStart || 0;
                const newMessage =
                    inputMessage.slice(0, cursorPosition) + '**' + inputMessage.slice(cursorPosition);

                setMessage(newMessage);

                // Adjust the cursor position after inserting '*'
                e.currentTarget.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
            }
        } else if ((e.ctrlKey && e.key.toLowerCase() === 'i') || (e.metaKey && e.key.toLowerCase() === 'i')) {
            // Prevent the default behavior of the browser
            e.preventDefault();

            // Get the selected text
            const selectedText = window.getSelection()?.toString();

            // If there is selected text, replace the selected text with '_'
            if (selectedText) {
                const updatedMessage = inputMessage.replace(selectedText, `_${selectedText}_`);
                setMessage(updatedMessage);
            } else {
                // If no text is selected, insert '_' at the current cursor position
                const cursorPosition = e.currentTarget.selectionStart || 0;
                const newMessage =
                    inputMessage.slice(0, cursorPosition) + '__' + inputMessage.slice(cursorPosition);

                setMessage(newMessage);

                // Adjust the cursor position after inserting '_*'
                e.currentTarget.setSelectionRange(cursorPosition + 2, cursorPosition + 2);
            }
        }

        // Call your existing onKeyDown logic if needed
        if (editedMessage) {
            onEnterPress(e, editedMessage);
        } else {
            onEnterPress(e);
        }
    };

    useEffect(() => {
        if (replacementChat[message.id!]) {
            // update the message id to the new id
            message.id = replacementChat[message.id!]
            // remove the replacement chat
            const newReplacementChat = { ...replacementChat }
            delete newReplacementChat[message.id!]
            setReplacementChat(() => newReplacementChat)

            if (mediaDownloads[message.id!]) {
                // replace the media download id with the new id
                const newMediaDownloads = {
                    ...mediaDownloads,
                    [replacementChat[message.id!]]: mediaDownloads[message.id!]
                }

                // remove the old media download id
                delete newMediaDownloads[message.id!]
                setMediaDownloads(() => newMediaDownloads)
            }
        }
    }, [replacementChat])

    useEffect(() => {
        if (persistedMessages[phoneNumber]?.messages.length > 0) {
            const persistedMessage = persistedMessages[phoneNumber].messages.find(
                (msg) => msg.id === message.id
            );

            if (persistedMessage?.chat_j_message && !message.chat_j_message) {
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === message.id
                            ? {
                                ...msg,
                                chat_j_message: persistedMessage.chat_j_message,
                                isForwarded: persistedMessage.isForwarded,
                            }
                            : msg
                    )
                );
            }
        }
    }, [persistedMessages, phoneNumber, message.id]);

    useEffect(() => {
        if(phoneNumber) {
            setChatPhone(phoneNumber.split('-')[0])
            setChatAs(phoneNumber.split('-')[1])
        }
    }, [phoneNumber])

    const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>, editedMessage?: Message) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault()
            if (editedMessage) {
                Swal.fire({
                    title: 'Mohon tunggu',
                    html: 'Sedang mengedit pesan',

                    didOpen: () => {
                        Swal.showLoading()
                    },
                })
                handleEditChat(chatPhone, chatAs, editedMessage.id!, editMessage)
            }
        }
    }

    const handleContextMenu = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        await new Promise(resolve => setTimeout(resolve, 0))
        const messageActionsDropdown = document.getElementById('message-actions-dropdown')
        messageActionsDropdown?.click()
    }

    return (
        <div
            className={`d-flex flex-column ${phoneNumber !== user.data.chat_phone ? alignItems : 'align-items-end'} my-2`}
            key={message.id}
            id={message.id ?? ''}
            onMouseEnter={() => {
                if (!replacementChat[message.id!]) setShowMessageActions(true)
            }}
            onMouseLeave={() => {
                if (!replacementChat[message.id!]) setShowMessageActions(false)
            }}
            onContextMenu={handleContextMenu}
        >
            <span className="d-flex justify-content-end">
                {message.sender?.name}
            </span>
            {
                showMessageActions && !replacementChat[message.id!] &&
                <ChatAction message={message} />
            }
            <div className="mt-2 p-3 rounded bg-light-primary text-gray-800 fw-bolder fs-6 mw-lg-400px overflow-auto">
                {/* Forward Label */}
                {message.isForwarded && (
                    <div className={`d-flex align-items-center gap-1 mb-1 ${isFromMe ? 'justify-content-end' : 'justify-content-start'}`}>
                        <i className="fas fa-share fs-7 text-gray-500 me-2"></i>
                        <span className="text-gray-500 fs-7" style={{ fontStyle: 'italic' }}>Forwarded</span>
                    </div>
                )}
                <ChatReply message={message} scrollToChat={scrollToChat} />
                {
                    ((message.id && message.attachment_name) || message.attachment) &&
                    <ChatAttachment message={message} />
                }
                {
                    !message.is_editing ?
                        <div dangerouslySetInnerHTML={{ __html: formatText(message.text, true) }} className='mt-2' />
                        :
                        <textarea
                            name="edit-message"
                            id="edit-message"
                            value={editMessage}
                            onChange={(e) => { setEditMessage(e.target.value) }}
                            onKeyDown={(e) => { handleKeyDown(e, message) }}
                        ></textarea>
                }
                <div className="text-gray-400 fw-bold fs-7 float-end ms-3 mt-2 d-flex">
                    <div>
                        {moment(message.time).format('HH:mm')}
                    </div>
                    <div className="ms-3">
                        {
                            (message.type === 'out' || message.phone === user.data.chat_phone) &&
                            <>
                                {
                                    !temporaryChatIds.includes(message.id!) && message.id !== null ?
                                        <i className="fas fa-check-double"></i>
                                        :
                                        <i className="fas fa-check"></i>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export { ChatMessage }