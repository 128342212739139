import clsx from 'clsx'
import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Dropdown} from 'rsuite'
import {ITemplateAnalytic} from '../../../interfaces/Analytics/Analytics'
import {useNavigate} from 'react-router-dom'
import { useAnalyticsStore } from '../../../stores/Analytics/AnalyticStore'

const renderIconButtonSection = (props: any, ref: any) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      if (props.onClick) {
        props.onClick(e) // Tetap panggil onClick asli
      }
    }
  return (
    <button {...props} ref={ref} className='btn btn-sm btn-icon btn-light' onClick={handleClick}>
      <i className={`fas fs-8 fa-ellipsis-v`}></i>
    </button>
  )
}
interface IProps {
  template: ITemplateAnalytic
}
export default function CardTemplate({template}: IProps) {
  const navigate = useNavigate()
  const intl = useIntl()
  const [isHover, setIsHover] = useState(false)
  const {templateAnalytic, setTemplateAnalytic, setShowUpdateTemplateModal, setShowDeleteTemplateModal} = useAnalyticsStore()
  return (
    <div
      className={clsx(
        ' position-relative shadow-sm d-flex justify-content-center align-items-center cursor-pointer ',
        isHover && 'card-analytics-hover'
      )}
      style={{width: '200px', height: '200px'}}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
      onClick={() => {
        navigate(`${template.id}`)
      }}
    >
      <div className=' p-4 d-flex justify-content-between flex-column '>
        <div className='position-absolute top-0 end-0 m-3 d-flex gap-2 justify-content-center align-items-center'>
          <Dropdown renderToggle={renderIconButtonSection} placement='bottomEnd'>
            <Dropdown.Item
              onClick={(e:React.MouseEvent<HTMLButtonElement>) => {
                  setShowUpdateTemplateModal(true)
                  setTemplateAnalytic(template)
                  e.stopPropagation()
              }}
            >
              {intl.formatMessage({id: 'FORM.ACTION.UPDATE'})}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={(e:React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                setTemplateAnalytic(template)
                setShowDeleteTemplateModal(true)
              }}
            >
              {intl.formatMessage({id: 'FORM.ACTION.DELETE'})}
            </Dropdown.Item>
          </Dropdown>
        </div>
        <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3'>
          <div className='me-2'>
            <span className='fw-bolder text-gray-800 d-block fs-3'>{template.name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
