import axios from 'axios';
import { Email } from '../interfaces/Email';
import { UserModel } from '../app/modules/auth/models/UserModel';

const API_URL = process.env.REACT_APP_API_URL
const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

interface CreateEmail {
    email: string
    password: string
    firstName: string
    lastName: string
    userId: string
    companyId: string
    photoUrl: string
}

export interface UpdateEmailType {
    id: string
    firstName: string
    lastName: string
    photoUrl: string
}

export interface UpdateNewPassword {
    id?: string
    oldPassword?: string
    newPassword?: string
}

export const createEmail = (token: string, data: CreateEmail) => {
    return async (url: string,) => {
        const response = await axios.post(url, {
            ...data
        }, {
            headers: {
                'X-Auth-token': token
            }
        });
        return response.data;
    };
};

export const updateEmail = (token: string, data: UpdateEmailType) => {
    return async (url: string) => {
        const response = await axios.patch(url, {
            ...data
        }, {
            headers: {
                'X-Auth-token': token
            }
        });
        return response.data;
    };
};

export const updateNewPassword = (token: string, data: UpdateNewPassword) => {
    return async (url: string) => {
        const response = await axios.patch(url, {
            ...data
        }, {
            headers: {
                'X-Auth-token': token
            }
        });
        return response.data;
    };
};

export const deleteEmail = (token: string, id: string) => {
    return async (url: string) => {
        const response = await axios.delete(url, {
            headers: {
                'X-Auth-token': token
            },
            data: {
                id
            }
        });
        return response.data;
    };
};


export const handleLoginWithToken = async (token: string, crmToken: string) => {
    try {
        const response = await axios.post(`${API_URL_EMAIL}auth/login-token`, { token }, {
            headers: {
                'X-Auth-token': {token: crmToken} 
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const getCompanyLimitEmail = async ([url, token]: [string, string]) => {
    try {
        const response = await axios.get(url, {
            headers: {
                'X-Auth-token': token
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};
