import React, { useEffect, useState } from 'react';
import { Map, Marker, GeoJson, GeoJsonFeature } from "pigeon-maps"
import { KTSVG } from '../../_metronic/helpers';
import { MyAttendance } from '../../interfaces/Attendance';
import { customDistanceFormat } from "../../functions/general";
import { useIntl } from "react-intl";

interface MapModalProps {
    latitude: number
    longitude: number
    attendance: MyAttendance
    difference?: number
}

const MapDistanceAttendance: React.FC<MapModalProps> = ({ latitude, longitude, attendance, difference }) => {
    const color1 = `hsl(${0 % 360}deg 39% 70%)`
    const color2 = `hsl(${180 % 360}deg 39% 70%)`
    const [geoJsonFeatureLocation, setGeoJsonFeatureLocation] = useState<Object>({})
    const [geoJsonFeatureLine, setGeoJsonFeatureLine] = useState<Object>({})
    const intl = useIntl();
    
    useEffect(() => {
        let radius = attendance.templateRadiusClockIn ?? 0;
        if (radius > 0 && (attendance.templateLatitudeClockIn || attendance.templateLatitudeClockOut) && (attendance.templateLongitudeClockIn || attendance.templateLongitudeClockOut)) {
            const circleToPolygon = require("circle-to-polygon");
        
            const coordinates = [attendance.templateLongitudeClockIn ?? attendance.templateLongitudeClockOut, attendance.templateLatitudeClockIn ?? attendance.templateLatitudeClockOut]; //[lon, lat]
            const numberOfEdges = 32; //optional that defaults to 32
        
            let polygon = circleToPolygon(coordinates, radius, numberOfEdges);
        
            setGeoJsonFeatureLocation({
                type: "Feature",
                geometry: polygon,
                properties: { prop0: "value0" },
            });
        }
        
        if ((attendance.templateLatitudeClockIn || attendance.templateLatitudeClockOut) && (attendance.templateLongitudeClockIn || attendance.templateLongitudeClockOut)){
            const speedFactor = 300;
            const start = [longitude, latitude];
            const end = [attendance.templateLongitudeClockIn ?? attendance.templateLongitudeClockOut, attendance.templateLatitudeClockIn ?? attendance.templateLatitudeClockOut];
            const diffX = end[0] - start[0];
            const diffY = end[1] - start[1];
            const sfX = diffX / speedFactor;
            const sfY = diffY / speedFactor;
            let currentDiff = [sfX, sfY]

            const interval = setInterval(() => {
                
                currentDiff[0] += sfX;
                currentDiff[1] += sfY;

                if (Math.abs(currentDiff[0]) > Math.abs(diffX) || Math.abs(currentDiff[1]) > Math.abs(diffY)) {
                    setGeoJsonFeatureLine({
                        type: "Feature",
                        geometry: {
                            "type": "LineString",
                            "coordinates": [start, end]
                        },
                        properties: { prop0: "value0" }
                    })
                    clearInterval(interval);
                    return;
                }
                
                setGeoJsonFeatureLine({
                    type: "Feature",
                    geometry: {
                        "type": "LineString",
                        "coordinates": [start, [start[0] + currentDiff[0], start[1] + currentDiff[1]]]
                    },
                    properties: { prop0: "value0" }
                })
                
            }, 10);

            return () => clearInterval(interval);
        }
    }, [])

    return (
        <>
            <div className="modal fade" tabIndex={-1} id={`map-attendance-modal-${attendance.userId}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ lineBreak: 'anywhere' }} >{intl.formatMessage({ id: "FORM.LABEL.ATTENDANCE_LOCATION" })} {attendance.userName}</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <div className="modal-body">
                            {
                                <Map height={300} defaultCenter={[latitude, longitude]} defaultZoom={15} attribution={false}>
                                    {Object.keys(geoJsonFeatureLocation).length > 0 ?
                                        <GeoJson
                                            svgAttributes={{
                                                fill: "#c9909080",
                                                strokeWidth: "1",
                                                stroke: "white",
                                                r: "20",
                                            }}
                                        >
                                            <GeoJsonFeature feature={geoJsonFeatureLocation} />
                                        </GeoJson> : <></>
                                    }
                                    {Object.keys(geoJsonFeatureLine).length > 0 ?
                                        <GeoJson
                                            svgAttributes={{ strokeWidth: "4", stroke: "black" }}
                                        >
                                            <GeoJsonFeature feature={geoJsonFeatureLine} />
                                        </GeoJson> : <></>
                                    }
                                    {(attendance.templateLatitudeClockIn || attendance.templateLatitudeClockOut) && (attendance.templateLongitudeClockIn || attendance.templateLongitudeClockOut) &&
                                        <Marker
                                            width={50}
                                            height={50}
                                            anchor={[attendance.templateLatitudeClockIn ?? attendance.templateLatitudeClockOut, attendance.templateLongitudeClockIn ?? attendance.templateLongitudeClockOut]}
                                            color={color1}
                                        />
                                    }
                                    {
                                        <Marker
                                            width={50}
                                            height={50}
                                            anchor={[latitude, longitude]}
                                            color={color2}
                                        />
                                    }
                                </Map>
                            }
                            {/* {Object.keys(geoJsonFeatureLocation).length > 0 &&
                                <p className='mb-0 mt-2'>Radius: {attendance.templateRadiusClockIn} meter</p>
                            } */}
                            {
                                difference &&
                                <p className='mb-0 mt-2'>{intl.formatMessage({ id: "FORM.LABEL.DISTANCE_DIFFERENCE" })}: {customDistanceFormat(difference)}</p>
                            }
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                {intl.formatMessage({ id: "FORM.ACTION.CLOSE" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { MapDistanceAttendance }