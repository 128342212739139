import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { nanoid } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import { SelectOption } from "../../interfaces/SelectOption";
import { useSettingsStore } from "../../stores/SettingsStore";
import useAccessToken from "../../hooks/AccessToken";
import { CustomField, TypeSerial } from "../../interfaces/Settings";
import { getTemplateSettingMeeting, getTemplateSettingMerchant, saveSetting } from "../../api/SettingCRUD";
import { useIntl } from "react-intl";
import moment from "moment";
import { KTSVG } from "../../_metronic/helpers";
import clsx from "clsx";
import * as Yup from 'yup';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { RowCustomField } from "./RowCustomField";
import { convertFromMinutes, convertToMinutes, customNumberFormat } from "../../functions/general";
import { useMeetingStore } from "../../stores/MeetingsStore";
import { CustomFieldMultiMeeting } from "./Meeting/CustomFieldMultiMeeting";
import { AddTemplateSettingMeeting } from "./Meeting/AddTemplateSettingMeeting";
import { DeleteTemplateSettingMeeting } from "./Meeting/DeleteTemplateSettingMeeting";
import useSWR from "swr";
import axios from "axios";
import useUser from "../../hooks/User";

interface Props {
    optionType: SelectOption[]
    optionsResetSerial: SelectOption[]
    dataPipeline: SelectOption[]
    dataMerchant: SelectOption[]
}

const CustomFieldMeeting: FC<Props> = ({ optionType, optionsResetSerial, dataMerchant, dataPipeline }) => {
    const API_URL = process.env.REACT_APP_API_URL

    const { settings, setSettings } = useSettingsStore()
    const token = useAccessToken()
    const user = useUser()
    const {
        meetings,
        setMeetings,
        meetingLoading,
        setMeetingLoading,
        multiMeetings,
        setMultiMeetings,
        selectMultiMeeting,
        setSelectMultiMeeting,
    } = useMeetingStore();
    const [optionMeeting, setOptionMeeting] = useState<any[]>([])
    const [selectTemplate, setSelectTemplate] = useState(multiMeetings ? multiMeetings[0] : {} as any)
    
    const [keysMeeting, setKeysMeeting] = useState<string[]>([])
    const [fieldMeeting, setFieldMeeting] = useState<CustomField>({})
    const [loadingMeetingPreferences, setLoadingMeetingPreferences] = useState(false)
    const [loadingMeeting, setLoadingMeeting] = useState(false)
    const [countAutoCancel, setCountAutoCancel] = useState({
        day: 0,
        hour: 0,
        minute: 0
    })
    const [resultMultiMeetings, setResultMultiMeetings] = useState<any[]>([])
    const [widthProgress, setWidthProgress] = useState<number>(0)

    const intl = useIntl()

    const linkref = useRef(null)
    // const fetcher = (url: string) => axios.get(url, {
    //     headers: {
    //         'X-Auth-token': token
    //     }
    // }).then(res => res.data.data)
    // const { data: resultMultiMeeting = [], error: resultMultiMeetingError, isLoading: resultMultiMeetingisLoading } = useSWR(`${API_URL}meeting-templates`, fetcher)

    // useEffect(() => {
    //     // if (!meetingLoading && resultMultiMeeting) {
    //     //   if (resultMultiMeeting && resultMultiMeeting.length > 0) {
    //         setMultiMeetings(resultMultiMeeting)

    //     //     const result = resultMultiMeeting.map((meeting: SettingMultiMeetings) => ({
    //     //       ...meeting,
    //     //       label: meeting.name,
    //     //       value: meeting.id,
    //     //     }));

    //     //     let dataLocal = localStorage.getItem("selectMultiMeeting")
    //     //     if (dataLocal) {
    //     //       setSelectMultiMeeting(JSON.parse(dataLocal))
    //     //     } else {
    //     //       setSelectMultiMeeting(result[0])
    //     //     }
    //     //     setMultiMeetings(result);
    //     //   }
    //     // }
    //   }, [resultMultiMeeting])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5
            }
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    const formikPreferences = useFormik({
        initialValues: {
            key: '',
            value: settings.meeting_title ? settings.meeting_title : intl.formatMessage({ id: "MENU.MEETINGS" }),
            checkAutoCancel: Number(settings.meeting_auto_cancel) > 0 ? true : false,
            valueAutoCancel: settings.meeting_auto_cancel ? settings.meeting_auto_cancel : -1,
            // valueVisitRadius: settings.radius_visit ? settings.radius_visit : 1,
            valueVisitRadius: 500,
            checkUpdateMeeting: settings.update_meeting,
        },
        validationSchema: Yup.object().shape({
            value: Yup.string().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.NAME" }) })),
            valueVisitRadius: Yup.number().required(intl.formatMessage({ id: "FORM.VALIDATION.FIELD_REQUIRED" }, { title: intl.formatMessage({ id: "FORM.LABEL.VISIT_RADIUS" }) })),
        }),
        enableReinitialize: true,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            let isAutoCancelEmpty = false

            if (values.value === "") return false
            if (Number(values.valueVisitRadius) === 0) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.RADIUS" }, { title: settings.meeting_title }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                setLoadingMeetingPreferences(false)
                return false
            }
            if (values.valueVisitRadius < 0) return false
            if (values.checkAutoCancel && values.valueAutoCancel! <= 0) isAutoCancelEmpty = true
            if (isAutoCancelEmpty) {
                Swal.fire({
                    title: intl.formatMessage({ id: "FORM.VALIDATION.MEETING_CANCEL_TIME" }, { title: settings.meeting_title }),
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    heightAuto: false
                })
                setLoadingMeetingPreferences(false)
                return false
            }

            setLoadingMeetingPreferences(true)

            try {
                const response = await saveSetting("meeting_title", values.value, token)
                const response2 = await saveSetting("meeting_auto_cancel", String(values.valueAutoCancel === 0 ? -1 : values.valueAutoCancel), token)
                const response3 = await saveSetting("radius_visit", String(values.valueVisitRadius), token)
                const response4 = await saveSetting("update_meeting", String(values.checkUpdateMeeting), token)
                if (response.data.success && response2.data.success && response3.data.success && response4.data.success) {
                    Swal.fire({
                        icon: 'success',
                        heightAuto: false,
                        title: intl.formatMessage({ id: "FORM.VALIDATION.SAVE_SUCCESS" }),
                        timer: 2000,
                        timerProgressBar: true
                    })
                    Object.values(fieldMeeting).forEach(field => {
                        field.new = false;
                    });
                    setSettings({
                        ...settings,
                        meeting_title: values.value,
                        meeting_auto_cancel: Number(values.valueAutoCancel),
                        radius_visit: Number(values.valueVisitRadius),
                        update_meeting: values.checkUpdateMeeting
                    })
                }
            } catch (error: any) {
                Swal.fire({
                    icon: 'error',
                    title: error.respons.data.message,
                    confirmButtonText: 'Ok'
                })
            } finally {
                setLoadingMeetingPreferences(false)
            }
        }
    })

    const handleCheckAutoCancel = (checked: boolean) => {
        formikPreferences.setFieldValue('checkAutoCancel', checked)
        formikPreferences.setFieldValue('valueAutoCancel', -1)
        setCountAutoCancel({
            day: 0,
            hour: 0,
            minute: 0
        })
    }

    const handleCheckUpdateMeeting = (checked: boolean) => {
        formikPreferences.setFieldValue('checkUpdateMeeting', checked)
    }

    useEffect(() => {
        formikPreferences.setFieldValue("valueAutoCancel", (convertToMinutes(countAutoCancel)))
    }, [countAutoCancel])

    useEffect(() => {
        if (settings.meeting_custom_fields) {
            setFieldMeeting(JSON.parse(`${settings.meeting_custom_fields}`))
            setKeysMeeting(Object.keys(JSON.parse(`${settings.meeting_custom_fields}`)))
        }

        if (Number(settings.meeting_auto_cancel) > 0) {
            setCountAutoCancel(convertFromMinutes(Number(settings.meeting_auto_cancel)))
            // formikPreferences.setFieldValue("checkAutoCancel", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings])

    const handleGetListMeetingTemplate = async (id: string) => {
        try {
            const res = await getTemplateSettingMerchant(token)
            if (res.data.success) {
                const option = res.data.data.flatMap((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));

                option.unshift({
                    label: intl.formatMessage({ id: "FORM.LABEL.ALL" }, { title: settings.merchant_title }),
                    value: 'all'
                });

                setOptionMeeting(option);
            }
        } catch (error: any) {
            console.error('error', error)
            Swal.fire({
                icon: 'error',
                title: error.response?.data?.message || "An error occurred",
                confirmButtonText: 'Ok',
                heightAuto: false
            })
        }
    }

    useEffect(() => {
        if (selectTemplate) {
            handleGetListMeetingTemplate(selectTemplate.id)
        }
    }, [])

    useEffect(() => {
        // console.log('multiMeetings========================',multiMeetings)
        const updateMultiMeeting = async () => {
            try {
                const updatedMeetings = await Promise.all(
                    multiMeetings.map(async (item: any) => {
                        try {
                            // Panggil API secara paralel
                            const meetingTemplateReq = await getTemplateSettingMeeting(item.id, token);
                            return meetingTemplateReq.data.data;

                        } catch (error) {
                            // Log error untuk item spesifik
                            console.error(`Error updating meeting ${item.id}:`, error);
                        }

                        // Jika terjadi error, kembalikan item asli
                        return item;
                    })
                );

                // Tetapkan hasil langsung ke state
                setResultMultiMeetings(updatedMeetings);
            } catch (error) {
                console.error("Error updating multiMeetings:", error);
            }
        };


        updateMultiMeeting();
    }, [multiMeetings]);

    useEffect(() => {
        setWidthProgress(Math.round(resultMultiMeetings.length / (user.data.companyFeatureLimitMeetingTemplate ?? 0) * 100))
    }, [resultMultiMeetings])

    return (
        <>
            <div className="card mb-5 mb-xl-10">
                <div className="card-header border-0">
                    <div className="card-title m-0 w-100 d-flex justify-content-between">
                        <h3 className="fw-bolder m-0">{intl.formatMessage({ id: "FORM.LABEL.PREFERENCES" }, { title: settings.meeting_title })}</h3>
                        <div className="d-flex align-items-center w-250px flex-column">
                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                <span className="fw-bold fs-6 text-gray-400">{intl.formatMessage({ id: 'FORM.LABEL.TEMPLATE_LIMIT' }, { title: settings.meeting_title })}</span>
                                <span className="fw-bolder fs-6">{customNumberFormat(resultMultiMeetings.length ?? 0)} / {customNumberFormat(user.data.companyFeatureLimitMeetingTemplate ?? 0)}</span>
                            </div>
                            <div className="h-5px mx-3 w-100 mb-3" style={{ backgroundColor: "#f5f8fa" }}>
                                <div className="bg-success rounded h-5px" role="progressbar" style={{ width: (widthProgress >= 100 ? 100 : widthProgress) + "%" }}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="kt_account_settings_email_preferences">
                    <form onSubmit={formikPreferences.handleSubmit} noValidate>
                        <div className="card-body border-top px-9 py-9">
                            <div className="row">
                                <span className="col-md-7 col-lg-9 col-xl-9 d-flex flex-column align-items-start">
                                    <span className="fw-bolder fs-5 mb-0">{`${intl.formatMessage({ id: "FORM.LABEL.NAME" })} `}</span>
                                    <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_SETTING_NAME" })}</span>
                                </span>
                                <div className="col-md-5 col-lg-3 col-xl-3">
                                    <input
                                        {...formikPreferences.getFieldProps('value')}
                                        className={clsx(
                                            'form-control',
                                            { 'is-invalid': formikPreferences.touched.value && formikPreferences.errors.value },
                                        )}
                                        type='text'
                                        name='value'
                                    />
                                    {formikPreferences.touched.value && formikPreferences.errors.value && (
                                        <div className='fv-plugins-message-container text-danger'>
                                            <span role='alert' className="text-danger">{formikPreferences.errors.value}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 form-check form-switch form-check-custom form-check-solid align-items-start cursor-pointer">
                                    <input className="form-check-input me-3" type="checkbox" {...formikPreferences.getFieldProps('checkUpdateMeeting')} checked={formikPreferences.values.checkUpdateMeeting!} onChange={(e) => handleCheckUpdateMeeting(e.target.checked)} />
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.UPDATE_CUSTOM_FIELD" })} {settings.meeting_title}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_UPDATE_CUTOM_FIELD" }, { title: settings.meeting_title })}</span>
                                    </span>
                                </label>
                            </div>
                            <div className="separator separator-dashed my-6"></div>
                            <div className="row">
                                <label className="col-md-7 col-lg-9 col-xl-9 form-check form-switch form-check-custom form-check-solid align-items-start cursor-pointer">
                                    <input className="form-check-input me-3" type="checkbox" {...formikPreferences.getFieldProps('checkAutoCancel')} checked={formikPreferences.values.checkAutoCancel!} onChange={(e) => handleCheckAutoCancel(e.target.checked)} />
                                    <span className="form-check-label d-flex flex-column align-items-start">
                                        <span className="fw-bolder fs-5 mb-0">{intl.formatMessage({ id: "FORM.LABEL.MEETING_AUTO_CANCEL_2" }, { title: settings.meeting_title })}</span>
                                        <span className="text-muted fs-6">{intl.formatMessage({ id: "FORM.LABEL.DESC_MEETING_AUTO_CANCEL_2" }, { title: settings.meeting_title })}</span>
                                    </span>
                                </label>
                                {
                                    formikPreferences.values.checkAutoCancel &&
                                    <div className="col-md-5 col-lg-3 col-xl-3 d-flex justify-content-end align-items-center">
                                        <div className="d-flex align-items-center gap-2 me-3">
                                            <input
                                                value={countAutoCancel.day === 0 ? "" : countAutoCancel.day}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, day: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "DAYS" })},</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 me-3">
                                            <input
                                                value={countAutoCancel.hour === 0 ? "" : countAutoCancel.hour}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, hour: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "HOURS" })},</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <input
                                                value={countAutoCancel.minute === 0 ? "" : countAutoCancel.minute}
                                                className={'form-control ms-3'}
                                                type='text'
                                                name='value'
                                                style={{ border: "none", borderBottom: "2px solid #E4E6EF", width: "50px", textAlign: "right" }}
                                                onChange={(e) => { setCountAutoCancel({ ...countAutoCancel, minute: Number((e.target.value).replace(/[^0-9]/g, '')) }) }}
                                            />
                                            <label>{intl.formatMessage({ id: "MINUTES" })}</label>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                            <button type="submit" className={`btn btn-primary ${loadingMeetingPreferences ? "disabled" : ""}`} data-kt-indicator={loadingMeetingPreferences ? 'on' : 'off'}>
                                <span className="indicator-label">
                                    {intl.formatMessage({ id: "FORM.ACTION.SAVE" })}
                                </span>
                                <span className="indicator-progress">
                                    Loading... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div>
                <div className="d-flex align-items-center gap-3">
                    <div className="card-toolbar custom-tabs mb-3">
                        <ul className="nav">
                            {
                                // multiMeetings?.map((data: any, index: number) => (
                                multiMeetings.map((data: any, index: number) => (
                                    <li className="nav-item" style={{ position: "relative" }} key={data.id}>
                                        <a
                                            ref={index === 0 ? linkref : null}
                                            className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light ps-4 fw-bolder ${index === 0 ? 'active pe-4' : 'pe-8'}`}
                                            data-bs-toggle="tab"
                                            href={`#tab_${data.id}`}
                                        // onClick={() => handleGetListMeetingTemplate(data.id)}
                                        >
                                            <span>{data.name}</span>
                                        </a>

                                        {
                                            index !== 0 &&
                                            <span
                                                className="text-hover-primary cursor-pointer"
                                                onClick={() => setSelectTemplate(data)}
                                                style={{ position: "absolute", right: "5px", top: "7px" }}
                                                data-bs-toggle="modal"
                                                data-bs-target="#delete-template-setting-merchant-modal"
                                            >
                                                <KTSVG path="media/icons/duotune/general/gen040.svg" className="svg-icon-5 m-0" />
                                            </span>
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    {
                        multiMeetings.length <= user.data.companyFeatureLimitMeetingTemplate &&
                        <span className='menu-icon cursor-pointer text-hover-primary mb-3' data-bs-toggle="modal" data-bs-target="#add-template-setting-merchant-modal">
                            <KTSVG path="media/icons/duotune/general/gen041.svg" className='svg-icon-1' />
                        </span>
                    }
                </div>
            </div>

            <div className="tab-content" id="myTabContent">
                {
                    resultMultiMeetings.map((data: any, index: number) => (
                        <>
                            <div key={data.id} className={`tab-pane fade ${index === 0 && 'show active'}`} id={`tab_${data.id}`} role="tabpanel">
                                {/* <p>{data.name}</p> */}
                                <CustomFieldMultiMeeting dataPipeline={dataPipeline} dataMerchant={dataMerchant} optionMeeting={optionMeeting} key={data.id} data={data} optionType={optionType} optionsResetSerial={optionsResetSerial} />
                            </div>
                        </>
                    ))
                }
            </div>

            <AddTemplateSettingMeeting />
            <DeleteTemplateSettingMeeting selectTemplate={selectTemplate} linkRef={linkref} />
        </>
    )
}

export { CustomFieldMeeting }