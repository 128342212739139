import React, { FC, useEffect, useState } from 'react'
import { EmailMessage } from '../../interfaces/Email/EmailMessage'
import { ListEmail } from './ListEmail'
import { useEmailStore } from '../../stores/EmailStore'
import moment from 'moment'
import useSWR, { mutate } from 'swr'
import axios from 'axios'
import Swal from 'sweetalert2'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { deleteEmail, moveEmail } from '../../api/EmailCRUD'
import { KTSVG } from '../../_metronic/helpers'
import { checkTypeMoveEmail, extractTextFromHtml } from '../../functions/email'
import TopNavbar from './TopNavbar'
import useAccessToken from '../../hooks/AccessToken'

const API_URL_EMAIL = process.env.REACT_APP_API_URL_EMAIL

const API_URL = process.env.REACT_APP_API_URL_EMAIL
interface Props {
  typeMenu: string
}

const CardEmail: FC<Props> = ({ typeMenu }) => {
  const {
    emails,
    setEmails,
    filteredEmails,
    setFilteredEmails,
    tokenEmail,
    checkedEmailIds,
    setCheckedEmailIds,
    setTypeMenu,
    filterText,
    setCurrentPage,
    currentPage,
    setTokenEmail,
    setIsApiError,
  } = useEmailStore()
  const [countCheckList, setCountCheckList] = useState<number>(0)
  const itemsPerPage = 10
  const totalPages = Math.ceil(filteredEmails.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentEmails = filteredEmails.slice(startIndex, endIndex)
  const pagination = Array.from({ length: totalPages }, (_, index) => index + 1)
  const [isLoading, setIsLoading] = useState(false)
  const [deletePermanent, setDeletePermanent] = useState(false)
  const filterWords = filterText.trim().toLowerCase().split(/\s+/)
  const token = useAccessToken()


  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const fetcher = async (url: string) => {
    try {
      const headers: Record<string, string> = {
        'X-auth-token': 'Bearer ' + tokenEmail,
      };

      if (token !== 'undefined') {
        headers['x-crm-auth-token'] = token;
      }

      const response = await axios.get(url, { headers });

      if (response?.data?.token) {
        setTokenEmail(response.data.token);
      }

      return response.data.data;
    } catch (error) {
      setIsApiError(true);
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const {
    data: emailsData = [],
    error: emailsError,
    isLoading: emailsLoading,
    mutate: emailsMutate,
    isValidating: emailIsValidating,
  } = useSWR(typeMenu === 'formLogin' ? null : `${API_URL_EMAIL}dashboard/list-emails?type=${typeMenu}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  useEffect(() => {
    if (!emailIsValidating) {
      // setCountCheckList(0)
      if (
        typeMenu === 'inbox' ||
        typeMenu === 'spam' ||
        typeMenu === 'archive' ||
        typeMenu === 'trash'
      ) {
        const data = emailsData.map((data: any) => {
          if (!data.header && !data.body) {
            return { id: data.attributes.uid, subject: '(Tanpa Subject)', body: 'Ada Kesalahan Sistem', from: 'Tidak ada', to: 'Tidak ada', date: 'Tidak ada', read: false }
          }
          return {
            messageId: data.header?.['message-id'] ?? '',
            id: data.attributes.uid,
            subject: data.header?.subject ? data.header?.subject[0] : '(Tanpa subjek)',
            body: data.body !== 'null' ? extractTextFromHtml(data.body) : '',
            // textPlain: removeMarkdownStars(data.textPlain),
            from: data.header?.from
              ? data.header.from[0].match(/^(.*?) <(.*?)>$/)
                ? data.header.from[0].match(/^(.*?) <(.*?)>$/)[1].trim()
                : data.header.from[0]
              : '',
            email: data.header?.from
              ? data.header.from[0].match(/^(.*?) <(.*?)>$/)
                ? data.header.from[0].match(/^(.*?) <(.*?)>$/)[2]
                : data.header.from[0]
              : '',
            to: data.header?.to ? data.header.to[0] : '',
            date: moment(data.attributes.date).format('YYYY-MM-DD HH:mm:ss'),
            read: data.attributes.flags[0] === '\\Seen' ? true : false,
            readAnswered: data.attributes.flags[0] === '\\Answered' ? true : false,
            references: data.header?.references?.map((reference: any) => reference),
            merchantName: data.merchantName || '',
          }
        })
        const sortedData = data.sort(
          // untuk sorted data berdasarkan waktu yang terbaru yang paling atas
          (a: EmailMessage, b: EmailMessage) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        setEmails(sortedData)
      } else if (typeMenu === 'sent') {
        const emailRegex = /<([^>]+)>/

        const data = emailsData.map((data: any) => {
          if (!data.header && !data.body) {
            return { id: data.attributes.uid, subject: '(Tanpa Subject)', body: 'Ada Kesalahan Sistem', from: 'Tidak ada', to: 'Tidak ada', date: 'Tidak ada', read: false }
          }
          let to = data.header.to ? data.header.to[0] : data.header.to[0].match(emailRegex)

          // Menghapus tanda kutip ganda ("") dari nama email dalam 'to'
          if (typeof to === 'string') {
            to = to.replace(/"/g, '')
          }

          // Mengecek apakah subject mengandung "Re:"
          const isReply = data.header.subject && data.header.subject[0].startsWith('Re:')

          // Jika subject mengandung "Re:", ambil hanya alamat email dari 'to'
          if (isReply && typeof to === 'string') {
            const match = to.match(emailRegex)
            to = match ? match[1] : to
          }

          return {
            id: data.attributes.uid,
            subject: data.header.subject ? data.header.subject[0] : '(Tanpa subjek)',
            body: data.body !== 'null' ? extractTextFromHtml(data.body) : '',
            // textPlain: extractTextFromHtml(data.body),
            from: data.header.from ? data.header.from[0] : '',
            email: data.header.from ? data.header.from[0] : '',
            to: to,
            date: moment(data.attributes.date).format('YYYY-MM-DD HH:mm:ss'),
            read: data.attributes.flags[0] === '\\Seen' ? true : false,
            merchantName: data.merchantName || '',
          }
        })
        const sortedData = data.sort(
          (a: EmailMessage, b: EmailMessage) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        setEmails(sortedData)
      } else if (typeMenu === 'draft') {
        const data = emailsData.map((data: any) => ({
          id: data.attributes.uid,
          subject: data.header.subject ? data.header.subject[0] : '(Tanpa subjek)',
          from: data.header.from ? data.header.from[0] : '',
          email: data.header.from ? data.header.from[0] : '',
          to: 'Draft',
          date: moment(data.attributes.date).format('YYYY-MM-DD HH:mm:ss'),
          read: data.attributes.flags[0] === '\\Seen' ? true : false,
          readAnswered: data.attributes.flags[0] === '\\Answered' ? true : false,
          merchantName: data.merchantName || '',
        }))
        const sortedData = data.sort(
          (a: EmailMessage, b: EmailMessage) =>
            new Date(b.date).getTime() - new Date(a.date).getTime()
        )
        setEmails(sortedData)
      }
    }
  }, [emailsData])

  const isDate = (value: any): value is Date | string => {
    return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)))
  }

  useEffect(() => {
    if (emails) {
      setFilteredEmails(
        emails.filter((item) => {
          if (filterText) {
            const filterWords = filterText.split(' ')
            const isTextInValues = Object.values(item).some((val) => {
              if (isDate(val)) {
                const formattedDate = String(val)
                return filterWords.every((word) => {
                  const regexText = '.*' + word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*'
                  const regex = new RegExp(regexText, 'i')
                  return regex.test(formattedDate)
                })
              }
              return filterWords.every((word) => {
                const regexText = '.*' + word.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + '.*'
                const regex = new RegExp(regexText, 'i')
                return regex.test(String(val))
              })
            })

            if (!isTextInValues) {
              return false
            }
          }

          return true // If all filters match (or are empty), and date is in range (if applicable), include this item.
        })
      )
    }
  }, [filterText, emails, setFilteredEmails])


  const checkAllEmails = (checked: boolean) => {
    if (checked) {
      setCountCheckList(emails.length)
      const checkedIds = emails.map((email: EmailMessage) => email.id.toString())
      setCheckedEmailIds(checkedIds)
      emails.forEach((email: EmailMessage) => {
        const checkbox = document.getElementById(
          'check-list-email-' + email.id
        ) as HTMLInputElement | null

        if (checkbox) {
          checkbox.checked = true
        }
      })
    } else {
      setCountCheckList(0)
      setCheckedEmailIds([])
      emails.forEach((email: EmailMessage) => {
        const checkbox = document.getElementById(
          'check-list-email-' + email.id
        ) as HTMLInputElement | null

        if (checkbox) {
          checkbox.checked = false
        }
      })
    }
  }

  const handleDeleteEmail = async () => {
    try {
      const confirmDelete = await Swal.fire({
        title: 'Konfirmasi Penghapusan',
        text: 'Apakah Anda yakin ingin menghapus email ini secara permanen?',
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        cancelButtonText: 'Tidak',
        confirmButtonText: 'Ya, Hapus',
        reverseButtons: true,
        customClass: {
          cancelButton: 'btn btn-active-light me-3',
          confirmButton: 'btn btn-danger',
        },
      });


      setIsLoading(true)
      if (confirmDelete.isConfirmed) {
        for (const emailId of checkedEmailIds) {
          await deleteEmail(emailId, typeMenu)
        }
        setCheckedEmailIds([])
        Swal.fire('Berhasil', 'Email telah berhasil dihapus', 'success')
      } else {
        setCheckedEmailIds([])
      }
    } catch (error) {
      console.log(error)
      Swal.fire('Gagal', 'Terjadi kesalahan saat menghapus email', 'error')
    } finally {
      setIsLoading(false)
      emailsMutate()
      setCountCheckList(0)
    }
  }

  const handleMoveFunction = async (typeTo: string) => {
    try {
      setIsLoading(true)
      for (const emailId of checkedEmailIds) {
        await moveEmail(emailId, typeMenu, typeTo)
      }
      Swal.fire({
        icon: 'success',
        title: 'Berhasil!',
        text: `Memindahkan Ke ${checkTypeMoveEmail(typeTo)}`,
        heightAuto: false,
      })
      setCheckedEmailIds([])
      mutate(`${API_URL}dashboard/count-emails`)
    } catch (error) {
      console.log(error)
      Swal.fire('Gagal', 'Terjadi kesalahan saat memindahkan email', 'error')

    } finally {
      setIsLoading(false)
      emailsMutate()
      setCountCheckList(0)
    }
  }


  const checkIsTrashMenu = () => {
    if (typeMenu === 'trash') {
      setDeletePermanent(true)
    } else {
      setDeletePermanent(false)
    }
  }

  useEffect(() => {
    checkIsTrashMenu()
  }, [typeMenu])

  const handleReformatTitleCard = (typeMenu: string) => {
    if (typeMenu === 'inbox') {
      return 'Kotak Masuk'
    } else if (typeMenu === 'spam') {
      return 'Spam'
    } else if (typeMenu === 'archive') {
      return 'Arsip'
    } else if (typeMenu === 'draft') {
      return 'Draf'
    } else if (typeMenu === 'trash') {
      return 'Sampah'
    } else if (typeMenu === 'sent') {
      return 'Terkirim'
    } else {
      setTypeMenu('inbox')
    }
  }

  useEffect(() => {
    setCheckedEmailIds([])
    setCountCheckList(0)
  }, [typeMenu])

  return (
    <>
      <div className='d-flex align-items-center gap-3 position-relative mb-5'>
        <TopNavbar />
      </div>

      <div className='card mb-5'>
        <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
          <div className='d-flex flex-column gap-3'>
            <div>
              <h3>{handleReformatTitleCard(typeMenu)}</h3>
            </div>
            <div className='d-flex flex-wrap gap-1'>
              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='check-all-list-email'
                  onChange={(e) => checkAllEmails(e.target.checked)}
                />
              </div>
              {/* Tambahkan Untuk dropdown dan ada menu untuk tandai semua sudah di baca */}
              {/* <EmailActionsDropdown
                onMarkAllAsRead={handleMarkAllAsRead}
                isMarkingAsRead={isMarkingAsRead}
              /> */}
              <div>
              </div>
              <OverlayTrigger
                key='refresh'
                placement='bottom'
                overlay={<Tooltip id='tooltip-refresh'>Refresh</Tooltip>}
              >
                <span
                  id='refresh'
                  className='btn btn-sm btn-icon btn-active-light-primary cursor-pointer'
                  data-toggle='tooltip'
                  data-placement='top'
                  title=''
                  data-original-title='Archive'
                  onClick={() => {
                    emailsMutate()
                    mutate(`${API_URL}dashboard/count-emails`)
                  }}
                >
                  <span className='svg-icon svg-icon-2'>
                    <KTSVG path='media/icons/duotune/arrows/arr029.svg' />
                  </span>
                </span>
              </OverlayTrigger>
              {countCheckList > 0 && (
                <>
                  {/* begin spam */}
                  {typeMenu !== 'spam' && (
                    <OverlayTrigger
                      key='spam'
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-spam'>Spam</Tooltip>}
                    >
                      <span
                        id='spam'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='top'
                        onClick={() => handleMoveFunction('spam')}
                      >
                        <span className='svg-icon svg-icon-2'>
                          <KTSVG path='media/icons/duotune/general/gen044.svg' />
                        </span>
                      </span>
                    </OverlayTrigger>
                  )}
                  {/* end spam */}
                  {/* begin arsip */}
                  {typeMenu !== 'archive' && (
                    <OverlayTrigger
                      key='archive'
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-archive'>Arsip</Tooltip>}
                    >
                      <span
                        id='archive'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='top'
                        onClick={() => handleMoveFunction('archive')}
                      >
                        <span className='svg-icon svg-icon-2'>
                          <KTSVG path='media/icons/duotune/communication/com010.svg' />
                        </span>
                      </span>
                    </OverlayTrigger>
                  )}
                  {/* end arsip */}
                  {/* begin move kotak masuk */}
                  {typeMenu !== 'inbox' && (
                    <OverlayTrigger
                      key='inbox'
                      placement='bottom'
                      overlay={<Tooltip id='tooltip-archive'>Kotak Masuk</Tooltip>}
                    >
                      <span
                        id='inbox'
                        className='btn btn-sm btn-icon btn-light btn-active-light-primary cursor-pointer'
                        data-toggle='tooltip'
                        data-placement='top'
                        onClick={() => handleMoveFunction('inbox')}
                      >
                        <span className='svg-icon svg-icon-2'>
                          <KTSVG path='media/icons/duotune/arrows/arr076.svg' />
                        </span>
                      </span>
                    </OverlayTrigger>
                  )}

                  {/* end move kotak masuk */}
                  <OverlayTrigger
                    key='delete'
                    placement='bottom'
                    overlay={<Tooltip id='tooltip-delete'>Hapus</Tooltip>}
                  >
                    <span
                      id='delete'
                      className='btn btn-sm btn-icon btn-light btn-active-light-primary cursor-pointer'
                      data-toggle='tooltip'
                      data-placement='top'
                      onClick={() => {
                        deletePermanent ? handleDeleteEmail() : handleMoveFunction('trash');
                      }}
                    >
                      <span className='svg-icon svg-icon-2'>
                        <KTSVG path='media/icons/duotune/general/gen027.svg' />
                      </span>
                    </span>
                  </OverlayTrigger>
                </>
              )}
            </div>
          </div>
          <div className='d-flex align-items-center flex-wrap gap-2'>
            {countCheckList > 0 && (
              <span className='menu-title fw-bolder me-3'>{countCheckList} Select</span>
            )}
          </div>
        </div>
        {isLoading || emailIsValidating ? (
          <div className='card-body p-0' style={{ marginTop: '300px' }}>
            <div className='loading-overlay'>
              <div className='loader'></div>
            </div>
          </div>
        ) : (
          // Begin : List Email
          <div className='card-body p-0'>
            <div
              id='kt_inbox_listing_wrapper'
              className='dataTables_wrapper dt-bootstrap4 no-footer'
            >
              <div className='table-responsive'>
                <table
                  className='table table-hover table-row-dashed fs-6 gy-5 my-0 dataTable no-footer'
                  id='kt_inbox_listing'
                >
                  <thead className='d-none'>
                    <tr>
                      <th
                        className='sorting'
                        tabIndex={0}
                        aria-controls='kt_inbox_listing'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Checkbox: activate to sort column ascending'
                        style={{ width: '0px' }}
                      >
                        Checkbox
                      </th>
                      <th
                        className='sorting'
                        tabIndex={0}
                        aria-controls='kt_inbox_listing'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Actions: activate to sort column ascending'
                      >
                        Actions
                      </th>
                      <th
                        className='sorting'
                        tabIndex={0}
                        aria-controls='kt_inbox_listing'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Author: activate to sort column ascending'
                      >
                        From
                      </th>
                      <th
                        className='sorting'
                        tabIndex={0}
                        aria-controls='kt_inbox_listing'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Title: activate to sort column ascending'
                      >
                        Subject
                      </th>
                      <th
                        className='sorting sorting_desc'
                        tabIndex={0}
                        aria-controls='kt_inbox_listing'
                        rowSpan={1}
                        colSpan={1}
                        aria-label='Date: activate to sort column descending'
                        aria-sort='descending'
                      >
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEmails.length > 0 ? (
                      <>
                        {currentEmails.map((email: EmailMessage, index: number) => (
                          <ListEmail
                            email={email}
                            key={index}
                            typeMenu={typeMenu}
                            countCheckList={countCheckList}
                            setCountCheckList={setCountCheckList}
                            filterWords={filterWords}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <tr>
                          <td colSpan={5} className='text-center'>
                            Email tidak ditemukan
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='row px-9 pt-3 pb-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_inbox_listing_paginate'
                  >
                    <ul className='pagination'>
                      <li
                        className={`paginate_button page-item previous ${currentPage === 1 ? 'disabled' : ''
                          }`}
                        onClick={prevPage}
                      >
                        <span
                          aria-controls='kt_inbox_listing'
                          data-dt-idx='0'
                          tabIndex={0}
                          className='page-link cursor-pointer'
                        >
                          Previous
                        </span>
                      </li>

                      {pagination.map((page: number) => (
                        <li
                          key={page}
                          className={`paginate_button page-item ${page === currentPage ? 'active' : ''
                            }`}
                        >
                          <span
                            aria-controls='kt_inbox_listing'
                            data-dt-idx={page}
                            tabIndex={0}
                            className='page-link cursor-pointer'
                            onClick={() => handlePageChange(page)}
                          >
                            {page}
                          </span>
                        </li>
                      ))}

                      <li
                        className={`paginate_button page-item next ${currentPage === totalPages ? 'disabled' : ''
                          }`}
                        onClick={nextPage}
                      >
                        <span
                          aria-controls='kt_inbox_listing'
                          data-dt-idx='3'
                          tabIndex={0}
                          className='page-link cursor-pointer'
                        >
                          Next
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // End : List Email
        )}
      </div>
    </>
  )
}

export { CardEmail }
