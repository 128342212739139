import React, { ReactNode, useEffect, useState } from 'react'
import MasterTableEmail from '../table/MasterTableEmail'
import { useIntl } from 'react-intl'
import { TableColumn } from 'react-data-table-component'
import useUser from '../../../../hooks/User'
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment-timezone'
import AddGroupBc from '../addModal/AddGroupEmail'
import { DeleteGroupEmail } from '../deleteModal/DeleteGroupEmail'
import { GroupEmailTypes } from '../../../../interfaces/Email/BroadCastEmailType'
import { Modal } from 'bootstrap'
import { UpdateGroupEmail } from '../updateModal/UpdateGroupEmail'
import { useEmailStore } from '../../../../stores/EmailStore'
import { useUserStore } from '../../../../hooks/UserEmail'

const GroupEmail = () => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showUpdateGroup, setShowUpdateGroup] = useState<boolean>(false);
    const [openMenuId, setOpenMenuId] = useState<string | number | null>(null);
    const [groupContactEmail, setGroupContactEmail] = useState<GroupEmailTypes | null>(null);
    const intl = useIntl()
    const { setIdDetailGroupEmail, setTypeEmail, setIsDetailActive } = useEmailStore()
    const { userEmail } = useUserStore()

    const handleClick = (menuId: string | number) => {
        setOpenMenuId(menuId);
    };
    const handleClose = () => {
        setOpenMenuId(null);
    };

    const tableColumns: TableColumn<GroupEmailTypes>[] = [
        {
            name: intl.formatMessage({ id: 'MASTERS.DATATABLE.COLUMNS.ACTION' }),
            // center: true,
            allowOverflow: true,
            width: '70px',
            id: 'fixedLeft',
            cell: (row) => {
                return (
                    <>
                        <IconButton
                            aria-label={`more-${row.id}`}
                            id={`menu-anchor-${row.id}`}
                            onClick={() => handleClick(row.id)}
                        >
                            <MoreVertIcon />
                        </IconButton>

                        <Menu
                            id={`menu-${row.id}`}
                            anchorEl={document.getElementById(`menu-anchor-${row.id}`)}
                            open={openMenuId === row.id}
                            onClose={handleClose}
                            disableScrollLock={true}
                        >
                            <MenuItem onClick={() => {
                                handleClose();
                                setShowUpdateGroup(true);
                                setGroupContactEmail(row)
                            }}>
                                <i className="fas fa-edit fs-4 me-3"></i>
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.UPDATE_GROUP' })}</span>
                            </MenuItem>

                            <MenuItem onClick={() => {
                                handleClose();
                                setShowDeleteModal(true);
                                setGroupContactEmail(row)
                            }}>
                                <i className="fas fa-trash fs-3 me-4"></i>
                                <span>{intl.formatMessage({ id: 'FORM.ACTION.DELETE' })}</span>
                            </MenuItem>
                        </Menu>
                    </>
                )
            },
        },
        {
            name: 'Nama Broadcast',
            width: '250px',
            selector: row => row.name || '',
            sortable: true,
            allowOverflow: true,
            reorder: true,
            cell: (row) => {
                return <span className='text-primary cursor-pointer'
                    onClick={() => {
                        setIdDetailGroupEmail(row.id)
                        setTypeEmail('detailGroupEmail')
                        setIsDetailActive(true)
                    }}
                >{row.name}</span>
            }
        },
        {
            name: 'Email Active',
            width: '250px',
            sortable: true,
            allowOverflow: true,
            reorder: true,
            selector: row => row.userDataCount || '',
            cell: (row) => {
                return row.userDataCount
            }
        },
        {
            name: 'Tgl Dibuat',
            width: '200px',
            sortable: true,
            selector: row => row.createdAt || '',
            cell: row => {
                return moment(row.createdAt).format("DD MMMM YYYY")
            },
            reorder: true,
        },

    ]

    useEffect(() => {
        if (showDeleteModal && groupContactEmail) {
            const modalElement = document.getElementById(`delete-group-email-modal-${groupContactEmail.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowDeleteModal(false);
                };

                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showDeleteModal, groupContactEmail, setShowDeleteModal]);

    useEffect(() => {
        if (showUpdateGroup && groupContactEmail) {
            const modalElement = document.getElementById(`update-group-contact-modal-${groupContactEmail.id}`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();

                const handleModalHide = () => {
                    setShowUpdateGroup(false);
                };

                modalElement.addEventListener('hidden.bs.modal', handleModalHide);

                return () => {
                    modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
                };
            }
        }
    }, [showUpdateGroup, groupContactEmail, setShowUpdateGroup]);

    let actions: ReactNode[] = []
    actions.push(<AddGroupBc />)

    return (
        <>
            <MasterTableEmail
                tableColumns={tableColumns}
                actions={actions}
                tableKey='groupEmail'
                apiURL={`groupcontact/list-group?userId=${userEmail?.id}`}
            />

            {showUpdateGroup && <UpdateGroupEmail groupContact={groupContactEmail!} />}

            {showDeleteModal && (
                <DeleteGroupEmail data={groupContactEmail!} />
            )}
        </>
    )
}

export default GroupEmail