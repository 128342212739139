import React, { FC, useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import { checkWhatsapp } from '../../api/Whatsapp';
import debounce from 'lodash/debounce';

interface Props {
    keyString: string;
    formik: any;
    type: string;
    typeField?: 'terminForm' | 'detailTerminForm' | 'default';
}

const InputPhone: FC<Props> = ({ keyString, formik, type, typeField = 'default' }) => {
    const [phoneValue, setPhoneValue] = useState("62");
    const [checkWa, setCheckWa] = useState("");
    const intl = useIntl();

    const debouncedCheckWa = useCallback(
        debounce(async (value: string) => {
            if (value === "62" || !value) {
                setCheckWa('');
                return;
            }

            setCheckWa("loading");
            try {
                const hasPlus = value.startsWith("+");
                const phoneNumber = hasPlus ? value : value.replace("+", "");
                const response = await checkWhatsapp(phoneNumber);
                setCheckWa(response.data.result.exists ? "true" : "false");
            } catch (error) {
                console.log('Error checking WhatsApp:', error);
                setCheckWa("false");
            }
        }, 500),
        []
    );

    useEffect(() => {
        return () => {
            debouncedCheckWa.cancel();
        };
    }, [debouncedCheckWa]);

    useEffect(() => {
        let fieldValue;
        if (typeField === 'terminForm') {
            fieldValue = formik.values.terminForm.customFields[keyString];
        } else if (typeField === 'detailTerminForm') {
            fieldValue = formik.values.customFields[keyString];
        } else if (typeField === 'default') {
            fieldValue = formik.values[keyString as keyof typeof formik.values];
        }

        setPhoneValue(fieldValue || "62");

        if (!fieldValue || fieldValue === "62") {
            setPhoneValue("62");
            setCheckWa('');
        }

    }, [formik.values, keyString, typeField]);

    const handleOnchange = (val: string, country: string) => {
        const newValue = val || "62";
        setPhoneValue(newValue);

        if (typeField === 'terminForm') {
            formik.setFieldValue(`terminForm.customFields.${keyString}`, newValue);
        } else if (typeField === 'detailTerminForm') {
            formik.setFieldValue(`customFields.${keyString}`, newValue);
        } else {
            formik.setFieldValue(keyString, newValue);
        }

        debouncedCheckWa(newValue);
    };

    return (
        <>
            {checkWa === "true" ? (
                <span className="text-success" style={{ float: "inline-end" }}>
                    {intl.formatMessage({ id: 'FORM.VALIDATION.VALID_WHATSAPP' })}
                </span>
            ) : checkWa === "false" ? (
                <span className="text-danger" style={{ float: "inline-end" }}>
                    {intl.formatMessage({ id: 'FORM.VALIDATION.INVALID_WHATSAPP' })}
                </span>
            ) : checkWa === "loading" ? (
                <span className="spinner-border spinner-border-sm align-middle" style={{ float: "inline-end" }}></span>
            ) : null}
            <div style={{marginBottom: "0.75rem"}}>
                <PhoneInput
                    countryCodeEditable={false}
                    value={phoneValue}
                    placeholder='62xxxxxxxxx'
                    country="id"
                    onChange={(value, country: any) => handleOnchange(value, country.dialCode)}
                    enableSearch={true}
                    inputStyle={{ width: '100%' }}
                    dropdownClass="z-index-3"
                    preferredCountries={['id']}
                />
            </div>
        </>
    );
};

export default InputPhone;