export function calculateDayDurations(days: string, nextDays: string): string {
    // Konversi string tanggal ke objek Date
    const d1 = new Date(days);
    const d2 = new Date(nextDays);

    // Fungsi untuk menghitung perbedaan hari
    const getDaysDifference = (startDate: Date, endDate: Date): number => {
        // Ekstrak hanya tahun, bulan, dan hari
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

        // Selisih dalam milidetik
        const diffTime = end.getTime() - start.getTime();

        // Jika tanggal sama, kembalikan 0
        if (diffTime === 0) return 0;

        // Hitung hari berdasarkan selisih milidetik
        return Math.abs(diffTime / (1000 * 60 * 60 * 24));
    };

    // Hitung durasi hari antara pasangan tanggal
    return `${getDaysDifference(d1, d2)} Hari`;
}
