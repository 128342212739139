import { FC, useEffect, useState } from "react";
import { Message } from "../../../interfaces/Chat/Message";
import { useChatStore } from "../../../stores/ChatStore";
import { downloadMedia } from "../../../api/ChatHelper";
import useAccessToken from "../../../hooks/AccessToken";
import Swal from "sweetalert2";
import { ChatAttachmentModal } from "../ChatAttachmentModal";
import { Modal } from "bootstrap";
import { isImage, isVideo } from "../../../functions/general";

interface ChatImageProps {
    message: Message
}

const ChatAttachment: FC<ChatImageProps> = ({ message }) => {

    const token = useAccessToken()

    const {
        mediaDownloads, setMediaDownloads, setAttachmentMessageId, setAttachmentUrl,
        attachmentMessageId
    } = useChatStore()

    const [showAttachmentModal, setShowAttachmentModal] = useState<boolean>(false)

    const handleMediaClick = async (message: Message) => {
        // Check if the attachment is an image or video; return early if not
        if (message.attachment_name && (!isImage(message.attachment_name!) && !isVideo(message.attachment_name!))) {
            return;
        }

        if (message.attachment && (!isImage(message.attachment!) && !isVideo(message.attachment!))) {
            return;
        }

        if (!mediaDownloads[message.id!] && !message.attachment) {
            setMediaDownloads((prevMediaDownloads) => ({
                ...prevMediaDownloads,
                [message.id!]: {
                    ...prevMediaDownloads[message.id!],
                    isDownloading: true,
                },
            }));

            try {
                const downloadResponse = await downloadMedia(message.id!, token)

                if (downloadResponse.status === 200) {
                    setMediaDownloads((prevMediaDownloads) => ({
                        ...prevMediaDownloads,
                        [message.id!]: {
                            isDownloading: false,
                            isDownloaded: true,
                            url: downloadResponse.data.data.link!,
                        },
                    }));
                }
            } catch (error) {
                // Remove failed download from mediaDownloads
                const filteredMediaDownloads = Object.keys(mediaDownloads).reduce((object: any, key: string) => {
                    if (key !== message.id) {
                        object[key] = mediaDownloads[key]
                    }
                    return object
                }, {})
                setMediaDownloads(filteredMediaDownloads)
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to download media',
                    text: 'Please try downloading again!',
                })
            }
        } else if (message.attachment || mediaDownloads[message.id!]) {
            setAttachmentMessageId(message.id!)
            setAttachmentUrl(message.attachment! ?? mediaDownloads[message.id!].url)
            const modalElement = document.getElementById(`chat-attachment-modal`);

            if (modalElement) {
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
            }
        }
    }

    const handleFileClick = async (message: Message) => {
        if (message.attachment) {
            window.open(message.attachment, '_blank');
        } else if (mediaDownloads[message.id!] && mediaDownloads[message.id!].url) {
            window.open(mediaDownloads[message.id!].url, '_blank');
        } else {
            try {
                setMediaDownloads((prevMediaDownloads) => ({
                    ...prevMediaDownloads,
                    [message.id!]: {
                        ...prevMediaDownloads[message.id!],
                        isDownloading: true,
                    },
                }));

                const downloadResponse = await downloadMedia(message.id!, token)

                if (downloadResponse.status === 200) {
                    setMediaDownloads((prevMediaDownloads) => ({
                        ...prevMediaDownloads,
                        [message.id!]: {
                            isDownloading: false,
                            isDownloaded: true,
                            url: downloadResponse.data.data.link!,
                        },
                    }));
                }
            } catch (error) {
                // Remove failed download from mediaDownloads
                const filteredMediaDownloads = Object.keys(mediaDownloads).reduce((object: any, key: string) => {
                    if (key !== message.id) {
                        object[key] = mediaDownloads[key]
                    }
                    return object
                }, {})
                setMediaDownloads(filteredMediaDownloads)
                Swal.fire({
                    icon: 'error',
                    title: 'Failed to download media',
                    text: 'Please try downloading again!',
                })
            }
        }
    }

    const imageScale: React.CSSProperties = { maxWidth: "22.5rem", maxHeight: "22.5rem" }

    return (
        <div className="mb-3 overflow-hidden" style={imageScale}>
            <span role='button' className='text-hover-primary' onClick={() => handleMediaClick(message)}>
                <div>
                    {
                        !mediaDownloads[message.id!] && !message.attachment &&
                        (isImage(message.attachment_name!) || isVideo(message.attachment_name!)) &&
                        <i className="fas fa-cloud-download-alt" style={{ fontSize: '72px' }}></i>
                    }
                    {
                        mediaDownloads[message.id!] && mediaDownloads[message.id!].isDownloading && (isImage(message.attachment_name!) || isVideo(message.attachment_name!)) &&
                        <div className="loader"></div>
                    }
                    {
                        mediaDownloads[message.id!] && mediaDownloads[message.id!].isDownloaded && !message.attachment ?
                            isImage(message.attachment_name!) &&
                            <img src={mediaDownloads[message.id!].url} alt="" className='img-fluid' />
                            :
                            message.attachment && isImage(message.attachment) &&
                            <img src={message.attachment} alt="" className='img-fluid' />
                    }
                    {
                        mediaDownloads[message.id!] && mediaDownloads[message.id!].isDownloaded && !message.attachment ?
                            isVideo(message.attachment_name!) &&
                            <video controls className='img-fluid'>
                                <source src={mediaDownloads[message.id!].url} type="video/mp4" />
                            </video>
                            :
                            message.attachment && isVideo(message.attachment) &&
                            <video controls className='img-fluid'>
                                <source src={message.attachment} type="video/mp4" />
                            </video>
                    }
                    {
                        !isImage(message.attachment_name!) && !isVideo(message.attachment_name!) && message.attachment_name &&
                        <div className='d-flex gap-1 m-1' style={{ overflowWrap: "anywhere" }} onClick={() => handleFileClick(message)}>
                            <i className="fas fa-file mt-1" style={{ fontSize: '26px' }}></i>
                            <div className="mx-1">
                                <p className='fs-6 mb-0'>{message.attachment_name}</p>
                                <p className='text-muted mb-1' style={{ fontSize: '12px' }}>{message.attachment_name.split('.').pop()?.toUpperCase()}</p>
                            </div>
                            {
                                !mediaDownloads[message.id!] && !message.attachment &&
                                <i className="fas fa-arrow-alt-circle-down mt-1" style={{ fontSize: '28px' }}></i>
                            }
                            {
                                mediaDownloads[message.id!] && mediaDownloads[message.id!].isDownloading &&
                                <div className="loader"></div>
                            }
                            {
                                ((mediaDownloads[message.id!] && mediaDownloads[message.id!].isDownloaded) || message.attachment) &&
                                <i className="fas fa-eye" style={{ fontSize: '27px' }}></i>
                            }
                        </div>
                    }
                </div>
            </span>
        </div>
    )
}

export { ChatAttachment }