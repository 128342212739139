import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL

const SEARCH_LAT_LONG_FROM_ADDRESS = `https://tool.looyal.id/general/gmaps/latlong`
const GET_SERIAL = `${API_URL}setting/custom-fields/`

export const searchLatLongFromAddress = async (address: string) => {
    return axios.get(
        SEARCH_LAT_LONG_FROM_ADDRESS + `?address=${address}`,
    )
}

export const generateSerialApi = async (key: string, type: string, token: string) => {
    return axios.get(
        GET_SERIAL + `${key}/generate-serial-number?type=${type}`,
        {
            headers: {
                'X-Auth-token': token
            }
        }
    )
}