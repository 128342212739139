import React, { useEffect, useRef } from 'react'
import { KTSVG } from '../../../../_metronic/helpers';
import { useIntl } from "react-intl";
import { DatePicker } from "rsuite";
import useTodayRange from '../../../../hooks/TodayRange';
import useHandleCloseModal from '../../../../hooks/HandleCloseModal';

export interface SettingsBroadcastEmail {
    sendTimeOption: string;
    customDate: Date | null;
    interval: number;
}

interface AddModalSettingProps {
    settings: SettingsBroadcastEmail;
    setSettings: React.Dispatch<React.SetStateAction<SettingsBroadcastEmail>>;
}

const AddModalSetting: React.FC<AddModalSettingProps> = ({ settings, setSettings }) => {
    const todayRange = useTodayRange();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl();

    const handleSendTimeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSettings(prev => ({ ...prev, sendTimeOption: event.target.value }));
    };

    const handleDateChange = (date: Date | null) => {
        setSettings(prev => ({ ...prev, customDate: date }));
    };

    const handleIntervalChange = (value: string) => {
        const numValue = value === '' ? 0 : parseInt(value, 10);
        if (!isNaN(numValue) && numValue >= 0) {
            setSettings(prev => ({ ...prev, interval: numValue }));
        }
    };

    const incrementInterval = () => {
        setSettings(prev => ({ ...prev, interval: prev.interval + 1 }));
    };

    const decrementInterval = () => {
        setSettings(prev => ({ ...prev, interval: Math.max(0, prev.interval - 1) }));
    };

    // membuat fungsi untuk mengecek value nya apakah sebelum current today dan current time
    const shouldDisableDate = (date: Date) => {
        const currentDate = new Date();
        const startOfToday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

        if (date < startOfToday) {
            return true;
        }

        if (date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()) {
            return date.getTime() < currentDate.getTime();
        }

        return false;
    };


    return (
        <div>
            <span className='btn btn-light-primary btn-sm d-flex justify-items-center align-items-center gap-2 my-auto' data-bs-toggle="modal" data-bs-target="#add-group-bc">
                <i className="fa fa-cog" aria-hidden="true"></i>
                Settings
            </span>
            <div className="modal fade" tabIndex={-1} id="add-group-bc" ref={modalRef}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Settings</h5>
                            <div
                                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                                onClick={() => modalRef.current?.click()}
                            >
                                <KTSVG
                                    path="/media/icons/duotune/arrows/arr061.svg"
                                    className="svg-icon svg-icon-2x"
                                />
                            </div>
                        </div>
                        <main className='modal-body'>
                            <div className="mb-3">
                                <label htmlFor="sendTime" className="form-label">Waktu Pengiriman</label>
                                <select
                                    className="form-select"
                                    id="sendTime"
                                    value={settings.sendTimeOption}
                                    onChange={handleSendTimeChange}
                                >
                                    <option value="Segera">Segera</option>
                                    <option value="Custom">Custom</option>
                                </select>
                                {settings.sendTimeOption === 'Custom' && (
                                    <div className="mt-3">
                                        <label htmlFor="customDate" className="form-label">Pilih Tanggal</label>
                                        <DatePicker
                                            ranges={todayRange}
                                            editable={false}
                                            format="dd MMM yyyy HH:mm"
                                            value={settings.customDate}
                                            onChange={handleDateChange}
                                            style={{ width: "100%", zIndex: 0 }}
                                            container={document.querySelector('#add-group-bc') as HTMLElement}
                                            shouldDisableDate={shouldDisableDate}
                                            renderExtraFooter={() => (
                                                <div className='d-flex justify-content-end'>
                                                    <button className='btn btn-outline-secondary'>{intl.formatMessage({ id: 'FORM.ACTION.SAVE' })}</button>
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="intervalInput" className="form-label">Kecepatan (Interval)</label>
                                <div className="input-group">
                                    <button className="btn btn-outline-secondary" type="button" onClick={decrementInterval}>
                                        <i className="fa fa-minus"></i>
                                    </button>
                                    <input
                                        type="text"
                                        inputMode='numeric'
                                        className="form-control text-center"
                                        id="intervalInput"
                                        value={settings.interval}
                                        onChange={(e) => handleIntervalChange(e.target.value)}
                                    />
                                    <button className="btn btn-outline-secondary" type="button" onClick={incrementInterval}>
                                        <i className="fa fa-plus"></i>
                                    </button>
                                    <span className="input-group-text">detik</span>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddModalSetting