import Swal from "sweetalert2";

import { useState } from "react";
import { CustomField, SettingMultiPipelines } from "../../../interfaces/Settings";
import * as ExcelJS from 'exceljs';
import { useIntl } from "react-intl";
import { insertLeadViaExcel } from "../../../api/PipelineCRUD";
import useAccessToken from "../../../hooks/AccessToken";
import moment from "moment-timezone";
import { useCustomeStore } from "../../../stores/CustomeStore";
import useUser from "../../../hooks/User";

interface UseHandleImportPipelineProps {
    listData: any
    setListData: any
    customFields: CustomField
    templatePipelineId: string
    dataUsers: any
    dataMerchants: any
    dataProgress: any
    dataProducts: any
    dataSources: any
    dataError: any
    dataSuccess: any
    selectMultiPipeline: SettingMultiPipelines
}


const useHandleImportPipeline = ({ listData, setListData, customFields, templatePipelineId, dataUsers, dataMerchants, dataProgress, dataProducts, dataSources, dataError, dataSuccess, selectMultiPipeline }: UseHandleImportPipelineProps) => {
    const intl = useIntl()
    const token = useAccessToken()
    const { companyId } = useCustomeStore()
    const user = useUser()

    const handleChangePipeline = (e: any) => {
        // handle change pada bagian file uploader
        setListData([]);
        var check_excel = true;
        var empty_excel = false;
        var success = false;
        const file = e;
        const wb = new ExcelJS.Workbook();
        const reader = new FileReader();
        var filteredCustomFieldPipelines = Object.fromEntries(
            Object.entries(customFields).filter(([key, value]) => {
                // Ensure value is an object and has a type property before filtering
                return value && typeof value === 'object' && value.type !== 'multiple' && value.type !== 'images' && value.type !== 'files' && value.type !== 'serial' && value.type !== 'pipeline' && value.type !== 'merchant' && value.type !== 'user';
            })
        );
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const buffer: any = reader.result;
            wb.xlsx.load(buffer).then((workbook: any) => {
                const newData: any = [];
                var nameWorksheet = workbook.getWorksheet("Sheet1")
                var nameWorksheetInfo = workbook.getWorksheet("Info")
                if (nameWorksheetInfo !== undefined) {
                    nameWorksheetInfo.eachRow(async (rowInfo: any, rowIndexInfo: number) => {
                        if (rowIndexInfo > 3) {
                            if (rowInfo.values[2] === `Template Insert Pipeline ${templatePipelineId}` || rowInfo.values[2] === `Template Error Insert Pipeline ${templatePipelineId}`) {
                                nameWorksheet?.eachRow((row: any, rowIndex: number) => {
                                    if (rowIndex > 2) {
                                        const rowData = row.values
                                        if (Array.isArray(rowData)) {
                                            let pic: any = dataUsers.find((data: any) => (data.name)?.toLowerCase() === rowData[1]?.toLowerCase())
                                            let merchant: any = dataMerchants.find((data: any) => (data.name)?.toLowerCase() === rowData[2]?.toLowerCase())
                                            let progress: any = dataProgress.find((data: any) => (data.name)?.toLowerCase() === rowData[5]?.toLowerCase())
                                            let product: any = dataProducts.find((data: any) => (data.name)?.toLowerCase() === rowData[4]?.toLowerCase())
                                            let source: any = dataSources.find((data: any) => (data.name)?.toLowerCase() === rowData[6]?.toLowerCase())

                                            const customFieldsStartIndex = 10; // mulai dari kolom ke 6 untuk custom field
                                            const customFieldsData = user.data.company_id === companyId.vedaPraxis || companyId.happyPlay ?
                                                Object.fromEntries(Object.keys(filteredCustomFieldPipelines).map((key, index) => {
                                                    const rowIndex = customFieldsStartIndex + index;
                                                    const rowValue = rowData[rowIndex];
                                                    const fieldType = filteredCustomFieldPipelines[key].type;
                                                    let fieldValue;

                                                    if (fieldType === 'image' || fieldType === 'file' || fieldType === 'text' || fieldType === 'link') {
                                                        fieldValue = typeof rowValue === 'object' ? rowValue.text : rowValue;
                                                    } else {
                                                        fieldValue = rowValue;
                                                    }

                                                    return [key, fieldValue];
                                                })) : {};

                                            newData.push({
                                                pic_id: pic?.id,
                                                pic_name: pic?.name,
                                                merchant_id: merchant?.id,
                                                merchant_name: merchant?.name,
                                                product_id: product?.id,
                                                product_name: product?.name,
                                                nominal: typeof rowData[3] === 'object' ? rowData[3].text ? rowData[3].text : rowData[3].url : rowData[3],
                                                progress_id: progress?.id,
                                                progress_name: progress?.name,
                                                progress_type: progress?.progress_type,
                                                source_id: source?.id,
                                                source_name: source?.name,
                                                date_start: typeof rowData[7] === 'object' ? rowData[7].text ? rowData[7].text : rowData[7].url : rowData[7],
                                                date_end: typeof rowData[8] === 'object' ? rowData[8].text ? rowData[8].text : rowData[8].url : rowData[8],
                                                note: typeof rowData[9] === 'object' ? rowData[9].text ? rowData[9].text : rowData[9].url : rowData[9],
                                                ...customFieldsData
                                            })

                                            check_excel = true
                                            success = true
                                            empty_excel = false
                                        }
                                    } else {
                                        empty_excel = true
                                        return false
                                    }
                                });
                            } else {
                                check_excel = false
                                return false
                            }
                        }
                    })
                } else {
                    check_excel = false
                }

                if (empty_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.FILL_DATA_FIRST' }),
                        confirmButtonText: 'Ok'
                    });
                }

                if (!check_excel) {
                    Swal.fire({
                        icon: 'error',
                        title: intl.formatMessage({ id: 'FORM.VALIDATION.EXCEL_TEMPLATE_NOT_MATCHING' }),
                        confirmButtonText: 'Ok'
                    });
                }
                if (success) setListData(newData);
            });
        };
    }

    const chunkArray = (array: any[], size: number) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    // HANDLE INSERT PIPELINE
    const handleInsertPipeline = async (data: any[], batchSize: number = 200) => {
        const dataBatches = chunkArray(data, batchSize);

        for (const batch of dataBatches) {
            const validData = batch.filter((item) => !dataError.some((errorItem: any) => errorItem.merchant_id === item.merchant_id));
            const invalidData = batch.filter((item) => dataError.some((errorItem: any) => errorItem.merchant_id === item.merchant_id));

            const leads = validData.map((item) => {
                const customFieldsValues: Record<string, string | null> = Object.keys(customFields).reduce(
                    (obj, fieldKey) => ({
                        ...obj,
                        [fieldKey]: item[fieldKey as keyof typeof item] ?? null, // Pastikan properti ada pada item
                    }),
                    {}
                );

                return {
                    merchantId: item.merchant_id,
                    dateStart: item.date_start,
                    dateEnd: item.date_end,
                    note: item.note || null,
                    progressId: item.progress_id,
                    subProgressId: null,
                    product: {
                        id: item.product_id,
                        unitId: null,
                        name: item.product_name,
                        qty: 1,
                        price: item.nominal,
                    },
                    sourceId: item.source_id,
                    ownerId: item.pic_id,
                    customFields: customFieldsValues, // Tambahkan custom fields
                };
            });

            const response = await insertLeadViaExcel({
                leadTemplateId: selectMultiPipeline.id,
                token: token,
                leads: leads,
            });

            if (response.data.success) {
                const insertedData = response.data.data.validLeads.map((item: any) => ({
                    ...item,
                    lastMeetEnd: "-",
                    lastLeadCreatedTime: "-",
                    dateCreated: moment(item.dateCreated).format("DD MMM YYYY HH:mm"),
                }));
                dataSuccess.push(...insertedData);

                if (response.data.data.invalidLeads.length > 0) {
                    const invalidResponseData = response.data.data.invalidLeads.map((item: any) => ({
                        ...item,
                        error: item.error || "Invalid data",
                    }));
                    dataError.push(...invalidResponseData);
                }
            } else {
                // Handle error
            }

            // Add the invalid data to dataError
            dataError.push(...invalidData);
        }
    };


    return { handleChangePipeline, handleInsertPipeline }
}

export default useHandleImportPipeline