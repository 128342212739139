import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type TokenCustomCompanyState = {
    tokenCustomCompany: string
    numberCustomCompany: string
    setTokenCustomCompany: (tokenCustomCompany: string) => void
    setNumberCustomCompany: (numberCustomCompany: string) => void
    clearTokenAndNumber: () => void
}

export const useTokenCustomCompany = create<TokenCustomCompanyState>()(
    persist(
        (set) => ({
            tokenCustomCompany: '',
            numberCustomCompany: '',
            setTokenCustomCompany: (tokenCustomCompany: string) => set({ tokenCustomCompany }),
            setNumberCustomCompany: (numberCustomCompany: string) => set({ numberCustomCompany }),
            clearTokenAndNumber: () => set({ tokenCustomCompany: '', numberCustomCompany: '' })
        }),
        {
            name: 'token-custom-company',
            storage: createJSONStorage(() => localStorage)
        }
    )
)